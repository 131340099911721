/**
 * Creates default placeholder title
 *
 * @param {String} storyTitle Title of the story instance
 * @param {Object} content SlateJS editor content
 * @param {Object} variables An multi level object to replace string literals
 * @returns {String[]} Colored words
 */

const transform = (templateString = '', templateVariables = {}) =>
  templateString.replace(/\${(.*?)}/g, (_, g) => {
    const keys = g.split('.');
    const getValue = (array, obj) => {
      if (array.length === 1) return obj[array[0]];
      return getValue(array.slice(1), obj[array[0]]);
    };
    return getValue(keys, templateVariables);
  });

const generatePlaceholderDefaultTitle = (placeholderFormat = '', content, variables = {}) => {
  const existingPlaceholder = content ? getNumberOfExistingPlaceholder(content) : 0;

  const numberIndicator = existingPlaceholder === 0 ? '' : ` (#${existingPlaceholder + 1})`;

  const placeholderTitle = transform(placeholderFormat, variables);

  return `${placeholderTitle}${numberIndicator}`;
};

const getNumberOfExistingPlaceholder = ({ document }) =>
  document.reduce((accumulator, { data }) => {
    if (data && data.assets)
      return data.assets.reduce((innerAccumulator, { mediaType }) => {
        if (mediaType === 'video/placeholder') return innerAccumulator + 1;
        return innerAccumulator;
      }, accumulator);

    return accumulator;
  }, 0);

export default generatePlaceholderDefaultTitle;
