import React from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import AppBar from 'screens/main/components/rightArea/sidebar/appBar';
import AvatarHeader from 'screens/main/components/rightArea/sidebar/avatarHeader';
import ShowItem from 'screens/main/components/rightArea/sidebar/showItem';
import Button from 'components/buttons/button/button-view';
import DetailList from 'screens/main/components/rightArea/sidebar/detailList';
import useStyles from './summary-view-styles';

const SummaryView = ({
  title,
  description,
  listDetails,
  onClose,
  type,
  subtitle,
  additionalInfo,
  variant,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar>
        <div className={classes.header}>
          <AvatarHeader
            {...rest}
            variant={variant}
            type={type}
            isSummary
            disableUpload
            title={title}
            subtitle={subtitle}
            className={classes.header}
          />
        </div>
      </AppBar>
      <div className={classes.body}>
        <ShowItem
          title="Description"
          content={description || 'No description'}
        />
        {variant === 'People' && (
          <>
            <ShowItem title="E-MAIL" content="" />
            <ShowItem title="Mobile Phone" content="" />
          </>
        )}
        {listDetails.map(({ listTitle, avatarVariant, members }) => (
          <DetailList
            key={avatarVariant}
            listTitle={listTitle}
            avatarVariant={avatarVariant}
            members={members}
          />
        ))}
      </div>
      <div className={classes.bottomDivider}>
        <Divider />
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="text" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

SummaryView.propTypes = {
  /** Team title  */
  title: PropTypes.string,
  /** Team Description  */
  description: PropTypes.string,
  /** list details */
  listDetails: PropTypes.arrayOf(
    PropTypes.shape({
      listTitle: PropTypes.string,
      avatarVariant: PropTypes.string,
      members: PropTypes.array,
    })
  ),
};

SummaryView.defaultProps = {
  title: '',
  description: '',
  listDetails: [],
};

export default SummaryView;
