import { markTypes, elementTypes } from 'components/editor/constants';

const {
  ACCESSORY,
  ADLIB,
  AUDIO,
  BREAK,
  CAMERA,
  DVE,
  FULLSCREEN_GRAPHICS,
  HEADING_FOUR,
  HEADING_ONE,
  HEADING_THREE,
  HEADING_TWO,
  JINGLE,
  LINK,
  LIVE,
  ORDERED_LIST,
  PACKAGE,
  TELEPHONE,
  UNORDERED_LIST,
  VOICE_OVER,
  OVERLAY_GRAPHICS,
  GIF,
  IMAGE,
  VIDEO,
} = elementTypes;

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const tooltipEnum = {
  [ACCESSORY]: 'Accessory',
  [ADLIB]: 'Adlib',
  [AUDIO]: 'Audio',
  [BOLD]: 'Bold',
  [BREAK]: 'Break',
  [CAMERA]: 'Camera',
  [DVE]: 'DVE',
  [FULLSCREEN_GRAPHICS]: 'Fullscreen Graphics',
  [HEADING_FOUR]: 'Heading 4',
  [HEADING_ONE]: 'Heading 1',
  [HEADING_THREE]: 'Heading 3',
  [HEADING_TWO]: 'Heading 2',
  [ITALIC]: 'Italic',
  [JINGLE]: 'Jingle',
  [LINK]: 'Link',
  [LIVE]: 'Live',
  [ORDERED_LIST]: 'Numbered list',
  [PACKAGE]: 'Clip',
  [STRIKE_THROUGH]: 'Strikethrough',
  [TELEPHONE]: 'Telephone',
  [UNDERLINE]: 'Underline',
  [UNORDERED_LIST]: 'Bulleted list',
  [GIF]: 'GIF',
  [IMAGE]: 'Image',
  [VIDEO]: 'Video',
  [VOICE_OVER]: 'Voiceover',
  [OVERLAY_GRAPHICS]: '*CG',
  COLOR: 'Color',
  GIF: 'GIF',
  HORIZONTAL_RULE: 'Horizontal rule',
  IMAGE: 'Image',
  PLACEHOLDER: 'Placeholder',
  QUOTE: 'Quote',
  VIDEO: 'Video',
};

export default tooltipEnum;
