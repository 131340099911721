import React, { memo } from 'react';
import SelectedElement from '../view';

const withSelection = Component =>
  memo(props => (
    <SelectedElement {...props}>
      <Component {...props} />
    </SelectedElement>
  ));

export default withSelection;
