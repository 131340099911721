import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/buttons/button';
import theme from 'theme/theme';
import Dialog from 'components/dialog';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as EditIcon } from 'assets/icons/systemicons/edit_alt.svg';
import { makeCompactGiven } from './utils';
import GroupHeader from '../groupHeader';
import Table from './components/table';
import FilledGrid from './components/filledGrid';
import useStyles from './grid-styles';

const returnButtonUtils = parsedValue => {
  if (parsedValue.length) return { icon: <EditIcon />, mode: 'Edit' };
  return { icon: <AddIcon />, mode: 'Add' };
};

const GridView = ({
  label,
  parameters,
  parameterFields,
  renderedValue,
  updateField,
  disableEdit,
}) => {
  const classes = useStyles();
  const parsedValue = renderedValue ? JSON.parse(renderedValue) : [];
  const [openGrid, setOpenGrid] = useState(false);

  const handleCloseGrid = () => setOpenGrid(!openGrid);

  const columns = parameters.reduce((fields, fPrm) => {
    const pField = parameterFields.find(param => param.id === fPrm.id);
    if (pField) fields.push(pField);
    return fields;
  }, []);

  const rows = makeCompactGiven(columns, parsedValue);

  const { icon, mode } = returnButtonUtils(parsedValue);

  return (
    <GroupHeader label={label}>
      <div className={classes.container}>
        {mode === 'Edit' && <FilledGrid columns={columns} rows={rows} />}
        {!disableEdit && (
          <div className={classes.buttonContainer}>
            <Button onClick={() => setOpenGrid(!openGrid)}>
              {icon}
              {`${mode} ${label}`}
            </Button>
          </div>
        )}
        <Dialog
          open={openGrid}
          onClose={handleCloseGrid}
          style={{ minWidth: '800px' }}
        >
          <Table
            label={label}
            columns={columns}
            rows={rows}
            updateField={updateField}
            onCancel={() => setOpenGrid(!openGrid)}
            tableStyle={{ backgroundColor: theme.palette.dina.tableHeader }}
          />
        </Dialog>
      </div>
    </GroupHeader>
  );
};

GridView.propTypes = {
  /** Columns for table */
  parameters: PropTypes.arrayOf(PropTypes.object),
  /** Rows for table */
  parameterFields: PropTypes.arrayOf(PropTypes.object),
  /** callback when any field is updated  */
  updateField: PropTypes.func,
  /** stringified json object rendered on the grid */
  renderedValue: PropTypes.string,
  /** label of the dialog */
  label: PropTypes.string,
  /** Boolean that stops an user from editing */
  disableEdit: PropTypes.bool,
};

GridView.defaultProps = {
  parameters: [],
  parameterFields: [],
  updateField: () => {},
  renderedValue: '',
  label: '',
  disableEdit: false,
};
export default GridView;
