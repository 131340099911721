import React from 'react';
import useUpdateInstanceState from 'hooks/useUpdateInstanceState';
import { DragDropContext } from 'react-beautiful-dnd';
import StatusView from './status-view';

const StatusActionContainer = ({
  states,
  data,
  startDate,
  endDate,
  onlyShowUsersItems,
  updateInstance,
  platforms,
  openStory,
  stateCollapsed,
  setStateCollapsed,
}) => {
  const [update] = useUpdateInstanceState();

  const instances = data;
  const onDragEnd = async result => {
    const { destination, source, draggableId } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId) return;

    const sourceInstances = instances[source.droppableId];
    const draggingInstance = sourceInstances.filter(i => i.mId === draggableId)[0];

    const newSourceInstances = sourceInstances;
    newSourceInstances.splice(source.index, 1);

    const newDestinationInstances = instances[destination.droppableId];
    newDestinationInstances.splice(destination.index, 0, draggingInstance);

    update(draggableId, destination.droppableId, startDate, endDate);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StatusView
        {...{
          states,
          data,
          onlyShowUsersItems,
          updateInstance,
          startDate,
          endDate,
          platforms,
          openStory,
          stateCollapsed,
          setStateCollapsed,
        }}
      />
    </DragDropContext>
  );
};

export default StatusActionContainer;
