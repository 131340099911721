import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormatQuote } from '@material-ui/icons';
import { useSlate } from 'slate-react';
import Tooltip from 'components/tooltip';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import { isElementActive, toggleElement } from 'components/editor/utils';
import { elementTypes } from 'components/editor/constants';
import useStyles from './styles';

const { QUOTE } = elementTypes;

const QuoteButton = () => {
  const editor = useSlate();
  const isActive = isElementActive(editor, QUOTE);
  const classes = useStyles({ isActive });

  const onMouseDown = useCallback(
    event => {
      event.preventDefault();
      toggleElement(editor, QUOTE, isActive);
    },
    [editor, isActive],
  );

  return (
    <Tooltip title={tooltipEnum.QUOTE}>
      <div className={classes.root} role="presentation" {...{ onMouseDown }}>
        <FormatQuote className={classes.icon} />
      </div>
    </Tooltip>
  );
};

QuoteButton.propTypes = {
  /** Type for the button */
  type: PropTypes.oneOf(Object.values(elementTypes)),
};

QuoteButton.defaultProps = {
  type: QUOTE,
};

export default QuoteButton;
