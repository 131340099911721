const syncSyncProviders = (syncProviders, frameId) => {
  if (!syncProviders) return;
  const mimirProvider = syncProviders.find(sp => sp.provider === 'Mimir');
  if (!mimirProvider || !mimirProvider.mMetaData) return;

  const { mMetaData } = mimirProvider;
  const folderId = mMetaData.find(mdata => mdata.key === 'folderId');
  if (!folderId) return;

  const messageStringOpenFolder = mimirOpenFolder;
  const messageStringSetWorkFolder = mimirWorkfolder;
  messageStringOpenFolder.payload = folderId.value;
  messageStringSetWorkFolder.payload = folderId.value;

  const ifrm = document.getElementById('mimir');
  if (ifrm) {
    ifrm.contentWindow.postMessage(messageStringOpenFolder, '*');
  }
  if (ifrm) {
    ifrm.contentWindow.postMessage(messageStringSetWorkFolder, '*');
  }
};
const mimirOpenFolder = {
  action: 'open_folder',
  payload: 'folder-id',
  destination: 'MIMIR',
};

const mimirWorkfolder = {
  action: 'set_work_folder',
  payload: 'folder-id',
  destination: 'MIMIR',
};
export default syncSyncProviders;
