import { Editor } from 'slate';

/**
 * Determines if specified element type is active or not
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} type Type for the node to check for
 * @returns {Boolean}
 */

const isElementActive = (editor, type) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === type,
  });

  return Boolean(match);
};

export default isElementActive;
