import React, { useState, useEffect, useRef } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as Close } from 'assets/icons/systemicons/close_small.svg';
import FilledInput from '@material-ui/core/FilledInput';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import useStyles from './TextAreaStyle';

const TextArea = props => {
  const {
    label,
    description,
    severity,
    disabled,
    value,
    onChange,
    optionalLabel,
    type,
    subDescription,
    ...other
  } = props;

  const [fieldTouched, setFieldTouched] = useState(false);
  const [renderLabel, setRenderLabel] = useState(label);
  const textAreaRef = useRef(null);
  const [focused, setFocused] = useState(false);
  const classes = useStyles({ type });

  const handleChangeValue = event => {
    event.preventDefault();
    onChange(event.target.value);
    setFieldTouched(event.target.value);
  };

  const handleFocus = event => {
    event.preventDefault();
    if (!event.target.value) {
      if (!focused) {
        setRenderLabel(optionalLabel);
        setFocused(!focused);
      } else {
        setRenderLabel(label);
        setFocused(!focused);
      }
    }
  };
  useEffect(() => {
    if (value.length > 0) setRenderLabel(optionalLabel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const ReturnUnderlineStyle = () => {
    if (severity === 'mandatory') {
      return fieldTouched ? classes.cssUnderlineNotTouched : classes.cssUnderlineTouched;
    }
    return classes.cssUnderlineRegular;
  };

  const handleClickIconButton = event => {
    event.preventDefault();
    onChange('');
    setFieldTouched(false);
    textAreaRef.current.focus();
  };

  const isEditorOrSoMe = type === 'editor' || type === 'SoMe';

  return (
    <div className={classes.inputFieldDiv}>
      <FormControl
        variant="filled"
        error={severity === 'error' && true}
        disabled={disabled}
        fullWidth
      >
        <InputLabel
          htmlFor={renderLabel}
          classes={{
            root: classes.labelRoot,
            focused: classes.focused,
            disabled: classes.disabled,
            error: classes.error,
            shrink: classes[isEditorOrSoMe ? 'shrink' : null],
          }}
        >
          {renderLabel}
        </InputLabel>
        <FilledInput
          data-testid="input"
          fullWidth
          type="text"
          value={value}
          onChange={handleChangeValue}
          disableUnderline={disabled}
          multiline
          onFocus={handleFocus}
          onBlur={handleFocus}
          classes={{
            root: classes.filledInputRoot,
            underline: ReturnUnderlineStyle(),
            input: classes.filledInput,
            error: classes.error,
            disabled: classes.disabled,
            multiline: classes[isEditorOrSoMe ? 'multiline' : null],
            inputMultiline: classes[isEditorOrSoMe ? 'inputMultiline' : null],
          }}
          endAdornment={
            type === 'descriptionField' &&
            value && (
              <InputAdornment position="end">
                <IconButton
                  className={classes.iconButton}
                  tabIndex="-1"
                  onClick={handleClickIconButton}
                >
                  <Close />
                </IconButton>
              </InputAdornment>
            )
          }
          inputRef={textAreaRef}
          {...other}
        />

        <FormHelperText
          classes={{
            root: classes.formHelperRoot,
            error: classes.error,
            disabled: classes.disabled,
          }}
        >
          {description}
          {subDescription && <span className={classes.exceedChar}>{subDescription}</span>}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

TextArea.propTypes = {
  /** type of the text area according to usage i.e. editor descriptionField, SoMe */
  type: PropTypes.string,
  /** Label stating the purpose of the input field (text) */
  label: PropTypes.string,
  /** Optional label to show when text field focused */
  optionalLabel: PropTypes.string,
  /** Assistive help text shown at the bottom if defined (text)  */
  description: PropTypes.string,
  /** formatted description text to show beside description */
  subDescription: PropTypes.string,
  /** Values: regular, mandatory, error */
  severity: PropTypes.string,
  /** Values: true or false */
  disabled: PropTypes.bool,
  /** Value of the input field */
  value: PropTypes.string,
  /** onChange function */
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  type: 'default',
  label: '',
  optionalLabel: '',
  description: undefined,
  subDescription: '',
  severity: 'regular',
  disabled: false,
  value: '',
  onChange: () => {},
};

export default TextArea;
