const saveAutoTempOnContext = (automationResult, config) => {
  config.automationTemplateConfigs = [
    ...automationResult.data.getMemberFromId.map(({ mProperties }) => ({
      templateSets: (mProperties && mProperties.templateSets) || [],
      resources: (mProperties && mProperties.resources) || null,
    })),
  ];
};

export default saveAutoTempOnContext;
