import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@material-ui/core';
import Divider from 'components/divider';
import returnState from 'screens/planning/components/status/utils/statusBoards/returnState';
import variants from 'utils/instance/variants';
import Assignees from './components/assignees';
import InfoSummary from './components/infoSummary';
import StatusSelector from './components/statusSelector';
import LockedIndicator from './components/lockedIndicator';
import useStyles from './footer-styles';

const FooterView = ({
  variant,
  clipDuration,
  onClipDurationChange,
  scriptDuration,
  totalDuration,
  statusId,
  onStatusChange,
  onBackgroundClick,
  collapsed,
  isSavingContent,
  readLock,
  lockedByUser,
  writeLock,
  onDone,
  publishingPoint,
  assignees,
  onAssigneeUpdate,
  disableEdit,
  hasChanges,
  twitterThreadCount,
  canShowNewDesign,
}) => {
  const state = returnState(statusId);
  const backgroundColor = state ? state.backgroundColor : null;
  const classes = useStyles({ backgroundColor });

  return (
    <div className={classes.root}>
      <div className={classes.footerContent}>
        <div role="presentation" className={classes.backgroundColor} onClick={onBackgroundClick} />
        <div className={classes.content}>
          <div className={classes.info}>
            {variant === variants.LINEAR && (
              <>
                <InfoSummary
                  disableEdit={disableEdit}
                  duration={clipDuration}
                  title="Dur"
                  onUpdate={onClipDurationChange}
                />

                <InfoSummary disableEdit duration={scriptDuration} title="script" />

                <InfoSummary disableEdit duration={totalDuration} title="total" />
              </>
            )}
            {canShowNewDesign && variant === variants.TWITTER && (
              <InfoSummary disableEdit size="large" duration={twitterThreadCount} title="Threads" />
            )}
          </div>
          <div className={classes.status}>
            <div className={classes.assigneesWrapper}>
              <Assignees {...{ assignees, disableEdit }} onAssigneeUpdate={onAssigneeUpdate} />
            </div>
            <Divider orientation="vertical" className={classes.divider} />
            <StatusSelector {...{ statusId, disableEdit }} onChange={onStatusChange} />
          </div>
        </div>
      </div>
      {(readLock || writeLock) && (
        <Fade in={writeLock || readLock} timeout={{ enter: 250, exit: 250 }}>
          <LockedIndicator
            {...{
              readLock,
              writeLock,
              onDone,
              onBackgroundClick,
              lockedByUser,
              isSavingContent,
              hasChanges,
            }}
          />
        </Fade>
      )}
    </div>
  );
};

FooterView.propTypes = {
  /** Variant of the instance card */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Callback to be invoked on clip duration change */
  onClipDurationChange: PropTypes.func,
  /** Current status id of the instance */
  statusId: PropTypes.string,
  /** Callback to be invoked on status change */
  onStatusChange: PropTypes.func,
  /** Callback to be invoked on click for non-editable elements */
  onBackgroundClick: PropTypes.func,
  /** Boolean that indicates that editor content is saving */
  isSavingContent: PropTypes.bool,
  /** Name of the instance's locking user */
  lockedByUser: PropTypes.string,
  /** Boolean to indicate that the user has write access of the instance */
  writeLock: PropTypes.bool,
  /** Callback to be invoked when done button is pressed */
  onDone: PropTypes.func,
  /** Publishing point of the instance */
  publishingPoint: PropTypes.string,
  /** List of users assigned to current instance */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Callback to be invoked on assignee update */
  onAssigneeUpdate: PropTypes.func,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
  twitterThreadCount: PropTypes.oneOf(PropTypes.string, PropTypes.number),
};

FooterView.defaultProps = {
  variant: variants.LINEAR,
  onClipDurationChange: newDuration => {},
  statusId: 'todo',
  onStatusChange: newStatusId => {},
  onBackgroundClick: () => {},
  isSavingContent: false,
  lockedByUser: 'Someone',
  writeLock: false,
  onDone: () => {},
  assignees: [],
  onAssigneeUpdate: updatedAssignees => {},
  publishingPoint: null,
  disableEdit: false,
  twitterThreadCount: '-',
};

export default FooterView;
