import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Container = ({ children, className, width, height, onClick }) => {
  const classes = useStyles(width, height)();

  return (
    <div className={`${classes.root} ${className}`} {...{ onClick }}>
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
};

Container.defaultProps = {
  children: null,
};

export default Container;
