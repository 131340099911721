import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DragHandle } from 'assets/icons/systemicons/dragHandle.svg';
import Tooltip from 'components/tooltip';
import Menu from '../menu';
import useStyles from './styles';

const MemoizedTooltip = memo(Tooltip);

const Box = ({
  children,
  readOnly,
  height,
  title,
  subtitle,
  iconComponent,
  type,
  menuItems,
  onMenuSelect,
  showDragHandle,
  hideEllipsisButton,
}) => {
  const classes = useStyles({ height, type });
  return (
    <div contentEditable={false} className={classes.contentContainer}>
      <div className={classes.iconWrapper}>{iconComponent}</div>
      <div className={classes.contentWrapper}>
        <div style={{ height: subtitle ? 56 : 36 }} className={classes.withEllipsis}>
          <MemoizedTooltip title={subtitle} noArrow>
            <div className={classes.titleWrapper}>
              <p className={classes.title}>{title}</p>
              {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
            </div>
          </MemoizedTooltip>
          {!hideEllipsisButton && (
            <Menu className={classes.ellipsis} items={menuItems} onSelect={onMenuSelect} />
          )}
        </div>
        {children}
        {showDragHandle && <DragHandle className={classes.dragHandle} />}
      </div>
    </div>
  );
};

Box.propTypes = {
  type: PropTypes.oneOf(['default', 'media']),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      action: PropTypes.string,
      icon: PropTypes.element,
    }),
  ),
  onMenuSelect: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  iconComponent: PropTypes.node,
  readOnly: PropTypes.bool,
  showDragHandle: PropTypes.bool,
  hideEllipsisButton: PropTypes.bool,
};

Box.defaultProps = {
  type: 'default',
  menuItems: [],
  onMenuSelect: () => {},
  height: null,
  title: '',
  iconComponent: null,
  readOnly: false,
  showDragHandle: false,
  hideEllipsisButton: false,
};

export default memo(Box);
