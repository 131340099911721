import React, { useState, useEffect } from 'react';
import MemberList from './member-list-view';

const MemberListContainer = props => {
  const { input, inputValues, data, variant, ...rest } = props;
  const [members, setMembers] = useState([]);

  useEffect(() => {
    let newMembers = [];
    if (data && data.getMembersOftype) {
      const extendedMembers = data.getMembersOftype.map(member =>
        Object.assign({}, member, { selected: false })
      );
      newMembers = extendedMembers;
    }
    if (inputValues.length > 0) {
      inputValues.forEach(value => {
        newMembers = newMembers.map(member => {
          const newMember = { ...member };
          if (value.mId === member.mId) {
            newMember.selected = true;
          }
          return newMember;
        });
      });
    }
    setMembers(newMembers);
  }, [inputValues, data]);

  let showMember;
  if (input && members) {
    const lowerCaseInput = input.toLowerCase();
    showMember = members.filter(member => {
      return member.mTitle.toLowerCase().includes(lowerCaseInput);
    });
  } else showMember = members;

  return (
    <MemberList
      variant={variant}
      showMember={input ? showMember : members}
      {...rest}
    />
  );
};

export default MemberListContainer;
