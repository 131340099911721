import { pipe } from 'lodash/fp';
import parseXml from './parseXml';
import convertIntoMosAsset from './convertIntoMosAsset';

/**
 * Parses given text and converts to mos asset object
 *
 * @param {String} text Text to be parsed
 * @returns {Object} Parsed xml object
 */

const getMosAsset = pipe(
  parseXml,
  convertIntoMosAsset
);

export default getMosAsset;
