import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '238px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },
  icon: {
    width: '20px',
    height: '20px',
    margin: '0 22px 0',
  },
  text: {
    ...theme.typography.dina.listItemLabelMedium,
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    color: theme.palette.dina.highEmphasis,
  },
}));

export default useStyles;
