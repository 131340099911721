import gql from 'graphql-tag';

export default gql`
  mutation CreateStoryInstance($input: CreateInstanceInput) {
    createInstance(input: $input) {
      mId
      mRefId
      mTitle
      mState
      mType
      mUpdatedAt
      mCreatedAt
      mPublishingAt
      mStoryId
      locked
      mThumbnailKey
      mThumbnailUrl(linearVariant: rundown)
      mContentKey
      mDefaultContentKey
      mDescription
      mAssignedMembers {
        mId
        mType
      }
      mMetaData {
        key
        value
      }
      mProperties {
        __typename
        ... on PlatformType {
          platform
          provider {
            requestId
            updateEndpoint
            embeddedEndpoint
            state
            message
          }
          account {
            accountUrl
            accountLogo
            accountTitle
            accountId
            accountRefId
            orderType
          }
        }
      }
    }
  }
`;
