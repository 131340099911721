import { reduce, isString } from 'lodash';
import withSelection from '../components/selectedElement/utils/withSelection';
import withBorderSelection from '../components/focusedElement/utils/withBorderSelection';
import List from '../components/list';
import Heading from '../components/heading';
import Paragraph from '../components/paragraph';
import Content from '../components/content';
import Quote from '../components/quote';
import Link from '../components/link';
import HorizontalRule from '../components/horizontalRule';
import Video from '../components/video';
import Image from '../components/image';
import PrimaryAutomation from '../components/primaryAutomation';
import SecondaryAutomation from '../components/secondaryAutomation';
import Placeholder from '../components/placeholder';
import Mention from '../components/mention';
import Description from '../components/description';
import Gif from '../components/gif';
import Info from '../components/info';
import Tags from '../components/tags';
import Title from '../components/title';
import Privacy from '../components/privacy';
import TweetThread from '../components/tweetThread';
import Media from '../components/media';
import FactBox from '../components/factBox';
import { elementTypes } from './types';

const {
  HEADING_FOUR,
  HEADING_ONE,
  HEADING_THREE,
  HEADING_TWO,
  HORIZONTAL_RULE,
  IMAGE,
  LINK,
  LIST_ITEM,
  MENTION,
  ORDERED_LIST,
  PARAGRAPH,
  CONTENT,
  PLACEHOLDER,
  primaryTypes,
  QUOTE,
  secondaryTypes,
  UNORDERED_LIST,
  VIDEO,
  DESCRIPTION,
  TWEET_THREAD,
  GIF,
  INFO,
  TAGS,
  TITLE,
  PRIVACY,
  MEDIA,
  FACT,
} = elementTypes;

const primaryAutomationComponents = reduce(
  primaryTypes,
  (result, value, key) => {
    if (isString(value)) return { ...result, [value]: PrimaryAutomation };

    return result;
  },
  {},
);

const secondaryAutomationComponents = reduce(
  secondaryTypes,
  (result, value, key) => {
    if (isString(value)) return { ...result, [value]: SecondaryAutomation };

    return result;
  },
  {},
);

const elementComponents = {
  [HEADING_FOUR]: withSelection(Heading),
  [HEADING_ONE]: withSelection(Heading),
  [HEADING_THREE]: withSelection(Heading),
  [HEADING_TWO]: withSelection(Heading),
  [HORIZONTAL_RULE]: withSelection(HorizontalRule),
  [IMAGE]: withSelection(Image),
  [LINK]: Link,
  [LIST_ITEM]: List,
  [MENTION]: Mention,
  [ORDERED_LIST]: withSelection(List),
  [PARAGRAPH]: withSelection(Paragraph),
  [CONTENT]: withSelection(Content),
  [PLACEHOLDER]: withSelection(Placeholder),
  [QUOTE]: withSelection(Quote),
  [UNORDERED_LIST]: withSelection(List),
  [INFO]: withSelection(Info),
  [VIDEO]: withSelection(Video),
  [TWEET_THREAD]: withBorderSelection(TweetThread),
  [DESCRIPTION]: withSelection(Description),
  [GIF]: withSelection(Gif),
  [TAGS]: withSelection(Tags),
  [TITLE]: withSelection(Title),
  [PRIVACY]: withSelection(Privacy),
  [MEDIA]: withSelection(Media),
  [FACT]: withSelection(FactBox),
  ...primaryAutomationComponents,
  ...secondaryAutomationComponents,
};

export default elementComponents;
