import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => {
  const commonStyle = {
    ...theme.typography.dina.captionSmall,
    color: theme.palette.dina.highEmphasis,
  };
  return {
    root: {
      borderRadius: '2px',
      height: '16px',
      '&:hover': {
        transform: 'scale(1.1)',
        transition: 'transform 200ms ease-out',
      },
      '&:active': {
        transform: 'scale(0.9)',
        transition: 'transform 200ms ease-out',
      },
    },
    input: {
      padding: '2px 4px',
    },
    empty: {
      ...commonStyle,
      color: theme.palette.dina.mediumEmphasis,
      backgroundColor: theme.palette.dina.blackHoverOverlay,
    },
    priorityBreaking: {
      ...commonStyle,
      backgroundColor: theme.palette.dina.prioBreaking,
      '&:hover': {
        backgroundColor: theme.palette.dina.prioBreaking,
      },
    },
    priority1: {
      ...commonStyle,
      backgroundColor: theme.palette.dina.prio1,
      '&:hover': {
        backgroundColor: theme.palette.dina.prio1,
      },
    },
    priority2: {
      ...commonStyle,
      backgroundColor: theme.palette.dina.prio2,
      '&:hover': {
        backgroundColor: theme.palette.dina.prio2,
      },
    },
    priority3: {
      ...commonStyle,
      backgroundColor: theme.palette.dina.prio3,
      '&:hover': {
        backgroundColor: theme.palette.dina.prio3,
      },
    },
  };
});

export default useStyles;
