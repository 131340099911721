import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { sortBy, groupBy } from 'lodash';
import useApolloSubscription from 'hooks/useApolloSubscription';
import UPDATE_DAY_VIEW_SUBSCRIPTION from 'graphql/subscriptions/updateDayView';
import UPDATE_PLAN_STATUS_VIEW_SUBSCRIPTION from 'graphql/subscriptions/updatePlanStatusView';
import GET_ALL_MEMBERS_BY_PUBLISHING_DATE from 'graphql/queries/getAllMembersByPublishingDate';
import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'graphql/queries/getRundownByPublishingDate';
import UPDATE_RUNDOWN_HOUR_VIEW_SUBSCRIPTION from
  'graphql/subscriptions/updateRundownHourViewSubscription';
import UserContext from 'contexts/UserContext';
import LoadingIndicator from 'components/loadingIndicator';
import useGetPlatforms from 'hooks/useGetPlatforms';
import useFuseSearch from 'hooks/useFuseSearch';
import useOpenStory from 'hooks/useOpenStory';
import getDates from 'utils/getDates';
import frontendFiltering from '../../utils/frontendFiltering';
import getUniqueFilters from '../../utils/getUniqueFilters';
import HourView from './hour-view';
import updateCache from '../day/updateCache';
import updateCacheHourViewInstance from '../status/updateCache';

const HourContainer = ({
  time,
  timeVariant,
  showStory,
  showPitch,
  onlyShowUsersItems,
  filters,
  selectedFilter,
}) => {
  const { startDate, endDate } = getDates(time, timeVariant);
  const { data, error, loading } = useQuery(GET_ALL_MEMBERS_BY_PUBLISHING_DATE, {
    variables: {
      storyInput: {
        mType: 'story',
        startDate,
        endDate,
      },
      pitchInput: {
        mType: 'pitch',
        startDate,
        endDate,
      },
      instanceInput: {
        mType: 'instance',
        startDate,
        endDate,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const publishingDate = startDate.split("T")[0];
  const [subscribe, unSubscribe] = useApolloSubscription(UPDATE_DAY_VIEW_SUBSCRIPTION, {
    variables: {
      publishingDate,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      console.log("subscription data for hour view:", subscriptionData);
      const story = subscriptionData.data.updateDayViewSubscription;
      updateCache(client, story, startDate, endDate);
    },
  });

  const planStatusViewDate = startDate.split('T')[0];
  const [subscribeInstance, unSubscribeInstance] =
    useApolloSubscription(UPDATE_PLAN_STATUS_VIEW_SUBSCRIPTION, {
      variables: {
        planStatusViewDate,
      },
      onSubscriptionData: ({ client, subscriptionData }) => {
        console.log('subscription data hour view for instances:', subscriptionData);
        const instance = subscriptionData.data.updatePlanStatusViewSubscription;
        updateCacheHourViewInstance(client, instance, startDate, endDate);
      },
    });

  console.log("mPublishingAt in hour view:", publishingDate);
  const [subscribeRundown, unsubscribeRundown] =
    useApolloSubscription(UPDATE_RUNDOWN_HOUR_VIEW_SUBSCRIPTION, {
      variables: {
        rundownPublishingDate: publishingDate,
      },
      onSubscriptionData: ({ client, subscriptionData }) => {
        console.log('subscription data rundown update in hour view', subscriptionData);
      },
    });

  useEffect(() => {
    subscribe();
    subscribeInstance();
    subscribeRundown();
    return () => {
      unSubscribe();
      unSubscribeInstance();
      unsubscribeRundown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const { data: rundownData, error: rundownError, loading: rundownLoading } = useQuery(
    GET_RUNDOWNS_BY_PUBLISHING_DATE,
    {
      variables: {
        input: {
          mType: 'rundown',
          startDate,
          endDate,
        },
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  const [platforms, platformsLoadingError, platformsLoading] = useGetPlatforms(time);

  const search = useFuseSearch();
  const openStory = useOpenStory();
  const user = useContext(UserContext);
  const { mId: userId } = user;

  if (error || platformsLoadingError || rundownError) return null;
  if (loading || platformsLoading || rundownLoading) return <LoadingIndicator />;

  const { story, pitch, instance } = data;
  const { getRundownsByPublishingDate } = rundownData;

  const collection = [];
  if (showStory) collection.push([...story]);
  if (showPitch) collection.push([...pitch]);
  const allStories = collection.flat();

  const usersItems = onlyShowUsersItems
    ? allStories.filter(allStory => allStory.mAssignedMembers.find(member => member.mId === userId))
    : allStories;

  const storyInstances = instance.filter(ins => usersItems.find(sto => sto.mId === ins.mStoryId));
  const usersInstances = onlyShowUsersItems
    ? storyInstances.filter(({ mAssignedMembers }) => {
      return mAssignedMembers ? mAssignedMembers.find(({ mId }) => mId === userId) : false;
    })
    : storyInstances;

  const filterList = getUniqueFilters(filters, selectedFilter);

  const filteredItems = frontendFiltering(usersItems, filterList, search, false);
  const filteredInstances = frontendFiltering(usersInstances, filterList, search, true);

  const groupedInstances = groupBy(filteredInstances, 'mStoryId');
  const sortedStories = sortBy(filteredItems, ['mPriority']);

  return (
    <HourView
      time={time}
      stories={sortedStories}
      instances={groupedInstances}
      platforms={platforms}
      rundowns={getRundownsByPublishingDate}
      openStory={openStory}
    />
  );
};

HourContainer.propTypes = {
  /** A time within the day */
  time: PropTypes.string.isRequired,
  /** Time variant of the view */
  timeVariant: PropTypes.string,
  /** Boolean that shows story */
  showStory: PropTypes.bool,
  /** Boolean that shows pitch */
  showPitch: PropTypes.bool,
  /** Boolean that shows only the items that user is assigned to */
  onlyShowUsersItems: PropTypes.bool,
  /** List of filters from search bar */
  filters: PropTypes.arrayOf(PropTypes.object),
  /** List of selected filters from toolbar */
  selectedFilter: PropTypes.shape({
    label: PropTypes.string,
    expression: PropTypes.arrayOf(PropTypes.object),
  }),
};

HourContainer.defaultProps = {
  timeVariant: 'date',
  showStory: true,
  showPitch: true,
  filters: [],
  onlyShowUsersItems: false,
  selectedFilter: {},
};

export default HourContainer;
