import React, { useContext } from 'react';
import { StoryTabsContext, CurrentTabContext } from 'globalState';
import { BreakingFeedContext } from 'contexts/BreakingFeedContext';
import Tabs from './tabs-view';

const HeaderTabs = () => {
  const [storyTabs] = useContext(StoryTabsContext);
  const [, , tabIndex, setTabIndex] = useContext(CurrentTabContext);
  const [breakingFeedNotifications, clearNotificationCount] = useContext(BreakingFeedContext);

  return (
    <Tabs
      storyTabs={storyTabs}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      breakingFeedNotifications={breakingFeedNotifications}
      clearNotificationCount={clearNotificationCount}
    />
  );
};

export default HeaderTabs;
