import React, { useCallback, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor } from 'slate-react';
import configCtx from 'contexts/configContext';
import DropZone from 'components/editor/components/dropZone';
import getTypeValues from 'components/editor/utils/getTypeValues';
import getDefaultTemplate from 'components/editor/utils/getDefaultTemplate';
import { elementTypes } from 'components/editor/constants/types';
// eslint-disable-next-line max-len
import insertSecondaryAutomationElement from 'components/editor/components/secondaryAutomation/utils/insertSecondaryAutomationElement';

const secondaryTypeValues = getTypeValues(elementTypes.secondaryTypes);

const SecondaryDropZone = ({ children, element }) => {
  const editor = useSlate();
  const { automationTemplateConfigs } = useContext(configCtx);

  const { templates } = automationTemplateConfigs[0]
    ? automationTemplateConfigs[0].templateSets[0]
    : {};

  const onDrop = useCallback(
    ({ type }) => {
      const { variant: templateVariant, name, description } = getDefaultTemplate(type, templates);
      const data = { templateVariant, name, description };

      insertSecondaryAutomationElement(editor, type, data, false);

      ReactEditor.focus(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  return (
    <DropZone accept={secondaryTypeValues} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

SecondaryDropZone.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

SecondaryDropZone.defaultProps = {
  children: null,
  element: {
    children: [],
  },
};

export default memo(SecondaryDropZone);
