import React, { useRef, useEffect } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import useStyles from './current-date-indicator-styles';

const CurrentDateIndicator = ({
  date,
  dateFormat,
  index,
  newXScaleRef,
  margin,
  sticky,
  setShowStickyTick,
  stickyTickWidth,
  setStickyTickWidth,
}) => {
  const groupRef = useRef(null);
  const textRef = useRef(null);
  const textWidth = useRef(null);
  const classes = useStyles();

  const translateX = newXScaleRef.current
    ? newXScaleRef.current(date) + margin
    : null;

  const init = () => {
    const { width } = textRef.current.getBBox();
    textWidth.current = width;

    if (sticky) {
      setStickyTickWidth(width);
    }

    return () => {
      if (!sticky) setShowStickyTick(true);
    };
  };

  const update = () => {
    if (!sticky && index === 0) {
      const offset = stickyTickWidth || textWidth.current;

      if (translateX <= offset + margin) setShowStickyTick(false);
      else if (translateX > offset + margin) setShowStickyTick(true);
    }
  };

  useEffect(init, []);
  useEffect(update);

  return (
    translateX && (
      <g
        ref={groupRef}
        className={classes.root}
        transform={`translate(${sticky ? margin : translateX},0)`}
      >
        <text ref={textRef} x={0} y={15} fill="currentColor">
          {format(date, dateFormat)}
        </text>
      </g>
    )
  );
};

CurrentDateIndicator.propTypes = {
  /** Defines the date to show */
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  /** Defines the string format for the date */
  dateFormat: PropTypes.string.isRequired,
  /** Defines the index of the rendered date on date row */
  index: PropTypes.number,
  /** Specifies the current d3 time scale */
  newXScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  /** Specifies the margin for the timeline grid */
  margin: PropTypes.number.isRequired,
  /** If true, the indicator sticks to the left */
  sticky: PropTypes.bool,
  /** Callback to be invoked to toggle stick tick */
  setShowStickyTick: PropTypes.func.isRequired,
  /** Defines the width of the stick tick label */
  stickyTickWidth: PropTypes.number.isRequired,
  /** Callback to be invoked to set the width of the sticky tick */
  setStickyTickWidth: PropTypes.func.isRequired,
};

CurrentDateIndicator.defaultProps = {
  index: null,
  sticky: false,
};

export default CurrentDateIndicator;
