import React from 'react';
import PropTypes from 'prop-types';
import Autolinker from 'autolinker';
import useHighLightWords from 'hooks/useHighlightWords';
import { Typography } from '@material-ui/core';

const Content = props => {
  const { classes, mContent, mDescription, searchWords } = props;
  const highlight = useHighLightWords();

  const createMarkup = () => {
    return {
      __html: `${
        mDescription && mDescription !== 'null'
          ? highlight(Autolinker.link(mDescription), searchWords)
          : ''
      } ${
        mContent && mContent !== 'null' ? highlight(Autolinker.link(mContent), searchWords) : ''
      }`,
    };
  };

  return <Typography className={classes.content} dangerouslySetInnerHTML={createMarkup()} />;
};

Content.propTypes = {
  mContent: PropTypes.string,
  mDescription: PropTypes.string,
  searchWords: PropTypes.arrayOf(PropTypes.string),
};

Content.defaultProps = {
  mContent: '',
  mDescription: '',
  searchWords: [],
};

export default Content;
