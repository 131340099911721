import { useContext } from 'react';
import { CurrentTabContext } from 'globalState';
import { useScrollIntoView } from 'contexts/ScrollIntoViewContext';
import memberTypes from 'graphql/memberTypes';

const useOpenStory = () => {
  const [, setCurrentTab] = useContext(CurrentTabContext);
  const [, setCurrentClicked] = useScrollIntoView();
  const openStory = async (title, id, page = memberTypes.STORY, scrollId = null) => {
    await setCurrentTab({
      page,
      title,
      id,
    });
    if (scrollId) await setCurrentClicked(scrollId);
  };
  return openStory;
};

export default useOpenStory;
