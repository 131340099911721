import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/popover';
import StoryBox from 'screens/storybox';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import memberTypes from 'graphql/memberTypes';
import fallbackImage from 'assets/images/storyCard/StoryCardFallback.png';
import { groupBy, flatMap } from 'lodash';
import { scaleLinear } from 'd3';
import Instances from '../instances';
import Card from '../../../card';
import useStyles from './storyCard-styles';

const StoryCard = ({
  id,
  priorityLevel,
  image,
  categories,
  title,
  isPitch,
  openStory,
  assignees,
  onPriorityChange,
  instances,
  platforms,
  xScaleRef,
  margin,
}) => {
  const storyRowHeight = '48px';
  const classes = useStyles({ isPitch });
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);
  const page = isPitch ? memberTypes.PITCH : memberTypes.STORY;

  const handleStoryOpen = () => {
    openStory(title, id, page);
  };

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const [handleClick, handleDoubleClick] = useMouseClickEvents(handleOpenPopover, handleStoryOpen);

  const handleClose = () => {
    setOpenPopover(false);
  };

  const onInstanceClick = (sId, instanceId) => {
    handleClose();
    openStory(title, id, page, instanceId);
  };

  const groupedInstances = groupBy(instances, 'mPublishingAt');

  const instancesWithOffset = xScaleRef.current
    ? flatMap(groupedInstances, group =>
        group.map(({ mPublishingAt, ...rest }, index, array) => {
          const yScale = scaleLinear().domain([0, array.length - 1]).range([0, 48 / 2]);

          const offset = yScale(index);
          return { offset, mPublishingAt, ...rest };
        }),
      )
    : [];

  return (
    <div className={classes.root}>
      <div className={classes.card} ref={anchorRef}>
        <div className={classes.cardRoot}>
          <Card
            {...{
              handleClick,
              handleDoubleClick,
              categories,
              isPitch,
              image,
              priorityLevel,
              onPriorityChange,
              assignees,
              title,
            }}
            titleLineClamp={1}
          />
        </div>
        <svg width="100%" height={storyRowHeight} className={classes.svg}>
          {instancesWithOffset.map(instance => {
            const { mId, mPublishingAt, offset } = instance;
            const platform = platforms.find(
              item => item.mProperties.platform === instance.mProperties.platform,
            );

            return (
              <Instances
                key={mId}
                {...{
                  offset,
                  mPublishingAt,
                  platform,
                  xScaleRef,
                  margin,
                }}
              />
            );
          })}
        </svg>
      </div>
      <Popover
        onClose={handleClose}
        anchorEl={openPopover ? anchorRef.current : null}
        position="right"
        noMargin
      >
        <div className={classes.storyBox}>
          <StoryBox
            storySpec={{ id, image }}
            onClose={handleClose}
            onOpenStory={handleStoryOpen}
            onInstanceClick={onInstanceClick}
          />
        </div>
      </Popover>
    </div>
  );
};

StoryCard.propTypes = {
  /** mId of the story */
  id: PropTypes.string,
  /** Defines the priority */
  priorityLevel: PropTypes.string,
  /** Story title */
  title: PropTypes.string,
  /** the image of the story */
  image: PropTypes.string,
  /** the categories selected for the story */
  categories: PropTypes.arrayOf(PropTypes.any),
  /** Callback to invoke when opening a story */
  openStory: PropTypes.func,
  /** boolean that indicates pitch */
  isPitch: PropTypes.bool,
  /** list of users the story/pitch is assigned to */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      /** name of assignee */
      mTitle: PropTypes.string,
      /** avatar image of assignee */
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** callback to be invoked when priority is changed */
  onPriorityChange: PropTypes.func,
  /** publishing platforms of instances */
  platforms: PropTypes.arrayOf(PropTypes.object),
  /** Specifies the current d3 time scale */
  xScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  /** list od instances for a story */
  instances: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mPublishingAt: PropTypes.string,
    }),
  ),
};

StoryCard.defaultProps = {
  id: '',
  priorityLevel: '',
  title: '',
  image: fallbackImage,
  categories: [],
  openStory: () => {},
  isPitch: false,
  assignees: [],
  onPriorityChange: () => {},
  platforms: [],
  instances: [],
};

export default StoryCard;
