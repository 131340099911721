import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: 24,
    height: 24,
    borderRadius: 4,
  },
  image: {
    width: '100%',
    height: '100%',
    cursor: 'grab',
  },
}));

export default useStyles;
