import gql from 'graphql-tag';

export default gql`
  query GetStoryForStoryBox($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mDescription
      mAvatarKey
      mThumbnailKey
      mContentKey
      mCreatedAt
      mUpdatedAt
      mState
      mPublishingAt
      mMetaData {
        key
        value
      }
      mCategories {
        label
        score
        categoryId
      }
    }
  }
`;
