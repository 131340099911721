import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './ContainerBoxStyles';

const ContainerBox = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...props}>
      {children}
    </div>
  );
};

ContainerBox.propTypes = {
  children: PropTypes.node,
};

ContainerBox.defaultProps = {
  children: null,
};

export default ContainerBox;
