import { differenceInMilliseconds } from 'date-fns';
import { zoom, event } from 'd3';

/* eslint-disable no-param-reassign */

/**
 * @typedef {Object} zoomControllerProperties
 * @property {Object} startDate Defines the start of the timeline
 * @property {Object} endDate Defines the end of the timeline
 * @property {number} width Specifies the width of the timeline
 * @property {number} height Specifies the height of the timeline
 * @property {Object} zoomingRef Determines if zoom controller is being changed or not
 * @property {Object} kRef Determines the zoom factor of the zoom controller
 * @property {Function} onZoomChange Callback to be invoked on zoom change
 */

/**
 * Creates zoomability on timelance
 *
 * @param {zoomControllerProperties}
 * @returns {Object} D3 zoom instance
 */

const createZoomBehavior = ({
  startDate,
  endDate,
  width,
  height,
  zoomingRef,
  kRef,
  onZoomChange,
}) => {
  const tenMinutes = 10 * 1000 * 60;
  const zoomMax = differenceInMilliseconds(endDate, startDate) / tenMinutes;

  const zoomStart = () => {
    zoomingRef.current = true;
  };

  const zoomed = () => {
    const { transform } = event;

    kRef.current = transform.k;

    onZoomChange(transform);
  };

  const zoomEnd = () => {
    zoomingRef.current = false;
  };

  const zoomInstance = zoom()
    .scaleExtent([1, zoomMax])
    .translateExtent([[0, 0], [width, height]])
    .extent([[0, 0], [width, height]])
    .on('start', zoomStart)
    .on('zoom', zoomed)
    .on('end', zoomEnd);

  return zoomInstance;
};

export default createZoomBehavior;
