import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SplitPane from 'react-split-pane';
import useStyles from './split-pane-styles';

const minSize = 100;

const SplitPaneView = ({
  TimelineComponent,
  UnscheduledInstancesComponent,
  ...rest
}) => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const [timelineSize, setTimelineSize] = useState(null);

  const init = () => {
    let resizeObserver;

    if (rootRef.current) {
      const element = rootRef.current;

      if (window.ResizeObserver) {
        resizeObserver = new ResizeObserver(() => {
          const { width } = element.getBoundingClientRect();
          setTimelineSize(width - minSize);
        });

        resizeObserver.observe(element);
      } else {
        const { width } = element.getBoundingClientRect();
        setTimelineSize(width - minSize);
      }
    }

    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  };

  useEffect(init, []);

  return (
    <div ref={rootRef}>
      {timelineSize && (
        <SplitPane
          className={classes.root}
          split="vertical"
          primary="second"
          {...{ minSize }}
        >
          <TimelineComponent {...{ timelineSize }} {...rest} />
          <UnscheduledInstancesComponent {...rest} />
        </SplitPane>
      )}
    </div>
  );
};

SplitPaneView.propTypes = {
  /** Defines the timeline component to be rendered on the left pane */
  TimelineComponent: PropTypes.elementType.isRequired,
  /** Defines the unscheduled instances  component to be rendered on the right pane  */
  UnscheduledInstancesComponent: PropTypes.elementType.isRequired,
};

export default SplitPaneView;
