import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import useStyles from './tooltip-styles';

const Tooltip = ({ children, title, noArrow, ...rest }) => {
  const classes = useStyles();

  return (
    <MaterialTooltip
      arrow={!noArrow}
      title={title}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      {...rest}
    >
      {children}
    </MaterialTooltip>
  );
};

Tooltip.propTypes = {
  /** children must be React component/div/button etc, react functional
   * component won't work, in this case wrap it around a div
   */
  children: PropTypes.node,
  /** value to show on tooltip, if empty string, nothing will be shown */
  title: PropTypes.string,
};

Tooltip.defaultProps = {
  children: null,
  title: '',
};

export default Tooltip;
