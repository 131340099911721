import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@material-ui/core';
import Avatar from 'components/avatar/Avatar';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close_small.svg';
import useStyles from './chip-styles';

const Chip = props => {
  const {
    avatarUrl,
    variant,
    onDelete,
    height,
    width,
    label,
    showAvatar,
    ...rest
  } = props;
  const classes = useStyles();

  const initial = label ? label[0].toUpperCase() : '';
  const avatar = avatarUrl ? (
    <Avatar src={avatarUrl} variant={variant} size={24} />
  ) : (
    <Avatar size={24} variant={variant}>
      {initial}
    </Avatar>
  );

  return (
    <div className={classes.root} style={{ height, width }} {...rest}>
      {showAvatar && <div className={classes.avatarDiv}>{avatar}</div>}
      <Typography
        className={`${!showAvatar ? classes.noAvatar : ''} ${
          classes.chipLabel
        }`}
      >
        {label}
      </Typography>
      <IconButton classes={{ root: classes.button }} onClick={onDelete}>
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
    </div>
  );
};

Chip.propTypes = {
  /** Avatar image url */
  avatarUrl: PropTypes.string,
  /** Label of the chip */
  label: PropTypes.string,
  /** onDelete click function */
  onDelete: PropTypes.func,
  /** show avatar or not: values- true/false */
  showAvatar: PropTypes.bool,
  /** width of the chip */
  width: PropTypes.number,
  /** height of the chip */
  height: PropTypes.number,
};

Chip.defaultProps = {
  avatarUrl: '',
  label: '',
  onDelete: () => {},
  showAvatar: true,
  width: 128,
  height: 32,
};

export default Chip;
