import React, { memo } from 'react';
import FocusedElement from '../view';

const withBorderSelection = Component =>
  memo(props => (
    <FocusedElement {...props}>
      <Component {...props} />
    </FocusedElement>
  ));

export default withBorderSelection;
