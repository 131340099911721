import gql from 'graphql-tag';

export default gql`
  mutation CreateStoryFromPitch($input: CreateStoryFromPitchInput) {
    createStoryFromPitch(input: $input) {
      mId
      mRefId
      mTitle
      mState
      mDescription
      mContent
      mContentKey
      mAvatarUrl
      mThumbnailUrl
      mCreatedAt
      mUpdatedAt
      mPublishingAt
    }
  }
`;
