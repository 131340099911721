import DocSrc from 'assets/icons/systemicons/Doc.svg';
import SoundtrackSrc from 'assets/icons/systemicons/soundtrack.svg';
import PdfSrc from 'assets/icons/systemicons/PDF.svg';
import PptSrc from 'assets/icons/systemicons/PPT.svg';
import TextOffSrc from 'assets/icons/systemicons/text_off.svg';
import AudioSrc from 'assets/icons/systemicons/audio.svg';

const IconImage = {
  '.pdf': PdfSrc,
  '.ppt': PptSrc,
  '.pptx': PptSrc,
  '.doc': DocSrc,
  '.docx': DocSrc,
  '.xlsx': TextOffSrc,
  '.xls': TextOffSrc,
  '.txt': TextOffSrc,
  '.csv': TextOffSrc,
  '.mp3': SoundtrackSrc,
  defaultDoc: TextOffSrc,
  defaultAudio: AudioSrc,
};

export default IconImage;
