import { useMutation } from 'react-apollo';
import GET_RUNDOWN from 'graphql/queries/getRundown';
import MOVE_INSTANCE_TO_RUNDOWN from 'graphql/mutations/moveInstanceToRundown';

const useMoveInstanceToRundown = onInstanceMoved => {
  const [moveInstanceToRundownMutation] = useMutation(MOVE_INSTANCE_TO_RUNDOWN);

  const updateCacheForSourceRundown = (proxy, sourceRundown, mId) => {
    try {
      const originRundown = proxy.readQuery({
        query: GET_RUNDOWN,
        variables: { input: sourceRundown },
      });

      const { mOrder, mPreorder } = originRundown.getRundown;

      proxy.writeQuery({
        query: GET_RUNDOWN,
        variables: { input: sourceRundown },
        data: {
          getRundown: {
            ...originRundown.getRundown,
            mOrder: mOrder.filter(order => order !== mId),
            mPreorder: mPreorder.filter(preOrder => preOrder !== mId),
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const moveInstanceToRundown = async (mId, sourceRundown, destinationRundown, copy) => {
    if (!destinationRundown.mId) return;
    const tRundown = {
      mId: destinationRundown.mId,
      mRefId: destinationRundown.mRefId,
    };

    const input = { mId, targetRundown: tRundown, copy };

    if (sourceRundown) {
      const sRundown = { mId: sourceRundown.mId, mRefId: sourceRundown.mRefId };
      input.sourceRundown = sRundown;
    }

    await moveInstanceToRundownMutation({
      variables: { input },
      update: proxy => {
        !copy && sourceRundown && updateCacheForSourceRundown(proxy, sourceRundown, mId);
        if (onInstanceMoved) onInstanceMoved();
      },
    });
  };

  return [moveInstanceToRundown];
};

export default useMoveInstanceToRundown;
