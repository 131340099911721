import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '652px',
    maxHeight: '320px',
    display: 'flex',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    overflow: 'hidden',
    position: 'relative',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  body: {
    display: 'flex',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    borderRadius: '0 0 0 8px',
  },
  dateSelector: {
    width: '384px',
    height: '272px',
  },
  drawer: {
    position: 'absolute',
    display: 'flex',
    left: '384px',
    minWidth: '24px',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    borderRadius: '0 8px 8px 0',
  },
  selectors: {
    display: 'flex',
    width: ({ openDrawer }) => (openDrawer ? '244px' : 0),
    height: '272px',
    overflow: 'hidden',
    transition: 'width 200ms ease-in-out',
    transform: 'translateZ(0)',
  },
  monthSelector: {
    maxHeight: '272px',
    width: '148px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  yearSelector: {
    width: '96px',
    maxHeight: '272px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  button: {
    maxWidth: '24px',
    minWidth: '24px',
    height: '320px',
    padding: 0,
    borderRadius: '0 8px 8px 0',
    borderLeft: `1px solid ${theme.palette.dina.dividerLight}`,
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    '&:hover': {
      backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    },
  },
}));

export default useStyles;
