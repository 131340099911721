import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_small_off.svg';
import { ReactComponent as VisibilityIcon } from 'assets/icons/systemicons/editor/visibility.svg';
import Preview from '../../../preview';
import DatetimeIndicator from '../datetimeIndicator';
import useStyles from './schedulingButtons-styles';

const SchedulingButtons = ({
  datetime,
  canShowNewDesign,
  isCMS,
  openPublishingSettings,
  id,
  title,
}) => {
  const classes = useStyles();

  const [openPreview, setOpenPreview] = useState(false);

  const openPreviewView = () => setOpenPreview(true);
  const closePreviewView = () => setOpenPreview(false);

  if (datetime)
    return (
      <>
        {canShowNewDesign && isCMS && (
          <div>
            <div role="presentation" onClick={openPreviewView} className={classes.scheduleButton}>
              <VisibilityIcon className={classes.calendarIcon} />
              <div className={classes.schedule}>Preview</div>
            </div>
            <Dialog
              BackdropProps={{ classes: { root: classes.backdropRoot } }}
              open={openPreview}
              onClose={closePreviewView}
              maxWidth="xl"
            >
              <Preview id={id} title={title} closeDialog={closePreviewView} />
            </Dialog>
          </div>
        )}
        <DatetimeIndicator
          {...{ datetime }}
          format="MMM D / HH:mm"
          onClick={openPublishingSettings}
        />
      </>
    );

  return (
    <div role="presentation" onClick={openPublishingSettings} className={classes.scheduleButton}>
      <CalendarOff className={classes.calendarIcon} />
      <div className={classes.schedule}>Schedule...</div>
    </div>
  );
};

export default SchedulingButtons;
