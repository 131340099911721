import React, { useEffect } from 'react';
import { useMutation, useApolloClient } from 'react-apollo';
import UPDATE_RUNDOWN_SYNC from 'graphql/mutations/updateRundownSync';
import UPDATE_INSTANCE from 'graphql/mutations/updateInstance';
import GET_INSTANCE_METADATA_FROM_CACHE from 'graphql/queries/getInstanceMetadataFromCache';
import BATCH_UPDATE_INSTANCES from 'graphql/mutations/batchUpdateInstance';
import { useLoadingIndicator } from 'contexts/LoadingContext';
import getMetadataWithMosTag from 'utils/metadata/addMostag';
import respectHostReadSpeed from 'screens/rundown/utils/respectHostReadSpeed';
import useUpdateInstanceMetaData from 'hooks/useUpdateInstanceMetadata';
import ListActionContainer from './list-action-container';

const isOrderIncluded = key => key.includes('order');
const isReadSpeedIncluded = key => key.includes('hostReadSpeed');

const ListContainer = props => {
  const {
    mid,
    refId,
    selecteddate,
    data,
    type,
    form,
    hostReadSpeed,
    mOrder,
    batchInstanceUpdateNeeded,
    setBatchInstanceUpdateNeeded,
    ...rest
  } = props;

  const [batchUpdateInstances] = useMutation(BATCH_UPDATE_INSTANCES);
  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);
  const [updateInstanceMutation] = useMutation(UPDATE_INSTANCE);
  const { setLoadingIndicatorFor } = useLoadingIndicator();
  const [saveMetaData] = useUpdateInstanceMetaData();
  const client = useApolloClient();
  const formFields = (form && form.fields) || [];

  const readInstanceFromCache = mId =>
    client.readFragment({
      id: mId,
      fragment: GET_INSTANCE_METADATA_FROM_CACHE,
    });

  const updateInstanceMetaData = async (instanceId, mMetaData) => {
    await saveMetaData(instanceId, mMetaData, 'no-cache');
  };

  const saveRunDown = payload => {
    const variables = {
      input: { mId: mid, mRefId: refId },
    };
    const payloads = [];

    payloads.push(payload);
    variables.input.mPayload = payloads; // [order] = getUniqueIds(newOrder);
    updateRundownSync({
      variables,
      optimisticResponse: false,
    }).catch(e => {
      console.log(e);
    });
  };

  const updateTitle = (instanceId, title) => {
    updateInstanceMutation({
      variables: {
        input: {
          mId: instanceId,
          mTitle: title,
        },
      },
    });
  };

  const createBatchUpdateInput = (metaKey, isOrder, isIncluded) => {
    const filteredMIds = mOrder.filter(mId => mId[0] !== '-');

    const batchUpdateInput = filteredMIds
      .map((mId, index) => {
        const instance = readInstanceFromCache(mId);
        if (instance) {
          const metaValue = isOrder ? index + 1 : hostReadSpeed;

          if (instance.mMetaData === null) {
            return {
              mId: instance.mId,
              mMetaData: [{ key: metaKey, value: metaValue }],
            };
          }

          if (!instance.mMetaData.find(meta => isIncluded(meta.key)))
            return {
              mId: instance.mId,
              mMetaData: [
                ...instance.mMetaData.map(({ key, value }) => ({ key, value })),
                { key: metaKey, value: metaValue },
              ],
            };
          return {
            mId: instance.mId,
            mMetaData: instance.mMetaData.map(({ key, value }) =>
              isIncluded(key) ? { key, value: metaValue } : { key, value },
            ),
          };
        }
      })
      .filter(Boolean);

    return {
      instances: batchUpdateInput.map(instance => {
        const { mMetaData } = instance;
        const metadata = isOrder ? mMetaData : respectHostReadSpeed(mMetaData, hostReadSpeed);
        return {
          mId: instance.mId,
          mMetaData: getMetadataWithMosTag(formFields, metadata),
        };
      }),
    };
  };

  useEffect(() => {
    if (batchInstanceUpdateNeeded) {
      const input = createBatchUpdateInput('hostReadSpeed', false, isReadSpeedIncluded);
      batchUpdateInstances({ variables: { input } });
      setBatchInstanceUpdateNeeded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchInstanceUpdateNeeded]);

  const resetOrderingOfInstances = async () => {
    const input = createBatchUpdateInput('11-order', true, isOrderIncluded);
    setLoadingIndicatorFor(type);
    await batchUpdateInstances({ variables: { input } });
    setLoadingIndicatorFor(null);
  };

  return (
    <ListActionContainer
      onResetOrdering={resetOrderingOfInstances}
      {...{
        mid,
        refId,
        saveRunDown,
        selecteddate,
        updateInstanceMetaData,
        updateTitle,
        data,
        type,
        form,
        hostReadSpeed,
        mOrder,
      }}
      {...rest}
    />
  );
};

export default ListContainer;
