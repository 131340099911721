import React from 'react';
import PropTypes from 'prop-types';
import dndTypes from 'utils/dndTypes';
import { useDrag } from 'react-dnd';
import { ListItem, Typography } from '@material-ui/core';
import Avatar from 'components/avatar';
import returnState from 'screens/planning/components/status/utils/statusBoards/returnState';
import statusIcons from 'assets/icons/systemicons/status/statusIcon';
import useStyles from './instance-styles';

const Instance = ({
  id,
  title,
  schedule,
  publishingPoint,
  publishingPlatform,
  statusId,
  onClick,
  publishingAt,
  accountId,
  accountRefId,
}) => {
  const classes = useStyles();
  const { icon } = returnState(statusId);
  const iconSrc = statusIcons[`${icon}_small`];

  const getUnscheduledStyle = () => {
    return schedule === 'Unscheduled' ? classes.warning : null;
  };

  const payload = {
    id,
    platform: publishingPoint,
    publishingAt,
    statusId,
    accountId,
    accountRefId,
  };

  const [, drag] = useDrag({
    item: { type: dndTypes.INSTANCE, payload },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const { mProperties } = publishingPlatform;
  const { platformIcon } = mProperties;

  return (
    <ListItem classes={{ root: classes.root }} button disableGutters onClick={onClick} ref={drag}>
      <Avatar
        size={20}
        variant="Publish"
        publishingPoint={platformIcon}
        className={classes.avatar}
      />
      <div className={classes.middleComponents}>
        <Typography classes={{ root: classes.title }} noWrap>
          {title}
        </Typography>
        <Typography classes={{ root: classes.schedule }} className={getUnscheduledStyle()}>
          {schedule}
        </Typography>
      </div>
      <div className={classes.statusComponent}>
        <img src={iconSrc} alt="" />
      </div>
    </ListItem>
  );
};

Instance.propTypes = {
  /** Title of the instance */
  title: PropTypes.string,
  /** Publishing time of instance or rundown title */
  schedule: PropTypes.string,
  /** Publishing point of the instance */
  publishingPoint: PropTypes.string,
  /** Indicates the status of instance */
  statusId: PropTypes.string,
  accountId: PropTypes.string,
  accountRefId: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

Instance.defaultProps = {
  title: null,
  schedule: 'Unscheduled',
  publishingPoint: 'linear',
  statusId: 'todo',
  accountId: '',
  accountRefId: '',
  id: '',
  onClick: () => {},
};

export default Instance;
