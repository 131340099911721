import React, {useState} from 'react';
import {
  Timeline, 
  TimelineItem, 
  TimelineSeparator, 
  TimelineConnector, 
  TimelineContent, 
  TimelineDot} from '@material-ui/lab';
import useStyles from './timeline-styles';
import TimelineEntry from '../timelineEntry';

const renderSeparator = (length, index, classes, selected) => {
  return (
      <TimelineSeparator>
          {index !== 0 && 
            (<TimelineConnector
               classes={{root: `${classes.connectorGap} ${selected === index ? 'selected' : ''}`}} 
              />)}
          {index === 0 && (<span style={{marginTop: '8px'}} />)}
          <TimelineDot 
            classes={{root: `${classes.dot} ${selected === index ? 'selected' : ''}`}}
          />
          <TimelineConnector 
            // eslint-disable-next-line max-len
            classes={{root: `${classes.connector} ${selected === index ? 'selected' : ''} ${index === length - 1 ? 'lastElement' : ''}`}}
          />
        </TimelineSeparator>)
};

const TimelineView = props => {
    const {items, onClick, selectedLeftMenu} = props;
    const classes = useStyles();
    // eslint-disable-next-line max-len
    const [selected, setSelected] = useState(items.findIndex(item => item && selectedLeftMenu && item.timestamp === selectedLeftMenu.timestamp));
    const handleOnClick = (item, index) => {
      setSelected(index);
      onClick(item);
    } 
    return (
        <Timeline>
            {items && items.length > 0 && items.map((item, index) => {
              return (
                
                  <TimelineItem 
                    onClick={() => handleOnClick(item, index)} 
                    classes={{root: `${classes.timeline} ${selected === index ? 'selected' : ''}`}}>
                    {renderSeparator(items.length, index, classes, selected)}
                    <TimelineContent classes={{root: classes.timelineContent}}>
                      <TimelineEntry item={item} index={index} />
                      
                    </TimelineContent>
                  </TimelineItem>
              );
            })}
            </Timeline>
    );
};

export default TimelineView;