import gql from 'graphql-tag';

export default gql`
  query GetStory($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mDescription
      mAvatarUrl
      mAvatarKey
      mThumbnailKey
      mThumbnailUrl
      mContentKey
      mCreatedAt
      mUpdatedAt
      mState
      locked
      mPublishingAt
      mSyncActive
      mMetaData {
        key
        value
      }
      mSyncProviders {
        provider
        mMetaData {
          key
          value
        }
      }
      mCategories {
        label
        score
        categoryId
      }
      mAssignedMembers {
        mId
        mType
      }
    }
  }
`;
