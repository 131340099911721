import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import Avatar from 'components/avatar';
import getInitials from 'utils/getInitials';
import memberTypes from 'graphql/memberTypes';
import Popover from 'components/popover';
import StoryBox from 'screens/storybox';
import { Typography, ListItem } from '@material-ui/core';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import PitchIndicator from '../../pitchIndicator';
import useStyles from './storyCard-styles';

const StoryCard = props => {
  const { id, image, title, isPitch, openStory, assignee } = props;
  const { mTitle, mAvatarUrl } = assignee;
  const classes = useStyles({ isPitch });
  const page = isPitch ? memberTypes.PITCH : memberTypes.STORY;
  const [openPopover, setOpenPopover] = useState(false);
  const anchorRef = useRef(null);
  const handleStoryOpen = () => {
    openStory(title, id, page);
  };

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const [handleClick, handleDoubleClick] = useMouseClickEvents(handleOpenPopover, handleStoryOpen);

  const handleClose = () => {
    setOpenPopover(false);
  };

  const onInstanceClick = (sId, instanceId) => {
    handleClose();
    openStory(title, id, page, instanceId);
  };

  return (
    <div className={classes.root} ref={anchorRef}>
      <ListItem
        button
        disableGutters
        className={classes.list}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
      >
        <div className={classes.leftComponents}>
          {isPitch && <PitchIndicator size={16} className={classes.pitchIndicator} />}
          <img src={image || fallbackImage} alt="" className={classes.image} />

          <div className={classes.title}>
            <Typography classes={{ root: classes.titleText }}>{title}</Typography>
          </div>
        </div>
        <Avatar size={18} src={mAvatarUrl} className={classes.avatar} title={mTitle}>
          {mTitle && (
            <Typography classes={{ root: classes.avatarText }}>{getInitials(mTitle)}</Typography>
          )}
        </Avatar>
      </ListItem>
      <Popover
        onClose={handleClose}
        anchorEl={openPopover ? anchorRef.current : null}
        position="right"
        noMargin
      >
        <div className={classes.storyBox}>
          <StoryBox
            storySpec={{ id, image }}
            dialogHeight="810px"
            onClose={handleClose}
            onOpenStory={handleStoryOpen}
            onInstanceClick={onInstanceClick}
          />
        </div>
      </Popover>
    </div>
  );
};

StoryCard.propTypes = {
  /** mId of the story */
  id: PropTypes.string,
  /** Story title */
  title: PropTypes.string,
  /** Image of the story */
  image: PropTypes.string,
  /** callback to open the story */
  openStory: PropTypes.func,
  /** boolean that indicates a pitch */
  isPitch: PropTypes.bool,
  assignee: PropTypes.shape({
    /** name of assignee */
    mTitle: PropTypes.string,
    /** avatar image of assignee */
    mAvatarUrl: PropTypes.string,
  }),
};

StoryCard.defaultProps = {
  id: '',
  title: '',
  image: fallbackImage,
  openStory: (title, id) => {},
  isPitch: false,
  assignee: {},
};

export default StoryCard;
