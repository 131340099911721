import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import configCtx from 'contexts/configContext';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import dndTypes from 'utils/dndTypes';
import { DropTarget } from 'react-dnd';
import HeaderView from './header-view';

const coverPhotoTarget = {
  drop(props) {
    return { id: props.id };
  },
};

const HeaderContainer = ({ image, id, hovered, connectDropTarget, ...rest }) => {
  const { metadataForms } = useContext(configCtx);
  const form = metadataForms[0];
  const blankMetaData = getEmptyMetadataForForm(form);

  return connectDropTarget(
    <div>
      <HeaderView image={image} id={id} hover={hovered} blankMetaData={blankMetaData} {...rest} />
    </div>,
  );
};

HeaderContainer.propTypes = {
  /** Title */
  title: PropTypes.string,
  /** Background image of the header */
  image: PropTypes.string,
  /** The Id of the story */
  id: PropTypes.string.isRequired,
  /** hovered by drag and drop */
  hovered: PropTypes.bool,
  /** data */
  data: PropTypes.objectOf(PropTypes.object),
};

HeaderContainer.defaultProps = {
  title: '',
  image: '',
  hovered: false,
  data: {},
};

export default DropTarget(dndTypes.MEMBER, coverPhotoTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  hovered: monitor.isOver(),
}))(HeaderContainer);
