import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: 29,
  },
  mainDiv: {
    display: 'flex',
    background: 'rgba(0, 0, 0, 0.08)',
  },
  icon: {
    cursor: 'pointer',
    width: 20,
    height: 20,
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.10)',
      '& path': {
        fillOpacity: 1,
      },
    },
  },
  iconDiv: {
    width: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pseudoDivMeta: {
    maxWidth: 176,
    minWidth: 176,
    height: '100%',
  },
  pseudoDivEllipsis: {
    width: 32,
  },
  buttonRoot: {
    margin: 'auto',
    height: '28px',
    width: '36px',
    borderRadius: '0%',
    padding: '0px',
  },
  titleRoot: {
    ...theme.typography.dina.overline,
    lineHeight: '28px',
    paddingLeft: '3px',
    flex: '1',
  },
  fieldTitle: {
    ...theme.typography.dina.overline,
    lineHeight: '28px',
  },
  divider: {
    backgroundColor: theme.palette.dina.dividerDark,
  },
  fabDiv: {
    height: 3,
    backgroundColor: theme.palette.dina.surfaceToolBarStoryView,
    width: '100%',
    borderRadius: '1px',
    position: 'relative',
    zIndex: 99,
  },
  selectionCount: {
    position: 'absolute',
    zIndex: 100,
    transform: 'translate3d(6px, -9px, 0)',
    width: '21px',
    height: '21px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.dina.highEmphasis,
    justifyContent: 'center',
    borderRadius: '50%',
    background: theme.palette.dina.statusOnFocused,
  },
  addIcon: {
    '& path': {
      fill: theme.palette.dina.iconActive,
      fillOpacity: '1',
    },
  },
}));

export default useStyles;
