import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './NotificationStyles';

const Notification = ({ notificationCount }) => {
  const classes = useStyles({ notificationCount });

  return (
    <div className={classes.root}>
      <div className={classes.text}>{notificationCount}</div>
    </div>
  );
};

Notification.propTypes = {
  notificationCount: PropTypes.number,
};

Notification.defaultProps = {
  notificationCount: null,
};

export default Notification;
