const buttonColors = {
  buttonBackgroundHighEmphasis: '#A269FF',
  buttonBorderMediumEmphasis: 'rgba(255, 255, 255, 0.25)',
  fabBackground: ' #5E728A',
  buttonBackgroundOutlined: 'rgba(255, 255, 255, 0.25)',
  buttonBorderOutlined: 'rgba(255, 255, 255, 0.25)',
  blackInactive: 'rgba(0, 0, 0, 0.54)',
  buttonBackgroundDangerousAction: '#F2453D',
  buttonBackgroundCyan: '#00BCD4',
};

const dividerColors = {
  dividerLight: 'rgba(172, 184, 198, 0.25)',
  dividerDark: 'rgba(0, 0, 0, 0.15)',
  eventBorder: 'rgba(242, 69, 61, 0.65)',
};

const textColors = {
  highEmphasis: '#ffffff',
  mediumEmphasis: 'rgba(255, 255, 255, 0.65)',
  disabled: 'rgba(255, 255, 255, 0.35)',
  blackHighEmphasis: 'rgba(0, 0, 0, 0.87)',
  whiteMediumEmphasis: 'rgba(255, 255, 255, 0.7)',
  watermark: 'rgba(255, 255, 255, 0.25)',
  datePickerHoliday: '#CF8C8C',
};

const statusColors = {
  statusApproved: '#4ECE3D',
  statusOnFocused: '#3997FF',
  statusWarning: '#FF9800',
  statusError: '#F2453D',
  onSelected: '#3997FF',
  onHover: 'brightness(108%)',
  onFocus: '#3997FF',
  onFocusOpacity: 'rgba(57, 151, 255, 0.8)',
  outlineButtonOnHover: 'rgba(255, 255, 255, 0.08)',
  inputBorderResting: 'rgba(172, 184, 198, 0.25)',
  statusOnPress: 'rgb(0, 0, 0)',
  statusOnFocusedDiscreetFill: 'rgba(57, 151, 255, 0.1)',
  selectionControlTrackInactive: 'rgba(141, 155, 174, 0.5)',
  statusOnHover: 'rgba(255, 255, 255, 0.08)',
  storyCardHover: '#424D5B',
  storyTimelineCurrentTimeIndicator: 'rgba(57, 151, 255, 0.35)',
  switchTrackInactive: 'rgba(141, 155, 174, 0.5)',
  gridFloat: 'rgba(174, 105, 255, 0.30)',
  gridFloatHover: 'rgba(174, 105, 255, 0.50)',
  prioBreaking: '#F2453D',
  prio1: '#FF9800',
  prio2: '#4ECE3D',
  prio3: '#A269FF',
  statusStoryMarket: '#07D77F',
  storyMarketOpacity: 'rgba(7, 215, 127, 0.08)',
  backgroundHover: 'rgba(0, 0, 0, 0.12)',
  borderHover: 'rgba(0, 0, 0, 0.16)',
};

const surfaceColors = {
  tooltip: 'rgba(32, 42, 54, 0.8)',
  surfaceAppBackgroundMain: 'linear-gradient(153.23deg, #415063 0%, #5E728A 100%)',
  surfaceAppBackgroundNavLevel1: '#202A36',
  surfaceAppBackgroundNavLevel2: '#323E4D',
  surfaceCard: '#516379',
  surfaceCardDark: '#323E4D',
  surfaceDialogs: '#323E4D',
  whiteHoverOverlay: 'rgba(255, 255, 255, 0.08)',
  blackHoverOverlay: 'rgba(0, 0, 0, 0.08)',
  blackMediumEmphasis: 'rgba(0, 0, 0, 0.7)',
  paletteAccentCyan: '#00BCD4',
  paletteAccentCyanOpacity: 'rgba(0, 188, 212, 0.35)',
  paletteAccentOrange: '#FF9800',
  paletteAccentRed: '#F2453D',
  paletteAccentPurple: '#A269FF',
  storyTimelineBackground: 'rgba(63, 75, 94, 0.25)',
  storyTimelineBackgroundNoBackdrop: 'rgba(63, 75, 94, 0.8)',
  storyTimelineTickDefault: 'rgba(255, 255, 255, 0.08)',
  thumbGraphicsBackground: 'rgba(50, 62, 77, 0.75)',
  kanbanInProgress: '#BE95FF',
  kanbanTodo: '#FE81A0',
  kanbanReady: '#64D983',
  kanbanInReview: '#FFC372',
  kanbanPublished: '#73BCFF',
  kanbanArchived: '#787878',
  kanbanTodoCardBackground: '#6B677F',
  kanbanInProgressCardBackground: '#616A8D',
  kanbanInReviewCardBackground: '#747678',
  kanbanReadyCardBackground: '#56807B',
  kanbanPublishedCardBackground: '#56708D',
  kanbanInProgressGradientStart: '#BE95FF ',
  kanbanTodoGradientStart: '#FE81A0',
  kanbanReadyGradientStart: '#A8FF73',
  kanbanInReviewGradientStart: '#FFC372',
  kanbanPublishedGradientStart: '#64BDD9',
  kanbanArchivedGradientStart: '#595959',
  kanbanInProgressGradientEnd: 'rgba(190, 149, 255, 0)',
  kanbanTodoGradientEnd: 'rgba(254, 129, 160, 0)',
  kanbanReadyGradientEnd: 'rgba(115, 255, 154,  0)',
  kanbanInReviewGradientEnd: 'rgba(255, 195, 114, 0)',
  kanbanPublishedGradientEnd: 'rgba(100, 189, 217, 0)',
  kanbanArchivedGradientEnd: 'rgba(100, 100, 100, 0)',
  surfaceToolBarStoryView: '#3997FF',
  chipBackground: 'rgba(94, 114, 136, 0.5)',
  inputBackground: 'rgba(0, 0, 0, 0.08)',
  inputBackgroundFocused: 'rgba(57, 151, 255, 0.15)',
  tableHeader: 'rgba(75,91,111,1)',
  primary700: '#415063',
  backgroundResting: 'rgba(0, 0, 0, 0.08)',
  scheduleEventBackground: 'rgba(242, 69, 61, 0.2)',
};

const iconColors = {
  iconActive: '#FFFFFF',
  iconInactive: 'rgba(255, 255, 255, 0.54)',
  iconSelectedWhite: '#fff',
  iconDisabled: 'rgba(255, 255, 255, 0.25)',
  timelineCamera: '#A0DBAF',
  timelineVideoClip: '#89B6F9',
  timelineLive: '#E459CE',
  timelineGraphic: '#FFBE55',
  timelineBreak: '#E8E8E8',
  timelineSecondaryAccessory: '#ACB3BD',
};

const categoryColors = {
  categoryArtCultureEntertainment: '#9C27B0',
  categoryLegal: '#2196F3',
  categoryDisasters: '#FF5722',
  categoryEconomy: '#3F51B5',
  categoryEducation: '#00BCD4',
  categoryEnvironment: '#4CAF50',
  categoryHealth: '#FFEB3B',
  categoryHumanInterest: '#FFC107',
  categoryLabour: '#673AB7',
  categoryLifestyleLeisure: '#CDDC39',
  categoryPolitics: '#FF9800',
  categoryReligion: '#795548',
  categoryScience: '#009688',
  categorySociety: '#607D8B',
  categorySports: '#E91E63',
  categoryConflictWarPeace: '#9E9E9E',
  categoryWeather: '#8BC34A',
  defaultCategoryColor: 'rgba(255, 255, 255, 0.25)',
};

const colors = {
  ...buttonColors,
  ...dividerColors,
  ...iconColors,
  ...statusColors,
  ...surfaceColors,
  ...textColors,
  ...categoryColors,
};

export default colors;
