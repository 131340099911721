import { makeStyles } from '@material-ui/styles';

const zIndex = 100;
const marginLeft = 16;

const useStyles = makeStyles(theme => ({
  root: {
    zIndex,
    marginLeft: ({ indentLevel = 0 }) => indentLevel * marginLeft,
  },
}));

export default useStyles;
