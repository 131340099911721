import React from 'react';
import PropTypes from 'prop-types';
import { groupBy, flatMap } from 'lodash';
import { scaleLinear } from 'd3';
import { publishingPoints } from 'assets/icons/publishingPoints';
import Instance from './instance';

const Instances = ({
  instances,
  newXScaleRef,
  gridHeight,
  iconSize,
  draggingRef,
  height,
  margin,
  rootRectRef,
  onSchedule,
  width,
}) => {
  const groupedInstances = groupBy(instances, 'start');

  const instancesWithCoordinates = newXScaleRef.current
    ? flatMap(groupedInstances, group =>
        group.map(({ start, ...rest }, index, array) => {
          const translateX = newXScaleRef.current(new Date(start));

          const yScale = scaleLinear()
            .domain([0, array.length - 1])
            .range([-gridHeight / 4, gridHeight / 4]);

          const translateY = yScale(index);

          return { translateX, translateY, start, ...rest };
        }),
      )
    : [];

  return instancesWithCoordinates.map(instance => (
    <Instance
      key={instance.id}
      {...{
        instance,
        gridHeight,
        iconSize,
        draggingRef,
        height,
        margin,
        rootRectRef,
        newXScaleRef,
        onSchedule,
        width,
      }}
    />
  ));
};

Instances.propTypes = {
  /** List of all story instances, scheduled and unscheduled */
  instances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      publishingPoint: PropTypes.oneOf(Object.keys(publishingPoints)),
    }),
  ).isRequired,
  /** Specifies the current d3 time scale */
  newXScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  /** Specifies the height of the timeline grid */
  gridHeight: PropTypes.number.isRequired,
  /** Specifies the size of the instance icons on timeline grid */
  iconSize: PropTypes.number.isRequired,
  /** Determines if the instances are being dragged or not */
  draggingRef: PropTypes.shape({
    current: PropTypes.bool,
  }).isRequired,
  /** Specifies the height of the timeline */
  height: PropTypes.number.isRequired,
  /** Specifies the margin for the timeline grid */
  margin: PropTypes.number.isRequired,
  /** Specifies the dimensions for the root timegrid element */
  rootRectRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  /** Callback to be invoked when an instance is scheduled/re-schduled,
   *  with the newly scheduled instance passed in */
  onSchedule: PropTypes.func.isRequired,
  /** Specifies the width of the timeline */
  width: PropTypes.number.isRequired,
};

export default Instances;
