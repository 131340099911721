import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  rundownContainer: {
    height: '100%',
    // overflowY: 'scroll',
    background: 'linear-gradient(218.14deg, #516379 -3.77%, #415063 100%)',
  },
  paperRoot: {
    height: '100%',
  },
  rundownHeader: {
    width: '100%',
  },
  content: {
    position: 'relative',
    height: 'calc(100% - 112px)',
  },
  editContent: {
    position: 'relative',
    height: 'calc(100vh - 96px - 152px - 42px)',
  },
  editor: {
    position: 'absolute',
    right: 0,
    width: '460px',
    height: 'calc(100% - 32px)',
    margin: '16px',
  },
  editContentEditor: {
    position: 'absolute',
    right: 0,
    width: '460px',
    height: 'calc(100vh - 96px - 168px - 16px - 5px - 32px)',
    margin: '16px',
  },
  divider: {
    backgroundColor: theme.palette.dina.dividerDark,
  },
}));

export default useStyles;
