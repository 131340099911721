import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  listContainer: {
    height: '580px',
  },
  heading: {
    ...theme.typography.dina.marketplaceHeader,
    margin: '16px',
  },
  list: {
    height: 'calc(100% - 50px)',
  },
}));

export default useStyles;
