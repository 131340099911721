import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import useFuseSearch from 'hooks/useFuseSearch';
import GET_FEED from 'graphql/queries/getFeeds';
import UPDATE_FEED from 'graphql/subscriptions/updateFeed';
import FILTER_PREFIX from 'utils/constants/filterPrefix';
import PanelView from './panel-view';

const PanelContainer = props => {
  const { provider, onInspect, filters } = props;
  const search = useFuseSearch();

  const searchString = filters.map(({ expression }) => expression).join(' ');

  const { data, error, loading, subscribeToMore, fetchMore } = useQuery(GET_FEED, {
    variables: {
      input: { provider, searchString },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    let unsubscribe;
    if (!searchString || searchString.length < 1) {
      unsubscribe = subscribeToMore({
        document: UPDATE_FEED,
        variables: { provider },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          if (subscriptionData.data.updateFeedSubscription.provider !== provider) return prev;
          const newFeedItem = subscriptionData.data.updateFeedSubscription;
          const foundIndex = prev.getFeeds.items.findIndex(x => x.mId == newFeedItem.mId);
          if (foundIndex >= 0) {
            const prevItems = prev.getFeeds.items;
            prevItems[foundIndex] = newFeedItem;
            return Object.assign({}, prev, {
              getFeeds: {
                nextToken: prev.getFeeds.nextToken,
                items: [...prevItems],
                __typename: 'FeedsConnection',
              },
            });
          }
          return Object.assign({}, prev, {
            getFeeds: {
              nextToken: prev.getFeeds.nextToken,
              items: [newFeedItem, ...prev.getFeeds.items],
              __typename: 'FeedsConnection',
            },
          });
        },
      });
    }

    // Unsubscribe when searching
    if (unsubscribe) return () => unsubscribe();
  }, [searchString, subscribeToMore, provider]);

  const fetchMoreResults = () => {
    if (!loading && data && data.getFeeds.nextToken) {
      fetchMore({
        variables: { input: { provider, searchString, nextToken: data.getFeeds.nextToken } },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newItems = fetchMoreResult.getFeeds.items;
          return Object.assign({}, prev, {
            getFeeds: {
              nextToken: fetchMoreResult.getFeeds.nextToken,
              items: [...prev.getFeeds.items, ...newItems],
              __typename: 'FeedsConnection',
            },
          });
        },
      });
    }
  };

  if (error) return <div>{error.message}</div>;

  const frontendFiltering = () => {
    let list = data && data.getFeeds && data.getFeeds.items ? data.getFeeds.items : [];
    const { LOCATION, CATEGORY } = FILTER_PREFIX;

    filters.map(({ expression, label }) => {
      if (label && label.startsWith(LOCATION)) {
        list = search(list, ['location'], expression);
      } else if (label && label.startsWith(CATEGORY)) {
        list = search(list, ['section'], expression);
      }
    });

    return list;
  };

  return (
    <PanelView
      items={frontendFiltering()}
      fetchMoreResults={fetchMoreResults}
      nextToken={data && data.getFeeds.nextToken ? data.getFeeds.nextToken : null}
      onInspect={onInspect}
      loading={loading}
    />
  );
};

PanelContainer.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  onInspect: PropTypes.func,
  provider: PropTypes.string,
};

PanelContainer.defaultProps = {
  filters: [],
  onInspect: () => {},
  provider: '',
};

export default PanelContainer;
