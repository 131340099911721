import React, { useState } from 'react';
import Divider from 'components/divider';
import Tabs from 'components/tabs';
import Header from './components/header';
import useStyles from './storybox-styles';

const StoryBox = ({ tabs, smallHeader, ...rest }) => {
  const classes = useStyles({ smallHeader });

  const [tabIndex, setTabIndex] = useState(0);

  const onTabChange = (value, index) => {
    setTabIndex(index);
  };

  return (
    <div className={classes.container}>
      <Header
        {...{ smallHeader, ...rest }}
        tabs={<Tabs scrollButtonRootClass={classes.tabScrollButton} variant="small" {...{ tabs, tabIndex, onTabChange }} />}
      />
      <div className={classes.divider}>
        <Divider />
      </div>
      <div className={smallHeader ? classes.extendContent : classes.content}>
        {tabs[tabIndex].content}
      </div>
    </div>
  );
};

export default StoryBox;
