import React, { createContext, useState } from 'react';

export const SidebarSubTabContext = createContext();

const SidebarSubTabProvider = ({ children }) => {
  const scheduledTypes = ['all', 'scheduled', 'unscheduled'];
  const tabs = ['all', 'scheduled', 'unscheduled', 'archived'];
  const [selectedTab, setSelectedTab] = useState(0);
  const [scheduleType, setScheduleType] = useState(scheduledTypes[0]);

  const setSelectedSubTab = newTab => {
    setSelectedTab(newTab);
    setScheduleType(scheduledTypes[newTab > scheduledTypes.length ? 0 : newTab]);
  };
  return (
    <SidebarSubTabContext.Provider
      value={[{
        subTabs: tabs,
        selectedSubTab: selectedTab,
        setSelectedSubTab,
        scheduleType,
      }]}
    >
      {children}
    </SidebarSubTabContext.Provider>
  );
};

export { SidebarSubTabProvider };
