import React, { useContext, useState } from 'react';
import { debounce, difference } from 'lodash';
import { useMutation } from 'react-apollo';
import UserCtx from 'contexts/UserContext';
import GET_TEAM_MEMBERS from 'graphql/queries/getTeamMembers';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import GET_TEAMS from 'graphql/queries/getTeams';
import UPDATE_MEMBERS from 'graphql/mutations/updateMembers';
import UPDATE_TEAM from 'graphql/mutations/updateTeam';
import memberTypes from 'graphql/memberTypes';
import {
  getMembersOfQuery,
  getMembersOfTypeQuery,
} from 'graphql/queryVariables';
import SummaryCard from 'screens/main/components/rightArea/sidebar/popup-cards';
import InfoTabView from './info-tab-view';

const InfoTabContainer = props => {
  const { teamId, containerRef, teamMembers, teamDepartments } = props;
  const { TEAM_USER, USER, DEPARTMENT, DEPARTMENT_TEAM, TEAM } = memberTypes;
  const [cardContent, setCardContent] = useState(null);

  const [updateTeam] = useMutation(UPDATE_TEAM);
  const user = useContext(UserCtx);
  const [updateMembers] = useMutation(UPDATE_MEMBERS);

  const updateDescription = debounce(newDescription => {
    const input = {
      mId: teamId,
      mDescription: newDescription,
    };

    updateTeam({
      variables: {
        input,
      },
    });
  }, 300);

  const getUpdatedTeamList = (list, team, isCurrentMemberAdded, isCurrentMemberRemoved) => {
    if (isCurrentMemberAdded) {
      list.push({ ...team, mType: TEAM });
      return list;
    }
    if (isCurrentMemberRemoved) {
      return list.filter(t => t.mId !== team.mId)
    }
    return list;
  }

  const updateCurrentUserTeamList = (
    proxy,
    isCurrentMemberAdded,
    isCurrentMemberRemoved,
  ) => {
    try {
      if (!(isCurrentMemberAdded || isCurrentMemberRemoved)) return;

      const teamList = proxy.readQuery({
        query: GET_MEMBERS_OF,
        variables: getMembersOfQuery(user.mId, TEAM_USER),
      });
      const tList = teamList.getMembersOf.filter(
        team => team !== null && team.mId === teamId,
      );
      if (tList.length !== 0 && !isCurrentMemberRemoved) return;

      const teams = proxy.readQuery({
        query: GET_TEAMS,
        variables: getMembersOfTypeQuery(TEAM),
      });

      const team = teams.getMembersOftype.find(t => t.mId === teamId);
      if (!team) return;
      const updatedList = getUpdatedTeamList(teamList.getMembersOf, team,
        isCurrentMemberAdded, isCurrentMemberRemoved);

      proxy.writeQuery({
        query: GET_MEMBERS_OF,
        variables: getMembersOfQuery(user.mId, TEAM_USER),
        data: {
          getMembersOf: updatedList,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addPeopleToTeam = async members => {
    const existingMemberIds = teamMembers.map(({ mId }) => mId);
    const updatedMemberIds = members.map(({ mId }) => mId);

    const removedIds = difference(existingMemberIds, updatedMemberIds);
    const addedIds = difference(updatedMemberIds, existingMemberIds);

    const addMembersInput = addedIds.map(mId => ({
      mId: teamId,
      mRefId: mId,
      mType: TEAM_USER,
    }));

    const newMembers = {
      members: addMembersInput,
    };

    const removedMembersInput = removedIds.map(mId => ({
      mId: teamId,
      mRefId: mId,
    }));

    const removedMembers = {
      members: removedMembersInput,
    };
    const isCurrentMemberAdded = addedIds.includes(user.mId);
    const isCurrentMemberRemoved = removedIds.includes(user.mId);

    try {
      await updateMembers({
        variables: { newMembers, removedMembers },

        update: proxy => {
          const newMembersWithType = members.map(member => ({
            ...member,
            mType: USER,
          }));

          updateCurrentUserTeamList(
            proxy,
            isCurrentMemberAdded,
            isCurrentMemberRemoved
          );

          proxy.writeQuery({
            query: GET_TEAM_MEMBERS,
            variables: {
              input: { mType: TEAM_USER, mId: teamId },
            },
            data: {
              getMembers: newMembersWithType,
            },
          });
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const addDepartmentToTeam = async members => {
    const existingDepartmentIds = teamDepartments.map(({ mId }) => mId);
    const updatedDepartmentIds = members.map(({ mId }) => mId);

    const removedDepartmentIds = difference(
      existingDepartmentIds,
      updatedDepartmentIds
    );
    const addedDepartmentIds = difference(
      updatedDepartmentIds,
      existingDepartmentIds
    );

    const addMembersInput = addedDepartmentIds.map(mId => ({
      mId,
      mRefId: teamId,
      mType: DEPARTMENT_TEAM,
    }));

    const newMembers = {
      members: addMembersInput,
    };

    const removedMembersInput = removedDepartmentIds.map(mId => ({
      mId,
      mRefId: teamId,
    }));

    const removedMembers = {
      members: removedMembersInput,
    };

    try {
      await updateMembers({
        variables: { newMembers, removedMembers },
        update: proxy => {
          const newMembersWithType = members.map(member => ({
            ...member,
            mType: DEPARTMENT,
          }));

          proxy.writeQuery({
            query: GET_MEMBERS_OF,
            variables: getMembersOfQuery(teamId, DEPARTMENT_TEAM),
            data: {
              getMembersOf: newMembersWithType,
            },
          });
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onListItemClick = item => {
    setCardContent(item);
  };

  return (
    <>
      <InfoTabView
        onItemClick={onListItemClick}
        {...props}
        {...{ updateDescription, addPeopleToTeam, addDepartmentToTeam }}
      />
      <SummaryCard item={cardContent} containerRef={containerRef} />
    </>
  );
};

export default InfoTabContainer;
