import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import memberTypes from 'graphql/memberTypes';
import { getMembersOfQuery } from 'graphql/queryVariables';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import SummaryView from '../summary-view';

const ContactContainer = props => {
  const { item, ...rest } = props;
  const [listDetails, setListDetails] = useState([]);
  const { id, description } = item;

  const { data: teamData, loading: teamLoading, error: teamError } = useQuery(
    GET_MEMBERS_OF,
    {
      variables: getMembersOfQuery(id, memberTypes.TEAM_USER),
      fetchPolicy: 'cache-and-network',
    }
  );

  const {
    data: departmentData,
    loading: departmentLoading,
    error: departmentError,
  } = useQuery(GET_MEMBERS_OF, {
    variables: getMembersOfQuery(id, memberTypes.DEPARTMENT_USER),
    fetchPolicy: 'cache-and-network',
  });

  if (teamLoading || departmentLoading) return <LoadingIndicator />;
  if (teamError) return <div> No Team Data. </div>;
  if (departmentError) return <div> No department data </div>;

  const { getMembersOf: getTeams } = teamData;
  const { getMembersOf: getDepartments } = departmentData;

  if (listDetails.length < 1 && getTeams && getDepartments) {
    setListDetails([
      {
        listTitle: 'Teams',
        avatarVariant: 'Team',
        members: getTeams,
      },
      {
        listTitle: 'Departments',
        avatarVariant: 'Department',
        members: getDepartments,
      },
    ]);
  }

  return (
    <SummaryView
      title={item.name}
      avatarUrl={item.image}
      listDetails={listDetails}
      description={description}
      {...rest}
    />
  );
};

export default ContactContainer;
