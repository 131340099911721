import React from 'react';
import { isToday } from 'date-fns';
import { AppBar } from '@material-ui/core';
import useStyles from './toolbar-styles';

const ToolbarView = props => {
  const { children, selecteddate, mType, page } = props;
  const classes = useStyles(mType, !isToday(selecteddate), page === 'story')();
  return (
    <AppBar className={classes.appBar} position="relative">
      <div className={classes.toolbar}>{children}</div>
    </AppBar>
  );
};

export default ToolbarView;
