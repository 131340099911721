import React from 'react';
import PropTypes from 'prop-types';
import { Dialog as MaterialDialog } from '@material-ui/core';
import useStyles from './dialog-styles';

const Dialog = props => {
  const { open, children, container, onClose, ...rest } = props;
  const classes = useStyles();

  return (
    <MaterialDialog
      style={{ position: 'absolute' }}
      classes={{
        root: classes.dialogRoot,
        paper: classes.dialogPaper,
        scrollPaper: classes.dialogScrollPaper,
        paperScrollPaper: classes.paperScrollPaper,
      }}
      BackdropProps={{ classes: { root: classes.backdropRoot } }}
      {...{ open, onClose, container }}
      {...rest}
    >
      {children}
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  /** If true shows the dialog */
  open: PropTypes.bool,
  /** Content to be rendered inside the dialog */
  children: PropTypes.node,
  /** Parent node to which the dialog should attach itself to,
   * if null dialog attaches itself to the html body node
   */
  container: PropTypes.instanceOf(Element),
  /** Callback to be invoked on dialog close */
  onClose: PropTypes.func,
};

Dialog.defaultProps = {
  open: false,
  children: <div />,
  container: null,
  onClose: () => {},
};

export default Dialog;
