import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.dina.storyTimelineCurrentTimeIndicator,
    borderRadius: 2,
    padding: '2px 6px',
  },
}));

export default useStyles;
