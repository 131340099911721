import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants/types';
import useStyles from './styles';

const target = '_blank';

const Link = ({ attributes, children, element }) => {
  const classes = useStyles();
  const { href } = element.data;

  const openInNewTab = useCallback(() => window.open(href, target), [href]);

  return (
    <a
      className={classes.root}
      onClick={openInNewTab}
      rel="noopener noreferrer"
      role="presentation"
      {...attributes}
      {...{ href, target }}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Link.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.LINK,
    data: { href: '' },
    children: [],
  },
};

export default memo(Link);
