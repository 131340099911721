import { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { EditorContext } from 'globalState';
import LOCK_MEMBER from 'graphql/mutations/lockMember';

const useLockMember = () => {
  const [lockMemberMutation] = useMutation(LOCK_MEMBER);
  const [, , , setEditorData] = useContext(EditorContext);

  const lock = async (mId, userId) => {
    const input = {
      mId,
      userId,
    };

    try{
      
      const result = await lockMemberMutation({
        variables: { input },
      });
      
      setEditorData({ mId, locked: userId, mType: 'story' });
      return result;
  }catch(e) {
    console.log(e);
  }



    /* try {
      await releaseLock(mId).then(() => {
        lockMemberMutation({
          variables: { input },
        }).then(() => {
          setEditorData({ mId, locked: userId, mType: 'story' });
        });
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } */
  };

  return [lock];
};

export default useLockMember;
