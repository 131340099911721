import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  listContainer: {
    backgroundColor: theme.palette.dina.surfaceCard,
    width: '360px',
    maxHeight: '784px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    overflowY: 'scroll',
  },
  header: {
    ...theme.typography.dina.h5,
    color: theme.palette.dina.mediumEmphasis,
    padding: '0 0 0 16px',
    verticalAlign: 'center',
  },
  list: {
    paddingLeft: '8px',
    margin: '0 4px 8px 0',
  },
}));

export default useStyles;
