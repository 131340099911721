import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider, Dialog, Fade } from '@material-ui/core';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useDrop } from 'react-dnd';
import dndTypes from 'utils/dndTypes';
import Scrollbar from 'screens/main/components/scrollbar';
import {ReactComponent as Minimize} from 'assets/icons/systemicons/minimize.svg';
import {ReactComponent as Maximize} from 'assets/icons/systemicons/maximize.svg';
import ToggleButton from 'components/buttons/toggleButton/ImageToggleButton';
import UserContext from 'contexts/UserContext';
import { getDefaultStateCollapsed } from 'utils/planningViews';
import SelectRundown from '../selectRundown';
import KanbanCard from '../kanbanCard';
import useStyles from './kanbanCell-styles';

const KanbanCell = props => {
  const {
    cellId,
    instances,
    title,
    color,
    onlyShowUsersItems,
    updateInstance,
    startDate,
    endDate,
    platforms,
    openStory,
    stateCollapsed,
    setStateCollapsed,
  } = props;
  const isCollapsed = Boolean(stateCollapsed[cellId]);
  const [collapse, setCollapse] = useState(isCollapsed);
  const [transitioning, setTransitioning] = useState(false);
  const classes = useStyles(color, collapse, transitioning)();
  const [open, setOpen] = useState(false);
  const [sourceRundown, setSourceRundown] = useState();
  const [droppedId, setDroppedId] = useState();
  const [droppedStatus, setDroppedStatus] = useState();

  const [{ didDrop }, dropRef] = useDrop({
    accept: [dndTypes.INSTANCE],
    drop(item, monitor) {
      if (!didDrop) {
        const { id, platform, statusId, publishingAt, accountId, accountRefId } = item.payload;
        if (platform !== 'linear') {
          updateInstance(id, cellId, publishingAt || new Date().toISOString());
        } else {
          setSourceRundown({ mId: accountId, mRefId: accountRefId });
          setDroppedId(id);
          setDroppedStatus(statusId);
          setOpen(true);
        }
      }
    },
  });
  const user = useContext(UserContext);
  const { mId: userId } = user;

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleToggleButton = () => {
    const newCollapseState = !collapse;
    const newStateCollapsed = { ...stateCollapsed };
    newStateCollapsed[cellId] = newCollapseState;
    setStateCollapsed(newStateCollapsed);
    setCollapse(newCollapseState);
  };

  return (
    <div className={classes.root} ref={dropRef}>
      <div className={classes.columnHeader}>
        <div className={classes.background} />
        <Fade in={!collapse} timeout={200} mountOnEnter unmountOnExit>
          <Typography classes={{ root: classes.title }}>{title}</Typography>
        </Fade>
        <div className={classes.headerRight}>
          <ToggleButton
            imageChecked={<Maximize />}
            imageUnChecked={<Minimize />}
            checked={collapse}
            onClick={handleToggleButton}
          />
        </div>
      </div>
      <Fade in={!collapse} timeout={200} mountOnEnter unmountOnExit>
        <Divider classes={{ root: classes.divider }} />
      </Fade>
     
        <div className={classes.container}>
          <div className={classes.containerBackground} />

          <Droppable droppableId={cellId}>
            {provided => (
               <div className={classes.cardContainerWrapper}>
              <Scrollbar>
              <div
                className={classes.cardContainer}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Fade in={collapse} mountOnEnter unmountOnExit onEnter={()=>setTransitioning(true)} onEntered={()=>setTransitioning(false)} onExit={()=>setTransitioning(true)} onExited={()=>setTransitioning(false)}  timeout={200}>
                  <div className={classes.cellMinimized}>
                    <div className={classes.cellTitleInfo}>
                      <div className={classes.title}>{title}</div>
                    </div>
                    <div className={classes.cellItemInfo}>
                      {instances.length > 0 && (
                        <div
                          className={classes.cellItemInfoText}
                        >{`${instances.length} instances`}</div>
                      )}
                    </div>
                  </div>
                </Fade>

                {instances.map((instance, index) => {
                  const {
                    mId,
                    mThumbnailUrl,
                    mProperties,
                    mTitle,
                    mStoryId,
                    mUsers,
                    mContentKey,
                    mState,
                    mPublishingAt,
                    mMetaData,
                    mDescription,
                  } = instance;
                  const isAssignedToUser = Boolean(mUsers.find(usr => usr.mId === userId));
                  if (onlyShowUsersItems && !isAssignedToUser) return null;
                  const { account, platform } = mProperties;

                  return (
                    <Fade key={mId} in={!collapse} mountOnEnter unmountOnExit timeout={200}>
                      <div className={classes.kanbanCard}>
                        <Draggable draggableId={mId} index={index}>
                          {(draggableProvided, snapshot) => (
                            <div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                            >
                              <KanbanCard
                                index={index}
                                id={mId}
                                image={mThumbnailUrl}
                                destination={account.accountTitle}
                                title={mTitle}
                                storyId={mStoryId}
                                publishingPlatform={platforms.find(
                                  item => item.mProperties.platform === platform,
                                )}
                                color={color}
                                mContentKey={mContentKey}
                                assignees={mUsers}
                                openStory={openStory}
                                state={mState}
                                publishingAt={mPublishingAt}
                                metaData={mMetaData}
                                description={mDescription}
                              />
                            </div>
                          )}
                        </Draggable>
                      </div>
                    </Fade>
                  );
                })}

                {provided.placeholder}
              </div>
              </Scrollbar>
               </div>
            )}
          </Droppable>
        </div>
     
      <Dialog open={open} onClose={handleDialogClose}>
        <SelectRundown
          {...{
            startDate,
            endDate,
            updateInstance,
            sourceRundown,
          }}
          instanceId={droppedId}
          instanceStatus={droppedStatus}
          closeDialog={handleDialogClose}
          statusId={cellId}
        />
      </Dialog>
    </div>
  );
};

KanbanCell.propTypes = {
  /** Unique identifier of a cell for drag and drop */
  cellId: PropTypes.string.isRequired,
  /** Instance list to show on Kanban cell */
  instances: PropTypes.arrayOf(PropTypes.any),
  /** Name of Kanban Cell */
  title: PropTypes.string,
  /** Color of Kanban cell */
  color: PropTypes.string,
  /** boolean that shows only users stories & pitches the user is assigned to */
  onlyShowUsersItems: PropTypes.bool,
  /** callback to be invoked to update linear instance */
  updateInstance: PropTypes.func,
  /** Callback to open story from instance */
  openStory: PropTypes.func,
  /** An object defining the collapse states of kanban cells  */
  stateCollapsed: PropTypes.objectOf(PropTypes.any),
  /** Callback to update state collapsed object */
  setStateCollapsed: PropTypes.func,
};

KanbanCell.defaultProps = {
  instances: [],
  title: '',
  color: 'highEmphasis',
  onlyShowUsersItems: false,
  updateInstance: () => {},
  openStory: () => {},
  stateCollapsed: getDefaultStateCollapsed(),
  setStateCollapsed: () => {},
};

export default KanbanCell;
