import actionTypes from './actionTypes';

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.UPDATE_META:
      return state.map(meta => {
        const newMeta = payload.find(uMeta => uMeta.key === meta.key);
        return newMeta || meta;
      });
    case actionTypes.UPDATE_FLOAT:
      return state.map(meta => (meta.key.includes('isFloat') ? { ...meta, value: payload } : meta));
    case actionTypes.UPDATE_STATE:
      return [...payload];
    default:
      return state;
  }
};

export default reducer;
