import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const HoverScroller = ({
  left,
  height,
  iconSize,
  zoomControllerRef,
  draggingRef,
  direction,
  newXScaleRef,
}) => {
  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);

  const scroll = () => {
    const currentTimeX = newXScaleRef.current(new Date());

    if (draggingRef.current) {
      if (direction === 'left') zoomControllerRef.current.translateLeft();
      if (currentTimeX < 0 && direction === 'right')
        zoomControllerRef.current.translateRight();
    }
  };

  const waitThenScroll = () => {
    timeoutRef.current = setTimeout(() => {
      intervalRef.current = setInterval(scroll);
    }, 100);
  };

  const stopScroll = () => {
    clearInterval(intervalRef.current);
    clearTimeout(timeoutRef.current);
  };

  return (
    <g
      onMouseOver={waitThenScroll}
      onDragEnter={waitThenScroll}
      onMouseOut={stopScroll}
      onDragLeave={stopScroll}
      onFocus={() => null}
      onBlur={() => null}
    >
      <line
        x1={left}
        y1={0}
        x2={left}
        y2={height}
        strokeWidth={iconSize * 2}
        stroke="transparent"
        fill="none"
      />
    </g>
  );
};

HoverScroller.propTypes = {
  /** Defines left position value for the scroller */
  left: PropTypes.number.isRequired,
  /** Defines the height of the scroller */
  height: PropTypes.number.isRequired,
  /** Specifies the size of the instance icons on timeline grid */
  iconSize: PropTypes.number.isRequired,
  /** Specifies the ImperativeHandle for ZoomController component */
  zoomControllerRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  /** Determines if the instances are being dragged or not */
  draggingRef: PropTypes.shape({
    current: PropTypes.bool,
  }).isRequired,
  /** Specifies the direction for the scroller, left or right */
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  /** Specifies the current d3 time scale */
  newXScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
};

export default HoverScroller;
