import React from 'react';
import PropTypes from 'prop-types';
import Container from '../container/Container';
import MaterialAvatar from '../material-avatar';
import useStyles from './styles';

const Team = ({ children, src, ...rest }) => {
  const classes = useStyles(!!src)();

  return (
    <Container {...rest}>
      <MaterialAvatar classes={classes.root} {...{ src }}>
        {src ? null : children}
      </MaterialAvatar>
    </Container>
  );
};

Team.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
};

Team.defaultProps = {
  children: null,
  src: '',
};

export default Team;
