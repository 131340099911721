import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  popover: {
    backgroundColor: theme.palette.dina.surfaceCardDark,
  },
  dialog: {
    minWidth: '300px',
    padding: '16px 12px 8px 14px',
  },
  buttons: {
    display: 'flex',
    marginTop: '8px',
    paddingTop: '8px',
    borderTop: `1px solid ${theme.palette.dina.dividerLight}`,
  },
  cancelButton: {
    color: theme.palette.dina.mediumEmphasis,
  },
  clearButton: {
    width: '100%',
    border: `1px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
  },
}));

export default useStyles;
