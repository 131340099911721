import React from 'react';
import KanbanCell from './kanbanCell';
import useStyles from './status-styles';

const Status = ({
  states,
  data,
  onlyShowUsersItems,
  updateInstance,
  startDate,
  endDate,
  platforms,
  openStory,
  stateCollapsed,
  setStateCollapsed,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {states.map(state => {
        const { id, name, backgroundColor } = state;
        return (
          <KanbanCell
            key={id}
            cellId={id}
            title={name}
            color={backgroundColor}
            className={classes.cell}
            instances={data[id]}
            onlyShowUsersItems={onlyShowUsersItems}
            updateInstance={updateInstance}
            {...{ startDate, endDate, openStory, stateCollapsed, setStateCollapsed }}
            platforms={platforms}
          />
        );
      })}
    </div>
  );
};

export default Status;
