import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CurrentTabContext } from 'globalState';
import memberTypes from 'graphql/memberTypes';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import getTimeZone from 'utils/getTimeZone';
import getRundownPublishingTime from 'utils/getRundownPublishingTime';
import Rundown from './rundown-view';

const RundownContainer = props => {
  const {
    title,
    plan,
    image,
    id,
    difference,
    selectedDates,
    setPreviewRundown,
    timeZone,
    startTime,
    initializeRundown,
    state,
    rundowntype,
    startDate,
  } = props;

  const [currentTab, setCurrentTab] = useContext(CurrentTabContext);
  const activeTab = id === currentTab.id;

  const publishingDate = new Date(selectedDates[0]);

  const selecteddate = getRundownPublishingTime(
    publishingDate,
    startTime,
    timeZone || getTimeZone(),
  );

  const openRundown = (mTitle, mId, date, rundownType) => {
    if (initializeRundown) {
      initializeRundown({ mTitle, mId, selectedDate: date });
      return;
    }

    setCurrentTab({
      page: memberTypes.RUNDOWN,
      title: mTitle,
      id: mId,
      mid: mId,
      selecteddate: date,
      mType: rundownType,
    });
  };

  return (
    <Rundown
      title={title}
      inTime={startTime}
      plan={plan}
      difference={difference}
      image={image || fallbackImage}
      id={id}
      active={activeTab}
      state={state}
      clickHandler={() => openRundown(title, id, startDate, rundowntype)}
      setPreviewRundown={() =>
        setPreviewRundown({
          id,
          date: selecteddate,
          image,
          title,
        })
      }
    />
  );
};

RundownContainer.propTypes = {
  /** Story id */
  id: PropTypes.string.isRequired,
  /** Story title */
  title: PropTypes.string.isRequired,
  /** The startTime in hh:mm:ss format  */
  startTime: PropTypes.string,
  /** plan time */
  plan: PropTypes.string,
  /** difference */
  difference: PropTypes.string,
  /** Url for thumbnail */
  image: PropTypes.string,
  /** the state for the rundown (nostate,ready, active) */
  state: PropTypes.string,
  /** the type of the rundown (rundown or rundowntemplate) */
  rundownType: PropTypes.string,
  /** set the previewRundown */
  setPreviewRundown: PropTypes.func,
  /** the selected dates */
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  /** Start date of the rundown */
  startDate: PropTypes.string,
};

RundownContainer.defaultProps = {
  startTime: '00:00:00',
  plan: '--:--:--',
  difference: '--:--:--',
  image: fallbackImage,
  setPreviewRundown: () => {},
  selectedDates: [],
  state: '',
  rundownType: 'rundown',
  startDate: new Date().toISOString(),
};

export default RundownContainer;
