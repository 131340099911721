import getIndicatorNewPosition from '../../functions/getIndicatorNewPosition';

const resetAnimatedIndicator = (indicatorNode, sliderWrapper, dayWidth) => {
  Object.assign(indicatorNode.style, {
    visibility: 'hidden',
    width: dayWidth,
    left: dayWidth * 3,
  });

  const newItems = Array.from(document.querySelectorAll('.selected-day'));

  const newItemsLength = newItems.length;
  if (newItemsLength > 0) {
    const firstItem = newItems[0];
    const newItemPosition = getIndicatorNewPosition(firstItem, indicatorNode, sliderWrapper);
    const left = newItemPosition + (dayWidth - 40) / 2;
    const width = newItemsLength === 1 ? 40 : newItemsLength * dayWidth - (dayWidth - 40);

    Object.assign(indicatorNode.style, {
      left: `${left}px`,
      width: `${width}px`,
    });
  }
};

export default resetAnimatedIndicator;
