import React from 'react';
import { Divider as MaterialDivider } from '@material-ui/core';
import useStyles from './divider-styles';

const Divider = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <MaterialDivider className={`${classes.root} ${className}`} {...rest} />
  );
};

export default Divider;
