import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '56px',
  },
  button: {
    zIndex: 50,
    margin: 0,
    '&:hover': {
      '& path': {
        'fill-opacity': 1,
      },
    },
    '&:hover $title': {
      color: theme.palette.dina.highEmphasis,
    },
  },
  title: {
    display: 'block',
    paddingTop: '2px',
    color: theme.palette.dina.mediumEmphasis,
  },
}));

export default useStyles;
