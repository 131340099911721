import React from 'react';
import PropTypes from 'prop-types';
import StoryCard from 'components/storyCard';
import useStyles from './header-styles';
import HeaderNav from './header-nav';

const HeaderView = ({
  title,
  timingInfo,
  image,
  isPitch,
  publishingAt,
  assignedUsers,
  noBorderRadius,
  tabs,
  smallHeader,
  isHovered,
  bookmarked,
  onBookmarkClick,
  onScheduleClick,
  ...actions
}) => {
  const classes = useStyles({ noBorderRadius });
  const style = { height: 128 };

  if (smallHeader)
    return (
      <div>
        <HeaderNav {...{ smallHeader, ...actions }} />
        {tabs && tabs}
      </div>
    );

  return (
    <div className={classes.root} {...(tabs && { style })}>
      {image && <img src={image} alt="Background" className={classes.background} />}
      <div className={classes.content}>
        <HeaderNav {...{ smallHeader, ...actions }} />
        <StoryCard
          {...{
            isPitch,
            title,
            timingInfo,
            isHovered,
            image,
            bookmarked,
            onBookmarkClick,
            onScheduleClick,
            publishingAt,
            assignedUsers,
          }}
        />
        {tabs && tabs}
      </div>
    </div>
  );
};

HeaderView.propTypes = {
  /** Title of the story */
  title: PropTypes.string,
  /** Description of the story */
  description: PropTypes.string,
  /** Last Opened */
  timingInfo: PropTypes.string,
  /** no Border Radius */
  noBorderRadius: PropTypes.bool,
  /** tabs component with the header */
  tabs: PropTypes.element,
  /** boolean to get a small header variant */
  smallHeader: PropTypes.bool,
};

HeaderView.defaultProps = {
  title: '',
  description: '',
  timingInfo: '',
  noBorderRadius: false,
  tabs: undefined,
  smallHeader: false,
};
export default HeaderView;
