import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '8px',
    height: '100%',
    backgroundColor: ({ isManual, isGraphic }) =>
      isManual
        ? 'transparent'
        : palette.dina[isGraphic ? 'timelineGraphic' : 'timelineSecondaryAccessory'],
    borderBottomRightRadius: '4px',
    position: 'relative',
  },
  top: {
    height: 'calc(50% - 6px)',
    borderTopRightRadius: '4px',
    boxSizing: 'border-box',
    borderTop: ({ isGraphic }) =>
      `1px solid ${palette.dina[isGraphic ? 'timelineGraphic' : 'timelineSecondaryAccessory']}`,
    borderRight: ({ isGraphic }) =>
      `1px solid ${palette.dina[isGraphic ? 'timelineGraphic' : 'timelineSecondaryAccessory']}`,
  },
  manual: {
    height: '12px',
  },
  itemOut: {
    width: '6px',
    height: '6px',
    position: 'absolute',
    left: 1,
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '0.5px',
  },
  instanceOut: {
    width: '6px',
    height: '6px',
    position: 'absolute',
    left: 1,
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '0.5px',
    boxSizing: 'border-box',
    border: `1px solid ${palette.dina.blackMediumEmphasis}`,
  },
  bottom: {
    height: 'calc(50% - 6px)',
    boxSizing: 'border-box',
    borderBottomRightRadius: '4px',
    borderBottom: ({ isGraphic }) =>
      `1px solid ${palette.dina[isGraphic ? 'timelineGraphic' : 'timelineSecondaryAccessory']}`,
    borderRight: ({ isGraphic }) =>
      `1px solid ${palette.dina[isGraphic ? 'timelineGraphic' : 'timelineSecondaryAccessory']}`,
  },
}));

export default useStyles;
