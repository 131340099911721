import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  pane1Div: {
    width: '100%',
  },
  splitPane: {
    '& .Resizer': {
      boxSizing: 'border-box',
      backgroundClip: 'padding-box',
      zIndex: 11,
      cursor: 'row-resize',
      backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
      '&:before': {
        content: `''`,
        height: '8px',
        boxShadow: `inset 0 1px 0 0 ${theme.palette.dina.dividerDark}`,
        display: 'block',
        width: '100%',
      },
      '&:hover': {
        backgroundColor: ({ dragColor }) => dragColor,
        boxShadow: `inset 0 1px 0 0 ${theme.palette.dina.dividerDark}`,
      },
    },
  },
}));

export default useStyles;
