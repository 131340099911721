import React from 'react';
import { create } from 'jss';
import JssProvider from 'react-jss/lib/JssProvider';
import { ApolloProvider } from 'react-apollo';
import AWSAppSyncClient, { createAppSyncLink } from 'aws-appsync';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createGenerateClassName, jssPreset } from '@material-ui/styles';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import * as localForage from 'localforage';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import ErrorBoundary from 'components/errorBoundary';
import resolveInstanceCache from 'graphql/localResolvers/resolveInstanceCache';
import resolveRundownInstancesCache from 'graphql/localResolvers/resolveRundownInstancesCache';
// this needs to also be installed when working with React
// import { Rehydrated } from 'aws-appsync-react';
import { ContextProvider } from 'globalState/state';

import theme from 'theme/theme';
import AppScreen from 'screens/screens-container';
import { AuthProvider, AuthConsumer } from './contexts/AuthContext';

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    mandatorySignIn: true,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      region: process.env.REACT_APP_AWS_APPSYNC_REGION,
    },
  },
});

const endpoint = process.env.REACT_APP_API_ENDPOINT;
const region = process.env.REACT_APP_AWS_APPSYNC_REGION;

const getUserInfo = async () => {
  const user = await Auth.currentAuthenticatedUser();
  let sub = null;
  let claims = [];
  if (user) {
    const token = user.getSignInUserSession().getIdToken();
    claims = token.payload['cognito:groups'] || [];
    sub = token.payload.sub;
  }

  return {
    sub,
    claims,
  };
};

const appsyncConfig = {
  url: endpoint,
  region,
  auth: {
    type: 'AWS_IAM', // TODO: Read it from config
    credentials: () => Auth.currentCredentials(),
    // jwtToken: getJwtToken,
    // Required when you use Cognito UserPools OR OpenID Connect.
    // token object is obtained previously
  },
  disableOffline: true,
  complexObjectsCredentials: () => Auth.currentCredentials(),
  cacheOptions: {
    dataIdFromObject: obj => {
      // if (obj.mType === 'instance') {
      //   const issubscritpion = obj.crudAction === 'MODIFY' ? 'subscritpion-' : '';
      //   console.log(`${issubscritpion}dataIdFromObject`);
      // }
      const { mId, mRefId, mType, __typename, provider } = obj;
      if (!mId) return null;
      if (mId !== mRefId && mType !== 'config') {
        // console.log('not equal ids', obj);
      }
      if (__typename === 'FeedItem') return `${provider}:${mId}`;
      return mId !== mRefId ? `${mId}:${mRefId}` : mId;
    },

    /* cacheRedirects: {
      Query: {
        instance: (_, args, { getCacheKey }) =>{
          return getCacheKey({ ...args.input , __typename: 'MemberType' })
        }
      },
    }, */
  },

  offlineConfig: {
    storage: localForage,
  },
};

const client = new AWSAppSyncClient(appsyncConfig, {
  /* This is required if we want to get user roles, id  in the velocity velocity templates
     We have to access it by $context.request.headers.claims, $context.request.headers.sub
  */
  link: createAppSyncLink({
    ...appsyncConfig,
    resultsFetcherLink: ApolloLink.from([
      setContext(async (request, previousContext) => {
        const { sub, claims } = await getUserInfo();
        return {
          headers: {
            ...previousContext.headers,
            claims,
            sub,
          },
        };
      }),
      createHttpLink({
        uri: appsyncConfig.url,
      }),
    ]),
  }),
  resolvers: {
    Query: {
      instance: resolveInstanceCache,
      rundownInstances: resolveRundownInstancesCache,
    },
  },
});

const App = () => (
  <ErrorBoundary>
    <ContextProvider>
      <AuthProvider>
        <ApolloProvider client={client}>
          <JssProvider jss={jss} generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AuthConsumer>{context => <AppScreen context={context} />}</AuthConsumer>
            </ThemeProvider>
          </JssProvider>
        </ApolloProvider>
      </AuthProvider>
    </ContextProvider>
  </ErrorBoundary>
);

export default App;
