import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  skeletonDiv: {
    flex: 2,
    width: '100%',
  },
  skeletonRoot: {
    backgroundColor: theme.palette.dina.statusOnHover,
    height: 20,
    width: 'calc(100% - 32px)',
  },
  skeletonText: {
    marginTop: '6px',
    marginBottom: '6px',
  },
}));

export default useStyles;
