/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import variants from 'utils/instance/variants';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import InstanceCard from 'components/instanceCard/index';
import Popover from 'components/popover/Popover';
import CreateNew from 'components/createNew';
import returnState from 'screens/planning/components/status/utils/statusBoards/returnState';
import { stopAllPropagation } from 'components/editors/utils';
import { actionTypes } from 'components/editor';
import generatePlaceholderDefaultTitle from 'utils/generatePlaceholderDefaultTitle';
import { durationTypes, getDuration, getSeconds, getTime, getDurationKey } from './utils';
import respectHostReadSpeed from '../../utils/respectHostReadSpeed';
import useStyles from './editor-styles';

const Editor = ({
  assignees,
  backgroundImage,
  canShowNewDesign,
  canCreateNewTemplate,
  canDeleteTemplate,
  canDeleteTemplateFolder,
  canEditReadyInstance,
  canSeeVersionHistory,
  createAsset,
  createPlaceholder,
  currentDestination,
  editorValue,
  folders,
  form,
  handleUpdateMetadata,
  hasChanges,
  hostReadRate,
  instance,
  isModalOpen,
  isSavingContent,
  loading,
  lockedByUser,
  mPublishingAt,
  onAssigneeUpdate,
  onChange,
  onCreateDuplicate,
  onCreateFolder,
  onCreateNewRundown,
  onDeleteFolder,
  onDeleteInstance,
  onDeleteTemplate,
  onForceUnlock,
  onLockInstance,
  onOpenStory,
  onSaveTemplate,
  onSelectTemplate,
  onStatusChange,
  onTitleUpdate,
  onUnlockInstance,
  onUpdatingRundownInstance,
  readLock,
  releaseWriteLock,
  removePlaceholder,
  setShowDialog,
  setShowMetadata,
  shouldResetEditorSelection,
  showDialog,
  showMetadata,
  templates,
  userId,
  writeLock,
  placeholderFormat,
  versions,
  currentVersionContent,
  refetchVersionList, 
  refetchVersionContent,
  auditListLoading,
  versionContentLoading,
  onRestoreVersion,
  checkVersionRestorability,
}) => {
  const containerRef = useRef();
  const { mId, mTitle, mState, locked, mStoryId } = instance;
  const blankMetaData = getEmptyMetadataForForm(form);
  const [disableEdit, setDisableEdit] = useState(true);
  const editorValueRef = useRef(editorValue);

  const mMetaData =
    !instance.mMetaData || instance.mMetaData.length === 0 ? blankMetaData : instance.mMetaData;

  const { mProperties } = instance;
  const { orderType } = mProperties.account;

  const metadata = respectHostReadSpeed(mMetaData, hostReadRate);
  const [status, setStatus] = useState(returnState(mState || 'todo'));
  const [, setTitle] = useState('Instance title here');
  const [, setPublishingSettingsAnchorEl] = useState(null);
  const [createNewAnchorEl, setCreateNewAnchorEl] = useState(null);
  const [targetRundownTitle, setTargetRundownTitle] = useState('');

  const classes = useStyles({
    dividerColor: status && status.backgroundColor,
  });

  const placeHolderFormatValues = { mTitle, rundown: { mTitle: currentDestination.title } };

  useEffect(() => {
    if (canEditReadyInstance) setDisableEdit(false);
    else setDisableEdit(orderType === 'ready');
  }, [orderType, canEditReadyInstance]);

  const updateStatus = () => setStatus(returnState(mState || 'todo'));

  useEffect(updateStatus, [mId, mState]);

  const handleCreateNewRundown = title => {
    onCreateNewRundown(title);
    setCreateNewAnchorEl(null);
    setShowDialog(false);
  };

  const handleCancelCreateNew = () => {
    setCreateNewAnchorEl(null);
    setShowDialog(false);
  };

  const handleInstanceRundownUpdate = ({ selectedDestination, publishingTime }) => {
    setTargetRundownTitle(selectedDestination.title);
    setCreateNewAnchorEl(containerRef.current);
    const targetRundown = {
      mId: selectedDestination.id,
      mRefId: selectedDestination.id,
      mPublishingAt: selectedDestination.publishingTime,
    };
    const sourceRundown = {
      mId: currentDestination.id,
      mRefId: currentDestination.id,
    };

    if (mId !== '-') onUpdatingRundownInstance(mId, sourceRundown, targetRundown);

    setPublishingSettingsAnchorEl(null);
  };

  const handleTitleChange = value => {
    setTitle(value);
    onTitleUpdate(mId, value);
  };

  const onUpdateMetadata = (newMetadata, cInstance = instance) => {
    handleUpdateMetadata(newMetadata, cInstance);
  };
  
  const findKey = str => {
    return blankMetaData.find(item => getDurationKey(item) === str).key;
  };

  const handleClipDurationChange = newClipDuration => {
    const scriptDuration = getDuration(metadata, durationTypes.SPEAK_DURATION);
    const newTotalDuration = getSeconds(scriptDuration) + getSeconds(newClipDuration);

    const updatedDurations = [
      { key: findKey(durationTypes.TOTAL_DURATION), value: getTime(newTotalDuration) },
      { key: findKey(durationTypes.CLIP_DURATION), value: newClipDuration },
    ];

    onUpdateMetadata(updatedDurations);
  };

  const handleEditorUpdate = useCallback(
    async ({ type, payload }) => {
      const { CHANGE, CREATE_ASSET, CREATE_PLACEHOLDER, REMOVE_PLACEHOLDER } = actionTypes;

      if (type === CREATE_ASSET) {
        const { asset } = payload;
        return createAsset(mStoryId, asset);
      }

      if (type === CREATE_PLACEHOLDER && mStoryId) {
        const { title } = payload;
        return createPlaceholder(title);
      }

      if (type === REMOVE_PLACEHOLDER) {
        const { placeholder } = payload;
        removePlaceholder(placeholder);
      }

      if (type === CHANGE) {
        editorValueRef.current = payload;
        onChange(payload, { ...instance });
      }

      return null;
    },
    [createAsset, createPlaceholder, instance, mStoryId, onChange, removePlaceholder],
  );

  const handleDoneClick = useCallback(() => {
    releaseWriteLock();
  }, [releaseWriteLock]);

  const handleStatusChange = newState => {
    setStatus(returnState(newState));

    if (mId[0] !== '-') onStatusChange(mId, newState);
  };

  const stopPropagation = event => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      stopAllPropagation(event);
    }
  };

  const handleLockInstance = () => {
    onLockInstance(instance.mId, userId);
  };

  const handleUnlockInstance = () => {
    locked === userId && onUnlockInstance(instance.mId);
  };

  const handleSaveTemplate = (...args) => onSaveTemplate(...args, metadata);

  return (
    <div
      className={classes.root}
      ref={containerRef}
      role="presentation"
      onKeyDown={stopPropagation}
    >
      <InstanceCard
        assignees={assignees}
        backgroundImage={backgroundImage}
        canShowNewDesign={canShowNewDesign}
        canCreateNewTemplate={canCreateNewTemplate}
        canDeleteTemplate={canDeleteTemplate}
        canDeleteTemplateFolder={canDeleteTemplateFolder}
        canSeeVersionHistory={canSeeVersionHistory}
        clipDuration={getDuration(metadata, durationTypes.CLIP_DURATION)}
        currentDestination={currentDestination}
        defaultPlaceholderTitle={generatePlaceholderDefaultTitle(
          placeholderFormat,
          editorValueRef.current,
          placeHolderFormatValues,
        )}
        disableEdit={disableEdit}
        folders={folders}
        form={form}
        handleUpdateMetadata={onUpdateMetadata}
        hostReadSpeed={hostReadRate}
        hasChanges={hasChanges}
        instance={instance}
        isSavingContent={isSavingContent}
        key={mId}
        loading={loading}
        lockedByUser={lockedByUser}
        mId={instance ? instance.id : undefined}
        mMetaData={metadata}
        onAssigneeUpdate={onAssigneeUpdate}
        onClipDurationChange={handleClipDurationChange}
        onCreateDuplicate={onCreateDuplicate}
        onCreateFolder={onCreateFolder}
        onDeleteFolder={onDeleteFolder}
        onDeleteInstance={onDeleteInstance}
        onDeleteTemplate={onDeleteTemplate}
        onDone={handleDoneClick}
        onEditorUpdate={handleEditorUpdate}
        onForceUnlock={onForceUnlock}
        onLockInstance={handleLockInstance}
        onOpenStory={onOpenStory}
        onPublishSettingsChange={handleInstanceRundownUpdate}
        onSaveTemplate={handleSaveTemplate}
        onSelectTemplate={onSelectTemplate}
        onStatusChange={handleStatusChange}
        onTitleUpdate={handleTitleChange}
        onUnlockInstance={handleUnlockInstance}
        publishingPoint="linear"
        publishingPointIcon="linear"
        readLock={readLock}
        schedule={mPublishingAt}
        scriptDuration={getDuration(metadata, durationTypes.SPEAK_DURATION)}
        setShowMetadata={setShowMetadata}
        showMetadata={showMetadata}
        statusId={status.id}
        templates={templates}
        title={mTitle}
        totalDuration={getDuration(metadata, durationTypes.TOTAL_DURATION)}
        variant={variants.LINEAR}
        writeLock={writeLock}
        disableCollapse
        // eslint-disable-next-line max-len
        {...{ editorValue, shouldResetEditorSelection, versions, currentVersionContent, refetchVersionList, refetchVersionContent, auditListLoading, versionContentLoading, onRestoreVersion, checkVersionRestorability}}
      />
      <Popover
        onClose={() => setCreateNewAnchorEl(null)}
        anchorEl={showDialog ? createNewAnchorEl : null}
      >
        <CreateNew
          onCancel={handleCancelCreateNew}
          onCreate={handleCreateNewRundown}
          variant="rundown"
          defaultTitle={targetRundownTitle}
        />
      </Popover>
    </div>
  );
};

Editor.propTypes = {
  /** Currently selected instance */
  currentInstance: PropTypes.shape({
    mId: PropTypes.string,
    mContentKey: PropTypes.string,
    mDefaultContentKey: PropTypes.string,
    mTitle: PropTypes.string,
    mState: PropTypes.string,
  }),
  /** Callback to be invoked on rundown selection update,
   * with the selected rundown id passed in
   */
  /** Callback to be invoked when save as template option is clicked */
  onSaveTemplate: PropTypes.func,
  /** Callback to be invoked on template selection,
   * with the selected template passed in
   */
  onSelectTemplate: PropTypes.func,
  /** Callback to be invoked when template deletion is confirmed,
   * with the template to be deleted passed in
   */
  onDeleteTemplate: PropTypes.func,
  /** Callback to be invoked on status change */
  onStatusChange: PropTypes.func,
  /** List of template folders */
  folders: PropTypes.arrayOf(PropTypes.any),
  /** Callback to be invoked while creating new folder */
  onCreateFolder: PropTypes.func,
  /** Callback to be invoked when opening a story */
  onOpenStory: PropTypes.func,
  /** Callback to be invoked while deleting a folder */
  onDeleteFolder: PropTypes.func,
  /** UserId of user */
  userId: PropTypes.string,
  /** Callback to be invoked to lock the instance */
  onLockInstance: PropTypes.func,
  /** Callback to be invoked to unlock the instance */
  onUnlockInstance: PropTypes.func,
  /** Name of the instance's locking user */
  lockedByUser: PropTypes.string,
  /** Boolean that indicates that a modal depending on editor content is open */
  /** Boolean that indicates that user can only read the content */
  readLock: PropTypes.bool,
  /** Boolean that indicates that user can edit the content */
  writeLock: PropTypes.bool,
  /** Callback to reset the readLock */
  releaseWriteLock: PropTypes.func,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  /** boolean that hides delete template from menu */
  canDeleteTemplate: PropTypes.bool,
  /** boolean that hides delete template folder from menu */
  canDeleteTemplateFolder: PropTypes.bool,
  /** Boolean that indicates that user can not edit a ready instance */
  canEditReadyInstance: PropTypes.bool,
};

Editor.defaultProps = {
  currentInstance: {},
  onSaveTemplate: templateName => {},
  onSelectTemplate: template => {},
  onDeleteTemplate: template => {},
  onStatusChange: newState => {},
  folders: [],
  onCreateFolder: () => {},
  onOpenStory: () => {},
  onDeleteFolder: () => {},
  userId: null,
  onLockInstance: () => {},
  onUnlockInstance: () => {},
  readLock: false,
  writeLock: false,
  lockedByUser: undefined,
  releaseWriteLock: () => {},
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canDeleteTemplateFolder: false,
  canEditReadyInstance: false,
};

export default Editor;
