import DragSelect from 'dragselect';
import { isEqual } from 'lodash';
import getIndicatorNewPosition from '../../functions/getIndicatorNewPosition';

const createDragSelect = ({
  sliderContainer,
  sliderWrapper,
  selector,
  hoverIndicatorNode,
  dayWidth,
  setArrowDirection,
  onDaySelect,
}) => {
  let oldx = 0;
  let oldDaysToSelect = [];

  const dragSelect = new DragSelect({
    selectables: sliderContainer.querySelectorAll('.day'),
    area: sliderWrapper,
    selector,
    customStyles: true,
    onDragStart: event => {
      oldx = event.pageX;

      const firstItem = document.querySelector('.ds-hover');

      if (firstItem) {
        const newItemPosition = getIndicatorNewPosition(
          firstItem,
          hoverIndicatorNode,
          sliderWrapper
        );
        const left = newItemPosition + (dayWidth - 40) / 2;

        Object.assign(hoverIndicatorNode.style, {
          left: `${left}px`,
        });
      }
    },
    onDragMove: event => {
      const newItems = Array.from(document.querySelectorAll('.ds-hover'));
      const newItemsLength = newItems.length;

      if (newItemsLength) {
        const firstItem = newItems[0];
        const newItemPosition = getIndicatorNewPosition(
          firstItem,
          hoverIndicatorNode,
          sliderWrapper
        );
        const left = newItemPosition + (dayWidth - 40) / 2;
        const width =
          newItemsLength === 1
            ? 40
            : newItemsLength * dayWidth - (dayWidth - 40);

        Object.assign(hoverIndicatorNode.style, {
          visibility: 'visible',
          left: `${left}px`,
          width: `${width}px`,
          transition: 'left 0.3s ease-out, width 0.3s ease-out',
        });

        if (event.pageX < oldx) {
          setArrowDirection('left');
        } else if (event.pageX > oldx) {
          setArrowDirection('right');
        }
      }
    },
    callback: elements => {
      sliderWrapper.classList.remove('dragging');

      Object.assign(hoverIndicatorNode.style, {
        visibility: 'hidden',
        left: dayWidth * 3,
        width: '40px',
        transition: 'none',
      });

      if (elements.length > 0) {
        const newDays = elements.map(element => Number(element.dataset.id));
        const orderedNewDays = newDays.sort((a, b) => a - b);
        const start = orderedNewDays[0];
        const end = orderedNewDays[elements.length - 1];
        const daysToSelect = new Array(end + 1 - start)
          .fill()
          .map((d, i) => i + start);

        if (!isEqual(daysToSelect, oldDaysToSelect)) {
          oldDaysToSelect = daysToSelect;
          onDaySelect(daysToSelect);
        }
      }
    },
  });

  return dragSelect;
};

export default createDragSelect;
