import { useRef, useCallback, useEffect } from 'react';

const useDebouncedCallback = (callBack, delay = 1000) => {
  const timeoutRef = useRef(null);
  const isSubscribedRef = useRef(true);

  const resetTimeout = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    return () => {
      isSubscribedRef.current = false;
      resetTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedCallback = useCallback(
    (...args) => {
      resetTimeout();

      timeoutRef.current = setTimeout(() => {
        if (isSubscribedRef.current) callBack(...args);
      }, delay);
    },
    [callBack, delay, resetTimeout],
  );

  return [debouncedCallback];
};

export default useDebouncedCallback;
