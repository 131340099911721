import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import InputField from 'components/inputField/InputField';
import useStyles from './LoginFormStyle';

const DefaultLogin = props => {
  const classes = useStyles();
  const defaultUserNameDescription = 'Type your User name here...';
  const defaultpasswordDescription = 'Type your Password here...';
  const errorMessage = 'The Username or Password was incorrect';
  const severityMandatory = 'mandatory';
  const severityError = 'error';

  const { authError, onSubmit, onForgotPassword } = props;
  const [severity, setSeverity] = useState(severityMandatory);
  const [userNameDescription, setUserNameDescription] = useState(
    defaultUserNameDescription
  );
  const [passwordDescription, setPasswordDescription] = useState(
    defaultpasswordDescription
  );
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const setDefaultDescriptionWithSeverity = () => {
    setUserNameDescription(defaultUserNameDescription);
    setPasswordDescription(defaultpasswordDescription);
    setSeverity(severityMandatory);
  };

  useEffect(() => {
    if (authError) {
      setUserNameDescription(errorMessage);
      setPasswordDescription(errorMessage);
      setSeverity(severityError);
    } else {
      setDefaultDescriptionWithSeverity();
    }
  }, [authError]);

  const onPasswordChange = value => {
    if (severity === severityError) {
      setDefaultDescriptionWithSeverity();
    }
    setPassword(value);
  };

  const onUserNameChange = value => {
    if (severity === severityError) {
      setDefaultDescriptionWithSeverity();
    }
    setUserName(value);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(userName, password);
    }
  };

  const handleOnForgotPassword = event => {
    event.preventDefault();
    if (onForgotPassword) {
      onForgotPassword();
    }
  };

  return (
    <>
      <Typography className={classes.textLogin}>Log in</Typography>
      <Typography className={classes.textDina}>
        Welcome to DiNA, Please log in below.
      </Typography>

      <form onSubmit={handleSubmit} data-testid="defaultForm">
        <div className={classes.inputPadding} />
        <InputField
          label="Username"
          description={userNameDescription}
          severity={severity}
          value={userName}
          onChange={value => onUserNameChange(value)}
        />
        <div className={classes.inputPadding} />
        <InputField
          label="Password"
          type="password"
          description={passwordDescription}
          severity={severity}
          value={password}
          onChange={value => onPasswordChange(value)}
        />

        <Button
          type="submit"
          size="large"
          data-testid="btnLogin"
          classes={{
            root: classes.buttonRoot,
            disabled: classes.buttonDisabled,
          }}
          disabled={!(userName && password)}
        >
          LOG IN
        </Button>
        <Typography>
          <Link
            href=""
            className={classes.link}
            onClick={handleOnForgotPassword}
          >
            Forgot your password?
          </Link>
        </Typography>
      </form>
    </>
  );
};

DefaultLogin.propTypes = {
  /* To set if there is any authentication error. Values: true/false */
  authError: PropTypes.bool,

  /**  onSubmit function */
  onSubmit: PropTypes.func,

  /** onForgotPassword function */
  onForgotPassword: PropTypes.func,
};

DefaultLogin.defaultProps = {
  authError: false,
  onSubmit: () => {},
  onForgotPassword: () => {},
};

export default DefaultLogin;
