import { format, isFirstDayOfMonth } from 'date-fns';
import getDateFormat from './getDateFormat';

/**
 * Filters the an array of tickvalues with intented format given current tick interval
 *
 * @param {Object} firstTick Date object for the first tick
 * @param {Object} secondTick Date object for the second tick
 * @param {Object[]} tickValues List of tick value Date objects to be filtered
 * @returns {Object[]} List of filtered tick value Date objects with intended format
 */

const formatTicks = tickValues => {
  const firstTick = tickValues[0];
  const secondTick = tickValues[1];
  const dateFormat = getDateFormat(firstTick, secondTick);

  if (dateFormat === 'MMM YYYY') {
    return tickValues
      .filter(date => isFirstDayOfMonth(date))
      .map(date => ({ date, dateFormat }));
  }

  if (dateFormat === 'MMM DD') {
    return tickValues
      .filter(date => format(date, 'HH:mm') === '00:00')
      .map(date => ({
        date,
        dateFormat,
      }));
  }

  if (dateFormat === 'ddd, MMM DD YYYY') {
    return tickValues
      .filter(date => format(date, 'HH:mm') === '00:00')
      .map(date => ({
        date,
        dateFormat,
      }));
  }

  return [];
};

export default formatTicks;
