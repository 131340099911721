import React, { useState, useEffect } from 'react';
import useShareStory from 'hooks/useShareStory';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import ShareStoryView from './share-story-view';

const ShareStoryContainer = props => {
  const { storydata } = props;
  const { getMember } = storydata;
  const { mId: storyId, mAssignedMembers } = getMember;
  const [skip, setSkip] = useState(true);

  const [assignedUsers, assignedTeams, assignedDepartments, assignedMarkets ] =
                   useGetAssignedMembers(mAssignedMembers);

  useEffect(() => {
    setSkip(true);
  }, [storyId]);

  const [, shareStoryWith] = useShareStory();

  const shareStory = async (members, previousMembers, mType) => {
    shareStoryWith(storyId, members, previousMembers, mType);
  };

  const loadData = () => {
    if (skip) {
      setSkip(!skip);
    }
  };

  return (
    <div>
      <ShareStoryView
        {...props}
        shareStoryWith={shareStory}
        teams={assignedTeams}
        people={assignedUsers}
        markets={assignedMarkets}
        departments={assignedDepartments}
        onOpen={loadData}
      />
    </div>
  );
};

export default ShareStoryContainer;
