import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ArrowRight from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import Avatar from 'components/avatar/Avatar';
import getInitials from 'utils/getInitials';
import useStyles from './member-styles';

const Member = props => {
  const {
    id,
    name,
    title,
    type,
    image,
    imageKey,
    avatarVariant,
    description,
    properties,
    hidePrimaryActionItem,
    secondaryActionItem,
    onClick,
    onSecondaryAction,
  } = props;

  const classes = useStyles();
  const handleClick = () => {
    if (onClick) {
      onClick({
        id,
        name,
        title,
        description,
        image,
        imageKey,
        type,
        properties,
      });
    }
  };

  return (
    <ListItem
      button
      classes={{ button: classes.button, root: classes.listItem }}
      onClick={handleClick}
    >
      <Grid container className={classes.grid}>
        <Grid item className={classes.avatar}>
          <Avatar variant={avatarVariant} src={image}>
            {getInitials(name)}
          </Avatar>
        </Grid>
        <Grid item xs>
          <Typography className={classes.nameLine}>{name}</Typography>
          <Typography className={classes.titleLine}>{title}</Typography>
        </Grid>
        {secondaryActionItem ? (
          <Grid item className={classes.icon} onClick={onSecondaryAction}>
            {secondaryActionItem}
          </Grid>
        ) : (
          <Grid item className={classes.icon} onClick={handleClick}>
            <img src={ArrowRight} alt="arrow icon" />
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};

Member.propTypes = {
  /** Member name */
  name: PropTypes.string.isRequired,
  /** Member title */
  title: PropTypes.string,
  /** Member type */
  type: PropTypes.string,
  /** Url for image */
  image: PropTypes.string,

  /** s3 key of the image */
  imageKey: PropTypes.string,

  /** Avatar Variant  */
  avatarVariant: PropTypes.string,
  /** Component to render to allow secondary actions  */
  secondaryActionItem: PropTypes.node,
  /** Callback to be invoked when item is clicked,
   * with the item details passed in as arguments */
  onClick: PropTypes.func,
  /** Callback to be invoked when ssecondary action item is clicked */
  onSecondaryAction: PropTypes.func,
  /** Hide Primary Action Item if specified */
  hidePrimaryActionItem: PropTypes.bool,
};

Member.defaultProps = {
  title: '',
  image: '',
  imageKey: null,
  type: '',
  avatarVariant: 'People',
  hidePrimaryActionItem: false,
  onClick: () => {},
  secondaryActionItem: null,
  onSecondaryAction: () => {},
};

export default Member;
