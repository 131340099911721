import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    background: 'transparent',
    maxWidth: ({ size }) => (size === 'large' ? 58 : 50),
    border: 'none',
    outline: 'none',
    padding: 0,
    ...theme.typography.dina.listItemLabelMedium,
    lineHeight: '16px',
    letterSpacing: '0.25px',
    color: theme.palette.dina.highEmphasis,
    '&:focus': {
      fontStyle: 'italic',
    },
  },
}));

export default useStyles;
