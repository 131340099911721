import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItem } from '@material-ui/core';
import { distanceInWordsToNow } from 'date-fns';
import ShareStory from 'screens/story/components/toolbar/shareStory/share-story-view';
import CategoryIndicator from 'components/categoryIndicator';
import memberTypes from 'graphql/memberTypes';
import EllipsisButton from 'components/buttons/ellipsisButton';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import StoryImportanceSelect from 'components/storyImportance';
import Groups from 'components/avatarGroup/groups';
import Assignees from 'components/avatarGroup/assignees';
import Divider from 'components/divider';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import Popover from 'components/popover';
import StoryBox from 'screens/storybox';
import capitalize from 'utils/capitalize';
import PitchIndicator from '../../pitchIndicator';
import useStyles from './storyCard-styles';

const StoryCard = props => {
  const {
    id,
    priorityLevel,
    image,
    categories,
    title,
    updatedAt,
    teams,
    departments,
    assignees,
    containerRef,
    shareStoryWith,
    openStory,
    isPitch,
    onPriorityChange,
    ...rest
  } = props;

  const classes = useStyles(isPitch)();
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);
  const page = isPitch ? memberTypes.PITCH : memberTypes.STORY;
  const lastUpdated = capitalize(distanceInWordsToNow(updatedAt, { addSuffix: true }));

  const handleStoryOpen = () => {
    openStory(title, id, page);
  };

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  const [handleClick, handleDoubleClick] = useMouseClickEvents(handleOpenPopover, handleStoryOpen);

  const propagationHandler = event => {
    event.stopPropagation();
  };

  const onInstanceClick = (sId, instanceId) => {
    handleClose();
    openStory(title, id, page, instanceId);
  };

  return (
    <div className={classes.storyCard} ref={anchorRef}>
      <ListItem
        button
        disableGutters
        className={classes.root}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
      >
        <div className={classes.categoryContainer}>
          {categories.map(category => {
            return (
              <CategoryIndicator
                key={category.categoryId}
                category={category}
                className={classes.categoryIndicator}
              />
            );
          })}
        </div>
        <div className={classes.leftComponents}>
          {isPitch && <PitchIndicator size={32} className={classes.pitchIndicator} />}
          <img src={image || fallbackImage} alt="" className={classes.image} />
          <div className={classes.storyContent}>
            <Typography classes={{ root: classes.title }}>{title}</Typography>
            <div className={classes.infoRow}>
              {!isPitch && (
                <StoryImportanceSelect value={priorityLevel || ''} onChange={onPriorityChange} />
              )}
              <Typography classes={{ root: classes.updatedAt }}>Last edit {lastUpdated}</Typography>
            </div>
          </div>
        </div>
        <div className={classes.rightComponents}>
          {assignees.length !== 0 && (
            <>
              <div className={classes.groups}>
                <Assignees assignees={assignees} size={24} />
              </div>
              <Divider orientation="vertical" flexItem className={classes.groups} />
            </>
          )}
          {departments.length !== 0 && (
            <div className={classes.groups}>
              <Groups
                variant="Department"
                onClick={propagationHandler}
                items={departments}
                width={24}
                height={16}
              />
            </div>
          )}
          {teams.length !== 0 && (
            <div className={classes.groups}>
              <Groups variant="Team" onClick={propagationHandler} items={teams} />
            </div>
          )}
          {(departments.length !== 0 || teams.length !== 0) && (
            <Divider orientation="vertical" flexItem className={classes.groups} />
          )}
          <div
            onClick={propagationHandler}
            onKeyPress={propagationHandler}
            role="button"
            tabIndex="0"
          >
            <ShareStory
              containerRef={containerRef}
              isShared={teams.length > 0 || departments.length > 0 || assignees.length > 1}
              people={assignees}
              teams={teams}
              departments={departments}
              shareStoryWith={shareStoryWith}
              {...rest}
            />
          </div>
          <EllipsisButton onClick={propagationHandler} />
        </div>
      </ListItem>
      <Popover
        onClose={handleClose}
        anchorEl={openPopover ? anchorRef.current : null}
        position="center"
        noMargin
      >
        <div className={classes.storyBox}>
          <StoryBox
            storySpec={{ id, image }}
            dialogHeight="810px"
            onClose={handleClose}
            onOpenStory={handleStoryOpen}
            onInstanceClick={onInstanceClick}
          />
        </div>
      </Popover>
    </div>
  );
};

StoryCard.propTypes = {
  /** Defines the priority */
  priorityLevel: PropTypes.string,
  /** Story title */
  title: PropTypes.string,
  /** the image of the story */
  image: PropTypes.string,
  /** the categories selected for the story */
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
  /** Time of the story updated */
  updatedAt: PropTypes.string,
  /** Teams assigned to the story */
  teams: PropTypes.arrayOf(PropTypes.any),
  /** Departments assigned to story */
  departments: PropTypes.arrayOf(PropTypes.any),
  /** List of people assigned to this story */
  assignees: PropTypes.arrayOf(PropTypes.any),
  /** Callback function for share story */
  shareStoryWith: PropTypes.func,
  /** Callback to be invoked when story is clicked */
  openStory: PropTypes.func,
  /** boolean that indicates a pitch */
  isPitch: PropTypes.bool,
  /** id of the story */
  id: PropTypes.string,
  /** Reference to the container element */
  containerRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  /** callback to be invoked when changing priority */
  onPriorityChange: PropTypes.func,
};

StoryCard.defaultProps = {
  priorityLevel: '',
  title: '',
  updatedAt: '',
  image: fallbackImage,
  categories: [],
  teams: [],
  departments: [],
  assignees: [],
  shareStoryWith: () => {},
  openStory: (title, id) => {},
  isPitch: false,
  id: '',
  containerRef: { current: null },
  onPriorityChange: () => {},
};

export default StoryCard;
