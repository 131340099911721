import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    position: 'relative',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    padding: '0 4px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  categoryIndicatorContainer: {
    display: 'flex',
    width: '100%',
    height: '3px',
    marginBottom: '4px',
  },
  categoryIndicator: {
    width: '16px',
    borderRadius: '0 0 1px 1px',
    marginRight: '2px',
    height: '3px',
    overflow: 'hidden',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '16px',
    marginBottom: '4px',
  },
  topLeft: {
    display: 'flex',
  },
  pitchIndicator: {
    marginRight: '2px',
  },
  image: {
    width: '16px',
    height: '16px',
    borderRadius: '2px',
    objectFit: 'cover',
    marginRight: '4px',
  },
  groups: {
    marginLeft: '4px',
  },
  topRight: {
    display: 'flex',
    alignItems: 'center',
  },
  categories: {
    display: 'flex',
    margin: '4px 0',
  },
  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': ({ titleLineClamp }) => titleLineClamp,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  titleText: {
    ...theme.typography.dina.captionRegular,
    fontWeight: '500',
    letterSpacing: '0.25px',
    color: theme.palette.dina.highEmphasis,
  },
}));

export default useStyles;
