/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { inTimingTypes, outTimingTypes } from 'components/editor/constants/types';
import InIcon from './components/inIcon';
import OutIcon from './components/outIcon';
import useStyles from './styles';

const { MANUAL_IN } = inTimingTypes;
const { MANUAL_OUT } = outTimingTypes;

const Box = ({ children, inTimingType, outTimingType, isGraphic }) => {
  const classes = useStyles({ isGraphic });

  return (
    <div className={classes.root}>
      <InIcon {...{ isGraphic, inTimingType }} className={classes.leftEdge} />
      <div className={classes.child}>{children}</div>
      <OutIcon {...{ isGraphic, outTimingType }} className={classes.rightEdge} />
    </div>
  );
};

Box.propTypes = {
  /** Content to show */
  children: PropTypes.node,
  /** Timing type for in */
  inTimingType: PropTypes.oneOf(Object.values(inTimingTypes)),
  /** Specifies if the type is 'OVERLAY_GRAPHICS' */
  isGraphic: PropTypes.bool,
  /** Timing type for out */
  outTimingType: PropTypes.oneOf(Object.values(outTimingTypes)),
};

Box.defaultProps = {
  children: null,
  inTimingType: MANUAL_IN,
  isGraphic: false,
  outTimingType: MANUAL_OUT,
};

export default memo(Box);
