import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import useStyles from './rundown-styles';

const RundownListItem = props => {
  const { title, image, inTime, plan, clickHandler, active } = props;
  const classes = useStyles();

  return (
    <ListItem
      button
      className={active ? classes.activeRundown : ''}
      classes={{ button: classes.button, root: classes.listItem }}
      onClick={clickHandler}
    >
      <Grid container className={classes.grid}>
        <Grid item className={classes.image}>
          <img className={classes.img} alt="complex" src={image} />
        </Grid>
        <Grid item xs>
          <Typography className={classes.title}>{title}</Typography>
          <Grid container justify="flex-start">
            <Grid item xs className={classes.timeItem}>
              <Typography className={classes.timeHeader}>IN</Typography>
              <Typography className={classes.timeValue}>{inTime}</Typography>
            </Grid>
            <Grid item xs className={classes.timeItem}>
              <Typography className={classes.timeHeader}>PLANNED</Typography>
              <Typography className={classes.timeValue}>{plan}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

RundownListItem.propTypes = {
  /** Rundown title */
  title: PropTypes.string.isRequired,
  /** The in time, should be a string and container should take care of date formating */
  inTime: PropTypes.string,
  /** The plan time, should be a string and container should take care of date formating */
  plan: PropTypes.string,
  /** Url for thumbnail image */
  image: PropTypes.string.isRequired,
  /** Click handler function */
  clickHandler: PropTypes.func,
  /** Rundown active or not */
  active: PropTypes.bool,
};

RundownListItem.defaultProps = {
  inTime: '--:--:--',
  plan: '--:--:--',
  clickHandler: () => {},
  active: false,
};

export default RundownListItem;
