import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    padding: 6,
    marginBottom: '8px',
  },
  top: {
    borderRadius: '8px 8px 0px 0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5.5px 7.5px 11px 7.5px',
    alignItems: 'center',
    // background: 'rgba(0, 0, 0, 0.08)',
  },
  user: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarContainer: {
    width: '25px',
    height: '25px',
    borderRadius: '90px',
    display: 'flex',
    background: 'rgba(0, 0, 0, 0.08)',
    justifyContent: 'center',
    alignItems: 'center',
  },

  username: {
    ...theme.typography.dina.caption,
    margin: '-12px  0px 0px 10px',
    color: 'rgba(255, 255, 255, 0.65)',
  },
  updated: {
    ...theme.typography.dina.caption,
    margin: '-12px  0px 0px 0px',
  },
  typeTextContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 0px 0px 24px',
    margin: '-18px  0px 0px 0px',
  },
  typeText: {
    ...theme.typography.dina.caption,
    color: 'rgba(255, 255, 255, 0.54)',
    padding: '0px 0px 2px 5px',
    margin: '-14px  0px 0px 0px',
  },

  messageType: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: '10',
    color: 'red',
  },

  message: {
    ...theme.typography.dina.caption,
    lineHeight: '14px',
    color: 'rgba(255, 255, 255, 0.65)',
    width: '100%',
    padding: '0px 16px 0px 8px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 20,
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'pre',
  },
}));

export default useStyles;
