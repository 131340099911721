import React, { useState, useCallback, memo } from 'react';
import { Popover } from '@material-ui/core';
import { GithubPicker } from 'react-color';
import { useSlate, useFocused } from 'slate-react';
import Tooltip from 'components/tooltip';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import { Editor } from 'slate';
import colors from 'theme/colors';
import { ReactComponent as Color } from 'assets/icons/systemicons/editor/color.svg';
import { markTypes, textColors } from 'components/editor/constants';
import { getMark } from 'components/editor/utils';
import useStyles from './styles';

const type = markTypes.COLOR;
const MemoizedTooltip = memo(Tooltip);

const ColorButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const editor = useSlate();
  const isFocused = useFocused();
  const markColor = getMark(editor, type) || textColors[0];
  const classes = useStyles({ markColor });

  const openPopover = useCallback(({ currentTarget }) => setAnchorEl(currentTarget), []);

  const closePopover = useCallback(() => setAnchorEl(null), []);

  const handleOpenPopover = useCallback(
    event => {
      event.preventDefault();

      if (isFocused) openPopover(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFocused],
  );

  const handleColorChange = useCallback(
    ({ hex }) => {
      closePopover();
      Editor.addMark(editor, type, hex);
    },
    [closePopover, editor],
  );

  return (
    <>
      <MemoizedTooltip title={tooltipEnum.COLOR}>
        <Color className={classes.root} onMouseDown={handleOpenPopover} />
      </MemoizedTooltip>
      <div style={{ position: 'relative' }}>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{ paper: classes.paper }}
        >
          <div className={classes.pickerWrapper}>
            <GithubPicker
              width={162}
              triangle="hide"
              styles={{
                default: {
                  card: {
                    background: colors.surfaceCard,
                  },
                },
              }}
              color={markColor}
              colors={textColors}
              onChangeComplete={handleColorChange}
            />
          </div>
        </Popover>
      </div>
    </>
  );
};

export default ColorButton;
