import React from 'react';
import { useMutation } from 'react-apollo';
import memberTypes from 'graphql/memberTypes';
import CREATE_TEAM from 'graphql/mutations/createTeam';
import GET_TEAMS from 'graphql/queries/getTeams';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import CreateTeam from './create-team';

const CreateTeamContainer = props => {
  const { onSubmit, onCancel } = props;
  const teamType = memberTypes.TEAM;

  const [createTeam] = useMutation(CREATE_TEAM, {
    update: (proxy, mutationResult) => {
      const { createMember } = mutationResult.data;
      // Read storlist from the cache
      try {
        const teamList = proxy.readQuery({
          query: GET_TEAMS,
          variables: getMembersOfTypeQuery(teamType),
        });

        const list = teamList.getMembersOftype.filter(
          team => team !== null && team.mId === createMember.mId
        );
        // Add new story to the storylist if it is not the cache
        if (list.length === 0) {
          teamList.getMembersOftype.push(createMember);
        }
        // Write updated storylist in the cache.
        proxy.writeQuery({
          query: GET_TEAMS,
          variables: getMembersOfTypeQuery(teamType),
          data: teamList,
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const createNewTeam = (title, description) => {
    if (!title) {
      return;
    }

    const input = {
      mTitle: title,
      mType: teamType,
    };

    if (description) {
      input.mDescription = description;
    }

    createTeam({
      variables: {
        input,
      },
    })
      .then(result => {
        if (result.data.createMember) onSubmit(result.data.createMember);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <>
      <CreateTeam onCancel={onCancel} onSubmit={createNewTeam}></CreateTeam>
    </>
  );
};

export default CreateTeamContainer;
