import React from 'react';
import useStyles from './title-styles';

const Title = () => {
  const classes = useStyles();

  return <div className={classes.root}>Unscheduled</div>;
};

export default Title;
