import React from 'react';
import Menu from 'components/menu/Menu';
import MenuItem from 'components/menu/menuItem/MenuItem';

const MenuCreator = props => {
  const { anchorEl, onClose, menuOptions, position } = props;
  
  return (
    <Menu
      position={position}
      anchorEl={anchorEl}
      type="createView"
      onClose={onClose}
    >
      <div>
        {menuOptions.map(option => {
          return (
            <MenuItem
              key={option.action}
              label={option.label}
              onClose={() => onClose({ action: option.action })}
            >
              {option.Icon && <option.Icon />}
            </MenuItem>
          );
        })}
      </div>
    </Menu>
  );
};

export default MenuCreator;
