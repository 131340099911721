import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format, isToday, isTomorrow, isYesterday } from 'date-fns';
import { ClickAwayListener } from '@material-ui/core';
import Popper from 'components/shared/popper';
import Calendar from 'components/calendar';
import useStyles from './timeIndicatorPicker-styles';

const TimeIndictorPicker = props => {
  const { timeVariant, time, onChange, isScheduleView } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handlePopoverOpen = e => {
    setAnchorEl(e.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = newTime => {
    onChange(newTime.toISOString(), timeVariant);
    handlePopoverClose();
  };

  const outputTimeFormat = (displayTime, variant) => {
    if (variant === 'date') return format(displayTime, 'ddd. D MMMM YYYY');
    if (variant === 'week') return format(displayTime, 'MMMM YYYY, [W]eek W');
    if (variant === 'month') return format(displayTime, 'MMMM YYYY');
    if (variant === 'year') return format(displayTime, 'YYYY');
    return null;
  };

  const outputTimeFormatForScheduleView = displayTime => {
    if (isToday(displayTime)) return 'Today';
    if (isTomorrow(displayTime)) return 'Tomorrow';
    if (isYesterday(displayTime)) return 'Yesterday';
    return format(displayTime, 'ddd. D MMMM YYYY');
  };

  return (
    <div className={classes.container}>
      <div
        onClick={handlePopoverOpen}
        onKeyPress={handlePopoverOpen}
        role="button"
        tabIndex="0"
        className={classes.input}
        data-testid="display"
      >
        {isScheduleView
          ? outputTimeFormatForScheduleView(time)
          : outputTimeFormat(time, timeVariant)}
      </div>
      <Popper {...{ anchorEl }}>
        <ClickAwayListener onClickAway={handlePopoverClose}>
          <div className={classes.popover} data-testid="popover">
            <Calendar
              selectedDate={time}
              changeSelectedDate={handleOnChange}
              hideUnscheduleButton
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

TimeIndictorPicker.propTypes = {
  /** Variant of time navigator,
   *  defines time resolution
   */
  timeVariant: PropTypes.oneOf(['date', 'week', 'month', 'year']).isRequired,
  /** Initial time assigned to the components */
  time: PropTypes.string,
  /** Callback to be invoked when navigator's button clicked */
  onChange: PropTypes.func,
  /** To determine if the timeIndicatorPicker is being called from Schedule component or not */
  isScheduleView: PropTypes.bool,
};

TimeIndictorPicker.defaultProps = {
  time: new Date().toISOString(),
  onChange: () => {},
  isScheduleView: false,
};

export default TimeIndictorPicker;
