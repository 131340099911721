import { elementTypes } from 'components/editor/constants/types';
import isHotkey from 'is-hotkey';
import { Editor, Transforms, Range } from 'slate';
import onContentKeyDown from 'components/editor/components/content/utils/onContentKeyDown';
import preventDefaultEvent from 'utils/preventDefaultEvent';
import matchRestriction from './matchRestriction';
import selectElement from './selectElement';

const { nodes, isEmpty, previous, next } = Editor;
const { setNodes, removeNodes, insertText } = Transforms;
const elementTypeValues = Object.values(elementTypes);

/**
 * Handles onKeyDown event on all elements
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} event React synthetic event
 * @returns {Object} SlateJS editor instance
 */

const anchorInPath = (editor, anchor, path) => isEdge => isEdge(editor, anchor, path);

const checkIfStrictEqual = (arr1, arr2) => JSON.stringify(arr1) === JSON.stringify(arr2);

const onElementKeyDown = (editor, event, variant, isAllowed) => {
  try {
    const [match] = nodes(editor, {
      match: ({ type }) => elementTypeValues.includes(type),
    });

    const [element, path] = match;
    const { isVoid } = editor;
    const { key } = event;
    const shouldPrevent = isAllowed && matchRestriction(variant);

    const isContentElement = element.type === elementTypes.CONTENT;
    const isShiftEnter = isHotkey('shift+enter')(event);
    const isEnter = key === 'Enter';
    const isBackspace = key === 'Backspace';
    const isDelete = key === 'Delete';

    const { selection } = editor;

    const cursorAt = anchorInPath(editor, selection.anchor, path);

    if (match) {
      /** handle enter or shift+enter key press */
      if (shouldPrevent && isContentElement) {
        /** on shift+enter press enter a newline on content block */
        if (isShiftEnter || isEnter) {
          preventDefaultEvent(event);
          insertText(editor, '\n');
        }
      }

      /** handle backspace keypress */
      if (isBackspace) {
        const isStart = cursorAt(Editor.isStart);

        if (shouldPrevent) onContentKeyDown(event, isContentElement, isStart);

        const previousNode = previous(editor, { at: path });

        if (!isContentElement && isEmpty(editor, element) && !previousNode)
          setNodes(editor, { type: elementTypes.PARAGRAPH });
      }

      /** handle delete key press */
      if (isDelete) {
        const isEnd = cursorAt(Editor.isEnd);

        if (shouldPrevent) onContentKeyDown(event, isContentElement, isEnd);

        const { isExpanded, isCollapsed, edges } = Range;
        const [nextNode] = next(editor, { at: path }) || [];

        if (selection && isCollapsed(selection) && isEnd && nextNode && isVoid(nextNode)) {
          preventDefaultEvent(event);
          if ((isEmpty(editor, element) || isVoid(element)) && !shouldPrevent) {
            selectElement(editor, nextNode);
            removeNodes(editor, { at: path });
          }
        }

        if (selection && isExpanded(selection)) {
          const [start, end] = edges(selection);
          const { path: startPath } = start;
          const { path: endPath } = end;
          const [startRoot] = startPath;
          const [endRoot] = endPath;

          if (isContentElement && checkIfStrictEqual(startPath, endPath)) {
            Transforms.delete(editor);
          }

          if (startRoot !== endRoot) {
            const selectedNodes = Array.from(nodes(editor, { at: selection }));
            const hasVoids = selectedNodes.some(([node]) => editor.isVoid(node));

            if (hasVoids) removeNodes(editor);
            else Transforms.delete(editor);
          }
        }
      }
    }
  } catch (e) {
    console.error(e);
  }

  return editor;
};

export default onElementKeyDown;
