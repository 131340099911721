import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    border: 'none',
    height: '100%',
    width: '100%',
    paddingBottom: 0,
    paddingTop: 0,
  },

  iframe: {
    border: 'none',
    height: '100%',
    width: '1200px',
  },

  icon: {
    width: '12px',
    height: '12px',
  },
  openStoryBoxButton: {
    position: 'absolute',
    top: '0px',
    left: -8,
    minWidth: '12px',
    maxWidth: '12px',
    height: '14px',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '0 8px 8px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // transition: 'opacity 0.5s ease-in',
    '&:hover': {
      top: '4px',
      left: 0,
      minWidth: '24px',
      maxWidth: '24px',
      height: '24px',
      backgroundColor: theme.palette.dina.fabBackground,
      '& > :first-child': {
        '& > :first-child': {
          '& path': {
            fillOpacity: 1,
          },
        },
      },
    },
    zIndex: 1,
  },
  fadeIn: {
    opacity: 1,
    animationName: '$fadeInOpacity',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in',
    animationDuration: '.5s',
  },
}));

export default useStyles;
