import React from 'react';
import dndTypes from 'utils/dndTypes';
import rundownListTypes from 'screens/rundown/components/utils/rundownListTypes';
import { useDrop } from 'react-dnd';
import GridItem from './grid-item-view';

const GeneralGridItem = ({
  instance,
  showMasterEditView,
  isSelected,
  scrollingId,
  dragged,
  disableEdit,
  thumbnail,
  ...props
}) => {
  const [{ hovered, dragType }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.INSTANCE, dndTypes.GRID_INSTANCE],
    canDrop: (item, monitor) => !disableEdit,
    drop: (item, monitor) => {
      const { type, title, id, publishingAt } = monitor.getItem();
      if (type && type === dndTypes.GRID_INSTANCE) {
        const { payload } = monitor.getItem();
        const result = {
          source: {
            index: payload.index,
            droppableId: payload.scrollingId,
          },
          destination: {
            index: props.index + 1,
            droppableId: scrollingId,
          },
        };
        props.onDragEnd(result);
      } else if (type && type === dndTypes.INSTANCE) {
        const { payload } = monitor.getItem();
        const { id: instanceId, platform } = payload;
        if (platform === 'linear') {
          props.onMoveInstanceToRundown(instanceId, props.index + 1);
        }
      } else if (!props.showMasterEditView) {
        const destType =
          scrollingId === 'readyList' ? rundownListTypes.READY : rundownListTypes.PREPARING;
        props.onCreateInstanceForStory(id, title, publishingAt, props.index + 1, destType);
      }
    },
    collect: monitor => ({
      hovered: monitor.isOver(),
      dragType: monitor.getItemType(),
    }),
  });

  const { mTitle, mId, mThumbnailUrl, ...restOfInstance } = instance;

  const draggedId = dragged && dragged.payload ? dragged.payload.mId : mId;
  const showPlus = dragType !== dndTypes.GRID_INSTANCE;

  return (
    <div ref={dropRef} id={isSelected ? scrollingId : ''}>
      <GridItem
        id={mId}
        mId={mId}
        title={mTitle}
        thumbnail={mThumbnailUrl || thumbnail}
        isSelected={isSelected}
        draggedId={draggedId}
        showPlus={showPlus}
        showMasterEditView={showMasterEditView}
        hovered={showMasterEditView ? false : hovered}
        disableEdit={disableEdit}
        {...restOfInstance}
        {...props}
      />
    </div>
  );
};

export default GeneralGridItem;
