import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => {
  const commonContainerStyle = {
    width: '100%',
    backgroundColor: theme.palette.dina.onSelected,
  };
  const commonStyle = {
    height: '24px',
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  };
  const commonTextStyle = {
    ...theme.typography.dina.listItemGroupHeadersLabel,
    color: theme.palette.dina.mediumEmphasis,
    letterSpacing: '0.25px',
    fontWeight: 'normal',
  };
  return {
    container: {
      width: '40Px',
      height: '20px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    middleContainer: {
      ...commonContainerStyle,
    },
    rightContainer: {
      ...commonContainerStyle,
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px',
    },
    root: {
      ...commonStyle,
    },
    rootToday: {
      ...commonStyle,
      backgroundColor: theme.palette.dina.blackHoverOverlay,
      border: `1px solid ${theme.palette.dina.onSelected}`,
      borderRadius: '12px',
      boxSizing: 'border-box',
    },
    rootSelected: {
      ...commonStyle,
      backgroundColor: theme.palette.dina.onSelected,
      borderRadius: '12px',
    },
    rootSelectedLeft: {
      ...commonStyle,
      backgroundColor: theme.palette.dina.onSelected,
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    rootSelectedMiddle: {
      ...commonStyle,
      backgroundColor: theme.palette.dina.onSelected,
    },
    rootSelectedRight: {
      ...commonStyle,
      backgroundColor: theme.palette.dina.onSelected,
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px',
    },
    rootTodaySelected: {
      ...commonStyle,
      width: '26px',
      height: '26px',
      backgroundColor: theme.palette.dina.blackHoverOverlay,
      border: `1px solid ${theme.palette.dina.onSelected}`,
      borderRadius: '2px',
      transform: 'rotate(-45deg)',
    },
    text: {
      ...commonTextStyle,
    },
    textDimmed: {
      ...commonTextStyle,
      color: theme.palette.dina.disabled,
    },
    textWeekend: {
      ...commonTextStyle,
      color: theme.palette.dina.datePickerHoliday,
    },
    textToday: {
      ...commonTextStyle,
      color: theme.palette.dina.onSelected,
    },
    textTodaySelectedDiamond: {
      ...commonTextStyle,
      color: theme.palette.dina.highEmphasis,
      transform: 'rotate(45deg)',
    },
    textSelected: {
      ...commonTextStyle,
      color: theme.palette.dina.highEmphasis,
    },
  };
});

export default useStyles;
