import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { elementTypes, actionTypes } from 'components/editor/constants/types';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { ReactComponent as HourglassIcon } from 'assets/icons/systemicons/hourglass.svg';
import { ReactComponent as PhotoIcon } from 'assets/icons/systemicons/photo.svg';
import { ReactComponent as DocIcon } from 'assets/icons/systemicons/text_off.svg';
import { ReactComponent as AudioIcon } from 'assets/icons/systemicons/audio.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/systemicons/editor/video_off.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/systemicons/editor/photo_off.svg';
import { menuOptions } from 'components/editor/constants';
import { removeBlock, updateBlock } from 'components/editor/utils';
import AddMedia from '../addMedia';
import AddThumbnails from '../addThumbnails';
import Box from '../box';
import useStyles from './styles';

const icons = {
  video: VideoIcon,
  image: ImageIcon,
  audio: AudioIcon,
  application: DocIcon,
};

const { setNodes } = Transforms;

const Placeholder = ({ attributes, children, element }) => {
  const classes = useStyles();
  const editor = useSlate();
  const { isAllowed, update } = useEditorContext();

  const { data } = element;
  const { showThumbnail, mTitle, mediaType, thumbnails } = data;
  const [type, subType] = mediaType.split('/');

  const removePlaceholder = useCallback(
    async event => {
      event.preventDefault();

      const { showThumbnail: shwThumb, ...placeholder } = element.data;

      await update({
        type: actionTypes.REMOVE_PLACEHOLDER,
        payload: { document: editor.children, placeholder },
      });

      const updatedData = {
        showThumbnail: shwThumb,
        thumbnails,
      };

      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData, type }, { at: path });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const addThumbnails = async response => {
    if (Array.isArray(response)) {
      const updatedData = {
        ...data,
        thumbnails: [...thumbnails, ...response],
      };
      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData }, { at: path });
    }
  };

  const removeThumbnail = useCallback(
    newThumbnails => {
      const updatedData = {
        ...data,
        thumbnails: newThumbnails,
      };

      updateBlock(editor, element, updatedData, update);
    },
    [data, editor, element, update],
  );

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const Icon = icons[type];

  if (!isAllowed)
    return (
      <div {...attributes}>
        {children}

        <div className={classes.root} contentEditable={false}>
          <div className={classes.box}>
            <div className={classes.icon}>
              {subType === 'placeholder' && <HourglassIcon alt="Placeholder" />}
              {subType !== 'placeholder' && type === 'image' && <PhotoIcon alt="image" />}
              {subType !== 'placeholder' && type === 'video' && <VideoIcon alt="video" />}
              {subType !== 'placeholder' && type === 'audio' && <AudioIcon alt="audio" />}
              {subType !== 'placeholder' && type === 'application' && <DocIcon alt="document" />}
            </div>

            <div className={classes.title}>{mTitle}</div>
          </div>
        </div>
      </div>
    );

  return (
    <div {...attributes}>
      {children}
      <Box
        iconComponent={<Icon className={classes.iconComponent} />}
        title={`${type} placeholder`}
        subtitle={mTitle}
        menuItems={menuOptions}
        onMenuSelect={onMenuSelect}
        type="media"
      >
        <AddMedia removePlaceholder={removePlaceholder} mediaWidth={156} hasPlaceholder />
        {showThumbnail && (
          <div className={classes.thumbnail}>
            <p className={classes.thumbnailText}>Add video thumbnails (max. 4)</p>
            <AddThumbnails {...{ thumbnails, removeThumbnail, addThumbnails }} />
          </div>
        )}
      </Box>
    </div>
  );
};

Placeholder.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Placeholder.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.PLACEHOLDER,
    children: [],
  },
};

export default memo(Placeholder);
