import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import Button from 'components/buttons/button';
import Divider from 'components/divider';
import Checkbox from 'components/checkbox';
import useStyles from './confirmationComponent-styles';

const ConfirmationComponent = ({ label, onOk, onCancel }) => {
  const classes = useStyles();
  const [isSelected, setIsSelcted] = useState(false);
  return (
    <div className={classes.dialogContent}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>Delete this item?</DialogTitle>
      <Divider />
      <DialogContent className={classes.body}>
        <div className={classes.checkboxContainer}>
          <FormControlLabel
            value="sendMessage"
            control={
              <Checkbox
                value="isSelected"
                selected={isSelected}
                onClick={() => setIsSelcted(!isSelected)}
                size={24}
              />
            }
            label={`Check this box to confirm you want to delete - ${label}`}
            classes={{
              label: classes.checkBoxText,
              root: classes.checkbox,
            }}
          />

          <div className={classes.labelTextContainer}>
            <Typography classes={{ root: classes.labelText }}>
              Once deleted, the Instance cannot be restored
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          No
        </Button>
        <Button variant="contained" type="danger" onClick={onOk} disabled={isSelected === false}>
          Yes
        </Button>
      </DialogActions>
    </div>
  );
};

ConfirmationComponent.propTypes = {
  /** Name of component */
  label: PropTypes.string,
  /** Callback for yes button click */
  onOk: PropTypes.func,
  /** Callback for no button click */
  onCancel: PropTypes.func,
};

ConfirmationComponent.defaultProps = {
  label: null,
  onOk: () => {},
  onCancel: () => {},
};

export default ConfirmationComponent;
