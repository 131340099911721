import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Divider from 'components/divider';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Scrollbar from 'screens/main/components/scrollbar';
import useStyles from './leftMenu-styles';
import Timeline from './components/timeline';

const LeftMenu = props => {
  const { variant, headerTitle, menuItems, height, onItemClick, selectedLeftMenu, loading } = props;
  const isTimelineEnabled = variant === 'version-history';
  const classes = useStyles({ height });

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.headerTitle}>{headerTitle}</Typography>
        <Divider className={classes.headerDivider} />
      </div>
      <div className={classes.menuContainer}>
        {loading && (<LoadingIndicator />)}
        <Scrollbar>
          {menuItems.map(menu => {
            return (
              <div>
                {!isTimelineEnabled && (<div className={classes.menuItemHeader}>
                  <Typography className={classes.subtitle}>{menu.subtitle}</Typography>
                </div>)}
                {isTimelineEnabled && (
                  <Timeline 
                    items={menu.items} 
                    onClick={onItemClick} 
                    selectedLeftMenu={selectedLeftMenu}
                  />
                )}
                {!isTimelineEnabled && menu.items.map(item => {
                  return (
                    <div className={classes.menuItem}>
                      <Typography className={classes.menuItemLabel}>{item}</Typography>
                    </div>
                  );
                })}
                {!isTimelineEnabled && (menu.items.length === 1 ? 
                  (<div className={classes.lastItem} />)
                  : (<Divider />))
                }
              </div>
            );
          })}
        </Scrollbar>
      </div>
    </div>
  );
};

LeftMenu.propTypes = {
  /** Title of the dialog */
  headerTitle: PropTypes.string,

  /** Menu items */
  menuItems: PropTypes.arrayOf(PropTypes.object),
};

LeftMenu.defaultProps = {
  headerTitle: 'Header for Menu',
  menuItems: [
    {
      subtitle: 'subtitle(optional)',
      items: ['Menu Item 1', 'Menu Item 2', 'Menu Item 3'],
    },
    {
      subtitle: 'subtitle(optional)',
      items: ['Menu Item 1', 'Menu Item 2', 'Menu Item 3'],
    },
    {
      subtitle: 'subtitle(optional)',
      items: ['Menu Item 1', 'Menu Item 2', 'Menu Item 3'],
    },
    {
      subtitle: 'subtitle(optional)',
      items: ['Menu Item 1', 'Menu Item 2', 'Menu Item 3'],
    },
    {
      subtitle: 'subtitle(optional)',
      items: ['Menu Item 1'],
    },
  ],
};

export default LeftMenu;
