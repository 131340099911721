import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import { actionTypes } from 'components/editor/constants/types';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import TransitionSelect from 'components/editor/components/transitionSelect';
import selectElement from 'components/editor/utils/selectElement';
import useStyles from './styles';

const { AUTOMATION_UPDATE } = actionTypes;

const Select = ({ initialData, element }) => {
  const classes = useStyles();
  const editor = useSlate();
  const { update } = useEditorContext();

  const updateTransition = useCallback(
    transitionObj => {
      const updatedData = { ...initialData, transition: transitionObj };

      selectElement(editor, element);
      Transforms.setNodes(editor, { data: updatedData });
      ReactEditor.focus(editor);

      update({
        type: AUTOMATION_UPDATE,
        payload: { document: editor.children, updatedData },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialData, element],
  );

  return (
    <div className={classes.selectContainer}>
      <div className={classes.select}>
        <TransitionSelect update={updateTransition} value={initialData.transition} />
      </div>
    </div>
  );
};

Select.propTypes = {
  /** Initial data of the automation element */
  initialData: PropTypes.shape({}),
};

Select.defaultProps = {
  initialData: {},
};

export default memo(Select);
