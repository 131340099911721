/**
 *  Hook for fetching instances by publishing date
 */

import { useQuery } from 'react-apollo';
import GET_INSTANCES_BY_PUBLISHING_DATE from 'graphql/queries/getInstancesByPublishingDate';

const useGetInstancesByPublishingDate = (startDate, endDate) => {
  const { data, error, loading } = useQuery(GET_INSTANCES_BY_PUBLISHING_DATE, {
    variables: {
      input: {
        mType: 'instance',
        startDate,
        endDate,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return [data, error, loading];
};

export default useGetInstancesByPublishingDate;
