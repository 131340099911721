import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import Toolbar from 'contexts/Toolbar';
import ScrollIntoViewContext from 'contexts/ScrollIntoViewContext';
import HTML5Backend from 'react-dnd-html5-backend';
import MaximizeContext from 'contexts/MaximizeContext';
import { DndProvider } from 'react-dnd';
import CoverphotoContext from 'contexts/CoverPhotoContext';
import NotificationCtx from 'contexts/NotificationContext';
import BreakingFeedCtx from 'contexts/BreakingFeedContext';
import GET_USER from 'graphql/queries/getUser';
import UserCtx from 'contexts/UserContext';
import memberTypes from 'graphql/memberTypes';
import LoadingIndicator from 'components/loadingIndicator';
import { getMemberQuery } from 'graphql/queryVariables';
import Main from './main-view';

const MainContainer = props => {
  const { user } = props;
  const { mId } = user;

  const { data, error, loading } = useQuery(GET_USER, {
    variables: getMemberQuery(mId),
  });

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    return <div> {error} </div>;
  }

  user.attributes = data.getMember;

  let bookmarks = user.bookmarks ? user.bookmarks : {};
  if (user.attributes && user.attributes.mBookmarks) {
    bookmarks = user.attributes.mBookmarks.reduce((map, bm) => {
      // eslint-disable-next-line no-param-reassign
      map[bm.bookmarkedId] = {
        bookmarkedId: bm.bookmarkedId,
        bookmarkedType: memberTypes.STORY,
      };
      return map;
    }, {});
  }

  user.bookmarks = bookmarks;

  return (
    <UserCtx.Provider value={user}>
      <NotificationCtx>
        <BreakingFeedCtx>
          <Toolbar>
            <ScrollIntoViewContext>
              <CoverphotoContext>
                <MaximizeContext>
                  <DndProvider backend={HTML5Backend}>
                    <Main />
                  </DndProvider>
                </MaximizeContext>
              </CoverphotoContext>
            </ScrollIntoViewContext>
          </Toolbar>
        </BreakingFeedCtx>
      </NotificationCtx>
    </UserCtx.Provider>
  );
};
export default MainContainer;
