import { makeStyles } from '@material-ui/styles';

const commonFieldStyle = () => ({
  display: 'flex',
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
});

const commonOrderStyle = {
  height: '100%',
  width: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  // cursor: 'move',
};

const useStyles = makeStyles(theme => ({
  gridItemRoot: {
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    cursor: 'move',
  },
  draggingListItem: {
    display: 'flex',
    height: 31,
    backgroundColor: theme.palette.dina.statusOnFocused,
    opacity: '0.35',
    // boxShadow: `inset 0 0 0 2px ${theme.palette.dina.statusOnFocused}`,
  },
  defaultGridItem: {
    display: 'flex',
    height: 31,
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
      '& $ellipsisButton': {
        opacity: 1,
      },
    },
    '&:focus': {
      boxShadow: `inset 0 0 0 2px ${theme.palette.dina.onFocus}`,
      backgroundColor: theme.palette.dina.statusOnFocusedDiscreetFill,
    },
  },
  order: {
    ...commonOrderStyle,
  },
  floatOrder: {
    ...commonOrderStyle,
    backgroundColor: theme.palette.dina.paletteAccentCyanOpacity,
    borderLeft: `1px solid ${theme.palette.dina.paletteAccentCyan}`,
    borderTop: `1px solid${theme.palette.dina.paletteAccentCyan}`,
    borderBottom: `1px solid ${theme.palette.dina.paletteAccentCyan}`,
  },
  selectedOrder: {
    ...commonOrderStyle,
    backgroundColor: theme.palette.dina.onFocus,
  },
  floatIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    '& path': {
      fill: theme.palette.dina.paletteAccentCyan,
      fillOpacity: '1',
    },
  },
  orderText: {
    ...theme.typography.dina.listItemLabelMedium,
    color: theme.palette.dina.highEmphasis,
  },

  fieldContainer: {
    ...commonFieldStyle(),
    outline: 'none',
  },

  selectedContainer: {
    ...commonFieldStyle(),
    outline: 'none',
    backgroundColor: theme.palette.dina.onFocus,
    '&:hover': {
      backgroundColor: theme.palette.dina.onFocusOpacity,
    },
  },
  floatingContainer: {
    ...commonFieldStyle(),
    boxShadow: `inset 0 0 0 1px ${theme.palette.dina.paletteAccentCyan}`,
    outline: 'none',
    backgroundColor: theme.palette.dina.paletteAccentCyanOpacity,
  },
  ellipsisButton: {
    opacity: 0,
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    backgroundColor: theme.palette.dina.dividerLight,
  },
  fabDiv: {
    height: 3,
    backgroundColor: theme.palette.dina.surfaceToolBarStoryView,
    width: '100%',
    borderRadius: '1px',
    position: 'relative',
    zIndex: 99,
  },
  // fabRoot: {
  //   // position: 'absolute',
  //   left: '10px',
  //   top: '50%',
  //   zIndex: 100,

  //   transform: 'translate3d(-50%, -50%, 0)',
  //   backgroundColor: theme.palette.dina.surfaceToolBarStoryView,
  //   height: 24,
  //   width: 24,
  //   minHeight: 24,
  //   '&:hover': {
  //     backgroundColor: theme.palette.dina.surfaceToolBarStoryView,
  //   },
  // },
  selectionCount: {
    position: 'absolute',
    // left: '10px',
    // top: '50%',
    zIndex: 100,

    transform: 'translate3d(6px, -9px, 0)',
    width: '21px',
    height: '21px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.dina.highEmphasis,
    justifyContent: 'center',
    borderRadius: '50%',
    background: theme.palette.dina.statusOnFocused,
  },
  addIcon: {
    '& path': {
      fill: theme.palette.dina.iconActive,
      fillOpacity: '1',
    },
  },
}));

export default useStyles;
