import { makeStyles } from '@material-ui/styles';

const menuWidth = 272;

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {},
  menu: {
    width: menuWidth,
    padding: 24,
    paddingRight: 12,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  menuList: {
    width: menuWidth - 24 - 12,
    backgroundColor: palette.dina.surfaceDialogs,
    borderRadius: 4,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2),' +
      '0px 8px 10px 1px rgba(0,0,0,0.14),' +
      '0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  menuItem: {
    ...typography.dina.listItemLabel,
    '&:hover': {
      backgroundColor: palette.dina.whiteHoverOverlay,
    },
    '&$menuItemSelected, &$menuItemSelected:hover, &$menuItemSelected:focus': {
      backgroundColor: palette.dina.onSelected,
    },
    marginTop: 8,
    marginBottom: 8,
  },
  icon: {
    marginRight: '16px',
  },
  menuItemSelected: {},
}));

export default useStyles;
