import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .tick': {
      ...theme.typography.dina.overline,
      letterSpacing: '1px',
      lineHeight: '12px',
    },
    '& .tick line': {
      stroke: theme.palette.dina.storyTimelineTickDefault,
    },
    '& .major': {
      strokeWidth: 3,
    },
  },
}));

export default useStyles;
