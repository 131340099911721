import React, { useState, useContext, createContext } from 'react';

const ScrollViewContext = createContext();

const ScrollContextProvider = props => {
  const [currentClicked, setCurrentClicked] = useState(null);

  return <ScrollViewContext.Provider value={[currentClicked, setCurrentClicked]} {...props} />;
};

export const useScrollIntoView = () => useContext(ScrollViewContext);

export default ScrollContextProvider;
