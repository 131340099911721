import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    marginRight: 4,
    marginLeft: 2,
  },
  menu: {
    background: theme.palette.dina.surfaceCard,
    minWidth: 280,
    borderRadius: '8px',
    backdropFilter: 'blur(30px)',
  },
  listSubheader: {
    ...theme.typography.dina.overline,
  },
  menuItem: {
    marginLeft: 0,
  },
}));

export default useStyles;
