import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  divider: {
    position: 'relative',
    top: -1,
  },
  content: {
    height: 'calc(100% - 128px)',
    // overflowY: 'auto',
  },
  extendContent: {
    height: 'calc(100% - 72px)',
    // overflowY: 'auto',
    // position: 'relative',
  },
  container: {
    height: '100%',
  },
  storyboxContainer: {
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    height: '100%',
    position: 'relative',
    backdropFilter: 'blur(8px)',
  },
  media: {
    margin: '16px',
    color: 'white',
  },

  tabScrollButton: {
    minWidth: '32px',
  },
}));

export default useStyles;
