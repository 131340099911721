import React from 'react';
import { Divider as MaterialDivider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.dina.dividerLight,
  },
}));

const Divider = () => {
  const classes = useStyles();
  return <MaterialDivider className={classes.root} />;
};

export default Divider;
