/**
 * merge date 1 with date 2
 *
 * @param {string} date1 a date string
 * @param {string} date2 a date string
 * @returns {string} returns an ISO timestring where hh:mm:ss is merged with date1
 */

export const getMergedIsoString = (date1, date2) => {
  const pDate = new Date(date1);
  const pTime = new Date(date2);
  const cDate = new Date(
    pDate.getFullYear(),
    pDate.getMonth(),
    pDate.getDate(),
    pTime.getHours(),
    pTime.getMinutes(),
    pTime.getSeconds()
  );

  return cDate.toISOString();
};

export const getIsoString = (date1, date2) => {
  const pTime = new Date(date1);
  const pDate = new Date(date2);
  const cDate = new Date(
    pDate.getFullYear(),
    pDate.getMonth(),
    pDate.getDate(),
    pTime.getHours(),
    pTime.getMinutes(),
    pTime.getSeconds()
  );

  return cDate.toISOString();
};
