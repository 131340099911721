import React, { useContext, useEffect } from 'react';
import { ToolbarContext } from 'contexts/Toolbar';
import Logo from 'assets/images/DinaIcon.svg';
import useStyles from './home-styles';

const Home = () => {
  const classes = useStyles();

  const [, setToolbarElements] = useContext(ToolbarContext);

  useEffect(() => {
    setToolbarElements(<div />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.home}>
      <img className={classes.logo} src={Logo} alt="Dina icon" />
    </div>
  );
};

export default Home;
