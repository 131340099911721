import { ReactEditor } from 'slate-react';
import getMosAsset from 'components/editor/utils/getMosAsset';
import { elementTypes } from 'components/editor/constants/types';
// eslint-disable-next-line max-len
import insertSecondaryAutomationElement from 'components/editor/components/secondaryAutomation/utils/insertSecondaryAutomationElement';

const { OVERLAY_GRAPHICS } = elementTypes;

/**
 * Adds graphics media to given element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element to be updated
 * @param {Object} payload Data payload of dropped item
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const addGraphics = (editor, element, payload, update) => {
  const asset = getMosAsset(payload);

  if (asset) {
    insertSecondaryAutomationElement(
      editor,
      OVERLAY_GRAPHICS,
      {
        templateVariant: asset.title,
        mosobj: payload,
      },
      false
    );

    ReactEditor.focus(editor);
  }
};

export default addGraphics;
