import { useMutation } from 'react-apollo';
import CREATE_ASSET from 'graphql/mutations/createAssets';
import GET_ASSETS from 'graphql/queries/getAssets';
import { uploadToS3 } from 'utils/s3Utils';
import memberTypes from 'graphql/memberTypes';
import { getMembersQuery } from 'graphql/queryVariables';

const useCreateAsset = () => {
  const [createAssets] = useMutation(CREATE_ASSET);

  const updateAssetList = (proxy, mutationResult, file) => {
    const { createAssets: newAssets } = mutationResult.data;

    if (!newAssets || !newAssets[0]) return;

    // Read asset list from the cache
    try {
      const asset = newAssets[0];
      const assetsList = proxy.readQuery({
        query: GET_ASSETS,
        variables: getMembersQuery(asset.mId, memberTypes.STORY_ASSET),
      });

      const list = assetsList.getAssets.filter(
        tAsset => tAsset !== null && tAsset.mRefId === asset.mRefId,
      );

      if (file) {
        const _URL = window.URL || window.webkitURL;
        const fileUrl = _URL.createObjectURL(file);
        asset.mContentUrl = fileUrl;
        asset.mThumbnailUrl = fileUrl;
      }

      const assets = [...assetsList.getAssets];
      if (list.length === 0) {
        assets.push(asset);
      }

      proxy.writeQuery({
        query: GET_ASSETS,
        variables: getMembersQuery(asset.mId, memberTypes.STORY_ASSET),
        data: {
          getAssets: assets,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('updateAssetList: Failed to update cache', error);
    }
  };

  const createStoryAsset = async (storyId, assetInput, updateCache) => {
    const { mTitle, mRefId, mediaType, renditions, data, extension, file } = assetInput;

    const asset = {
      mId: storyId,
      mMetaData: [],
      mediaType,
      mTitle,
    };

    if (renditions) asset.renditions = renditions;
    if (mRefId) asset.mRefId = mRefId;

    Object.keys(data).forEach(key => {
      const value = data[key];
      if (!value) return;

      if (key === 'id') asset.mAssetId = value;
      else if (key === 'title') asset.mTitle = value;
      else if (key === 'description') asset.mDescription = value;
      else asset.mMetaData.push({ key, value });
    });

    if (file) {
      asset.mMetaData.push({ key: 'extension', value: extension });
      asset.mMetaData.push({ key: 'size', value: file.size });
      asset.mMetaData.push({ key: 'provider', value: 'Dina' });
    }

    const promise = createAssets({
      variables: {
        input: {
          assets: [asset],
        },
      },
      update: (proxy, mutationResult) => {
        if (updateCache) updateAssetList(proxy, mutationResult, file);
      },
    });

    if (!file) return promise;

    promise.then(r => {
      const assets = r.data.createAssets;

      if (renditions && renditions.length > 0) return;

      if (!assets || !assets[0]) return;
      const { mContentKey: key } = assets[0];
      uploadToS3(key, file);
    });

    return promise;
  };

  return [createStoryAsset];
};

export default useCreateAsset;
