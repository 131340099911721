import React from 'react';
import PropTypes from 'prop-types';
import memberTypes from 'graphql/memberTypes';
import Rundown from './rundown';
import Stories from './stories';

const Sidebar = ({ selectionType }) => {
  return selectionType === memberTypes.RUNDOWN_USER ? (
    <Rundown />
  ) : (
    <Stories selectionType={selectionType} />
  );
};

Sidebar.propTypes = {
  /** type of data that should be shown in the sidebar list */
  selectionType: PropTypes.string,
};

Sidebar.defaultProps = {
  selectionType: '',
};

export default Sidebar;
