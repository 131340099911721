import resizeImage from 'utils/resizeImage';
import useFileUpload from './useFileUpload';

/**
 * @typedef {Object} Configuration
 * @property {Function} onImageLoad - Callback to be invoked invoked on image file upload with the
 * file and filePath passed in
 * @property {Number} width - The width of the uploaded image to be resized to
 * @property {Number} height - The height of the uploaded image to be resized to
 * @property {Number} quality - The quality of saved JPEG, 0 - 100, default 100
 * @property {Boolean} isThumbnail - If true, returns a square sized image, default false
 * @property {Boolean} disableResize - If true, disable image resizing and returns the originally
 * uploaded image
 * @property {String} fileName - Defines a custom name for the file
 * @param {String[]} imageTypes - Image file types allowed to be uploaded
 */

/**
 * Sets up image file upload mechanism from user device
 *
 * @param {Configuration} options - Set up image file upload with the given options
 * @returns {Function} Initiates file upload
 */

const useImageUpload = (options = {}) => {
  const {
    onImageLoad = () => {},
    width = 256,
    height = 256,
    quality = 100,
    isThumbnail = false,
    disableResize = false,
    imageTypes = ['gif', 'jpeg', 'png'],
    fileName,
    multiple = false,
  } = options;

  const mimeTypes = imageTypes.map(fileType => `image/${fileType}`);

  const processImage = (file, filePath) => {
    if (file) {
      if (disableResize) {
        return { file, filePath };
      }
      const [imageFile, imageUrl] = resizeImage({
        file,
        filePath,
        width,
        height,
        quality,
        isThumbnail,
        fileName,
      });
      return { file: imageFile, filePath: imageUrl };
    }
    return null;
  };

  const onLoad = async (files, filePaths) => {
    if (!multiple) {
      const { file, filePath } = processImage(files, filePaths);
      onImageLoad(file, filePath);
    } else {
      const output = [];
      files.forEach(({ file, filePath }) => {
        output.push(processImage(file, filePath));
      });
      onImageLoad(output);
    }
  };

  const captureImage = useFileUpload(mimeTypes, onLoad, multiple);

  return captureImage;
};

export default useImageUpload;
