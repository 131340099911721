import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    height: '48px',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
  },
  toolbar: {
    minHeight: '48px',
    padding: 0,
    justifyContent: 'space-between',
  },
  leftMenu: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  rightMenu: {
    display: 'flex',
    marginRight: '13.5px',
  },
  listIcon: {
    marginLeft: '20px',
  },
  notificationIcon: {
    marginRight: '19.5px',
    display: 'flex',
    position: 'relative',
  },
  scratchPadIcon: {
    marginRight: '19.5px',
    display: 'flex',
    position: 'relative',
  },
  notificationIndicator: {
    position: 'absolute',
    left: '14px',
    bottom: '13px',
  },
  scratchPad: {
    position: 'absolute',
    top: '40px',
    right: '116px',
  },
}));

export default useStyles;
