import { useMutation } from 'react-apollo';
import UPDATE_MEMBER from 'graphql/mutations/updateMember';
import updateCache from 'screens/planning/components/day/updateCache';

const useUpdatePublishingDate = () => {
  const [updatePublishingDate] = useMutation(UPDATE_MEMBER);

  const update = async (mId, mPublishingAt, startDate, endDate) => {
    await updatePublishingDate({
      variables: {
        input: {
          mId,
          mPublishingAt,
        },
      },
      update: (proxy, mutationResult) => {
        const story = mutationResult.data.updateMember;
        updateCache(proxy, story, startDate, endDate);
      },
    });
  };

  return [update];
};

export default useUpdatePublishingDate;
