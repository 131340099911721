import { makeStyles } from '@material-ui/styles';

const useStyles = width =>
  makeStyles(() => ({
    drawer: {
      maxWidth: width,
      zIndex: 50,
    },
    drawerPaper: {
      minWidth: width,
      maxWidth: width,
      top: '96px',
      height: 'calc(100% - 96px)',
    },
    paperAnchorRight: {
      right: '48px',
      boxShadow:
        '-8px 0px 8px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    },
  }));

export default useStyles;
