import { filter, values, isString } from 'lodash';

/**
 * Gets type values given type object
 *
 * @param {Object} types SlateJS node types enum
 * @returns {String[]} SlateJS node type values
 */

const getTypeValues = types => filter(values(types), isString);

export default getTypeValues;
