import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMonday, isToday, isWeekend, isThisWeek, format } from 'date-fns';
import { Typography } from '@material-ui/core';
import { useDrop } from 'react-dnd';
import { sortBy } from 'lodash';
import { planningViews } from 'utils/planningViews';
import dndTypes from 'utils/dndTypes';
import CardContainer from './cardContainer-view';
import useStyles from './dayCell-styles';

const DayCell = props => {
  const targetRef = useRef();
  const [height, setHeight] = useState(0);

  const {
    date,
    stories,
    isSameMonth,
    onTransition,
    openStory,
    onlyShowUsersItems,
    updateDroppedMember,
  } = props;
  const classes = useStyles(isToday(date), isWeekend(date), isThisWeek(date), isSameMonth)();

  const sortedStories = sortBy(stories, ['mPriority']);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setHeight(targetRef.current.offsetHeight);
    }
  }, []);

  const handleDayTransition = () => {
    onTransition(new Date(date).toISOString(), planningViews.DAY);
  };
  const handleWeekTransition = () => {
    onTransition(new Date(date).toISOString(), planningViews.WEEK);
  };

  const weekHeader = dateValue => {
    if (isMonday(dateValue))
      return (
        <Typography
          classes={{ root: classes.weekText }}
          className={classes.week}
          onClick={handleWeekTransition}
        >
          {format(date, '[W]W')}
        </Typography>
      );
    return <div />;
  };

  const [{ didDrop }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.PITCH],
    drop(item, monitor) {
      if (!didDrop) {
        item.id && updateDroppedMember(item.id, date);
      }
    },
  });

  return (
    <div className={classes.root} ref={dropRef}>
      <div className={classes.header}>
        {weekHeader(date)}
        <Typography
          classes={{ root: classes.dateText }}
          className={classes.date}
          onClick={handleDayTransition}
        >
          {date && format(date, 'D')}
        </Typography>
      </div>
      <div className={classes.body} ref={targetRef}>
        <CardContainer
          onMoreClick={handleDayTransition}
          stories={sortedStories}
          {...{ height, openStory, onlyShowUsersItems }}
        />
      </div>
    </div>
  );
};

DayCell.propTypes = {
  /** Date of the cell */
  date: PropTypes.string,
  /** A list of stories of the day */
  stories: PropTypes.arrayOf(PropTypes.any),
  /** Boolean that indicates if a dayCell is of the same month as date indicator */
  isSameMonth: PropTypes.bool,
  /** Callback to open a story */
  openStory: PropTypes.func,
  /** callback to transition between planning views */
  onTransition: PropTypes.func,
  /** boolean that shows only users stories & pitches the user is assigned to */
  onlyShowUsersItems: PropTypes.bool,
  /** callback to be invoked when a story is dropped from left panel */
  updateDroppedMember: PropTypes.func,
};

DayCell.defaultProps = {
  date: null,
  stories: [],
  isSameMonth: true,
  openStory: (title, id) => {},
  onTransition: () => {},
  onlyShowUsersItems: false,
  updateDroppedMember: () => {},
};

export default DayCell;
