/**
 * Extracts transform properties from given string
 *
 * @param {string} transform CSS transform value
 * @returns {Object} CSS translateX, translateY properties
 */

const getTranslateValues = transform => {
  const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');

  g.setAttributeNS(null, 'transform', transform);

  if (g.transform) {
    const { matrix } = g.transform.baseVal.consolidate();
    const { e, f } = matrix;

    return {
      translateX: e,
      translateY: f,
    };
  }

  return null;
};

export default getTranslateValues;
