import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  containerDiv: {
    display: 'flex',
    width: '342px',
  },
  serialDiv: {
    height: '32px',
    width: '36px',
    display: 'flex',
    position: 'relative',
  },
  floatIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    '& path': {
      fill: theme.palette.dina.paletteAccentCyan,
      fillOpacity: '1',
    },
  },
  serialTypography: {
    ...theme.typography.dina.listItemLabelMedium,
    color: theme.palette.dina.highEmphasis,
    margin: 'auto',
  },
  thumbnailDiv: {
    display: 'flex',
    maxWidth: '32px',
    minWidth: '32px',
    height: '100%',
  },
  thumbnailImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  titleDiv: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
  },
  input: {
    ...theme.typography.dina.listItemLabelMedium,
    color: theme.palette.dina.highEmphasis,
    height: 28,
    paddingLeft: '4px',
    minWidth: '160px',
    width: '100%',
    textTransform: 'none',
    border: '1px solid transparent',
    backgroundColor: 'transparent',
    '&:focus': {
      outline: 'blue',
      backgroundColor: theme.palette.dina.blackHoverOverlay,
      boxShadow: `inset 0 0 0 1px ${theme.palette.dina.whiteMediumEmphasis}`,
    },
  },
  title: {
    ...theme.typography.dina.listItemLabelMedium,
    color: theme.palette.dina.highEmphasis,
    lineHeight: '27px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    minWidth: '160px',
    width: '100%',
    paddingLeft: '5px',
    cursor: 'text',
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
      boxShadow: `inset 0 0 0 1px ${theme.palette.dina.inputBorderResting}`,
    },
  },
  placeholder: {
    ...theme.typography.dina.tab,
    color: theme.palette.dina.disabled,
    marginLeft: '176px',
    fontWeight: 'normal',
    fontStyle: 'italic',
    textAlign: 'left',
    width: '160px',
    textTransform: 'none',
    margin: 'auto',
  },
  verticalDivider: {
    height: '32px',
    width: '4px',
    backgroundColor: 'transparent',
  },
}));

export default useStyles;
