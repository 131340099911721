import React, { useCallback, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useReadOnly, useSelected, useFocused, useSlate, ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import useStyles from './styles';

const SelectedElement = ({ children, element }) => {
  const isReadOnly = useReadOnly();
  const isSelected = useSelected(element);
  const isFocused = useFocused();
  const showHighlight = !isReadOnly && isSelected && isFocused;
  const classes = useStyles({ showHighlight });
  const editor = useSlate();
  const rootRef = useRef();

  const ensureSelection = useCallback(
    event => {
      if (!isReadOnly && !isSelected && ReactEditor.hasDOMNode(editor, event.target)) {
        const target = ReactEditor.findEventRange(editor, event);

        if (target) Transforms.select(editor, target);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReadOnly, isSelected],
  );

  useEffect(() => {
    showHighlight &&
      rootRef.current &&
      rootRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
  }, [showHighlight]);

  return (
    <div ref={rootRef} role="presentation" className={classes.root} onClick={ensureSelection}>
      {children}
    </div>
  );
};

SelectedElement.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

SelectedElement.defaultProps = {
  children: null,
  element: {},
};

export default memo(SelectedElement);
