import React from 'react';
import { Skeleton } from '@material-ui/lab';
import fieldTypes from 'utils/rundown/fieldTypes';
import {
  durationTypes,
  getDuration,
  getDurationKey,
  getSeconds,
  getTime,
} from 'screens/rundown/components/editor/utils';
import ReadOnly from '../inputs/readOnly';
import FlexibleField from './flexibleField-view';
import useStyles from './custom-field-styles';

const CustomField = ({
  instanceId,
  fieldId,
  updateMetadata,
  type,
  width,
  style,
  readonly,
  metadata,
  parameters,
  function: func,
  ...rest
}) => {
  const findValue = fId => {
    const meta = metadata.find(data => data.key === fId);
    return meta ? meta.value : null;
  };

  const findKey = str => {
    return metadata.find(item => getDurationKey(item) === str);
  };

  const renderedValue = findValue(fieldId) || '';
  const isReadyOnly = readonly || type === fieldTypes.FUNCTION;

  const updateField = val => {
    if (val !== renderedValue) {
      const newFields = [];
      if (fieldId.includes(durationTypes.CLIP_DURATION)) {
        const scriptDuration = getDuration(metadata, durationTypes.SPEAK_DURATION);
        const newTotalDuration = getSeconds(scriptDuration) + getSeconds(val);
        newFields.push({
          key: findKey(durationTypes.TOTAL_DURATION).key,
          value: getTime(newTotalDuration),
        });
      }
      newFields.push({ key: fieldId, value: val });
      updateMetadata(newFields);
    }
  };

  const fieldWidth = Number(width);
  if (!fieldWidth) return null;

  return isReadyOnly ? (
    <ReadOnly
      key={fieldId}
      id={`${instanceId}${fieldId}`}
      value={renderedValue}
      width={fieldWidth}
      style={style}
    />
  ) : (
    <FlexibleField
      fieldValue={renderedValue}
      width={fieldWidth}
      {...{
        instanceId,
        fieldId,
        type,
        style,
        updateField,
        ...rest,
      }}
    />
  );
};

const CustomFieldView = ({ instanceId, fields, metadata, updateMetadata, disableEdit }) => {
  const classes = useStyles();

  return !fields.length ? (
    <div className={classes.skeletonDiv}>
      <Skeleton classes={{ root: classes.skeletonRoot, text: classes.skeletonText }} />
    </div>
  ) : (
    fields.map(({ id: fieldId, type, ...rest }) => (
      <CustomField
        key={`${instanceId}${fieldId}${type}`}
        {...{ fieldId, instanceId, type, metadata, updateMetadata, disableEdit }}
        {...rest}
      />
    ))
  );
};

export default CustomFieldView;
