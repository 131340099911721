import { makeStyles } from '@material-ui/styles';

const commonCellStyle = {
  display: 'flex',
  alignItems: 'center',
  width: '160px',
  height: '40px',
};

const useStyles = (color, collapse, transitioning) =>
  makeStyles(theme => {
    const backgroundColor = theme.palette.dina[color === 'surfaceCard' ? 'surfaceCardDark' : color];
    return {
      root: {
        height: '100%',
        width: collapse ? '40px' : '100%',
        minWidth: '40px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRadius: '8px',
        overflow: 'hidden',
        transition: 'width 200ms ease-in-out',
      },
      background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundColor,
        opacity: 0.1,
      },
      columnHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
        minHeight: '40px',
        position: 'relative',
      },
      headerRight: {
        zIndex: 1,
      },
      title: {
        ...theme.typography.dina.body1,
        color: theme.palette.dina.highEmphasis,
        zIndex: 1,
        marginLeft: '16px',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      container: {
        position: 'relative',
        width: '100%',
        height: 'calc(100% - 40px)',
      },
      containerBackground: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor,
        opacity: 0.1,
        borderRadius: '0px 0px 4px 4px',
      },
      cardContainerWrapper: {
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        paddingBottom: '5px',
      },
      cardContainer: {
        width: '100%',
        height: '100%',
        padding: collapse ? 0 : '8px',
        overflowX : 'hidden',
        overflowY: transitioning ? 'hidden' : collapse ? 'hidden' : 'auto',
      },
      cellMinimized: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      cellTitleInfo: {
        ...commonCellStyle,
        transform: 'rotate(90deg) translateX(40%)',
      },
      cellItemInfo: {
        ...commonCellStyle,
        justifyContent: 'flex-end',
        transform: 'rotate(90deg) translateX(calc(-40% - 16px))',
      },
      cellItemInfoText: {
        ...theme.typography.dina.captionRegular,
        letterSpacing: '0.25px',
        color: theme.palette.dina.mediumEmphasis,
      },
      divider: {
        backgroundColor:
          color === 'surfaceCard' ? theme.palette.dina.dividerLight : backgroundColor,
      },
      kanbanCard: {
        marginBottom: '2px',
      },
    };
  });

export default useStyles;
