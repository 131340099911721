import React, { useRef, useState, useEffect } from 'react';
import Timeline from './components/timeline';

const HourView = ({ time, stories, instances, platforms, rundowns, openStory }) => {
  const rootRef = useRef(null);
  const [timelineSize, setTimelineSize] = useState(0);

  const init = () => {
    let resizeObserver;

    if (rootRef.current) {
      const element = rootRef.current;

      if (window.ResizeObserver) {
        resizeObserver = new ResizeObserver(() => {
          const { width } = element.getBoundingClientRect();
          setTimelineSize(width);
        });

        resizeObserver.observe(element);
      } else {
        const { width } = element.getBoundingClientRect();
        setTimelineSize(width);
      }
    }

    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  };

  useEffect(init, [time]);
  return (
    <div ref={rootRef}>
      <Timeline {...{ time, timelineSize, stories, instances, platforms, rundowns, openStory }} />
    </div>
  );
};

export default HourView;
