import { makeStyles } from '@material-ui/styles';

const useStyles = () =>
  makeStyles(theme => ({
    feedsPage: {
      height: '100%',
      width: '100%',
    },
    pageTitleContainer: {
      height: '64px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '16px',
    },
    pageTitle: {
      ...theme.typography.dina.h4,
      verticalAlign: 'center',
    },
    feeds: {
      height: '100%',
      width: '100%',
      borderBottom: `2px solid ${theme.palette.dina.dividerDark}`,
      display: 'grid',
      gridTemplateColumns: '3fr 1fr',
      gridColumnGap: '16px',
      padding: '0 24px 101px 0px',
      borderTop: `1px solid ${theme.palette.dina.dividerLight}`,
    },
  }));

export default useStyles;
