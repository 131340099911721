import { makeStyles } from '@material-ui/styles';
import memberTypes from 'graphql/memberTypes';
import pitchToolbarSrc from 'assets/images/PitchBackgroundOverlay.png';
import rndToolbarSrc from 'assets/images/Tile_DiagonalPattern.png';

const useStyles = (mType, isNotToday, isStory) =>
  makeStyles(theme => ({
    appBar: {
      height: '48px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
      backgroundColor: () => {
        if (mType === memberTypes.RUNDOWN) {
          return theme.palette.dina.paletteAccentPurple;
        }
        if (mType === memberTypes.RUNDOWN_TEMPLATE) {
          return theme.palette.dina.paletteAccentOrange;
        }
        if (mType === 'create') return theme.palette.dina.onSelected;
        if (isStory) return theme.palette.dina.onSelected;
        return theme.palette.dina.primary700;
      },
      backgroundImage: () => {
        if (mType === memberTypes.RUNDOWN) {
          return isNotToday ? `url(${rndToolbarSrc})` : null;
        }
        if (mType === memberTypes.PITCH) {
          return `url(${pitchToolbarSrc})`;
        }
      },
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: '48px',
      padding: '0 12px',
    },
  }));

export default useStyles;
