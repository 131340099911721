import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import dndTypes from 'utils/dndTypes';
import useStyles from './member-styles';
import Member from './member-view';

const memberSource = {
  beginDrag(props) {
    return props;
  },
  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }
    if (monitor.getDropResult().id)
      props.handleDrop(props.id, monitor.getDropResult().id);
  },
};

const memberTarget = {
  drop(props, monitor) {
    props.handleDrop(props.id, monitor.getItem().id);
  },
};

const DraggableMember = props => {
  const { connectDragSource, connectDropTarget, hovered, ...rest } = props;

  const classes = useStyles({hovered});

  return (
    connectDragSource &&
    connectDropTarget &&
    connectDropTarget(
      connectDragSource(
        <div className={classes.focusDiv}>
          <Member {...rest} />
        </div>
      )
    )
  );
};

export default DropTarget(dndTypes.STORY, memberTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  hovered: monitor.isOver(),
  story: monitor.getItem(),
}))(
  DragSource(dndTypes.MEMBER, memberSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))(DraggableMember)
);
