import getIndicatorNewPosition from '../../functions/getIndicatorNewPosition';

const resetAnimatedIndicator = (indicatorNode, sliderWrapper) => {
  const newItem = document.querySelector('.selected-month');
  const newPosition = getIndicatorNewPosition(newItem, indicatorNode, sliderWrapper);

  Object.assign(indicatorNode.style, {
    visibility: 'hidden',
    transform: `translate(${newPosition}px, 0)`,
  });
};

export default resetAnimatedIndicator;
