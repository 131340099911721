import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ArrowLeftSrc from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import ArrowRightSrc from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
  },
  icon: {
    width: 24,
    height: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Navs = ({ onPrev, onNext }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div onClick={onPrev} onKeyDown={onPrev} role="button" tabIndex="0" className={classes.icon}>
        <img src={ArrowLeftSrc} alt="Previous" />
      </div>

      <div onClick={onNext} onKeyDown={onNext} role="button" tabIndex="0" className={classes.icon}>
        <img src={ArrowRightSrc} alt="Next" />
      </div>
    </div>
  );
};

Navs.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Navs;
