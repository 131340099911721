import React, { useState, useRef, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { DialogContent as MaterialDialogContent, DialogActions } from '@material-ui/core';
import InputField from 'components/inputField/InputField';
import Button from 'components/buttons/button/button-view';
import stopEventPropagation from 'utils/stopEventPropagation';
import actionTypes from '../../constants/actionTypes';
import useStyles from './styles';

const { CANCEL, CONFIRM } = actionTypes;

const DialogContent = ({ initialLink, initialLinkText, isCollapsed, update }) => {
  const classes = useStyles();
  const [href, setHref] = useState(initialLink);
  const [linkText, setLinkText] = useState(initialLinkText);
  const isCollapsedRef = useRef(isCollapsed);
  const initialLinkRef = useRef(initialLink);

  const cancel = useCallback(() => update({ type: CANCEL }), [update]);

  const confirm = useCallback(() => update({ type: CONFIRM, payload: { href, linkText } }), [
    linkText,
    update,
    href,
  ]);

  const handleEnterEscape = useCallback(
    event => {
      const { key } = event;
      const isEnter = key === 'Enter';
      const isEscape = key === 'Escape';

      if (isEnter || isEscape) event.preventDefault();

      if (isEnter) confirm();

      if (isEscape) cancel();
    },
    [cancel, confirm],
  );

  return (
    <div
      className={classes.root}
      onKeyDown={handleEnterEscape}
      onMouseDown={stopEventPropagation}
      role="presentation"
    >
      <MaterialDialogContent>
        <InputField
          fullWidth
          label="URL"
          description="Enter the URL for the link"
          value={href}
          onChange={setHref}
        />

        {isCollapsedRef.current && (
          <div className={classes.inputWrapper}>
            <InputField
              label="Text"
              disabled={!href}
              description="Enter the text for the link"
              value={linkText}
              onChange={setLinkText}
            />
          </div>
        )}
      </MaterialDialogContent>

      <DialogActions>
        <Button variant="text" onClick={cancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          // disabled={!href || (!initialLink && isCollapsed && !linkText)}
          type="significant"
          onClick={confirm}
        >
          Ok
        </Button>
      </DialogActions>
    </div>
  );
};

DialogContent.propTypes = {
  /** Specifies existant url for the link */
  initialLink: PropTypes.string,
  /** Specifies if SlateJS selection is collapsed or not */
  isCollapsed: PropTypes.bool,
  /** Callback to be invoked on chage, with update type and relevant payload passed in */
  update: PropTypes.func,
};

DialogContent.defaultProps = {
  initialLink: '',
  isCollapsed: true,
  update: ({ type, payload }) => {},
};

export default memo(DialogContent);
