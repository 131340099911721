import React, { useEffect } from 'react';
import useApolloSubscription from 'hooks/useApolloSubscription';
import LoadingIndicator from 'components/loadingIndicator';
import getDates from 'utils/getDates';
import useGetPlatforms from 'hooks/useGetPlatforms';
import useGetInstancesByPublishingDate from 'hooks/useGetInstancesByPublishingDate';
import useUpdateInstanceState from 'hooks/useUpdateInstanceState';
import useFuseSearch from 'hooks/useFuseSearch';
import useOpenStory from 'hooks/useOpenStory';
import { getMergedIsoString } from 'utils/dateTimeConvertions';
import UPDATE_PLAN_STATUS_VIEW_SUBSCRIPTION from 'graphql/subscriptions/updatePlanStatusView';
import updateCache from './updateCache';
import frontendFiltering from '../../utils/frontendFiltering';
import getUniqueFilters from '../../utils/getUniqueFilters';
import returnStates from './utils/statusBoards/returnStates';
import StatusActionContainer from './status-action-container';

const Status = props => {
  const { time, timeVariant, filters, selectedFilter, stateCollapsed, setStateCollapsed } = props;
  const { startDate, endDate } = getDates(time, timeVariant);  
  const planStatusViewDate = startDate.split('T')[0];

  const [subscribe, unSubscribe] = useApolloSubscription(UPDATE_PLAN_STATUS_VIEW_SUBSCRIPTION, {
    variables: {
      planStatusViewDate,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      console.log('subscription data:', subscriptionData);
      const instance = subscriptionData.data.updatePlanStatusViewSubscription;
      updateCache(client, instance, startDate, endDate);
    },
  });

  const states = returnStates('status-normal');
  const [update] = useUpdateInstanceState();
  const search = useFuseSearch();

  const [data, error, loading] = useGetInstancesByPublishingDate(startDate, endDate);
  const [platforms, platformsLoadingError, platformsLoading] = useGetPlatforms(time);
  useEffect(() => {
    subscribe();

    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const openStory = useOpenStory();

  if (loading || platformsLoading) return <LoadingIndicator />;
  if (error || platformsLoadingError) {
    return <div>Error! {error.message}</div>;
  }

  const { getMembersByPublishingDate: instanceData } = data;

  const filterList = getUniqueFilters(filters, selectedFilter);

  const filteredData = frontendFiltering(instanceData, filterList, search, true);

  const sortedData = {};
  states.forEach(state => {
    const instances = filteredData.filter(d => d.mState === state.id);
    sortedData[state.id] = instances;
  });

  const updateInstance = (id, statusId, publishingAt) => {
    update(
      id,
      statusId,
      startDate,
      endDate,
      publishingAt ? getMergedIsoString(time, publishingAt) : null,
    );
  };

  return (
    <StatusActionContainer
      {...props}
      states={states}
      data={sortedData}
      platforms={platforms}
      startDate={startDate}
      endDate={endDate}
      time={time}
      updateInstance={updateInstance}
      openStory={openStory}
      {...{ stateCollapsed, setStateCollapsed }}
    />
  );
};

export default Status;
