import React, { useMemo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'screens/main/components/scrollbar';
import Editor, { isOlderSlateValue, migrateValue } from 'components/editor';
import Group from '../group';
import TextBox from './textBox';
import Instances from './instances';
import useStyles from './content-styles';

const renderToolbar = () => null;

const ContentView = ({
  title,
  instances,
  description,
  groupBackground,
  content,
  storyId,
  onInstanceClick,
  platforms,
  users,
}) => {
  const [height, setHeight] = useState(0);
  const [contentCollapsed, setContentCollapsed] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef.current) {
      const containerElement = containerRef.current;
      const { height: rootHeight } = containerElement.getBoundingClientRect();
      setHeight(rootHeight);
    }
  }, []);

  const value = useMemo(
    () => (content && isOlderSlateValue(content) ? migrateValue(content) : content),
    [content],
  );

  const classes = useStyles({ hasInstance: instances.length > 0, height });

  return (
    <div className={classes.root} ref={containerRef}>
      <Group
        label="Main Content"
        background={groupBackground}
        collapsed={contentCollapsed}
        setCollapsed={setContentCollapsed}
      >
        <div className={classes.container}>
          <Scrollbar>
            <div className={classes.contentContainer}>
              <TextBox label="Title" description={title} />
              <TextBox label="Description" description={description} />
              <div className={classes.label}>Content</div>
            </div>

            <Editor
              background="transparent"
              placeholder="No content available..."
              readOnly
              height="calc(100% -100px)"
              isFixedHeightEditor={false}
              {...{ renderToolbar, users, value }}
            />
          </Scrollbar>
        </div>
      </Group>
      {instances.length > 0 && (
        <Group label="Instances" background={groupBackground} itemCount={instances.length}>
          <Instances
            collapsed={contentCollapsed}
            {...{ height, instances, storyId, onInstanceClick, platforms }}
          />
        </Group>
      )}
    </div>
  );
};

ContentView.propTypes = {
  /** label to show on the input field */
  title: PropTypes.string,
  /** instances to show on list  */
  instances: PropTypes.arrayOf(PropTypes.object),
  /** description of the story */
  description: PropTypes.string,
  /** background color of group tab */
  groupBackground: PropTypes.string,
  /** Content of the story */
  content: PropTypes.objectOf(PropTypes.any),
};

ContentView.defaultProps = {
  title: '',
  instances: [],
  description: '',
  groupBackground: undefined,
  content: null,
};
export default ContentView;
