import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
// eslint-disable-next-line max-len
import marketIcon from 'assets/icons/systemicons/exchange_ultrasmall_on.svg';

const useStyles = makeStyles(theme => ({
  pitch: {
    backgroundColor: theme.palette.dina.statusStoryMarket,
    borderRadius: '2px',
    width: ({ size }) => size,
    height: ({ size }) => size,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: ({ size }) => size / 2,
    height: ({ size }) => size / 2,
    minWidth: '10px',
    minHeight: '10px',
  },
}));

const PitchIndicator = ({ size, ...rest }) => {
  const classes = useStyles({ size });
  return (
    <div {...rest}>
      <div className={classes.pitch}>
        <img src={marketIcon} alt="" className={classes.image} />
      </div>
    </div>
  );
};

PitchIndicator.propTypes = {
  /** size of indicator */
  size: PropTypes.number,
};

PitchIndicator.defaultProps = {
  size: 16,
};

export default PitchIndicator;
