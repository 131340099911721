/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import useDidMount from 'hooks/useDidMount';
import BackButton from './backButton';
import useStyles from './metadata-styles';
import MetadataField from './metadataField';
import actionTypes from './utils/actionTypes';
import reducer from './utils/reducer';

const Compare = func => array => func(array);

const MetaDataView = ({
  onBackClick,
  onUpdateMeta,
  fields,
  parameterFields,
  metadata,
  usage,
  disableEdit,
  ...rest
}) => {
  const classes = useStyles();
  const didMount = useDidMount();
  const [state, dispatch] = useReducer(reducer, metadata);
  const deepCompare = Compare(JSON.stringify);

  useEffect(() => {
    if (didMount && deepCompare(metadata) !== deepCompare(state)) {
      dispatch({ type: actionTypes.UPDATE_STATE, payload: metadata });
    }
  }, [deepCompare(metadata)]);

  const handleUpdateMeta = payload => {
    dispatch({ type: actionTypes.UPDATE_META, payload });
    onUpdateMeta(payload);
  };

  const MemoizedField = useMemo(
    () => (
      <div className={onBackClick ? classes.fieldDiv : classes.generalDiv}>
        {fields.map(field => (
          <MetadataField
            {...field}
            key={field.id}
            onUpdateValue={handleUpdateMeta}
            metadata={state}
            {...{ fields, parameterFields, usage, disableEdit }}
          />
        ))}
      </div>
    ),
    [deepCompare(state)],
  );

  return (
    <div className={classes.rootDiv} {...rest}>
      {onBackClick && <BackButton label="Back to Editor" onBackClick={onBackClick} />}
      {MemoizedField}
    </div>
  );
};

MetaDataView.propTypes = {
  /** callback that it calls when backButton is clicked */
  onBackClick: PropTypes.func,
  /** Fields to be mapped on metadata on instance view */
  fields: PropTypes.arrayOf(PropTypes.object),
  /** current Metadata value for that instance */
  metadata: PropTypes.arrayOf(PropTypes.object),
  /** Callback when any metadata is upated */
  onUpdateMeta: PropTypes.func,
  /** usage: compact or standard */
  usage: PropTypes.string,
};

MetaDataView.defaultProps = {
  onBackClick: undefined,
  fields: [],
  metadata: [],
  onUpdateMeta: () => {},
  usage: 'standard',
};

export default MetaDataView;
