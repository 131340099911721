import { mediaTypes } from './rundownItemTypes';

export default {
  INSTANCE: 'instance',
  MEMBER: 'member',
  STORY: 'story',
  PITCH: 'pitch',
  GRID_INSTANCE: 'grid-instance',
  ASSET: 'asset',
  ...mediaTypes,
};
