/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useRundownContext } from 'globalState';
import { ToolbarContext } from 'contexts/Toolbar';
import LoadingIndicator from 'components/loadingIndicator';
import Download from 'components/download/download';
import { toolbarButtonIds } from './components/toolbar/toolbarButtons';
import rundownStates from './utils/rundownStates';
import Editor from './components/editor';
import Header from './components/header';
import useStyles from './rundown-styles';
import RundownToolbar from './components/toolbar/toolbar-view';
import EditMasterWrapper from './components/editMasterWrapper';
import DataLoader from './dataLoader-container';
import RundownLists from './components/rundownLists';

const RundownView = props => {
  const {
    selecteddate,
    showMasterEditView,
    setShowMasterEditView,
    updateMasterRundown,
    onArchiveMasterRundown,
    updateRundownState,
    updatePreparingState,
    saveEditorContent,
    updateInstanceMetaData,
    updateRundownHost,
    rundownRefetch,
    mId,
    refId,
    id,
    data,
    title,
    form,
    host,
    batchUpdateRundownInstanceState,
    ...rest
  } = props;

  const classes = useStyles();

  const {
    mTitle: rundownTitle,
    recurrence,
    mPublishingAt,
    mState,
    mCoverPhotoUrl,
    mThumbnailKey,
    mCoverPhotoKey,
    mPreparingState,
    mThumbnailUrl,
    mOrder,
  } = data.getRundown;
  const [imageUrl, setImageUrl] = useState(mCoverPhotoUrl);
  const [isShowingDownload, setIsShowingDownload] = useState(false);
  const [, setToolbarElements] = useContext(ToolbarContext);
  const [download, setDownload] = useState({});
  const [toolbarToggles, setToolbarToggles] = useState({});
  const [instanceDataLoading, setInstanceDataLoading] = useState(true);

  const [{ toolbarButtons }] = useRundownContext();

  const getToolbarButtonState = buttonId =>
    toolbarButtons.find(button => button.id === buttonId).selected;

  useEffect(() => {
    setToolbarToggles({
      showReady: getToolbarButtonState(toolbarButtonIds.READY),
      showPreparing: getToolbarButtonState(toolbarButtonIds.PREPARING),
      showEditor: getToolbarButtonState(toolbarButtonIds.EDITOR),
    });
  }, [toolbarButtons]);

  const { duration, startTime, dailyExclusive } = recurrence || {
    duration: '0',
    startTime: '0',
    dailyExclusive: true,
  };

  const [masterTitle, setMasterTitle] = useState(rundownTitle || '');
  const [masterStart, setMasterStart] = useState(startTime || '');
  const [masterDuration, setMasterDuration] = useState(duration || '');
  const [masterDailyExclusive, setMasterDailyExclusive] = useState(dailyExclusive);

  const [batchInstanceUpdateNeeded, setBatchInstanceUpdateNeeded] = useState(false);

  const onEditMasterClick = action => {
    if (action === 'edit_master') setShowMasterEditView(true);
  };

  const onRundownStateChange = mosState => {
    let rundownState = rundownStates.NOTREADY;
    if (mosState === rundownStates.ACTIVE) rundownState = rundownStates.ACTIVE;
    if (mosState === rundownStates.READY) rundownState = rundownStates.READY;

    updateRundownState(rundownState);
  };

  const onPreparingStateChange = newState => {
    updatePreparingState(newState);
  };

  const onRundownHostUpdate = updatedHost => {
    updateRundownHost(updatedHost || null);
    setBatchInstanceUpdateNeeded(true);
  };
  const onDownloadClick = type => {
    let contentType = 'application/pdf';
    let rundownSection = 'ready';
    switch (type) {
      case 'print':
        contentType = 'application/pdf';
        break;
      case 'printpreorder':
        contentType = 'application/pdf';
        rundownSection = 'preorder';
        break;
      case 'download':
        contentType = 'application/xml';
        break;
      default:
        contentType = 'application/pdf';
    }
    setDownload({ mId, mRefId: refId, contentType, rundownSection });
    setIsShowingDownload(true);
  };

  useEffect(() => {
    const mosState = mState || rundownStates.NOTREADY;

    setToolbarElements(
      <RundownToolbar
        mosState={mosState}
        preparingState={mPreparingState}
        selectedDate={mPublishingAt}
        isRundownExists
        {...{
          onEditMasterClick,
          onRundownStateChange,
          onDownloadClick,
          host,
          onRundownHostUpdate,
          onPreparingStateChange,
          batchUpdateRundownInstanceState,
        }}
      />,
    );
  }, [mPublishingAt, mState, mPreparingState, mId, host, mOrder]);

  const onCoverPhotoSelected = url => {
    setImageUrl(url);
  };

  return (
    <div className={classes.rundownContainer}>
      <EditMasterWrapper
        title={rundownTitle}
        onDoneEditing={() => {
          updateMasterRundown(masterTitle, masterStart, masterDuration, masterDailyExclusive);
        }}
        onArchiveMasterRundown={onArchiveMasterRundown}
        {...{ showMasterEditView }}
      >
        <DataLoader
          mId={mId}
          refId={refId}
          data={data}
          setInstanceDataLoading={setInstanceDataLoading}
        />
        <Header
          defaultStart={mPublishingAt}
          mState={data.getRundown.mState}
          onMasterTitleChange={titleValue => setMasterTitle(titleValue)}
          onMasterStartChange={val => setMasterStart(val)}
          onMasterDurationChange={val => setMasterDuration(val)}
          onToggleDailyExclusive={() => setMasterDailyExclusive(!masterDailyExclusive)}
          image={imageUrl}
          {...{
            data,
            selecteddate,
            showMasterEditView,
            rundownTitle,
            mId,
            refId,
            masterTitle,
            masterStart,
            masterDuration,
            mThumbnailKey,
            mCoverPhotoKey,
            onCoverPhotoSelected,
            masterDailyExclusive,
          }}
        />
        {instanceDataLoading ? (
          <LoadingIndicator />
        ) : (
          <div className={showMasterEditView ? classes.editContent : classes.content}>
            <RundownLists
              hostReadSpeed={
                host && host.mProperties && host.mProperties.readSpeed
                  ? host.mProperties.readSpeed
                  : 150
              }
              thumbnail={mThumbnailUrl}
              {...{
                selecteddate,
                showMasterEditView,
                updateMasterRundown,
                onEditMasterClick,
                mId,
                refId,
                id,
                data,
                title,
                toolbarToggles,
                form,
                batchInstanceUpdateNeeded,
                setBatchInstanceUpdateNeeded,
                ...rest,
              }}
            />

            {toolbarToggles.showEditor && (
              <div className={showMasterEditView ? classes.editContentEditor : classes.editor}>
                <Editor
                  data={data.getRundown}
                  rundownDate={mPublishingAt}
                  rundownId={mId}
                  hostReadRate={
                    host && host.mProperties && host.mProperties.readSpeed
                      ? host.mProperties.readSpeed
                      : 150
                  }
                  {...{
                    refId,
                    form,
                    rundownTitle,
                    selecteddate,
                    rundownRefetch,
                  }}
                />
              </div>
            )}
            {isShowingDownload ? (
              <Download download={download} setIsShowingDownload={setIsShowingDownload} />
            ) : null}
          </div>
        )}
      </EditMasterWrapper>
    </div>
  );
};

export default RundownView;
