import React, { useState, useContext, useEffect } from 'react';
import configCtx from 'contexts/configContext';
import { useQuery, useMutation } from 'react-apollo';
import GET_RUNDOWN from 'graphql/queries/getRundown';
import GET_PRESENTER from 'graphql/queries/getMember';
import BATCH_UPDATE_INSTANCES from 'graphql/mutations/batchUpdateInstance';
// import CurrentInstanceContext from 'contexts/CurrentInstanceContext';
import SelectionContext from 'contexts/SelectionContext';
import UPDATE_RUNDOWN_SYNC from 'graphql/mutations/updateRundownSync';
import LoadingContext from 'contexts/LoadingContext';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import getTimeZone from 'utils/getTimeZone';
import useArchiveMember from 'hooks/useArchiveMember';
import { StoryTabsContext } from 'globalState';
import memberTypes from 'graphql/memberTypes';
import { ToolbarContext } from 'contexts/Toolbar';
import RundownView from './rundown-view';
import ConvertDurationToMillisecond from './utils/convertDurationToMillisecond';

const RundownContainer = ({ mid, selecteddate, title, ...rest }) => {
  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);
  const [showMasterEditView, setShowMasterEditView] = useState(false);
  const [host, setHost] = useState(null);
  const [hostId, setHostId] = useState(null);
  const [skip, setSkip] = useState(true);

  const [, , closeStoryTab] = useContext(StoryTabsContext);

  const [, setToolbarElements] = useContext(ToolbarContext);

  const { metadataForms } = useContext(configCtx);
  const [, archiveMasterRundown] = useArchiveMember();
  const [batchUpdateInstances] = useMutation(BATCH_UPDATE_INSTANCES);

  const { data: hostData } = useQuery(GET_PRESENTER, {
    variables: {
      input: {
        mId: hostId,
        mType: 'user',
      },
    },
    skip,
    fetchPolicy: 'cache-and-network',
  });

  const { data, error, loading, refetch } = useQuery(GET_RUNDOWN, {
    variables: {
      input: {
        mId: mid,
        mRefId: mid,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data && data.getRundown) {
      const { mMetaData } = data.getRundown;
      const hostField = mMetaData && mMetaData.find(val => val.key === 'host');

      if (hostField && hostField.value) {
        const value = JSON.parse(hostField.value);
        const hostUserId = (value && value.mId) || null;
        setHostId(hostUserId);
        setSkip(!hostUserId);
      }
      if (data.getRundown.mType === memberTypes.RUNDOWN_TEMPLATE) {
        setShowMasterEditView(true);
      }
      if (data.getRundown.mType === memberTypes.RUNDOWN) {
        setShowMasterEditView(false);
      }
    }
  }, [data]);

  useEffect(() => {
    setSkip(true);
    if (hostData && hostData.getMember) {
      setHost(hostData.getMember);
    }
  }, [hostData]);

  useEffect(() => {
    if (loading) {
      setHost(null);
      setHostId(null);
      setToolbarElements(<div />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (error) return <div> {error.message} </div>;
  if (loading) return <LoadingIndicator />;

  const updateMasterRundown = (updatedTitle, startTime, updatedDuration, dailyExclusive) => {
    const mPlannedDuration = ConvertDurationToMillisecond(updatedDuration);
    const { timeZone, start } = data.getRundown.recurrence;
    const payloadInput = {
      crudAction: 'UPDATE',
    };
    const mPayload = [payloadInput];
    const input = {
      mId: mid,
      mRefId: mid,
      mTitle: updatedTitle,
      mPlannedDuration,
      recurrence: {
        duration: updatedDuration,
        timeZone: timeZone || getTimeZone(),
        startTime,
        start,
        dailyExclusive,
      },
      mPayload,
    };

    updateRundownSync({
      variables: {
        input,
      },
    }).then(() => {
      closeStoryTab(null, mid);
    });
  };

  const onArchiveMasterRundown = () => {
    archiveMasterRundown(mid).then(() => {
      setShowMasterEditView(false);
      closeStoryTab(null, mid);
    });
  };

  const updateRundownHost = updatedHost => {
    const payloadInput = {
      crudAction: 'UPDATE',
    };
    const mPayload = [payloadInput];

    const input = {
      mId: mid,
      mRefId: mid,
      mMetaData: {
        key: 'host',
        value: JSON.stringify(updatedHost),
      },
      mPayload,
    };

    setHostId(updatedHost ? updatedHost.mId : null);
    setHost(updatedHost);

    updateRundownSync({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
    });
  };

  const updateRundownState = newState => {
    const payloadInput = {
      crudAction: 'UPDATE',
    };

    const mPayload = [payloadInput];

    const input = {
      mId: mid,
      mRefId: mid,
      mState: newState,
      mPayload,
    };
    updateRundownSync({
      variables: {
        input,
      },
    });
  };

  const updatePreparingState = newState => {
    const payloadInput = {
      crudAction: 'UPDATE',
    };

    const mPayload = [payloadInput];

    const input = {
      mId: mid,
      mRefId: mid,
      mPreparingState: newState,
      mPayload,
    };
    updateRundownSync({
      variables: {
        input,
      },
    });
  };

  const rundownRefetch = async () => {
    await refetch();
  };

  const handleBatchUpdateRundownInstanceState = async newState => {
    if (!(data && data.getRundown && data.getRundown.mOrder && data.getRundown.mOrder.length > 0))
      return;

    const filteredMIds = data.getRundown.mOrder.filter(instanceId => instanceId[0] !== '-');

    const input = {
      instances: filteredMIds.map(instanceId => ({
        mId: instanceId,
        mState: newState,
      })),
    };

    await batchUpdateInstances({ variables: { input } });
  };

  return (
    data &&
    data.getRundown && (
      <SelectionContext>
        <LoadingContext>
          <RundownView
            data={data}
            selecteddate={selecteddate}
            title={title}
            mId={mid}
            form={metadataForms[0]}
            refId={data.getRundown.mRefId}
            batchUpdateRundownInstanceState={handleBatchUpdateRundownInstanceState}
            {...{
              mid,
              showMasterEditView,
              setShowMasterEditView,
              updateMasterRundown,
              updateRundownState,
              updatePreparingState,
              updateRundownHost,
              host,
              onArchiveMasterRundown,
              rundownRefetch,
            }}
            {...rest}
          />
        </LoadingContext>
      </SelectionContext>
    )
  );
};

export default RundownContainer;
