import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SurfaceOverlay from 'assets/images/SurfaceOverlay.png';
import DetailHeader from '../detailHeader';
import AvatarRow from './avatarRow';
import useStyles from './avatar-header-styles';

const AvatarHeaderView = ({
  id,
  hideControls,
  backButtonLabel,
  onBackButtonClick,
  favorited,
  onFavoriteClick,
  subscribed,
  onSubscribeClick,
  onMenuItemClick,
  hideIcons,
  menuItems,
  variant,
  disableUpload,
  avatarUrl,
  title,
  subtitle,
  additionalInfo,
  updateAvatar,
  className,
  style,
  isSummary,
  onLinkClick,
  hideMenu,
  updateDepartmentTitle,
  showMenu,
  onMenuButtonClick,
}) => {
  const classes = useStyles();
  const [backgroundAvatar, setBackgroundAvatar] = useState(avatarUrl);

  return (
    <div
      className={`${classes.root} ${className}`}
      style={{
        backgroundImage: backgroundAvatar ? 'none' : `url(${SurfaceOverlay})`,
        ...style,
      }}
    >
      {backgroundAvatar && (
        <img src={backgroundAvatar} alt="Background Avatar" className={classes.avatar} />
      )}

      {!hideControls && (
        <DetailHeader
          {...{
            onMenuItemClick,
            backButtonLabel,
            onBackButtonClick,
            favorited,
            onFavoriteClick,
            subscribed,
            onSubscribeClick,
            hideIcons,
            menuItems,
            isSummary,
            onLinkClick,
            hideMenu,
            showMenu,
            onMenuButtonClick,
          }}
        />
      )}

      <AvatarRow
        {...{
          id,
          variant,
          disableUpload,
          avatarUrl,
          title,
          subtitle,
          additionalInfo,
          updateDepartmentTitle,
        }}
        onImageUpload={(imageFile, imageUrl) => {
          updateAvatar(imageFile, imageUrl);
          setBackgroundAvatar(imageUrl);
        }}
      />
    </div>
  );
};

AvatarHeaderView.propTypes = {
  /** Defines Selected item id */
  id: PropTypes.string,
  /** Defines the text to shown beside the back arrow */
  backButtonLabel: PropTypes.string,
  /** Callback to be invoked on back button click */
  onBackButtonClick: PropTypes.func,
  /** When true hides the secondary icons */
  hideIcons: PropTypes.bool,
  /** Defines if the item is favorited or not */
  favorited: PropTypes.bool,
  /** Callback to be invoked on favorite button click */
  onFavoriteClick: PropTypes.func,
  /** Defines if subscription is on or not */
  subscribed: PropTypes.bool,
  /** Callback to be invoked on subscribe button click */
  onSubscribeClick: PropTypes.func,
  /** Defines the primary header text */
  title: PropTypes.string,
  /** Defines the secondary header text */
  subtitle: PropTypes.string,
  /** If true, disables the action menu for image upload */
  disableUpload: PropTypes.bool,
  /** Defines additional header components to render */
  additionalInfo: PropTypes.node,
  /** Defines the avatar header variant */
  isSummary: PropTypes.bool,
  /** Updates title of the department */
  updateDepartmentTitle: PropTypes.func,
  /** callback to update avatar image for the department */
  updateAvatar: PropTypes.func,
};

AvatarHeaderView.defaultProps = {
  id: null,
  backButtonLabel: 'Back',
  onBackButtonClick: () => {},
  hideIcons: false,
  favorited: false,
  onFavoriteClick: () => {},
  subscribed: false,
  onSubscribeClick: () => {},
  title: 'The Title',
  subtitle: 'Subtitle',
  disableUpload: false,
  additionalInfo: null,
  isSummary: false,
  updateDepartmentTitle: () => {},
  updateAvatar: () => {},
};

export default AvatarHeaderView;
