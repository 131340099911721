import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import MenuItem from '../MenuItem/InstanceMenuItem';
import PlatformIcons from '../PlatformIcons';

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: theme.palette.dina.mediumEmphasis,
    marginLeft: '15px',
  },
}));

const SingleInstance = props => {
  const { instanceItems, onClose } = props;
  const classes = useStyles();
  const task = instanceItems.find(i => i.mProperties.platform === 'task');
  const PlatformIconTask = PlatformIcons.task;
  const instanceSelection = instanceItems.filter(
    i => i.mProperties.platform !== 'task'
  );
  return (
    <>
      <Typography className={classes.text}>CREATE INSTANCE</Typography>

      {instanceSelection.map(item => {
        const { platformIcon } = item.mProperties;

        const PlatformIcon = PlatformIcons[platformIcon]
          ? PlatformIcons[platformIcon]
          : PlatformIcons.defaultIcon;
        return (
          <MenuItem
            key={item.mTitle}
            label={
              item.mProperties.platform.charAt(0).toUpperCase() +
              item.mProperties.platform.slice(1)
            }
            noOfSubInstance={
              item.mProperties.accounts.length === 0
                ? undefined
                : item.mProperties.accounts.length
            }
            items={item.mProperties.accounts}
            onClose={account =>
              onClose({ platform: item.mProperties.platform, account })
            }
          >
            <SvgIcon>
              <PlatformIcon />
            </SvgIcon>
          </MenuItem>
        );
      })}

      {task ? (
        <>
          <Typography className={classes.text}>CREATE TASK</Typography>
          <MenuItem
            key={task.mTitle}
            label={
              task.mProperties.platform.charAt(0).toUpperCase() +
              task.mProperties.platform.slice(1)
            }
            noOfSubInstance={
              task.mProperties.accounts.length === 0
                ? undefined
                : task.mProperties.accounts.length
            }
            items={task.mProperties.accounts}
            onClose={account =>
              onClose({ platform: task.mProperties.platform, account })
            }
          >
            <SvgIcon>
              <PlatformIconTask />
            </SvgIcon>
          </MenuItem>
        </>
      ) : null}
    </>
  );
};

SingleInstance.propTypes = {
  /** Content that will be shown on the Menu */
  instanceItems: PropTypes.oneOfType([PropTypes.array]),
  /** onClose function */
  onClose: PropTypes.func,
};

SingleInstance.defaultProps = {
  instanceItems: [],
  onClose: () => {},
};

export default SingleInstance;
