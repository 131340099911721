import AddIcon from 'assets/icons/systemicons/add.svg';
import categoryIconData from 'assets/icons/systemicons/Categories/categoryIcons';

const categoryIconLoader = id => {
  const topicIcon = categoryIconData.filter(icon => icon.mId === id);
  return topicIcon.length ? topicIcon[0].mAvatarUrl : AddIcon;
};

export const categoryAssetLoader = id => {
  const category = categoryIconData.find(c => c.mId === id) || {};
  return {
    icon: category.mAvatarUrl || AddIcon,
    color: category.color || 'defaultCategoryColor',
  };
};

export default categoryIconLoader;
