import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ChipInput from 'material-ui-chip-input';
import useDebounce from 'hooks/use-debounce';
import Button from '@material-ui/core/IconButton';
import searchIcon from 'assets/icons/systemicons/search.svg';
import Icon from 'components/icon/icon-view';
import ICONS from 'assets/icons/icons';
import AdvancedSearchDialog from './advanced/dialog';
import useStyles from './searchbar-styles';

const Searchbar = props => {
  const classes = useStyles(searchIcon)();
  const {
    searchHandler,
    chips,
    chipChangeHandler,
    customFilter,
    toggleCustomFilter,
    advancedInputFields,
    submitAdvanced,
    clearAdvancedInput,
  } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const [advancedFilterButtonAnchorEl, setAdvancedFilterButtonAnchorEl] = useState(null);

  useEffect(() => {
    searchHandler(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const addChip = chip => {
    const temp = chips.filter(c => c.label !== chip).concat([{ label: chip, expression: chip }]);
    chipChangeHandler(temp);
  };

  const removeChip = chip => {
    if (chip && chips.length < 1) {
      toggleCustomFilter(chip);
    }

    const temp = chips.filter(c => c.label !== chip);
    chipChangeHandler(temp);
    setSearchTerm('');
  };

  const chipLabels = () => {
    return chips.map(chip => chip.label);
  };

  const clearAll = () => {
    chipChangeHandler([]);
    if (customFilter) toggleCustomFilter();
  };

  const clearInput = () => {
    removeChip(searchTerm);
  };

  return (
    <div className={classes.searchbar}>
      <div className={classes.inputContainer}>
        <ChipInput
          value={customFilter ? [customFilter.label, ...chipLabels()] : chipLabels()}
          onUpdateInput={event => setSearchTerm(event.target.value)}
          classes={{
            chipContainer: classes.chipContainer,
            chip: classes.chip,
          }}
          className={classes.input}
          placeholder="Type to find..."
          disableUnderline
          onAdd={addChip}
          onBeforeAdd={() => searchTerm.length > 0}
          onDelete={removeChip}
          newChipKeys={['Enter']}
        />
        <Button
          classes={{ root: classes.clearInputButton }}
          onClick={clearInput}
          title="Clear input"
        >
          <Icon
            className={classes.searchbarIcon}
            icon={ICONS.CLOSE_SMALL}
            height={24}
            width={24}
            color="rgba(255, 255, 255, 0.25)"
          />
        </Button>
      </div>

      {advancedInputFields ? (
        <Button
          classes={{ root: classes.searchBarButton }}
          onClick={e => setAdvancedFilterButtonAnchorEl(e.currentTarget.parentNode)}
          title="Filter options"
        >
          <Icon className={classes.searchbarIcon} icon={ICONS.FILTERS_OFF} height={24} width={24} />
        </Button>
      ) : null}
      <Button
        classes={{ root: classes.searchBarButton }}
        onClick={clearAll}
        title="Clear all filters"
      >
        <Icon className={classes.searchbarIcon} icon={ICONS.CLEAR} height={24} width={24} />
      </Button>
      <AdvancedSearchDialog
        anchorEl={advancedFilterButtonAnchorEl}
        closeDialog={() => setAdvancedFilterButtonAnchorEl(null)}
        submit={submitAdvanced}
        clearInput={clearAdvancedInput}
      >
        {advancedInputFields}
      </AdvancedSearchDialog>
    </div>
  );
};

Searchbar.propTypes = {
  /** function that will handle chip changes */
  chipChangeHandler: PropTypes.func,
  /** array with chips that will be displayed on the searchbar */
  chips: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      expression: PropTypes.string,
    }),
  ),
  /** if there is a custom filter, that filter will be displayed with just the label */
  customFilter: PropTypes.shape({
    label: PropTypes.string,
    expression: PropTypes.arrayOf(PropTypes.object),
  }),
  /** search handler that will be called when text is entered in the searchbar */
  searchHandler: PropTypes.func,
  /** (un)toggle custom filter when chip is removed */
  toggleCustomFilter: PropTypes.func,
  /** advanced input fields */
  advancedInputFields: PropTypes.element,
  /** submit advanced input func */
  submitAdvanced: PropTypes.func,
  /** clear advanced input fields */
  clearAdvancedInput: PropTypes.func,
};

Searchbar.defaultProps = {
  searchHandler: () => {},
  chipChangeHandler: () => {},
  chips: [],
  customFilter: null,
  toggleCustomFilter: () => {},
  advancedInputFields: null,
  submitAdvanced: () => {},
  clearAdvancedInput: () => {},
};

export default Searchbar;
