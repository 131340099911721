import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    width: '506px',
    height: '242px',
    background: theme.palette.dina.surfaceCard,
    backdropFilter: 'blur(30px)',
    borderRadius: '8px',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    borderRadius: '8px',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    ...theme.typography.dina.marketplaceHeader,
    margin: '24px 48px 8px 0',
    marginLeft: '16px',
  },
  subHeading: {
    ...theme.typography.dina.overline,
    marginTop: '7px',
  },
  inputContainer: {
    marginRight: '16px',
  },
  buttonLayout: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '5px 15px 6px 0',
  },
  button: {
    minHeight: '40px',
    width: '168px',
    marginLeft: '8px',
  },
  info: {
    margin: '12px 16px 8px 16px',
    height: '100%',
  },
  infoText: {
    ...theme.typography.dina.body1,
    color: theme.palette.dina.mediumEmphasis,
  },
}));

export default useStyles;
