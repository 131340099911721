import { useMutation } from 'react-apollo';
import { deleteFromS3 } from 'utils/s3Utils';
import DELETE_CONTENT_TEMPLATE from 'graphql/mutations/deleteContentTemplate';
import GET_FOLDERS from 'graphql/queries/getFolders';

const useCreateStory = () => {
  const [deleteFolderMutation] = useMutation(DELETE_CONTENT_TEMPLATE);

  const deleteFolder = async (mId, mRefId, mContentKey) => {
    const input = {
      mId,
      mRefId,
    };
    await deleteFolderMutation({
      variables: { input },
      update: (proxy, mutationResult) => {
        const { mRefId: deletedId } = mutationResult.data.deleteSingleMember;
        try {
          const folderList = proxy.readQuery({
            query: GET_FOLDERS,
          });

          const newFolderList = folderList.getFolders.map(folder => {
            if (folder.mRefId === mId) {
              const newFolder = folder;
              newFolder.items = folder.items.filter(
                item => item.mRefId !== deletedId,
              );
              return newFolder;
            }
            return folder;
          });

          proxy.writeQuery({
            query: GET_FOLDERS,
            data: { getFolders: newFolderList },
          });
        } catch (error) {
          // console.log(error);
        }
      },
    }).then(() => {
      try {
        deleteFromS3(mContentKey);
      } catch (err) {
        // console.log(err);
      }
    });
  };

  return [deleteFolder];
};

export default useCreateStory;
