import React from 'react';
import { MenuItem as MaterialMenuItem } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '&$selected': {
      backgroundColor: theme.palette.dina.onSelected,
    },
    '&:hover': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
  },
  label: {
    ...theme.typography.dina.rightColumnHeaderText,
    color: theme.palette.dina.highEmphasis,
    width: '215px',
    marginLeft: '-10px',
  },
  icon: {
    color: theme.palette.dina.mediumEmphasis,
  },
  primary: {
    color: theme.palette.dina.mediumEmphasis,
  },
  selected: {},
}));

const MenuItem = props => {
  const classes = useStyles();
  const { label, children, onClose, ...others } = props;
  return (
    <>
      <MaterialMenuItem
        onClick={onClose}
        classes={{
          root: classes.root,
          selected: classes.selected,
        }}
        {...others}
      >
        <ListItemIcon className={classes.icon}>{children}</ListItemIcon>
        <ListItemText classes={{ primary: classes.label }} primary={label} />
      </MaterialMenuItem>
    </>
  );
};

export default MenuItem;
