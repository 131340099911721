import { publishingPoints } from 'assets/icons/publishingPoints';
import React from 'react';
import { useDrag } from 'react-dnd';
import types from 'utils/dndTypes';
import useStyles from './instance-styles';

const Instance = ({ instance }) => {
  const classes = useStyles();
  const [, dragRef] = useDrag({ item: { type: types.INSTANCE, instance } });
  const { publishingPlatform, publishingPoint } = instance;
  const { mProperties } = publishingPlatform;
  const { platformIcon } = mProperties;

  return (
    <div ref={dragRef} className={classes.root}>
      <img
        src={
          publishingPoints[platformIcon] ? publishingPoints[platformIcon] : publishingPoints.default
        }
        className={classes.image}
        alt={publishingPoint}
      />
    </div>
  );
};

export default Instance;
