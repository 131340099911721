/* eslint-disable no-param-reassign */
import isUrl from 'is-url';
import wrapLink from './wrapLink';

/**
 * Wraps editor with overriden plugin functionalites
 *
 * @param {Object} editor SlateJS editor instance
 * @returns {Object} SlateJS editor instance
 */

const withLink = editor => {
  const { insertData, insertText } = editor;

  editor.insertText = text => {
    if (text && isUrl(text)) wrapLink(editor, text);
    else insertText(text);
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) wrapLink(editor, text);
    else insertData(data);
  };

  return editor;
};

export default withLink;
