import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useInputEvents from 'hooks/useInputEvents';
import useStyles from './duration-styles';

const DurationView = ({ duration, onUpdate, disableEdit, size }) => {
  const classes = useStyles({ size });
  const [value, setValue] = useState(duration);

  const onUpdateInput = newValue => {
    if (newValue === duration) setValue(newValue);
    else onUpdate(newValue);
  };

  const [inputRef, onKeyDown, onBlur] = useInputEvents(onUpdateInput, value, duration);

  useEffect(() => setValue(duration), [duration]);

  const onChange = event => setValue(event.target.value);

  return (
    <div className={classes.root}>
      <input
        className={classes.input}
        type="text"
        ref={inputRef}
        disabled={disableEdit}
        {...{ value, onChange, onBlur, onKeyDown }}
      />
    </div>
  );
};

DurationView.propTypes = {
  /** Callback to be invoked when user finishes updating duration value and
   * moves focus to elsewhere, with the updated value passed in
   */
  onUpdate: PropTypes.func,
  /** If true, disables editability of the duration value */
  disableEdit: PropTypes.bool,
};

DurationView.defaultProps = {
  onUpdate: newDuration => {},
  disableEdit: false,
};

export default DurationView;
