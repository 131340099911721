import { makeStyles } from '@material-ui/styles';

const useStyles = selected =>
  makeStyles(theme => ({
    chip: {
      margin: '0 4px',
      height: '32px',
      padding: '1px 11px 0 11px',
      background: selected ? theme.palette.dina.highEmphasis : 'none',
      ...theme.typography.dina.listItemLabel,
      color: () => {
        if (!selected) return theme.palette.dina.mediumEmphasis;
        return theme.palette.dina.onSelected;
      },
      border: `1px solid ${theme.palette.dina.mediumEmphasis}`,
      textTransform: 'capitalize',
      '&:hover': {
        background: selected ? theme.palette.dina.highEmphasis : 'none',
      },
    },
    keepButton: {
      width: '80px',
      height: '32px',
      boxSizing: 'border-box',
      padding: 0,
      borderRadius: '16px 0px 0px 16px',
      backgroundColor: theme.palette.dina.statusApproved,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        backgroundColor: theme.palette.dina.statusApproved,
      },
    },
    removeButton: {
      width: '80px',
      height: '32px',
      padding: 0,
      borderRadius: '0px 16px 16px 0px',
      backgroundColor: theme.palette.dina.statusError,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        backgroundColor: theme.palette.dina.statusError,
      },
    },
    buttonText: {
      ...theme.typography.dina.listItemLabel,
      letterSpacing: '0.25px',
      textTransform: 'none',
    },
    deletable: {
      '&:focus': {
        background: selected ? theme.palette.dina.highEmphasis : 'none',
        color: () => {
          if (!selected) return theme.palette.dina.mediumEmphasis;
          return theme.palette.dina.onSelected;
        },
      },
    },
    deleteIconHovered: {
      color: theme.palette.dina.mediumEmphasis,
    },
    deleteIcon: {
      display: 'none',
    },
  }));

export default useStyles;
