import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Wrapper = ({ children, showRightMargin }) => {
  const classes = useStyles({ showRightMargin });

  return <div className={classes.root}>{children}</div>;
};

Wrapper.propTypes = {
  /** React children */
  children: PropTypes.node,
  /** Specifies if right margin should be added or not */
  showRightMargin: PropTypes.bool,
};

Wrapper.defaultProps = {
  children: null,
  showRightMargin: false,
};

export default memo(Wrapper);
