import React from 'react';
import { Typography } from '@material-ui/core';
import { ReactComponent as Icon } from 'assets/icons/systemicons/editions_selected.svg';
import useStyles from './multiSelectState-styles';

const MultiSelectState = ({ selectionCount }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Icon className={classes.icon} />
        <Typography classes={{ root: classes.text }}>
          {`${selectionCount} Instances selected`}
        </Typography>
      </div>
    </div>
  );
};

export default MultiSelectState;
