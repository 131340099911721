import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    '&:hover': {
      background: theme.palette.dina.surfaceAppBackgroundMain,
    },
    '&:focus': {
      backgroundColor: theme.palette.dina.onFocus,
    },
  },
  title: {
    ...theme.typography.dina.overline,
    width: '287px',
    marginTop: '-3px',
  },
  content: {
    ...theme.typography.dina.rightColumnHeaderText,
    color: theme.palette.dina.highEmphasis,
    width: '287px',
    minHeight: '30px',
  },
  dividerWrapper: {
    paddingLeft: 16,
  },
}));

export default useStyles;
