/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { useSidebarContext } from 'globalState';
import Group from './group-view';

const groupTarget = {
  drop(props) {
    return props;
  },
};

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  hovered: monitor.isOver(),
});

const ListGroup = props => {
  const {
    title,
    children,
    onEllipsis,
    connectDropTarget,
    hovered,
    id,
    hasMore,
    handleCreateClicked,
    type,
    onLoadMore,
    toolTipTitle,
    ...rest
  } = props;

  const [{ openGroups }, dispatch] = useSidebarContext();

  const isGroupOpen = () => {
    return !!Object.prototype.hasOwnProperty.call(openGroups, id);
  };

  const toggleGroupOpen = () => {
    const groups = openGroups;
    if (isGroupOpen()) {
      delete groups[id];
    } else {
      groups[id] = { open: true };
    }

    dispatch({ type: 'setOpenGroups', payload: groups });
  };

  const [listOpen, setListOpen] = useState(isGroupOpen(id));

  const toggleListOpen = () => {
    toggleGroupOpen(id);
    setListOpen(!listOpen);
  };

  useEffect(() => {
    if (hovered && !listOpen) toggleListOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  return connectDropTarget(
    <div>
      <Group
        open={listOpen}
        title={title}
        onEllipsis={onEllipsis}
        toggleListOpen={() => toggleListOpen()}
        toolTipTitle={toolTipTitle}
        {...{ handleCreateClicked, type, onLoadMore, hasMore }}
        {...rest}
      >
        {children}
      </Group>
    </div>
  );
};

ListGroup.propTypes = {
  /** Title of the list group */
  title: PropTypes.string,
  /** List items */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  /** should the header have options? */
  onEllipsis: PropTypes.bool,
  /** group id */
  id: PropTypes.string,
  /** has more token */
  hasMore: PropTypes.bool,
  /** load more function */
  onLoadMore: PropTypes.func,
};

ListGroup.defaultProps = {
  title: 'Group',
  children: null,
  onEllipsis: false,
  id: '',
  hasMore: false,
  onLoadMore: () => {},
};

export default DropTarget(['story', 'contact'], groupTarget, collect)(
  ListGroup
);
