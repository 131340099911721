/**
 * Given a date in ISO format, and Updated Time returns updated ISO string with updated time
 *
 * @param {string} previousDate string representation of ISO date
 * @param {string} updatedTime  string representation of updated start time, format 'HH:mm:ss'
 * @returns {string} string representation of ISO updated date-time
 */

import { setHours, setMinutes, setSeconds } from 'date-fns';

const changeTimeOfISODate = (previousDate, updatedTime) => {
  const [hour, minute, second] = updatedTime.split(':');
  let time = previousDate;
  time = setHours(time, hour);
  time = setMinutes(time, minute);
  if (second) time = setSeconds(time, second);
  else time = setSeconds(time, '00');
  
  return time.toISOString();
};

export default changeTimeOfISODate;
