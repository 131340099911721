const actionTypes = {
  ASSET_INSERT: 'asset-insert',
  ASSET_REMOVE: 'asset-remove',
  AUTOMATION_INSERT: 'automation-insert',
  AUTOMATION_REMOVE: 'automation-remove',
  AUTOMATION_UPDATE: 'automation-update',
  CHANGE: 'change',
  CREATE_ASSET: 'create-asset',
  CREATE_PLACEHOLDER: 'create-placeholder',
  REMOVE_PLACEHOLDER: 'remove_placeholder',
  BLOCK_UPDATE: 'block-update',
};

export default actionTypes;
