import React from 'react';
import PropTypes from 'prop-types';
import { Tab, IconButton } from '@material-ui/core';
import { DropTarget } from 'react-dnd';
import CloseSmall from 'assets/icons/systemicons/close_small.svg';
import RundownIcon from 'assets/icons/systemicons/HeaderNavbar/rundown_off.svg';
import StoryIcon from 'assets/icons/systemicons/HeaderNavbar/stories_off.svg';
import PitchIcon from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_off.svg';
import Notification from 'components/statusIndicators/notification/Notification';
import { UNTITLED_STORY } from 'utils/constants';
import { formatDateToLocaleString } from 'utils/formatDate';
import useStyles from './content-styles';

const storyTabTarget = {
  drop(props) {
    return { id: props.id };
  },
};

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  hovered: monitor.isOver(),
  contact: monitor.getItem(),
});

const tabIcons = {
  rundown: RundownIcon,
  story: StoryIcon,
  pitch: PitchIcon,
  create: StoryIcon,
};

const StoryTab = props => {
  const {
    label,
    id,
    pinned,
    notificationCount,
    connectDropTarget,
    hovered,
    openStory,
    closeTab,
    type,
    selecteddate,
    mType,
    ...rest
  } = props;
  const classes = useStyles();

  const labelText = pinned ? classes.labelTextWrapPinned : classes.labelTextWrap;

  const tabIcon = tabIcons[type];
  const tabTitle = label || UNTITLED_STORY;
  const shortDate = mType === 'rundown' ? formatDateToLocaleString(selecteddate) : '';
  return connectDropTarget(
    <div>
      <Tab
        key={id}
        onClick={openStory}
        className={`${classes.tab} ${hovered ? classes.tabHovered : ''}`}
        {...rest}
        label={
          <div className={classes.container}>
            <img src={tabIcon} alt="Close tab icon" className={classes.tabIcon} />
            <div className={classes.label}>
              <div>
                <div className={labelText}>{tabTitle} </div>
                <div className={labelText}>{shortDate} </div>
              </div>
              {notificationCount > 0 && (
                <div className={classes.notification}>
                  <Notification notificationCount={notificationCount} />
                </div>
              )}
            </div>
          </div>
        }
      />
      <IconButton
        className={classes.iconButton}
        onClick={closeTab}
        classes={{ label: classes.iconButtonLabel }}
      >
        <img src={CloseSmall} alt="Close tab icon" />
      </IconButton>
    </div>,
  );
};

StoryTab.propTypes = {
  /** Story label */
  label: PropTypes.string.isRequired,
  /** Story id */
  id: PropTypes.string.isRequired,
  /** Story pinned */
  pinned: PropTypes.bool,
  /** is it hovered */
  hovered: PropTypes.bool,
  /** number to show on indicator */
  notificationCount: PropTypes.number,
  /** open story function */
  openStory: PropTypes.func.isRequired,
  /** close tab function */
  closeTab: PropTypes.func.isRequired,
  /** type of tab? rundown or story */
  type: PropTypes.string,
  /** mType member type */
  mType: PropTypes.string,
  /** the date, used to differentiate rundowns */
  selecteddate: PropTypes.string,
};

StoryTab.defaultProps = {
  pinned: false,
  notificationCount: 0,
  hovered: false,
  type: '',
  selececteddate: '',
};

export default DropTarget('member', storyTabTarget, collect)(StoryTab);
