import { makeStyles } from '@material-ui/styles';

const useStyles = color =>
  makeStyles(theme => {
    const backgroundColor = theme.palette.dina[color];
    return {
      root: {
        height: '3px',
        width: '24px',
        borderRadius: '2px',
        marginRight: '4px',
      },
      backgroundDiv: {
        backgroundColor,
        width: '100%',
        height: '100%',
      },
      tooltip: {
        backgroundColor,
      },
    };
  });

export default useStyles;
