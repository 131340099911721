import getMosAsset from 'components/editor/utils/getMosAsset';
import { actionTypes } from 'components/editor/constants/types';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import findAndReplace from 'utils/findAndReplace';

/**
 * Adds graphics media to given element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element to be updated
 * @param {Object} payload Data payload of dropped item
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const addGraphics = (editor, element, payload, update) => {
  const asset = getMosAsset(payload);

  if (asset) {
    const { data } = element;
    const { assets: previousAssets, ...rest } = data;
    const newAsset = { ...asset, mosobj: payload };

    const updatedData = {
      ...rest,
      assets: findAndReplace(previousAssets, newAsset, 'assetType'),
    };

    Transforms.setNodes(editor, { data: updatedData });
    update({
      type: actionTypes.AUTOMATION_UPDATE,
      payload: { document: editor.children, updatedData },
    });

    ReactEditor.focus(editor);
  }
};

export default addGraphics;
