import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@material-ui/core';
import { ReactComponent as InsertEmptyIcon } from 'assets/icons/systemicons/rundown/gridIcons/insertEmpty.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/rundown/gridIcons/add.svg';
import { ReactComponent as OpenIcon } from 'assets/icons/systemicons/rundown/gridIcons/open.svg';
import { ReactComponent as FloatIcon } from 'assets/icons/systemicons/rundown/gridIcons/float.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/systemicons/rundown/gridIcons/minus.svg';
import SlidingButtonWrapper from 'components/buttons/wrapper/SlidingButtonWrapper';
import useStyles from './actionButton-styles';

const ActionButtons = ({
  onRemoveClick,
  onOpenStoryClick,
  onFloatClick,
  onCreateInstanceClick,
  onInsertEmptyClick,
  onClose,
  isFloat,
  isPlaceholder,
  disableEdit,
  ...rest
}) => {
  const classes = useStyles();

  const returnActionButton = (label, Icon, classDef, handleClick) => {
    return (
      <Button
        variant="contained"
        onClick={event => {
          event.stopPropagation();
          handleClick(event);
        }}
        classes={{ root: classes[classDef] }}
        tabIndex="-1"
      >
        <Icon className={classes.icon} />
        {label && label}
      </Button>
    );
  };

  return (
    <SlidingButtonWrapper {...rest}>
      <div className={classes.buttonContanier}>
        {!disableEdit && returnActionButton('Remove', RemoveIcon, 'removeRoot', onRemoveClick)}
        {!isPlaceholder && returnActionButton('Open Story', OpenIcon, 'openRoot', onOpenStoryClick)}
        {!disableEdit &&
          returnActionButton('Insert Empty', InsertEmptyIcon, 'emptyRoot', onInsertEmptyClick)}
        {!disableEdit &&
          returnActionButton('New Instance', AddIcon, 'createRoot', onCreateInstanceClick)}
        {!isPlaceholder &&
          !disableEdit &&
          returnActionButton(isFloat ? 'Unfloat' : 'Float', FloatIcon, 'floatRoot', onFloatClick)}
        <IconButton
          tabIndex="-1"
          onClick={event => {
            event.stopPropagation();
            onClose(event);
          }}
          title="Close"
          classes={{ root: classes.iconButton }}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
    </SlidingButtonWrapper>
  );
};

ActionButtons.propTypes = {
  onRemoveClick: PropTypes.func,
  onOpenStoryClick: PropTypes.func,
  onFloatClick: PropTypes.func,
  onCreateInstanceClick: PropTypes.func,
  onInsertEmptyClick: PropTypes.func,
  onClose: PropTypes.func,
  /** Boolean that stops an user from editing a ready rundown */
  disableEdit: PropTypes.bool,
};

ActionButtons.defaultProps = {
  onRemoveClick: () => {},
  onOpenStoryClick: () => {},
  onFloatClick: () => {},
  onCreateInstanceClick: () => {},
  onInsertEmptyClick: () => {},
  onClose: () => {},
  disableEdit: false,
};

export default ActionButtons;
