import React from 'react';
import PropTypes from 'prop-types';
import FilledInput from '@material-ui/core/FilledInput';
import Icon from 'components/icon/icon-view';
import useStyles from './input-styles';

const FreeTextInput = props => {
  const { icon, label, value, onChange } = props;
  const classes = useStyles();

  return (
    <div className={classes.inputWrapper}>
      <Icon icon={icon} height={24} width={24} />
      <FilledInput
        fullWidth
        value={value}
        placeholder={label}
        onChange={e => onChange(e.target.value)}
        classes={{
          root: classes.root,
          underline: classes.underline,
          input: classes.input,
        }}
      />
    </div>
  );
};

FreeTextInput.propTypes = {
  /** icon */
  icon: PropTypes.string.isRequired,
  /** input label */
  label: PropTypes.string.isRequired,
  /** controlled form value */
  value: PropTypes.string,
  /** onChange handler */
  onChange: PropTypes.func,
};

FreeTextInput.defaultProps = {
  value: '',
  onChange: () => {},
};

export default FreeTextInput;
