import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    cursor: 'pointer',
    '& *': {
      fill: ({ isActive }) => isActive && palette.dina.statusOnFocused,
      fillOpacity: ({ isActive }) => isActive && 1,
    },
  },
}));

export default useStyles;
