import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import UserCtx from 'contexts/UserContext';
import { CurrentTabContext } from 'globalState';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useSetStorySync from 'hooks/useSetStorySync';
import useCreateStoryFromFeed from 'hooks/useCreateStoryFromFeed';
import Button from 'components/buttons/button';
import fallbackImage from 'assets/images/default/defaultCoverPhoto.png';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import memberTypes from 'graphql/memberTypes';
import AllPitchesOff from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_off.svg';
import StoryIcon from 'assets/icons/systemicons/story.svg';
import Scrollbar from 'components/scrollbar';
import Assets from './assets';
import Content from './content';
import Header from './header';
import useStyles from './inspector-styles';

const Inspector = props => {
  const { feedItem, filters } = props;
  const {
    assets,
    mId,
    provider,
    mDescription,
    href,
    byline,
    section,
    mPublishedAt,
    mContent,
    mTitle,
  } = feedItem;

  const { mId: userId } = useContext(UserCtx);
  const [, setCurrentTab] = useContext(CurrentTabContext);
  const [createStoryFromFeed] = useCreateStoryFromFeed();
  const [checkUserRight] = useCheckUserRight();
  const enableSynchronize = checkUserRight('feature', 'story-auto-synchronize');
  const [setSync] = useSetStorySync();
  const [isCreatingStory, setIsCreatingStory] = useState(false);

  let rendition = null;
  if (assets && assets.length > 0) {
    assets.map(asset => {
      if (asset.type === 'Image' || asset.type === 'CoverImage') {
        rendition = asset.renditions[asset.renditions.length - 1];
      }
    });
  }

  const image = rendition ? rendition.href : fallbackImage;
  const classes = useStyles(image)();

  const searchWords = filters.map(({ expression }) => expression);

  const createStory = async type => {
    try {
      setIsCreatingStory(true);
      const createdStoryId = await createStoryFromFeed(mId, mTitle, userId, provider, type);
      setIsCreatingStory(false);
      if (enableSynchronize) setSync(createdStoryId, ['*']);
      setCurrentTab({
        page: type,
        mTitle,
        id: createdStoryId,
      });

      const elm = document.getElementById('scrollbar');

      if (elm && elm.firstChild) {
        setTimeout(() => {
          elm.firstChild.scrollTop = 0;
        }, 1000);
      }
    } catch (e) {
      setIsCreatingStory(false);
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  // remove search expression prefix, i.e title:
  const searchWordsWithoutPrefix = searchWords.map(word => word.split(':').pop());

  return (
    <div className={classes.root}>
      <Scrollbar valueChanged={mId}>
        <div className={classes.inspector}>
          <Header
            classes={classes}
            source={byline || provider}
            {...{ href, mPublishedAt, section, mTitle, searchWords: searchWordsWithoutPrefix }}
          />
          <Content
            classes={classes}
            {...{ mContent, mDescription, searchWords: searchWordsWithoutPrefix }}
          />
          {assets && assets.length > 0 ? <Assets assets={assets} classes={classes} /> : null}
        </div>
      </Scrollbar>

      <div className={classes.buttons}>
        <Button
          variant="contained"
          type="cta"
          onClick={() => createStory(memberTypes.PITCH)}
          disabled={isCreatingStory}
        >
          <img src={AllPitchesOff} alt="Pitch icon" />
          Create Pitch
        </Button>
        <Button
          variant="contained"
          type="significant"
          onClick={() => createStory(memberTypes.STORY)}
          disabled={isCreatingStory}
        >
          <img src={StoryIcon} alt="Story Icon" />
          Create Story
        </Button>
        {isCreatingStory ? <LoadingIndicator /> : null}
      </div>
    </div>
  );
};

Inspector.propTypes = {
  /** feed articles */
  feedItem: PropTypes.shape({
    assets: PropTypes.arrayOf(PropTypes.object),
    byline: PropTypes.string,
    href: PropTypes.string,
    mContent: PropTypes.string,
    mDescription: PropTypes.string,
    mId: PropTypes.string,
    mTitle: PropTypes.string,
    mPublishedAt: PropTypes.string,
    provider: PropTypes.string,
    section: PropTypes.string,
  }),
  filters: PropTypes.arrayOf(PropTypes.object),
};

Inspector.defaultProps = {
  feedItem: {
    assets: [],
    byline: null,
    href: '',
    mContent: '',
    mDescription: '',
    mId: '',
    mTitle: '',
    mPublishedAt: '',
    provider: '',
    section: '',
  },
  filters: [],
};

export default Inspector;
