import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import List from '@material-ui/core/List';
import { CurrentTabContext } from 'globalState';
import memberTypes from 'graphql/memberTypes';
import { getStoriesQuery } from 'graphql/queryVariables';
import GET_STORIES from 'graphql/queries/getStoriesPagination';
import BATCH_GET_MEMBERS from 'graphql/queries/batchGetMembers';
import GET_STORIES_BY_UPDATING_DATE from 'graphql/queries/getStoriesByUpdatedAtDate';
import SEARCH_STORIES from 'graphql/queries/searchApi';
import Group from 'components/listGroup';
import UserCtx from 'contexts/UserContext';
import CreateStory from 'components/createNew';
import Popover from 'components/popover/Popover';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useCreatePitch from 'hooks/useCreatePitch';
import useSetStorySync from 'hooks/useSetStorySync';
import useCreateStoryForMember from 'hooks/useCreateStoryForMember';
import Story from '../listItem';

const GroupContainer = ({
  mId,
  groupIds,
  title,
  type,
  setShowStorybox,
  openStory,
  selectedDates,
  scheduleType,
  subTabs,
  selectedSubTab,
  filters,
  onBeforeRefetch,
  onAfterRefetch,
}) => {
  const user = useContext(UserCtx);
  const { bookmarks, mId: userId } = user;
  const [, setCurrentTab] = useContext(CurrentTabContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [createStory] = useCreateStoryForMember();
  const [createPitch] = useCreatePitch();
  const [checkUserRight] = useCheckUserRight();
  const enableSynchronize = checkUserRight('feature', 'story-auto-synchronize');
  const [setSync] = useSetStorySync();

  const { startDate, endDate, ignoreStartDate } = selectedDates;

  let mType = memberTypes.USER_STORY;

  switch (type) {
    case memberTypes.DEPARTMENT:
      mType = memberTypes.DEPARTMENT_STORY;
      break;
    case memberTypes.TEAM:
      mType = memberTypes.TEAM_STORY;
      break;
    case memberTypes.USER_BOOKMARK:
      mType = memberTypes.USER_BOOKMARK;
      break;
    case memberTypes.DEPARTMENT_USER:
      mType = memberTypes.DEPARTMENT_USER;
      break;
    case memberTypes.STORY:
      mType = memberTypes.STORY;
      break;
    case memberTypes.PITCH:
      mType = memberTypes.PITCH;
      break;
    default:
      mType = memberTypes.USER_STORY;
  }

  const groupType = type === memberTypes.STORY ? type : mType;

  const onCreateStory = async mTitle => {
    try {
      setAnchorEl(null);
      let createdmId = '';
      let page = memberTypes.STORY;
      const createType = mType === memberTypes.STORY ? memberTypes.USER_STORY : mType;
      if (createType === memberTypes.PITCH) {
        createdmId = await createPitch(mId, createType, mTitle, userId);
        page = memberTypes.PITCH;
      } else {
        createdmId = await createStory(mId, createType, mTitle, userId, groupType);
        if (enableSynchronize && createdmId) setSync(createdmId, ['*']);
      }
      setCurrentTab({
        page,
        title: mTitle,
        id: createdmId,
      });

      const elm = document.getElementById('scrollbar');

      if (elm && elm.firstChild) {
        setTimeout(() => {
          elm.firstChild.scrollTop = 0;
        }, 1000);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  let query = GET_STORIES;
  let variables = getStoriesQuery(mId, mType);
  let skip = false;
  variables.input.startDate = startDate.toISOString();
  variables.input.endDate = endDate.toISOString();
  variables.input.ignoreStartDate = ignoreStartDate;

  if (mType === memberTypes.USER_BOOKMARK) {
    query = BATCH_GET_MEMBERS;
    const mIds = Object.keys(user.bookmarks);
    variables = { input: { mIds } };
    skip = mIds.length === 0;
  }

  if (mType === memberTypes.STORY) {
    query = GET_STORIES_BY_UPDATING_DATE;
    variables = {
      input: {
        mType: subTabs[selectedSubTab] === 'archived' ? 'archived_story' : 'story',
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        ignoreStartDate,
        scheduleType,
      },
      limit: 50,
    };
  }

  if (mType === memberTypes.PITCH) {
    query = GET_STORIES_BY_UPDATING_DATE;
    variables = {
      input: {
        mType: 'pitch',
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        ignoreStartDate,
        scheduleType,
      },
      limit: 50,
    };
  }

  if (filters && filters.length > 0) {
    const searchString = filters.map(filter => filter.expression).join(' ');
    let isUnscheduled = null;
    let tmpType = 'story';
    const assignedMembers = [];
    switch (mType) {
      case memberTypes.PITCH:
        tmpType = 'pitch';
        break;
      default:
        tmpType = 'story';
    }

    tmpType = subTabs[selectedSubTab] === 'archived' ? 'archived_story' : tmpType;

    switch (scheduleType) {
      case 'scheduled':
        isUnscheduled = false;
        break;
      case 'unscheduled':
        isUnscheduled = true;
        break;
      case 'all':
        isUnscheduled = null;
        break;
      default:
        isUnscheduled = null;
        break;
    }
    if (mType === memberTypes.USER_STORY) {
      assignedMembers.push(userId);
      isUnscheduled = null;
    }
    if (mType === memberTypes.TEAM_STORY) {
      assignedMembers.push(...groupIds);
      isUnscheduled = null;
    }

    query = SEARCH_STORIES;

    const filter = {
      searchString,
      isUnscheduled,
      mType: tmpType,
      assignedMembers,
      searchBefore: endDate.toISOString(),
    };
    if (!ignoreStartDate) {
      filter.searchAfter = startDate.toISOString();
    }
    variables = {
      limit: 25,
      filter,
    };
  }

  const { data, error, loading, fetchMore } = useQuery(query, {
    variables,
    fetchPolicy: 'cache-and-network',
    skip,
  });

  if (loading) return <LoadingIndicator />;

  if (error) {
    const errorMessage = `Error! ${error.message}`;
    return <span style={{ color: 'white' }}>{errorMessage}</span>;
  }

  const hasMore = !!(
    data &&
    ((data.getMembersPagination && data.getMembersPagination.nextToken) ||
      (data.getMembersByUpdatedAtDate && data.getMembersByUpdatedAtDate.nextToken) ||
      (data.searchApi && data.searchApi.nextToken))
  );

  const fetchMoreStories = async () => {
    const scrollTopBefore = onBeforeRefetch();
    if (
      (mType === memberTypes.STORY || mType === memberTypes.PITCH) &&
      data &&
      data.getMembersByUpdatedAtDate &&
      data.getMembersByUpdatedAtDate.nextToken !== null
    ) {
      await fetchMore({
        variables: {
          ...variables,
          nextToken: data.getMembersByUpdatedAtDate.nextToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          // Add the new  items to the end of the old  items.
          getMembersByUpdatedAtDate: {
            items: [
              ...previousResult.getMembersByUpdatedAtDate.items,
              ...fetchMoreResult.getMembersByUpdatedAtDate.items,
            ],
            nextToken: fetchMoreResult.getMembersByUpdatedAtDate.nextToken,
            __typename: 'MemberType',
          },
        }),
      });
    } else if (
      mType !== memberTypes.USER_BOOKMARK &&
      data &&
      data.getMembersPagination &&
      data.getMembersPagination.nextToken !== null
    ) {
      await fetchMore({
        // fetchMore query is the same as the query associated with the component.
        // In this case query is GET_STORIES.
        // map of variables to be sent with the new query
        variables: {
          ...variables,
          nextToken: data.getMembersPagination.nextToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          // Add the new  items to the end of the old  items.
          getMembersPagination: {
            items: [
              ...previousResult.getMembersPagination.items,
              ...fetchMoreResult.getMembersPagination.items,
            ],
            nextToken: fetchMoreResult.getMembersPagination.nextToken,
            __typename: 'MemberType',
          },
        }),
      });
    } else if (filters && filters.length > 0 && data.searchApi.nextToken !== null) {
      await fetchMore({
        // fetchMore query is the same as the query associated with the component.
        // In this case query is GET_STORIES.
        // map of variables to be sent with the new query
        variables: {
          ...variables,
          nextToken: data.searchApi.nextToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          // Add the new  items to the end of the old  items.
          searchApi: {
            items: [...previousResult.searchApi.items, ...fetchMoreResult.searchApi.items],
            nextToken: fetchMoreResult.searchApi.nextToken,
            __typename: 'MemberType',
          },
        }),
      });
    }
    onAfterRefetch(scrollTopBefore);
  };

  const getCreateTitle = createType => {
    switch (createType) {
      case 'story':
        return 'Create Story';
      case 'pitch':
        return 'Create Pitch';
      default:
        return 'Create';
    }
  };

  let members = [];

  const sortMembers = unsortedMembers => {
    if (scheduleType === 'scheduled') {
      const sortedByPublishingAt =
        unsortedMembers
          .filter(Boolean)
          .sort((a, b) => (a.mPublishingAt < b.mPublishingAt ? 1 : -1)) || [];
      const groupedByPublishingAt = sortedByPublishingAt.reduce((r, a) => {
        // eslint-disable-next-line no-param-reassign
        r[a.mPublishingAt] = [...(r[a.mPublishingAt] || []), a];
        return r;
      }, {});
      return Object.values(groupedByPublishingAt)
        .map(arr => arr.sort((a, b) => (a.mUpdatedAt < b.mUpdatedAt ? 1 : -1)) || [])
        .flat();
    }
    return (
      unsortedMembers.filter(Boolean).sort((a, b) => (a.mUpdatedAt < b.mUpdatedAt ? 1 : -1)) || []
    );
  };

  switch (type) {
    case memberTypes.USER_BOOKMARK:
      members = data && data.batchGetMembers ? data.batchGetMembers : [];
      // clean up bookmarks for missing stories
      if (members.includes(null)) {
        members = members.filter(Boolean);
        Object.keys(bookmarks).forEach(bm => {
          const goodBookmark = members.find(member => member.mId === bm);
          if (!goodBookmark) {
            delete bookmarks[bm];
          }
        });
      }
      break;
    case memberTypes.PITCH:
    case memberTypes.STORY:
      members =
        data && data.getMembersByUpdatedAtDate
          ? sortMembers(data.getMembersByUpdatedAtDate.items)
          : [];
      break;

    default:
      members =
        data && data.getMembersPagination
          ? data.getMembersPagination.items
              .filter(Boolean)
              .sort((a, b) => (a.mUpdatedAt < b.mUpdatedAt ? 1 : -1)) || []
          : [];
      break;
  }

  if (filters && filters.length > 0 && data && data.searchApi) {
    members = data && data.searchApi.items ? data.searchApi.items : [];
  }

  return (
    <>
      <Group
        title={title}
        id={mId}
        hasMore={hasMore}
        handleCreateClicked={e => setAnchorEl(e.currentTarget.parentNode)}
        type={mType === memberTypes.USER_BOOKMARK ? null : 'left'}
        onLoadMore={fetchMoreStories}
        toolTipTitle={getCreateTitle(type)}
      >
        <List disablePadding>
          {members.map(story => {
            if (story) {
              return (
                <Story
                  key={story.mId}
                  id={story.mId}
                  title={story.mTitle}
                  description={story.mDescription}
                  updatedAt={story.mUpdatedAt}
                  publishingAt={story.mPublishingAt}
                  assignedMembers={story.mAssignedMembers}
                  mContentKey={story.mContentKey}
                  imgUrl={story.mThumbnailUrl}
                  coverPhotoUrl={story.mAvatarUrl}
                  groupId={mId}
                  groupType={groupType}
                  storyType={story.mType}
                  queryVariables={variables}
                  {...{ openStory, setShowStorybox }}
                />
              );
            }
            return undefined;
          })}
        </List>
      </Group>
      <Popover onClose={() => setAnchorEl(null)} anchorEl={anchorEl} position="right">
        <CreateStory onCancel={() => setAnchorEl(null)} onCreate={onCreateStory} variant={type} />
      </Popover>
    </>
  );
};

GroupContainer.propTypes = {
  /** Title of the list group */
  title: PropTypes.string,
  /** group id */
  mId: PropTypes.string.isRequired,
  /** group type */
  type: PropTypes.string.isRequired,
};

GroupContainer.defaultProps = {
  title: 'Group',
};

export default GroupContainer;
