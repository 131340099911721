import { makeStyles } from '@material-ui/styles';
import image from 'assets/images/PitchBackgroundOverlay.png';

const useStyles = isPitch =>
  makeStyles(theme => ({
    storyCard: {
      margin: '1px 2px 1px 2px',
    },
    root: {
      width: '100%',
      minWidth: '640px',
      height: '48px',
      borderRadius: '2px',
      display: 'flex',
      justifyContent: 'space-between',
      // eslint-disable-next-line max-len
      boxShadow: `0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)`,
      backgroundColor: theme.palette.dina.surfaceCard,
      backgroundImage: isPitch ? `url(${image})` : null,
      cursor: 'pointer',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: theme.palette.dina.whiteHoverOverlay,
      },
      position: 'relative',
    },
    categoryContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      marginLeft: '8px',
    },
    categoryIndicator: {
      width: '16px',
      borderRadius: '0 0 1px 1px',
      marginRight: '2px',
      height: '3px',
      overflow: 'hidden',
    },
    leftComponents: {
      position: 'relative',
      display: 'flex',
      flexGrow: 1,
      height: '100%',
      margin: '0 8px',
    },
    pitchIndicator: {
      marginRight: '2px',
    },
    image: {
      width: '32px',
      height: '32px',
      objectFit: 'cover',
      borderRadius: '2px',
    },
    storyContent: {
      marginLeft: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      height: '100%',
    },
    infoRow: {
      display: 'flex',
    },
    updatedAt: {
      ...theme.typography.dina.captionRegular,
      color: theme.palette.dina.mediumEmphasis,
      maxHeight: '14px',
      overflow: 'hidden',
      marginLeft: !isPitch ? '8px' : 0,
    },
    rightComponents: {
      display: 'flex',
      alignItems: 'center',
      height: '24px',
    },
    title: {
      ...theme.typography.dina.listItemLabelMedium,
      color: theme.palette.dina.highEmphasis,
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    groups: {
      marginRight: '8px',
    },
    storyBox: {
      width: '327px',
      height: '830px',
      borderRadius: '8px',
      overflow: 'hidden',
    },
  }));

export default useStyles;
