import React, { useState, useEffect } from 'react';
import Capitalize from 'utils/capitalize';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';
import TabScrollButton from './tabScrollButton';
import useStyles from './tabs-styles';

const TabsView = ({
  tabs,
  onTabChange,
  tabWidth,
  variant,
  tabIndex,
  scrollButtonRootClass,
  ...rest
}) => {
  const classes = useStyles();

  const onChange = (event, newIndex) => {
    event.preventDefault();
    onTabChange(tabs[newIndex], newIndex);
  };

  return (
    <Tabs
      ScrollButtonComponent={props => (
        <TabScrollButton customRootClass={scrollButtonRootClass} {...props} />
      )}
      variant={tabWidth}
      classes={{
        root: classes[`tabs${Capitalize(variant)}`],
        indicator: classes[`tabs${Capitalize(variant)}Indicator`],
        flexContainer: classes[`${variant}Container`],
        scroller: classes[`${variant}Scroller`],
      }}
      value={tabIndex}
      {...{ onChange }}
      {...rest}
    >
      {tabs.map(tab => (
        <Tab
          label={tab.title || tab.label}
          key={tab.title || tab.label}
          icon={tab.icon}
          classes={{
            root: classes[`tab${Capitalize(variant)}`],
            selected: classes[`tab${Capitalize(variant)}Selected`],
          }}
          disabled={tab.disabled}
        />
      ))}
    </Tabs>
  );
};

TabsView.propTypes = {
  /** Items to be shown on the tabs */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      timeVariant: PropTypes.string,
      fallbackImage: PropTypes.any,
    }),
  ).isRequired,
  /** Callback to be invoked on tab change,
   * with the selected tabItem passed in
   */
  onTabChange: PropTypes.func,
  /** variant of tabs: outlined, small , icon or default */
  variant: PropTypes.string,
  /** Initial tab to be selected */
  tabIndex: PropTypes.number,
  /** tabWidth: standard or fullWidth or scrollable */
  tabWidth: PropTypes.string,
  /** classes for the scroll buttons */
  scrollButtonRootClass: PropTypes.string,
};

TabsView.defaultProps = {
  onTabChange: () => {},
  variant: 'default',
  tabIndex: 0,
  tabWidth: 'fullWidth',
  scrollButtonRootClass: '',
};

export default TabsView;
