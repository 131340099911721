import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useInputEvents from 'hooks/useInputEvents';
import useStyles from './styles';

const InputView = ({ id, value, width, style, updateInputField, disableEdit, ...rest }) => {
  const classes = useStyles({ style });
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onUpdate = newValue => {
    if (newValue === value) setInputValue(newValue);
    else updateInputField(newValue);
  };

  const [inputRef, handleKeyDown, handleBlur] = useInputEvents(onUpdate, inputValue, value);

  return (
    <div className={classes.inputDiv}>
      <input
        id={id}
        ref={inputRef}
        className={classes.input}
        value={inputValue}
        onChange={event => {
          event.preventDefault();
          event.stopPropagation();
          setInputValue(event.target.value);
        }}
        style={{ width }}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        disabled={disableEdit}
        {...rest}
      />
      <div className={classes.verticalDivider} />
    </div>
  );
};

InputView.propTypes = {
  value: PropTypes.string,
  width: PropTypes.number.isRequired,
};

InputView.defaultProps = {
  value: '',
};

export default InputView;
