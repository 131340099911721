import { makeStyles } from '@material-ui/styles';

const useStyles = showImage =>
  makeStyles(theme => ({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: showImage ? 'transparent' : theme.palette.dina.paletteAccentCyan,
      // borderRadius: 14,
      borderRadius: '25%',
      cursor: 'pointer',
    },
  }));

export default useStyles;
