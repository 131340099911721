/**
 * Converts hh:mm:ss formatted time string to milliseconds
 *
 * @param {String} time Formatted time string hh:mm:ss
 * @returns {Number} Converted milliseconds
 */

const getMilliseconds = (time = '') => {
  if (!time) return 0;

  const parsedTime = time
    .toString()
    .split(':')
    .map(Number);

  if (parsedTime.some(Number.isNaN)) return 0;

  const [firstItem] = parsedTime;
  const sign = firstItem === 0 ? 1 : Math.sign(firstItem);

  const seconds = parsedTime.reduce(
    (accumulator, currentValue) => 60 * accumulator + Math.abs(currentValue),
    0,
  );

  return sign * seconds * 1000;
};

export default getMilliseconds;
