import { makeStyles } from '@material-ui/styles';

const useStyles = (writeLock, readLock) =>
  makeStyles(theme => {
    let lockColor = 'transparent';
    if (readLock) lockColor = theme.palette.dina.statusWarning;
    if (writeLock) lockColor = theme.palette.dina.statusApproved;
    return {
      storyHeaderText: {
        ...theme.typography.dina.overline,
      },
      description: {
        ...theme.typography.dina.body1,
        paddingTop: 0,
        display: 'flex',
        maxHeight: '50px',
        minHeight: '50px',
        alignItems: 'start',
      },
      input: {
        ...theme.typography.dina.body1,
      },
      editor: {
        border: `1px solid ${lockColor}`,
        borderRadius: '0 0 8px 8px',
        marginBottom: '20px',
        outline: 'none',
      },
      footer: {
        width: '100%',
        height: 40,
        position: 'relative',
        left: 0,
        borderRadius: '0 0 8px 8px',
        display: 'grid',
        alignItems: 'center',
        backgroundColor: lockColor,
        overflow: 'hidden',
      },
      contentContainer: {
        width: '100%',
      },
    };
  });

export default useStyles;
