import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  drawer: {
    maxWidth: '327px',
    zIndex: 50,
  },
  drawerPaper: {
    minWidth: '327px',
    maxWidth: '327px',
    top: '96px',
    height: 'calc(100% - 96px)',
  },
  paperAnchorLeft: {
    left: '48px',
    boxShadow:
      '8px 0px 8px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
  },
});

export default useStyles;
