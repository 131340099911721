import gql from 'graphql-tag';

export default gql`
  mutation UpdateRundownSync($input: UpdateRundownInput) {
    updateRundownSync(input: $input) {
      mTitle
      mOrder
      mPreorder
      mId
      mRefId
      mState
      mPreparingState
      mPlannedDuration
      mPrePlannedDuration
      mPublishingAt
      version
      recurrence {
        duration
        startTime
        timeZone
        start
        dailyExclusive
      }
      mMetaData {
        key
        value
      }
    }
  }
`;
