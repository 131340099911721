import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, FormControlLabel } from '@material-ui/core';
import Divider from 'components/divider';
import Checkbox from 'components/checkbox';
import useStyles from './deleteInstance-styles';

const CreateNew = props => {
  const { onCancel, onOk, isDeleteEnalbled } = props;
  const rootRef = useRef(null);
  const [isSelected, setIsSelcted] = useState(false);
  const classes = useStyles();

  const handleOk = () => {
    onOk();
  };

  return (
    <div className={classes.root} ref={rootRef}>
      <div className={classes.header}>
        <Typography classes={{ root: classes.headerText }}>Delete this Instance?</Typography>
      </div>
      <div className={classes.dividerTop}>
        <Divider />
      </div>
      <div className={classes.body}>
        {!isDeleteEnalbled ? (
          <div className={classes.checkboxContainer}>
            <FormControlLabel
              value="sendMessage"
              control={
                <Checkbox
                  value="isSelected"
                  selected={isSelected}
                  onClick={() => setIsSelcted(!isSelected)}
                  size={24}
                />
              }
              label="Check this box to confirm you want to delete the Instance"
              classes={{
                label: classes.checkBoxText,
                root: classes.checkbox,
              }}
            />

            <div className={classes.labelTextContainer}>
              <Typography classes={{ root: classes.labelText }}>
                Once deleted, the Instance cannot be restored
              </Typography>
            </div>
          </div>
        ) : (
          <div className={classes.checkboxContainer}>
            <Typography classes={{ root: classes.labelText }}>
              This instance is scheduled. You can not delete already sceduled instances.
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.dividerBottom}>
        <Divider />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          classes={{ root: classes.createButton, label: classes.okButtonLabel }}
          onClick={handleOk}
          disabled={isSelected === false || isDeleteEnalbled}
        >
          Delete
        </Button>
        <Button
          classes={{ root: classes.cancelButton, label: classes.cancelButtonLabel }}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

CreateNew.propTypes = {
  /** Callback to be invoked when cancel button is clicked */
  onCancel: PropTypes.func,
  /** Callback to be invoked when create button is clicked */
  onOk: PropTypes.func,
};

CreateNew.defaultProps = {
  onCancel: () => {},
  onOk: () => {},
};

export default CreateNew;
