import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  input: {
    ...theme.typography.dina.listItemLabel,
    fontWeight: 'normal',
    paddingLeft: '4px',
    height: '31px',
    width: '100%',
    background: 'transparent',
    border: `1px solid transparent`,
    outline: 'none',
    '&:hover': {
      transition: '.2s ease-in',
      border: `1px solid ${theme.palette.dina.inputBorderResting}`,
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
    '&:focus': {
      transition: '.2s ease-out',
      border: `1px solid ${theme.palette.dina.onFocus}`,
      backgroundColor: theme.palette.dina.blackHoverOverlay,
    },
  },
  endAdornment: {
    display: 'flex',
  },
  inputBase: {
    ...theme.typography.dina.listItemLabel,
    fontWeight: 'normal',
    height: 31,
    background: 'transparent',
    // border: `1px solid transparent`,
    border: `1px solid ${theme.palette.dina.inputBorderResting}`,
    outline: 'none',
    '&:hover': {
      transition: '.2s ease-in',
      border: `1px solid ${theme.palette.dina.inputBorderResting}`,
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },
  comboPaper: {
    backgroundColor: theme.palette.dina.surfaceDialogs,
  },
  comboOption: {
    ...theme.typography.dina.instanceHeaderTitle,
    fontWeight: 'normal',
    color: theme.palette.dina.mediumEmphasis,
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.dina.onSelected,
    },
    '&[data-focus="true"]:not([aria-selected="true"])': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
  },
  comboNoOptions: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.disabled,
    fontWeight: 'normal',
  },
  inputFocused: {
    padding: 0,
    paddingLeft: '4px',
  },
}));

export default useStyles;
