import React from 'react';
import { Drawer } from '@material-ui/core';
import useStyles from './right-area-styles';
import Sidebar from './sidebar';
import VerticalNavbar from './verticalNavbar';

const RightAreaView = ({
  isRightHidden,
  rightSelection,
  isRightExpanded,
  contactTab,
  dispatch,
}) => {
  const classes = useStyles(isRightExpanded ? window.innerWidth / 2 : 327)();

  const handleSelectionChanged = val => {
    if (!val) return;
    dispatch({
      type: 'setRightSelection',
      payload: val,
    });
    if (val !== 'storybox' || val !== 'graphicsbox') {
      dispatch({
        type: 'setExpandRightSidebar',
        payload: false,
      });
    }
  };
  const handleContactTabChange = val => {
    if (val === undefined) return;
    dispatch({
      type: 'setContactTab',
      payload: val,
    });
  };
  return (
    <>
      <Drawer
        transitionDuration={{ enter: 300, exit: 300 }}
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={!isRightHidden}
        classes={{
          paper: classes.drawerPaper,
          paperAnchorRight: classes.paperAnchorRight,
        }}
      >
        <Sidebar
          selectionType={rightSelection}
          setExpand={val => {
            dispatch({
              type: 'setExpandRightSidebar',
              payload: val,
            });
          }}
          contactTab={contactTab}
          isRightExpanded={isRightExpanded}
          handleContactTabChange={handleContactTabChange}
        />
      </Drawer>
      <VerticalNavbar
        selectionType={rightSelection}
        toggleSidebar={() =>
          dispatch({
            type: 'toggleRightSidebar',
          })
        }
        selectionChanged={handleSelectionChanged}
        hidden={isRightHidden}
      />
    </>
  );
};

export default RightAreaView;
