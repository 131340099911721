import React, { useState, useContext } from 'react';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import useStorageImage from 'hooks/useStorageImage';
import DeleteIcon from '@material-ui/icons/Delete';
import { uploadToS3 } from 'utils/s3Utils';
import UserCtx from 'contexts/UserContext';
import memberTypes from 'graphql/memberTypes';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import MenuCreator from 'screens/main/components/rightArea/menuCreator/MenuCreator';
import Dialog from 'components/dialog';
import ConfirmDialog from 'components/confirmDialog';
import useDeleteContact from 'hooks/useDeleteContact';
import DetailView from './detail-view';

const GET_USERS = gql`
  query GetUsers($input: GetMemberOfTypeInput) {
    getMembersOftype(input: $input) {
      mId
      mRefId
      mAvatarUrl
    }
  }
`;

const topRowOptions = [{ label: 'Delete Contact', action: 'deleteContact', Icon: DeleteIcon }];

const DetailContainer = ({ showMaster, selectedItem, ...rest }) => {
  const [showTopMenu, setShowTopMenu] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const client = useApolloClient();

  const { image, imageKey: imageStoragePath } = selectedItem;
  const { data: imageSrc } = useStorageImage(imageStoragePath, !!image);

  const updateAvatar = async (imageFile, imageUrl) => {
    uploadToS3(imageStoragePath, imageFile);

    const members = await client.readQuery({
      query: GET_USERS,
      variables: getMembersOfTypeQuery(memberTypes.CONTACT),
    });

    const updatedMembers = members.getMembersOftype.map(member => {
      if (member.mId === selectedItem.id) {
        // eslint-disable-next-line no-param-reassign
        member.mAvatarUrl = imageUrl;
      }
      return member;
    });

    client.writeQuery({
      query: GET_USERS,
      variables: getMembersOfTypeQuery(memberTypes.CONTACT),
      data: { getMembersOftype: updatedMembers },
    });
  };

  const user = useContext(UserCtx);
  const showMenu = selectedItem && selectedItem.type === memberTypes.CONTACT;

  const [deleteContact] = useDeleteContact();

  const onDeleteContact = async id => {
    await deleteContact(id);
    showMaster();
  };

  return (
    <>
      <DetailView
        {...rest}
        {...{ showMaster, updateAvatar, selectedItem, user, showMenu }}
        avatarUrl={imageSrc || image}
        onMenuButtonClick={event => {
          setShowTopMenu(event.currentTarget);
        }}
      />
      {showMenu && (
        <>
          <MenuCreator
            onClose={({ action }) => {
              if (action) {
                if (action === 'deleteContact') setDeleteDialogOpen(true);
              }
              setShowTopMenu(null);
            }}
            anchorEl={showTopMenu}
            menuOptions={topRowOptions}
            position="right-top"
          />
          <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
            <ConfirmDialog
              onCancel={() => setDeleteDialogOpen(false)}
              onConfirm={() => {
                onDeleteContact(selectedItem.id);
              }}
              title="Delete contact?"
              confirmButtonLabel="Delete"
              confirmButtonType="danger"
              info={`Contact: "${selectedItem.name}" will be deleted.`}
            />
          </Dialog>
        </>
      )}
    </>
  );
};

export default DetailContainer;
