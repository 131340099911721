import { makeStyles } from '@material-ui/styles';

const useStyles = (image, notToday, onAir) =>
  makeStyles(theme => ({
    rootContainer: {
      width: '100%',
      height: '112px',
      position: 'relative',
      overflow: 'hidden',
      // eslint-disable-next-line max-len
      backgroundImage: `linear-gradient(180deg, rgba(50,62,77, 0.65) 0%, rgba(32,42,54,0.95) 100%), url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    toolbar: {
      position: 'absolute',
      top: 0,
      width: '100%',
      minHeight: '32px',
      maxHeight: '32px',
      background: onAir
        ? theme.palette.dina.statusError
        : theme.palette.dina.statusWarning,
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      minWidth: '24px',
      minHeight: '24px',
      marginRight: '8px',
      animation: '$animate 10s ease infinite',
      transformOrigin: 'bottom',
      '& path': {
        fill: notToday
          ? theme.palette.dina.blackHighEmphasis
          : theme.palette.dina.highEmphasis,
        fillOpacity: '1',
      },
    },
    '@keyframes animate': {
      '0%': { transform: 'translateY(0)' },
      '10%': { transform: 'translateY(0)' },
      '12.5%': { transform: 'translateY(-4px)' },
      '14.5%': { transform: 'translateY(0)' },
      '16.25%': { transform: 'translateY(-3px)' },
      '17.75%': { transform: 'translateY(0)' },
      '19%': { transform: 'translateY(-1px)' },
      '20%': { transform: 'translateY(0)' },
      '100%': { transform: 'translateY(0)' },
    },
    toolbarText: {
      ...theme.typography.dina.listItemLabel,
      color: notToday
        ? theme.palette.dina.blackHighEmphasis
        : theme.palette.dina.highEmphasis,
      display: '-webkit-box',
      lineClamp: 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    content: {
      position: 'absolute',
      display: 'flex',
      width: 'calc(100% - 48px)',
      margin: '0 24px 0 24px',
      bottom: '0',
      height: '64px',
    },
    titleBlock: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '24px',
      maxWidth: 'calc(100% - 328px)',
    },
    title: {
      display: 'flex',
      alignItems: 'flex-end',
      height: '40px',
      width: '100%',
    },
    titleText: {
      ...theme.typography.dina.h5,
      lineHeight: '28px',
      display: '-webkit-box',
      lineClamp: 1,
      '-webkit-box-orient': 'vertical',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    label: {
      ...theme.typography.dina.overline,
      lineHeight: '12px',
      letterSpacing: '1px',
    },
    dateBlock: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '24px',
    },
    startTimeBlock: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10px',
    },
    date: {
      ...theme.typography.dina.marketplaceHeader,
      height: '40px',
      display: 'flex',
      alignItems: 'flex-end',
    },
    dateText: {
      color: notToday
        ? theme.palette.dina.statusWarning
        : theme.palette.dina.highEmphasis,
    },
    input: {
      ...theme.typography.dina.marketplaceHeader,
      backgroundColor: 'transparent',
      border: '1px solid transparent',
      '&:focus': {
        outline: 'transparent',
      },
      padding: 0,
      maxWidth: '108px',
      fontFamily: 'Roboto',
    },
  }));

export default useStyles;
