import { makeStyles } from '@material-ui/styles';
import image from 'assets/images/PitchBackgroundOverlay.png';

const useStyles = (isPitch, isDragging) =>
  makeStyles(theme => ({
    storyCard: {
      marginBottom: '2px',
      boxShadow:
        // eslint-disable-next-line max-len
        '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
      borderRadius: '2px',
      height: '72px',
      width: '100%',
      overflow: 'hidden',
      backgroundColor: theme.palette.dina.surfaceCard,
      '&:hover': {
        backgroundColor: theme.palette.dina.whiteHoverOverlay,
      },
      backgroundImage: () => {
        return isPitch ? `url(${image})` : '';
      },
      border: () => {
        if (isDragging) {
          if (isPitch) return `1px solid ${theme.palette.dina.statusStoryMarket}`;
          return `1px solid ${theme.palette.dina.onFocus}`;
        }
      },
    },
    storyBox: {
      width: '327px',
      height: '830px',
      borderRadius: '8px',
      overflow: 'hidden',
    },
  }));

export default useStyles;
