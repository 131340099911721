const reducer = (state, action) => {
  switch (action.type) {
    case 'toggleLeftSidebar':
      return {
        ...state,
        isLeftHidden: !state.isLeftHidden,
      };
    case 'toggleRightSidebar':
      return {
        ...state,
        isRightHidden: !state.isRightHidden,
      };
    case 'openRightSidebar':
      return {
        ...state,
        isRightHidden: false,
      };
    case 'setExpandRightSidebar':
      return {
        ...state,
        isRightExpanded: action.payload,
      };
    case 'setLeftSelection':
      return {
        ...state,
        leftSelection: action.payload,
      };
    case 'setRightSelection':
      return {
        ...state,
        rightSelection: action.payload,
      };
    case 'setOpenGroups':
      return {
        ...state,
        openGroups: action.payload,
      };
    case 'setContactTab':
      return {
        ...state,
        contactTab: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
