import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'components/chip';
import MaterialChipInput from 'material-ui-chip-input';
import useStyles from './chipInput-styles';

const ChipInput = props => {
  const {
    chipInputItems,
    onDelete,
    helperText,
    variant,
    placeholder,
    inputRef,
    onKeyDown,
    disabled,
    disableEdit,
  } = props;

  const classes = useStyles();

  return (
    <>
      <MaterialChipInput
        fullWidth
        {...{ placeholder }}
        variant="filled"
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: classes.input,
            underline: classes.underline,
            disabled: classes.disabled,
            focused: classes.focused,
            error: classes.error,
          },
          onKeyDown,
        }}
        value={chipInputItems}
        classes={{
          helperText: classes.helperText,
        }}
        disabled={disabled || disableEdit}
        onDelete={onDelete}
        helperText={helperText}
        blurBehavior="ignore"
        clearInputValueOnChange
        chipRenderer={({ chip, handleDelete }, key) => (
          <div className={classes.chip} key={key}>
            <Chip
              variant={variant}
              key={chip.mId}
              onDelete={!disableEdit ? handleDelete : undefined}
              label={chip.mTitle}
              avatarUrl={chip.mAvatarUrl}
            />
          </div>
        )}
        inputRef={node => {
          inputRef.current = node;
        }}
      />
    </>
  );
};

ChipInput.propTypes = {
  chipInputItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdateInput: PropTypes.func,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  disableEdit: PropTypes.bool,
};

ChipInput.defaultProps = {
  chipInputItems: [],
  onAdd: () => {},
  onDelete: () => {},
  onUpdateInput: () => {},
  helperText: '',
  placeholder: 'Type to find items',
  disableEdit: false,
};

export default ChipInput;
