import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: 328,
    transition: 'height 0.3s ease-out',
    userSelect: 'none',
    ...theme.typography.dina.rightColumnHeaderText,

    '&:not(.collapsed)': {
      height: 136,
    },
  },
}));

export default useStyles;
