import React, { memo } from 'react';
import Dialog from 'components/dialog';
import useStyles from 'screens/storybox/components/assets/mediaViewer/mediaViewer-styles';
import { Wrapper } from 'screens/storybox/components/assets/mediaViewer/mediaViewer-view';

const MemoizedDialog = memo(Dialog);

const MediaViewer = ({ asset, onClose }) => {
  const classes = useStyles();
  const { mediaType } = asset;

  switch (mediaType) {
    case 'image':
      return (
        <Wrapper onClose={onClose}>
          <img
            src={asset.mContentUrl || asset.thumbnailUrl}
            className={classes.image}
            alt="imageUrl"
          />
        </Wrapper>
      );
    case 'video':
      return (
        <video controls width="800" height="500" className={classes.video}>
          <source src={asset.mContentUrl || asset.proxy} type="video/mp4" />
        </video>
      );
    case 'audio':
      return (
        <audio controls className={classes.audio}>
          <source src={asset.mContentUrl || asset.proxy} type="audio/mpeg" />
        </audio>
      );
    default:
      return <div />;
  }
};

const MediaDialog = ({ asset, open, onClose }) => {
  return (
    <MemoizedDialog
      disableRestoreFocus
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
      {...{ open, onClose }}
    >
      <MediaViewer {...{ asset, onClose }} />
    </MemoizedDialog>
  );
};

export default MediaDialog;
