import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: 512,
    height: 360,
    borderRadius: 8,
    backgroundColor: theme.palette.dina.surfaceCard,
    position: 'absolute',
    left: 'calc(50% - 256px)',
    top: 'calc(50% - 180px)',
    display: 'flex',
    justifyContent: 'center',
  },
  loadingContainer: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingIndicator: {
    position: 'relative',
    height: 98,
    marginTop: 50,
  },
  loadingText: {
    ...theme.typography.dina.captionMedium,
    lineHeight: '13px',
    fontWeight: 'normal',
    color: theme.palette.dina.highEmphasis,
  },
  logo: {
    height: 32,
    width: 58,
  },
  copyright: {
    position: 'absolute',
    left: 24,
    bottom: 24,
  },
  textCopyright: {
    ...theme.typography.dina.captionSmall,
    lineHeight: '12px',
    color: theme.palette.dina.mediumEmphasis,
  },
  sevenMount: {
    position: 'absolute',
    right: 32,
    bottom: 24,
    display: 'flex',
    alignItems: 'center',
  },
  textMountains: {
    fontSize: '11px',
    lineHeight: '15px',
    marginLeft: '4px',
    color: theme.palette.dina.iconInactive,
  },
}));

export default useStyles;
