import React from 'react';
import DayCell from './dayCell';
import useStyles from './week-styles';

const WeekView = props => {
  const { stories, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {Object.entries(stories).map(date => (
        <DayCell key={date[0]} date={date[0]} stories={date[1]} {...rest} />
      ))}
    </div>
  );
};

export default WeekView;
