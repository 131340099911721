import gql from 'graphql-tag';

export default gql`
  query GetFeed($input: GetFeedsInput) {
    getFeeds(input: $input, searchIndex: "feeds") {
      __typename
      nextToken
      items {
        __typename
        provider
        mId
        version
        mPublishedAt
        section
        priority
        pubstatus
        ednotes
        newscodes
        mTitle
        byline
        mContent
        mDescription
        location
        embargoed
        href
        assets {
          duration
          headline
          byline
          type
          renditions {
            href
          }
        }
      }
    }
  }
`;
