/* eslint-disable max-len */
/* eslint-disable camelcase */
import twitterSrc from './systemicons/publishing_points/twitter.svg';

import linearSrc from './systemicons/publishing_points/tv.svg';

import facebookSrc from './systemicons/publishing_points/facebook.svg';

import cmsSrc from './systemicons/publishing_points/cms.svg';

import taskSrc from './systemicons/publishing_points/todo_on.svg';

import instagramSrc from './systemicons/publishing_points/instagram.svg';

import defaultSrc from './systemicons/publishing_points/default.svg';

import youtubeSrc from './systemicons/publishing_points/youtube.svg';
import snapchatSrc from './systemicons/publishing_points/snapchat.svg';
import custom_A_Src from './systemicons/publishing_points/tools/custom_A.svg';
import custom_B_Src from './systemicons/publishing_points/tools/custom_B.svg';
import custom_C_Src from './systemicons/publishing_points/tools/custom_C.svg';
import custom_D_Src from './systemicons/publishing_points/tools/custom_D.svg';
import custom_E_Src from './systemicons/publishing_points/tools/custom_E.svg';
import custom_F_Src from './systemicons/publishing_points/tools/custom_F.svg';
import custom_G_Src from './systemicons/publishing_points/tools/custom_G.svg';
import custom_H_Src from './systemicons/publishing_points/tools/custom_H.svg';
import custom_I_Src from './systemicons/publishing_points/tools/custom_I.svg';
import custom_J_Src from './systemicons/publishing_points/tools/custom_J.svg';
import custom_K_Src from './systemicons/publishing_points/tools/custom_K.svg';
import custom_L_Src from './systemicons/publishing_points/tools/custom_L.svg';
import custom_M_Src from './systemicons/publishing_points/tools/custom_M.svg';
import custom_N_Src from './systemicons/publishing_points/tools/custom_N.svg';
import custom_O_Src from './systemicons/publishing_points/tools/custom_O.svg';
import custom_P_Src from './systemicons/publishing_points/tools/custom_P.svg';
import custom_Q_Src from './systemicons/publishing_points/tools/custom_Q.svg';
import custom_R_Src from './systemicons/publishing_points/tools/custom_R.svg';
import custom_S_Src from './systemicons/publishing_points/tools/custom_S.svg';
import custom_T_Src from './systemicons/publishing_points/tools/custom_T.svg';
import custom_U_Src from './systemicons/publishing_points/tools/custom_U.svg';
import custom_V_Src from './systemicons/publishing_points/tools/custom_V.svg';
import custom_W_Src from './systemicons/publishing_points/tools/custom_W.svg';
import custom_X_Src from './systemicons/publishing_points/tools/custom_X.svg';
import custom_Y_Src from './systemicons/publishing_points/tools/custom_Y.svg';
import custom_Z_Src from './systemicons/publishing_points/tools/custom_Z.svg';

import storyTimelineTwitterSrc from './systemicons/publishing_points/story_timeline/twitter.svg';

import storyTimelineLinearSrc from './systemicons/publishing_points/story_timeline/tv.svg';

import storyTimelineFacebookSrc from './systemicons/publishing_points/story_timeline/facebook.svg';

import storyTimelineCmsSrc from './systemicons/publishing_points/story_timeline/cms.svg';

import storyTimelineTaskSrc from './systemicons/publishing_points/story_timeline/todo_on.svg';

import storyTimelineInstagramSrc from './systemicons/publishing_points/story_timeline/instagram.svg';
import storyTimelineYoutubeSrc from './systemicons/publishing_points/story_timeline/youtube.svg';
import storyTimelineSnapchatSrc from './systemicons/publishing_points/story_timeline/snapchat.svg';
import storyTimelineDefaultSrc from './systemicons/publishing_points/story_timeline/default.svg';

import storyTimelineCustom_A_Src from './systemicons/publishing_points/story_timeline/tools/custom_A.svg';
import storyTimelineCustom_B_Src from './systemicons/publishing_points/story_timeline/tools/custom_B.svg';
import storyTimelineCustom_C_Src from './systemicons/publishing_points/story_timeline/tools/custom_C.svg';
import storyTimelineCustom_D_Src from './systemicons/publishing_points/story_timeline/tools/custom_D.svg';
import storyTimelineCustom_E_Src from './systemicons/publishing_points/story_timeline/tools/custom_E.svg';
import storyTimelineCustom_F_Src from './systemicons/publishing_points/story_timeline/tools/custom_F.svg';
import storyTimelineCustom_G_Src from './systemicons/publishing_points/story_timeline/tools/custom_G.svg';
import storyTimelineCustom_H_Src from './systemicons/publishing_points/story_timeline/tools/custom_H.svg';
import storyTimelineCustom_I_Src from './systemicons/publishing_points/story_timeline/tools/custom_I.svg';
import storyTimelineCustom_J_Src from './systemicons/publishing_points/story_timeline/tools/custom_J.svg';
import storyTimelineCustom_K_Src from './systemicons/publishing_points/story_timeline/tools/custom_K.svg';
import storyTimelineCustom_L_Src from './systemicons/publishing_points/story_timeline/tools/custom_L.svg';
import storyTimelineCustom_M_Src from './systemicons/publishing_points/story_timeline/tools/custom_M.svg';
import storyTimelineCustom_N_Src from './systemicons/publishing_points/story_timeline/tools/custom_N.svg';
import storyTimelineCustom_O_Src from './systemicons/publishing_points/story_timeline/tools/custom_O.svg';
import storyTimelineCustom_P_Src from './systemicons/publishing_points/story_timeline/tools/custom_P.svg';
import storyTimelineCustom_Q_Src from './systemicons/publishing_points/story_timeline/tools/custom_Q.svg';
import storyTimelineCustom_R_Src from './systemicons/publishing_points/story_timeline/tools/custom_R.svg';
import storyTimelineCustom_S_Src from './systemicons/publishing_points/story_timeline/tools/custom_S.svg';
import storyTimelineCustom_T_Src from './systemicons/publishing_points/story_timeline/tools/custom_T.svg';
import storyTimelineCustom_U_Src from './systemicons/publishing_points/story_timeline/tools/custom_U.svg';
import storyTimelineCustom_V_Src from './systemicons/publishing_points/story_timeline/tools/custom_V.svg';
import storyTimelineCustom_W_Src from './systemicons/publishing_points/story_timeline/tools/custom_W.svg';
import storyTimelineCustom_X_Src from './systemicons/publishing_points/story_timeline/tools/custom_X.svg';
import storyTimelineCustom_Y_Src from './systemicons/publishing_points/story_timeline/tools/custom_Y.svg';
import storyTimelineCustom_Z_Src from './systemicons/publishing_points/story_timeline/tools/custom_Z.svg';

const publishingPoints = {
  twitter: twitterSrc,
  linear: linearSrc,
  facebook: facebookSrc,
  cms: cmsSrc,
  task: taskSrc,
  instagram: instagramSrc,
  youtube: youtubeSrc,
  snapchat: snapchatSrc,
  custom_A: custom_A_Src,
  custom_B: custom_B_Src,
  custom_C: custom_C_Src,
  custom_D: custom_D_Src,
  custom_E: custom_E_Src,
  custom_F: custom_F_Src,
  custom_G: custom_G_Src,
  custom_H: custom_H_Src,
  custom_I: custom_I_Src,
  custom_J: custom_J_Src,
  custom_K: custom_K_Src,
  custom_L: custom_L_Src,
  custom_M: custom_M_Src,
  custom_N: custom_N_Src,
  custom_O: custom_O_Src,
  custom_P: custom_P_Src,
  custom_Q: custom_Q_Src,
  custom_R: custom_R_Src,
  custom_S: custom_S_Src,
  custom_T: custom_T_Src,
  custom_U: custom_U_Src,
  custom_V: custom_V_Src,
  custom_W: custom_W_Src,
  custom_X: custom_X_Src,
  custom_Y: custom_Y_Src,
  custom_Z: custom_Z_Src,
  default: defaultSrc,
};

const storyTimelinePublishingPoints = {
  twitter: storyTimelineTwitterSrc,
  linear: storyTimelineLinearSrc,
  facebook: storyTimelineFacebookSrc,
  cms: storyTimelineCmsSrc,
  task: storyTimelineTaskSrc,
  instagram: storyTimelineInstagramSrc,
  youtube: storyTimelineYoutubeSrc,
  snapchat: storyTimelineSnapchatSrc,
  custom_A: storyTimelineCustom_A_Src,
  custom_B: storyTimelineCustom_B_Src,
  custom_C: storyTimelineCustom_C_Src,
  custom_D: storyTimelineCustom_D_Src,
  custom_E: storyTimelineCustom_E_Src,
  custom_F: storyTimelineCustom_F_Src,
  custom_G: storyTimelineCustom_G_Src,
  custom_H: storyTimelineCustom_H_Src,
  custom_I: storyTimelineCustom_I_Src,
  custom_J: storyTimelineCustom_J_Src,
  custom_K: storyTimelineCustom_K_Src,
  custom_L: storyTimelineCustom_L_Src,
  custom_M: storyTimelineCustom_M_Src,
  custom_N: storyTimelineCustom_N_Src,
  custom_O: storyTimelineCustom_O_Src,
  custom_P: storyTimelineCustom_P_Src,
  custom_Q: storyTimelineCustom_Q_Src,
  custom_R: storyTimelineCustom_R_Src,
  custom_S: storyTimelineCustom_S_Src,
  custom_T: storyTimelineCustom_T_Src,
  custom_U: storyTimelineCustom_U_Src,
  custom_V: storyTimelineCustom_V_Src,
  custom_W: storyTimelineCustom_W_Src,
  custom_X: storyTimelineCustom_X_Src,
  custom_Y: storyTimelineCustom_Y_Src,
  custom_Z: storyTimelineCustom_Z_Src,
  default: storyTimelineDefaultSrc,
};

export { publishingPoints, storyTimelinePublishingPoints };
