import { makeStyles } from '@material-ui/styles';

const useStyles = (width, height) =>
  makeStyles(theme => {
    const commonStyle = {
      width,
      height,
      backgroundColor: theme.palette.dina.chipBackground,
      color: theme.palette.dina.mediumEmphasis,
    };
    return {
      root: {
        ...commonStyle,
      },
      bordered: {
        ...commonStyle,
      },
      thinBordered: {
        ...commonStyle,
        border: `0.5px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
      },
      activityStatus: {
        position: 'absolute',
        bottom: height / 4,
        left: 0,
        transform: 'translate3d(-37.5%, 50%, 0)',
      },
      checked: {
        position: 'absolute',
        right: 0,
        top: 0,
        transform: 'translate3d(25%, -25%, 0)',
      },
      notification: {
        position: 'absolute',
        right: 0,
        top: 16,
        left: 4,
        transform: 'translate3d(25%, -25%, 0)',
      },
    };
  });

export default useStyles;
