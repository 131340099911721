import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor } from 'slate-react';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { Transforms } from 'slate';
import { actionTypes } from 'components/editor/constants/types';
import MergeByProp from '../../../details/utils/MergeByProp';
import TitleBox from '../../../titleBox';

const { setNodes } = Transforms;

const Title = ({ initialData, ...rest }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const { assets, templateVariant, templateType, metaData } = initialData;
  const [asset] = assets;

  const updateVariant = useCallback(
    (newVariant, fields, name, description) => {
      const newFields = fields.map(({ name: tempName, value }) => ({
        name: tempName,
        value,
        isVisible: !!value,
      }));
      const newMeta = MergeByProp(metaData, newFields, 'name');

      const updatedData = {
        ...initialData,
        templateVariant: newVariant,
        metaData: newMeta,
        name,
        description,
      };

      setNodes(editor, { data: updatedData });
      ReactEditor.focus(editor);
      update({
        type: actionTypes.AUTOMATION_UPDATE,
        payload: { document: editor.children, updatedData },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialData],
  );

  return (
    <TitleBox
      title={templateVariant}
      meta={metaData}
      {...{ asset, templateType, updateVariant }}
      {...rest}
    />
  );
};

Title.propTypes = {
  /** Initial data of the automation element */
  initialData: PropTypes.shape({}),
};

Title.defaultProps = {
  initialData: {},
};

export default memo(Title);
