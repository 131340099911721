import { makeStyles } from '@material-ui/styles';

const inputMargin = 16;

const useStyles = makeStyles(() => ({
  root: {},
  inputWrapper: {
    marginTop: inputMargin,
  },
}));

export default useStyles;
