import React from 'react';
import UploadSrc from 'assets/icons/systemicons/upload.svg';
import InternetSrc from 'assets/icons/systemicons/internet.svg';
import menuActionTypes from './menuActionTypes';

const menuItems = [
  {
    label: 'Upload...',
    action: menuActionTypes.UPLOAD,
    icon: <img src={UploadSrc} alt="Upload from Computer" />,
  },
  // {
  //   label: 'Get from URL...',
  //   action: menuActionTypes.GET_FROM_URL,
  //   icon: <img src={InternetSrc} alt="Upload from URL" />,
  // },
];

export default menuItems;
