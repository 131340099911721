import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import ToggleButton from 'components/toolbar/toolbarButton';
import EllipsisButton from 'components/buttons/ellipsisButton';
import Popover from 'components/popover';
import Divider from 'components/divider';
import FilterButton from 'components/toolbar/toolbarChipButton';
import CreateNew from 'components/createNew';
import ICONS from 'assets/icons/icons';
import colors from 'theme/colors';
// eslint-disable-next-line max-len
import { ReactComponent as StoryOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_on.svg';
// eslint-disable-next-line max-len
import { ReactComponent as StoryOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_off.svg';
import { ReactComponent as PitchOn } from 'assets/icons/systemicons/storymarket_on.svg';
import { ReactComponent as PitchOff } from 'assets/icons/systemicons/storymarket_off.svg';
import { ReactComponent as Add } from 'assets/icons/systemicons/add_small.svg';
import useStyles from './toolbar-styles';

const Toolbar = ({
  showStory,
  setShowStory,
  showPitch,
  setShowPitch,
  filters,
  selectedFilter,
  setSelectedFilter,
  onCreateFilter,
  updateSavedFilters,
}) => {
  const classes = useStyles({ showStory, showPitch });
  const [anchorEl, setAnchorEl] = useState();
  const handleSetShowStory = () => {
    setShowStory(!showStory);
  };
  const handleSetShowPitch = () => {
    setShowPitch(!showPitch);
  };
  const toggleButtons = [
    {
      title: 'Pitches',
      selected: showPitch,
      onClick: handleSetShowPitch,
      icon: showPitch ? <PitchOn /> : <PitchOff />,
      hoverColor: colors.highEmphasis,
    },

    {
      title: 'Stories',
      selected: showStory,
      onClick: handleSetShowStory,
      icon: showStory ? <StoryOn /> : <StoryOff />,
      hoverColor: colors.highEmphasis,
    },
  ];
  const toggleFilter = filter => {
    if (selectedFilter && selectedFilter.label === filter.label) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(filter);
    }
  };

  return (
    <div className={classes.toolbar}>
      <div className={classes.leftComponents}>
        {toggleButtons.map(tButton => (
          <ToggleButton
            title={tButton.title}
            key={tButton.title}
            selected={tButton.selected}
            onClick={tButton.onClick}
            icon={tButton.icon}
          >
            {tButton.title}
          </ToggleButton>
        ))}
      </div>
      <div className={classes.rightComponents}>
        {filters.map(filter => {
          return (
            <FilterButton
              title="Custom filter"
              key={filter.label}
              label={filter.label}
              onClick={() => toggleFilter(filter)}
              icon={ICONS.BOOKMARK_SEARCH_OFF}
              onDelete={() => updateSavedFilters(filters.filter(f => f !== filter))}
              selected={selectedFilter && selectedFilter.label === filter.label}
            />
          );
        })}
        {filters.length > 0 && (
          <Divider className={classes.divider} classes={{ root: classes.divider }} />
        )}
        <Button className={classes.button} onClick={e => setAnchorEl(e.currentTarget.parentNode)}>
          <Add />
        </Button>
        <Popover onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
          <CreateNew
            onCancel={() => setAnchorEl(null)}
            onCreate={label => {
              onCreateFilter(label);
              setAnchorEl(null);
            }}
            variant="feedFilter"
          />
        </Popover>
        {/* <EllipsisButton /> */}
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  showStory: PropTypes.bool,
  showPitch: PropTypes.bool,
  setShowStory: PropTypes.func,
  setShowPitch: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.object),
  selectedFilter: PropTypes.shape({
    expression: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.string,
  }),
  setSelectedFilter: PropTypes.func,
  onCreateFilter: PropTypes.func,
  updateSavedFilters: PropTypes.func,
};

Toolbar.defaultProps = {
  showStory: true,
  showPitch: true,
  setShowStory: () => {},
  setShowPitch: () => {},
  filters: [],
  selectedFilter: {},
  setSelectedFilter: () => {},
  onCreateFilter: () => {},
  updateSavedFilters: () => {},
};

export default Toolbar;
