import gql from 'graphql-tag';

export default gql`
  query getMembers($input: GetMemberInput) {
    getMembers(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mState
      mPublishingAt
      mUpdatedAt
      mCreatedAt
      mContentKey
      mDefaultContentKey
      mThumbnailUrl(linearVariant: rundown)
      mDescription
      locked
      mProperties {
        __typename
        ... on PlatformType {
          platform
          provider {
            requestId
            updateEndpoint
            embeddedEndpoint
            state
            message
          }
          account {
            accountId
            accountRefId
            accountUrl
            accountLogo
            accountTitle
            orderType
          }
          provider {
            requestId
            updateEndpoint
            embeddedEndpoint
            state
            message
            previewEndpoint
          }
        }
      }
      mMetaData {
        key
        value
      }
      mAssignedMembers {
        mId
        mType
      }
    }
  }
`;
