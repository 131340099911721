import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/dialog';
import { useSlate, ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import { elementTypes, actionTypes } from 'components/editor/constants/types';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import selectElement from 'components/editor/utils/selectElement';
import MergeByProp from '../../../details/utils/MergeByProp';
import { selectItems } from '../../constants';
import Details from '../../../details';

const MemoizedDialog = memo(Dialog);

const DetailsDialog = ({ initialData, onClose, open, type, element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const { transition, templateVariant, metaData, templateType } = initialData;

  const updateBlockDetails = useCallback(
    (newVariant, fields, selectedTransition) => {
      onClose();

      const newFields = fields.map(({ name, value }) => ({
        name,
        value,
        isVisible: !!value,
      }));
      const newMeta = MergeByProp(initialData.metaData, newFields, 'name');

      const updatedData = {
        ...initialData,
        templateVariant: newVariant,
        transition: selectedTransition,
        metaData: newMeta,
      };

      ReactEditor.focus(editor);
      selectElement(editor, element);
      Transforms.setNodes(editor, { data: updatedData });

      update({
        type: actionTypes.AUTOMATION_UPDATE,
        payload: { document: editor.children, updatedData },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialData, element],
  );

  return (
    <MemoizedDialog {...{ open, onClose }} disableAutoFocus disableEnforceFocus>
      <Details
        initialTransition={transition}
        initialVariant={templateVariant}
        transitionData={selectItems}
        onOk={updateBlockDetails}
        {...{ metaData, onClose, templateType, type }}
      />
    </MemoizedDialog>
  );
};

DetailsDialog.propTypes = {
  /** Initial data of the automation element */
  initialData: PropTypes.shape({}),
  /** Callback to be invoked when the dialog closes */
  onClose: PropTypes.func,
  /** Specifies if the dialog is open or not */
  open: PropTypes.bool,
  /** Automation type */
  type: PropTypes.string,
};

DetailsDialog.defaultProps = {
  initialData: {},
  onClose: () => {},
  open: false,
  type: elementTypes.CAMERA,
};

export default memo(DetailsDialog);
