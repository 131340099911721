import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';
import useStyles from './leftArea-styles';
import VerticalNavbar from './verticalNavbar';
import Sidebar from './sidebar';

const LeftArea = ({ isLeftHidden, leftSelection, dispatch }) => {
  const classes = useStyles();

  return (
    <>
      <VerticalNavbar
        toggleSidebar={() =>
          dispatch({
            type: 'toggleLeftSidebar',
          })
        }
        selectionChanged={val =>
          dispatch({
            type: 'setLeftSelection',
            payload: val,
          })
        }
        hidden={isLeftHidden}
        selectionType={leftSelection}
      />
      <Drawer
        transitionDuration={{ enter: 200, exit: 200 }}
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={!isLeftHidden}
        classes={{
          paper: classes.drawerPaper,
          paperAnchorLeft: classes.paperAnchorLeft,
        }}
      >
        <Sidebar selectionType={leftSelection} />
      </Drawer>
    </>
  );
};

LeftArea.propTypes = {
  isLeftHidden: PropTypes.bool,
  leftSelection: PropTypes.string,
  dispatch: PropTypes.func,
};

LeftArea.defaultProps = {
  isLeftHidden: false,
  leftSelection: 'user',
  dispatch: () => {},
};

export default LeftArea;
