import { useState, useEffect, useRef } from 'react';
import Storage from '@aws-amplify/storage';

const useStorageImage = (key, skipDownload = false) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const isSubscribed = useRef(null);

  useEffect(() => {
    isSubscribed.current = true;

    if (!key && data) setData(null);

    if (!skipDownload && key) {
      (async () => {
        setLoading(true);

        try {
          const url = await Storage.get(key, { customPrefix: { public: '' } });
          const response = await fetch(url);

          if (response.ok) {
            const blob = await response.blob();
            const result = URL.createObjectURL(blob);

            if (isSubscribed.current) setData(result);
          } else {
            throw new Error(response.statusText);
          }
        } catch (err) {
          if (isSubscribed.current) setError(err.message);
        } finally {
          if (isSubscribed.current) setLoading(false);
        }
      })();
    }

    return () => {
      isSubscribed.current = false;
    };
  }, [key, skipDownload]);

  return { error, loading, data };
};

export default useStorageImage;
