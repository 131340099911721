/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Select as MaterialSelect, OutlinedInput, MenuItem } from '@material-ui/core';
import { ReactComponent as ReadyIcon } from 'assets/icons/systemicons/rundown/gridIcons/ready.svg';
import { ReactComponent as NotStartedIcon } from 'assets/icons/systemicons/rundown/gridIcons/NotStarted.svg';
import { ReactComponent as InProgressIcon } from 'assets/icons/systemicons/rundown/gridIcons/progress.svg';
import { ReactComponent as InReviewIcon } from 'assets/icons/systemicons/rundown/gridIcons/inReview.svg';
import { ReactComponent as ArrowDoubleIcon } from 'assets/icons/systemicons/arrows/arrow_double.svg';
import useStyles from './menu-styles';

const IconDirectory = {
  Ready: ReadyIcon,
  'Not Started': NotStartedIcon,
  'In Progress': InProgressIcon,
  'In Review': InReviewIcon,
};

const Select = ({ items, selectedValue, showIconInput, width, onChange, disableEdit, ...rest }) => {
  const classes = useStyles({ width });

  const handleChange = event => {
    onChange(event.target.value);
  };

  const IconComponent = ArrowDoubleIcon;

  const renderIcon = () => {
    if (items) {
      const iconName = items.find(item => item.value === selectedValue);
      if (iconName) return IconDirectory[iconName.value];
    }
    return IconDirectory['Not Started'];
  };

  const renderValue = () => {
    const selectedItem = items.find(({ value }) => value === selectedValue);
    const renderTitle = selectedItem ? selectedItem.title : '—';
    const RenderIcon = renderIcon();

    return (
      <MenuItem
        disableGutters
        classes={{
          root: classes.inputMenuItem,
        }}
      >
        {showIconInput ? <RenderIcon /> : <div className={classes.title}>{renderTitle}</div>}
      </MenuItem>
    );
  };

  return (
    <div className={classes.selectContainer}>
      <MaterialSelect
        value={selectedValue || ''}
        onChange={handleChange}
        classes={{
          select: classes.select,
          icon: classes.gridSelectIcon,
        }}
        IconComponent={IconComponent}
        renderValue={renderValue}
        displayEmpty
        input={
          <OutlinedInput
            name="input"
            labelWidth={0}
            classes={{
              root: classes.modifiedOutlinedInput,
              notchedOutline: classes.notchedOutline,
              input: classes.input,
              focused: classes.focused,
              disabled: classes.disabled,
              error: classes.error,
            }}
          />
        }
        MenuProps={{
          classes: {
            paper: classes.menu,
          },
        }}
      >
        {items && items.length > 0 ? (
          items.map(({ value, title, icon }) => {
            const RenderIcon = IconDirectory[value];
            return (
              <MenuItem
                disabled={disableEdit}
                disableGutters
                {...{ value }}
                key={value}
                classes={{
                  root: classes.menuItem,
                  selected: classes.menuItemSelected,
                }}
                data-testid={`input-${value}`}
              >
                {showIconInput && (
                  <div className={classes.icon}>
                    <RenderIcon />
                  </div>
                )}
                <div className={classes.optionTitle}>{title}</div>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem
            value=""
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
          >
            No options available
          </MenuItem>
        )}
      </MaterialSelect>
    </div>
  );
};

Select.propTypes = {
  /** Items that should be rendered as select options. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
    }),
  ),
  /** Currently selected option's value. */
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** usage of the select input: default or gridView */
  usage: PropTypes.string,
  /** Callback to be invoked when an option is selected.
   * Gets the currently selected value as argument.
   */
  onChange: PropTypes.func,
};

Select.defaultProps = {
  items: [],
  selectedValue: '',
  usage: 'default',
  onChange: () => {},
};

export default Select;
