import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    height: '100%',
    alignItems: 'center',
  },
  addButton: {
    borderRadius: '20px',
    border: `1px solid ${theme.palette.dina.mediumEmphasis}`,
    padding: '2px',
    marginLeft: '4px',
  },
  leftComponents: {
    flexGrow: 1,
  },
  rightComponents: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
}));

export default useStyles;
