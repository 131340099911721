import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  listWrapper: {
    height: 'calc(100% - 43px)',
    // overflowY: 'scroll',
  },
  backdropRoot: {
    position: 'absolute',
    width: '100%',
  },
  filterBar: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '43px',
  },
}));

export default useStyles;
