import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ListItem from './listItem';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
}));

const ListView = ({ data, onSelectionChange, selectedIndex }) => {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      {data.map((item, index) => (
        <ListItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          label={item.variant}
          selected={index === selectedIndex}
          onClick={() => {
            onSelectionChange(index);
          }}
        />
      ))}
    </div>
  );
};

ListView.propTypes = {
  /** data to be shown on the list */
  data: PropTypes.arrayOf(PropTypes.object),
  /** onSelectionChange callback function */
  onSelectionChange: PropTypes.func,
};

ListView.defaultProps = {
  data: [],
  onSelectionChange: () => {},
};

export default ListView;
