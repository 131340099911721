import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText } from '@material-ui/core';
import Divider from 'components/divider';
import useStyles from './listItem-styles';

const ListItemView = ({
  text,
  firstChild,
  iconChild,
  excludeDivider,
  onClick,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <div>
      <ListItem
        disabled={disabled}
        component="label"
        button
        classes={{ root: classes.listItem }}
        onClick={onClick}
      >
        {firstChild}
        <ListItemText primary={text} classes={{ primary: classes.primary }} />
        {iconChild}
      </ListItem>
      {!excludeDivider && <Divider />}
    </div>
  );
};

ListItemView.propTypes = {
  /** Primary text of the list item */
  text: PropTypes.string.isRequired,
  /** Component that appears on the left side of text */
  firstChild: PropTypes.node,
  /** Icon component that appears on the right side of the text */
  iconChild: PropTypes.node,
  /** Determines if the divider should be excluded */
  excludeDivider: PropTypes.bool,
  /** Returns a callback on Click */
  onClick: PropTypes.func,
};

ListItemView.defaultProps = {
  firstChild: null,
  iconChild: null,
  excludeDivider: false,
  onClick: () => {},
};

export default ListItemView;
