import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import useStyles from './monthSelector-styles';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const MonthSelector = ({ month, onMonthSelect }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.months}>
        {months.map((m, index) => (
          <Button
            key={m}
            classes={{
              root: classes[month === index ? 'monthSelected' : 'month'],
              text: classes[month === index ? 'monthTextSelected' : 'monthText'],
            }}
            onClick={() => {
              onMonthSelect(index);
            }}
          >
            {m}
          </Button>
        ))}
      </div>
    </div>
  );
};

MonthSelector.propTypes = {
  month: PropTypes.number,
  onMonthSelect: PropTypes.func,
};

MonthSelector.defaultProps = {
  month: new Date().getMonth(),
  onMonthSelect: () => {},
};

export default MonthSelector;
