import React, { useState } from 'react';
import AddItem from 'screens/main/components/rightArea/sidebar/teams/listItem/addItem/addItem-view';
import Dialog from 'components/dialog';
import { makeStyles } from '@material-ui/styles';
import AddMember from 'components/addMemberDialog';
import DetailList from '../../../../detailList';

const useStyles = makeStyles(theme => ({
  text: {
    ...theme.typography.dina.overline,
    paddingTop: '8px',
    marginLeft: '16px',
  },
  backdropRoot: {
    position: 'absolute',
  },
  divider: {
    paddingLeft: '72px',
  },
}));
const Members = ({ teamMembers, addPeople, containerRef, ...rest }) => {
  const classes = useStyles();
  const teamMembersExist = teamMembers.length > 0;
  const [openMember, setOpenMember] = useState(false);
  const addTeamMembers = members => {
    addPeople(members);
    setOpenMember(false);
  };
  return (
    <div>
      {teamMembersExist && (
        <DetailList
          listTitle="Team Members"
          members={teamMembers}
          avatarVariant="People"
          {...rest}
        />
      )}

      <AddItem title="Team Members..." onClick={() => setOpenMember(!openMember)} />
      <Dialog
        open={openMember}
        container={containerRef.current}
        BackdropProps={{ classes: { root: classes.backdropRoot } }}
        onClose={() => setOpenMember(false)}
      >
        <AddMember
          members={teamMembers}
          variant="People"
          showItems={false}
          dialogTitle="People"
          onCancel={() => setOpenMember(false)}
          onOk={addTeamMembers}
        />
      </Dialog>
    </div>
  );
};

export default Members;
