import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
// import { IconButton } from '@material-ui/core';
// import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { format, isToday, isTomorrow, isYesterday } from 'date-fns';
import Switch from 'components/switch/Switch';
import ArrowUpSrc from 'assets/icons/systemicons/arrows/disclosurearrow_up.svg';
import ArrowDownSrc from 'assets/icons/systemicons/arrows/disclosurearrow_down.svg';
// eslint-disable-next-line max-len
import ArrowDownDisabledSrc from 'assets/icons/systemicons/arrows/disclosurearrow_down_disabled.svg';
import useStyles from './ToggleStyles';

const Toggle = ({
  on,
  setOn,
  collapsed,
  setCollapsed,
  selectedDates,
  reset,
}) => {
  const classes = useStyles(on)();
  const datesArrayLength = selectedDates.length;
  const firstSelectedDate = selectedDates[0];
  const isSingleDay = datesArrayLength === 1;
  let date;

  if (isSingleDay) {
    date = format(firstSelectedDate, 'dddd, MMM D YYYY');

    if (isToday(firstSelectedDate)) {
      date = 'Today';
    } else if (isTomorrow(firstSelectedDate)) {
      date = 'Tomorrow';
    } else if (isYesterday(firstSelectedDate)) {
      date = 'Yesterday';
    }
  } else {
    const lastSelectedDate = selectedDates[datesArrayLength - 1];

    date = `${format(firstSelectedDate, 'MMM D')} to ${format(
      lastSelectedDate,
      'MMM D',
    )}`;
  }

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Switch
            selected={on}
            onChange={() => {
              setOn(!on);
              setCollapsed(on);
              if (on) {
                reset();
              }
            }}
          />
        }
        classes={{
          root: classes.formControlLabel,
          label: classes.label,
        }}
        label={`${on ? date : 'Filter by date'}`}
      />

      <div className={classes.right}>
        <Button
          variant="outlined"
          size="small"
          disabled={!on || (isSingleDay && isToday(firstSelectedDate))}
          classes={{
            outlined: classes.outlined,
            disabled: classes.disabled,
          }}
          onClick={reset}
        >
          Today
        </Button>

        {collapsed ? (
          <div
            onClick={() => on && setCollapsed(false)}
            onKeyDown={() => on && setCollapsed(false)}
            role="button"
            tabIndex="0"
            className={classes.icon}
          >
            <img src={on ? ArrowDownSrc : ArrowDownDisabledSrc} alt="Expand" />
          </div>
        ) : (
          <div
            onClick={() => setCollapsed(true)}
            onKeyDown={() => setCollapsed(true)}
            role="button"
            tabIndex="0"
            className={classes.icon}
          >
            <img src={ArrowUpSrc} alt="Collapse" />
          </div>
        )}
      </div>
    </div>
  );
};

Toggle.propTypes = {
  on: PropTypes.bool.isRequired,
  setOn: PropTypes.func.isRequired,
  setCollapsed: PropTypes.func.isRequired,
  selectedDates: PropTypes.arrayOf(Date).isRequired,
};

export default Toggle;
