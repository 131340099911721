import React, { useState, useRef, useEffect, useCallback } from 'react';
import { isEqual } from 'lodash';
import { DropTarget } from 'react-dnd';
import dndTypes from 'utils/dndTypes';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import useUpdateTwitterThreadCount from 'hooks/useUpdateTwitterThreadCount';
import AddMember from 'components/addMemberDialog';
import CreateNew from 'components/createNew';
import useDidMount from 'hooks/useDidMount';
import Dialog from 'components/dialog';
import variants from 'utils/instance/variants';
import getVariant from 'components/instanceCard/utils/getVariant';
import { useMaximized } from 'contexts/MaximizeContext';
import InstanceCard from 'components/instanceCard/index';
import Popover from 'components/popover/Popover';
import {
  durationTypes,
  getDuration,
  getDurationKey,
  getSeconds,
  getTime,
  isBeforeToday,
} from 'screens/rundown/components/editor/utils';
import { actionTypes } from 'components/editor';
import respectHostReadSpeed from 'screens/rundown/utils/respectHostReadSpeed';
import generatePlaceholderDefaultTitle from 'utils/generatePlaceholderDefaultTitle';
import countTwitterThread from 'utils/instance/countTwitterThread';
import getHostReadSpeed from 'components/instanceCard/utils/getHostReadSpeed';
import getInitialValues from 'components/editor/constants/initialValues';
import useStyles from './instance-styles';

const instanceTarget = {
  drop(props, monitor) {
    props.handleUserDrop([{ mId: monitor.getItem().id }]);
    return { id: props.storyId };
  },
};

const Instance = props => {
  const classes = useStyles();

  const {
    assignMembers,
    backgroundImage,
    canCreateNewTemplate,
    canDeleteTemplate,
    canDeleteTemplateFolder,
    canSeeVersionHistory,
    connectDropTarget,
    containerRef,
    createAsset,
    createPlaceholder,
    currentDestination,
    disableEdit,
    editorValue,
    folders,
    form,
    hasChanges,
    hovered,
    isSavingContent,
    loading,
    lockedByUser,
    lockedByCurrentUser,
    members,
    onAssetInsert,
    onAssetRemove,
    onCmsEditingActivated,
    onChange,
    onCreateDuplicate,
    onCreateFolder,
    onCreateNewRundown,
    onDeleteFolder,
    onDeleteInstance,
    onDeleteTemplate,
    onFocus,
    onForceUnlock,
    onInstanceChanged,
    onLockInstance,
    onSaveTemplate,
    onSelectTemplate,
    onUnlockInstance,
    onUpdatingRundownInstance,
    platform,
    readLock,
    releaseWriteLock,
    removePlaceholder,
    schedule,
    setCurrentDestination,
    setShowDialog,
    setShowMetadata,
    setSkipDownload,
    shouldResetEditorSelection,
    showDialog,
    showMetadata,
    storyId,
    storyInstance,
    summarize,
    summarizedValue,
    templates,
    updateInstanceMetadata,
    writeLock,
    canShowNewDesign,
    placeholderFormat,
    provider,
    versions,
    currentVersionContent,
    refetchVersionList,
    refetchVersionContent,
    onRestoreVersion,
    checkVersionRestorability,
    auditListLoading,
    versionContentLoading,
    height,
  } = props;

  const [open, setOpen] = useState(false);
  // const [, setStatusId] = useState('todo');
  const [targetRundownTitle, setTargetRundownTitle] = useState('');
  const [, setPublishingSettingsAnchorEl] = useState(null);
  const [createNewAnchorEl, setCreateNewAnchorEl] = useState(null);
  const { isMaximized, setIsMaximized } = useMaximized();
  const didMount = useDidMount();
  const variant = getVariant(storyInstance.mProperties.platform);

  const editorValueRef = useRef(editorValue);

  const blankMetaData = getEmptyMetadataForForm(form);
  if (!storyInstance.mMetaData) {
    storyInstance.mMetaData = blankMetaData.map(({ key, value }) => ({
      key,
      value,
      __typename: 'mMetaDataField',
    }));
  }

  if (storyInstance.mMetaData && form && storyInstance.mMetaData.length !== form.fields.length) {
    form.fields ||
      [].forEach(md => {
        if (!storyInstance.mMetaData.find(smd => smd.key === md.id)) {
          const defaultMeta = { key: md.id, value: md.value, __typename: 'mMetaDataField' };
          storyInstance.mMetaData.push(defaultMeta);
        }
      });
  }

  const { mDescription, mTitle, mMetaData } = storyInstance;
  const hostReadSpeed = getHostReadSpeed(mMetaData);
  const [metadata, setMetadata] = useState(respectHostReadSpeed(mMetaData, hostReadSpeed));

  useEffect(() => {
    if (didMount) setMetadata(respectHostReadSpeed(mMetaData, hostReadSpeed));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mMetaData]);

  const placeHolderFormatValues = { mTitle, rundown: { mTitle: currentDestination.title } };

  const onTitleChange = value => {
    onInstanceChanged({ changeType: 'title', title: value });
  };

  const onDescriptionChange = value => {
    onInstanceChanged({ changeType: 'description', description: value });
  };

  const onStatusChange = useCallback(
    value => {
      // setStatusId(value);
      onInstanceChanged({ changeType: 'status', status: value });
    },
    [onInstanceChanged],
  );

  const onPublishSettingsChange = ({ selectedDestination, publishingTime }) => {
    setCurrentDestination(selectedDestination);
    onInstanceChanged({
      changeType: 'publishingSchedule',
      schedule: {
        publishTime: publishingTime,
      },
    });
    onInstanceChanged({
      changeType: 'destination',
      accountUrl: selectedDestination.value,
    });
  };

  const handleCreateNewRundown = title => {
    onCreateNewRundown(title);
    setCreateNewAnchorEl(null);
    setShowDialog(false);
  };

  const handleCancelCreateNew = () => {
    setCreateNewAnchorEl(null);
    setShowDialog(false);
  };

  const handleInstanceRundownUpdate = ({ selectedDestination, publishingTime }) => {
    setTargetRundownTitle(selectedDestination.title);

    const newCurrentDestination = {
      id: selectedDestination.id,
      value: selectedDestination.value,
      title: selectedDestination.title,
      publishingTime,
      startTime: selectedDestination.startTime,
      timeZone: selectedDestination.timeZone,
    };

    setCurrentDestination(newCurrentDestination);
    setCreateNewAnchorEl(containerRef.current);

    const targetRundown = {
      mId: selectedDestination.id,
      mRefId: selectedDestination.id,
      mTitle: selectedDestination.title,
    };
    const sourceRundown = {
      mId: storyInstance.mProperties.account.accountId,
      mRefId: storyInstance.mProperties.account.accountId,
    };

    if (storyInstance.mId !== '-' && (!publishingTime || !isBeforeToday(publishingTime))) {
      onUpdatingRundownInstance(publishingTime, sourceRundown, targetRundown);
    }

    setPublishingSettingsAnchorEl(null);
  };

  const updateMetadataState = newMetaFields => {
    const updatedMetadata = metadata.map(meta => {
      const nMeta = newMetaFields.find(field => field.key === meta.key);
      return nMeta || meta;
    });
    setMetadata(updatedMetadata);
  };

  const [handleTwitterThreadChange] = useUpdateTwitterThreadCount(
    blankMetaData,
    updateMetadataState,
  );

  const handleUpdate = useCallback(
    async ({ type, payload }) => {
      const {
        CHANGE,
        ASSET_INSERT,
        ASSET_REMOVE,
        CREATE_ASSET,
        CREATE_PLACEHOLDER,
        REMOVE_PLACEHOLDER,
      } = actionTypes;

      if (type === ASSET_INSERT) {
        const { file, fileName } = payload;

        return onAssetInsert(file, fileName);
      }

      if (type === ASSET_REMOVE) {
        const { src } = payload;
        onAssetRemove(src);
      }

      if (type === CREATE_ASSET) {
        const { asset } = payload;
        return createAsset(storyId, asset);
      }

      if (type === CREATE_PLACEHOLDER) {
        const { title } = payload;
        return createPlaceholder(title);
      }

      if (type === REMOVE_PLACEHOLDER) {
        const { placeholder } = payload;
        removePlaceholder(placeholder);
      }

      if (type === CHANGE) {
        if (canShowNewDesign && variant === variants.TWITTER) {
          const initialValue = getInitialValues(variant, canShowNewDesign);
          const isChanged = !isEqual(initialValue.document, payload.document);
          if (isChanged && storyInstance.mState !== '02-in-progress')
            onStatusChange('02-in-progress');
        }

        let newMetaData = [];
        if (variant === variants.TWITTER) {
          newMetaData = handleTwitterThreadChange(payload, mMetaData);
        } else if (variant === variants.LINEAR) {
          newMetaData = mMetaData;
        }

        editorValueRef.current = payload;
        onChange(payload, { ...storyInstance, mMetaData: newMetaData });
      }

      return null;
    },
    [
      onAssetInsert,
      onAssetRemove,
      createAsset,
      storyId,
      createPlaceholder,
      removePlaceholder,
      canShowNewDesign,
      variant,
      onChange,
      storyInstance,
      onStatusChange,
      handleTwitterThreadChange,
      mMetaData,
    ],
  );

  const handleDoneClick = useCallback(() => {
    releaseWriteLock();
  }, [releaseWriteLock]);

  const onMetadataChanged = (newData, instance) => {
    updateMetadataState(newData);
    updateInstanceMetadata(newData, instance);
  };

  const findKey = str => {
    return blankMetaData.find(item => getDurationKey(item) === str).key;
  };

  const handleClipDurationChange = newClipDuration => {
    // eslint-disable-next-line no-param-reassign
    const scriptDuration = getDuration(metadata, durationTypes.SPEAK_DURATION);
    const newTotalDuration = getTime(getSeconds(scriptDuration) + getSeconds(newClipDuration));
    const updatedDurations = [
      { key: findKey(durationTypes.TOTAL_DURATION), value: newTotalDuration },
      { key: findKey(durationTypes.CLIP_DURATION), value: newClipDuration },
    ];

    onMetadataChanged(updatedDurations, storyInstance);
  };

  const handlePublishSettingChange = publishSetting => {
    if (storyInstance.mProperties.platform === variants.LINEAR)
      handleInstanceRundownUpdate(publishSetting);
    else onPublishSettingsChange(publishSetting);
  };

  const handleSaveTemplate = (...args) => onSaveTemplate(...args, metadata);

  return connectDropTarget(
    <div className={!hovered ? classes.instance : classes.hoveredInstance}>
      {storyInstance && (
        <div className={classes.generalInstanceWidth}>
          <InstanceCard
            instance={storyInstance}
            mId={storyInstance ? storyInstance.mId : undefined}
            variant={variant}
            statusId={storyInstance.mState}
            isStoryEditor
            publishingPoint={storyInstance.mProperties.platform}
            publishingPointIcon={
              storyInstance.mProperties.platform === 'linear'
                ? 'linear'
                : platform.mProperties.platformIcon
            }
            assignees={members}
            onAssigneeUpdate={assignMembers}
            title={storyInstance.mTitle}
            onTitleUpdate={onTitleChange}
            onStatusChange={onStatusChange}
            initiallyCollapsed
            onPublishSettingsChange={handlePublishSettingChange}
            onClipDurationChange={handleClipDurationChange}
            clipDuration={getDuration(metadata, durationTypes.CLIP_DURATION)}
            description={mDescription}
            fixedHeight={isMaximized && height ? height : 600}
            handleUpdateMetadata={onMetadataChanged}
            mMetaData={metadata}
            onDone={handleDoneClick}
            onEditorUpdate={handleUpdate}
            onSaveTemplate={handleSaveTemplate}
            scriptDuration={getDuration(metadata, durationTypes.SPEAK_DURATION)}
            totalDuration={getDuration(metadata, durationTypes.TOTAL_DURATION)}
            writeLock={!loading && writeLock}
            defaultPlaceholderTitle={generatePlaceholderDefaultTitle(
              placeholderFormat,
              editorValueRef.current,
              placeHolderFormatValues,
            )}
            twitterThreadCount={countTwitterThread(metadata, editorValueRef.current, blankMetaData)}
            {...{
              backgroundImage,
              canShowNewDesign,
              canCreateNewTemplate,
              canDeleteTemplate,
              canDeleteTemplateFolder,
              canSeeVersionHistory,
              currentDestination,
              disableEdit,
              editorValue,
              folders,
              form,
              hostReadSpeed,
              hasChanges,
              isSavingContent,
              loading,
              lockedByUser,
              lockedByCurrentUser,
              onAssetInsert,
              onAssetRemove,
              onCmsEditingActivated,
              onCreateDuplicate,
              onCreateFolder,
              onDeleteFolder,
              onDeleteInstance,
              onDeleteTemplate,
              onDescriptionChange,
              onFocus,
              onForceUnlock,
              onLockInstance,
              onSelectTemplate,
              onUnlockInstance,
              readLock,
              schedule,
              setShowMetadata,
              setSkipDownload,
              shouldResetEditorSelection,
              showMetadata,
              storyId,
              summarize,
              summarizedValue,
              templates,
              provider,
              versions,
              currentVersionContent,
              refetchVersionList,
              refetchVersionContent,
              onRestoreVersion,
              checkVersionRestorability,
              auditListLoading,
              versionContentLoading,
            }}
          />
          <Popover
            onClose={() => setCreateNewAnchorEl(null)}
            anchorEl={showDialog ? createNewAnchorEl : null}
          >
            <CreateNew
              onCancel={handleCancelCreateNew}
              onCreate={handleCreateNewRundown}
              variant="rundown"
              defaultTitle={targetRundownTitle}
            />
          </Popover>

          <Dialog
            open={open}
            disableScrollLock
            disableRestoreFocus
            container={containerRef.current}
            onClose={() => setOpen(false)}
          >
            <AddMember
              members={members}
              variant="People"
              dialogTitle="People"
              showItems={false}
              onCancel={() => setOpen(false)}
              onOk={users => {
                assignMembers(users);
                setOpen(false);
              }}
            />
          </Dialog>
        </div>
      )}
    </div>,
  );
};

export default DropTarget(dndTypes.MEMBER, instanceTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  hovered: monitor.isOver(),
}))(Instance);
