import { Transforms } from 'slate';
import { elementTypes } from '../constants';
import isList from '../components/list/utils/isList';

const { unwrapNodes, setNodes } = Transforms;

/**
 * Toggles the specified element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} type Type for the node to toggle
 * @param {Boolean} isActive Specifies if the given element type is active or not
 * @returns {Object} SlateJS editor instance
 */

const toggleElement = (editor, type, isActive = false) => {
  unwrapNodes(editor, {
    match: isList,
    split: true,
  });

  setNodes(editor, {
    type: isActive ? elementTypes.PARAGRAPH : type,
  });

  return editor;
};

export default toggleElement;
