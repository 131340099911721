/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { distanceInWords } from 'date-fns';
import { FeedsContext } from 'globalState';
import PriorityMinimal from 'components/priorityLabel';
import useKeyPress from 'hooks/useKeyPress';
import fallbackImage from 'assets/images/default/defaultCoverPhoto.png';
import useStyles from './table-styles';

const ItemTable = props => {
  const classes = useStyles();
  const { items, onInspect } = props;

  const [selected, setSelected] = useState(undefined);
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const [cursor, setCursor] = useState(null);
  const { readItems, setReadItems } = useContext(FeedsContext);

  const markAsRead = id => {
    const read = { ...readItems } || {};
    read[id] = true;
    setReadItems(read);
  };

  useEffect(() => {
    if (items.length && downPress) {
      setCursor(prevState => (prevState < items.length - 1 ? prevState + 1 : prevState));
    }
  }, [downPress]);
  useEffect(() => {
    if (items.length && upPress) {
      setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);
  useEffect(() => {
    if (items.length) {
      setSelected(items[cursor]);
    }
  }, [cursor]);
  useEffect(() => {
    onInspect(selected);
    if (selected) {
      markAsRead(selected.mId);
    }
  }, [selected]);

  const columns = [
    { id: 'coverPhoto', label: '' },
    { id: 'mTitle', label: 'Title', maxWidth: '90%' },
    { id: 'section', label: '' },
    { id: 'priority', label: '' },
    { id: 'provider', label: 'Source' },
    {
      id: 'mPublishedAt',
      label: 'Date/Time',
      minWidth: 170,
      format: value => new Date(value).toString(),
    },
  ];

  function createRow({
    mId,
    mTitle,
    assets,
    provider,
    priority,
    mPublishedAt,
    mDescription,
    section,
    mContent,
    byline,
    href,
  }) {
    let rendition = null;

    if (assets && assets.length > 0) {
      assets.map(asset => {
        if (
          (asset && asset.type === 'Image') ||
          (asset && asset.type === 'CoverImage' && rendition === null)
        ) {
          [rendition] = asset.renditions;
        }
      });
    }

    const coverPhoto = (
      <img className={classes.coverPhoto} src={rendition ? rendition.href : fallbackImage} />
    );
    const date = `${distanceInWords(mPublishedAt, new Date())} ago`;

    return {
      mId,
      mTitle,
      coverPhoto,
      assets,
      provider,
      date,
      priority,
      mDescription,
      section,
      mContent,
      mPublishedAt,
      byline,
      href,
    };
  }

  const rows = items.map(item => {
    return createRow(item);
  });

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead classes={{ root: classes.tableHead }}>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                style={{ maxWidth: column.maxWidth }}
                classes={{
                  root: classes.headerTableCell,
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            const read = readItems && row.mId in readItems;
            const active = selected && selected.mId === row.mId;

            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row.mId}
                onClick={() => {
                  setSelected(row);
                  setCursor(i);
                }}
                className={`${i % 2 ? classes.evenRow : ''} ${active ? classes.active : ''}`}
              >
                <TableCell
                  classes={{
                    root: classes.bodyTableCell,
                  }}
                  className={classes.photoCell}
                >
                  {row.coverPhoto}
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.bodyTableCell,
                  }}
                  className={`${classes.title} ${read && !active ? classes.read : ''}`}
                >
                  {row.mTitle || row.mDescription}
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.bodyTableCell,
                  }}
                  className={`${classes.columnPadding} ${read && !active ? classes.read : ''}`}
                >
                  {row.section ? (
                    <Chip
                      label={row.section}
                      classes={{ root: classes.chip, label: classes.chipLabel }}
                    />
                  ) : null}
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.bodyTableCell,
                  }}
                  className={`${classes.columnPadding} ${read && !active ? classes.read : ''}`}
                >
                  {row.priority ? (
                    <PriorityMinimal priorityLevel={row.priority} type="minimal" />
                  ) : null}
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.bodyTableCell,
                  }}
                  className={`${classes.source} ${read && !active ? classes.read : ''}`}
                >
                  {row.provider}
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.bodyTableCell,
                  }}
                  className={`${classes.date} ${read && !active ? classes.read : ''}`}
                >
                  {row.date}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

ItemTable.propTypes = {
  /** feed articles */
  items: PropTypes.arrayOf(PropTypes.object),
  /** inspect function */
  onInspect: PropTypes.func,
};

ItemTable.defaultProps = {
  items: [],
  onInspect: () => {},
};

export default ItemTable;
