import React from 'react';
import useStorageImage from 'hooks/useStorageImage';
import { uploadToS3 } from 'utils/s3Utils';
import HeaderView from './header-view';

const HeaderContainer = props => {
  const { avatarUrl, imageKey: imageStoragePath } = props;
  const { data: imageSrc } = useStorageImage(imageStoragePath, !!avatarUrl);
  const updateAvatar = imageFile => uploadToS3(imageStoragePath, imageFile);
  return <HeaderView {...props} {...{ updateAvatar }} avatarUrl={imageSrc || avatarUrl} />;
};

export default HeaderContainer;
