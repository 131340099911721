import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    display: 'flex',
    height: '423px',
  },
  centerContent: {
    height: 'calc(100% - 64px)',
  },
  variants: {
    width: '216px',
  },
  details: {
    width: '578px',
  },
  content: {
    margin: '16px',
  },
  select: {
    width: 176,
    height: 32,
    borderRadius: '4px',
    border: '1px solid rgba(255, 255, 255, 0.25)',
  },
  textContent: {
    ...theme.typography.dina.subtitle1,
    color: theme.palette.dina.highEmphasis,
    height: '40px',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
