import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './priorityLabel-styles';

const PriorityLabel = props => {
  const { priorityLevel, type } = props;
  const classes = useStyles();

  const isSmall = type === 'small';
  const isMinimal = type === 'minimal';

  const titles = {
    priorityBreaking: 'BREAKING',
    priority1: isSmall ? '1' : 'PRIO 1',
    priority2: isSmall ? '2' : 'PRIO 2',
    priority3: isSmall ? '3' : 'PRIO 3',
    1: isMinimal ? 'B' : '',
    2: isMinimal ? '1' : '',
    3: isMinimal ? '2' : '',
    4: isMinimal ? '3' : '',
    5: isMinimal ? '4' : '',
    6: isMinimal ? '5' : '',
  };

  const smallBreaking =
    isSmall && priorityLevel === 'priorityBreaking' ? 'smallBreaking' : null;

  return (
    <div
      className={`${classes[type]} ${classes[priorityLevel]} ${classes[smallBreaking]}`}
    >
      <Typography classes={{ root: classes.labelText }}>
        {type !== 'round' && titles[priorityLevel]}
      </Typography>
    </div>
  );
};

PriorityLabel.propTypes = {
  /** Type of Priority Label components */
  type: PropTypes.oneOf([
    'vertical',
    'horizontal',
    'small',
    'round',
    'minimal',
  ]),
  /** Level of priority */
  priorityLevel: PropTypes.oneOf([
    'priorityBreaking',
    'priority1',
    'priority2',
    'priority3',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
  ]),
};

PriorityLabel.defaultProps = {
  type: 'vertical',
  priorityLevel: 'priority3',
};

export default PriorityLabel;
