import { format, isToday, isTomorrow, isYesterday } from 'date-fns';

const getRelativeDate = (date, dateFormat = 'dddd, MMM D YYYY') => {
  if (isToday(date)) return 'today';
  if (isTomorrow(date)) return 'tomorrow';
  if (isYesterday(date)) return 'yesterday';
  return format(date, dateFormat);
};

export default getRelativeDate;
