import { useContext } from 'react';
import gql from 'graphql-tag';
import { format, isWithinRange } from 'date-fns';
import { useMutation, useApolloClient } from 'react-apollo';
import memberTypes from 'graphql/memberTypes';
import differenceByProp from 'utils/differenceByProp';
import SHARE_STORY_WITH from 'graphql/mutations/shareStoryWith';
import GET_STORY from 'graphql/queries/getStory';
import updateCache from 'graphql/utils/cache/share-story';
import UserCtx from 'contexts/UserContext';
import { useSidebarContext } from 'globalState';
import { SidebarDatePickerContext } from 'globalState/sidebarDatePicker';

const GET_STORIES = gql`
  query GetStories($input: GetMemberInput, $limit: Int, $nextToken: String) {
    getMembersPagination(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
      }
      nextToken
    }
  }
`;

const useShareStory = () => {
  const user = useContext(UserCtx);
  const [{ isLeftHidden }] = useSidebarContext();
  const [selectedDates] = useContext(SidebarDatePickerContext);
  const [shareStoryMutation] = useMutation(SHARE_STORY_WITH);
  const client = useApolloClient();

  const getStory = async storyId => {
    const result = await client.query({
      query: GET_STORY,
      variables: {
        input: {
          mId: storyId,
        },
      },
    });

    const { data } = result;

    return data && data.getMember;
  };

  const shareStory = (
    storyId,
    type,
    addemembersInput,
    removedMembersInput,
    updateAssigneesInput,
  ) => {
    const { startDate, endDate, ignoreStartDate } = selectedDates;
    const date = format(new Date(), 'YYYY-MM-DD');
    const sDate = format(startDate, 'YYYY-MM-DD');
    const eDate = format(endDate, 'YYYY-MM-DD');

    const newMembers = {
      members: addemembersInput,
    };

    const removedMembers = {
      members: removedMembersInput,
    };

    const variables = {
      newMembers,
      removedMembers,
      updateAssigneesInput,
    };

    shareStoryMutation({
      variables,
      update: (proxy, mutationResult) => {
        try {
          if (
            isWithinRange(date, sDate, eDate) &&
            !isLeftHidden &&
            (addemembersInput.map(usr => usr.mId).includes(user.mId) ||
              removedMembersInput.map(usr => usr.mId).includes(user.mId))
          ) {
            const query = GET_STORIES;
            const queryVariables = {
              input: {
                mId: user.mId,
                mType: 'usr_str',
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                ignoreStartDate,
              },
              limit: 50,
            };
            let hasQueryInCache = true;
            let storyList;

            try {
              storyList = proxy.readQuery({
                query,
                variables: queryVariables,
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              hasQueryInCache = false;
            }

            if (hasQueryInCache) {
              const exists = story => {
                return story !== null && story.mId === storyId;
              };

              if (addemembersInput.map(usr => usr.mId).includes(user.mId)) {
                const story = getStory(storyId);
                if (!storyList.getMembersPagination.items.some(exists)) {
                  storyList.getMembersPagination.items.unshift(story);
                }
              } else if (removedMembersInput.map(usr => usr.mId).includes(user.mId)) {
                storyList.getMembersPagination.items = storyList.getMembersPagination.items.filter(
                  story => story.mId !== storyId,
                );
              }

              proxy.writeQuery({
                query,
                variables: queryVariables,
                data: storyList,
              });
            }
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
        updateCache(proxy, mutationResult, storyId, type, user.mId);
      },
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
  };

  const storyMemberMap = {
    usr_str: memberTypes.USER,
    tea_str: memberTypes.TEAM,
    dep_str: memberTypes.DEPARTMENT,
    mar_str: memberTypes.MARKET,
    str_con: memberTypes.CONTACT,
  };

  const shareStoryWith = async (storyId, members, existingMembers, mType) => {
    let addMembersInput = [];
    let removedMembersInput = [];

    if (mType === memberTypes.STORY_CONTACT) {
      const [addedIds, removedIds] = differenceByProp(existingMembers, members, 'mRefId');

      addMembersInput = addedIds.map(mRefId => ({
        mId: storyId,
        mRefId,
        mType,
      }));

      removedMembersInput = removedIds.map(mRefId => ({
        mId: storyId,
        mRefId,
        mType,
      }));
    } else {
      const [addedIds, removedIds] = differenceByProp(existingMembers, members, 'mId');

      addMembersInput = addedIds.map(mId => ({
        mId,
        mRefId: storyId,
        mType,
      }));

      removedMembersInput = removedIds.map(mId => ({
        mId,
        mRefId: storyId,
        mType,
      }));
    }

    const memberType = storyMemberMap[mType];

    const assignedMembers = [];

    members.forEach(member => {
      assignedMembers.push({
        mId: member.mId,
        mType: memberType,
      });
    });

    const story = await getStory(storyId);
    const { mAssignedMembers } = story;

    (mAssignedMembers || [])
      .filter(m => m.mType !== memberType)
      .forEach(m => {
        assignedMembers.push({
          mId: m.mId,
          mType: m.mType,
        });
      });

    const updateAssigneesInput = {
      mId: storyId,
      mRefId: storyId,
      mAssignedMembers: assignedMembers,
    };

    shareStory(storyId, mType, addMembersInput, removedMembersInput, updateAssigneesInput);
  };

  const assignMemberToStory = async (storyId, memberId, memberType) => {
    let memberInput = {
      mId: memberId,
      mRefId: storyId,
      mType: memberTypes.USER_STORY,
    };

    switch (memberType) {
      case memberTypes.TEAM:
        memberInput.mType = memberTypes.TEAM_STORY;
        break;
      case memberTypes.DEPARTMENT:
        memberInput.mType = memberTypes.DEPARTMENT_STORY;
        break;
      case memberTypes.CONTACT:
      case memberTypes.STORY_CONTACT:
        memberInput = {
          mId: storyId,
          mRefId: memberId,
          mType: memberTypes.STORY_CONTACT,
        };
        break;
      default:
        break;
    }

    const assignedMembers = [];

    const story = await getStory(storyId);

    const { mAssignedMembers } = story;

    (mAssignedMembers || []).forEach(m => {
      assignedMembers.push({
        mId: m.mId,
        mType: m.mType,
      });
    });

    assignedMembers.push({
      mId: memberId,
      mType: storyMemberMap[memberInput.mType],
    });

    let updateAssigneesInput = null;

    updateAssigneesInput = {
      mId: storyId,
      mRefId: storyId,
      mAssignedMembers: assignedMembers,
    };

    shareStory(storyId, memberInput.mType, [memberInput], [], updateAssigneesInput);
  };

  return [assignMemberToStory, shareStoryWith];
};

export default useShareStory;
