import React, { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { useSlate, ReactEditor } from 'slate-react';
import { elementTypes } from 'components/editor/constants/types';
import getIndentLevel from './utils/getIndentLevel';
import PrimaryDropZone from './components/primaryDropZone';
import SecondaryDropZone from './components/secondaryDropZone';
import MediaDropZone from './components/mediaDropZone';
import AssetDropZone from './components/assetDropZone';
import TextDropZone from './components/textDropZone';
import useStyles from './styles';

const Paragraph = ({ attributes, children, element }) => {
  const indentLevel = useMemo(() => getIndentLevel(element), [element]);
  const editor = useSlate();
  const classes = useStyles({ indentLevel });
  const [index] = ReactEditor.findPath(editor, element);

  const renderContent = useCallback(
    provided => {
      const { draggableProps, innerRef } = provided;

      return (
        <div ref={innerRef} {...draggableProps}>
          <div className={classes.root} {...attributes}>
            <PrimaryDropZone {...{ element }}>
              <SecondaryDropZone {...{ element }}>
                <MediaDropZone {...{ element }}>
                  <AssetDropZone {...{ element }}>
                    <TextDropZone {...{ element }}>{children}</TextDropZone>
                  </AssetDropZone>
                </MediaDropZone>
              </SecondaryDropZone>
            </PrimaryDropZone>
          </div>
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributes, children, element],
  );

  return (
    <Draggable isDragDisabled draggableId={`paragraph${index}`} {...{ index }}>
      {renderContent}
    </Draggable>
  );
};

Paragraph.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Paragraph.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.PARAGRAPH,
    data: { indentLevel: 0 },
    children: [],
  },
};

export default memo(Paragraph);
