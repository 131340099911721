import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '8px',
    height: '100%',
    backgroundColor: ({ isAuto, isGraphic }) =>
      isAuto
        ? palette.dina[isGraphic ? 'timelineGraphic' : 'timelineSecondaryAccessory']
        : 'transparent',
    borderTopLeftRadius: '4px',
  },
  top: {
    height: 'calc(50% - 6px)',
    borderTopLeftRadius: '4px',
    boxSizing: 'border-box',
    borderTop: ({ isGraphic }) =>
      `1px solid ${palette.dina[isGraphic ? 'timelineGraphic' : 'timelineSecondaryAccessory']}`,
    borderLeft: ({ isGraphic }) =>
      `1px solid ${palette.dina[isGraphic ? 'timelineGraphic' : 'timelineSecondaryAccessory']}`,
  },
  middle: { height: '12px' },
  bottom: {
    height: 'calc(50% - 6px)',
    borderBottomLeftRadius: ({ isAuto }) => (isAuto ? 0 : '4px'),
    boxSizing: 'border-box',
    borderBottom: ({ isGraphic }) =>
      `1px solid ${palette.dina[isGraphic ? 'timelineGraphic' : 'timelineSecondaryAccessory']}`,
    borderLeft: ({ isGraphic }) =>
      `1px solid ${palette.dina[isGraphic ? 'timelineGraphic' : 'timelineSecondaryAccessory']}`,
  },
}));

export default useStyles;
