import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',

    position: 'static',
    width: '256px',
    height: ({ height }) => `calc(${height} - 120px)`,
    left: '0px',
    top: '0px',

    flex: 'none',
    order: '0',
    alignSelf: 'flex-start',
    margin: '0px 0px',
    background: theme.palette.dina.blackHoverOverlay,
  },

  header: {
    position: 'relative',
    width: '256px',
    height: '40px',
    left: '0px',
    top: '0px',

    flex: 'none',
    order: '0',
    alignSelf: 'flex-start',
    margin: '0px 1px',
  },

  headerTitle: {
    position: 'absolute',
    left: '16px',
    right: '8px',
    top: 'calc(50% - 18px/2)',
    bottom: '0px',

    /* Subtitles/Subtitle 1 */

    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',
    /* or 112% */

    letterSpacing: '0.15px',

    /* textHighEmphasis */

    color: '#FFFFFF',
  },

  headerDivider: {
    position: 'absolute',
    height: '1px',
    left: '0px',
    right: '1px',
    top: '39px',
    bottom: '0px',
  },

  menuContainer: {
    height: '100%',
    width: '100%',
  },

  subtitle: {
    ...theme.typography.dina.overline,
    position: 'static',
    height: '12px',
    left: '16px',
    right: '16px',
    top: '9.5px',

    flex: 'none',
    order: '1',
    alignSelf: 'center',
    margin: '8px 0px',
  },

  menuItemHeader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 16px',

    position: 'static',
    width: '153px',
    height: '31px',
    left: '0px',
    top: 'calc(50% - 31px / 2)',

    flex: 'none',
    order: '1',
    alignSelf: 'flex-start',
    margin: '0px 0px',
  },

  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 16px',

    position: 'static',
    width: '256px',
    height: '32px',
    left: '0px',
    top: '73px',

    flex: 'none',
    order: '2',
    margin: '0px 1px',
  },
  menuItemLabel: {
    ...theme.typography.dina.button,
    position: 'static',
    left: '16px',
    right: '40px',
    top: '0%',
    bottom: '0%',

    color: theme.palette.dina.textEmphasis,

    flex: 'none',
    order: '1',
    alignSelf: 'center',
    margin: '8px 0px',
  },
  lastItem: {
    paddingBottom: '20px',
  },
}));

export default useStyles;
