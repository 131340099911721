import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  button: {
    width: 41,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    outline: 'none',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    margin: '2px',
  },
  inputAdornment: {
    marginLeft: '4px',
    marginRight: '4px',
  },
  icon: {
    '& path': {
      fillOpacity: 1,
    },
  },
  approved: {
    backgroundColor: theme.palette.dina.statusApproved,
  },
  danger: {
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    border: `1px solid ${theme.palette.dina.buttonBackgroundOutlined}`,
  },
  filledInput: {
    width: '100%',
    padding: 0,
    paddingLeft: '8px',
  },
  underline: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.dina.inputBorderResting}`,
    },

    '&:after': {
      borderBottom: `1px solid ${theme.palette.dina.onFocus}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
    '&:before': {
      borderBottom: `1px solid ${theme.palette.dina.disabled}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
  },
  inputProp: {
    padding: 0,
    height: 36,
  },
  input: {
    ...theme.typography.dina.listItemLabel,
    lineHeight: '16px',
    letterSpacing: '0.25px',
    fontWeight: 'normal',
  },
  focused: {},
  disabled: {},
  error: {},
}));

export default useStyles;
