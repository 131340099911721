import React, { Component } from 'react';

const clipString = (str, to, include) => {
  return str.substr(0, str.indexOf(to) + (include ? to.length : 0));
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, timeStamp: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
      timeStamp: Date.now(),
    });
  }

  render() {
    const { errorInfo, error, timeStamp } = this.state;
    const { children } = this.props;
    const isDevEnv = process.env.NODE_ENV === 'development';

    const tStamp = new Intl.DateTimeFormat('en-US', {
      // year: 'numeric',
      // month: '2-digit',
      // day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(timeStamp);

    if (errorInfo) {
      return (
        <div
          style={{ display: 'flex', justifyContent: 'center', color: 'rgba(255, 255, 255, 0.65)' }}
        >
          <div style={{ width: 600, position: 'relative' }}>
            <h3>
              Something went wrong.{' '}
              <span
                role="presentation"
                style={{ cursor: 'pointer', color: '#3997FF' }}
                onClick={() => window.location.reload()}
              >
                Try to reload the page
              </span>{' '}
              <span
                style={{
                  position: 'absolute',
                  right: 0,
                  fontSize: 16,
                  color: 'rgba(255, 255, 255, 0.35)',
                }}
              >{`[${tStamp}]`}</span>
            </h3>
            <details style={{ whiteSpace: 'pre-wrap', cursor: 'pointer' }}>
              {isDevEnv && error && (
                <>
                  {error.toString()}
                  <br />
                  {errorInfo.componentStack}
                </>
              )}
              {!isDevEnv && error && clipString(error.toString(), 'message', true)}
            </details>
          </div>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
