import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants/types';
import headingComponents from './constants/headingComponents';
import useStyles from './styles';

const Heading = ({ attributes, children, element }) => {
  const classes = useStyles();
  const HeadingComponent = headingComponents[element.type];

  return (
    <HeadingComponent className={classes.root} {...attributes}>
      {children}
    </HeadingComponent>
  );
};

Heading.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Heading.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.HEADING_ONE,
    children: [],
  },
};

export default memo(Heading);
