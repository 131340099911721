import { makeStyles } from '@material-ui/styles';

const useStyles = color =>
  makeStyles(theme => ({
    shape: {
      fill: theme.palette.dina[color],
      stroke: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    },
  }));

export default useStyles;
