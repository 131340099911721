import { format } from 'date-fns';

/**
 * Determines the intended date format for time grid axis
 *
 * @param {Object} xAxis D3 time scale
 * @returns {Function} Callback function for d3 x axis tickFormat
 */

const formatTicks = xAxis => (date, index, ticks) => {
  const time = format(date, 'HH:mm');

  if (time === '00:00') {
    ticks[index].parentNode.firstChild.classList.add('major');
  }
  if (time === '12:00') {
    ticks[index].parentNode.firstChild.classList.add('major');
  }

  return time.slice(-2) === '00' ? time : '';
};

export default formatTicks;
