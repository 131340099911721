import { makeStyles } from '@material-ui/styles';

// const borderRadius = 4;

const useStyles = makeStyles(({ palette }) => ({
  root: {
    paddingBottom: '8px',
    // borderRadius,
    // marginTop: '8px',
    // boxShadow: ({ showHighlight }) => showHighlight && `0 0 0 2px ${palette.dina.onFocus}`,
  },
}));

export default useStyles;
