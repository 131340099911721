import gql from 'graphql-tag';

export default gql`
  query GetInstancesByPublishingDate($input: GetMembersByPublishingDateInput) {
    getMembersByPublishingDate(input: $input) {
      mId
      mRefId
      mTitle
      mUpdatedAt
      mThumbnailUrl
      mState
      mStoryId
      mContentKey
      mDescription
      mPublishingAt
      mMetaData {
        key
        value
      }
      mProperties {
        __typename
        ... on PlatformType {
          platform
          account {
            accountTitle
          }
        }
      }
      mUsers {
        mId
        mTitle
        mAvatarUrl
      }
      mAssignedMembers {
        mId
        mType
      }
    }
  }
`;
