import React from 'react';
import Avatar from 'components/avatar';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import getInitials from 'utils/getInitials';
import { Typography } from '@material-ui/core';
import useStyles from './popoverList-styles';

const Popover = ({ items, variant, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.listContainer}>
      <div className={classes.triangle} />
      <div className={classes.listItemsContainer}>
        {items.map(item => (
          <ListItem
            key={item.mTitle}
            text={item.mTitle}
            firstChild={
              <Avatar
                variant={variant}
                type={type}
                size={24}
                src={item.mAvatarUrl}
                className={classes.avatar}
              >
                {getInitials(item.mTitle)}
              </Avatar>
            }
            iconChild={
              <Typography classes={{ root: classes.typeText }}>
                {variant === 'Team' ? 'Team' : 'Dept.'}
              </Typography>
            }
            onClick={() => {}}
            excludeDivider
          />
        ))}
      </div>
    </div>
  );
};

export default Popover;
