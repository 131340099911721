import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    cursor: 'default',
    display: 'flex',
    height: ({ height }) => height || 'auto',
    transition: '0.3s ease',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.dina.blackHoverOverlay}`,
    borderRadius: '4px',
    background: theme.palette.dina.blackHoverOverlay,
    '&:hover': {
      background: theme.palette.dina.backgroundHover,
      border: `1px solid ${theme.palette.dina.borderHover}`,
      '& $ellipsis': {
        opacity: 1,
      },
    },
    position: 'relative',
  },
  iconWrapper: {
    width: '40px',
    backgroundColor: ({ type }) =>
      theme.palette.dina[type === 'media' ? 'timelineVideoClip' : 'buttonBackgroundHighEmphasis'],
    borderRadius: '4px 0 0 4px',
    margin: 0,
  },
  contentWrapper: {
    width: '100%',
    padding: '0px 16px',
    overflow: 'hidden',
  },
  withEllipsis: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    alignItems: 'center',
  },
  titleWrapper: {
    width: 'calc(100% - 32px)',
  },
  title: {
    ...theme.typography.dina.overline,
    fontWeight: '500',
    lineHeight: '12px',
    letterSpacing: '1px',
    margin: 0,
  },
  subtitle: {
    ...theme.typography.dina.listItemLabelMedium,
    color: theme.palette.dina.highEmphasis,
    letterSpacing: '0.25px',
    margin: 0,
    marginTop: '2px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  ellipsis: {
    marginRight: '-10px',
    opacity: 0,
    transition: '0.3s ease',
  },
  dragHandle: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
}));

export default useStyles;
