import React, { useEffect } from 'react';
import useBatchGetMembers from 'hooks/useBatchGetRundownInstances';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'graphql/subscriptions/notifyMemberUpdate';
import useApolloSubscription from 'hooks/useApolloSubscription';

const DataLoader = ({ mId, refId, data, setInstanceDataLoading }) => {
  const [subscribe, unSubscribe] =  useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: mId,
    },
  });
  
  useEffect(()=>{
    subscribe();
    return ()=>{
      unSubscribe();
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  const { getRundown: rundown } = data;
  const [loadItems] = useBatchGetMembers();

  const loadInstances = async () => {
    setInstanceDataLoading(true);
    await loadItems(rundown);
    setInstanceDataLoading(false);
  };

  useEffect(() => {
    loadInstances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refId]);

  // setInstanceDataLoading(loading);

  /* useEffect(() => {
    if (!loading) {
      setSkip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]); */

  return <div style={{ display: 'none' }} />;
};

export default DataLoader;
