import gql from 'graphql-tag';

export default gql`
  query GetMember($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mProperties {
        __typename
        ... on PlatformType {
          platform
          provider {
            requestId
            updateEndpoint
            embeddedEndpoint
            state
            message
            previewEndpoint
          }
          account {
            accountId
            accountRefId
            accountUrl
            accountLogo
            accountTitle
            orderType
          }
        }
      }
    }
  }
`;
