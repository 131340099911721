const primaryTypes = {
  CAMERA: 'camera',
  PACKAGE: 'package',
  VOICE_OVER: 'voiceOver',
  LIVE: 'live',
  FULLSCREEN_GRAPHICS: 'fullscreenGraphics',
  DVE: 'dve',
  JINGLE: 'jingle',
  TELEPHONE: 'telephone',
  ADLIB: 'adlib',
  BREAK: 'break',
};

const secondaryTypes = {
  OVERLAY_GRAPHICS: 'overlayGraphics',
  AUDIO: 'audio',
  TEXT_TELE_PROMPTER: 'textTelePrompter',
  ACCESSORY: 'accessory',
};

const mediaTypes = {
  GRAPHICS: 'graphics',
  CLIP: 'clip',
};

const RundownItemTypes = {
  ...primaryTypes,
  ...secondaryTypes,
  // Properties of the Meta (i.e. Mos ID)
  properties: {
    camera: { id: 0 },
    package: { id: 1 },
    voiceOver: { id: 2 },
    live: { id: 3 },
    fullscreenGraphics: { id: 4 },
    dve: { id: 5 },
    jingle: { id: 6 },
    telephone: { id: 7 },
    adlib: { id: 8 },
    break: { id: 9 },
    overlayGraphics: { id: 100 },
    audio: { id: 215 },
    textTelePrompter: { id: 320 },
    accessory: { id: 220 },
  },
};

export { primaryTypes, secondaryTypes, mediaTypes };
export default RundownItemTypes;
