import { isKeyHotkey } from 'is-hotkey';

const isBoldHotkey = isKeyHotkey('mod+b');
const isItalicHotkey = isKeyHotkey('mod+i');
const isUnderlineHotkey = isKeyHotkey('mod+u');
const isUndoHotkey = isKeyHotkey('mod+z');
const isShiftTabHotkey = isKeyHotkey('shift+tab');

export {
  isBoldHotkey,
  isItalicHotkey,
  isUnderlineHotkey,
  isUndoHotkey,
  isShiftTabHotkey,
};
