import gql from 'graphql-tag';

export default gql`
  mutation CreateFolder($input: CreateFolderInput) {
    createFolder(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mParentId
      items {
        mId
        mRefId
        mTitle
        mContentKey
      }
    }
  }
`;
