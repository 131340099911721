import React from 'react';
import { Typography } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '../MenuItem/FavoriteMenuItem';
import PlatformIcons from '../PlatformIcons';

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: theme.palette.dina.mediumEmphasis,
  },
  div: {
    margin: '15px',
  },
  button: {
    margin: '5px',
  },
}));

const Favorites = props => {
  const { favoriteItems, onClose } = props;
  const classes = useStyles();
  const PlatformIcon = PlatformIcons.segment;

  return (
    <div className={classes.div}>
      <Typography className={classes.text}>SEGMENTS</Typography>

      <MenuItem
        label="Breaking News"
        type="danger"
        onClose={account => onClose({ type: 'favorite', account })}
      >
        <SvgIcon>
          <PlatformIcon />
        </SvgIcon>
      </MenuItem>
      <MenuItem
        label="Breaking Sports"
        type="cta"
        onClose={account => onClose({ type: 'favorite', account })}
      >
        <SvgIcon>
          <PlatformIcon />
        </SvgIcon>
      </MenuItem>
      {favoriteItems &&
        favoriteItems.map(item => (
          <MenuItem
            key={item.mProperties.platform}
            label={item.mProperties.platform}
            type="normal"
            onClose={account =>
              onClose({
                platform: item.mProperties.platform,
                account,
                type: 'favorite',
              })
            }
          >
            {}
          </MenuItem>
        ))}
    </div>
  );
};

export default Favorites;
