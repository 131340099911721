import { Transforms } from 'slate';
import { elementTypes } from 'components/editor/constants';
import isList from './isList';

const { PARAGRAPH, LIST_ITEM } = elementTypes;
const { unwrapNodes, setNodes, wrapNodes } = Transforms;

/**
 * Toggles the specified list element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} type Type for the node to toggle
 * @param {Boolean} isActive Specifies if list element type is active or not
 * @returns {Object} SlateJS editor instance
 */

const toggleList = (editor, type, isActive = false) => {
  unwrapNodes(editor, {
    match: isList,
    split: true,
    mode: 'all',
  });

  setNodes(editor, {
    type: isActive ? PARAGRAPH : LIST_ITEM,
  });

  if (!isActive && isList({ type })) {
    const block = { type, children: [] };

    wrapNodes(editor, block);
  }

  return editor;
};

export default toggleList;
