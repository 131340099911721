import React, { useState, useEffect, memo, useCallback, useRef } from 'react';
import { ReactComponent as ImageIcon } from 'assets/icons/systemicons/editor/photo_off.svg';
import PropTypes from 'prop-types';
import Dialog from 'components/dialog';
import CreateNew from 'components/createNew';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { removeBlock, updateBlock } from 'components/editor/utils';
import { elementTypes } from 'components/editor/constants/types';
import { menuOptions } from 'components/editor/constants';
import useImageUpload from 'hooks/useImageUpload';
import generateName from 'components/editor/utils/generateName';
import actionTypes from 'components/editor/constants/types/actionTypes';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import useStorageImage from 'hooks/useStorageImage';
import AddMedia from '../addMedia';
import Box from '../box';
import useStyles from './styles';

const { select, setNodes } = Transforms;

const Image = ({ attributes, children, element, readOnly }) => {
  const classes = useStyles();
  const editor = useSlate();
  const { containerRef, defaultPlaceholderTitle, update, isAllowed } = useEditorContext();
  const [dialogOpen, setDialogOpen] = useState(false);

  /** Image data fetch */
  const { data } = element;
  const { src, cache } = data;
  const cacheRef = useRef(cache || null);
  const { data: imageData } = useStorageImage(src);
  const imageSrc = (src && imageData) || cacheRef.current;

  const removeCacheFromSlateData = useCallback(() => {
    if (cache) {
      const path = ReactEditor.findPath(editor, element);

      Transforms.setNodes(editor, { data: { ...element.data, cache: undefined } }, { at: path });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cache, element]);

  useEffect(removeCacheFromSlateData, []);

  /** Dialog */
  const { selection } = editor;
  const selectionCacheRef = useRef(selection);

  const closeDialog = useCallback(() => setDialogOpen(false), []);
  const openDialog = useCallback(() => setDialogOpen(true), []);

  const onImageLoad = useCallback(
    async (file, fileUrl) => {
      const fileName = generateName(file.type);
      cacheRef.current = fileUrl;

      const result = await update({
        type: actionTypes.ASSET_INSERT,
        payload: { document: editor.children, file, fileName },
      });
      const updatedData = {
        ...element.data,
        ...result,
      };

      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData }, { at: path });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const imageTypes = ['png', 'jpeg'];

  const uploadImage = useImageUpload({
    disableResize: true,
    imageTypes,
    onImageLoad,
  });

  const handleOpenDialog = useCallback(
    event => {
      event.preventDefault();

      selectionCacheRef.current = selection;
      openDialog();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selection],
  );

  const handleCreate = useCallback(
    async title => {
      closeDialog();

      try {
        if (selectionCacheRef.current) select(editor, selectionCacheRef.current);

        const result = await update({
          type: actionTypes.CREATE_PLACEHOLDER,
          payload: { document: editor.children, title },
        });

        const updatedData = {
          ...result,
          mTitle: title,
          itemType: 'image',
          mediaType: 'image/placeholder',
          showThumbnail: false,
        };

        const path = ReactEditor.findPath(editor, element);
        setNodes(editor, { data: updatedData, type: elementTypes.PLACEHOLDER }, { at: path });
      } catch (error) {
        // console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const handleCancel = useCallback(() => {
    if (selectionCacheRef.current) select(editor, selectionCacheRef.current);
    closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** to be discussed and implemented later */
  const removeImage = useCallback(
    event => {
      event.preventDefault();
      updateBlock(editor, element, {}, update);
      cacheRef.current = null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!isAllowed)
    return (
      <div {...attributes}>
        {children}
        <div className={classes.wrapper} contentEditable={false}>
          {imageSrc && <img className={classes.image} src={imageSrc} alt="inserted asset" />}
        </div>
      </div>
    );

  return (
    <div {...attributes}>
      {children}
      <Box
        iconComponent={<ImageIcon className={classes.icon} />}
        title="Image"
        readOnly={readOnly}
        menuItems={menuOptions}
        onMenuSelect={onMenuSelect}
        type="media"
      >
        <AddMedia
          addMedia={uploadImage}
          removeMedia={removeImage}
          createPlaceholder={handleOpenDialog}
          mediaSrc={imageSrc}
          mediaType="image"
          mediaWidth={156}
        />
      </Box>
      <Dialog container={containerRef.current} open={dialogOpen} onClose={handleCancel}>
        <CreateNew
          variant="placeholder"
          defaultTitle={defaultPlaceholderTitle}
          onCreate={handleCreate}
          onCancel={handleCancel}
        />
      </Dialog>
    </div>
  );
};

Image.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Image.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { src: '' },
    type: elementTypes.IMAGE,
  },
};
export default memo(Image);
