import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import GET_STORIES from 'graphql/queries/getStoriesPagination';
import { getMembersOfQuery, getStoriesQuery } from 'graphql/queryVariables';
import memberTypes from 'graphql/memberTypes';
import updateStoryList from 'graphql/utils/cache/notification/updateStoryList';

const getUpdatedList = (list, item) => {
  const userUpdated = item.user;
  const updatedList = list;
  if (item.crudAction === 'INSERT') {
    userUpdated.__typename = 'MemberType';
    userUpdated.mDescription = item.user.mDescription || null;
    userUpdated.mAvatarUrl = item.user.mAvatarUrl || null;
    const userExist = updatedList.filter(u => u.mId === item.user.mId);
    if (userExist.length === 0) {
      updatedList.push(userUpdated);
    }
    return updatedList;
  }
  // crudAction is REMOVE if it is not INSERT.
  if (item.crudAction === "REMOVE") {
    return updatedList.filter(u => u.mId !== item.user.mId);
  }
  return list;
  // return updatedList.filter(u => u.mId !== item.user.mId);
};

const updateCahe = (proxy, item, query, variables) => {
  try {
    const list = proxy.readQuery({
      query,
      variables,
    });
    const updatedList = getUpdatedList(list.getMembersOf, item);
    // Write updated member list in the cache.
    proxy.writeQuery({
      query,
      variables,
      data: {
        getMembersOf: updatedList,
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const shareStoryWithUser = (proxy, story) => {
  const { user, loggedInUserId, selectedDates } = story.message;
  // const variables = getMembersOfQuery(story.mId);
  if (loggedInUserId === user.mId) {
    const { startDate, endDate, ignoreStartDate } = selectedDates;
    const variablesForStoryList = getStoriesQuery(
      user.mId,
      memberTypes.USER_STORY,
    );
    const startDateISO = startDate.toISOString();
    const endDateISO = endDate.toISOString();
    variablesForStoryList.input.startDate = startDateISO;
    variablesForStoryList.input.endDate = endDate.toISOString();
    variablesForStoryList.input.ignoreStartDate = ignoreStartDate;
    const rootQuery = proxy.cache.data.data.ROOT_QUERY;
    const queryInCache = `getMembersPagination({"input":{"endDate":"${endDateISO}","ignoreStartDate":${ignoreStartDate},"mId":"${user.mId}","mType":"usr_str","startDate":"${startDateISO}"},"limit":50})`;
    // If query doesn't exists in cache return.
    if (rootQuery[queryInCache] === undefined) return;
    updateStoryList(proxy, story, GET_STORIES, variablesForStoryList);
  }
  // updateCahe(proxy, story, GET_MEMBERS_OF, variables);
};

export default shareStoryWithUser;
