import React, { createContext, useContext, useState } from 'react';

const RundownListContext = createContext();

const RundownContextProvider = ({ children }) => {
  const [openId, setOpenId] = useState('');

  return (
    <RundownListContext.Provider value={{ openId, setOpenId }}>
      {children}
    </RundownListContext.Provider>
  );
};

export const useRundownListContext = () => useContext(RundownListContext);

export default RundownContextProvider;
