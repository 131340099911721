import React, { useState, useMemo } from 'react';
import Metadata from 'components/metadata';
import Chip from 'components/chip';
import Dialog from 'components/dialog';
import Scrollbar from 'screens/main/components/scrollbar';
import AddMember from 'components/addMemberDialog';
import CategoryIconLoader from 'utils/categoryIconLoader';
import Group from '../group';
import useStyles from './metadata-styles';

const isNotEmpty = array => !!array.length;

const MetadataView = ({
  categories,
  analytics,
  containerRef,
  metadata,
  dialogHeight,
  updateCategories,
  ...rest
}) => {
  const classes = useStyles();
  const [openCategory, setOpenCategory] = useState(false);

  const changeCategories = selected => {
    const selectedCategories = selected.map(s => ({
      label: s.mTitle,
      categoryId: s.mId,
      score: 0.9,
    }));
    updateCategories(selectedCategories);
    setOpenCategory(!openCategory);
  };

  const handleOpenCategory = () => setOpenCategory(!openCategory);

  const memoizedMetadata = useMemo(
    () => <Metadata usage="compact" metadata={metadata} {...rest} />,
    [JSON.stringify(metadata)],
  );

  return (
    <Scrollbar>
      <div>
        <Group collapsed label="Meta" itemCount={metadata.length}>
          {memoizedMetadata}
        </Group>
        <Group
          label="News Categories"
          itemCount={categories.length}
          onAddClick={handleOpenCategory}
        >
          <div className={isNotEmpty(categories) ? classes.chipGroup : ''}>
            {categories.map(category => (
              <div key={category.categoryId} className={classes.chip}>
                <Chip
                  variant="Category"
                  avatarUrl={CategoryIconLoader(category.categoryId)}
                  width={280}
                  label={category.label}
                />
              </div>
            ))}
          </div>
        </Group>
        <Group label="Labels" itemCount={analytics.length}>
          <div className={isNotEmpty(analytics) ? classes.chipGroup : ''}>
            {analytics.map(analytic => (
              <div key={analytic.categoryId} className={classes.chip}>
                <Chip
                  width={280}
                  showAvatar={false}
                  label={`${analytic.label} (${Math.round(analytic.score * 100)}%)`}
                />
              </div>
            ))}
          </div>
        </Group>
        <Dialog
          disableScrollLock
          disableRestoreFocus
          open={openCategory}
          container={containerRef.current}
          onClose={handleOpenCategory}
        >
          <AddMember
            variant="Category"
            dialogTitle="Add or remove Categories"
            onCancel={handleOpenCategory}
            height={dialogHeight}
            onOk={changeCategories}
            members={categories.map(category => ({
              mId: category.categoryId,
              mTitle: category.label,
              mAvatarUrl: CategoryIconLoader(category.categoryId),
            }))}
          />
        </Dialog>
      </div>
    </Scrollbar>
  );
};

export default MetadataView;
