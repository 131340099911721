import { elementTypes } from 'components/editor/constants';
// eslint-disable-next-line max-len
import { ReactComponent as BulletedList } from 'assets/icons/systemicons/editor/listUnordered_off.svg';
// eslint-disable-next-line max-len
import { ReactComponent as NumberedList } from 'assets/icons/systemicons/editor/listOrdered_off.svg';

const { UNORDERED_LIST, ORDERED_LIST } = elementTypes;

const iconComponents = {
  [UNORDERED_LIST]: BulletedList,
  [ORDERED_LIST]: NumberedList,
};

export default iconComponents;
