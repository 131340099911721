import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  draggableInner: {
    marginTop: 4,
    marginBottom: 4,
  },
  root: {
    width: '100%',
    pointerEvents: ({ readOnly }) => (readOnly ? 'none' : 'all'),
    userSelect: 'none',
  },
  box: {
    position: 'relative',
    border: `1px solid ${palette.dina.buttonBackgroundOutlined}`,
    borderRadius: 4,
    width: '100%',
    height: 40,
    display: 'flex',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: palette.dina.whiteHoverOverlay,
      '& $menu': { opacity: 1, width: 32 },
      '& $titleBox': { width: 'calc(100% - 96px - 42px - 6px - 32px)' },
    },
  },
  rundownIcon: {
    position: 'absolute',
    top: -1,
    left: -1,
  },
  content: {
    paddingLeft: '42px',
    // paddingLeft: '2px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%',
  },
  titleBox: {
    width: 'calc(100% - 96px - 42px - 6px)',
    transition: 'width 0.3s ease',
    willChange: 'width',
  },
  menu: {
    width: 0,
    opacity: 0,
    transition: 'all 0.3s ease',
    overflow: 'hidden',
    willChange: 'width opacity',
  },
  children: {
    marginLeft: ({ isEmpty }) => !isEmpty && 40,
    marginTop: ({ isEmpty }) => !isEmpty && 8,
  },
  placheholders: {
    width: 40,
    height: 38,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  asset: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },
  secondaryItems: {
    marginLeft: 40,
  },
}));

export default useStyles;
