import { useMutation } from 'react-apollo';
import CREATE_FOLDER from 'graphql/mutations/createFolder';
import GET_FOLDERS from 'graphql/queries/getFolders';

const useCreateFolder = () => {
  const [createFolderMutation] = useMutation(CREATE_FOLDER);

  const create = async (mTitle, mParentId) => {
    const input = { mTitle };
    if (mParentId) input.mParentId = mParentId;
    await createFolderMutation({
      variables: { input },
      update: (proxy, mutationResult) => {
        const { createFolder } = mutationResult.data;
        try {
          const folderList = proxy.readQuery({
            query: GET_FOLDERS,
          });

          if (
            !folderList.getFolders.find(
              folder => folder.mRefId === createFolder.mRefId,
            )
          )
            folderList.getFolders.push(createFolder);

          proxy.writeQuery({
            query: GET_FOLDERS,
            data: folderList,
          });
        } catch (err) {
          console.error(err);
        }
      },
    });
  };

  return [create];
};

export default useCreateFolder;
