import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useInputEvents from 'hooks/useInputEvents';
import { useSelectionContext } from 'contexts/SelectionContext';
import useDidMount from 'hooks/useDidMount';
import { Typography, ClickAwayListener } from '@material-ui/core';
import FallbackSrc from 'assets/images/default/defaultThumbnail.png';
import IconView from './icon-view';
import useStyles from './styles';

const FixedColumns = ({
  isPlaceholder,
  instanceId,
  thumbnail,
  items,
  title,
  updateTitle,
  disableEdit,
}) => {
  const classes = useStyles();
  const didMount = useDidMount();
  const [selectedIds] = useSelectionContext();
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(title);

  const resetTitle = () => {
    setInputValue(title);
    setShowInput(false);
  };

  useEffect(() => {
    if (didMount) setInputValue(title);
  }, [title]);

  useEffect(() => {
    if (didMount && selectedIds.length === 1 && selectedIds[0] !== instanceId) resetTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  const onUpdate = newValue => {
    if (!newValue) setInputValue(title);
    else if (newValue === title) setInputValue(newValue);
    else updateTitle(instanceId, newValue);

    setShowInput(!showInput);
  };
  const [inputRef, handleKeyDown] = useInputEvents(onUpdate, inputValue, title, true);

  const showOrHideInput = () => setShowInput(!showInput);
  const handleChange = event => setInputValue(event.target.value);

  if (isPlaceholder)
    return (
      <div className={classes.titleDiv}>
        <Typography classes={{ root: classes.placeholder }}>Instance Placeholder...</Typography>
      </div>
    );

  return (
    <>
      <div className={classes.thumbnailDiv}>
        <img src={thumbnail || FallbackSrc} alt="Thumbnail" className={classes.thumbnailImage} />
      </div>
      <IconView items={items} />
      <div className={classes.titleDiv}>
        {showInput && (
          <ClickAwayListener onClickAway={resetTitle}>
            <input
              key="title"
              id={`${title}-title`}
              ref={inputRef}
              onKeyDown={handleKeyDown}
              value={inputValue}
              className={classes.input}
              onChange={handleChange}
              disabled={disableEdit}
            />
          </ClickAwayListener>
        )}
        {!showInput && (
          <Typography classes={{ root: classes.title }} onClick={showOrHideInput}>
            {inputValue}
          </Typography>
        )}
      </div>
      <div className={classes.verticalDivider} />
    </>
  );
};

FixedColumns.propTypes = {
  thumbnail: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  /** Boolean that stops an user from editing a rundown list */
  disableEdit: PropTypes.bool,
};

FixedColumns.defaultProps = {
  thumbnail: '',
  title: '',
  items: [],
  disableEdit: false,
};
export default FixedColumns;
