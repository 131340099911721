import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  root: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate3d(-50%, calc(-50% - 48px), 0)',
    width: '280px',
    height: '176px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: '64px',
    height: '64px',
    '& path': {
      fillOpacity: '0.54',
    },
  },
  text: {
    ...theme.typography.dina.body1,
    fontWeight: 'normal',
    color: theme.palette.dina.mediumEmphasis,
    textAlign: 'center',
    width: '100%',
    marginTop: '16px',
  },
}));

export default useStyles;
