import React from 'react';
import { useQuery } from 'react-apollo';
import GET_MEMBERS from 'graphql/queries/getUsers';
import memberTypes from 'graphql/memberTypes';
import LoadingIndicator from 'components/loadingIndicator';
import GET_INSTANCE from 'graphql/queries/getInstance';
import { getStoryInstancesQuery, getMembersOfTypeQuery } from 'graphql/queryVariables';
import ContentView from './content-view';

const ContentContainer = ({ storyId, contentLoading, ...rest }) => {
  const { data: instanceData, error, loading: instanceLoading } = useQuery(GET_INSTANCE, {
    variables: getStoryInstancesQuery(storyId),
    fetchPolicy: 'cache-first',
  });

  const { data: peopleData, loading: peopleLoading } = useQuery(GET_MEMBERS, {
    variables: getMembersOfTypeQuery(memberTypes.USER),
    fetchPolicy: 'cache-first',
  });

  if (error) return `${error.message}`;
  if (instanceLoading || contentLoading || peopleLoading) return <LoadingIndicator />;

  let instances = [];
  if (instanceData && instanceData.getMembers) {
    const { getMembers } = instanceData;
    instances = [...getMembers];
  }

  let users = [];

  if (peopleData && peopleData.getMembersOftype) users = peopleData.getMembersOftype;

  return <ContentView {...{ instances, storyId, users }} {...rest} />;
};

export default ContentContainer;
