import React, { useCallback } from 'react';
import { useQuery } from 'react-apollo';
import GET_MEMBERS from 'graphql/queries/getUsers';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import memberTypes from 'graphql/memberTypes';
import useCreateAsset from 'hooks/useCreateAsset';
import { getFileAssetData, getAssetData } from 'utils/assetData';
import Editor, { isOlderSlateValue, migrateValue, actionTypes } from 'components/editor';

const { CHANGE, ASSET_INSERT, CREATE_ASSET } = actionTypes;

const EditorContainer = ({
  background,
  content,
  height,
  hostReadSpeed,
  mId,
  onChange,
  onClickAway,
  readOnly,
  shouldResetEditorSelection,
  ...rest
}) => {
  const { data: peopleData } = useQuery(GET_MEMBERS, {
    variables: getMembersOfTypeQuery(memberTypes.USER),
    fetchPolicy: 'cache-and-network',
  });

  const [createStoryAsset] = useCreateAsset();

  const users = peopleData ? peopleData.getMembersOftype : [];
  const value = content && isOlderSlateValue(content) ? migrateValue(content) : content;

  const createAsset = useCallback(
    async (storyId, assetData) => {
      const asset = getAssetData(storyId, assetData);
      const result = await createStoryAsset(storyId, asset, true);

      return result;
    },
    [createStoryAsset],
  );

  const onAssetInsert = useCallback(
    async (file, fileName) => {
      const assetData = getFileAssetData(mId, file);
      const sourceData = {
        mId: assetData.mId,
        mRefId: assetData.mRefId,
      };

      try {
        const result = await createStoryAsset(mId, assetData);
        const { createAssets: assets } = result.data;
        if (assets && assets[0]) {
          sourceData.src = assets[0].mContentKey;
        }
      } catch (e) {
        // console.log(e)
      }

      return sourceData;
    },
    [createStoryAsset, mId],
  );

  const update = useCallback(
    ({ type, payload }) => {
      if (type === CHANGE) onChange(payload);

      if (type === CREATE_ASSET) {
        const { asset } = payload;

        return createAsset(mId, asset);
      }

      if (type === ASSET_INSERT) {
        const { file, fileName } = payload;

        return onAssetInsert(file, fileName);
      }

      return null;
    },
    [createAsset, mId, onAssetInsert, onChange],
  );

  return (
    <Editor
      isFixedHeightEditor={false}
      shouldResetSelection={shouldResetEditorSelection}
      {...{ background, height, hostReadSpeed, readOnly, update, users, value }}
    />
  );
};

export default EditorContainer;
