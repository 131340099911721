import React from 'react';
import Searchbar from 'components/searchbar';
import LoadingIndicator from 'components/loadingIndicator';
import { useModel, useModelProperty } from 'hooks/useDataModel';
import useGetPlatforms from 'hooks/useGetPlatforms';
import ICONS from 'assets/icons/icons';
import FILTER_PREFIX from 'utils/constants/filterPrefix';
import FreeTextInput from 'components/searchbar/advanced/freeTextInput';
import CheckboxInput from 'components/searchbar/advanced/checkboxInput';
import MemberInput from 'components/searchbar/advanced/memberInput';

const SearchContainer = props => {
  const { chips, chipChangeHandler, customFilter, toggleCustomFilter, time, view } = props;

  // Advanced search data model
  const myModel = useModel()[0];
  const title = useModelProperty(myModel, 'mTitle');
  const description = useModelProperty(myModel, 'mDescription');
  const platform = useModelProperty(myModel, 'platform');
  const team = useModelProperty(myModel, 'team');
  const user = useModelProperty(myModel, 'user');
  const department = useModelProperty(myModel, 'department');

  const [platforms, platformsLoadingError, platformsLoading] = useGetPlatforms(time);
  const { TITLE, DESCRIPTION, PLATFORM, USER, DEPARTMENT, TEAM } = FILTER_PREFIX;

  if (platformsLoading) return <LoadingIndicator />;
  if (platformsLoadingError) {
    return <div>Error! {platformsLoadingError.message}</div>;
  }

  const platformCheckBoxes = platforms.map(plat => plat.mProperties.platform);

  // advanced search dialog input fields
  const inputFields = (
    <>
      <FreeTextInput
        icon={ICONS.H1_OFF}
        label="Titles"
        value={title()}
        onChange={val => title(val)}
      />
      <FreeTextInput
        icon={ICONS.DESCRIPTION}
        label="Descriptions"
        value={description()}
        onChange={val => description(val)}
      />
      {view === 'Status' || view === 'Hours' ? (
        <CheckboxInput
          icon={ICONS.INSTANCE}
          label="Filter on Instance Types"
          value={platform() ? platform() : []}
          onChange={val => platform(val)}
          boxes={platformCheckBoxes}
        />
      ) : null}
      <MemberInput
        icon={ICONS.USER}
        label="People"
        value={user() ? user() : []}
        type="People"
        dialogTitle="People"
        onChange={val => user(val)}
      />
      <MemberInput
        icon={ICONS.TEAMS_OFF}
        label="Teams"
        value={team() ? team() : []}
        type="Team"
        dialogTitle="Teams"
        onChange={val => team(val)}
      />
      <MemberInput
        icon={ICONS.DEPARTMENTS_OFF}
        label="Departments"
        value={department() ? department() : []}
        type="Department"
        dialogTitle="Departments"
        onChange={val => department(val)}
      />
    </>
  );

  // concat filters from the advanced input box with filters shown on the searchbar
  const addAdvancedFilters = () => {
    const advancedFilters = [];
    Object.keys(myModel).forEach(e => {
      if (e === 'mTitle' && myModel[e] && myModel[e].length > 0) {
        advancedFilters.push({
          label: `${TITLE}${myModel[e]}`,
          expression: `${TITLE}${myModel[e]}`,
        });
      } else if (e === 'mDescription' && myModel[e] && myModel[e].length > 0) {
        advancedFilters.push({
          label: `${DESCRIPTION}${myModel[e]}`,
          expression: `${DESCRIPTION}${myModel[e]}`,
        });
      } else if (e === 'platform' && myModel[e] && myModel[e].length > 0) {
        advancedFilters.push({
          label: `${PLATFORM}${myModel[e].join(' | ')}`,
          expression: `${PLATFORM}${myModel[e].join(' | ')}`,
        });
      } else if (e === 'department' && myModel[e] && myModel[e].length > 0) {
        advancedFilters.push({
          label: `${DEPARTMENT}${myModel[e].map(d => d.mTitle).join(' | ')}`,
          expression: `${DEPARTMENT}${myModel[e].map(d => d.mId).join(' | ')}`,
        });
      } else if (e === 'team' && myModel[e] && myModel[e].length > 0) {
        advancedFilters.push({
          label: `${TEAM}${myModel[e].map(t => t.mTitle).join(' | ')}`,
          expression: `${TEAM}${myModel[e].map(t => t.mId).join(' | ')}`,
        });
      } else if (e === 'user' && myModel[e] && myModel[e].length > 0) {
        advancedFilters.push({
          label: `${USER}${myModel[e].map(u => u.mTitle).join(' | ')}`,
          expression: `${USER}${myModel[e].map(u => u.mId).join(' | ')}`,
        });
      }
    });

    chipChangeHandler([...new Set([...chips, ...advancedFilters])]);
  };

  const clearAdvancedInput = () => {
    title('');
    description('');
    platform('');
    user('');
    team('');
    department('');
  };

  return (
    <Searchbar
      chips={chips}
      chipChangeHandler={updatedChips => chipChangeHandler(updatedChips)}
      customFilter={customFilter}
      toggleCustomFilter={toggleCustomFilter}
      advancedInputFields={inputFields}
      submitAdvanced={addAdvancedFilters}
      clearAdvancedInput={clearAdvancedInput}
    />
  );
};

export default SearchContainer;
