import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: 0,
    left: 0,
    maxHeight: '72px',
    minWidth: '327px',
    boxShadow: `72px -1px 0px ${theme.palette.dina.dividerLight}`,
  },
  image: {
    width: '48px',
    height: '48px',
    margin: '12px',
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '14px',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    padding: '8px',
  },
  title: {
    ...theme.typography.dina.teamInfoType,
    display: '-webkit-box',
    lineClamp: 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  timeItem: {
    maxWidth: '56px',
    marginRight: '8px',
  },
  timeHeader: {
    ...theme.typography.dina.caption,
  },
  timeValue: {
    ...theme.typography.dina.groupHeaderCounter,
    color: theme.palette.dina.highEmphasis,
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
    '&:focus': {
      backgroundColor: theme.palette.dina.onFocus,
    },
  },
  activeRundown: {
    backgroundColor: theme.palette.dina.onFocus,
  },
  icon: {
    color: theme.palette.dina.iconInactive,
    cursor: 'pointer',
  },
}));

export default useStyles;
