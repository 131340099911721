import { useEffect } from 'react';
import { useApolloClient }  from 'react-apollo';

const useApolloSubscription = (query, options, onError)=> {
    
    const client = useApolloClient();
    let subscription = null;
    const subscribe = ()=> {
        const { variables, onSubscriptionData } = options;        
        console.log(`subscribing: ${JSON.stringify(variables)}`, new Date());
        const querySubscription = client.subscribe({
            query,
            variables: {
                ...variables,
            },
        }); 
        
        
        if(subscription) subscription.unsubscribe();
        
        subscription = querySubscription.subscribe(data=>{
            onSubscriptionData &&  onSubscriptionData({client, subscriptionData: data});
        },
         error=>{
             // eslint-disable-next-line no-console
             console.error(error);
             onError && onError();
             // subscribe();
         });
    }

    const unsubscribe = ()=>{
        if(subscription) {
            subscription.unsubscribe();
        }
    }

    const updateConnectionStatus = (event)=> {
        if(event.type === 'online') {
            console.log('Online: reconnecting');
            subscription && subscribe();
        } else {
            unsubscribe();
        }
    }

    useEffect(()=>{
        window.addEventListener('online',  updateConnectionStatus);
        window.addEventListener('offline',  updateConnectionStatus);
        return ()=>{
            window.removeEventListener('online',  updateConnectionStatus);
            window.removeEventListener('offline',  updateConnectionStatus);
        }
    }, [])

    
    return [subscribe, unsubscribe];
}


export default useApolloSubscription;