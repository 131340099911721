/**
 *  Hook for creating a new master rundown.
 */

import { useMutation } from 'react-apollo';
import GET_RUNDOWNS from 'graphql/queries/getRundowns';
import CREATE_MASTER_RUNDOWN from 'graphql/mutations/createMasterRundown';
import { getRundownsQuery } from 'graphql/queryVariables';
import getTimeZone from 'utils/getTimeZone';

const useCreateMasterRundown = () => {
  const [createRundown] = useMutation(CREATE_MASTER_RUNDOWN);
  let rundown;

  const create = async (rundownTitle, userId) => {
    await createRundown({
      variables: {
        input: {
          mUpdatedById: userId,
          mTitle: rundownTitle,
          recurrence: {
            startTime: '19:00:00', // 7PM local time
            timeZone: getTimeZone(),
            dailyExclusive: true,
          },
        },
      },
      update: (proxy, mutationResult) => {
        const { createMasterRundown } = mutationResult.data;
        try {
          const rundownList = proxy.readQuery({
            query: GET_RUNDOWNS,
            variables: getRundownsQuery(''),
          });

          rundownList.getRundowns.unshift(createMasterRundown);

          proxy.writeQuery({
            query: GET_RUNDOWNS,
            variables: getRundownsQuery(''),
            data: rundownList,
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
        rundown = createMasterRundown;
      },
    });

    return rundown;
  };

  return [create];
};

export default useCreateMasterRundown;
