import { makeStyles } from '@material-ui/styles';
import SurfaceOverlay from 'assets/images/SurfaceOverlay.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    backgroundImage: `url(${SurfaceOverlay})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left top',
    backgroundSize: 'cover',
    height: 168,
    position: 'relative',
    overflow: 'hidden',
  },
  avatar: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectPosition: '0% 25%',
    filter: 'blur(15px)',
    opacity: 0.5,
  },
}));

export default useStyles;
