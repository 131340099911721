import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.dina.surfaceCard,
    borderRadius: '8px',
    backdropFilter: 'blur(30px)',
    width: '256px',
    padding: '8px 0',
    maxHeight: '85vh',
    overflow: 'auto',
  },
  primary: {
    ...theme.typography.dina.listItemLabel,
    marginLeft: '20px',
  },
  listItem: {
    height: '40px',
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
    '&:hover $secondaryAction': {
      opacity: 1,
    },
  },
  secondaryAction: {
    opacity: 0,
    cursor: 'pointer',
  },
  divider: {
    margin: '4px 8px',
  },
  add: {
    margin: '0 5px',
  },
  popover: {
    margin: '-8px 0',
  },
}));

export default useStyles;
