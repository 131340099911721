import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import RundownIcon from 'components/rundownIcons';
import { Typography, IconButton } from '@material-ui/core';
import close from 'assets/icons/systemicons/close_small.svg';
import drag from 'assets/icons/systemicons/drag.svg';
import { mediaTypes } from 'utils/rundownItemTypes';
import useStyles from './thumbGraphic-styles';

const ThumbGraphic = props => {
  const { data, title, onClose } = props;
  const classes = useStyles();
  const [, dragRef] = useDrag({
    item: {
      type: mediaTypes.GRAPHICS,
      payload: data,
    },
  });
  return (
    <div className={classes.container} ref={dragRef}>
      <div className={classes.rundownIcon}>
        <RundownIcon variant="overlayGraphics" isNormalSize noBackground />
      </div>
      <Typography
        className={classes.title}
        classes={{ root: classes.titleText }}
      >
        {title}
      </Typography>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <img src={close} alt="close" className={classes.closeImage} />
      </IconButton>
      <IconButton className={classes.dragButton}>
        <img src={drag} alt="drag" className={classes.dragImage} />
      </IconButton>
    </div>
  );
};

ThumbGraphic.propTypes = {
  /** Title of the graphic */
  title: PropTypes.string,
  /** Callback to be invoked on close button click */
  onClose: PropTypes.func,
};

ThumbGraphic.defaultProps = {
  title: '',
  onClose: () => {},
};

export default ThumbGraphic;
