import React from 'react';
import PropTypes from 'prop-types';
import Menu from 'components/menu/Menu';
import MenuItem from 'components/menu/menuItem/MenuItem';

const ActionMenuView = ({
  menuItems,
  anchorEl,
  closeMenu,
  onMenuItemClick,
  position,
}) => {
  return (
    menuItems.length > 0 && (
      <Menu type="createView" {...{ anchorEl, position }} onClose={closeMenu}>
        {menuItems.map(({ action, label, icon }) => {
          return (
            <MenuItem
              key={label}
              {...{ label }}
              onClose={() => {
                closeMenu();
                onMenuItemClick(action);
              }}
            >
              {icon}
            </MenuItem>
          );
        })}
      </Menu>
    )
  );
};

ActionMenuView.propTypes = {
  /** Items to be shown as popover options on ellipsis click */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      action: PropTypes.string,
    })
  ),
  /** Node for the popover to attach to */
  anchorEl: PropTypes.instanceOf(Element),
  /** Hides the popover */
  closeMenu: PropTypes.func,
  /** Callback to invoked on menu item click,
   * with the action type passed in
   */
  onMenuItemClick: PropTypes.func,
  /** Defines which side of the anchor the popover should show up */
  position: PropTypes.oneOf(['top', 'right', 'right-top', 'left-center']),
};

ActionMenuView.defaultProps = {
  menuItems: [],
  anchorEl: null,
  closeMenu: () => {},
  onMenuItemClick: () => {},
  position: 'right-top',
};

export default ActionMenuView;
