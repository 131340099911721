import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListSubheader, List } from '@material-ui/core';
import { take, drop } from 'lodash';
import EllipsisButton from 'components/buttons/ellipsisButton';
import Divider from 'components/divider';
import Popover from 'components/popover';
import { Notification } from 'components/statusIndicators';
import RightMetadataOffSrc from 'assets/icons/systemicons/right_metadata_off.svg';
import PrimaryFloatSrc from 'assets/icons/systemicons/primary_float.svg';
import OpenSrc from 'assets/icons/systemicons/open.svg';
import MinusSrc from 'assets/icons/systemicons/minus.svg';
import PrintSrc from 'assets/icons/systemicons/print.svg';
import DownloadSrc from 'assets/icons/systemicons/download.svg';
import History from 'assets/icons/systemicons/time.svg';
import Duplicate from 'assets/icons/systemicons/duplicate.svg';
import Delete from 'assets/icons/systemicons/delete.svg';
import Notes from 'assets/icons/systemicons/notes.svg';
import FolderMore from 'assets/icons/systemicons/folder_more.svg';
import FolderAdd from 'assets/icons/systemicons/folder_add.svg';
import PadlockSrc from 'assets/icons/systemicons/padlock_off.svg';
import InstanceVersionHistory from 'components/instanceVersionHistory';
import TemplateSubMenu from './components/templatesSubMenu';
import FoldersSubMenu from './components/foldersSubMenu';
import CreateNewFolder from './components/createNew';
import DeleteInstance from './components/deleteInstance';
import MenuItem from '../menuItem';
import useStyles from './linear-ellipsis-menu-styles';

const LinearEllipsisMenuView = props => {
  const {
    metadataCount,
    onSaveTemplate,
    onSelectTemplate,
    onDeleteTemplate,
    onCreateFolder,
    onDeleteFolder,
    onMetadataSelect,
    onCreateDuplicate,
    onFloatInstance,
    onOpenStory,
    onRemove,
    onDownload,
    onForceUnlock,
    notes,
    folders,
    showMetadata,
    hideTemplateOptions,
    hideMoreOptions,
    writeLock,
    lockedByUser,
    canCreateNewTemplate,
    canDeleteTemplate,
    canDeleteTemplateFolder,
    canSeeVersionHistory,
    onDeleteInstance,
    isDeleteEnalbled,
    disableEdit,
    title,
    versions,
    currentVersionContent,
    refetchVersionList, 
    refetchVersionContent,
    onRestoreVersion,
    checkVersionRestorability,
    isSavingContent,
    auditListLoading,
    versionContentLoading,
  } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [popoverComponent, setPopoverComponent] = useState(null);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [versionRestoreDisabled, setVersionRestoreDisabled] = useState(false);

  const sortedFolders = folders.sort((a, b) =>
    a.mTitle.localeCompare(b.mTitle, undefined, { numeric: true }),
  );
  const firstThreeFolders = take(sortedFolders, 3);
  const remainingFolders = drop(sortedFolders, 3);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleTemplateSelect = template => {
    writeLock && onSelectTemplate(template);
    handleClosePopover();
    closeMenu();
  };

  const handleSaveTemplate = (folderId, title, overwriteData) => {
    onSaveTemplate(folderId, title, overwriteData);
    handleClosePopover();
    closeMenu();
  };

  const handleDeleteTemplate = (mId, mRefId, mContentKey) => {
    onDeleteTemplate(mId, mRefId, mContentKey);
    handleClosePopover();
  };

  const handleMetadataClick = () => {
    closeMenu();
    onMetadataSelect();
  };

  const handleFloatInstance = () => {
    closeMenu();
    onFloatInstance();
  };

  const handleOpenStory = () => {
    closeMenu();
    onOpenStory();
  };

  const handlePrintInstance = () => {
    closeMenu();
    onDownload('application/pdf');
  };
  const handleDownloadInstance = () => {
    closeMenu();
    onDownload('application/xml');
  };

  const handleForceUnlock = () => {
    closeMenu();
    onForceUnlock();
  };

  const handleRemove = () => {
    closeMenu();
    onRemove();
  };

  const handleHistory = async () => {
    refetchVersionList();
    setHistoryDialogOpen(true);
  };

  const handleRestoreVersion = async (content) => {
    await onRestoreVersion(content);
    setHistoryDialogOpen(false);
    setAnchorEl(null);
  }

  const handleDelete = () => {
    handleClosePopover();
    closeMenu();
    onDeleteInstance();
  };
  const OpenDelete = () => {
    setAnchor(anchorEl);
    setPopoverComponent(
      <DeleteInstance
        onCancel={handleClosePopover}
        onOk={handleDelete}
        isDeleteEnalbled={isDeleteEnalbled}
      />,
    );
  };
  const handleOpenNotes = () => {};
  const handleClosePopover = () => {
    setAnchor(null);
    setPopoverComponent(null);
  };

  const handleDuplicate = () => {
    onCreateDuplicate();
    closeMenu();
  };
  const handleOpenPopover = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    handleClosePopover();
  };

  const handleCreateFolder = (title, description) => {
    onCreateFolder(title);
    handleClosePopover();
  };

  const handleDeleteFolder = (mId, mRefId) => {
    onDeleteFolder(mId, mRefId);
    handleClosePopover();
  };

  const moreOptions = [
    {
      src: History,
      label: 'History',
      callback: handleHistory,
      disabled: !canSeeVersionHistory,
    },
    {
      src: Duplicate,
      label: 'Duplicate Instance',
      callback: handleDuplicate,
      disabled: disableEdit,
    },
    {
      src: MinusSrc,
      label: 'Remove from Rundown',
      callback: handleRemove,
      disabled: true,
    },
    {
      src: Delete,
      label: 'Delete Instance',
      callback: OpenDelete,
      disabled: disableEdit,
    },
    {
      src: PrintSrc,
      label: 'Print Instance',
      callback: handlePrintInstance,
      disabled: false,
    },
    {
      src: DownloadSrc,
      label: 'Download Instance',
      callback: handleDownloadInstance,
      disabled: false,
    },
    {
      src: PadlockSrc,
      label: 'Force unlock',
      callback: handleForceUnlock,
      disabled: disableEdit,
    },
  ];

  const MoreOptionsMenuList = () => {
    return (
      <List disablePadding classes={{ root: classes.menuItem }}>
        <ListSubheader classes={{ root: classes.listSubheader }}>MORE OPTIONS</ListSubheader>

        {moreOptions.map(({ src, label, callback, disabled }) => (
          <MenuItem
            key={label}
            image={src}
            label={label}
            onClick={callback}
            showSecondaryItem={false}
            disabled={disabled}
          />
        ))}
      </List>
    );
  };

  const general = [
    {
      src: PrimaryFloatSrc,
      label: 'Float Instance',
      callback: handleFloatInstance,
      disabled: true,
    },
    {
      src: OpenSrc,
      label: 'Open Story',
      callback: handleOpenStory,
      disabled: false,
    },
  ];

  const GeneralMenuList = () => {
    return (
      <List disablePadding classes={{ root: classes.menuItem }}>
        <ListSubheader classes={{ root: classes.listSubheader }}>GENERAL</ListSubheader>

        <MenuItem
          label="Metadata"
          image={RightMetadataOffSrc}
          onClick={handleMetadataClick}
          disabled={showMetadata}
          secondaryItem={
            <Notification notificationCount={metadataCount > 0 ? metadataCount : null} />
          }
        />

        <MenuItem label="Notes" onClick={handleOpenNotes} image={Notes} data={notes} disabled />
        {general.map(({ src, label, callback, disabled }) => (
          <MenuItem
            key={label}
            label={label}
            image={src}
            showSecondaryItem={false}
            onClick={callback}
            disabled={disabled}
          />
        ))}
      </List>
    );
  };

  const FoldersMenuList = () => {
    return (
      <List disablePadding classes={{ root: classes.menuItem }}>
        <ListSubheader classes={{ root: classes.listSubheader }}>INSTANCE TEMPLATES</ListSubheader>
        {firstThreeFolders.length > 0 &&
          firstThreeFolders.map(({ mId, mRefId, mTitle, items }) => (
            <MenuItem
              key={mRefId}
              label={mTitle}
              data={items}
              showDeleteButton={canDeleteTemplateFolder}
              anchorEl={anchorEl}
              isMuted={!writeLock}
              onDeleteButtonClick={() => {
                onDeleteFolder(mId, mRefId);
              }}
              onClick={() => {
                setAnchor(anchorEl);
                setPopoverComponent(
                  <TemplateSubMenu
                    folderId={mRefId}
                    templates={items}
                    anchorEl={anchorEl}
                    onTemplateSelect={handleTemplateSelect}
                    onTemplateSave={handleSaveTemplate}
                    onDeleteTemplate={handleDeleteTemplate}
                    disabled={!writeLock}
                    canCreateNewTemplate={canCreateNewTemplate}
                    canDeleteTemplate={canDeleteTemplate}
                  />,
                );
              }}
            />
          ))}
        {remainingFolders.length > 0 && (
          <MenuItem
            data={remainingFolders}
            label="More Template Folders"
            image={FolderMore}
            onClick={() => {
              setAnchor(anchorEl);
              setPopoverComponent(
                <FoldersSubMenu
                  folders={remainingFolders}
                  anchorEl={anchorEl}
                  onTemplateSelect={handleTemplateSelect}
                  onTemplateSave={handleSaveTemplate}
                  onDeleteTemplate={handleDeleteTemplate}
                  onDeleteFolder={handleDeleteFolder}
                  disableChildren={!writeLock}
                  canCreateNewTemplate={canCreateNewTemplate}
                  canDeleteTemplate={canDeleteTemplate}
                  canDeleteTemplateFolder={canDeleteTemplateFolder}
                />,
              );
            }}
          />
        )}
        {canCreateNewTemplate && (
          <MenuItem
            label="Create New Template Folder"
            image={FolderAdd}
            onClick={() => {
              setAnchor(anchorEl);
              setPopoverComponent(
                <CreateNewFolder
                  onCancel={handleClosePopover}
                  onOk={handleCreateFolder}
                  data={folders}
                />,
              );
            }}
            showSecondaryItem={false}
          />
        )}
        <Popover
          anchorEl={anchor}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleClosePopover}
        >
          <div className={classes.popover}>{popoverComponent}</div>
        </Popover>
      </List>
    );
  };

  return (
    <div>
      {historyDialogOpen && (
        <InstanceVersionHistory
         versions={versions}
         getSpecificVersionContent={refetchVersionContent}
         content={currentVersionContent} 
         title={title} 
         open={historyDialogOpen} 
         onCancel={() => setHistoryDialogOpen(false)} 
         onOk={handleRestoreVersion}
         checkVersionRestorability={checkVersionRestorability}
         versionRestoreDisabled={versionRestoreDisabled}
         lockedByUser={lockedByUser}
         isSavingContent={isSavingContent}
         auditListLoading={auditListLoading}
         versionContentLoading={versionContentLoading}
        />
      )}
      <div className={classes.container}>
      <EllipsisButton onClick={handleOpenPopover} />

      <Popover
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.menu}>
          <GeneralMenuList />
          {!hideTemplateOptions && (
            <>
              <Divider className={classes.divider} />
              <FoldersMenuList />
            </>
          )}
          {!hideMoreOptions && (
            <>
              <Divider className={classes.divider} />
              <MoreOptionsMenuList />
            </>
          )}
        </div>
      </Popover>
    </div>
    </div>
    
  );
};

LinearEllipsisMenuView.propTypes = {
  /** Reference to the container element */
  containerRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  /** Instance templates to show */
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),
  /** Callback to be invoked on template selection,
   * with the selected template passed in
   */
  onSelectTemplate: PropTypes.func,
  /** Callback to be invoked when save as template option is clicked,
   * with the templateName passed in
   */
  onSaveTemplate: PropTypes.func,
  /** Callback to be invoked on template deletion,
   * with the template to be deleted passed in
   */
  onDeleteTemplate: PropTypes.func,
  /** Metadata count to show on status notification indicator */
  metadataCount: PropTypes.number,
  /** Callback to be invoked when metadata option is clicked */
  onMetadataSelect: PropTypes.func,
  /** Callback to be invoked when float instance option is clicked */
  onFloatInstance: PropTypes.func,
  /** Callback to be invoked when open story option is clicked */
  onOpenStory: PropTypes.func,
  /** Callback to be invoked when remove from rundown is clicked */
  onRemove: PropTypes.func,
  /** List of notes  */
  notes: PropTypes.arrayOf(PropTypes.any),
  /** List of template folders */
  folders: PropTypes.arrayOf(PropTypes.any),
  /** Callback to be invoked while creating new folder */
  onCreateFolder: PropTypes.func,
  /** Callback to be invoked while deleting a folder */
  onDeleteFolder: PropTypes.func,
  /** Callback to be invoked download of an instance */
  onDownload: PropTypes.func,
  /** Whether to hide template options or not */
  hideTemplateOptions: PropTypes.bool,
  /** Whether to hide more options or not */
  hideMoreOptions: PropTypes.bool,
  /** Boolean that indicates that user can change content */
  writeLock: PropTypes.bool,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  /** boolean that hides delete template from menu */
  canDeleteTemplate: PropTypes.bool,
  /** boolean that hides delete template folder from menu */
  canDeleteTemplateFolder: PropTypes.bool,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
};

LinearEllipsisMenuView.defaultProps = {
  containerRef: { current: null },
  templates: [],
  onSelectTemplate: template => {},
  onSaveTemplate: templateName => {},
  onDeleteTemplate: template => {},
  metadataCount: null,
  onMetadataSelect: () => {},
  onFloatInstance: () => {},
  onOpenStory: () => {},
  onRemove: () => {},
  notes: [],
  folders: [],
  onCreateFolder: () => {},
  onDeleteFolder: () => {},
  onDownload: () => {},
  hideTemplateOptions: false,
  hideMoreOptions: false,
  writeLock: false,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canDeleteTemplateFolder: false,
  disableEdit: false,
};

export default LinearEllipsisMenuView;
