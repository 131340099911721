import React, { useMemo, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import Storybox from 'screens/storybox';
import { Typography } from '@material-ui/core';
import memberTypes from 'graphql/memberTypes';
import { SidebarSubTabContext } from 'globalState/sidebarSubTab';
import { useScrollIntoView } from 'contexts/ScrollIntoViewContext';
import Scrollbar from 'screens/main/components/scrollbar';
import useOpenStory from 'hooks/useOpenStory';
import Tabs from 'components/tabs';
import Divider from 'components/divider';
import Searchbar from 'components/searchbar';
import DatePickerButton from './datePickerButton';
import useStyles from './stories-styles';
import ListGroup from './listGroup';

const Stories = ({ groups, selectionType, onDateChanged, selectedDates }) => {
  const classes = useStyles();
  const [showStorybox, setShowStorybox] = useState(null);
  const [{ subTabs, selectedSubTab, setSelectedSubTab, scheduleType }] = useContext(
    SidebarSubTabContext,
  );
  const [, setCurrentClicked] = useScrollIntoView();
  const scrollbarRef = useRef(null);

  const onTabChange = (value, index) => {
    setSelectedSubTab(index);
  };
  const [filters, setFilters] = useState([]);

  const openStory = useOpenStory();
  const headers = {
    user: 'My Stories',
    rnd_usr: 'Rundowns',
    usr_bkm: 'Bookmarks',
    tea_usr: 'Team Stories',
    dep_usr: 'Department Stories',
    story: 'All Stories',
    pitch: 'All Pitches',
  };

  const sidebarHeader = (
    <div className={classes.sidebarHeader}>
      <Typography className={classes.sidebarHeaderText}>{headers[selectionType]}</Typography>
    </div>
  );

  const memoizedDateTimePicker = useMemo(
    () => <DatePickerButton onSelectedDateChange={onDateChanged} />,
    [onDateChanged],
  );

  const groupsCount = groups ? groups.length : 0;
  const groupIds = [];
  if (groups && groups.length > 0) {
    groups.forEach(group => {
      groupIds.push(group.mId);
    });
  }

  const onBeforeRefetchStories = () => {
    const itemHeight = 56;
    const additionalArea = 102;
    const offset = additionalArea + 3 * itemHeight;
    return scrollbarRef.current.getScrollTop() + scrollbarRef.current.getClientHeight() - offset;
  };

  const onAfterRefetchStories = scrollTopPosition => {
    scrollbarRef.current.scrollTop(scrollTopPosition);
  };

  const tabs = subTabs
    .filter(subTab => !(subTab === 'archived' && selectionType === memberTypes.PITCH))
    .map(subTab => ({
      label: subTab,
    }));

  const tabIndex = selectedSubTab > 2 && selectionType === memberTypes.PITCH ? 0 : selectedSubTab;

  const sidebarContent = (
    <div className={classes.sidebarContent}>
      <Scrollbar ref={scrollbarRef}>
        <div>
          {groups.map((group, index) =>
            group ? (
              <ListGroup
                groupIds={groupIds}
                key={group.mId}
                title={group.mTitle}
                mId={group.mId}
                type={group.mType}
                onBeforeRefetch={onBeforeRefetchStories}
                onAfterRefetch={onAfterRefetchStories}
                {...{
                  setShowStorybox,
                  groupsCount,
                  index,
                  selectedDates,
                  openStory,
                  subTabs,
                  selectedSubTab,
                  scheduleType,
                  filters,
                }}
              />
            ) : (
              <div style={{ background: 'red', color: 'white' }}>Group failure</div>
            ),
          )}
        </div>
      </Scrollbar>
      <div className={classes.filterBar}>
        <Searchbar chips={filters} chipChangeHandler={chips => setFilters(chips)} />
      </div>
    </div>
  );

  const handleOpenStory = () => {
    openStory(showStorybox.title, showStorybox.id, showStorybox.page);
  };

  const onInstanceClick = async (sId, instanceId) => {
    await openStory(showStorybox.title, showStorybox.id, showStorybox.page).then(() => {
      setCurrentClicked(instanceId);
    });
  };

  return (
    <div className={classes.root}>
      {!showStorybox ? (
        <>
          {sidebarHeader}
          {(selectionType === memberTypes.STORY || selectionType === memberTypes.PITCH) && (
            <>
              <Tabs
                variant="default"
                tabWidth={selectionType === memberTypes.PITCH ? 'fullWidth' : 'scrollable'}
                {...{ tabs, tabIndex, onTabChange }}
              />
              <div className={classes.divider}>
                <Divider />
              </div>
            </>
          )}
          {memoizedDateTimePicker}
          {sidebarContent}
        </>
      ) : (
        <Storybox
          onOpenStory={handleOpenStory}
          onBackClick={() => setShowStorybox(null)}
          storySpec={showStorybox}
          onInstanceClick={onInstanceClick}
        />
      )}
    </div>
  );
};

Stories.propTypes = {
  /** list group */
  groups: PropTypes.arrayOf(PropTypes.object),
  /** selection type */
  selectionType: PropTypes.string,
  /** on date change function */
  onDateChanged: PropTypes.func,
};

Stories.defaultProps = {
  groups: [],
  selectionType: '',
  onDateChanged: () => {},
};

export default Stories;
