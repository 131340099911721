import React, { useState } from 'react';
import AddItem from 'screens/main/components/rightArea/sidebar/teams/listItem/addItem/addItem-view';
import Dialog from 'components/dialog';
import { makeStyles } from '@material-ui/styles';
import AddMember from 'components/addMemberDialog';
import DetailList from '../../../../detailList';

const useStyles = makeStyles(theme => ({
  text: {
    ...theme.typography.dina.overline,
    paddingTop: '8px',
    marginLeft: '16px',
  },
  backdropRoot: {
    position: 'absolute',
  },
  divider: {
    paddingLeft: '72px',
  },
}));

const Departments = ({ teamDepartments, addDepartment, teamTitle, containerRef, ...rest }) => {
  const classes = useStyles();
  const teamDepartmentsExist = teamDepartments.length > 0;
  const [openDepartment, setOpenDepartment] = useState(false);
  const addTeamToDepartments = members => {
    addDepartment(members);
    setOpenDepartment(false);
  };
  return (
    <div>
      {teamDepartmentsExist && (
        <DetailList
          listTitle="DEPARTMENTS"
          members={teamDepartments}
          avatarVariant="Department"
          {...rest}
        />
      )}

      <AddItem title="Departments..." onClick={() => setOpenDepartment(!openDepartment)} />
      <Dialog
        open={openDepartment}
        container={containerRef.current}
        BackdropProps={{ classes: { root: classes.backdropRoot } }}
        onClose={() => setOpenDepartment(false)}
      >
        <AddMember
          members={teamDepartments}
          variant="Department"
          dialogTitle="Departments"
          onCancel={() => setOpenDepartment(false)}
          onOk={addTeamToDepartments}
        />
      </Dialog>
    </div>
  );
};

export default Departments;
