import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  backdropRoot: {
    position: 'absolute',
  },
  container: {
    width: '80vw',
    height: '93vh',
    backgroundColor: theme.palette.dina.surfaceCard,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  header: {
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    flexShrink: 0,
  },
  title: {
    ...theme.typography.dina.h5,
    lineHeight: '28px',
    display: '-webkit-box',
    lineClamp: 2,
    '-webkit-box-orient': 'vertical',
    '-webkit-box-align': 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    '&:hover': {
      '& path': {
        fill: theme.palette.dina.highEmphasis,
        fillOpacity: 1,
        transition: '0.3s ease',
      },
    },
  },
  iconEmphasis: {
    '& path': {
      fill: theme.palette.dina.highEmphasis,
      fillOpacity: 1,
      transition: '0.3s ease',
    },
  },
  tabs: {
    width: '100%',
    height: '48px',
  },
  iFrameContainer: {
    width: '100%',
    backgroundColor: theme.palette.dina.statusOnPress,
    overflow: 'auto',
    maxHeight: 'calc( 100% - 173px )',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  iFrame0: {
    width: '375px',
    height: '740px',
    padding: '20px',
  },
  iFrame1: {
    width: '723px',
    height: '375px',
  },
  iFrame2: {
    width: '1024px',
    height: '768px',
    padding: '25px',
  },
  iFrame3: {
    width: '100%',
    height: '100%',
  },
  iFrame: {
    width: '100%',
    height: '100%',
    border: 'none',
    backgroundColor: ({ hasPreview }) =>
      theme.palette.dina[hasPreview ? 'highEmphasis' : 'statusOnPress'],
  },
  footer: {
    width: '100%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '8px',
    flexShrink: 0,
  },
  button: {
    ...theme.typography.dina.button,
    color: theme.palette.dina.highEmphasis,
    backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
    width: '192px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      '-webkit-filter': theme.palette.dina.onHover,
    },
  },
}));

export default useStyles;
