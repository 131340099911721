import { useMutation } from 'react-apollo';
import ARCHIVE_MEMBER from 'graphql/mutations/archiveMember';
import GET_TEAMS from 'graphql/queries/getTeams';
import GET_DEPARTMENTS from 'graphql/queries/getDepartments';
import GET_RUNDOWNS from 'graphql/queries/getRundowns';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';

import memberTypes from 'graphql/memberTypes';
import { getMembersOfTypeQuery, getMembersOfQuery } from 'graphql/queryVariables';

const useArchiveMember = () => {
  const [archiveMemberMutation] = useMutation(ARCHIVE_MEMBER);

  const archiveMember = async (mId, memberType = memberTypes.TEAM, rundownId = null) => {
    const input = {
      mId,
    };
    if (rundownId) {
      input.rundownId = rundownId;
    }

    let memberQuery = GET_TEAMS;
    let memberTypeOf = memberTypes.TEAM_USER;

    switch (memberType) {
      case memberTypes.DEPARTMENT:
        memberTypeOf = memberTypes.DEPARTMENT_USER;
        memberQuery = GET_DEPARTMENTS;
        break;
      case memberTypes.TEAM:
        memberTypeOf = memberTypes.TEAM_USER;
        memberQuery = GET_TEAMS;
        break;

      default:
        memberQuery = GET_TEAMS;
        memberTypeOf = memberTypes.TEAM_USER;
    }

    await archiveMemberMutation({
      variables: { input },
      update: (proxy, mutationResult) => {
        const { mUpdatedById } = mutationResult.data.archiveMember;

        try {
          const member = proxy.readQuery({
            query: memberQuery,
            variables: getMembersOfTypeQuery(memberType),
          });

          const newTeams = member.getMembersOftype.filter(team => team.mId !== mId);

          proxy.writeQuery({
            query: memberQuery,
            variables: getMembersOfTypeQuery(memberType),
            data: { getMembersOftype: newTeams },
          });

          const myMembers = proxy.readQuery({
            query: GET_MEMBERS_OF,
            variables: getMembersOfQuery(mUpdatedById, memberTypeOf),
          });

          const wasMyMember = myMembers.getMembersOf.filter(team => team.mId !== mId);

          proxy.writeQuery({
            query: getMembersOfQuery,
            variables: getMembersOfQuery(mUpdatedById, memberTypeOf),
            data: { getMembersOf: wasMyMember },
          });
        } catch (error) {
          // console.log(error);
        }
      },
    });
  };

  const archiveMasterRundown = async mId => {
    let input = {
      mId,
    };

    await archiveMemberMutation({
      variables: { input },
      update: (proxy, mutationResult) => {
        try {
          input = { mPublishingAt: '', mType: 'rundown' };
          const myRundowns = proxy.readQuery({
            query: GET_RUNDOWNS,
            variables: input,
          });

          const wasRundown = myRundowns.getRundowns.filter(rundown => rundown.mId !== mId);

          proxy.writeQuery({
            query: GET_RUNDOWNS,
            variables: input,
            data: { getRundowns: wasRundown },
          });
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  return [archiveMember, archiveMasterRundown];
};

export default useArchiveMember;
