import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '90px',
    height: '16px',
    margin: '4px 0 2px 0',
  },
  input: {
    ...theme.typography.dina.teamInfoType,
    display: 'flex',
    alignItems: 'flex-end',
  },
  plannedNormal: {
    ...theme.typography.dina.teamInfoType,
  },
  timePlanned: {
    width: '120px',
  },
  planned: {
    ...theme.typography.dina.planned,
  },
  rootDiv: {
    height: '46px',
    display: 'flex',
    backgroundColor: theme.palette.dina.surfaceDialogs,
    borderTop:`1px solid ${theme.palette.dina.dividerDark}`,
    borderBottom:`1px solid ${theme.palette.dina.dividerDark}`,
  },
  durationDiv: {
    width: '96px',
  },
  onAirDiv: {
    width: '181px',
    height: '100%',
    marginLeft: '30px',
    display: 'flex',
    alignItems: 'center',
  },
  textRoot: {
    ...theme.typography.dina.onAir,
  },
  durationLabel: {
    ...theme.typography.dina.planned,
  },
  duration: {
    ...theme.typography.dina.teamInfoType,
  },
}));

export default useStyles;
