import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const dudUrl = 'javascript';

const useStyles = makeStyles(theme => ({
  signUp: {
    position: 'absolute',
    right: '24px',
    top: '24px',
  },
  textSignUp: {
    fontSize: '14px',
    color: theme.palette.dina.mediumEmphasis,
  },
  buttonSignUp: {
    backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
    color: theme.palette.dina.mediumEmphasis,
    display: 'inline-block',
    width: '100%',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
      color: theme.palette.dina.highEmphasis,
    },
  },
}));

const SignUp = () => {
  const classes = useStyles();
  return (
    <div className={classes.signUp} id="divSignUp">
      <Grid
        container
        spacing={2}
        alignItems="center"
        direction="row"
        justify="center"
      >
        <Grid item>
          <Typography href={dudUrl} className={classes.textSignUp}>
            Don&apos;t have an account?
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" className={classes.buttonSignUp}>
            SIGN UP
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUp;
