import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import DinaIcon from 'assets/images/DinaIcon.svg';

const useStyles = makeStyles(theme => ({
  dina: {
    position: 'absolute',
    left: '56px',
    top: '32px',
    textAlign: 'left',
    '@media (max-width:830px)': {
      // eslint-disable-line no-useless-computed-key
      left: '25px',
    },
  },

  textLogo: {
    color: theme.palette.dina.mediumEmphasis,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.4px',
  },
}));

const Logo = () => {
  const classes = useStyles();

  return (
    <div className={classes.dina} data-testid="logo">
      <img src={DinaIcon} alt="Dina Icon" />
      <Typography className={classes.textLogo}>
        Story production the smart way.
      </Typography>
    </div>
  );
};

export default Logo;
