import React from 'react';
import PropTypes from 'prop-types';
import { format, isToday } from 'date-fns';
import useStyles from './DayStyles';

const Day = ({ day, className, isSelected, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={`day ${classes.root} ${className} ${
        isToday(day) ? 'today' : ''
      } ${isSelected ? 'selected-day' : ''}`}
      {...rest}
    >
      <div>{format(day, 'dd')}</div>
      <div>{format(day, 'D')}</div>
    </div>
  );
};

Day.propTypes = {
  day: PropTypes.instanceOf(Date).isRequired,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
};

Day.defaultProps = {
  className: '',
  isSelected: false,
};

export default Day;
