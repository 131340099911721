import React from 'react';
import Icon from 'components/icon';
import ICONS from 'assets/icons/icons';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  inspector: {
    marginBottom: '18px',
    marginTop: '16px',
    borderRadius: '8px',
    backgroundColor: theme.palette.dina.surfaceCard,
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    minWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    ...theme.typography.dina.body1,
    marginTop: '8px',
    color: theme.palette.dina.mediumEmphasis,
  },
}));

const InspectorEmptyState = () => {
  const classes = useStyles();

  return (
    <div className={classes.inspector}>
      <Icon icon={ICONS.FEEDS_OFF} width={64} height={64} />
      <Typography className={classes.label}>No Feed Item selected</Typography>
    </div>
  );
};

export default InspectorEmptyState;
