import { useMutation } from 'react-apollo';
import UPDATE_INSTANCE from 'graphql/mutations/updateInstance';
import { filterEditorMeta } from 'screens/rundown/components/editor/utils';

const useUpdateInstanceMeta = () => {
  const [updateInstanceMutation] = useMutation(UPDATE_INSTANCE);
  const updateMeta = (instanceId, content) => {
    const items = filterEditorMeta(content.document);

    const input = {
      mId: instanceId,
      items,
    };

    updateInstanceMutation({
      variables: {
        input,
      },
    });
  };

  return [updateMeta];
};

export default useUpdateInstanceMeta;
