import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    width: '100%',
  },
  box: {
    position: 'relative',
    border: `1px dashed ${palette.dina.onSelected}`,
    borderRadius: 4,
    width: '100%',
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s ease',

    '&:hover': {
      backgroundColor: palette.dina.whiteHoverOverlay,
    },
  },
  children: { height: 0 },
  icon: {
    position: 'absolute',
    top: -1,
    left: -1,
    borderRadius: '4px 0px 0px 4px',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: palette.dina.onSelected,
    '& svg': { height: 16 },
    '& path': {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      fill: '#516379',
      fillOpacity: 1,
    },
  },
  title: {
    paddingLeft: 44,
  },
  iconComponent: {
    width: '20px',
    height: '20px',
    margin: '10px',
    '& path': {
      fill: palette.dina.blackMediumEmphasis,
      fillOpacity: 1,
    },
  },
  thumbnailText: {
    ...typography.dina.overline,
    fontWeight: '500',
    lineHeight: '12px',
    letterSpacing: '1px',
  },
}));

export default useStyles;
