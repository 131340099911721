import React, { memo } from 'react';
import { elementTypes } from 'components/editor/constants';
import Wrapper from '../wrapper';
import IconButton from '../iconButton';

const { GIF, IMAGE, VIDEO } = elementTypes;

const TwitterToolbar = () => {
  return (
    <>
      <Wrapper showRightMargin>
        <IconButton type={VIDEO} data={{ showThumbnail: true }} />
        <IconButton type={IMAGE} />
        <IconButton type={GIF} />
      </Wrapper>
    </>
  );
};

export default memo(TwitterToolbar);
