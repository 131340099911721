import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { axisTop, select, timeMinute } from 'd3';
import formatTicks from './formatTicks';
import useStyles from './timeGrid-styles';

const TimeGrid = ({
  xScaleRef,
  margin,
  height,
  topGutterHeight,
  storyCardWidth,
}) => {
  const classes = useStyles();
  const groupRef = useRef(null);
  const xAxisRef = useRef(null);
  const [translateX, setTranslateX] = useState(storyCardWidth + margin);

  const init = () => {
    if (xScaleRef.current) {
      const xScale = xScaleRef.current;
      const xAxis = axisTop(xScale);

      xAxis
        .tickArguments([timeMinute.every(30)])
        .tickSize(height - topGutterHeight)
        .tickFormat(formatTicks(xAxis));

      select(groupRef.current).call(xAxis);

      xAxisRef.current = xAxis;
    }
    setTranslateX(storyCardWidth + margin);
  };

  useEffect(init);

  return (
    <g
      ref={groupRef}
      transform={`translate(${translateX} ,${height})`}
      className={`${classes.root} axis-grid`}
    />
  );
};

TimeGrid.propTypes = {
  /** Specifies the current d3 time scale */
  xScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  margin: PropTypes.number,
  height: PropTypes.number.isRequired,
};

TimeGrid.defaultProps = {
  margin: 10,
};

export default TimeGrid;
