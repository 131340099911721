import React from 'react';
import GET_SINGLE_INSTANCE from 'graphql/queries/getSingleInstance';
import LoadingIndicator from 'components/loadingIndicator';
import { useQuery } from 'react-apollo';
import IframeView from './iframe-view';
import useStyles from './iframe-styles';

const IframeContainer = ({ instanceId, provider, height }) => {
  const classes=useStyles({height});
  const skip = Boolean(provider);

  const { data, loading } = useQuery(GET_SINGLE_INSTANCE, {
    variables: {
      input: {
        mId: instanceId,
      },
    },
    fetchPolicy: 'network-only',
    skip,
  });

  if (loading)
    return (
      <div className={classes.loader}>
        <LoadingIndicator />
      </div>
    );

  const updatedProvider = skip ? provider : data.getMember.mProperties.provider;

  return <IframeView height={height} provider={updatedProvider} />;
};

export default IframeContainer;
