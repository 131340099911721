import GET_INSTANCE from 'graphql/queries/getInstance';
import { getStoryInstancesQuery } from 'graphql/queryVariables';
import memberTypes from 'graphql/memberTypes';

const getUpdatedList = (list, item) => {
    const memberUpdated = item;
    const updatedList = list;
    if (item.crudAction === "INSERT") {
        memberUpdated.__typename = "MemberType";
        const memberExist = updatedList.filter(m => m.mId === item.mId);
        if (memberExist.length === 0) {
            updatedList.push(memberUpdated);
        }
        return updatedList;
    }
    if (item.crudAction === "REMOVE") {
        return updatedList.filter(m => m.mId !== item.mId);
    }
    return updatedList;
}

const updateCahe = (proxy, item, query, variables) => {
    try {
        const rootQuery = proxy.cache.data.data.ROOT_QUERY;
        // Query to read from cache.
        const queryInCache =
            `getMembers({"input":{"mId":"${item.storyId}","mType":"${memberTypes.STORY_INSTANCE}"}})`;
        // If query doesn't exists in cache return.
        if (rootQuery[queryInCache] === undefined) {
            return;
        }
        const list = proxy.readQuery({
            query,
            variables,
        });
        list.getMembers = getUpdatedList(list.getMembers, item);
        // Write updated member list in the cache.
        proxy.writeQuery({
            query,
            variables,
            data: list,
        });
    }

    catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
}

const updateInstanceToStory = (proxy, item) => {
    try {
        const variables = getStoryInstancesQuery(item.storyId);
        updateCahe(proxy, item, GET_INSTANCE, variables);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
}

export default updateInstanceToStory;
