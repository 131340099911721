import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useCheckUserRight from 'hooks/useCheckUserRight';
import RundownList from './list-view';

const SidebarContainer = props => {
  const { setPreviewRundown } = props;
  const [selectedDates, setSelectedDates] = useState([new Date(Date.now())]);
  const [hideMasterRundowns, setHideMasterRundowns] = useState(true);
  const [checkUserRight] = useCheckUserRight();

  useEffect(() => {
    setHideMasterRundowns(!checkUserRight('rundown', 'view-master'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDateChanged = e => {
    setSelectedDates(e);
  };

  return (
    <RundownList
      onDateChanged={onDateChanged}
      selectedDates={selectedDates}
      hideMasterRundowns={hideMasterRundowns}
      {...{ setPreviewRundown }}
    />
  );
};

SidebarContainer.propTypes = {
  /** function set the rundown that should be previewed   */
  setPreviewRundown: PropTypes.func,
};

SidebarContainer.defaultProps = {
  setPreviewRundown: () => {},
};

export default SidebarContainer;
