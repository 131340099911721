/**
 * Scrolls into view a target element with delay provided
 *
 * @param {DOMNode} target that needs to be scrolled into view
 * @param {Number} delay after which the scrolling will perform
 * @returns  Undefined
 */

const scrollAfterTimeout = (target, delay = 300) => {
  setTimeout(() => {
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }, delay);
};

export default scrollAfterTimeout;
