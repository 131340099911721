import React from 'react';
import getFormBySpec from 'utils/rundown/getFormBySpec';
import { entityTypes, specTypes } from 'utils/metadata/defaultViewTypes';
import UPDATE_MEMBER from 'graphql/mutations/updateMember';
import { useQuery, useMutation } from 'react-apollo';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import GET_ANALYTICS from 'graphql/queries/getAnalytics';
import UPDATE_CATEGORIES from 'graphql/mutations/updateStoryCategories';
import returnFieldPairs from 'utils/rundown/returnFieldPairs';
import MetadataView from './metadata-view';

const MetadataContainer = ({
  categories,
  mMetadata,
  form,
  storyId,
  analyticInput,
  updateMetadataState,
  ...rest
}) => {
  const input = analyticInput || '';
  const [updateStoryCategories] = useMutation(UPDATE_CATEGORIES);
  const [updateStory] = useMutation(UPDATE_MEMBER);

  const { data, error, loading } = useQuery(GET_ANALYTICS, {
    variables: {
      input: { mContent: input },
    },
  });

  if (error) return `Error! ${error.message}`;
  if (loading) return <LoadingIndicator />;

  const onUpdateMeta = newMetadata => {
    updateMetadataState(newMetadata);
    const variables = {
      input: {
        mId: storyId,
        mMetaData: newMetadata,
      },
    };
    updateStory({ variables });
  };

  const updateCategories = async newCategories => {
    try {
      await updateStoryCategories({
        variables: {
          input: {
            mId: storyId,
            mCategories: newCategories,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const [fields, parameterFields] = getFormBySpec(form, {
    entity: entityTypes.STORY,
    type: specTypes.METADATA,
  });

  const metadata = returnFieldPairs(mMetadata, fields);

  return (
    <MetadataView
      analytics={data.getAnalytics || []}
      categories={categories || []}
      metadata={metadata || []}
      {...{ fields, parameterFields, onUpdateMeta, updateCategories }}
      {...rest}
    />
  );
};

export default MetadataContainer;
