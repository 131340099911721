import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import getInitials from 'utils/getInitials';
import Avatar from 'components/avatar';
import InputField from 'components/inputField';
import useImageUpload from 'hooks/useImageUpload';
import useMenuAnchor from 'hooks/useMenuAnchor';
import PlaceHolder from 'assets/icons/systemicons/AddMorePeople.png';
import ActionMenu from '../../actionMenu';
import useStyles from './avatar-row-styles';
import menuItems from './menuItems';
import menuActionTypes from './menuActionTypes';

const AvatarRowView = ({
  id,
  title: initialTitle,
  subtitle,
  additionalInfo,
  avatarUrl,
  variant,
  onImageUpload,
  disableUpload,
  updateDepartmentTitle,
}) => {
  const classes = useStyles();
  const [uploadedAvatar, setUploadedAvatar] = useState(null);
  const [showInputField, setShowInputField] = useState(false);
  const [title, setTitle] = useState(initialTitle);
  const { anchorEl, openMenu, closeMenu } = useMenuAnchor();

  const onImageLoad = imageFile => {
    const imageSrc = URL.createObjectURL(imageFile);

    setUploadedAvatar(imageSrc);
    onImageUpload(imageFile);
  };

  const captureImage = useImageUpload({ onImageLoad });
  const avatarImage = uploadedAvatar || avatarUrl;

  const onMenuItemClick = action => {
    if (action === menuActionTypes.UPLOAD) captureImage();
  };

  const onTitleClick = () => {
    setShowInputField(true);
  };

  const onTitleChange = value => {
    setTitle(value);
  };

  const handleEnterKey = e => {
    if (e.keyCode === 13) {
      updateDepartmentTitle(id, title);
      setShowInputField(false);
    }
    if (e.keyCode === 27) {
      setTitle(initialTitle);
      setShowInputField(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.avatarWrapper}>
        <Avatar
          size={90}
          {...{ variant }}
          onClick={disableUpload ? null : openMenu}
          src={avatarImage}
          defaultSrc={PlaceHolder}
          uploadEnabled={!disableUpload}
        >
          {getInitials(title)}
        </Avatar>
      </div>

      <div className={classes.typographyDiv}>
        {showInputField && variant === 'Department' ? (
          <InputField
            value={title}
            autoFocus
            onChange={onTitleChange}
            onKeyDown={handleEnterKey}
          />
        ) : (
          <Typography className={classes.title} onClick={onTitleClick}>
            {title}
          </Typography>
        )}
        <div className={classes.subtitle}>{subtitle}</div>
        <div className={classes.teamInfo}>{additionalInfo}</div>
      </div>

      <ActionMenu {...{ menuItems, anchorEl, closeMenu, onMenuItemClick }} position="left-center" />
    </div>
  );
};

AvatarRowView.propTypes = {
  /** Defines the primary header text */
  title: PropTypes.string,
  /** Defines the secondary header text */
  subtitle: PropTypes.string,
  /** If true, disables the action menu for image upload */
  disableUpload: PropTypes.bool,
  /** Defines additional header components to render */
  additionalInfo: PropTypes.node,
};

AvatarRowView.defaultProps = {
  title: 'The Title',
  subtitle: 'Subtitle',
  disableUpload: false,
  additionalInfo: null,
};

export default AvatarRowView;
