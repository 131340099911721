import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { min } from 'date-fns';
import getDateFormat from './getDateFormat';
import formatTicks from './formatTicks';
import CurrentDateIndicator from './currentDateIndicator';

const CurrentDatesRow = ({ zoomTransform, newXScaleRef, margin }) => {
  const [ticks, setTicks] = useState([]);
  const [showStickyTick, setShowStickyTick] = useState(false);
  const [stickyTick, setStickyTick] = useState(null);
  const [stickyTickWidth, setStickyTickWidth] = useState(0);

  const update = () => {
    if (newXScaleRef.current) {
      const currentTicks = newXScaleRef.current.ticks();
      const firstTick = min(...currentTicks);

      setTicks(currentTicks);

      if (firstTick) {
        setStickyTick({
          date: firstTick,
          dateFormat: getDateFormat(currentTicks[0], currentTicks[1]),
        });
      }
    }
  };

  useEffect(update, [zoomTransform]);

  return (
    ticks.length > 0 && (
      <g>
        {stickyTick && showStickyTick && (
          <CurrentDateIndicator
            sticky
            {...stickyTick}
            {...{
              newXScaleRef,
              margin,
              stickyTickWidth,
              setStickyTickWidth,
              setShowStickyTick,
            }}
          />
        )}

        {formatTicks(ticks).map((tick, index) => (
          <CurrentDateIndicator
            key={tick.date}
            {...tick}
            {...{
              index,
              newXScaleRef,
              margin,
              setShowStickyTick,
              stickyTickWidth,
              setStickyTickWidth,
            }}
          />
        ))}
      </g>
    )
  );
};

CurrentDatesRow.propTypes = {
  /** Specifies the current d3 time scale zoom level */
  zoomTransform: PropTypes.shape({
    k: PropTypes.number,
  }).isRequired,
  /** Specifies the current d3 time scale */
  newXScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  /** Specifies the margin for the timeline grid */
  margin: PropTypes.number.isRequired,
};

export default CurrentDatesRow;
