import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '96px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: ({ noBorderRadius }) => (noBorderRadius ? '0px' : '8px 8px 0px 0px'),
  },
  content: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
  },
  background: {
    width: '100%',
    height: '100%',
    objectPosition: '0% 25%',
    filter: 'blur(10px)',
    opacity: '0.8',
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  backDiv: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      '& > :first-child': {
        '& path': {
          fillOpacity: 1,
        },
      },
      '& > :last-child': {
        color: theme.palette.dina.highEmphasis,
      },
    },
    '&:focus': {
      outline: 'none',
    },
  },
  backText: {
    ...theme.typography.dina.body2,
    fontWeight: 'normal',
    color: theme.palette.dina.mediumEmphasis,
  },
  storyBoxText: {
    ...theme.typography.dina.h5,
    color: theme.palette.dina.mediumEmphasis,
    letterSpacing: 'normal',
    lineHeight: '28px',
    marginLeft: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  buttonDiv: {
    height: '40px',
    display: 'flex',
  },
  button: {
    padding: '6px',
  },
  icon: {
    '&:hover': {
      '& path': {
        fillOpacity: 1,
      },
    },
  },
}));

export default useStyles;
