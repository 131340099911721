import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Avatar, { avatarVariants } from 'components/avatar/Avatar';
import { IconButton, Typography } from '@material-ui/core';
import { ReactComponent as Close } from 'assets/icons/systemicons/close_small.svg';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import memberTypes from 'graphql/memberTypes';
import Popover from 'components/popover';
import Assignees from 'components/avatarGroup/assignees';
import Preview from './preview-container';
import useStyles from './kanbanCard-styles';

const KanbanCard = props => {
  const {
    id,
    image,
    destination,
    title,
    storyId,
    publishingPlatform,
    color,
    mContentKey,
    assignees,
    openStory,
    state,
    publishingAt,
    metaData,
    description,
  } = props;
  const classes = useStyles(image, color)();
  const [openPopover, setOpenPopover] = useState(false);
  const anchorRef = useRef(null);

  const handleStoryOpen = () => {
    setOpenPopover(false);
    if (storyId && id) {
      openStory(title, storyId, memberTypes.STORY, id);
    }
  };

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  const [handleClick, handleDoubleClick] = useMouseClickEvents(handleOpenPopover, handleStoryOpen);

  const { mProperties } = publishingPlatform;
  const { platform, platformIcon } = mProperties;

  return (
    <div ref={anchorRef} className={classes.ref}>
      <div
        className={classes.container}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        role="presentation"
      >
        <Typography className={classes.title}>{title}</Typography>
        <div className={classes.detailsRow}>
          <div className={classes.detailsLeft}>
            <div className={classes.editionIcon}>
              <div className={classes.imageContainer} />
              <Avatar
                size={16}
                variant={avatarVariants.publish}
                publishingPoint={platformIcon}
                className={classes.publishingPoint}
              />
            </div>
            <Typography classes={{ root: classes.destination }}>{destination}</Typography>
          </div>
          <Assignees assignees={assignees} size={24} />
        </div>
      </div>

      <Popover
        onClose={handleClose}
        anchorEl={openPopover ? anchorRef.current : null}
        position="right"
        noMargin
      >
        <div className={classes.preview}>
          <Preview
            id={id}
            title={title}
            image={image}
            mContentKey={mContentKey}
            onOpenStory={handleStoryOpen}
            isLinear={platform === 'linear'}
            platform={platform}
            assignees={assignees}
            state={state}
            publishingPointIcon={platformIcon}
            publishingAt={publishingAt}
            destination={destination}
            metaData={metaData}
            description={description}
          />
          <IconButton className={classes.close} onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </Popover>
    </div>
  );
};

KanbanCard.propTypes = {
  /** Background image  of instance of Story */
  image: PropTypes.string,
  /** Destination of the instance */
  destination: PropTypes.string,
  /** Title of the instance */
  title: PropTypes.string,
  /** color of the kanban type */
  color: PropTypes.string,
  /** ID of the story of current instance */
  storyId: PropTypes.string,
  /** Key for the content of the instance */
  mContentKey: PropTypes.string,
  /** Callback to open story from instance */
  openStory: PropTypes.func,
  /** platform info  */
  publishingPlatform: PropTypes.shape({
    mProperties: PropTypes.shape({
      platform: PropTypes.string,
      platformIcon: PropTypes.string,
    }),
  }),
  /** id of the instance */
  id: PropTypes.string,
};

KanbanCard.defaultProps = {
  image: '',
  destination: '',
  title: '',
  storyId: '',
  color: 'surfaceCard',
  mContentKey: '',
  openStory: () => {},
  publishingPlatform: { mProperties: {} },
  id: '',
};

export default KanbanCard;
