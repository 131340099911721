import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    height: '100%',
    alignItems: 'center',
  },
  pitchButton: {
    ...theme.typography.dina.button,
    fontWeight: 500,
    textTransform: 'none',
    backgroundColor: theme.palette.dina.statusStoryMarket,
    boxShadow: 'none',
    height: '32px',
    padding: '2px 30px 1px 30px',
    '&:hover': {
      '-webkit-filter': theme.palette.dina.onHover,
      backgroundColor: theme.palette.dina.statusStoryMarket,
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: `0 0 0 1px ${theme.palette.dina.onFocus}`,
    },
  },
  leftTools: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    height: '32px',
    marginLeft: '4px',
    marginRight: '7px',
    background: theme.palette.dina.watermark,
    width: 1.5,
  },
  datePicker: {
    width: 200,
  },
}));

export default useStyles;
