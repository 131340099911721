import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, Button } from '@material-ui/core';
import colors from 'theme/colors';
import useHover from 'hooks/useHover';
import Icon from 'components/icon';
import useStyles from './toolbarChipButton-styles';

const ToolbarChipButton = props => {
  const { selected, onClick, label, onDelete, title, icon } = props;
  const classes = useStyles(selected)();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [hoverRef, isHovered] = useHover();

  const handleDelete = () => setShowConfirmation(true);

  const handleKeep = () => setShowConfirmation(false);

  const handleRemove = () => onDelete();
  return showConfirmation ? (
    <div>
      <Button
        classes={{
          root: classes.keepButton,
          label: classes.buttonText,
        }}
        onClick={handleKeep}
      >
        Keep
      </Button>
      <Button
        classes={{
          root: classes.removeButton,
          label: classes.buttonText,
        }}
        onClick={handleRemove}
      >
        Remove
      </Button>
    </div>
  ) : (
    <Chip
      title={title}
      className={classes.chip}
      label={label}
      onClick={onClick}
      ref={hoverRef}
      classes={{
        deletable: classes.deletable,
        deleteIcon: isHovered ? classes.deleteIconHovered : classes.deleteIcon,
      }}
      onDelete={handleDelete}
      icon={
        <Icon
          icon={icon}
          height={24}
          width={24}
          hovered={selected}
          hoverColor={colors.onFocus}
        />
      }
    />
  );
};

ToolbarChipButton.propTypes = {
  /** Icon that will be displayed on the button */
  icon: PropTypes.string.isRequired,
  /** The label of the button */
  label: PropTypes.string,
  /** click function */
  onClick: PropTypes.func,
  /** selected / not selected */
  selected: PropTypes.bool,
  /** on delete/remove function */
  onDelete: PropTypes.func,
  /** title that can be displayed on hover */
  title: PropTypes.string,
};

ToolbarChipButton.defaultProps = {
  label: '',
  onClick: () => {},
  selected: false,
  onDelete: () => {},
  title: '',
};

export default ToolbarChipButton;
