import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button as MaterialButton, ClickAwayListener } from '@material-ui/core';
import useStyles from './styles';

const Button = ({ label, icon, onClick, type, onClickAway }) => {
  const classes = useStyles();

  const renderedLabel = type === 'confirm' ? 'Click again to remove' : label;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <MaterialButton
        startIcon={icon}
        onClick={onClick}
        classes={{ root: classes[type], label: classes.label, startIcon: classes.startIcon }}
      >
        <div className={classes[`${type}Label`]}>{renderedLabel}</div>
      </MaterialButton>
    </ClickAwayListener>
  );
};

Button.propTypes = {
  /** Label of the button */
  label: PropTypes.string,
  /** Icon React component */
  icon: PropTypes.element,
  /** onClick callback function */
  onClick: PropTypes.func,
  /** type of the button */
  type: PropTypes.string,
  /** click away from button callback */
  onClickAway: PropTypes.func,
};

Button.defaultProps = {
  label: '',
  icon: null,
  onClick: () => {},
  type: 'default',
  onClickAway: () => {},
};

export default memo(Button);
