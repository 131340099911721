import memberTypes from 'graphql/memberTypes';
import doNotify from './notificationConfig';

const ASSIGNED = 'Assigned';
const REMOVED = 'Removed';
const MODIFIED = 'Modified';
const FROM = 'From';
const TO = 'to';
const SHARED = 'Shared';
const WITH = 'With';
const ADDED = 'Added';

const { USER, STORY, TEAM, DEPARTMENT, INSTANCE } = memberTypes;

const sharePitchWithUser = (userTitle, pitchTitle, actionName) =>
  `${userTitle !== 'You' ? `${userTitle} is` : 'You have been '}\r\n${
  actionName === 'INSERT' ? 'assigned to pitch' : 'removed from pitch'
  }\r\n"${pitchTitle}"`;

const shareStoryWithUser = (userTitle, storyTitle, actionName) =>
  `${userTitle !== 'You' ? `${userTitle} is` : 'You have been '}\r\n${
  actionName === 'INSERT' ? 'assigned to story' : 'removed from story'
  }\r\n"${storyTitle}"`;

const shareStoryWithTeam = (teamName, storyName, actionName) =>
  `${
  actionName === 'INSERT' ? SHARED : REMOVED
  } ${STORY}:\r\n"${storyName}"\r\n${
  actionName === 'INSERT' ? WITH : FROM
  }  ${TEAM}:\r\n"${teamName}"`;

const shareStoryWithdepartment = (departmentName, storyName, actionName) =>
  `${
  actionName === 'INSERT' ? SHARED : REMOVED
  } ${STORY}:\r\n"${storyName}"\r\n${
  actionName === 'INSERT' ? WITH : FROM
  }  ${DEPARTMENT}:\r\n"${departmentName}"`;

const addOrRemoveUserToTeam = (userName, teamName, actionName) =>
  `${actionName === 'INSERT' ? ADDED : REMOVED} ${
  userName !== 'You' ? USER : ''
  }\r\n"${userName}"\r\n${
  actionName === 'INSERT' ? TO : FROM
  }  ${TEAM}:\r\n"${teamName}"`;

const addOrRemoveDepartmentToTeam = (departmentName, teamName, actionName) =>
  `${
  actionName === 'INSERT' ? ADDED : REMOVED
  } ${DEPARTMENT}\r\n"${departmentName}"\r\n${
  actionName === 'INSERT' ? TO : FROM
  }  ${TEAM}:\r\n"${teamName}"`;

const instanceAddedToStory = (instanceName, storyName, actionName) =>
  `${ADDED} a "${instanceName.toUpperCase()}" instance to ${STORY}:\r\n${storyName}`;

const userAddOrRemoveFromInstance = (userTitle, instanceName, actionName) =>
  `${userTitle !== 'You' ? `${userTitle} is` : 'You have been'}\r\n${
  actionName === 'INSERT' ? 'assigned to instance' : 'removed from instance'
  }\r\n"${instanceName}"`;

// message types
const sharePitchWithUserMessage = (pitch) => {
  const { user, loggedInUserId, crudAction } = pitch.message;
  const message = sharePitchWithUser(
    getUserTitle(user, loggedInUserId),
    pitch.mTitle,
    crudAction,
  );
  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === 'INSERT' ? 'Shared pitch' : 'Removed user from pitch',
    itemId: pitch.mId,
    itemType: pitch.mType,
    itemTitle: pitch.mTitle,

  };
};

const shareStoryWithUserMessage = (story) => {
  const { user, loggedInUserId, crudAction } = story.message;
  const message = shareStoryWithUser(
    getUserTitle(user, loggedInUserId),
    story.mTitle,
    crudAction,
  );
  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === 'INSERT' ? 'Shared story' : 'Removed user from story',
    itemId: story.mId,
    itemType: story.mType,
    itemTitle: story.mTitle,
  };
};

const shareStoryWithTeamMessage = item => {
  const message = shareStoryWithTeam(
    item.team.mTitle,
    item.story.mTitle,
    item.crudAction
  );
  return {
    message,
    messageType: 'share',
    messageTypeString:
      item.crudAction === 'INSERT'
        ? 'Shared story with team'
        : 'Removed story from team',
  };
};

const shareStoryWithDepartmentMessage = item => {
  const message = shareStoryWithdepartment(
    item.department.mTitle,
    item.story.mTitle,
    item.crudAction
  );
  return {
    message,
    messageType: 'share',
    messageTypeString:
      item.crudAction === 'INSERT'
        ? 'Shared story with department'
        : 'Removed story from department',
  };
};

const addOrRemoveUserToTeamMessage = item => {
  const message = addOrRemoveUserToTeam(
    item.user.mTitle,
    item.team.mTitle,
    item.crudAction
  );
  return {
    message,
    messageType: 'share',
    messageTypeString:
      item.crudAction === 'INSERT'
        ? 'Added user to team:'
        : 'Removed user from team:',
  };
};

const addOrRemoveDepartmentToTeamMessage = item => {
  const message = addOrRemoveDepartmentToTeam(
    item.department.mTitle,
    item.team.mTitle,
    item.crudAction
  );
  return {
    message,
    messageType: 'share',
    messageTypeString:
      item.crudAction === 'INSERT'
        ? 'Added department to team:'
        : 'Removed department from team:',
  };
};

const instanceAddedToStoryMessage = item => {
  const message = instanceAddedToStory(
    item.instance.mProperties.platform,
    item.story.mTitle,
    item.crudAction
  );
  return {
    message,
    messageType: `${item.instance.mProperties.platform}`,
    messageTypeString:
      item.crudAction === 'INSERT'
        ? 'Added an instance to story:'
        : 'Removed instance from story:',
  };
};

const addOrRemoveUserFromInstance = (instance) => {
  const { user, loggedInUserId, crudAction } = instance.message;
  const message = userAddOrRemoveFromInstance(
    getUserTitle(user, loggedInUserId),
    instance.mTitle,
    crudAction,
  );

  return {
    message,
    messageType: 'share',
    messageTypeString:
      crudAction === 'INSERT'
        ? 'Assigned user to instance:'
        : 'Removed user from instance:',
    itemId: instance.mStoryId,
    itemType: instance.mType,
    itemTitle: instance.mTitle,
  };
};


const getUserTitle = (user, currentUser) => {
  return currentUser !== user.mId ? user.mTitle : 'You';
};

const getMessage = (notifyUser) => {
  const { mType } = notifyUser.message;
  let message;
  switch (mType) {
    case 'usr_str':
      message = shareStoryWithUserMessage(
        notifyUser,
      );
      break;
    case 'tea_str':
      message = shareStoryWithTeamMessage(notifyUser);
      break;
    case 'dep_str':
      message = shareStoryWithDepartmentMessage(notifyUser);
      break;
    case 'tea_usr':
      message = addOrRemoveUserToTeamMessage(notifyUser);
      break;
    case 'dep_tea':
      message = addOrRemoveDepartmentToTeamMessage(notifyUser);
      break;
    case 'str_ins':
      message = instanceAddedToStoryMessage(notifyUser);
      break;
    case 'usr_ins':
      message = addOrRemoveUserFromInstance(notifyUser);
      break;
    case 'usr_pitch':
      message = sharePitchWithUserMessage(notifyUser);
      break;
    default:
      break;
  }
  return message;
};

const createMessage = notifyUser => {
  const messages = [];
  let messageType = '';
  let messageTypeString = '';
  let itemId = '';
  let itemType = '';
  let itemTitle = '';
  try {
    const { loggedInUserId } = notifyUser.message;
    const notify = doNotify(notifyUser.message, loggedInUserId);
    const message = notify ? getMessage(notifyUser) : undefined;
    if (message !== undefined) {
      messages.push(`${message.message}\r\n\r\n`);
      messageType = message.messageType;
      messageTypeString = message.messageTypeString;
      itemId = message.itemId;
      itemType = message.itemType;
      itemTitle = message.itemTitle;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error in getTitle:', error);
  }
  const ret = { messages, messageType, messageTypeString, itemId, itemType, itemTitle };

  return ret;
};

export default createMessage;
