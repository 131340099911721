import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    minHeight: '40px',
    maxHeight: '80px',
    overflow: 'hidden',
    borderRadius: '0px 0px 8px 8px',
  },
  footerContent: {
    width: '100%',
    height: 40,
    position: 'relative',
    overflow: 'hidden',
    borderTop: ({ backgroundColor }) =>
      `1px solid ${
        palette.dina[backgroundColor === 'surfaceCard' ? 'dividerLight' : backgroundColor]
      }`,
  },
  backgroundColor: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: ({ backgroundColor }) => palette.dina[backgroundColor],
    opacity: 0.15,
    transition: 'background 0.5s ease-in-out',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pointerEvents: 'none',
    '& > *': { pointerEvents: 'all' },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  assigneesWrapper: { marginLeft: 4, marginRight: 8 },
  divider: {
    height: '24px',
  },
  info: {
    display: 'flex',
    paddingLeft: 4,
  },
}));

export default useStyles;
