import GET_INSTANCE_FROM_CACHE from 'graphql/queries/getInstanceFromCache';
import GET_RUNDOWN from 'graphql/queries/getRundown';

// Returns instances from cache of a particular order (mOrder, mPreorder) of a rundown.
const resolveRundownInstancesCache = (parent, args, { cache, getCacheKey }) => {
  const { mId, mRefId: orderType } = args.input;
  const items = [];

  if (!['mOrder', 'mPreorder'].includes(orderType)) return items;

  try {
    const rundownData = cache.readQuery({
      variables: {
        input: {
          mId,
          mRefId: mId,
        },
      },
      query: GET_RUNDOWN,
    });

    const { getRundown: rundown } = rundownData;

    const order = rundown[orderType] || [];

    order
      .filter(id => id && id[0] !== '-')
      .forEach(mid => {
        const id = getCacheKey({ mId: mid, mRefId: mid, __typename: 'MemberType' });

        const data = cache.readFragment({
          id,
          fragment: GET_INSTANCE_FROM_CACHE,
        });

        if (data) {
          items.push(data);
        }
      });
  } catch (e) {
    console.error(e);
  }

  return items;
};

export default resolveRundownInstancesCache;
