import { useContext } from 'react';
import { EditorContext } from 'globalState';
import UserCtx from 'contexts/UserContext';
import useUnlockMember from 'hooks/useUnlockMember';
import useUnlockInstance from 'hooks/useUnlockInstance';

const useReleaseEditorLock = () => {
  const [, , editorData] = useContext(EditorContext);
  const { mId, mType, locked } = editorData;

  const [unlockStory] = useUnlockMember();
  const [unlockInstance] = useUnlockInstance();

  const user = useContext(UserCtx);
  const releaseLock = async id => {
    try {
      if (id !== mId && !!mId && locked === user.mId) {
        if (mType === 'story') await unlockStory(mId);
        if (mType === 'instance') await unlockInstance(mId);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return [releaseLock];
};

export default useReleaseEditorLock;
