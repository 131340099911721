import statusBoard from './statusBoard';

/**
 * Returns a list (array) of states properties according to view
 *
 * @param {String} viewVariant id of the view
 * @returns {Object[]} Array of states
 */

const returnStates = viewVariant => {
  const { defaultView, states, views } = statusBoard;
  const viewType = views.some(view => viewVariant === view.id)
    ? viewVariant
    : defaultView;
  const view = views.filter(v => v.id === viewType);
  const { states: viewingStates } = view[0];
  const stateList = viewingStates.map(s =>
    states.find(state => state.id === s.id)
  );
  return stateList;
};

export default returnStates;
