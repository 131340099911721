import { useState, useEffect, useRef } from 'react';
import Storage from '@aws-amplify/storage';
import { getItems } from 'utils/s3Utils';

const useStorage = (key, skipDownload = false, defaultKey = null) => {
  const [error, setError] = useState(null);
  const [reFetch, setReFetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const isSubscribed = useRef(null);

  const refetchCallback = () => {
    setReFetch(previousState => !previousState);
  };

  useEffect(() => {
    isSubscribed.current = true;

    if (!key && data) setData(null);

    if (!skipDownload && key) {
      (async () => {
        setLoading(true);
        let sourceKey = key;

        try {
          // console.log(defaultKey);
          const items = await getItems(sourceKey);
          // console.log(items);
          if (defaultKey && sourceKey !== defaultKey && !items.length) {
            sourceKey = defaultKey;
          }

          // console.log(sourceKey);

          await Storage.get(sourceKey, {
            download: true,
            customPrefix: { public: '' },
          }).then(r => {
            if (isSubscribed.current) setData(r);
          });
        } catch (err) {
          if (isSubscribed.current) {
            setError(err.message);
            setData(null);
          }
        } finally {
          if (isSubscribed.current) setLoading(false);
        }
      })();
    } else {
      setLoading(false);
      setData(null);
    }

    return () => {
      isSubscribed.current = false;
    };
  }, [key, skipDownload, defaultKey, reFetch]);

  return { error, loading, data, refetch: refetchCallback };
};

export default useStorage;
