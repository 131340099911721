import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants/types';
import listComponents from './constants/listComponents';
import useStyles from './styles';

const List = ({ attributes, children, element }) => {
  const classes = useStyles();
  const ListComponent = listComponents[element.type];

  return (
    <ListComponent className={classes.root} {...attributes}>
      {children}
    </ListComponent>
  );
};

List.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

List.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.LIST_ITEM,
    children: [],
  },
};

export default memo(List);
