import gql from 'graphql-tag';

export default gql`
  query GetPlatform($input: GetPlatformInput) {
    getPlatform(input: $input) {
      mId
      mProperties {
        ... on PlatformType {
          platform
          platformAccount
          provider {
            embeddedEndpoint
            message
            previewEndpoint
            requestId
            state
            updateEndpoint
          }
          account {
            accountId
            accountTitle
          }
          publishedAt
          scheduledAt
          updatedAt
        }
      }
      mTitle
      mType
    }
  }
`;
