import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  mainContent: {
    width: '100%',
    height: 'calc(100% - 120px)',
    position: 'absolute',
    top: 120,
    left: 0,
  },
  withoutHeader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  centerContent: {
    flex: 1,
    height: '100%',
    position: 'relative',
  },
  contentContainer: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    scrollBehavior: 'smooth',
  },
  subscreen: {
    // height: 'calc(100% - 190px)',
    display: 'flex',
    justifyContent: 'center',
    // overflow: 'auto',
  },
  fadeIn: {
    animationName: '$fadeInOpacity',
    animationTimingFunction: 'ease-in',
    animationDuration: '2s',
    animationIterationCount: 2,
  },

  '@keyframes fadeInOpacity': {
    '0%': {
      boxShadow: 'none',
    },
    '50%': {
      boxShadow: `0 0 0 3px ${theme.palette.dina.onSelected}`,
    },
    '100%': {
      boxShadow: 'none',
    },
  },
  scrollStyle: {
    boxShadow: `0 0 0 2px ${theme.palette.dina.statusApproved}`,
  },
  tabContent: {
    position: 'relative',
    width: ({ isMaximized }) => (isMaximized ? 'calc(100% - 112px)' : '645px'),
    transition: 'width 0.3s ease-out',
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentDiv: {
    width: ({ isMaximized }) => (isMaximized ? 'calc(100% - 112px)' : '645px'),
    transition: 'width 0.3s ease-out',
    maxWidth: 1058,
  },
}));

export default useStyles;
