import React from 'react';
import PropTypes from 'prop-types';
import HeaderBase from '../headerBase';

const RelevantHeaderView = ({
  menuItems,
  onMenuItemClick,
  hideMenu,
  headerTitle,
}) => {
  return (
    <HeaderBase {...{ menuItems, onMenuItemClick, hideMenu, headerTitle }} />
  );
};

RelevantHeaderView.propTypes = {
  /** Items to be shown as popover options on ellipsis click */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
    })
  ),
  /** Callback to be invoked on menu item select,
   * with the action type passed in as argument
   */
  onMenuItemClick: PropTypes.func,
  /** If true the ellipsis menu is not shown */
  hideMenu: PropTypes.bool,
  /** The header title */
  headerTitle: PropTypes.string,
};

RelevantHeaderView.defaultProps = {
  menuItems: [],
  onMenuItemClick: () => {},
  hideMenu: false,
  headerTitle: '',
};

export default RelevantHeaderView;
