import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  panel: {
    height: '100%',
    width: '100%',
    borderRight: `4px solid ${theme.palette.dina.dividerLight}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingIndicatorContainer: {
    position: 'absolute',
    width: '100%',
  },
}));

export default useStyles;
