import {
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  differenceInHours,
} from 'date-fns';

/**
 * Determines the intended date format given the current tick interval
 *
 * @param {Object} firstTick Date object for the first tick
 * @param {Object} secondTick Date object for the second tick
 * @returns {string} Date format
 */

const getTickFormat = (firstTick, secondTick) => {
  if (differenceInYears(secondTick, firstTick)) return null;

  if (differenceInMonths(secondTick, firstTick)) return 'YYYY';

  if (differenceInDays(secondTick, firstTick)) return 'MMM YYYY';

  if (differenceInHours(secondTick, firstTick) >= 12) return 'MMM DD';

  if (differenceInHours(secondTick, firstTick) < 12) return 'ddd, MMM DD YYYY';

  return null;
};

export default getTickFormat;
