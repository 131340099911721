import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderLeft: `1px solid ${theme.palette.dina.dividerLight}`,
    display: 'flex',
    justifyContent: 'center',
  },
  months: {
    width: '108px',
    display: 'flex',
    flexDirection: 'column',
  },
  month: {
    width: '100%',
    maxHeight: '24px',
    minHeight: '24px',
    margin: '8px 0',
    borderRadius: '12px',
  },
  monthSelected: {
    width: '100%',
    maxHeight: '24px',
    minHeight: '24px',
    margin: '8px 0',
    display: 'flex',
    backgroundColor: theme.palette.dina.onSelected,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: theme.palette.dina.onSelected,
    },
  },
  monthText: {
    ...theme.typography.dina.instanceFooterLabel,
    letterSpacing: '0.25px',
    color: theme.palette.dina.mediumEmphasis,
    textTransform: 'none',
  },
  monthTextSelected: {
    ...theme.typography.dina.instanceFooterLabel,
    letterSpacing: '0.25px',
    color: theme.palette.dina.highEmphasis,
    textTransform: 'none',
  },
}));

export default useStyles;
