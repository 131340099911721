import React, { useState, useEffect } from 'react';
import { List } from '@material-ui/core';
// eslint-disable-next-line max-len
import Member from 'screens/main/components/rightArea/sidebar/teams/listItem/addMember/addMember-view';

let selectedMember;

const MemberList = props => {
  const {
    variant,
    addChipMember,
    removeChipMember,
    showMember,
    pressedKey,
    onPressKey,
  } = props;
  const [cursor, setCursor] = useState(0);

  useEffect(() => {
    if (pressedKey === 'ArrowDown') {
      onPressKey();
      if (cursor < showMember.length - 1) {
        setCursor(cursor + 1);
      } else setCursor(showMember.length - 1);
    }
    if (pressedKey === 'ArrowUp') {
      onPressKey();
      if (cursor > 0) {
        setCursor(cursor - 1);
      } else setCursor(0);
    }
    if (pressedKey === 'Enter') {
      onPressKey();
      setCursor(0);

      if (selectedMember) {
        const { selected, ...rest } = selectedMember;
        if (!selectedMember.selected) addChipMember({ ...rest });
        else removeChipMember({ ...rest });
      }
    }
    if (pressedKey === 'Character') setCursor(0);
  }, [
    pressedKey,
    cursor,
    onPressKey,
    addChipMember,
    showMember,
    removeChipMember,
  ]);

  useEffect(() => {
    const target = document.querySelector('.scrollActive');
    if (target) target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [cursor]);

  return (
    <List disablePadding>
      {showMember.map((member, index) => {
        const { selected, ...rest } = member;
        const focused = cursor === index;
        if (focused) selectedMember = member;
        return (
          <Member
            key={member.mId}
            id={member.mId}
            name={member.mTitle}
            title={member.mCreatedAt} // TODO: fix this
            image={member.mAvatarUrl}
            avatarVariant={variant}
            onAdd={() => {
              addChipMember({ ...rest });
            }}
            onRemove={() => {
              removeChipMember({ ...rest });
            }}
            selected={selected}
            focused={focused}
            pressedKey={pressedKey}
          />
        );
      })}
    </List>
  );
};

export default MemberList;
