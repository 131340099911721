import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    minHeight: 56,
    borderRadius: 4,
    position: 'relative',
    '&:hover': {
      backgroundColor: palette.dina.whiteHoverOverlay,
      transition: '0.3s ease',
    },
    display: 'flex',
  },
  child: {
    width: 'calc(100% - 16px)',
    margin: '0 8px',
    borderTop: ({ isGraphic }) =>
      `1px solid ${
        isGraphic ? palette.dina.timelineGraphic : palette.dina.timelineSecondaryAccessory
      }`,
    borderBottom: ({ isGraphic }) =>
      `1px solid ${
        isGraphic ? palette.dina.timelineGraphic : palette.dina.timelineSecondaryAccessory
      }`,
    boxSizing: 'border-box',
  },
  leftEdge: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
  },
  rightEdge: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
  },
}));

export default useStyles;
