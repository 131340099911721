import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import useMoveInstanceToRundown from 'hooks/useMoveInstanceToRundown';
import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'graphql/queries/getRundownByPublishingDate';
import SelectRundownView from './selectRundown-view';

const SelectRundown = ({
  startDate,
  endDate,
  sourceRundown,
  closeDialog,
  instanceId,
  instanceStatus,
  updateInstance,
  statusId,
}) => {
  const [moveInstanceToRundown] = useMoveInstanceToRundown(() => {});
  const { data, error, loading } = useQuery(GET_RUNDOWNS_BY_PUBLISHING_DATE, {
    variables: {
      input: {
        mType: 'rundown',
        startDate,
        endDate,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  if (loading || error) return null;

  const updateRundown = async destinationRundown => {
    if (sourceRundown.mId === destinationRundown.mId) return;
    const sRundown = sourceRundown.mId && sourceRundown.mRefId ? sourceRundown : null;
    await moveInstanceToRundown(instanceId, sRundown, destinationRundown, false);
  };

  const onSelect = async destinationRundown => {
    closeDialog();
    await updateRundown(destinationRundown).then(() => {
      if (instanceStatus !== statusId) updateInstance(instanceId, statusId);
    });
  };

  return <SelectRundownView rundowns={data.getRundownsByPublishingDate} onSelect={onSelect} />;
};

SelectRundown.propTypes = {
  /** starting time of the day */
  startDate: PropTypes.string.isRequired,
  /** ending time of the day */
  endDate: PropTypes.string.isRequired,
  sourceRundown: PropTypes.shape({
    mId: PropTypes.string,
    mRefId: PropTypes.string,
  }),
  /** callback to be invoked when closing the popup */
  closeDialog: PropTypes.func,
  /** id of the instance to be updated */
  instanceId: PropTypes.string,
  /** id of status of the instance before update */
  instanceStatus: PropTypes.string,
  /** callback to be invoked to update the state  */
  updateInstance: PropTypes.func,
  /** id of new status to be changed */
  statusId: PropTypes.string,
};
SelectRundown.defaultProps = {
  instanceId: '',
  sourceRundown: {},
  closeDialog: () => {},
  instanceStatus: '',
  updateInstance: () => {},
  statusId: '',
};

export default SelectRundown;
