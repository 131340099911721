const filterPrefixes = {
  TITLE: 'title:',
  DESCRIPTION: 'desc:',
  CONTENT: 'content:',
  LOCATION: 'location:',
  CATEGORY: 'category:',
  ARCHIVE: 'archive:',

  DEPARTMENT: 'department:',
  TEAM: 'team:',
  USER: 'user:',
  PLATFORM: 'platform:',
};

export default filterPrefixes;
