import { ReactEditor } from 'slate-react';
import { actionTypes } from 'components/editor/constants/types';
import { Transforms } from 'slate';

const { setNodes } = Transforms;

/**
 * Removes Block (video, image, gif, section etc. ) element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element element block object
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const updateBlock = (editor, element, data, update) => {
  const path = ReactEditor.findPath(editor, element);
  setNodes(editor, { data }, { at: path });
  ReactEditor.focus(editor);

  update({
    type: actionTypes.BLOCK_UPDATE,
    payload: {
      document: editor.children,
      updatedElement: {
        ...element,
        data,
      },
    },
  });

  return editor;
};

export default updateBlock;
