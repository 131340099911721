import React from 'react';
import DefaultHeader from './defaultHeader';
import MasterHeader from './masterHeader';
import useStyles from './header-styles';

const HeaderView = ({
  showMasterEditView,
  rundownTitle,
  mId,
  refId,
  defaultStart,
  mState,
  selecteddate,
  image,
  data,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.rundownHeader}>
      {!showMasterEditView ? (
        <DefaultHeader
          title={rundownTitle}
          mid={mId}
          startTime={defaultStart}
          {...{ mState, selecteddate, refId, image }}
        />
      ) : (
        <MasterHeader mid={mId} image={image} {...rest} />
      )}
    </div>
  );
};

export default HeaderView;
