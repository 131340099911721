import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  instance: {
    marginBottom: '16px',
  },
  generalInstanceWidth: {
    // maxWidth: '640px',
    maxWidth: '1058px',
    minWidth: '460px',
    margin: 'auto',
  },
  hoveredInstance: {
    marginBottom: '15px',
    boxShadow: `0 0 0 2px ${theme.palette.dina.statusWarning}`,
    borderRadius: '4px',
  },
  backdropRoot: {
    position: 'absolute',
  },
}));

export default useStyles;
