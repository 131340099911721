import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@material-ui/core';
import { ReactComponent as ArrowLeft } from 'assets/icons/systemicons/arrows/arrow_left/arrow_left.svg';
import Divider from 'components/divider';
import useStyles from './backButton-styles';

const BackButtonView = ({ label, onBackClick, ...rest }) => {
  const classes = useStyles();
  return (
    <div>
      <div
        role="button"
        tabIndex="0"
        className={classes.buttonDiv}
        onClick={event => {
          event.preventDefault();
          onBackClick();
        }}
        onKeyDown={() => {}}
        {...rest}
      >
        <IconButton disableRipple classes={{ root: classes.buttonRoot }}>
          <ArrowLeft />
        </IconButton>
        <Typography classes={{ root: classes.textRoot }}>{label}</Typography>
      </div>
      <Divider />
    </div>
  );
};

BackButtonView.propTypes = {
  /** label to show on right of back icon */
  label: PropTypes.string,
  /** callback that it calls when backButton is clicked */
  onBackClick: PropTypes.func,
};

BackButtonView.defaultProps = {
  label: '',
  onBackClick: () => {},
};

export default BackButtonView;
