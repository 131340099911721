/**
 * Returns array of unique strings which will be used as filters for search
 *
 * @param {String} searchString string typed in searchBar
 * @param {Array} filters filters added in searchBar
 * @param {Object} selectedFilter a saved filter selected from toolBar
 * @returns {Array} array consisting of unique filter strings
 *
 */

const getUniqueFilters = (filters = [], selectedFilter) => {
  const savedFilters = selectedFilter && selectedFilter.expression ? selectedFilter.expression : [];
  const allFilters = [...savedFilters, ...filters];
  const filterList = [...new Set(allFilters)];
  return filterList;
};

export default getUniqueFilters;
