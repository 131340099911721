import React from 'react';
import useStyles from './instances-styles';
import Instance from './instance';

const Instances = ({ instances }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {instances &&
        instances.length > 0 &&
        instances.map(instance => <Instance key={instance.id} {...{ instance }} />)}
    </div>
  );
};

export default Instances;
