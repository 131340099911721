import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'tiny-slider/dist/tiny-slider.css';
import { getMonth, getYear } from 'date-fns';
import useSliderStyles from '../useSliderStyles';
import Navs from '../Navs';
import useStyles from './MonthPickerStyles';
import CapsuleIndicator from './CapsuleIndicator';
import {
  generateMonths,
  animateIndicator,
  resetAnimatedIndicator,
  showStaticInidicator,
  createSlider,
} from './functions';

let slider;

const MonthPicker = ({
  rootWidth,
  calendarDate,
  onMonthSelect,
  selectedDates,
}) => {
  const classes = useStyles();
  const sliderClasses = useSliderStyles();
  const firstUpdate = useRef(true);
  const sliderWrapper = useRef(null);
  const sliderContainer = useRef(null);
  const indicator = useRef(null);
  const currentMonth = getMonth(calendarDate);
  const currentYear = getYear(calendarDate);
  const [months, setMonths] = useState(generateMonths(currentYear));
  const selectedMonth = months.findIndex(
    ({ monthIndex, year }) =>
      monthIndex === currentMonth && year === currentYear
  );

  const resetIndicator = () =>
    resetAnimatedIndicator(indicator.current, sliderWrapper.current);
  const showIndicator = () =>
    showStaticInidicator(indicator.current, sliderWrapper.current);

  const init = () => {
    slider = createSlider(sliderContainer.current, rootWidth);

    slider.events.on('transitionEnd', resetIndicator);
    slider.events.on('transitionStart', showIndicator);
    slider.events.on('transitionEnd', showIndicator);

    showIndicator();

    return () => {
      slider.events.off('transitionEnd', resetIndicator);
      slider.events.off('transitionStart', showIndicator);
      slider.events.off('transitionEnd', showIndicator);
    };
  };

  const updateSlider = () => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const selectedYear = getYear(selectedDates[0]);

    if (currentYear > selectedYear + 5 || currentYear < selectedYear - 5) {
      setMonths(generateMonths(selectedYear));
    }
  };

  const updateMonth = () => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const { index, indexCached, slideCount } = slider.getInfo();
    const secondActiveItemIndex = index + 1;
    const lastActiveItemIndex = index + 2;
    const isActiveItem = index === indexCached;
    const isAtStart = index === 0;
    const isFirstActiveItem = selectedMonth === index;
    const isSecondActiveItem = selectedMonth === secondActiveItemIndex;
    const isAtEnd = lastActiveItemIndex === slideCount - 1;
    const isLastActiveItem = selectedMonth === lastActiveItemIndex;

    if (
      isActiveItem &&
      (isSecondActiveItem ||
        ((isAtStart && isFirstActiveItem) || (isAtEnd && isLastActiveItem)))
    ) {
      animateIndicator(indicator.current, sliderWrapper.current);
    } else {
      slider.goTo(selectedMonth - 1);
    }
  };

  useEffect(init, []);
  useEffect(updateSlider, [currentYear]);
  useEffect(updateMonth, [currentMonth]);

  const onPrev = () => {
    slider.goTo('prev');
  };

  const onNext = () => {
    slider.goTo('next');
  };

  return (
    <div className={classes.root}>
      <Navs {...{ onPrev, onNext }} />

      <div className={sliderClasses.root} ref={sliderWrapper}>
        <CapsuleIndicator ref={indicator} />
        <div ref={sliderContainer} className={sliderClasses.sliderContainer}>
          {months.map((month, index) => {
            const { monthName, year } = month;

            return (
              <div
                key={`${year}${monthName}`}
                role="button"
                tabIndex={index}
                onKeyDown={() => onMonthSelect(month)}
                onClick={() => onMonthSelect(month)}
              >
                <div
                  className={`${classes.month} ${
                    index === selectedMonth ? 'selected-month' : ''
                  }`}
                >
                  <span>{`${monthName} ${
                    index === selectedMonth ? year : ''
                  }`}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

MonthPicker.propTypes = {
  rootWidth: PropTypes.number.isRequired,
  calendarDate: PropTypes.instanceOf(Date).isRequired,
  onMonthSelect: PropTypes.func.isRequired,
  onYearChange: PropTypes.func.isRequired,
};

export default MonthPicker;
