import getDurationKey from './getDurationKey';

/**
 * Finds duration by key & returns its value
 *
 * @param {Object[]} items Array of duration objects that will be searched
 * @param {string} key Property of object that should be used to find duration
 * @returns {string} Duration value
 */

const getDuration = (collection, key) => {
  const duration = (collection || []).find(item => getDurationKey(item) === key);

  return duration ? duration.value : undefined;
};

export default getDuration;
