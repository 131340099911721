import gql from 'graphql-tag';

export default gql`
  
  mutation ShareStoryWith($newMembers: CreateMembersInput, 
      $removedMembers: BacthDeleteMemberRelationInput,
      $updateAssigneesInput: UpdateMemberInput) {
        addMembers: createMembers(input: $newMembers) {
          mId
          mRefId
          mType
        }
        
       removeMembers: batchDeleteMemberRelation(input: $removedMembers) {
          mId
          mRefId
          mType
      }

      updateAssignees: updateMember(input: $updateAssigneesInput) {
          mId
          mRefId
          mUpdatedAt
          mAssignedMembers {
            mId
            mType
          }
      }
    }
`;
