import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import GET_DEPARTMENT_MEMBERS from 'graphql/queries/getDepartmentMembers';
import memberTypes from 'graphql/memberTypes';
import { getMembersQuery } from 'graphql/queryVariables';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import SummaryView from '../summary-view';

const DepartmentContainer = props => {
  const { item, ...rest } = props;
  const { id, description } = item;
  const [listDetails, setListDetails] = useState([]);

  const { data: teamData, loading: teamLoading, error: teamError } = useQuery(
    GET_DEPARTMENT_MEMBERS,
    {
      variables: getMembersQuery(id, memberTypes.DEPARTMENT_TEAM),
      fetchPolicy: 'cache-and-network',
    }
  );

  const {
    data: peopleData,
    loading: peopleLoading,
    error: peopleError,
  } = useQuery(GET_DEPARTMENT_MEMBERS, {
    variables: getMembersQuery(id, memberTypes.DEPARTMENT_USER),
    fetchPolicy: 'cache-and-network',
  });

  if (teamLoading || peopleLoading) return <LoadingIndicator />;
  if (teamError) return <div> No Team Data. </div>;
  if (peopleError) return <div> No people data </div>;

  const { getMembersOf: getTeams } = teamData;
  const { getMembersOf: getPeople } = peopleData;

  if (listDetails.length < 1 && getTeams && getPeople) {
    setListDetails([
      {
        listTitle: 'Teams',
        avatarVariant: 'Team',
        members: getTeams,
      },
      {
        listTitle: 'People',
        avatarVariant: 'People',
        members: getPeople,
      },
    ]);
  }

  return (
    <SummaryView
      title={item.name}
      avatarUrl={item.image}
      listDetails={listDetails}
      description={description}
      {...rest}
    />
  );
};

export default DepartmentContainer;
