/**
 * Returns new image dimensions according to given fitSize.
 * Uses the smallest dimension as critical.
 * I.e. will be set the smallest dimension equal to the fitSize.
 * The largest dimension will be set to fitSize * aspectRatio.
 * @param orgSize - Orginal size { width, height }
 * @param fitSize - The dimension to fit.
 * @return New scaled dimensions { width, height }
 */
const getScaledImageSize = (orgSize, fitSize) => {
  const { width, height } = orgSize;
  if (width <= fitSize || height <= fitSize) {
    return orgSize;
  }
  if (width < height) {
    return { width: fitSize, height: (fitSize * height) / width };
  }
  return { height: fitSize, width: (fitSize * width) / height };
};

export default getScaledImageSize;
