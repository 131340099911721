/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { inTimingTypes } from 'components/editor/constants/types';
import useStyles from './styles';

const { MANUAL_IN, AUTO_IN } = inTimingTypes;

const InIconView = ({ inTimingType, isGraphic, ...rest }) => {
  const isAuto = inTimingType === AUTO_IN;
  const classes = useStyles({ isGraphic, isAuto });

  return (
    <div {...rest}>
      <div className={classes.root}>
        <div className={classes.top} />
        <div className={classes.middle} />
        <div className={classes.bottom} />
      </div>
    </div>
  );
};

InIconView.propTypes = {
  /** Timing type for in */
  inTimingType: PropTypes.oneOf(Object.values(inTimingTypes)),
  /** Specifies if the type is 'OVERLAY_GRAPHICS' */
  isGraphic: PropTypes.bool,
};

InIconView.defaultProps = {
  inTimingType: MANUAL_IN,
  isGraphic: false,
};

export default memo(InIconView);
