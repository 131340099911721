import React, { useState, useContext, createContext } from 'react';

const LoadingContext = createContext();

const LoadingContextProvider = props => {
  const [loadingIndicatorFor, setLoadingIndicatorFor] = useState(null);

  return (
    <LoadingContext.Provider
      value={{ loadingIndicatorFor, setLoadingIndicatorFor }}
      {...props}
    />
  );
};

export const useLoadingIndicator = () => useContext(LoadingContext);

export default LoadingContextProvider;
