import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  chipGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '4px',
    marginBottom: '20px',
  },
  chip: {
    marginTop: '4px',
  },
}));

export default useStyles;
