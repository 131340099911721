import React from 'react';
import { MenuItem as MaterialMenuItem } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  label: {
    color: theme.palette.dina.highEmphasis,
    width: '80px',
  },
  icon: {
    color: theme.palette.dina.mediumEmphasis,
  },
  primary: {
    color: theme.palette.dina.mediumEmphasis,
  },
  danger: {
    borderRadius: '4px',
    backgroundColor: theme.palette.dina.statusError,
    marginBottom: '8px',
  },
  cta: {
    borderRadius: '4px',
    backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
    marginBottom: '8px',
  },
  normal: {
    borderRadius: '4px',
    border: '1px solid grey',
    marginBottom: '8px',
  },
}));

const FavoriteMenuItem = props => {
  const { label, children, type, onClose, ...others } = props;
  const classes = useStyles(type);
  return (
    <div className={classes[type]}>
      <MaterialMenuItem
        onClick={() => {
          onClose(label);
        }}
        classes={{
          root: classes.root,
        }}
        {...others}
      >
        <ListItemIcon className={classes.icon}>{children}</ListItemIcon>
        <ListItemText classes={{ primary: classes.label }} primary={label} />
      </MaterialMenuItem>
    </div>
  );
};

export default FavoriteMenuItem;
