import React, { useContext, useMemo } from 'react';
import configCtx from 'contexts/configContext';
import RundownItemTypes from 'screens/rundown/utils/RundownItemTypes';
import Details from './details-view';
import SortAfterFilter from './utils/SortAfterFilter';

const removeDisabledTemplates = templates => templates.filter(({ disabled }) => !disabled);

const DetailsContainer = props => {
  /** Context to access the template configuration */
  const { automationTemplateConfigs } = useContext(configCtx);
  const { templateType, type } = props;

  const templateConfigs = automationTemplateConfigs[0].templateSets[0].templates;
  const { mixerInputs } = automationTemplateConfigs[0].resources;

  const sortedSpec = useMemo(
    () =>
      SortAfterFilter(
        removeDisabledTemplates(templateConfigs),
        { type: RundownItemTypes[templateType] || type },
        'variant',
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <Details spec={sortedSpec} mixerInputs={mixerInputs} {...props} />;
};

export default DetailsContainer;
