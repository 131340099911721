import React from 'react';
import PropTypes from 'prop-types';
import usePublishDateUpdate from 'hooks/usePublishDateUpdate';
import DatePickerButtonView from './publishingDatePickerButton-view';

const DatePickerButton = ({ storyId, publishingTime }) => {
  const [updatePublishDate] = usePublishDateUpdate();

  const onPublishingTimeChange = newTime => {
    const publishingDate = new Date(newTime);
    updatePublishDate(storyId, publishingDate);
  };

  return (
    <DatePickerButtonView
      {...{
        publishingTime,
        onPublishingTimeChange,
      }}
    />
  );
};

DatePickerButton.propTypes = {
  storyId: PropTypes.string.isRequired,
  publishingTime: PropTypes.string,
};

DatePickerButton.defaultProps = {
  publishingTime: '',
};

export default DatePickerButton;
