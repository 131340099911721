/**
 * Extracts changed value given mos xml object
 *
 * @param {Object} xmlDoc Parsed mos xml object
 * @returns {String} date value
 */

const getMosChanged = xmlDoc => {
  const changedObject = xmlDoc.querySelector('changed');
  const changed = changedObject
    ? new Date(changedObject.textContent).toISOString()
    : null;

  return changed;
};

export default getMosChanged;
