import getMosAsset from 'components/editor/utils/getMosAsset';
import { elementTypes, actionTypes } from 'components/editor/constants/types';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import findAndReplace from 'utils/findAndReplace';
// eslint-disable-next-line max-len
import insertSecondaryAutomationElement from 'components/editor/components/secondaryAutomation/utils/insertSecondaryAutomationElement';

const { FULLSCREEN_GRAPHICS, DVE, OVERLAY_GRAPHICS } = elementTypes;

/**
 * Adds graphics media to given element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element to be updated
 * @param {Object} payload Data payload of dropped item
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const addGraphics = (editor, element, payload, update) => {
  const asset = getMosAsset(payload);
  const { type: elementType } = element;

  if (asset) {
    if (elementType === FULLSCREEN_GRAPHICS || elementType === DVE) {
      const { data } = element;
      const { assets: previousAssets, ...rest } = data;
      const newAsset = { ...asset, mosobj: payload };

      const updatedData = {
        ...rest,
        assets: findAndReplace(previousAssets, newAsset, 'assetType'),
      };

      Transforms.setNodes(editor, { data: updatedData });
      update({
        type: actionTypes.AUTOMATION_UPDATE,
        payload: { document: editor.children, updatedData },
      });
    } else
      insertSecondaryAutomationElement(editor, OVERLAY_GRAPHICS, {
        templateVariant: asset.title,
        mosobj: payload,
      });

    ReactEditor.focus(editor);
  }
};

export default addGraphics;
