import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
    height: '40px',
    '&$selected, &$selected:hover': {
      backgroundColor: theme.palette.dina.onSelected,
    },
    '&:hover': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
  },
  label: {
    ...theme.typography.dina.listItemLabel,
    fontWeight: 'normal',
    marginLeft: '16px',
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  textRoot: {
    flex: '0 1 auto',
  },
  selected: {},
}));

const ListItemView = ({ label, selected, onClick }) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      disableGutters
      classes={{ root: classes.listItem, selected: classes.selected }}
      {...{ selected, onClick }}
    >
      <ListItemText
        primary={label}
        classes={{ root: classes.textRoot, primary: classes.label }}
      />
    </ListItem>
  );
};

ListItemView.propTypes = {
  /** Label of List Item */
  label: PropTypes.string,
  /** Whether the list item is selected or not */
  selected: PropTypes.bool,
  /** onClick callback when list item used as button */
  onClick: PropTypes.func,
};

ListItemView.defaultProps = {
  label: '',
  selected: false,
  onClick: () => {},
};

export default ListItemView;
