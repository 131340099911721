import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignSelf: 'flex-start',
  },
  icon: {
    color: theme.palette.dina.mediumEmphasis,
    position: 'absolute',
    fontWeight: 300,
  },
  tabIcon: {
    marginRight: '9px',
  },
  iconButton: {
    position: 'relative',
    left: '-35px',
    marginRight: '-20px', // clear some negative space left by above
    height: '10px',
    top: '2px',
    width: '10px',
  },
  iconButtonLabel: {
    position: 'absolute',
  },
  labelTextWrap: {
    '&:first-child': {
      display: '-webkit-box',
      lineClamp: 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      marginRight: '10px',
      fontStyle: 'italic',
      fontWeight: 400,
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  labelTextWrapPinned: {
    '&:first-child': {
      display: '-webkit-box',
      lineClamp: 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      marginRight: '30px',
      lineHeight: '20px',
    },
  },
  notification: {
    position: 'relative',
    left: '-9px',
    top: '-2px',
  },
  tab: {
    marginTop: '4px',
    borderRadius: '8px 8px 0 0',
    minHeight: '44px',
    height: '44px',
  },
  tabHovered: {
    border: `1px solid ${theme.palette.dina.statusWarning}`,
  },
}));

export default useStyles;
