import React from 'react';
import PropTypes from 'prop-types';
import { MenuList, MenuItem, Paper } from '@material-ui/core';
import useStyles from './navbar-styles';
import menuItems from './menuItems';

function LeftVerticalNavbar(props) {
  const { toggleSidebar, selectionChanged, selectionType, hidden } = props;
  const classes = useStyles();

  const hideSidebar = () => {
    selectionChanged(null);
    toggleSidebar();
  };

  const showSidebar = val => {
    selectionChanged(val);
    toggleSidebar();
  };

  function onSelected(val) {
    if (selectionType === val) {
      hideSidebar();
    } else if (hidden) {
      showSidebar(val);
    } else {
      selectionChanged(val);
    }
  }

  return (
    <Paper className={classes.paper} square>
      <MenuList className={classes.menuList}>
        {menuItems.map(item => {
          const {
            title,
            selectionType: type,
            selectedImage,
            unselectedImage,
          } = item;
          const isSelected = selectionType === type;
          return (
            <MenuItem
              key={title}
              selected={isSelected}
              classes={{ root: classes.menuItem, selected: classes.selected }}
              title={title}
              onClick={() => onSelected(type)}
            >
              <img
                src={isSelected ? selectedImage : unselectedImage}
                alt={title}
              />
            </MenuItem>
          );
        })}
      </MenuList>
    </Paper>
  );
}

LeftVerticalNavbar.propTypes = {
  /** Call parent component function to hide the sidebar */
  toggleSidebar: PropTypes.func,
  /** Call parent component function to change the selection */
  selectionChanged: PropTypes.func,
  /** What should be selected */
  selectionType: PropTypes.string,
  /** Is the sidebar hidden */
  hidden: PropTypes.bool,
};

LeftVerticalNavbar.defaultProps = {
  toggleSidebar: () => {},
  selectionChanged: () => {},
  selectionType: null,
  hidden: false,
};

export default LeftVerticalNavbar;
