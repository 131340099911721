import {
  format,
  differenceInYears,
  differenceInMonths,
  differenceInWeeks,
  differenceInDays,
  isFirstDayOfMonth,
  getMonth,
} from 'date-fns';

/**
 * Determines the intended date format for timegrid axis
 *
 * @param {Object} xAxis D3 time scale
 * @returns {Function} Callback function for d3 x axis tickFormat
 */

const formatTicks = xAxis => (date, index, ticks) => {
  const tickValues = xAxis.scale().ticks();
  const firstTick = tickValues[0];
  const secondTick = tickValues[1];

  ticks[index].parentNode.firstChild.classList.remove('major');
  if (differenceInYears(secondTick, firstTick)) {
    return format(date, 'MMM YYYY');
  }

  if (differenceInMonths(secondTick, firstTick)) {
    if (getMonth(date) === 0) {
      ticks[index].parentNode.firstChild.classList.add('major');
    }
    return format(date, 'DD MMM');
  }

  if (differenceInWeeks(secondTick, firstTick)) {
    if (isFirstDayOfMonth(date)) {
      ticks[index].parentNode.firstChild.classList.add('major');
    }
    return format(date, 'DD MMM');
  }

  if (differenceInDays(secondTick, firstTick)) {
    if (isFirstDayOfMonth(date)) {
      ticks[index].parentNode.firstChild.classList.add('major');
    }
    if (differenceInDays(secondTick, firstTick) > 1) {
      return format(date, 'DD');
    }
    if (differenceInDays(secondTick, firstTick) === 1) {
      return format(date, 'ddd DD');
    }
    return format(date, 'DD');
  }

  const time = format(date, 'HH:mm');

  if (time === '00:00') {
    ticks[index].parentNode.firstChild.classList.add('major');
  }

  return time;
};

export default formatTicks;
