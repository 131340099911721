import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => {
  const buttonStyle = {
    ...theme.typography.dina.button,
    height: '40px',
    width: '144px',
    borderRadius: '4px',
    textTransform: 'none',
    fontWeight: 500,
  };
  return {
    root: {
      width: '572px',
      height: '440px',
      borderRadius: '8px',
      backdropFilter: 'blur(30px)',
      background: theme.palette.dina.surfaceCard,
      position: 'relative',
      boxSizing: 'border-box',
      boxShadow:
        '0px 12px 24px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    },
    triangle: {
      position: 'absolute',
      top: 0,
      right: 0,
      transform: 'translate3d(0, -50%, 0)',
      borderBottom: `16px solid ${theme.palette.dina.surfaceCard}`,
      borderLeft: `16px solid transparent`,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    scratchPad: {
      ...theme.typography.dina.h5,
      lineHeight: '28px',
      letterSpacing: 'normal',
      height: '64px',
      marginLeft: '16px',
      display: 'flex',
      alignItems: 'center',
    },
    divider: {
      height: '1px',
      backgroundColor: theme.palette.dina.dividerLight,
    },
    cardContainer: {
      flexGrow: 1,
      display: 'flex',
      flexWrap: 'wrap',
      margin: '8px',
      overflow: 'auto',
    },
    footer: {
      height: '56px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '8px',
    },
    formControlLabel: {
      marginLeft: '16px',
    },
    card: {
      margin: '8px',
      height: '133px',
    },
    label: {
      ...theme.typography.dina.captionRegular,
      color: theme.palette.dina.highEmphasis,
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '8px',
    },
    cancelButton: {
      ...buttonStyle,
      margin: '8px 4px 8px 8px',
      color: theme.palette.dina.mediumEmphasis,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        color: theme.palette.dina.highEmphasis,
      },
      '&:focus': {
        boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
        color: theme.palette.dina.highEmphasis,
      },
    },
    okButton: {
      ...buttonStyle,
      margin: '8px 8px 8px 4px',
      backgroundColor: theme.palette.dina.statusApproved,
      '&:hover': {
        backgroundColor: theme.palette.dina.statusApproved,
      },
      '&:focus': {
        boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
      },
    },
  };
});

export default useStyles;
