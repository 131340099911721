import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';
import { useQuery, useMutation, useApolloClient } from 'react-apollo';
import { difference, isEqual, isNull } from 'lodash';
import memberTypes from 'graphql/memberTypes';
import useDidMount from 'hooks/useDidMount';
import { useLoadingIndicator } from 'contexts/LoadingContext';
import { UsersContext } from 'globalState/users';
import COPY_INSTANCE from 'graphql/mutations/moveInstanceToRundown';
import GET_USER from 'graphql/queries/getUser';
import { getMemberQuery } from 'graphql/queryVariables';
import GET_CONTENT_TEMPLATES from 'graphql/queries/getContentTemplates';
import UPDATE_RUNDOWN_SYNC from 'graphql/mutations/updateRundownSync';
import fallBackImage from 'assets/images/default/defaultThumbnail.png';
import GET_FOLDERS from 'graphql/queries/getFolders';
import UPDATE_INSTANCE from 'graphql/mutations/updateInstance';
import CREATE_RUNDOWN from 'graphql/mutations/createRundownFromTemplate';
import useLockInstance from 'hooks/useLockInstance';
import useUnlockInstance from 'hooks/useUnlockInstance';
import useTextStorage from 'hooks/useTextStorage';
import useDeleteTemplate from 'hooks/useDeleteTemplate';
import useCreateTemplate from 'hooks/useCreateTemplate';
import useCreateFolder from 'hooks/useCreateFolder';
import useDeleteFolder from 'hooks/useDeleteFolder';
import useCreateAsset from 'hooks/useCreateAsset';
import useCreatePlaceholder from 'hooks/useCreatePlaceholder';
import useRemovePlaceholder from 'hooks/useRemovePlaceholder';
import useAssignInstance from 'hooks/useAssignInstance';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useArchiveMember from 'hooks/useArchiveMember';
import useSettingsValue from 'hooks/useSettingsValue';
import useUpdateDurationMeta from 'hooks/useUpdateDurationMeta';
import useVersionHistory from 'hooks/useVersionHistory';
import { uploadToS3 } from 'utils/s3Utils';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import { getUserLockToken, getUserIdFromLockedId } from 'utils/lock/lockToken';
import { CurrentTabContext } from 'globalState';
import useMoveInstanceToRundown from 'hooks/useMoveInstanceToRundown';
import useUpdateInstanceMetaData from 'hooks/useUpdateInstanceMetadata';
import UserCtx from 'contexts/UserContext';
import { getAssetData } from 'utils/assetData';
import { isOlderSlateValue, migrateValue } from 'components/editor';
import Editor from './editor-view';
import { filterEditorMeta, isBeforeToday } from './utils';
import respectHostReadSpeed from '../../utils/respectHostReadSpeed';

const findIndexInArray = item => array => (array ? array.indexOf(item) : -1);

const EditorContainer = ({
  hostReadRate,
  rundownId,
  refId,
  selecteddate,
  currentInstance,
  data: currentRundown,
  rundownRefetch,
  form,
  ...rest
}) => {
  const [templateContentKey, setTemplateContentKey] = useState();
  const {
    mId: insId,
    mContentKey,
    mDefaultContentKey,
    mThumbnailUrl: strThumbnail,
  } = currentInstance;
  const currentInstanceRef = useRef(null);
  const previousIdRef = useRef();
  const previousLockedRef = useRef();
  const [showMetadata, setShowMetadata] = useState(false);
  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);
  const { setLoadingIndicatorFor } = useLoadingIndicator();
  const [editorData, setEditorData] = useState(null);
  const [shouldResetEditorSelection, setShouldResetEditorSelection] = useState(false);

  const { data: s3Data, loading, refetch } = useTextStorage(
    mContentKey,
    showMetadata,
    mDefaultContentKey,
  );
  const editorValueRef = useRef(s3Data);

  const [updateInstanceMutation] = useMutation(UPDATE_INSTANCE);
  const [duplicateInstanceToRundown] = useMutation(COPY_INSTANCE);
  const [showDialog, setShowDialog] = useState(false);
  const [instanceMoveArgs, setInstanceMoveArgs] = useState({});
  const [, setCurrentTab] = useContext(CurrentTabContext);
  const { users } = useContext(UsersContext);
  const [assignees, setAssignees] = useState([]);
  const client = useApolloClient();
  const { mPublishingAt, mThumbnailUrl: rndThumbnail } = currentRundown;
  const currentDestination = {
    id: currentRundown.mId,
    title: currentRundown.mTitle,
    value: currentRundown.mId,
  };

  const [createFolder] = useCreateFolder();
  const [deleteFolder] = useDeleteFolder();
  const [createTemplate] = useCreateTemplate();
  const [deleteTemplate] = useDeleteTemplate();
  const [lockInstance] = useLockInstance();
  const [unlockInstance] = useUnlockInstance();
  const [saveMetaData] = useUpdateInstanceMetaData();
  const [createStoryAsset] = useCreateAsset();
  const [createPlaceholder] = useCreatePlaceholder();
  const [removePlaceholder] = useRemovePlaceholder();
  const [addUsersToInstance] = useAssignInstance();
  const [checkUserRight] = useCheckUserRight();
  const [getSettingsValue] = useSettingsValue();

  const canCreateNewTemplate = checkUserRight('instance', 'create-template');
  const canDeleteTemplate = checkUserRight('instance', 'delete-template');
  const canDeleteTemplateFolder = checkUserRight('instance', 'delete-template-folder');
  const canEditReadyInstance = checkUserRight('rundown', 'edit-ready-instances');
  const canShowNewDesign = checkUserRight('feature', 'new-some-design');
  const canSeeVersionHistory = checkUserRight('feature', 'version-history');

  const placeholderFormat = getSettingsValue('mam.placeholderName');

  const user = useContext(UserCtx);
  const { mId: userId } = user;

  const [readLock, setReadLock] = useState(false);
  const [writeLock, setWriteLock] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const didMount = useDidMount();

  const blankMetaData = getEmptyMetadataForForm(form);

  const [handleDurationFieldsChange] = useUpdateDurationMeta(blankMetaData, () => {});

  const resetEditorValue = useCallback(newValue => {
    if (newValue && !isEqual(newValue, editorData )) {
      const value = isOlderSlateValue(newValue) ? migrateValue(newValue) : newValue;
      setEditorData(value);
      editorValueRef.current = value;
    } else if (isNull(newValue)) {
      setEditorData(null);
      editorValueRef.current = null;
    }
  }, [editorData]);

  const fetchContent = useCallback(
    async url => {
      if (url) {
        const response = await fetch(url);
        const updatedContent = await response.json();
        resetEditorValue(updatedContent);
      }
    },
    [resetEditorValue],
  );

  useEffect(() => {
    if (s3Data) resetEditorValue(s3Data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Data]);

  const { 
    versions, 
    currentVersionContent, 
    refetchVersionList, 
    refetchVersionContent,
    auditListLoading,
    versionContentLoading,
  } = useVersionHistory(currentInstance.mId, users);

  const [lockedByCurrentUser, setLockedByCurrentUser] = useState(false);


  const onRestoreVersion = useCallback(
    async (content) => {
      resetEditorValue(content);
      {
        const cInstance = currentInstance;
        const value = content;
        const unlock = true;
        setIsSavingContent(true);
        await saveContent(value, cInstance.mContentKey);
        
        const items = filterEditorMeta(value.document);
        const metadata = handleDurationFieldsChange(value, hostReadRate, cInstance.mMetaData);
        await handleUpdateMetadata(metadata, cInstance, items, unlock, value);
        setIsSavingContent(false);
        setHasChanges(false);
      }
      updateLock();
    },
    [
      writeLock, readLock, releaseWriteLock, onLockInstance, resetEditorValue
    ],
  );

  const checkVersionRestorability = async (content) => {
    if(lockedByCurrentUser) return true;
    const lockedId = await onLockInstance();
    return lockedId === getUserLockToken(userId);
  }

  const getLockedByUser = useCallback(
    async lockedId => {
      return lockedId &&
        (await client
          .query({
            query: GET_USER,
            variables: getMemberQuery(getUserIdFromLockedId(lockedId)),
          })
          .then(result => {
            setLockedByUser(
              result.data && result.data.getMember ? result.data.getMember.mTitle : '-',
            );
            return result.data.getMember.mTitle;
          }));
    },
    [client],
  );

  const updateLock = useCallback(
    lockedId => {
      if (lockedId) {
        setWriteLock(lockedId === getUserLockToken(userId));
        setReadLock(lockedId !== getUserLockToken(userId));
        setLockedByCurrentUser(lockedId === getUserLockToken(userId))
        lockedId !== getUserLockToken(userId) && getLockedByUser(lockedId);
      } else {
        window.requestAnimationFrame(() => {
          setWriteLock(false);
          setReadLock(false);
        });
      }
    },
    [getLockedByUser, userId],
  );

  const onUnlockInstance = useCallback(
    (instanceId) => {
      unlockInstance(instanceId, editorValueRef.current).then(() => {
        previousLockedRef.current = null;
        updateLock();
      });
    },
    [unlockInstance, updateLock],
  );

  const saveContent = useCallback(async (content, newKey) => {
    const key = newKey || (currentInstanceRef.current && currentInstanceRef.current.mContentKey);

    if (!key) return;
    const file = new window.File([JSON.stringify(content)], 'content.data', {
      type: 'text/plain',
    });
    try {
      await uploadToS3(key, file);
    } catch (error) {
      // console.log(error);
    }
  }, []);

  const handleUpdateMetadata = useCallback(
    async (newFields, cInstance, items, unlock, content) => {
      if (cInstance) {
        const instanceId = cInstance.mId;
        const updatedFields = (newFields || []).map(({ key, value }) => ({
          key,
          value,
        }));

        const metadata = respectHostReadSpeed(updatedFields, hostReadRate);
        await saveMetaData(instanceId, metadata, null, null, items, unlock, content);
      }
    },
    [hostReadRate, saveMetaData],
  );

  const saveAll = useCallback(
    async (value, cInstance, unlock) => {
      if (!loading && cInstance && writeLock) {
        setIsSavingContent(true);
        
        await saveContent(value, cInstance.mContentKey);
        
        const items = filterEditorMeta(value.document);
        const metadata = handleDurationFieldsChange(value, hostReadRate, cInstance.mMetaData);
        await handleUpdateMetadata(metadata, cInstance, items, unlock, value);
        
        setIsSavingContent(false);
        setHasChanges(false);
      }
    },
    [
      loading,
      writeLock,
      saveContent,
      handleUpdateMetadata,
      handleDurationFieldsChange,
      hostReadRate,
    ],
  );

  const releaseWriteLock = useCallback(
    async () => {
      if (editorValueRef.current) {
        await saveAll(
          editorValueRef.current,
          { ...currentInstance },
          true,
        );
        onUnlockInstance(currentInstance.mId);
        resetEditorValue(editorValueRef.current)
      } else onUnlockInstance(currentInstance.mId);
    },
    [currentInstance, onUnlockInstance, resetEditorValue, saveAll],
  );

  const onCurrentInstanceIdLockedChange = () => {
    if (didMount) {
      const { mId, locked } = currentInstance;
      refetch();
      updateLock(locked);
      locked && getLockedByUser(currentInstance.locked);

      if (
        previousIdRef.current === mId &&
        previousLockedRef.current === getUserLockToken(userId) &&
        locked !== getUserLockToken(userId)
      ) {
        if (editorValueRef.current) saveContent(editorValueRef.current);
      }

      if (previousIdRef.current !== mId) {
        previousLockedRef.current === getUserLockToken(userId) &&
          onUnlockInstance(previousIdRef.current);

        // if (editorValue) saveContent(editorValue);
        currentInstanceRef.current = currentInstance;
        resetEditorValue(null);
        setTemplateContentKey(null);
      }
    }
    previousIdRef.current = currentInstance.mId;
    previousLockedRef.current = currentInstance.locked;
  };

  useEffect(onCurrentInstanceIdLockedChange, [
    currentInstance.mId,
    currentInstance.locked,
    currentInstance.mContentKey,
  ]);

  const {
    loading: templateDataLoading,
    data: templateData,
    refetch: refetchTemplate,
  } = useTextStorage(templateContentKey, false);

  const setAssigneeData = assigneeIds => {
    const assigneeData = (users && users.filter(p => assigneeIds.includes(p.mId))) || [];
    setAssignees(assigneeData);
  };

  useEffect(() => {
    setAssigneeData(
      (currentInstance.mAssignedMembers && currentInstance.mAssignedMembers.map(m => m.mId)) || [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInstance, users]);

  const [lockedByUser, setLockedByUser] = useState();

  const { data: foldersData } = useQuery(GET_FOLDERS);
  const folders = foldersData && foldersData.getFolders ? foldersData.getFolders : [];

  const createAsset = async (storyId, assetData) => {
    const mId = storyId || rundownId;
    const asset = getAssetData(mId, assetData);

    const result = await createStoryAsset(mId, asset, true);

    return result;
  };

  const handleCreatePlaceholder = useCallback(
    async title => {
      const { mStoryId, mProperties } = currentInstance;

      const { mId, mRefId } = await createPlaceholder(mStoryId || rundownId, {
        mTitle: title,
        mProperties,
      });

      return { title, mId, mRefId };
    },
    [createPlaceholder, currentInstance, rundownId],
  );

  const onAssigneeUpdate = async updatedAssignees => {
    const existingIds = assignees.map(({ mId }) => mId);
    const updatedIds = updatedAssignees.map(({ mId: id }) => id);

    const removedIds = difference(existingIds, updatedIds);
    const addedIds = difference(updatedIds, existingIds);
    await addUsersToInstance(currentInstance.mId, addedIds, removedIds, updatedIds);

    setAssigneeData(updatedIds);
  };

  const { data: templateContents } = useQuery(GET_CONTENT_TEMPLATES, {
    variables: { mId: rundownId },
    fetchPolicy: 'cache-and-network',
  });

  let templates = [];

  if (templateContents && templateContents.getContentTemplates)
    templates = templateContents.getContentTemplates.map(
      ({ mRefId, mTitle, mContentKey: storageKey }) => ({
        id: mRefId,
        name: mTitle,
        storageKey,
      }),
    );

  const onInstanceMoved = () => {
    // eslint-disable-next-line no-param-reassign
    currentInstance = { mId: '-', mRefId: '-', mTitle: 'Blank' };
  };

  const [moveInstanceToRundown] = useMoveInstanceToRundown(onInstanceMoved);

  const onMoveRundownInstance = (instance, sourceRundown, targetRundown) => {
    const { mOrder } = currentRundown;
    const isInMOrder = mOrder.find(val => val === currentInstance.mId);
    // Always copy instances that are in mOrder of past rundowns, otherwise move
    const copy = isInMOrder && isBeforeToday(sourceRundown.mPublishingAt);

    sourceRundown.mRefId !== targetRundown.mRefId &&
      !isBeforeToday(targetRundown.mPublishingAt) &&
      moveInstanceToRundown(instance, sourceRundown, targetRundown, copy).then(r => {
        setShowDialog(r);
        r &&
          setInstanceMoveArgs({
            instance,
            sourceRundown,
            targetRundown,
            copy,
          });
      });
  };

  const [createRundownMutation] = useMutation(CREATE_RUNDOWN);
  const onCreateNewRundown = mTitle => {
    const { instance, sourceRundown, targetRundown, copy } = instanceMoveArgs;
    const { mId, mRefId } = targetRundown;
    const variables = { input: { mId, mRefId, mTitle } };
    createRundownMutation({ variables }).then(r => {
      !r.loading && moveInstanceToRundown(instance, sourceRundown, targetRundown, copy);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetEditorValue(null), [mContentKey]);

  const [isSavingContent, setIsSavingContent] = useState(false);

  const onLockInstance = async () => {
    if (!writeLock && !readLock) {
      updateLock(getUserLockToken(userId));
      // eslint-disable-next-line no-param-reassign
      currentInstance.locked = getUserLockToken(userId);
      const result = await lockInstance(currentInstance.mId, userId);
      if (result && result.data && result.data.lockMember) {
          const { locked, mContentUrl } = result.data.lockMember;
          await fetchContent(mContentUrl);
          updateLock(locked);
          await getLockedByUser(locked);
          return locked;
        } else updateLock();
    }
  };

  const onForceUnlock = () => {
    unlockInstance(currentInstance.mId);
  };

  const updateRundownInstanceTitle = (instanceId, instanceTitle) => {
    const input = {
      mId: instanceId,
      mTitle: instanceTitle,
    };

    updateInstanceMutation({
      variables: {
        input,
      },
    });
  };

  const onStatusChange = async (instanceId, newState) => {
    try {
      await updateInstanceMutation({
        variables: {
          input: {
            mId: instanceId,
            mState: newState,
          },
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const [debouncedSaveAll] = useDebouncedCallback(saveAll, 3000);

  const onChange = useCallback(
    (value, updatedInstance) => {
      if (writeLock) {
        setHasChanges(true);
        editorValueRef.current = value;
        debouncedSaveAll(value, updatedInstance);
      }
    },
    [debouncedSaveAll, writeLock],
  );

  const onTemplateInsert = useCallback(
    (isLoading, data) => {
      if (!isLoading && data) {
        const template = isOlderSlateValue(data) ? migrateValue(data) : data;

        resetEditorValue(template);
        setTemplateContentKey(null);

        const metadata = template.metadata || currentInstance.mMetaData;
        const instanceWithTemplateMetadata = {
          ...currentInstance,
          mMetaData: metadata,
        };

        
        // this is required to save all template metadata fields
        const items = filterEditorMeta(template.document);
        handleUpdateMetadata(metadata, currentInstance, items);
        
        saveAll(template, instanceWithTemplateMetadata);
      }
    },
    [resetEditorValue, currentInstance, saveAll, handleUpdateMetadata],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onTemplateInsert(templateDataLoading, templateData), [
    templateDataLoading,
    templateData,
  ]);

  const onSaveTemplate = useCallback(
    async (folderId, title, overwriteData, metadata) => {
      if (editorValueRef.current) {
        const template = metadata
          ? { ...editorValueRef.current, metadata }
          : editorValueRef.current;

        const file = new window.File([JSON.stringify(template)], 'content.data', {
          type: 'text/plain',
        });

        if (overwriteData) {
          try {
            uploadToS3(overwriteData.mContentKey, file);
          } catch (err) {
            // console.log(err);
          }
        } else {
          try {
            const result = await createTemplate(folderId, title);
            uploadToS3(result.data.createContentTemplate.mContentKey, file);
          } catch (err) {
            // console.log(err);
          }
        }
      }
    },
    [createTemplate],
  );

  const onSelectTemplate = ({ mContentKey: storageKey }) => {
    if (templateContentKey === storageKey) refetchTemplate();
    else {
      setTemplateContentKey(storageKey);
    }
  };

  const onCreateDuplicate = () => {
    const returnIndex = findIndexInArray(currentInstance.mId);
    const { mOrder, mPreorder } = currentRundown;
    const { type } = currentInstance;
    const position = type === 'ready' ? returnIndex(mOrder) + 1 : returnIndex(mPreorder) + 1;

    const input = {
      mId: currentInstance.mId,
      targetRundown: {
        mId: rundownId,
        mRefId: refId,
      },
      orderType: type,
      index: position,
      copy: true,
    };

    setLoadingIndicatorFor(type);

    duplicateInstanceToRundown({
      variables: {
        input,
      },
      update: (proxy, mutationResult) => {
        const newInstance = mutationResult.data.moveInstanceToRundown;
        const variables = {
          input: { mId: rundownId, mRefId: rundownId },
        };
        const order = type === 'ready' ? 'mOrder' : 'mPreorder';
        const payloadInput = {
          crudAction: 'NONE',
          value: {
            mId: newInstance.mId,
            index: position,
            destination: order,
          },
        };
        const payloads = [payloadInput];
        variables.input.mPayload = payloads;
        updateRundownSync({
          variables,
          optimisticResponse: false,
        }).catch(e => {
          // eslint-disable-next-line no-console
          console.log(e);
        });
      },
    }).then(() => {
      setTimeout(() => setLoadingIndicatorFor(null), 1000);
    });
  };

  const onOpenStory = () => {
    setCurrentTab({
      page: memberTypes.STORY,
      title: currentInstance.mTitle,
      id: currentInstance.mStoryId,
      image: currentInstance.mStoryId,
    });
  };

  const [archiveMember] = useArchiveMember();

  const onDeleteInstance = async () => {
    await archiveMember(
      currentInstance.mId,
      memberTypes.instanceMemberTypes,
      currentInstance.mProperties.platform === 'linear' ? currentDestination.id : null,
    );
    rundownRefetch();
  };

  return (
    <Editor
      backgroundImage={strThumbnail || rndThumbnail || fallBackImage}
      createPlaceholder={handleCreatePlaceholder}
      instance={currentInstance}
      onCreateFolder={createFolder}
      onDeleteFolder={deleteFolder}
      onDeleteTemplate={deleteTemplate}
      onTitleUpdate={updateRundownInstanceTitle}
      onUpdatingRundownInstance={onMoveRundownInstance}
      writeLock={!loading && writeLock}
      editorValue={editorData}
      {...{
        assignees,
        canShowNewDesign,
        canCreateNewTemplate,
        canDeleteTemplate,
        canDeleteTemplateFolder,
        canEditReadyInstance,
        canSeeVersionHistory,
        createAsset,
        currentDestination,
        currentInstance,
        folders,
        form,
        handleUpdateMetadata,
        hasChanges,
        hostReadRate,
        isSavingContent,
        loading,
        lockedByUser,
        mPublishingAt,
        onAssigneeUpdate,
        onChange,
        onCreateDuplicate,
        onCreateNewRundown,
        onDeleteInstance,
        onForceUnlock,
        onLockInstance,
        onOpenStory,
        onSaveTemplate,
        onSelectTemplate,
        onStatusChange,
        onUnlockInstance,
        readLock,
        refId,
        releaseWriteLock,
        removePlaceholder,
        rundownId,
        saveContent,
        selecteddate,
        setShowDialog,
        setShowMetadata,
        shouldResetEditorSelection,
        showDialog,
        showMetadata,
        templates,
        userId,
        users,
        placeholderFormat,
        versions,
        currentVersionContent,
        refetchVersionList, 
        refetchVersionContent,
        auditListLoading,
        versionContentLoading,
        onRestoreVersion,
        checkVersionRestorability,
      }}
      {...rest}
    />
  );
};

export default EditorContainer;
