import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  contentContainer: {
    marginLeft: '16px',
    marginRight: '12px',
  },
  label: {
    ...theme.typography.dina.overline,
    lineHeight: '12px',
    letterSpacing: '1px',
    color: theme.palette.dina.highEmphasis,
    paddingTop: '6px',
  },
  container: {
    height: ({ hasInstance, height }) => (hasInstance ? height / 2 : height),
  },
}));

export default useStyles;
