import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ICONS from 'assets/icons/icons';
import IconButton from 'components/buttons/iconButton';
import CreateNew from 'components/createNew';
import ToggleButton from 'components/toolbar/toolbarButton';
import FilterButton from 'components/toolbar/toolbarChipButton';
import Popover from 'components/popover';
import useStyles from './toolbar-styles';

const FeedsToolbar = props => {
  const {
    selectedSource,
    setSelectedSource,
    sources,
    filters,
    selectedFilter,
    setSelectedFilter,
    onCreateFilter,
    updateSavedFilters,
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const toggleSource = label => {
    if (selectedSource === label) {
      setSelectedSource('');
    } else {
      setSelectedSource(label);
    }
  };

  const toggleFilter = filter => {
    if (selectedFilter && selectedFilter.label === filter.label) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(filter);
    }
  };

  const sourceLabels = sources.map(source => source.mRefId);

  return (
    <div className={classes.toolbar}>
      {/** SOURCES */}
      <div className={classes.leftComponents}>
        {sourceLabels.map(label => {
          return (
            <ToggleButton
              title="Feed provider"
              key={label}
              selected={selectedSource === label}
              onClick={() => toggleSource(label)}
              icon={ICONS.SOURCE}
            >
              {label}
            </ToggleButton>
          );
        })}
      </div>
      {/** FILTERS */}
      <div className={classes.rightComponents}>
        {filters.map(filter => {
          return (
            <FilterButton
              title="Custom filter"
              key={filter.label}
              label={filter.label}
              onClick={() => toggleFilter(filter)}
              icon={ICONS.BOOKMARK_SEARCH_OFF}
              onDelete={() =>
                updateSavedFilters(filters.filter(f => f !== filter))
              }
              selected={selectedFilter && selectedFilter.label === filter.label}
            />
          );
        })}

        <IconButton
          onClick={e => setAnchorEl(e.currentTarget.parentNode)}
          className={classes.addButton}
          icon={ICONS.PLUS}
          height={24}
          width={24}
          title="Save filter"
        />
        <Popover onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
          <CreateNew
            onCancel={() => setAnchorEl(null)}
            onCreate={label => {
              onCreateFilter(label);
              setAnchorEl(null);
            }}
            variant="feedFilter"
          />
        </Popover>
      </div>
    </div>
  );
};

FeedsToolbar.propTypes = {
  /** Custom filters that should be displayed in toolbar */
  filters: PropTypes.arrayOf(PropTypes.object),
  /** Function for adding new a new filter */
  onCreateFilter: PropTypes.func,
  /** The filter that is currently selected */
  selectedFilter: PropTypes.shape({
    label: PropTypes.string,
    expression: PropTypes.arrayOf(PropTypes.string),
  }),
  /** The source that is selected */
  selectedSource: PropTypes.string,
  /** Set a new selected filter */
  setSelectedFilter: PropTypes.func,
  /** Set a new selected source */
  setSelectedSource: PropTypes.func,
  /** sources/providers */
  sources: PropTypes.arrayOf(PropTypes.object),
  /** update saved filters, used when a filter is removed */
  updateSavedFilters: PropTypes.func,
};

FeedsToolbar.defaultProps = {
  filters: [],
  onCreateFilter: () => {},
  selectedFilter: null,
  selectedSource: '',
  setSelectedFilter: () => {},
  setSelectedSource: () => {},
  sources: [],
  updateSavedFilters: () => {},
};

export default FeedsToolbar;
