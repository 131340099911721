// eslint-disable-next-line max-len
import twitter from './systemicons/publishing_points/twitter.svg';
// eslint-disable-next-line max-len
import linear from './systemicons/publishing_points/tv.svg';
// eslint-disable-next-line max-len
import facebook from './systemicons/publishing_points/facebook.svg';
// eslint-disable-next-line max-len
import cms from './systemicons/publishing_points/cms.svg';
// eslint-disable-next-line max-len
import share from './systemicons/HeaderNavbar/todo_on.svg';
// eslint-disable-next-line max-len

const notifications = {
  twitter,
  linear,
  facebook,
  cms,
  share,
};

const other = {
  twitter,
  linear,
  facebook,
  cms,
};

export { notifications, other };
