import React from 'react';
import { makeStyles } from '@material-ui/styles';
import loadingSpinner from 'assets/images/loadingSpinner/dina-loader-anpng-60frames.png';

const useStyles = makeStyles(() => ({
  img: {
    position: 'absolute',
    left: 'calc(50% - 32px)',
    top: 'calc(50% - 32px)',
    height: '64px',
    width: '64px',
  },
}));

const LoadingIndicator = () => {
  const classes = useStyles();

  return <img src={loadingSpinner} alt="spinner" className={classes.img} />;
};

export default LoadingIndicator;
