import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    opacity: '0.54',
    marginRight: '4px',
    marginLeft: '8px',
  },
  divider: {
    margin: '8px 16px',
    padding: '0 16px',
  },
}));

export default useStyles;
