import getIndicatorNewPosition from '../../functions/getIndicatorNewPosition';

const animateIndicator = (indicatorNode, sliderWrapper, dayWidth) => {
  const newItems = Array.from(document.querySelectorAll('.selected-day'));
  const newItemsLength = newItems.length;
  if (newItemsLength > 0) {
    newItems.forEach(newItem => {
      newItem.classList.remove('static-selected');
      newItem.classList.add('animated-selected');
    });

    const firstItem = newItems[0];
    const newItemPosition = getIndicatorNewPosition(
      firstItem,
      indicatorNode,
      sliderWrapper
    );

    const left = newItemPosition + (dayWidth - 40) / 2;
    const width =
      newItemsLength === 1 ? 40 : newItemsLength * dayWidth - (dayWidth - 40);

    Object.assign(indicatorNode.style, {
      visibility: 'visible',
      left: `${left}px`,
      width: `${width}px`,
    });
  }
};

export default animateIndicator;
