import React, { useContext } from 'react';
import { debounce, difference } from 'lodash';
import { useQuery, useMutation } from 'react-apollo';
import UserCtx from 'contexts/UserContext';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import memberTypes from 'graphql/memberTypes';
import GET_DEPARTMENT_MEMBERS from 'graphql/queries/getDepartmentMembers';
import UPDATE_DEPARTMENT from 'graphql/mutations/updateDepartment';
import GET_DEPARTMENT from 'graphql/queries/getDepartments';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import {
  getMembersOfQuery,
  getMembersOfTypeQuery,
} from 'graphql/queryVariables';
// import ADD_MEMBERS from 'graphql/mutations/addMembers';
import UPDATE_MEMBERS from 'graphql/mutations/updateMembers';
import InfoView from './info-view';

const InfoContainer = props => {
  const { id, setNoOfTeams } = props;
  const {
    USER,
    DEPARTMENT,
    DEPARTMENT_USER,
    DEPARTMENT_TEAM,
    TEAM,
  } = memberTypes;
  const user = useContext(UserCtx);
  const [updateMembers] = useMutation(UPDATE_MEMBERS);
  const [updateDepartment] = useMutation(UPDATE_DEPARTMENT);

  const {
    data: getDepartmentTeamsData,
    loading: teamsLoading,
    error: teamsError,
  } = useQuery(GET_DEPARTMENT_MEMBERS, {
    variables: { input: { mType: DEPARTMENT_TEAM, mId: id } },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: getDepartmentUserData,
    loading: usersLoading,
    error: usersError,
  } = useQuery(GET_DEPARTMENT_MEMBERS, {
    variables: { input: { mType: DEPARTMENT_USER, mId: id } },
    fetchPolicy: 'cache-and-network',
  });

  if (teamsLoading || usersLoading) return <LoadingIndicator />;
  if (teamsError || usersError)
    return `Error loading department teams or users!`;

  const { getMembers: getTeams } = getDepartmentTeamsData;
  const { getMembers: getPeople } = getDepartmentUserData;

  setTimeout(() => {
    setNoOfTeams(getTeams.length);
  });

  const updateDescription = debounce(newDescription => {
    const input = {
      mId: id,
      mDescription: newDescription,
    };

    updateDepartment({
      variables: {
        input,
      },
    });
  }, 300);

  const addTeamsToDepartment = async updatedTeams => {
    const existingTeamIds = getTeams.map(({ mId }) => mId);
    const updatedTeamIds = updatedTeams.map(({ mId }) => mId);
    const removedTeamIds = difference(existingTeamIds, updatedTeamIds);
    const addedTeamIds = difference(updatedTeamIds, existingTeamIds);

    const addMembersInput = addedTeamIds.map(mId => ({
      mId: id,
      mRefId: mId,
      mType: DEPARTMENT_TEAM,
    }));

    const newMembers = {
      members: addMembersInput,
    };

    const removedMembersInput = removedTeamIds.map(mId => ({
      mId: id,
      mRefId: mId,
    }));

    const removedMembers = {
      members: removedMembersInput,
    };

    try {
      await updateMembers({
        variables: { newMembers, removedMembers },
        update: proxy => {
          const newMembersWithType = updatedTeams.map(member => ({
            ...member,
            mType: TEAM,
          }));

          proxy.writeQuery({
            query: GET_DEPARTMENT_MEMBERS,
            variables: {
              input: { mType: DEPARTMENT_TEAM, mId: id },
            },
            data: {
              getMembers: newMembersWithType,
            },
          });
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const getUpdatedDepartmentList = (list, department,
    isCurrentMemberAdded, isCurrentMemberRemoved) => {
    if (isCurrentMemberAdded) {
      list.push({ ...department, mType: DEPARTMENT });
      return list;
    }
    if (isCurrentMemberRemoved) {
      return list.filter(d => d.mId !== department.mId)
    }
    return list;
  }

  const updateCurrentUserDepartmentList = (
    proxy,
    isCurrentMemberAdded,
    isCurrentMemberRemoved,
  ) => {
    try {
      if (!(isCurrentMemberAdded || isCurrentMemberRemoved)) return;

      const departmentList = proxy.readQuery({
        query: GET_MEMBERS_OF,
        variables: getMembersOfQuery(user.mId, DEPARTMENT_USER),
      });
      const dList = departmentList.getMembersOf.filter(
        department => department !== null && department.mId === id,
      );
      if (dList.length !== 0 && !isCurrentMemberRemoved) return;

      const departments = proxy.readQuery({
        query: GET_DEPARTMENT,
        variables: getMembersOfTypeQuery(DEPARTMENT),
      });

      const department = departments.getMembersOftype.find(d => d.mId === id);
      if (!department) return;
      const updatedList = getUpdatedDepartmentList(departmentList.getMembersOf, department,
        isCurrentMemberAdded, isCurrentMemberRemoved);

      proxy.writeQuery({
        query: GET_MEMBERS_OF,
        variables: getMembersOfQuery(user.mId, DEPARTMENT_USER),
        data: {
          getMembersOf: updatedList,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const addPeopleToDepartment = async updatedPeople => {
    const existingIds = getPeople.map(({ mId }) => mId);
    const updatedIds = updatedPeople.map(({ mId }) => mId);
    const removedIds = difference(existingIds, updatedIds);
    const addedIds = difference(updatedIds, existingIds);

    const addMembersInput = addedIds.map(mId => ({
      mId: id,
      mRefId: mId,
      mType: DEPARTMENT_USER,
    }));

    const newMembers = {
      members: addMembersInput,
    };

    const removedMembersInput = removedIds.map(mId => ({
      mId: id,
      mRefId: mId,
    }));

    const removedMembers = {
      members: removedMembersInput,
    };

    const isCurrentMemberAdded = addedIds.includes(user.mId);
    const isCurrentMemberRemoved = removedIds.includes(user.mId);

    try {
      await updateMembers({
        variables: { newMembers, removedMembers },

        update: proxy => {
          const newMembersWithType = updatedPeople.map(member => ({
            ...member,
            mType: USER,
          }));

          updateCurrentUserDepartmentList(
            proxy,
            isCurrentMemberAdded,
            isCurrentMemberRemoved,
          );

          proxy.writeQuery({
            query: GET_DEPARTMENT_MEMBERS,
            variables: {
              input: { mType: DEPARTMENT_USER, mId: id },
            },
            data: {
              getMembers: newMembersWithType,
            },
          });
        },
      });
    } catch (err) {
      // error handling
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  return (
    <InfoView
      {...props}
      teams={getTeams}
      people={getPeople}
      {...{ updateDescription, addTeamsToDepartment, addPeopleToDepartment }}
    />
  );
};

export default InfoContainer;
