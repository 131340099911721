import { useMutation } from 'react-apollo';
import CREATE_CONTENT_TEMPLATE from 'graphql/mutations/createContentTemplate';
import GET_FOLDERS from 'graphql/queries/getFolders';

const useCreateStory = () => {
  const [createTemplateMutation] = useMutation(CREATE_CONTENT_TEMPLATE);

  const create = async (mId, mTitle) => {
    const input = {
      mId,
      mTitle,
    };
    const result = await createTemplateMutation({
      variables: {
        input,
      },
      update: (proxy, mutationResult) => {
        const { createContentTemplate: createdTemplate } = mutationResult.data;
        try {
          const folderList = proxy.readQuery({
            query: GET_FOLDERS,
          });
          const newFolderList = folderList.getFolders.map(folder => {
            if (
              folder.mRefId === mId &&
              !folder.items.find(item => item.mRefId === createdTemplate.mRefId)
            ) {
              folder.items.push(createdTemplate);
            }
            return folder;
          });

          proxy.writeQuery({
            query: GET_FOLDERS,
            data: { getFolders: newFolderList },
          });
        } catch (err) {
          console.error(err);
        }
      },
    });
    return result;
  };

  return [create];
};

export default useCreateStory;
