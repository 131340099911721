import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    maxWidth: '327px',
    padding: 0,
    overflow: 'hidden',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    display: 'flex',
    flexDirection: 'column',
  },
  sidebarHeader: {
    height: '40px',
    minHeight: '40px',
    margin: '0 0 0 12px',
    paddingBottom: '4px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  sidebarHeaderText: {
    ...theme.typography.dina.h5,
    lineHeight: '28px',
    color: theme.palette.dina.mediumEmphasis,
  },
  sidebarContent: {
    height: '100%',
    // overflowY: 'auto',
    // overflowX: 'hidden',
    // padding: 0,
    marginBottom: '43px',
  },
  filterBar: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '43px',
  },
  divider: {
    position: 'relative',
    top: -1,
  },
}));

export default useStyles;
