/**
 * Hook for skipping invoked function on initial render
 *
 * How to use:
 *
 * import useDidMount from 'hooks/useDidMount';
 *
 * const didMount = useDidMount();
 * ...
 *
 * useEffect(()=>{
 *    if( didMount){
 *      console.log('Executed after initial render')
 *    }
 * }, [someCondition]);
 *
 * ...
 */
import { useEffect, useRef } from 'react';

const useDidMount = () => {
  const didMountRef = useRef(false);

  useEffect(() => {
    didMountRef.current = true;
  }, []);
  return didMountRef.current;
};

export default useDidMount;
