/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import getFormBySpec from 'utils/rundown/getFormBySpec';
import { useSelectionContext } from 'contexts/SelectionContext';
import { specTypes, entityTypes } from 'utils/metadata/defaultViewTypes';
import memoize from 'memoize-one';
import { FixedSizeList, areEqual } from 'react-window';
import GridHeader from './gridHeader/grid-header-view';
import GridItem from './gridItem';
import useStyles from './grid-styles';
import ExtendedGrid from './extended-grid';

const PLATFORM_PREFIX_FOR_MAC = 'Mac';

const wasToggleInSelectionGroupKeyUsed = event => {
  const isUsingMac = navigator.platform.indexOf(PLATFORM_PREFIX_FOR_MAC) >= 0;
  // If using mac we need to check if CMD key is pressed during the click event.
  // In Windows or Linux machine we will check if ctrl key was pressed during the click event.
  return isUsingMac ? event.metaKey : event.ctrlKey;
};

const wasMultiSelectKeyUsed = event => event.shiftKey;

const getItemKey = (index, data) => {
  const { mOrder: Ids } = data;
  const item = Ids[index];
  return item;
};

const createItemData = memoize(
  (mOrder, selectedIds, toggleSelectionInGroup, multiSelectTo, toggleSelection, onDragEnd) => ({
    mOrder,
    selectedIds,
    toggleSelectionInGroup,
    multiSelectTo,
    toggleSelection,
    onDragEnd,
  }),
);

const GridView = ({
  instances,
  onAddButtonClick,
  onOpenStoryClick,
  onRemoveClick,
  onResetOrdering,
  onCreateStoryClick,
  scrollingId,
  height,
  form,
  onMoveInstanceToRundown,
  onCreateInstanceForStory,
  showMasterEditView,
  hostReadSpeed,
  mOrder,
  // experimental multidrag functions
  toggleSelectionInGroup,
  multiSelectTo,
  toggleSelection,
  onDragEnd,
  disableEdit,
  ...rest
}) => {
  const classes = useStyles();
  const [fields] = getFormBySpec(form, {
    entity: entityTypes.RUNDOWN,
    type: specTypes.GRID,
  });
  const contextMenuRef = useRef(null);
  const [selectedIds] = useSelectionContext();

  const listRef = useRef(null);
  const gridItemHeight = 32;
  const remainingGridHeight = height - mOrder.length * gridItemHeight;

  useEffect(() => {
    if (selectedIds.length === 1) {
      const current = selectedIds[0];
      const index = mOrder.indexOf(current);
      if (index >= 0) listRef.current.scrollToItem(index, 'smart');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  const Row = useMemo(
    () => ({ data: virtualData, index, style }) => {
      const {
        selectedIds: vSelectedIds,
        multiSelectTo: shiftSelect,
        toggleSelectionInGroup: ctrlSelect,
        toggleSelection: clickSelect,
        onDragEnd: handleDragEnd,
        mOrder: vMorder,
      } = virtualData;

      const instance = { mId: vMorder[index] };

      const isSelected = vSelectedIds.includes(instance.mId);
      const selectionCount = vSelectedIds.length;

      const handleClick = event => {
        if (wasToggleInSelectionGroupKeyUsed(event)) {
          ctrlSelect(instance.mId, scrollingId);
          return;
        }

        if (wasMultiSelectKeyUsed(event)) {
          shiftSelect(instance.mId, scrollingId);
          return;
        }
        clickSelect(instance.mId);
      };

      return (
        <GridItem
          style={style}
          isSelected={isSelected}
          selectionCount={selectionCount}
          handleClick={handleClick}
          onDragEnd={handleDragEnd}
          onInsertEmptyClick={() => {
            onAddButtonClick(index);
          }}
          onRemoveClick={() => onRemoveClick(index, instance, vSelectedIds, vMorder)}
          onOpenStoryClick={onOpenStoryClick}
          onCreateStoryClick={onCreateStoryClick}
          onMoveInstanceToRundown={onMoveInstanceToRundown}
          onCreateInstanceForStory={onCreateInstanceForStory}
          contextMenuRef={contextMenuRef}
          {...{
            fields,
            index,
            instance,
            scrollingId,
            hostReadSpeed,
            showMasterEditView,
            disableEdit,
          }}
          {...rest}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(fields), areEqual],
  );

  const itemData = createItemData(
    mOrder,
    selectedIds,
    toggleSelectionInGroup,
    multiSelectTo,
    toggleSelection,
    onDragEnd,
  );

  return (
    <div ref={contextMenuRef} className={classes.gridContainer}>
      <GridHeader
        fields={fields}
        onResetOrderingClick={onResetOrdering}
        {...{
          onMoveInstanceToRundown,
          onCreateInstanceForStory,
          showMasterEditView,
          onDragEnd,
          scrollingId,
          disableEdit,
        }}
      />
      <div className={classes.extendedContainer}>
        <FixedSizeList
          layout="vertical"
          height={height}
          itemCount={mOrder.length}
          width="100%"
          itemSize={32}
          ref={listRef}
          overscanCount={8}
          itemKey={getItemKey}
          itemData={itemData}
        >
          {Row}
        </FixedSizeList>
        <ExtendedGrid
          listLength={mOrder.length}
          {...{
            remainingGridHeight,
            onMoveInstanceToRundown,
            onCreateInstanceForStory,
            showMasterEditView,
            onDragEnd,
            scrollingId,
            disableEdit,
          }}
        />
      </div>
    </div>
  );
};

GridView.propTypes = {
  /** List of instances to show in the grid */
  instances: PropTypes.arrayOf(PropTypes.object),
  /** function for updating cursor for selected item in the grid */
  updateCursor: PropTypes.func,
  /** function for creating new empty placeholder in the grid list */
  onAddButtonClick: PropTypes.func,
  /** function for opening corresponding story on new tab */
  onOpenStoryClick: PropTypes.func,
  /** function for removing grid item */
  onRemoveClick: PropTypes.func,
  /** function for resetting order in ascending order */
  onResetOrdering: PropTypes.func,
  /** specifies the scrolling list id */
  scrollingId: PropTypes.string,
};

GridView.defaultProps = {
  instances: [],
  updateCursor: () => {},
  onAddButtonClick: () => {},
  onOpenStoryClick: () => {},
  onRemoveClick: () => {},
  onResetOrdering: () => {},
  scrollingId: '',
};

export default GridView;
