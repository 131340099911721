import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useFileUpload from 'hooks/useFileUpload';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import variants from 'utils/instance/variants';
import { Transforms } from 'slate';
import { actionTypes, elementTypes } from 'components/editor/constants/types';
import { generateName, removeBlock, updateBlock } from 'components/editor/utils';
import { useSlate, ReactEditor } from 'slate-react';
import useStorage from 'hooks/useStorage';
import Dialog from 'components/dialog';
import CreateNew from 'components/createNew';
import VideoBase from '../videoBase';
import useStyles from './styles';

const { select, setNodes } = Transforms;

const Video = ({ attributes, children, element }) => {
  const editor = useSlate();
  const classes = useStyles();
  const { containerRef, defaultPlaceholderTitle, update, isAllowed, variant } = useEditorContext();
  const [dialogOpen, setDialogOpen] = useState(false);

  /** Video data fetch */
  const { data } = element;
  const { src, showThumbnail, thumbnails = [], cache } = data;

  const cacheRef = useRef(cache || null);
  const { data: storageData } = useStorage(src);
  const { Body, ContentType } = storageData || {};
  const blob = storageData && new Blob([Body], { type: ContentType });
  const videoSrc = (src && blob && URL.createObjectURL(blob)) || cacheRef.current;

  const removeCacheFromSlateData = useCallback(() => {
    if (cache) {
      const path = ReactEditor.findPath(editor, element);

      Transforms.setNodes(editor, { data: { ...element.data, cache: undefined } }, { at: path });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cache, element]);

  useEffect(removeCacheFromSlateData, []);

  /** dialog */
  const { selection } = editor;
  const selectionCacheRef = useRef(selection);

  const closeDialog = useCallback(() => setDialogOpen(false), []);
  const openDialog = useCallback(() => setDialogOpen(true), []);

  const addThumbnails = async response => {
    if (Array.isArray(response)) {
      const updatedData = {
        ...data,
        thumbnails: [...thumbnails, ...response],
      };
      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData }, { at: path });
    }
  };

  const onFileUpload = useCallback(
    async (file, fileUrl) => {
      const fileName = generateName(file.type);
      cacheRef.current = fileUrl;

      const result = await update({
        type: actionTypes.ASSET_INSERT,
        payload: { document: editor.children, file, fileName },
      });

      const updatedData = {
        ...element.data,
        ...result,
      };
      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData }, { at: path });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const uploadVideo = useFileUpload(['video/mp4'], onFileUpload);

  const handleOpenDialog = useCallback(
    event => {
      event.preventDefault();

      selectionCacheRef.current = selection;
      openDialog();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selection],
  );

  const handleCreatePlaceholder = useCallback(
    async title => {
      closeDialog();

      try {
        if (selectionCacheRef.current) select(editor, selectionCacheRef.current);

        const result = await update({
          type: actionTypes.CREATE_PLACEHOLDER,
          payload: { document: editor.children, title },
        });

        const updatedData = {
          ...result,
          showThumbnail,
          thumbnails,
          mTitle: title,
          itemDuration: 0,
          itemType: 'video',
          mediaType: 'video/placeholder',
        };

        const path = ReactEditor.findPath(editor, element);
        setNodes(editor, { data: updatedData, type: elementTypes.PLACEHOLDER }, { at: path });
      } catch (error) {
        // console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const handleCancel = useCallback(() => {
    if (selectionCacheRef.current) select(editor, selectionCacheRef.current);
    closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Remove Video function tobe implemented */
  const removeVideo = useCallback(
    event => {
      event.preventDefault();
      const updatedData = {
        showThumbnail: data.showThumbnail,
      };
      if (data.showThumbnail) updatedData.thumbnails = thumbnails;

      updateBlock(editor, element, updatedData, update);
      cacheRef.current = null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const removeThumbnail = useCallback(
    newThumbnails => {
      const updatedData = {
        ...data,
        thumbnails: newThumbnails,
      };

      updateBlock(editor, element, updatedData, update);
    },
    [data, editor, element, update],
  );

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!isAllowed)
    return (
      <div {...attributes}>
        {children}
        <div className={classes.wrapper} contentEditable={false}>
          {videoSrc && (
            <video className={classes.video} controls>
              <track kind="captions" />
              <source src={videoSrc} type="video/mp4" />
            </video>
          )}
        </div>
      </div>
    );

  return (
    <div {...attributes}>
      {children}
      <VideoBase
        createPlaceholder={handleOpenDialog}
        hideEllipsisButton={variant === variants.YOUTUBE}
        {...{
          videoSrc,
          onMenuSelect,
          uploadVideo,
          removeVideo,
          addThumbnails,
          removeThumbnail,
          thumbnails,
          showThumbnail,
        }}
      />
      <Dialog container={containerRef.current} open={dialogOpen} onClose={handleCancel}>
        <CreateNew
          variant="placeholder"
          defaultTitle={defaultPlaceholderTitle}
          onCreate={handleCreatePlaceholder}
          onCancel={handleCancel}
        />
      </Dialog>
    </div>
  );
};

Video.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Video.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.VIDEO,
    data: { src: '', showThumbnail: true },
    children: [],
  },
};

export default memo(Video);
