import gql from 'graphql-tag';

export default gql`
  query GetAssets($input: GetMemberInput) {
    getAssets(input: $input) {
      mId
      mRefId
      mAssetId
      mTitle
      mType
      mediaType
      mUpdatedAt
      mThumbnailUrl
      mContentUrl
      mContentKey
      mMetaData {
        key
        value
      }
    }
  }
`;
