/* eslint-disable max-len */
import React, { memo, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { ReactComponent as DescriptionIcon } from 'assets/icons/systemicons/editor/description.svg';
import { ReactComponent as CheckboxSelected } from 'assets/icons/systemicons/editor/CheckboxSelected.svg';
import useGetSummary from 'hooks/useGetSummary';
import { ReactComponent as Summarize } from 'assets/icons/systemicons/summarize.svg';
import TextArea from 'components/textArea';
import { ReactComponent as PlusIcon } from 'assets/icons/systemicons/add_small.svg';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { IconButton, Typography } from '@material-ui/core';
import { elementTypes } from 'components/editor/constants/types';
import variants from 'utils/instance/variants';
import Tooltip from 'components/tooltip';
import updateBlock from 'components/editor/utils/updateBlock';
import stopEventPropagation from 'utils/stopEventPropagation';
import Box from '../box';
import insertTweetThread from './utils/insertTweetThread';
import useStyles from './styles';

const MemoizedTooltip = memo(Tooltip);

const menuItems = [
  {
    title: 'Summarize text',
    action: 'summarize',
    icon: <Summarize />,
  },
];

const YOUTUBE_CHAR_LIMIT = 5000;
const TWITTER_CHAR_LIMIT = 240;

const getAssistiveTextFor = (variant, text) => {
  if (variant === variants.TWITTER) return `${text.length} of ${TWITTER_CHAR_LIMIT}`;
  if (variant === variants.YOUTUBE)
    return `Max. ${YOUTUBE_CHAR_LIMIT} characters. First 150 characters are always shown.`;
  return '';
};

const splitAtIndex = (value, index) => {
  return [value.substring(0, index), value.substring(index)];
};

const Description = ({ attributes, children, element }) => {
  const { data } = element;
  const initialValue = (data && data.content) || '';
  const editor = useSlate();
  const { update, variant } = useEditorContext();
  const [summarize] = useGetSummary();
  const [localValue, setLocalValue] = useState(initialValue);
  const isExceeded = localValue.length > TWITTER_CHAR_LIMIT;
  const classes = useStyles({ isExceeded });

  const updateDescription = useCallback(
    newData => {
      const updatedData = {
        content: newData,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const onKeyDown = event => {
    const { key } = event;

    if (key === 'Enter') {
      event.preventDefault();
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        const el = document.activeElement;
        const { selectionStart, selectionEnd, value } = el;
        const preSubString = value.substring(0, selectionStart);
        const postSubString = value.substring(selectionEnd, value.length);
        const newValue = `${preSubString}\n${postSubString}`;
        el.value = newValue;
        el.selectionStart = selectionStart + 1;
        el.selectionEnd = selectionStart + 1;
        setLocalValue(newValue);
      } else {
        updateDescription(localValue);
      }
    }
    if (key === 'Tab') {
      updateDescription(localValue);
    }
    if (key === 'Escape') setLocalValue(initialValue);
  };

  const onChange = useCallback(
    val => {
      if (variant === variants.YOUTUBE && val.length <= YOUTUBE_CHAR_LIMIT) setLocalValue(val);
      else setLocalValue(val);
    },
    [variant],
  );

  const onBlur = useCallback(
    event => {
      event.preventDefault();
      if (event.relatedTarget) updateDescription(localValue);
    },
    [localValue],
  );

  const addNewThread = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      const [lValue, rValue] = splitAtIndex(localValue, 240);
      setLocalValue(lValue);
      updateDescription(lValue);
      insertTweetThread({ editor, update, data: { ...data, content: rValue || '' } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localValue],
  );

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'summarize') {
        summarize(localValue).then(async summarizedValue => {
          if (summarizedValue) {
            setLocalValue(summarizedValue);
            updateDescription(summarizedValue);
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localValue],
  );

  return (
    <div {...attributes}>
      {children}
      <Box
        title="Description"
        menuItems={menuItems}
        onMenuSelect={onMenuSelect}
        hideEllipsisButton={variant === variants.YOUTUBE}
        iconComponent={<DescriptionIcon className={classes.icon} />}
      >
        <>
          <div className={variant !== variants.TWITTER ? classes.textareaWrapper : ''}>
            <TextArea
              severity="regular"
              type="SoMe"
              rows={4}
              onMouseDown={stopEventPropagation}
              description={getAssistiveTextFor(variant, localValue)}
              subDescription={
                variant === variants.TWITTER && isExceeded
                  ? ` (${TWITTER_CHAR_LIMIT - localValue.length})`
                  : ''
              }
              value={localValue}
              {...{ onChange, onBlur, onKeyDown }}
            />
          </div>
          {variant === variants.TWITTER && (
            <div className={classes.buttonWrapper}>
              <div className={classes.overshooting}>
                <Typography classes={{ root: classes.overshootingText }}>
                  Move overshooting words to new thread
                </Typography>
                <CheckboxSelected className={classes.checkbox} />
              </div>
              <MemoizedTooltip title="Create a new Thread item" noArrow>
                <span>
                  <IconButton
                    disabled={!isExceeded}
                    onClick={addNewThread}
                    className={classes.button}
                  >
                    <PlusIcon />
                  </IconButton>
                </span>
              </MemoizedTooltip>
            </div>
          )}
        </>
      </Box>
    </div>
  );
};

Description.propTypes = {
  /**
   * Attributes of SlateJS children
   */
  attributes: PropTypes.shape({}),
  /**
   * SlateJS children
   */
  children: PropTypes.node,
  /**
   * SlateJS element
   */
  element: PropTypes.shape({}),
};

Description.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { content: '' },
    type: elementTypes.DESCRIPTION,
  },
};

export default memo(Description);
