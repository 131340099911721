import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  icon: {
    width: 24 + 8,
    marginRight: 10,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 100,
  },
}));

export default useStyles;
