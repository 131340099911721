import React, { memo, useState, useCallback } from 'react';
import { useSlate } from 'slate-react';
import PropTypes from 'prop-types';
import { ReactComponent as H1Icon } from 'assets/icons/systemicons/editor/h1_off.svg';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import useInputEvents from 'hooks/useInputEvents';
import updateBlock from 'components/editor/utils/updateBlock';
import InputBlock from '../inputBlock';
import useStyles from './styles';

const MAX_LENGTH = 100;

const Title = ({ attributes, children, element, readOnly }) => {
  const { data } = element;
  const editor = useSlate();
  const { update } = useEditorContext();
  const initialValue = data.content || '';
  const [value, setValue] = useState(initialValue);
  const classes = useStyles();

  const updateTitle = useCallback(
    newTitle => {
      const updatedData = {
        ...data,
        content: newTitle,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const onUpdate = useCallback(
    newValue => {
      if (newValue === initialValue) setValue(initialValue);
      else updateTitle(newValue);
    },
    [initialValue, updateTitle],
  );

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(onUpdate, value, initialValue);

  const handleChange = useCallback(val => {
    if (val.length <= MAX_LENGTH) setValue(val);
  }, []);

  return (
    <div {...attributes}>
      {children}
      <InputBlock
        label="Title"
        icon={<H1Icon className={classes.icon} />}
        description="Max. 100 characters. First 70 characters are always shown."
        placeholder="Enter video title here..."
        hideEllipsisButton
        value={value}
        onChange={handleChange}
        inputRef={inputRef}
        onKeyDown={handleKeydown}
        onBlur={handleBlur}
      />
    </div>
  );
};

Title.propTypes = {
  /**
   * whether title block is readonly on not
   */
  readOnly: PropTypes.bool,
};

Title.defaultProps = {
  readOnly: false,
};

export default memo(Title);
