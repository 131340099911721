import React from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import Title from './components/title';
import Duration from './components/duration';
import useStyles from './info-summary-styles';

const InfoSummaryView = ({
  title,
  duration,
  onUpdate,
  disableEdit,
  durationColor,
  showIcon,
  showSign,
  size,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <Title>{title}</Title>
        <Duration
          {...{
            size,
            duration,
            onUpdate,
            disableEdit,
            durationColor,
            showIcon,
            showSign,
          }}
        />
      </div>
      <Divider orientation="vertical" className={classes.divider} />
    </div>
  );
};

InfoSummaryView.propTypes = {
  /** Defines the title text */
  title: PropTypes.string,
  /** Value for the duration input in milliseconds */
  duration: PropTypes.string,
  /** Callback to be invoked when user finishes updating duration value and
   * moves focus to elsewhere, with the updated value passed in
   */
  onUpdate: PropTypes.func,
  /** If true, disables editability of the duration value */
  disableEdit: PropTypes.bool,
  /** Defines the text color for the duration */
  durationColor: PropTypes.string,
  /** If true, shows the status warning icon */
  showIcon: PropTypes.bool,
  /** If true, shows +/- sign along with duration */
  showSign: PropTypes.bool,
  size: PropTypes.string,
};

InfoSummaryView.defaultProps = {
  title: '',
  duration: '00:00',
  onUpdate: newDuration => {},
  disableEdit: false,
  durationColor: 'white',
  showIcon: false,
  showSign: false,
  size: '',
};

export default InfoSummaryView;
