import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  destination: {
    flex: 1,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
  },
  title: {
    ...theme.typography.dina.captionRegular,
    color: theme.palette.dina.mediumEmphasis,
    letterSpacing: '0.25px',
    marginRight: '8px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
}));

export default useStyles;
