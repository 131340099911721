import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    cursor: 'default',
    display: 'flex',
    height: '132px',
    transition: '0.3s ease',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.dina.blackHoverOverlay}`,
    background: theme.palette.dina.blackHoverOverlay,
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.dina.backgroundHover,
      border: `1px solid ${theme.palette.dina.borderHover}`,
      '& $ellipsis': {
        opacity: 1,
      },
    },
  },
  iconWrapper: {
    height: '100%',
    width: '40px',
    backgroundColor: theme.palette.dina.timelineVideoClip,
    borderRadius: '4px 0 0 4px',
    margin: 0,
  },
  icon: {
    width: '22px',
    height: '22px',
    margin: '9px',
    '& path': {
      fill: theme.palette.dina.blackMediumEmphasis,
      fillOpacity: 1,
    },
  },
  contentWrapper: {
    width: '100%',
    padding: '0px 16px',
  },
  withEllipsis: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '36px',
    overflow: 'hidden',
  },
  title: {
    ...theme.typography.dina.overline,
    height: '36px',
    fontWeight: '500',
    letterSpacing: '1px',
    lineHeight: '36px',
    margin: 0,
  },
  ellipsis: {
    marginRight: '-10px',
    marginTop: '-2px',
    opacity: 0,
    transition: '0.3s ease',
  },
  gif: {
    height: '80px',
    width: '80px',
    borderRadius: '2px',
    '&:hover': {
      '& $addIcon': {
        '& path': {
          fill: theme.palette.dina.highEmphasis,
          fillOpacity: 1,
          transition: '0.3s ease',
        },
      },
    },
  },
  gifImage: {
    height: '80px',
    width: '80px',
    borderRadius: '2px',
    marginRight: '84px',
  },
  gifWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  addIcon: {},
}));

export default useStyles;
