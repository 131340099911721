import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    background: ({ background }) => background,
    width: ({ width }) => width,
    height: ({ height }) => height,
    transition: 'height 0.3s ease-out',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  wrapper: {},
  toolbarWrapper: {
    flex: '0 0 auto',
  },
  editableWrapper: {
    flex: '1 1 auto',
    overflowY: 'auto',
    padding: 16,
    color: 'white',
  },
}));

export default useStyles;
