import React, { useEffect, useRef, useState, useContext, useCallback } from 'react';
import { useQuery, useMutation, useApolloClient } from 'react-apollo';
import { difference, isEqual, isNull } from 'lodash';
import configCtx from 'contexts/configContext';
import UserCtx from 'contexts/UserContext';
import {UsersContext} from 'globalState/users';
import memberTypes from 'graphql/memberTypes';
import useDidMount from 'hooks/useDidMount';
import GET_USER from 'graphql/queries/getUser';
import COPY_INSTANCE from 'graphql/mutations/moveInstanceToRundown';
import GET_CONTENT_TEMPLATES from 'graphql/queries/getContentTemplates';
import GET_RUNDOWN from 'graphql/queries/getRundown';
import GET_SUMMARY from 'graphql/queries/getSummary';
import GET_FOLDERS from 'graphql/queries/getFolders';
import GET_MEMBER_LOCK from 'graphql/queries/getMemeberLock';
import UPDATE_INSTANCE from 'graphql/mutations/updateStoryInstance';
import useMoveInstanceToRundown from 'hooks/useMoveInstanceToRundown';
import useTextStorage from 'hooks/useTextStorage';
import useCreateAsset from 'hooks/useCreateAsset';
import useCreatePlaceholder from 'hooks/useCreatePlaceholder';
import useRemovePlaceholder from 'hooks/useRemovePlaceholder';
import useDeleteTemplate from 'hooks/useDeleteTemplate';
import useCreateTemplate from 'hooks/useCreateTemplate';
import useCreateFolder from 'hooks/useCreateFolder';
import useDeleteFolder from 'hooks/useDeleteFolder';
import useInterval from 'hooks/useInterval';
import useUnscheduleInstance from 'hooks/useUnscheduleInstance';
import useUpdateInstanceMeta from 'hooks/useUpdateInstanceMeta';
import useUpdateInstanceMetadata from 'hooks/useUpdateInstanceMetadata';
import useUpdateDurationMeta from 'hooks/useUpdateDurationMeta';
import useLockInstance from 'hooks/useLockInstance';
import useUnlockInstance from 'hooks/useUnlockInstance';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useArchiveMember from 'hooks/useArchiveMember';
import useSettingsValue from 'hooks/useSettingsValue';
import useVersionHistory from 'hooks/useVersionHistory';
import { getMemberQuery } from 'graphql/queryVariables';
import { EditorContext } from 'globalState';
import { uploadToS3 } from 'utils/s3Utils';
import { getUserLockToken, getUserIdFromLockedId } from 'utils/lock/lockToken';
import { isBeforeToday, filterEditorMeta } from 'screens/rundown/components/editor/utils';
import { isOlderSlateValue, migrateValue, serializeToText } from 'components/editor';
import { elementTypes } from 'components/editor/constants';
import { getFileAssetData, getAssetData } from 'utils/assetData';
import useAssignInstance from 'hooks/useAssignInstance';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import getHostReadSpeed from 'components/instanceCard/utils/getHostReadSpeed';
import respectHostReadSpeed from 'screens/rundown/utils/respectHostReadSpeed';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import Instance from './instance-view';
import updateStoryInstanceCache from './updateStoryInstanceCache';

const findIndexInArray = item => array => (array ? array.indexOf(item) : -1);

const InstanceContainer = ({ storyInstance, platform, storyRefetch, storyId, people, ...rest }) => {
  const [duplicateInstanceToRundown] = useMutation(COPY_INSTANCE);
  const [instance, setInstance] = useState(storyInstance);
  const user = useContext(UserCtx);
  const {users} = useContext(UsersContext);
  const { mId: userId } = user;
  const { metadataForms } = useContext(configCtx);
  const [rundownIds, setRundownIds] = useState({});
  const [shouldDuplicate, setShouldDuplicate] = useState(false);
  const [shouldSkipGettingRundown, setShouldSkipGettingRundown] = useState(true);
  const [isSavingContent, setIsSavingContent] = useState(false);
  const [rundownMoveFrom, setRundownMoveFrom] = useState(null);
  const [rundownMoveTo, setRundownMoveTo] = useState(null);
  const [, unscheduleRundownInstances] = useUnscheduleInstance();
  const [editorData, setEditorData] = useState(null);
  const [shouldResetEditorSelection, setShouldResetEditorSelection] = useState(false);
  const [lockInstance] = useLockInstance();
  const [unlockInstance] = useUnlockInstance();
  const [addUsersToInstance] = useAssignInstance();
  const [saveMetaData] = useUpdateInstanceMetadata();
  const client = useApolloClient();
  const [createStoryAsset] = useCreateAsset();
  const [createPlaceholder] = useCreatePlaceholder();
  const [removePlaceholder] = useRemovePlaceholder();
  const [checkUserRight] = useCheckUserRight();
  const [getSettingsValue] = useSettingsValue();

  const canCreateNewTemplate = checkUserRight('instance', 'create-template');
  const canDeleteTemplate = checkUserRight('instance', 'delete-template');
  const canDeleteTemplateFolder = checkUserRight('instance', 'delete-template-folder');
  const canEditReadyInstance = checkUserRight('rundown', 'edit-ready-instances');
  const canShowNewDesign = checkUserRight('feature', 'new-some-design');
  const canSeeVersionHistory = checkUserRight('feature', 'version-history');

  const placeholderFormat = getSettingsValue('mam.placeholderName');

  const blankMetaData = getEmptyMetadataForForm(metadataForms[0]);
  const [handleDurationFieldsChange] = useUpdateDurationMeta(blankMetaData, () => {});

  const hostReadSpeed = getHostReadSpeed(storyInstance.mMetaData);

  const didMount = useDidMount();

  const { data: rundownData } = useQuery(GET_RUNDOWN, {
    variables: {
      input: {
        mId: rundownIds.mId,
        mRefId: rundownIds.mRefId,
      },
    },
    skip: shouldSkipGettingRundown,
  });


  const [assignedUsers] = useGetAssignedMembers(storyInstance.mAssignedMembers || []);
  const currentRundown = rundownData && rundownData.getRundown ? rundownData.getRundown : null;
  const { 
    versions, 
    currentVersionContent, 
    refetchVersionList, 
    refetchVersionContent,
    auditListLoading,
    versionContentLoading,
  } = useVersionHistory(instance.mId, users);

  const onRestoreVersion = useCallback(
    async (content) => {
      resetEditorValue(content);
      {
        const cInstance = storyInstance;
        const value = content;
        const unlock = true;
        setIsSavingContent(true);
        await saveContent(value, cInstance.mContentKey);
        
        let metadata = cInstance.mMetaData;
        if (platformType === 'linear') {
          metadata = handleDurationFieldsChange(value, hostReadSpeed, metadata);
        }
        
        const items = filterEditorMeta(value.document);
        
        await updateInstanceMetadata(metadata, cInstance, items, unlock, value);
        setIsSavingContent(false);
        setHasChanges(false);
      }
      updateLock();
    },
    [
      writeLock, readLock, releaseWriteLock, handleLockInstance, resetEditorValue
    ],
  );

  const checkVersionRestorability = async (content) => {
    if(lockedByCurrentUser) return true;
    const lockedId = await handleLockInstance();
    return lockedId === getUserLockToken(userId);
  }

  useEffect(() => {
    setShouldSkipGettingRundown(false);
  }, [rundownIds]);

  const createAsset = async (mStoryId, assetData) => {
    const asset = getAssetData(mStoryId, assetData);
    const result = await createStoryAsset(mStoryId, asset, true);

    return result;
  };

  const handleCreatePlaceholder = useCallback(
    async title => {
      const { mId, mRefId } = await createPlaceholder(storyId, {
        mTitle: title,
        mProperties: storyInstance.mProperties,
      });

      return { title, mId, mRefId };
    },
    [createPlaceholder, storyId, storyInstance.mProperties],
  );

  const [templateContentKey, setTemplateContentKey] = useState();
  const { mId, mContentKey, mDefaultContentKey } = storyInstance;
  const mContentKeyRef = useRef(mContentKey);
  const [showDialog, setShowDialog] = useState(false);
  const [enableShowMetadata, setEnableShowMetadata] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);
  const [, setInstanceMoveArgs] = useState({});
  const [schedule, setSchedule] = useState(storyInstance.mPublishingAt);
  const [skipDownload, setSkipDownload] = useState(true);
  const [disableEdit, setDisableEdit] = useState(true);

  const { data: s3Data, loading: contentLoading, refetch } = useTextStorage(
    mContentKey,
    skipDownload || enableShowMetadata,
    mDefaultContentKey,
  );

  const editorValueRef = useRef(s3Data);
  const [isModalOpen] = useContext(EditorContext);

  const displayMetadata = async () => {
    if (editorValueRef.current) {
      resetEditorValue(editorValueRef.current);
      saveAll(editorValueRef.current, null, storyInstance);
    }

    setEnableShowMetadata(showMetadata);
  };

  /* This effect ensures that when user switch to display
  metadata quickly after writing some content the content
  is saved properly. */
  useEffect(() => {
    // set the value early when pressing back button
    if (!showMetadata) setEnableShowMetadata(showMetadata);
    else displayMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMetadata]);

  const [readLock, setReadLock] = useState(false);
  const [writeLock, setWriteLock] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [lockedByUser, setLockedByUser] = useState('Someone');
  const [lockedByCurrentUser, setLockedByCurrentUser] = useState(false);

  const { account: platformAccount, platform: platformType, provider } = storyInstance.mProperties;

  const { accountId: platformAccountId } = platformAccount;

  const destination = {
    id: platformAccountId,
    title: storyInstance.mProperties.account.accountTitle,
    value:
      platformType === 'linear' && platformAccountId
        ? platformAccountId
        : storyInstance.mProperties.account.accountTitle,
  };

  const [currentDestination, setCurrentDestination] = useState(destination);


  const resetEditorValue = useCallback(
    newValue => {
      if (newValue && !isEqual(newValue, editorData)) {
        const value = isOlderSlateValue(newValue) ? migrateValue(newValue) : newValue;
        setEditorData(value);
        editorValueRef.current = value;
      } else if (isNull(newValue)) {
        setEditorData(null);
        editorValueRef.current = null;
      }
    },
    [editorData],
  );

  const fetchContent = useCallback(
    async url => {
      if (url) {
        const response = await fetch(url);
        const updatedContent = await response.json();

        resetEditorValue(updatedContent);
      }
    },
    [resetEditorValue],
  );

  const getLockedByUser = async lockedId => {
    if(lockedId){
      const result = await client
        .query({
          query: GET_USER,
          variables: getMemberQuery(getUserIdFromLockedId(lockedId)),
        })
      setLockedByUser(result.data.getMember.mTitle);
      return result.data.getMember.mTitle;
    } 
  };

  useEffect(() => {
    if (s3Data) resetEditorValue(s3Data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Data]);

  useEffect(() => {
    if(didMount){
      updateLock(storyInstance.locked);
      if (!storyInstance.locked) refetch();
      if (storyInstance.locked) getLockedByUser(storyInstance.locked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyInstance.locked]);

  const saveContent = useCallback(
    async (value, newKey) => {
      const key = newKey || mContentKey;
      const file = new window.File([JSON.stringify(value)], 'content.data', {
        type: 'text/plain',
      });

      try {
        await uploadToS3(key, file);
        // Instance.DurationFieldsChange(value);
      } catch (e) {
        // console.log(e);
      }
    },
    [mContentKey],
  );

  const onUpdateMetadata = useCallback(
    async (instanceId, metadata, items, unlock, content) => {
      await saveMetaData(
        instanceId,
        respectHostReadSpeed(metadata, hostReadSpeed),
        null,
        null,
        items,
        unlock,
        content,
      );
    },
    [hostReadSpeed, saveMetaData],
  );

  const updateInstanceMetadata = useCallback(
    async (newFields, cInstance, items, unlock, content) => {
      if (newFields)
        await onUpdateMetadata(
          cInstance.mId,
          newFields.map(({ key, value }) => ({
            key,
            value,
          })),
          items,
          unlock,
          content,
        );
    },
    [onUpdateMetadata],
  );

  const saveAll = useCallback(
    async (value, cInstance, unlock) => {
      if (!contentLoading && cInstance && writeLock) {
        
        setIsSavingContent(true);
        await saveContent(value, cInstance.mContentKey);
        
        let metadata = cInstance.mMetaData;
        if (platformType === 'linear') {
          metadata = handleDurationFieldsChange(value, hostReadSpeed, metadata);
        }
        
        const items = filterEditorMeta(value.document);
        
        await updateInstanceMetadata(metadata, cInstance, items, unlock, value);
        setIsSavingContent(false);
        setHasChanges(false);
      }
    },
    [
      contentLoading,
      writeLock,
      platformType,
      saveContent,
      updateInstanceMetadata,
      handleDurationFieldsChange,
      hostReadSpeed,
    ],
  );

  const updateLock = useCallback(
    lockedId => {
      setInstance(storyInstance);
      if (lockedId) {
        setWriteLock(lockedId === getUserLockToken(userId));
        setReadLock(lockedId !== getUserLockToken(userId));
        setLockedByCurrentUser(lockedId === getUserLockToken(userId));
      } else {
        setShouldResetEditorSelection(true);

        window.requestAnimationFrame(() => {
          setWriteLock(false);
          setReadLock(false);
        });
      }
    },
    [storyInstance, userId],
  );

  const handleUnlockInstance = useCallback(async () => {
    storyInstance.locked === getUserLockToken(userId) &&
      (await unlockInstance(storyInstance.mId, editorValueRef.current).then(updateLock));
  }, [storyInstance.locked, storyInstance.mId, unlockInstance, updateLock, userId]);

  const releaseWriteLock = useCallback(async () => {
    if (editorValueRef.current) {
      await saveAll(editorValueRef.current, { ...storyInstance }, true);
      updateLock();
      resetEditorValue(editorValueRef.current);
    } else handleUnlockInstance();
  }, [handleUnlockInstance, saveAll, storyInstance, updateLock, resetEditorValue]);

  useInterval(
    async () => {
      if (readLock) {
        const currentLockedData = await getInstanceLockedData();
        if (currentLockedData && currentLockedData.data && currentLockedData.data.getMember) {
          const { locked, mContentUrl } = currentLockedData.data.getMember;
          if (readLock && locked === null) {
            fetchContent(mContentUrl);
            storyRefetch();
          }
          updateLock(locked);
        }
      }
    },
    readLock ? 5000 : null,
  );

  const [skipSummarizeQuery, setSkipSummarizeQuery] = useState(true);

  const document = editorValueRef.current ? editorValueRef.current.document : [];
  const { data: summarizedData } = useQuery(GET_SUMMARY, {
    variables: {
      input: {
        mContent: serializeToText(document),
        mSummarizeLines: 1,
      },
    },
    fetchpolicy: 'cache-and-network',
    skip: skipSummarizeQuery,
  });

  useEffect(() => {
    if (summarizedData && summarizedData.getSummary) {
      const summarizedValue = {
        ...editorValueRef.current,
        document: [
          {
            type: elementTypes.PARAGRAPH,
            children: [{ text: summarizedData.getSummary.mSummarize }],
          },
        ],
      };

      resetEditorValue(summarizedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summarizedData]);

  useEffect(() => {
    setSkipSummarizeQuery(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summarizedData]);

  
  const updateEditStatus = () => {
    if (platformType !== 'linear' || canEditReadyInstance) setDisableEdit(false);
    else setDisableEdit(platformAccount.orderType === 'ready');
  };

  useEffect(() => {
    setInstance(storyInstance);
    setSchedule(storyInstance.mPublishingAt);
    updateEditStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyInstance]);

  useEffect(() => {
    updateEditStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canEditReadyInstance, platformAccount, platformType]);

  const { data: foldersData } = useQuery(GET_FOLDERS);
  const folders = foldersData && foldersData.getFolders ? foldersData.getFolders : [];

  const {
    loading: templateDataLoading,
    data: templateData,
    refetch: refetchTemplate,
  } = useTextStorage(templateContentKey, false);

  const { data: templateContents } = useQuery(GET_CONTENT_TEMPLATES, {
    variables: { mId: storyInstance.mProperties.account.accountId },
    fetchPolicy: 'cache-and-network',
  });

  let templates = [];

  if (templateContents && templateContents.getContentTemplates)
    templates = templateContents.getContentTemplates.map(
      ({ mRefId, mTitle, mContentKey: storageKey }) => ({
        id: mRefId,
        name: mTitle,
        storageKey,
      }),
    );

  const [updateInstance] = useMutation(UPDATE_INSTANCE);
  const [updateInstanceMeta] = useUpdateInstanceMeta();
  const [createFolder] = useCreateFolder();
  const [deleteFolder] = useDeleteFolder();
  const [createTemplate] = useCreateTemplate();
  const [deleteTemplate] = useDeleteTemplate();

  const [moveInstanceToRundown] = useMoveInstanceToRundown();

  const onMoveRundownInstance = (publishingTime, sourceRundown, targetRundown) => {
    setRundownIds({ mId: sourceRundown.mId, mRefId: sourceRundown.mRefId });
    setRundownMoveFrom(sourceRundown);
    setSchedule(publishingTime);
    setRundownMoveTo(targetRundown);
  };

  const returnOrderType = (mOrder, mPreorder) => {
    const returnIndex = findIndexInArray(storyInstance.mId);
    const isPrepareList = mPreorder && returnIndex(mPreorder) >= 0;
    if (isPrepareList) return { orderType: 'preparing', position: returnIndex(mPreorder) + 1 };
    return { orderType: 'ready', position: returnIndex(mOrder) + 1 };
  };

  useEffect(() => {
    if (shouldDuplicate) {
      setShouldDuplicate(false);
      const { mId: rundownId, mRefId: rundownRefId, mOrder, mPreorder } = currentRundown;

      const { orderType, position } = returnOrderType(mOrder, mPreorder);

      const input = {
        mId: storyInstance.mId,
        targetRundown: {
          mId: rundownId,
          mRefId: rundownRefId,
        },
        orderType,
        index: position,
        copy: true,
      };
      duplicateInstanceToRundown({
        variables: {
          input,
        },
        update: (proxy, mutationResult) => {
          const { moveInstanceToRundown: instanceData } = mutationResult.data;
          updateStoryInstanceCache(proxy, instanceData.mStoryId, instanceData);
        },
      });
      setShouldSkipGettingRundown(true);
    }
    if (rundownMoveTo) {
      setShouldSkipGettingRundown(true);
      const { mOrder } = currentRundown || {};
      const isInMOrder = !mOrder ? false : mOrder.find(val => val === storyInstance.mId);
      const sourceRundown = rundownMoveFrom;

      const targetRundown = rundownMoveTo;
      if (!targetRundown.mId && !targetRundown.mRefId) {
        unscheduleRundownInstances([storyInstance.mId], null, null, true, sourceRundown.mId);
        return;
      }
      const instanceId = storyInstance.mId;
      setRundownMoveTo(null);
      // Always copy instances that are in mOrder of past rundowns, otherwise move
      const copy = isInMOrder && isBeforeToday(sourceRundown.mRefId);

      if (copy) {
        setSchedule(sourceRundown.mRefId);
        setCurrentDestination({
          ...currentDestination,
          publishingTime: schedule,
        });
      }

      const source = sourceRundown.mId && sourceRundown.mRefId ? sourceRundown : null;
      !(sourceRundown.mId === targetRundown.mId && sourceRundown.mRefId === targetRundown.mRefId) &&
        !isBeforeToday(targetRundown.mRefId) &&
        moveInstanceToRundown(instanceId, source, targetRundown, copy).then(r => {
          setShowDialog(r);
          r &&
            setInstanceMoveArgs({
              instanceId,
              source,
              targetRundown,
              copy,
            });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRundown, rundownMoveTo]);

  // const [createRundownMutation] = useMutation(CREATE_RUNDOWN);
  const onCreateNewRundown = mTitle => {
    // *** to be implemented at a later phase ***
    // const { instance, sourceRundown, targetRundown, copy } = instanceMoveArgs;
    // const { mId: id, mRefId } = targetRundown;
    // const variables = { input: { mId: id, mRefId, mTitle } };
    // createRundownMutation({ variables }).then(r => {
    //   !r.loading &&
    //     moveInstanceToRundown(
    //       instance,
    //       sourceRundown,
    //       targetRundown,
    //       copy
    //     ).then(() => {
    //       onInstanceChanged({
    //         changeType: 'publishingSchedule',
    //         schedule: {
    //           publishTime: schedule,
    //         },
    //       });
    //       storyRefetch();
    //     });
    // });
  };

  const onMContentKeyChange = () => {
    mContentKeyRef.current = mContentKey;
    setTemplateContentKey(null);
  };

  const updateMeta = content => updateInstanceMeta(mId, content);

  useEffect(onMContentKeyChange, [mContentKey]);

  const [debouncedSaveAll] = useDebouncedCallback(saveAll, 3000);

  const onChange = useCallback(
    (value, updatedInstance) => {
      if (writeLock) {
        setHasChanges(true);
        editorValueRef.current = value;
        debouncedSaveAll(value, updatedInstance);
      }
    },
    [debouncedSaveAll, writeLock],
  );

  const onTemplateInsert = useCallback(
    (loading, data) => {
      if (!loading && data) {
        const template = isOlderSlateValue(data) ? migrateValue(data) : data;
        resetEditorValue(template);
        setTemplateContentKey(null);

        if (template.metadata) {
          const instanceWithTemplateMetadata = {
            ...storyInstance,
            mMetaData: template.metadata,
          };

          // this is required to save all template metadata fields
          const items = filterEditorMeta(template.document);
          updateInstanceMetadata(template.metadata, storyInstance, items);
          
          saveAll(template, instanceWithTemplateMetadata);
         
        } else saveAll(template, storyInstance);
      }
    },
    [resetEditorValue, updateInstanceMetadata, saveAll, storyInstance],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onTemplateInsert(templateDataLoading, templateData), [
    templateDataLoading,
    templateData,
  ]);

  const onSaveTemplate = useCallback(
    async (folderId, title, overwriteData, metadata) => {
      if (editorValueRef.current) {
        const template = metadata
          ? { ...editorValueRef.current, metadata }
          : editorValueRef.current;

        const file = new window.File([JSON.stringify(template)], 'content.data', {
          type: 'text/plain',
        });

        if (overwriteData) {
          try {
            uploadToS3(overwriteData.mContentKey, file);
          } catch (err) {
            // console.log(err);
          }
        } else {
          try {
            const result = await createTemplate(folderId, title);
            uploadToS3(result.data.createContentTemplate.mContentKey, file);
          } catch (err) {
            // console.log(err);
          }
        }
      }
    },
    [createTemplate],
  );

  const onSelectTemplate = ({ mContentKey: storageKey }) => {
    if (templateContentKey === storageKey) refetchTemplate();
    else {
      setTemplateContentKey(storageKey);
    }
  };

  const update = async input => {
    try {
      setIsSavingContent(true);

      await updateInstance({
        variables: {
          input,
        },
      });
    } catch (err) {
      // console.log(err);
    } finally {
      setIsSavingContent(false);
    }
  };

  const changeTitle = async title => {
    const input = {
      mId: storyInstance.mId,
      mTitle: title,
    };
    update(input);
  };

  const changeDescription = async description => {
    const input = {
      mId: storyInstance.mId,
      mDescription: description,
    };
    update(input);
  };

  const changeStatus = async status => {
    const input = {
      mId: storyInstance.mId,
      mState: status,
    };
    update(input);
  };

  const changeDestination = async account => {
    const input = {
      mId: storyInstance.mId,
      mProperties: {
        __typename: 'PlatformType',
        platform: storyInstance.mProperties.platform,
        account: {
          accountId: account.accountId,
          accountUrl: account.accountUrl,
          accountLogo: account.accountLogo,
          accountTitle: account.accountTitle,
        },
      },
    };

    update(input);
  };

  const saveInstance = publishTime => {
    update({
      mId: storyInstance.mId,
      mPublishingAt: publishTime,
    });
  };

  const onInstanceChanged = instanceChangeType => {
    const { changeType } = instanceChangeType;

    switch (changeType) {
      case 'publishingSchedule': {
        setSchedule(instanceChangeType.schedule.publishTime);
        const publishTime = instanceChangeType.schedule
          ? instanceChangeType.schedule.publishTime
          : null;
        return saveInstance(publishTime);
      }

      case 'destination': {
        const account = platform.mProperties.accounts.find(
          a => a.accountTitle === instanceChangeType.accountUrl,
        );

        return changeDestination(account);
      }

      case 'content': {
        const key = mContentKeyRef.current;
        setIsSavingContent(true);
        const file = new window.File([JSON.stringify(instanceChangeType.content)], 'content.data', {
          type: 'text/plain',
        });

        try {
          return uploadToS3(key, file);
        } catch (err) {
          // console.log(err);
          return err;
        } finally {
          setIsSavingContent(false);
        }
      }

      case 'title': {
        return changeTitle(instanceChangeType.title);
      }

      case 'status': {
        return changeStatus(instanceChangeType.status);
      }

      case 'description': {
        return changeDescription(instanceChangeType.description);
      }

      default:
        return null;
    }
  };

  const onAssetInsert = async (file, fileName) => {
    const assetData = getFileAssetData(storyId, file);
    const sourceData = {
      mId: assetData.mId,
      mRefId: assetData.mRefId,
    };

    try {
      const result = await createStoryAsset(storyId, assetData);
      const { createAssets: assets } = result.data;
      if (assets && assets[0]) {
        sourceData.src = assets[0].mContentKey;
      }
    } catch (e) {
      // console.log(e)
    }

    return sourceData;
  };

  // should we still support this
  const onAssetRemove = storageKey => {
    try {
      // deleteFromS3(storageKey);
    } catch (err) {
      // console.log(err);
    }
  };

  const assignMembers = async members => {
    const existingIds =
      (storyInstance.mAssignedMembers && storyInstance.mAssignedMembers.map(m => m.mId)) || [];
    const updatedIds = members.map(({ mId: id }) => id);
    const removedIds = difference(existingIds, updatedIds);
    const addedIds = difference(updatedIds, existingIds);

    addUsersToInstance(mId, addedIds, removedIds, updatedIds);
  };

  const assignMemberToInstance = members => {
    const assignee =
      (storyInstance.mAssignedMembers && storyInstance.mAssignedMembers.map(m => m.mId)) || [];
    const updatedAssignees = [...assignee, ...members.map(({ mId: id }) => id)];

    addUsersToInstance(
      mId,
      members.map(({ mId: id }) => id),
      [],
      updatedAssignees,
    );
  };

  const onCreateDuplicate = () => {
    const { accountId, accountRefId } = storyInstance.mProperties.account;
    if (accountId && accountRefId) {
      setRundownIds({ mId: accountId, mRefId: accountRefId });
      setShouldDuplicate(true);
    }
  };

  const summarize = () => {
    if (skipSummarizeQuery) setSkipSummarizeQuery(!skipSummarizeQuery);
  };

  const getInstanceLockedData = async () => {
    const lockedData = await client.query({
      query: GET_MEMBER_LOCK,
      variables: getMemberQuery(mId),
      fetchPolicy: 'network-only',
    });
    return lockedData;
  };

  const handleLockInstance = async () => {
    if (!writeLock && !readLock) {
      updateLock(getUserLockToken(userId));
      const result = await lockInstance(mId, userId);
      if (result && result.data && result.data.lockMember) {
          const { locked, mContentUrl } = result.data.lockMember;
          await fetchContent(mContentUrl);
          updateLock(locked);
          await getLockedByUser(locked);
          return locked;
        }
      else updateLock();
    }
  };

  const handleOnFocus = () => {
    setInstance(storyInstance);
  };
  const onForceUnlock = () => {
    unlockInstance(storyInstance.mId);
  };

  const [archiveMember] = useArchiveMember();

  const onDeleteInstance = async () => {
    await archiveMember(
      storyInstance.mId,
      memberTypes.instanceMemberTypes,
      storyInstance.mProperties.platform === 'linear' ? currentDestination.id : null,
    );
    storyRefetch();
  };

  const onCmsEditingActivated = useCallback(() => {
    setEditorData(editorValueRef.current);
  }, []);

  return (
    <Instance
      assignMembers={assignMembers}
      createPlaceholder={handleCreatePlaceholder}
      form={metadataForms[0]}
      handleUserDrop={assignMemberToInstance}
      loading={contentLoading}
      members={assignedUsers}
      onCreateFolder={createFolder}
      onDeleteFolder={deleteFolder}
      onDeleteTemplate={deleteTemplate}
      onFocus={handleOnFocus}
      onLockInstance={handleLockInstance}
      onUnlockInstance={handleUnlockInstance}
      onUpdatingRundownInstance={onMoveRundownInstance}
      onCmsEditingActivated={onCmsEditingActivated}
      reFetchContent={fetchContent}
      storyInstance={instance}
      editorValue={editorData}
      {...{
        provider,
        canShowNewDesign,
        canCreateNewTemplate,
        canDeleteTemplate,
        canDeleteTemplateFolder,
        canSeeVersionHistory,
        createAsset,
        currentDestination,
        disableEdit,
        folders,
        hasChanges,
        isModalOpen,
        isSavingContent,
        lockedByUser,
        lockedByCurrentUser,
        onAssetInsert,
        onAssetRemove,
        onChange,
        onCreateDuplicate,
        onCreateNewRundown,
        onDeleteInstance,
        onForceUnlock,
        onInstanceChanged,
        onSaveTemplate,
        onSelectTemplate,
        people,
        platform,
        readLock,
        releaseWriteLock,
        removePlaceholder,
        saveContent,
        schedule,
        setCurrentDestination,
        setShowDialog,
        setShowMetadata,
        setSkipDownload,
        shouldResetEditorSelection,
        showDialog,
        showMetadata,
        storyId,
        storyInstance,
        storyRefetch,
        summarize,
        templates,
        updateInstanceMetadata,
        updateMeta,
        userId,
        writeLock,
        placeholderFormat,
        versions,
        currentVersionContent,
        refetchVersionList, 
        refetchVersionContent,
        onRestoreVersion,
        checkVersionRestorability,
        auditListLoading,
        versionContentLoading,
      }}
      {...rest}
    />
  );
};

export default InstanceContainer;
