import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    position: 'absolute',
    top: ({ top }) => top,
    left: ({ left }) => left,
    zIndex: 100,
    backgroundColor: ({ background }) =>
      background || palette.dina.surfaceDialogs,
  },
  list: { borderRadius: 4 },
  menuItem: { color: 'white' },
}));

export default useStyles;
