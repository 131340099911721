import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  mediaIcons: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '140px',
  },
  icon: {
    margin: '8px',
    '& path': {
      fill: theme.palette.dina.blackMediumEmphasis,
      fillOpacity: 1,
    },
  },
  contentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: '0 8px 8px 0',
    },
    marginBottom: '8px',
  },
  thumbnail: {
    borderRadius: '4px',
    overflow: 'hidden',
  },
}));

export default useStyles;
