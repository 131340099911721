import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import InputField from 'components/inputField/InputField';
import InputFieldTypes from 'components/inputField/InputFieldTypes';
import TextArea from 'components/textArea/TextArea';
import Divider from 'components/divider/divider-view';
import Button from 'components/buttons/button/button-view';
import * as EmailValidator from 'email-validator';

const useStyles = makeStyles(theme => ({
  textRoot: {
    ...theme.typography.dina.formTitle,
    marginBottom: '19px',
    paddingTop: '24px',
  },
  inputContainer: {
    width: '279px',
    margin: 'auto',
  },
  inputDiv: {
    marginBottom: '18px',
  },
  textAreaDiv: {
    marginBottom: '17px',
  },
  formContainer: {
    width: '309px',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'space-around',
  },
}));

const CreateContact = ({ onSubmit, onCancel }) => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [readSpeed, setReadSpeed] = useState('');
  const [description, setDescription] = useState('');
  const [preventSubmission, setPreventSubmission] = useState(false);

  const submitForm = e => {
    e.preventDefault();
    if (firstName && surname && (!email || EmailValidator.validate(email)) && !preventSubmission) {
      setPreventSubmission(true);
      onSubmit({
        firstName,
        surname,
        email,
        phone: phoneNumber,
        description,
        readSpeed,
      });
    }
  };

  return (
    <form className={classes.formContainer} onSubmit={submitForm}>
      <div className={classes.inputContainer}>
        <Typography classes={{ root: classes.textRoot }}>Create new Contact</Typography>
        <div className={classes.inputDiv}>
          <InputField
            label="First name"
            description="Type first name here"
            value={firstName}
            severity="mandatory"
            onChange={val => setFirstName(val)}
            autoFocus
          />
        </div>
        <div className={classes.inputDiv}>
          <InputField
            label="Surname"
            description="Type surname here"
            value={surname}
            severity="mandatory"
            onChange={val => setSurname(val)}
          />
        </div>
        <div className={classes.inputDiv}>
          <InputField
            label="E-mail"
            description="Type e-mail address here"
            value={email}
            type={InputFieldTypes.EMAIL}
            onChange={val => setEmail(val)}
          />
        </div>
        <div className={classes.inputDiv}>
          <InputField
            label="Phone Number"
            description="Type phone number here"
            value={phoneNumber}
            type={InputFieldTypes.TELEPHONE}
            onChange={val => setPhoneNumber(val)}
          />
        </div>
        <div className={classes.inputDiv}>
          <InputField
            label="Read Speed"
            description="Type read speed here"
            value={readSpeed}
            onChange={val => setReadSpeed(val)}
          />
        </div>
        <div className={classes.textAreaDiv}>
          <TextArea
            label="Description"
            value={description}
            optionalLabel="Description"
            onChange={val => setDescription(val)}
            description="Type a short bio here"
            rows="4"
          />
        </div>
      </div>
      <Divider />
      <div className={classes.buttonContainer}>
        <Button variant="text" onClick={onCancel} shape="full-width">
          Cancel
        </Button>
        <Button
          shape="full-width"
          variant="contained"
          type="significant"
          disabled={
            preventSubmission ||
            !firstName ||
            !surname ||
            (!!email && !EmailValidator.validate(email))
          }
          onClick={submitForm}
          submit
        >
          Create Contact
        </Button>
      </div>
    </form>
  );
};

CreateContact.propTypes = {
  /** Cancel button */
  onCancel: PropTypes.func,
  /** Create button */
  onSubmit: PropTypes.func,
};

CreateContact.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
};

export default CreateContact;
