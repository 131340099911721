import getIndicatorNewPosition from '../../functions/getIndicatorNewPosition';

const animateIndicator = (indicatorNode, sliderWrapper) => {
  const newItem = document.querySelector('.selected-month');
  const newPosition = getIndicatorNewPosition(newItem, indicatorNode, sliderWrapper);

  Object.assign(indicatorNode.style, {
    visibility: 'visible',
    transform: `translate(${newPosition}px, 0)`,
  });

  newItem.classList.add('animated-selected');
};

export default animateIndicator;
