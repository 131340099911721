import { useMutation } from 'react-apollo';
import DELETE_MEMBER from 'graphql/mutations/deleteMember';

const useRemovePlaceholder = () => {
  const [removePlaceholder] = useMutation(DELETE_MEMBER);

  const removeAssetPlaceholder = async data => {
    const input = {
      mId: data.mId,
      mRefId: data.mRefId,
    };

    try {
      await removePlaceholder({ variables: { input } });
      return;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return [removeAssetPlaceholder];
};

export default useRemovePlaceholder;
