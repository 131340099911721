/**
 * Check if the user is logged in and return either Login or Main screen.
 */
import React from 'react';
import Login from './login/Login';
import Main from './main';

const user = { mId: '-', groups: [] };

const ScreensHandler = ({ context }) => {
  let screen;
  let loggedIn = false;

  if (context.user) {
    const idToken = context.user.getSignInUserSession().getIdToken();
    if (idToken && idToken.payload) {
      user.mId = idToken.payload.sub;
    }
    user.groups = context.groups;
    loggedIn = true;
  }

  if (loggedIn) screen = <Main user={user} />;
  if (!loggedIn) screen = <Login />;
  if (!context.user) screen = <Login />;

  return screen;
};

export default ScreensHandler;
