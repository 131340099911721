import React, { useState, useRef, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, ListItemIcon } from '@material-ui/core';
import { useSlate, ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import useEditorContext from 'components/editor/hooks/useEditorContext';
// eslint-disable-next-line max-len
import { ReactComponent as ArrowDoubleUnboxedIcon } from 'assets/icons/systemicons/arrows/arrow_double_unboxed.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/systemicons/check.svg';
import getTimeString from 'utils/getTimeString';
import getMilliseconds from 'utils/getMilliseconds';
import Input from 'components/editor/components/inputBase';
import { outTimingTypes, actionTypes } from 'components/editor/constants/types';
import stopAllPropagation from 'components/editors/utils/stopAllPropagation';
import selectElement from 'components/editor/utils/selectElement';
import useStyles from './styles';

const { MANUAL_OUT, ITEM_OUT, INSTANCE_OUT, AUTO_OUT } = outTimingTypes;

const items = [
  { type: MANUAL_OUT, title: 'Manual Out' },
  { type: AUTO_OUT, title: 'Set Duration' },
  { type: ITEM_OUT, title: 'Item Out' },
  { type: INSTANCE_OUT, title: 'Instance Out' },
];

const renderSelectIcon = () => null;

const OutTimingSelect = ({ element }) => {
  const containerRef = useRef(null);
  const iconRef = useRef(null);
  const editor = useSlate();
  const [isOpen, setIsOpen] = useState(false);
  const { update } = useEditorContext();
  const { outTiming, duration } = element.data;
  const showInput = outTiming === AUTO_OUT;
  const classes = useStyles({ showInput });

  const openSelectMenu = useCallback(() => setIsOpen(true), []);
  const closeSelectMenu = useCallback(() => setIsOpen(false), []);

  const updateData = useCallback(
    updatedProperty => {
      const updatedData = { ...element.data, ...updatedProperty };

      selectElement(editor, element);
      Transforms.setNodes(editor, { data: updatedData });
      ReactEditor.focus(editor);

      update({
        type: actionTypes.AUTOMATION_UPDATE,
        payload: { document: editor.children, updatedData },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const handleTypeChange = useCallback(
    event => {
      updateData({ outTiming: event.target.value });
      ReactEditor.focus(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateData],
  );

  const handleInputChange = useCallback(
    newValue => {
      updateData({ outTiming: AUTO_OUT, duration: getMilliseconds(newValue) });
    },
    [updateData],
  );

  const onKeyDown = useCallback(
    event => {
      stopAllPropagation(event);

      const { key } = event;

      if ((key === 'ArrowUp' || key === 'ArrowDown') && !isOpen) openSelectMenu();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const selectOptions = useMemo(
    () =>
      items.map(item => (
        <MenuItem
          value={item.type}
          key={item.type}
          classes={{
            root: classes.menuItem,
            selected: classes.menuItemSelected,
          }}
        >
          <ListItemIcon classes={{ root: classes.checkIcon }}>
            <CheckIcon />
          </ListItemIcon>

          {item.title}
        </MenuItem>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onOpen = useCallback(() => iconRef.current.click(), []);

  const inputValue = useMemo(() => getTimeString(duration), [duration]);

  return (
    <div
      className={classes.root}
      onClick={stopAllPropagation}
      ref={containerRef}
      role="presentation"
    >
      <div role="presentation" className={classes.icon} onClick={openSelectMenu} ref={iconRef}>
        <ArrowDoubleUnboxedIcon />
      </div>

      <div role="presentation" className={classes.selectWrapper} {...{ onKeyDown }}>
        {showInput && (
          <div className={classes.input}>
            <Input type="input" value={inputValue} onUpdate={handleInputChange} />
          </div>
        )}

        <Select
          disableUnderline
          fullWidth
          open={isOpen}
          onClose={closeSelectMenu}
          IconComponent={renderSelectIcon}
          value={outTiming}
          onChange={handleTypeChange}
          classes={{ root: classes.selectRoot, select: classes.select }}
          MenuProps={{
            classes: {
              paper: classes.menu,
              list: classes.menuList,
            },
            anchorEl: containerRef.current,
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'center',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'center',
              horizontal: 'left',
            },
            PaperProps: {
              onMouseLeave: closeSelectMenu,
            },
          }}
          inputProps={{
            classes: {
              root: classes.selectInput,
            },
          }}
          {...{ onOpen }}
        >
          {selectOptions}
        </Select>
      </div>
    </div>
  );
};

OutTimingSelect.propTypes = {
  /** SlateJS element */
  element: PropTypes.shape({}),
};

OutTimingSelect.defaultProps = {
  element: {},
};

export default memo(OutTimingSelect);
