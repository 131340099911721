import React from 'react';
import GET_PLATFORM from 'graphql/queries/getPlatform';
import LoadingIndicator from 'components/loadingIndicator';
import { useQuery } from 'react-apollo';
import Preview from './preview-view';
import useStyles from './preview-styles';

const PreviewContainer = ({ id, title, closeDialog }) => {
  const classes = useStyles();
  const { data, loading } = useQuery(GET_PLATFORM, {
    variables: {
      input: {
        mId: id,
        refresh: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  if (loading)
    return (
      <div className={classes.container}>
        <LoadingIndicator />
      </div>
    );

  const { provider } = data.getPlatform.mProperties;
  const src = provider && provider.previewEndpoint;

  return <Preview {...{ id, title, closeDialog, src }} />;
};

export default PreviewContainer;
