import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '../appBar';
import useStyles from './master-wrapper-styles';

const MasterWrapperView = ({ header, body }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar>
        <div className={classes.headerWrapper}>{header}</div>
      </AppBar>
      <div className={classes.bodyWrapper}>{body}</div>
    </div>
  );
};

MasterWrapperView.propTypes = {
  /** View to be shown on the head of master views */
  header: PropTypes.node,
  /** View to be shown on the body of master views */
  body: PropTypes.node,
};

MasterWrapperView.defaultProps = {
  header: <div>Header</div>,
  body: <div>Body</div>,
};

export default MasterWrapperView;
