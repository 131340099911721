import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  rootDiv: {
    width: '100%',
    height: '100%',
  },
  generalDiv: {
    width: '100%',
    height: '100%',
  },
  fieldDiv: {
    width: '100%',
    height: 'calc(100% - 40px)',
    overflowY: 'auto',
  },
}));

export default useStyles;
