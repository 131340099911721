/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useReducer, useEffect, useMemo } from 'react';
import { Divider, ClickAwayListener, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import returnFieldPairs from 'utils/rundown/returnFieldPairs';
import useDidMount from 'hooks/useDidMount';
import { getDurationKey } from 'screens/rundown/components/editor/utils';
import { ReactComponent as FloatImage } from 'assets/icons/systemicons/rundown/primary_float.svg';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/plus_ultraSmall_1px.svg';
import EllipsisButton from 'components/buttons/ellipsisButton';
import { useRundownListContext } from 'contexts/RundownListContext';
import { FixedField, CustomField } from './fields';
import reducer from './utils/reducer';
import actionTypes from './utils/actionTypes';
import ActionButtons from './actionButton';
import GridContextMenu from './contextMenu';
import respectHostReadSpeed from '../../../../utils/respectHostReadSpeed';
import useStyles from './grid-item-styles';

const ifIncludedIn = array => str => array.find(item => item.key.includes(str));

const deepEqualBetween = (array1, array2) => JSON.stringify(array1) !== JSON.stringify(array2);

const FLOAT = 'isFloat';
const ORDER = 'order';
const READRATE = 'hostReadSpeed';

const GridItemView = ({
  items,
  title,
  mStoryId,
  handleClick,
  thumbnail,
  showMasterEditView,
  onInsertEmptyClick,
  onCreateStoryClick,
  index,
  onRemoveClick,
  onOpenStoryClick,
  draggedId,
  hovered,
  isDragging,
  isSelected,
  showPlus,
  fields,
  mId,
  mMetaData,
  updateInstanceMetaData,
  updateTitle,
  contextMenuRef,
  hostReadSpeed,
  disableEdit,
}) => {
  const classes = useStyles();
  const initialTranslateX = 698;
  const initialPosition = {
    xPos: null,
    yPos: null,
  };
  const [translateX, setTranslateX] = useState(initialTranslateX);
  const [mousePosition, setMousePosition] = useState(initialPosition);
  const { openId, setOpenId } = useRundownListContext();
  const didMount = useDidMount();

  const hostReadSpeedField = (mMetaData || []).find(m => m.key.includes(READRATE));
  const readSpeedValue =
    hostReadSpeedField && hostReadSpeedField.value
      ? parseInt(hostReadSpeedField.value, 10)
      : hostReadSpeed;

  const readRate = isNaN(readSpeedValue) ? hostReadSpeed : readSpeedValue;

  const metadata = respectHostReadSpeed(returnFieldPairs(mMetaData, fields), readRate);
  const [state, dispatch] = useReducer(reducer, metadata);

  useEffect(() => {
    if (didMount && deepEqualBetween(state, metadata)) {
      dispatch({ type: actionTypes.UPDATE_STATE, payload: metadata });
    }
  }, [JSON.stringify(metadata)]);

  const findKey = str => {
    return metadata.find(item => getDurationKey(item) === str);
  };

  const updateMetadata = payload => {
    dispatch({ type: actionTypes.UPDATE_META, payload });
    updateInstanceMetaData(mId, payload);
  };

  const handleFloatChange = () => {
    const payload = (!isFloat).toString();
    dispatch({ type: actionTypes.UPDATE_FLOAT, payload });
    updateInstanceMetaData(mId, [{ key: findKey(FLOAT).key, value: payload }]);
  };

  const ifIncludedInState = ifIncludedIn(state);

  const metaOfFloat = ifIncludedInState(FLOAT);
  const metaOfOrder = ifIncludedInState(ORDER);
  const isFloat = metaOfFloat && metaOfFloat.value === 'true';
  const order = metaOfOrder ? metaOfOrder.value : '';

  const ListItemStyle = () => {
    if (isSelected) return classes.selectedContainer;
    if (isFloat) return classes.floatingContainer;
    return classes.fieldContainer;
  };

  const ListOrderStyle = () => {
    if (isSelected && !isFloat) return classes.selectedOrder;
    if (isFloat) return classes.floatOrder;
    return classes.order;
  };

  const handleContextClick = event => {
    event.preventDefault();
    if (contextMenuRef && contextMenuRef.current.contains(event.target)) {
      setMousePosition({
        xPos: event.clientX - 2,
        yPos: event.clientY - 4,
      });
    } else {
      setMousePosition(initialPosition);
    }
  };

  const handleContextClose = () => setMousePosition(initialPosition);

  const handleOpenStoryClick = () => {
    onOpenStoryClick({ mTitle: title, mStoryId });
  };

  const handleCreateInstanceClick = event => {
    onCreateStoryClick(event, index);
  };

  const memorizedView = useMemo(() => {
    const actionCreator = func => {
      setOpenId('');
      if (func) func();
      setTranslateX(initialTranslateX);
    };
    const handleClickAway = () => {
      if (openId === mId) {
        setOpenId('');
        setTranslateX(0);
      }
      setTranslateX(initialTranslateX);
    };
    const isPlaceholder = mId[0] === '-';
    const isGhosting = isSelected && Boolean(draggedId) && draggedId !== mId;

    const chooseDivider = () => {
      if (!hovered || disableEdit) return <Divider classes={{ root: classes.divider }} />;
      return (
        <div>
          {!showMasterEditView && (
            <div className={classes.fabDiv}>
              {showPlus && <AddIcon className={`${classes.addIcon} ${classes.selectionCount}`} />}
            </div>
          )}
        </div>
      );
    };

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          role="button"
          tabIndex="-1"
          onClick={event => {
            event.preventDefault();
            handleClick(event);
            setOpenId('');
            setTranslateX(initialTranslateX);
          }}
          onKeyDown={() => {}}
          className={classes.gridItemRoot}
          id={mId}
        >
          <div
            className={
              isDragging || isGhosting ? classes.draggingListItem : classes.defaultGridItem
            }
          >
            <div className={ListOrderStyle()}>
              {isFloat && <FloatImage className={classes.floatIcon} />}
              <Typography classes={{ root: classes.orderText }}>{order}</Typography>
            </div>
            <div className={ListItemStyle()}>
              <FixedField
                instanceId={mId}
                title={title || '-'}
                {...{ isPlaceholder, thumbnail, items, updateTitle, disableEdit }}
              />
              {!isPlaceholder && (
                <CustomField
                  instanceId={mId}
                  metadata={state}
                  {...{ updateMetadata, fields, disableEdit }}
                />
              )}
              <div className={classes.ellipsisButton}>
                <EllipsisButton
                  tabIndex="-1"
                  padding="6px"
                  disableRipple
                  hidden={translateX === 0}
                  onClick={event => {
                    event.stopPropagation();
                    setTranslateX(0);
                    setOpenId(mId);
                  }}
                />
              </div>
              <ActionButtons
                translateX={openId === mId ? 0 : translateX}
                isPlaceholder={isPlaceholder}
                tabIndex="-1"
                isFloat={isFloat}
                onClose={() => actionCreator()}
                onRemoveClick={() => actionCreator(onRemoveClick)}
                onOpenStoryClick={() => actionCreator(handleOpenStoryClick)}
                onFloatClick={() => actionCreator(handleFloatChange)}
                onCreateInstanceClick={handleCreateInstanceClick}
                onInsertEmptyClick={() => actionCreator(onInsertEmptyClick)}
                disableEdit={disableEdit}
              />
            </div>
          </div>
          {chooseDivider()}
        </div>
      </ClickAwayListener>
    );
  }, [
    JSON.stringify(items),
    title,
    showMasterEditView,
    hovered,
    isDragging,
    draggedId,
    isSelected,
    showPlus,
    mId,
    translateX,
    isFloat,
    handleClick,
    JSON.stringify(state),
  ]);

  return (
    <div onContextMenu={handleContextClick}>
      {memorizedView}
      <GridContextMenu
        mousePosition={mousePosition}
        handleContextClick={handleContextClick}
        handleContextClose={handleContextClose}
        isPlaceholder={mId[0] === '-'}
        isFloat={isFloat}
        onRemoveClick={() => {
          setOpenId('');
          onRemoveClick();
        }}
        onOpenStoryClick={() => {
          setOpenId('');
          handleOpenStoryClick();
        }}
        onFloatClick={() => {
          setOpenId('');
          handleFloatChange();
        }}
        onCreateInstanceClick={handleCreateInstanceClick}
        onInsertEmptyClick={() => {
          setOpenId('');
          onInsertEmptyClick();
        }}
        disableEdit={disableEdit}
      />
    </div>
  );
};

GridItemView.propTypes = {
  /** id for uniquely identifying the grid item in the front end */
  id: PropTypes.string,
  /** List of meta items to show in the grid item */
  items: PropTypes.arrayOf(PropTypes.object),
  /** function invoked when clicking to selecting grid item */
  clickHandler: PropTypes.func,
  /** function for creating new empty placeholder in the grid list */
  onInsertEmptyClick: PropTypes.func,
  /** function for opening corresponding story on new tab */
  onOpenStoryClick: PropTypes.func,
  /** function for removing grid item */
  onRemoveClick: PropTypes.func,
  /** when drag and dropping story from left to grid 
   hovered specifies if grid item is curently being hovered or not */
  hovered: PropTypes.bool,
  /** whether a grid item is being dragged or not  */
  isDragging: PropTypes.bool,
  /** whether a grid item is selected or not */
  isSelected: PropTypes.bool,
  /** function for updating meta data of particular grid item */
  updateInstanceMetaData: PropTypes.func,
  /** fields for creating the skeleton of grid item */
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Boolean that stops an user from editing a rundown list */
  disableEdit: PropTypes.bool,
};

GridItemView.defaultProps = {
  id: '',
  items: [],
  clickHandler: () => {},
  onInsertEmptyClick: () => {},
  onOpenStoryClick: () => {},
  onRemoveClick: () => {},
  hovered: false,
  isDragging: false,
  isSelected: false,
  updateInstanceMetaData: () => {},
  disableEdit: false,
};

export default GridItemView;
