import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/popover';

const ContextMenu = ({
  children,
  mousePosition,
  handleContextClose,
  handleContextClick,
}) => {
  return (
    <Popover
      keepMounted
      position='right'
      open={mousePosition.xPos !== null}
      onClose={handleContextClose}
      onContextMenu={handleContextClick}
      anchorReference="anchorPosition"
      anchorPosition={
        mousePosition.xPos !== null && mousePosition.yPos !== null
          ? { top: mousePosition.yPos, left: mousePosition.xPos }
          : undefined
      }
    >
      {children}
    </Popover>
  );
};

ContextMenu.propTypes = {
  mousePosition: PropTypes.shape({
    xPos: PropTypes.number,
    yPos: PropTypes.number,
  }),
  handleContextClose: PropTypes.func,
  handleContextClick: PropTypes.func,
};

ContextMenu.defaultProps = {
  mousePosition: {
    xPos: null,
    yPos: null,
  },
  handleContextClose: () => {},
  handleContextClick: () => {},
};

export default ContextMenu;
