import { tns } from 'tiny-slider/src/tiny-slider';

const createSlider = (sliderContainer, rootWidth) => {
  const slider = tns({
    container: sliderContainer,
    items: 3,
    slideBy: 1,
    controls: false,
    nav: false,
    loop: false,
    fixedWidth: rootWidth / 3,
  });

  return slider;
};

export default createSlider;
