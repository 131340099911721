import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, TableContainer } from '@material-ui/core';
import Header from 'components/shared/header';
import Footer from 'components/shared/footer';
import useDidMount from 'hooks/useDidMount';
import useStyles from './table-styles';
import { reducer, actionTypes, filterEmptyRow } from '../../utils';
import TableHeader from './components/TableHeader';
import TableBody from './components/TableBody';

const TableView = ({
  columns,
  rows,
  updateField,
  label,
  onCancel,
  tableStyle,
}) => {
  const classes = useStyles();
  const didMount = useDidMount();
  const columnLength = columns.length;

  const [state, dispatch] = useReducer(reducer, rows);

  useEffect(() => {
    if (!didMount && rows.length >= 0)
      dispatch({
        type: actionTypes.INSERT_ROW,
        payload: columns,
      });
  }, [state]);

  const handleOkPress = () => {
    updateField(JSON.stringify(filterEmptyRow(state)));
    onCancel();
  };

  return (
    <div>
      <Header label={label} />
      <div className={classes.tableDiv}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            size="small"
            aria-label="grid-table"
          >
            <TableHeader {...{ columns, tableStyle }} />
            <TableBody
              rows={state}
              columns={columns}
              columnLength={columnLength}
              onUpdate={dispatch}
            />
          </Table>
        </TableContainer>
      </div>
      <Footer onOk={handleOkPress} onCancel={onCancel} />
    </div>
  );
};

TableView.propTypes = {
  /** Columns for table */
  columns: PropTypes.arrayOf(PropTypes.object),
  /** Rows for table */
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  /** callback when any field is updated  */
  updateField: PropTypes.func,
  /** onOk callback */
  onOk: PropTypes.func,
  /** onCancel callback */
  onCancel: PropTypes.func,
};

TableView.defaultProps = {
  columns: [],
  rows: [[], []],
  updateField: () => {},
  onOk: () => {},
  onCancel: () => {},
};

export default TableView;
