import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: 120,
    position: 'relative',
    backgroundColor: theme.palette.dina.storyTimelineBackground,
    backdropFilter: 'blur(30px)',

    '@supports not (backdrop-filter: blur(30px))': {
      backgroundColor: theme.palette.dina.storyTimelineBackgroundNoBackdrop,
    },
  },
}));

export default useStyles;
