import React, { useMemo, memo } from 'react';
import RundownItemTypes from 'screens/rundown/utils/RundownItemTypes';
import TitleBox from './view';
import SortAfterFilter from '../details/utils/SortAfterFilter';

const removeDisabledTemplates = templates => templates.filter(({ disabled }) => !disabled);

const TitleBoxContainer = ({ templates = [], ...props }) => {
  const { templateType, type } = props;

  const sortedSpec = useMemo(
    () =>
      SortAfterFilter(
        removeDisabledTemplates(templates),
        { type: RundownItemTypes[templateType] || type },
        'variant',
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templateType, type],
  );

  return <TitleBox spec={sortedSpec} {...props} />;
};

export default memo(TitleBoxContainer);
