import React, { useState } from 'react';
import GET_INSTANCE_FROM_CACHE from 'graphql/queries/getInstanceFromLocalResolver';
import { useDrag } from 'react-dnd';
import memberTypes from 'graphql/memberTypes';
import dndTypes from 'utils/dndTypes';
import useOpenStory from 'hooks/useOpenStory';
import Popover from 'components/popover';
import distanceInWord from 'utils/distanceInWords';
import { useQuery } from 'react-apollo';
import { IconButton } from '@material-ui/core';
import { ReactComponent as Close } from 'assets/icons/systemicons/close_small.svg';
import InstancePreview from '../../instancePreview';
import InstanceItem from './instanceItem-view';
import useStyles from './instanceItem-styles';

const DraggableGridItem = ({ mId, defaultThumb, publishingtime, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const openStory = useOpenStory();

  const { data } = useQuery(GET_INSTANCE_FROM_CACHE, {
    variables: {
      input: {
        mId,
        mRefId: mId,
      },
    },
  });

  const payload = { id: mId, platform: 'linear' };
  const [{ isDragging }, dragRef] = useDrag({
    item: { type: dndTypes.INSTANCE, payload },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  if (!data) return <div style={{ display: 'none' }} />;

  const {
    mTitle,
    mThumbnailUrl,
    items,
    mUpdatedAt,
    mStoryId,
    mContentKey,
    mProperties,
    mUsers,
    mState,
    mMetaData,
  } = data.instance;
  const timeString = distanceInWord(mUpdatedAt);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenStory = () => {
    mStoryId && openStory(mTitle, mStoryId, memberTypes.STORY, mId);
  };

  const onOpenStory = () => {
    handleClose();
    handleOpenStory();
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const { account, platform } = mProperties;

  return (
    <div ref={dragRef}>
      <InstanceItem
        title={mTitle}
        image={mThumbnailUrl || defaultThumb}
        timingInfo={timeString}
        showFocus={isDragging || Boolean(anchorEl)}
        items={items}
        onClick={handleClick}
        {...rest}
      />
      <Popover
        anchorEl={anchorEl}
        noMargin
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        type="surfaceCardDark"
      >
        <div className={classes.instancePreview}>
          <InstancePreview
            id={mId}
            title={mTitle}
            mContentKey={mContentKey}
            image={mThumbnailUrl || defaultThumb}
            onOpenStory={onOpenStory}
            assignees={mUsers}
            state={mState}
            metaData={mMetaData}
            destination={account.accountTitle}
            platform={platform}
            publishingAt={publishingtime}
          />
          <IconButton className={classes.close} onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </Popover>
    </div>
  );
};

export default DraggableGridItem;
