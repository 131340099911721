import React, { useRef, useEffect, useState, memo } from 'react';
import ReactDOM from 'react-dom';
import getTime from 'screens/rundown/components/editor/utils/getTime';
import { ReactEditor, useSlate } from 'slate-react';
import { Editor, Range } from 'slate';
import { getWords } from 'components/editor/utils';
import useStyles from './hoveringTooltip-styles';

const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

const Menu = React.forwardRef(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={className} />
));

const getWordCount = nodes => {
  return getWords(nodes, true).length;
};

const HoveringTooltip = props => {
  const classes = useStyles();
  const { hostReadSpeed } = props;
  const ref = useRef();
  const editor = useSlate();

  const [textReadSpeed, setTextReadSpeed] = useState(0);

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style');
      return;
    }

    const nodes = Editor.fragment(editor, selection);

    const wordCount = getWordCount(nodes);
    const wordsPerSecond = hostReadSpeed / 60;
    const speakDuration = Math.ceil(wordCount / wordsPerSecond);
    setTextReadSpeed(getTime(speakDuration));

    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();
    el.style.opacity = 1;
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;
  });

  return (
    <Portal>
      <Menu ref={ref} className={classes.menu}>
        {textReadSpeed}
      </Menu>
    </Portal>
  );
};

export default memo(HoveringTooltip);
