/* eslint-disable jsx-a11y/media-has-caption */
import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ButtonComponent from 'components/buttons/button';
import { IconButton } from '@material-ui/core';
import play from 'assets/icons/systemicons/Play_WithCircleBackground.svg';
import { ReactComponent as PlaceholderIcon } from 'assets/icons/systemicons/hourglass.svg';
// import { ReactComponent as OpenIcon } from 'assets/icons/systemicons/editor/open_small.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import Capitalize from 'utils/capitalize';
import MediaDialog from '../primaryAutomation/components/mediaDialog';
import ActionButton from './components/button';
import useStyles from './styles';

const Button = memo(ButtonComponent);
const MemoisedIconButton = memo(IconButton);

const AddMedia = ({
  mediaSrc,
  mediaType,
  mediaWidth,
  hasPlaceholder,
  gotoPlaceholder,
  removePlaceholder,
  createPlaceholder,
  addMedia,
  removeMedia,
  readOnly,
}) => {
  const classes = useStyles({ mediaWidth });

  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [buttonType, setButtonType] = useState('default');

  const openMediaDialog = useCallback(() => setMediaDialogOpen(true), []);

  const closeMediaDialog = useCallback(() => setMediaDialogOpen(false), []);

  const handleMediaSelect = useCallback(e => {
    e.preventDefault();
    addMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAction = useCallback(
    event => {
      if (buttonType === 'default') setButtonType('confirm');
      else if (hasPlaceholder) removePlaceholder(event);
      else removeMedia(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buttonType],
  );

  const handleClickAway = useCallback(
    event => {
      if (buttonType === 'confirm') setButtonType('default');
    },
    [buttonType],
  );

  const placeholderView = () => (
    <div className={classes.mediaContainer}>
      <div className={classes.placeholder}>
        <PlaceholderIcon className={classes.placeholderIcon} />
      </div>
      <div className={classes.placeholderButtons}>
        {/* <ActionButton Icon={OpenIcon} label="Goto Placeholder" onClick={gotoPlaceholder} /> */}
        <ActionButton
          type={buttonType}
          icon={<CloseIcon />}
          onClickAway={handleClickAway}
          label="Remove Placeholder"
          onClick={handleAction}
        />
      </div>
    </div>
  );

  const addView = () => (
    <div className={classes.mediaContainer}>
      <Button
        type="dashedOutlined"
        className={classes.addButton}
        onClick={handleMediaSelect}
        disabled={readOnly}
      >
        <AddIcon className={classes.addIcon} />
      </Button>
      <ActionButton
        icon={<PlaceholderIcon />}
        label="Create Placeholder"
        onClick={createPlaceholder}
      />
    </div>
  );

  const displayView = () => (
    <div className={classes.mediaContainer}>
      <div style={{ width: mediaWidth }} className={classes.media}>
        {mediaType === 'video' ? (
          <>
            <video width={mediaWidth} height="88" className={classes.video}>
              <source src={mediaSrc} type="video/mp4" />
            </video>
            <MemoisedIconButton onClick={openMediaDialog} className={classes.playButton}>
              <img src={play} alt="close" className={classes.playImage} />
            </MemoisedIconButton>
            {mediaDialogOpen && (
              <MediaDialog
                asset={{ mediaType, mContentUrl: mediaSrc }}
                open={mediaDialogOpen}
                onClose={closeMediaDialog}
              />
            )}
          </>
        ) : (
          <img
            src={mediaSrc}
            alt="media"
            width={mediaWidth}
            height="88"
            className={classes.media}
          />
        )}
      </div>
      <ActionButton
        type={buttonType}
        icon={<CloseIcon />}
        onClickAway={handleClickAway}
        label={`Remove ${Capitalize(mediaType)}`}
        onClick={handleAction}
      />
    </div>
  );

  const mediaView = () => (mediaSrc ? displayView() : addView());

  return <div className={classes.root}>{hasPlaceholder ? placeholderView() : mediaView()}</div>;
};

AddMedia.propTypes = {
  mediaSrc: PropTypes.string,
  mediaType: PropTypes.string,
  readOnly: PropTypes.bool,
  mediaWidth: PropTypes.number,
  hasPlaceholder: PropTypes.bool,
  gotoPlaceholder: PropTypes.func,
  removePlaceholder: PropTypes.func,
  createPlaceholder: PropTypes.func,
  addMedia: PropTypes.func,
  removeMedia: PropTypes.func,
};

AddMedia.defaultProps = {
  mediaSrc: null,
  mediaType: 'image',
  readOnly: false,
  mediaWidth: 128,
  hasPlaceholder: false,
  gotoPlaceholder: () => {},
  removePlaceholder: () => {},
  createPlaceholder: () => {},
  addMedia: () => {},
  removeMedia: () => {},
};

export default memo(AddMedia);
