import gql from 'graphql-tag';

export default gql`
  mutation CreateStoryForMember($input: CreateStoryForMemberInput) {
    createStoryForMember(input: $input) {
      mId
      mType
      mTitle
      mRefId
      mCreatedById
    }
  }
`;
