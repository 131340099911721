import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    left: 24,
    width: 'calc(100% - 2 * 24px)',
    overflow: 'hidden',
    position: 'relative',
  },
  sliderContainer: {
    overflow: 'hidden',
    display: 'flex',
  },
}));

export default useStyles;
