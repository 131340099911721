import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { BorderHorizontal } from '@material-ui/icons';
import Tooltip from 'components/tooltip';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import { useSlate, useFocused } from 'slate-react';
import { isElementActive, toggleElement } from 'components/editor/utils';
import { elementTypes } from 'components/editor/constants';
import useStyles from './styles';

const { HORIZONTAL_RULE } = elementTypes;

const HorizontalRuleButton = () => {
  const editor = useSlate();
  const isFocused = useFocused();
  const isActive = isElementActive(editor, HORIZONTAL_RULE);
  const classes = useStyles({ isActive });

  const onMouseDown = useCallback(
    event => {
      event.preventDefault();
      if (isFocused) toggleElement(editor, HORIZONTAL_RULE, isActive);
    },
    [isFocused, editor, isActive],
  );

  return (
    <Tooltip title={tooltipEnum.HORIZONTAL_RULE}>
      <div className={classes.root} role="presentation" {...{ onMouseDown }}>
        <BorderHorizontal className={classes.icon} />
      </div>
    </Tooltip>
  );
};

HorizontalRuleButton.propTypes = {
  /** Type for the button */
  type: PropTypes.oneOf(Object.values(elementTypes)),
};

HorizontalRuleButton.defaultProps = {
  type: HORIZONTAL_RULE,
};

export default HorizontalRuleButton;
