import React, { useCallback, memo } from 'react';
import insertParagraph from 'components/editor/components/paragraph/utils/insertParagraph';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
const { findPath, focus } = ReactEditor;
const { moveNodes, select } = Transforms;
const ReorderDropZone = ({ children }) => {
  const editor = useSlate();
  const onDragEnd = useCallback(({ source, destination }) => {
    if (destination) {
      const { children: editorChildren } = editor;
      const sourceElement = editorChildren[source.index];
      const sourcePath = findPath(editor, sourceElement);
      const destinationElement = editorChildren[destination.index];
      const destinationPath = findPath(editor, destinationElement);
      if (destination.index === editorChildren.length - 1) {
        insertParagraph(editor, { mode: 'highest' });
      }
      moveNodes(editor, { at: sourcePath, to: destinationPath });
      select(editor, destinationPath);
      focus(editor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderContent = useCallback(
    provided => {
      const { droppableProps, innerRef, placeholder } = provided;
      return (
        <div ref={innerRef} {...droppableProps}>
          {children}
          {placeholder}
        </div>
      );
    },
    [children],
  );
  return (
    <DragDropContext {...{ onDragEnd }}>
      <Droppable droppableId="automation">{renderContent}</Droppable>
    </DragDropContext>
  );
};
export default memo(ReorderDropZone);
