import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor, useSelected } from 'slate-react';
import { Transforms } from 'slate';
import { Typography, Button } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import twitterSrc from 'assets/icons/systemicons/publishing_points/twitter.svg';
import Divider from 'components/divider';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import Tooltip from 'components/tooltip';
import FallbackImage from 'assets/images/default/defaultThumbnail.png';
import { elementTypes } from 'components/editor/constants/types';
import { selectElement } from 'components/editor/utils';
import useStyles from './styles';

const { TWEET_THREAD } = elementTypes;

const { removeNodes } = Transforms;

const MemoizedTooltip = memo(Tooltip);

const TweetThread = ({ attributes, children, element }) => {
  const classes = useStyles();
  const [buttonType, setButtonType] = useState('icon');
  const { thumbnail } = useEditorContext();
  const isSelected = useSelected(element);
  const editor = useSlate();
  const path = ReactEditor.findPath(editor, element);

  useEffect(() => {
    if (buttonType !== 'icon') setButtonType('icon');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  const confirmDelete = useCallback(() => {
    removeNodes(editor, { at: path });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMouseOver = useCallback(() => {
    selectElement(editor, element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [index] = path;

  const count = editor.children.reduce(
    (acc, cur) => (cur.type === TWEET_THREAD ? acc + 1 : acc),
    0,
  );
  const showHeader = count > 1;

  return (
    <div {...attributes} style={{ position: 'relative' }}>
      <div className={showHeader ? classes.children : ''}>{children}</div>
      {showHeader && (
        <div
          contentEditable={false}
          style={{ userSelect: 'none', pointerEvents: 'none' }}
          className={classes.header}
        >
          <div className={classes.avatar}>
            <img
              src={thumbnail || FallbackImage}
              alt="rundown-thumbnail"
              className={classes.thumbnail}
            />
            <img className={classes.publishingPoint} src={twitterSrc} alt="publishing-point" />
          </div>
          <div className={classes.thread}>
            <Divider />
            <div className={classes.labelWrapper}>
              <Typography classes={{ root: classes.threadText }}>{`Thread Item #${index +
                1}`}</Typography>
              {buttonType === 'icon' && (
                <MemoizedTooltip title="Delete Thread item" noArrow>
                  <CloseIcon
                    style={{
                      pointerEvents: 'auto',
                    }}
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      if (buttonType === 'icon') setButtonType('confirm');
                    }}
                    className={classes.iconButton}
                    onFocus={() => {}}
                    onMouseOver={handleMouseOver}
                  />
                </MemoizedTooltip>
              )}
              {buttonType === 'confirm' && (
                <Button
                  style={{
                    pointerEvents: 'auto',
                  }}
                  className={classes.confirmButton}
                  onClick={confirmDelete}
                >
                  Click again to Delete
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TweetThread.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

TweetThread.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.TWEET_THREAD,
    children: [],
  },
};

export default memo(TweetThread);
