import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  paper: {
    borderRadius: 8,
    height: '100%',
    background: theme.palette.dina.surfaceCard,
  },
  editorWrapper: { height: 'calc(100% - 48px - 40px)' },
  divider: {
    background: ({ dividerColor }) =>
      dividerColor ? theme.palette.dina[dividerColor] : theme.palette.dina.kanbanTodo,
  },
}));

export default useStyles;
