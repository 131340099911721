import React, { memo } from 'react';
import PropTypes from 'prop-types';
import variants from 'utils/instance/variants';
import GeneralToolbar from './components/generalToolbar';
import TwitterToolbar from './components/twitterToolbar';
import LinearToolbar from './components/linearToolbar';
import CmsToolbar from './components/cmsToolbar';
import useStyles from './styles';

const { GENERAL, LINEAR, TWITTER, CMS, YOUTUBE } = variants;

const preventDefault = event => event.preventDefault();

const variantConditions = [variants.GENERAL, variants.TWITTER, variants.CMS, variants.YOUTUBE];

const Toolbar = ({ variant, readOnly, isAllowed }) => {
  const classes = useStyles({ readOnly });

  if (!isAllowed) {
    return (
      <div className={classes.root} onMouseDown={preventDefault} role="presentation">
        {variant === LINEAR && <LinearToolbar />}
        {variantConditions.includes(variant) && <GeneralToolbar />}
      </div>
    );
  }

  if (variant === YOUTUBE) return null;
  return (
    <div className={classes.root} onMouseDown={preventDefault} role="presentation">
      {variant === LINEAR && <LinearToolbar />}
      {variant === GENERAL && <GeneralToolbar />}
      {variant === TWITTER && <TwitterToolbar />}
      {variant === CMS && <CmsToolbar />}
    </div>
  );
};

Toolbar.propTypes = {
  /** Variant of the toolbar */
  variant: PropTypes.oneOf(Object.values(variants)),
};

Toolbar.defaultProps = {
  variant: variants.GENERAL,
};

export default memo(Toolbar);
