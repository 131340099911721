import React from 'react';
import PropTypes from 'prop-types';
import { publishingPoints } from 'assets/icons/publishingPoints';
import Container from '../container/Container';
import MaterialAvatar from '../material-avatar';
import useStyles from './styles';

const Publish = ({
  children,
  src,
  publishingPoint,
  width,
  height,
  ...rest
}) => {
  const classes = useStyles(width, height)();
  const publishingPointImage = publishingPoints[publishingPoint]
    ? publishingPoints[publishingPoint]
    : publishingPoints.default;

  return (
    <Container {...{ width, height }} {...rest}>
      <MaterialAvatar classes={classes.root} src={publishingPointImage}>
        {children}
      </MaterialAvatar>
    </Container>
  );
};

Publish.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  publishingPoint: PropTypes.string,
};

Publish.defaultProps = {
  children: null,
  src: '',
  publishingPoint: '',
};

export default Publish;
