import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { axisTop, select } from 'd3';
import { publishingPoints } from 'assets/icons/publishingPoints';
import useStyles from './time-grid-styles';
import formatTicks from './formatTicks';
import Instances from './instances';

const TimeGrid = ({
  width,
  xScaleRef,
  newXScaleRef,
  gridHeight,
  margin,
  height,
  zoomTransform,
  instances,
  iconSize,
  draggingRef,
  rootRectRef,
  onSchedule,
}) => {
  const classes = useStyles();
  const groupRef = useRef(null);
  const xAxisRef = useRef(null);

  const init = () => {
    if (xScaleRef.current) {
      const xScale = xScaleRef.current;
      const xAxis = axisTop(xScale);

      xAxis.tickSize(gridHeight).tickFormat(formatTicks(xAxis));

      select(groupRef.current).call(xAxis);

      xAxisRef.current = xAxis;
    }
  };

  const update = () => {
    if (newXScaleRef.current && xAxisRef.current) {
      const newXAxis = xAxisRef.current.scale(newXScaleRef.current);

      select(groupRef.current).call(newXAxis);

      xAxisRef.current = newXAxis;
    }
  };

  useEffect(init, [width]);
  useEffect(update, [zoomTransform]);

  return (
    <g
      ref={groupRef}
      transform={`translate(${margin},${height})`}
      className={`${classes.root} axis-grid`}
    >
      {instances && instances.length > 0 && (
        <Instances
          {...{
            instances,
            newXScaleRef,
            gridHeight,
            iconSize,
            draggingRef,
            height,
            margin,
            rootRectRef,
            onSchedule,
            width,
          }}
        />
      )}
    </g>
  );
};

TimeGrid.propTypes = {
  /** Specifies the width of the timeline */
  width: PropTypes.number.isRequired,
  /** Specifies the initial d3 time scale */
  xScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  /** Specifies the current d3 time scale */
  newXScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  /** Specifies the height of the timeline grid */
  gridHeight: PropTypes.number.isRequired,
  /** Specifies the margin for the timeline grid */
  margin: PropTypes.number.isRequired,
  /** Specifies the height of the timeline */
  height: PropTypes.number.isRequired,
  /** Specifies the current d3 time scale zoom level */
  zoomTransform: PropTypes.shape({
    k: PropTypes.number,
  }).isRequired,
  /** List of all story instances, scheduled and unscheduled */
  instances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      start: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      publishingPoint: PropTypes.oneOf(Object.keys(publishingPoints)),
    })
  ).isRequired,
  /** Specifies the size of the instance icons on timeline grid */
  iconSize: PropTypes.number.isRequired,
  /** Determines if the instances are being dragged or not */
  draggingRef: PropTypes.shape({
    current: PropTypes.bool,
  }).isRequired,
  /** Specifies the dimensions for the root timegrid element */
  rootRectRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  /** Callback to be invoked when an instance is scheduled/re-schduled,
   *  with the newly scheduled instance passed in */
  onSchedule: PropTypes.func.isRequired,
};

export default TimeGrid;
