import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  login: {
    width: '328px',
    top: '45%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    position: 'absolute',

    '@media (max-width:360px)': {
      // eslint-disable-line no-useless-computed-key
      left: '25px',
      right: '25px',
      transform: 'translate3d(0, -50%, 0)',
      width: '310px',
    },
  },
  textLogin: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: 'normal',
    fontSize: '34px',
    letterSpacing: '0.25px',
    color: theme.palette.dina.highEmphasis,
  },
  textDina: {
    marginTop: '14px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '20px',
    fontSize: '14px',
    letterSpacing: '0.25px',
    color: theme.palette.dina.mediumEmphasis,
  },

  formhelper: {
    color: theme.palette.dina.mediumEmphasis,
    background: theme.palette.dina.statusError,
    marginTop: '0',
    paddingLeft: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
  },

  buttonRoot: {
    backgroundColor: theme.palette.dina.statusApproved,
    borderRadius: '4px',
    color: theme.palette.dina.mediumEmphasis,
    height: '40px',
    width: '326px',
    margin: 'auto',
    fontSize: '14px',
    marginTop: '30px',
    '&:hover': {
      backgroundColor: theme.palette.dina.statusApproved,
      color: theme.palette.dina.highEmphasis,
    },
    '&$buttonDisabled': {
      color: theme.palette.dina.highEmphasis,
      opacity: 0.25,
    },
    '@media (max-width:360px)': {
      // eslint-disable-line no-useless-computed-key
      width: '309px',
    },
  },

  buttonDisabled: {},

  link: {
    marginTop: '12px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    flinkontWeight: 'normal',
    lineHeight: '15px',
    fontSize: '12px',
    textAlign: 'right',
    letterSpacing: '0.4px',
    color: theme.palette.dina.mediumEmphasis,
    float: 'right',
  },

  resetText: {
    color: theme.palette.dina.highEmphasis,
    textAlign: 'center',
  },

  inputPadding: {
    paddingTop: '18px',
  },
}));

export default useStyles;
