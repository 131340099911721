import { getWords, getRedWords } from 'components/editor/utils';
import {
  durationTypes,
  getDuration,
  getSeconds,
  getTime,
  getDurationKey,
} from 'screens/rundown/components/editor/utils';

const useUpdateDurationMeta = (blankMetaData, updateMetadata) => {
  const findKey = str => {
    return blankMetaData.find(item => getDurationKey(item) === str).key;
  };

  const handleUpdateDurationFields = (value, hostReadRate, currentMetadata) => {
    if (!value) return [];

    const words = getWords(value.document);
    const redWords = getRedWords(value.document);
    const redDuration = redWords.reduce((acc, word) => {
      const time = getSeconds(word.split('=')[1]) || 0;
      return acc + time;
    }, 0);
    const wordCount = words ? words.length : 0;
    const readRate = hostReadRate; // words per minute
    const wordsPerSecond = readRate / 60;
    const newSpeakDuration = Math.ceil(wordCount / wordsPerSecond);
    const oldWordCount = getDuration(currentMetadata, durationTypes.WORD_COUNT);

    if (
      newSpeakDuration !== getSeconds(getDuration(currentMetadata, durationTypes.SPEAK_DURATION)) ||
      (oldWordCount && oldWordCount !== wordCount)
    ) {
      const clipDuration = !!redDuration
        ? getTime(redDuration)
        : getDuration(currentMetadata, durationTypes.CLIP_DURATION);
      const newTotalDuration = getSeconds(clipDuration) + newSpeakDuration;

      const updatedDurations = [
        { key: findKey(durationTypes.WORD_COUNT), value: wordCount },
        { key: findKey(durationTypes.CLIP_DURATION), value: clipDuration },
        { key: findKey(durationTypes.TOTAL_DURATION), value: getTime(newTotalDuration) },
        { key: findKey(durationTypes.SPEAK_DURATION), value: getTime(newSpeakDuration) },
      ];

      updateMetadata(updatedDurations);
      return updatedDurations;
    }

    return [];
  };
  return [handleUpdateDurationFields];
};

export default useUpdateDurationMeta;
