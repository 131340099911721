import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import InputField from 'components/inputField/InputField';
import Typography from '@material-ui/core/Typography';
import Button from 'components/buttons/button';
import useStyles from './createNew-styles';

const getLegends = variant => {
  if (!variant)
    return {
      popoverTitle: 'Create New Story',
      placeholder: 'Type Story title here...',
      title: 'Story Title',
    };
  const legends = {
    story: {
      popoverTitle: 'Create New Story',
      placeholder: 'Type Story title here...',
      title: 'Story Title',
    },
    user: {
      popoverTitle: 'Create New Story',
      placeholder: 'Type Story title here...',
      title: 'Story Title',
    },
    team: {
      popoverTitle: 'Create New Story',
      placeholder: 'Type Story title here...',
      title: 'Story Title',
    },
    department: {
      popoverTitle: 'Create New Story',
      placeholder: 'Type Story title here...',
      title: 'Story Title',
    },
    rundown: {
      popoverTitle: 'This Rundown does not exist. Create a new Rundown?',
      placeholder: 'Type Rundown title here...',
      title: 'Rundown Title',
    },
    rundownInstance: {
      popoverTitle: 'Create New Instance',
      placeholder: 'Type Instance title here...',
      title: 'Instance Title',
    },
    masterRundown: {
      popoverTitle: 'Create New Master Rundown',
      placeholder: 'Type Rundown title here...',
      title: 'Master Rundown Title',
    },
    feedFilter: {
      popoverTitle: 'Save current filters as a new shortcut',
      placeholder: 'Write a label for the filter here ...',
      title: 'Filter label',
    },
    pitch: {
      popoverTitle: 'Create New Pitch',
      placeholder: 'Type Pitch title here...',
      title: 'Pitch Title',
    },
    placeholder: {
      popoverTitle: 'Create Placeholder',
      placeholder: 'Enter a name for the placeholder',
      title: 'Placeholder title',
    },
  };

  return legends[variant] || legends.story;
};

const CreateForm = props => {
  const { onCreate, onCancel, variant, defaultTitle } = props;
  const classes = useStyles({ variant });
  const [title, setTitle] = useState(defaultTitle);

  const submitStory = event => {
    event.preventDefault();
    onCreate(title.trim());
    setTitle('');
  };

  const { popoverTitle, placeholder, title: titleText } = getLegends(variant);

  return (
    <div className={classes.dialogRoot}>
      <form onSubmit={submitStory} className={classes.formContainer}>
        <div className={classes.formDiv}>
          <div className={classes.topComponents}>
            <Typography className={classes.heading}>{popoverTitle}</Typography>
            <Divider />
            <Typography className={classes.subHeading}>{titleText}</Typography>
            <div className={classes.inputContainer}>
              <InputField
                placeholder={placeholder}
                value={title}
                onChange={value => setTitle(value)}
                autoFocus
              />
            </div>
          </div>
          <div className={classes.bottomComponents}>
            <Divider />
            <div className={classes.buttonLayout}>
              <div className={classes.button}>
                <Button variant="text" onClick={onCancel} shape="full-width">
                  Cancel
                </Button>
              </div>
              <div className={classes.button}>
                <Button
                  variant="contained"
                  type="significant"
                  disabled={!title}
                  shape="full-width"
                  submit
                >
                  Create
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
CreateForm.propTypes = {
  /** Cancel button */
  onCancel: PropTypes.func,
  /** Create button */
  onCreate: PropTypes.func,
  /** Variant of dialog */
  variant: PropTypes.string,
  /** Default title of the dialog */
  defaultTitle: PropTypes.string,
};

CreateForm.defaultProps = {
  onCancel: () => {},
  onCreate: () => {},
  variant: 'story',
  defaultTitle: '',
};

export default CreateForm;
