import React from 'react';
import memberTypes from 'graphql/memberTypes';
import AvatarHeader from '../../avatarHeader';
import DetailWrapper from '../../detailWrapper';
import Info from './info';

const DetailView = ({ showMaster, selectedItem, user, ...rest }) => {
  const { name, id, type } = selectedItem;
  return (
    <DetailWrapper
      header={
        <AvatarHeader
          {...selectedItem}
          {...rest}
          onBackButtonClick={showMaster}
          backButtonLabel="Contacts"
          variant="People"
          disableUpload={(type === memberTypes.USER && id !== user.mId) || false}
          title={name}
          subtitle={selectedItem.properties.jobTitle}
          hideIcons
          hideMenu
        />
      }
      body={<Info {...selectedItem} {...rest} />}
    />
  );
};

export default DetailView;
