import React, { useEffect } from 'react';
import { format, getMonth, getISOYear } from 'date-fns';
import LoadingIndicator from 'components/loadingIndicator';
import useApolloSubscription from 'hooks/useApolloSubscription';
import UPDATE_MONTH_VIEW_SUBSCRIPTION from 'graphql/subscriptions/updateMonthView';
import useGetMembersByPublishingDate from 'hooks/useGetMembersByPublishingDate';
import useOpenStory from 'hooks/useOpenStory';
import useUpdatePublishingDate from 'hooks/useUpdatePublishingDate';
import useFuseSearch from 'hooks/useFuseSearch';
import getDates from 'utils/getDates';
import frontendFiltering from '../../utils/frontendFiltering';
import getUniqueFilters from '../../utils/getUniqueFilters';
import MonthView from './month-view';
import updateCache from '../day/updateCache';

const Month = props => {
  const { time, timeVariant, showStory, showPitch, filters, selectedFilter, ...rest } = props;
  const { startDate, endDate, data: stories } = getDates(time, timeVariant);
  const openStory = useOpenStory();
  const [updatePublishingDate] = useUpdatePublishingDate();
  const search = useFuseSearch();

  // Month view: Month (yyyy-ISO month)
  const monthView = `${getISOYear(startDate)}-${getMonth(time)}`;
  const [subscribe, unSubscribe] = useApolloSubscription(UPDATE_MONTH_VIEW_SUBSCRIPTION, {
    variables: {
      monthView,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      console.log("subscription data month view:", subscriptionData);
      const story = subscriptionData.data.updateMonthViewSubscription;
      updateCache(client, story, startDate, endDate);
    },
  });

  useEffect(() => {
    subscribe();

    return () => {
      unSubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const [data, error, loading] = useGetMembersByPublishingDate(
    startDate,
    endDate,
    'story',
    !showStory,
  );

  const [pData, pError, pLoading] = useGetMembersByPublishingDate(
    startDate,
    endDate,
    'pitch',
    !showPitch,
  );

  if (loading || pLoading) return <LoadingIndicator />;
  if (error || pError) {
    if (error) return <div>Error! {error.message}</div>;
    if (pError) return <div>Error! {pError.message}</div>;
  }

  const { getMembersByPublishingDate: storyData } = data;
  const { getMembersByPublishingDate: pitchData } = pData;

  const filterList = getUniqueFilters(filters, selectedFilter);

  const filteredData = frontendFiltering([...storyData, ...pitchData], filterList, search);

  filteredData.forEach(item => {
    const date = format(item.mPublishingAt, 'YYYY-MM-DD');
    stories[date] && stories[date].push(item);
  });

  const updateDroppedMember = (mId, date) => {
    updatePublishingDate(mId, date, startDate, endDate);
  };

  return <MonthView {...{ stories, time, openStory, updateDroppedMember, ...rest }} />;
};

export default Month;
