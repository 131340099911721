import gql from 'graphql-tag';

export default gql`
  subscription notifyMemberUpdate($mIdSubscribed: String!) {
    notifyMemberUpdateSubscription(mIdSubscribed: $mIdSubscribed) {
      mId
      mTitle
      mRefId
      mDescription
      mState
      mPublishingAt
      mSyncProviders {
        provider
        mMetaData {
          key
          value
        }
      }
      mMetaData {
        key
        value
      }
      locked
      items {
        title
        itemId
        templateType
        templateVariant
        subItems {
          itemId
          title
          templateType
          templateVariant
        }
      }
      mProperties {
        __typename
        ... on PlatformType {
          platform
          account {
            accountUrl
            accountLogo
            accountTitle
            accountId
            accountRefId
            orderType
          }
        }
      }
      mAssignedMembers {
        mId
        mType
      }
    }
  }
`;
