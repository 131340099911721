import { makeStyles } from '@material-ui/styles';

const useStyles = size => {
  return makeStyles(theme => ({
    buttonRectangle: {
      textTransform: 'none',
      boxShadow: '0 0 0 0',
      ...theme.typography.dina.button,
      height: '40px',
      padding: '2px 40px 1px 40px',
      '&:disabled': {
        color: theme.palette.dina.highEmphasis,
        opacity: 0.35,
      },
      margin: '3px 6px',
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
      },
      '&:focus': {
        boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
      },
    },

    buttonFullWidth: {
      textTransform: 'none',
      boxShadow: '0 0 0 0',
      ...theme.typography.dina.button,
      height: '40px',
      padding: '2px 5px 0 5px',
      '&:disabled': {
        color: theme.palette.dina.highEmphasis,
        opacity: 0.35,
      },
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
      },
      '&:focus': {
        boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
      },
    },

    buttonRectanglelg: {
      boxShadow: '0 0 0 0',
      ...theme.typography.dina.button,
      height: '64px',
      width: '148px',
      padding: '0 12px 0 12px',
      '&:disabled': {
        color: theme.palette.dina.highEmphasis,
        opacity: 0.35,
      },
      margin: '3px 6px',
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
      },
      '&:focus': {
        boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
      },
    },

    buttonSquare: {
      textTransform: 'none',
      boxShadow: '0 0 0 0',
      ...theme.typography.dina.button,
      height: size,
      width: size,
      fontSize: '12px',
      lineHeight: '13px',
      padding: '0 24px 0 24px',
      borderRadius: '0px',
      // margin: '0px',
      '&:disabled': {
        color: theme.palette.dina.highEmphasis,
        opacity: 0.35,
      },
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
      },
      '&:focus': {
        boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
      },
    },
    actionButton: {
      textTransform: 'none',
      boxShadow: '0 0 0 0',
      ...theme.typography.dina.button,
      height: '100%',
      width: size,
      fontSize: '12px',
      lineHeight: '13px',
      padding: '0 24px 0 24px',
      borderRadius: '0px',
      '&:disabled': {
        color: theme.palette.dina.highEmphasis,
        opacity: 0.35,
      },
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        boxShadow: '0 0 0 0',
      },
      '&:focus': {
        boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
      },
    },

    buttonRectanglesm: {
      textTransform: 'none',
      boxShadow: '0 0 0 0',
      ...theme.typography.dina.headerTabButton,
      height: '32px',
      width: '115px',
      fontSize: '12px',
      lineHeight: '13px',
      padding: '0px 10px 0 10px',
      '&:disabled': {
        color: theme.palette.dina.highEmphasis,
        opacity: 0.35,
      },
      marginRight: '6px',
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
      },
      '&:focus': {
        boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
      },
    },
    outlined: {
      border: `1px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
      '&:disabled': {
        border: `1px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
      },
      '&:hover': {
        backgroundColor: theme.palette.dina.outlineButtonOnHover,
      },
    },
    contained: {
      border: 'none',
      width: '100%',
      margin: '3px 4px',
    },
    cta: {
      backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
      '&:disabled': {
        backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
      },
      '&:hover': {
        backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
      },
    },
    significant: {
      backgroundColor: theme.palette.dina.statusApproved,
      '&:disabled': {
        backgroundColor: theme.palette.dina.statusApproved,
      },
      '&:hover': {
        backgroundColor: theme.palette.dina.statusApproved,
      },
    },
    danger: {
      backgroundColor: theme.palette.dina.statusError,
      '&:disabled': {
        backgroundColor: theme.palette.dina.statusError,
      },
      '&:hover': {
        backgroundColor: theme.palette.dina.statusError,
      },
    },
    warning: {
      backgroundColor: theme.palette.dina.statusWarning,
      '&:disabled': {
        backgroundColor: theme.palette.dina.statusWarning,
      },
      '&:hover': {
        backgroundColor: theme.palette.dina.statusWarning,
      },
    },
    dashedOutlined: {
      border: `1px dashed ${theme.palette.dina.buttonBorderMediumEmphasis}`,
      '&:disabled': {
        border: `1px dashed ${theme.palette.dina.buttonBorderMediumEmphasis}`,
      },
      '&:hover': {
        backgroundColor: theme.palette.dina.outlineButtonOnHover,
      },
      borderRadius: '4px',
    },
    iconRight: {
      '& > :last-child': {
        position: 'absolute',
        right: '10px',
        color: theme.palette.dina.mediumEmphasis,
      },
    },
    iconLeft: {
      '& > :first-child': {
        position: 'absolute',
        left: '10px',
        color: theme.palette.dina.mediumEmphasis,
      },
    },
  }));
};

export default useStyles;
