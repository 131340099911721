import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TeamDescription from './TeamDescription';
import Members from './Members';
import Departments from './Departments';

const InfoTab = props => {
  const {
    teamTitle,
    description: initialDescription,
    updateDescription,
    addPeopleToTeam,
    addDepartmentToTeam,
    teamMembers,
    teamDepartments,
    containerRef,
    onItemClick,
  } = props;
  
  const [description, setDescription] = useState(initialDescription);

  const onChangeDescription = newDescription => {
    setDescription(newDescription);
    updateDescription(newDescription);
  };

  return (
    <div>
      <TeamDescription
        description={description}
        onChange={onChangeDescription}
      />
      <Members
        teamMembers={teamMembers}
        addPeople={addPeopleToTeam}
        containerRef={containerRef}
        onItemClick={onItemClick}
      />
      <Departments
        teamDepartments={teamDepartments}
        teamTitle={teamTitle}
        addDepartment={addDepartmentToTeam}
        containerRef={containerRef}
        onItemClick={onItemClick}
      />
    </div>
  );
};

InfoTab.propTypes = {
  description: PropTypes.string,
  teamMembers: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mType: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      mDescription: PropTypes.string,
    })
  ),
  teamDepartments: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mType: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      mDescription: PropTypes.string,
    })
  ),

  addPeople: PropTypes.func,
};

InfoTab.defaultProps = {
  description: '',
  teamMembers: [],
  teamDepartments: [],
  addPeople: () => {},
};

export default InfoTab;
