import inlineTypes from '../types/inlineTypes';
import blockTypes from '../types/blockTypes';

const { LIST_ITEM, LIST_ITEM_CHILD } = blockTypes;

/**
 * Converts new slate value to old format
 *
 * @param {Object} value SlateJS v0.58 value
 * @returns {Object} SlateJS v0.47 value
 */

const migrateValue = ({ document, metadata }) => ({
  object: 'value',
  document: {
    object: 'document',
    data: { metadata },
    nodes: document.reduce((acc, { type, data = {}, children }, index) => {
      if (isSecondaryAutomationNode(data)) return acc;

      const nodes = isPrimaryAutomationNode(data)
        ? [emptyBlock, ...getAdjacentSecondaryNodes(document, index)]
        : convertChildren(children);

      const node = {
        object: 'block',
        ...(type && { type }),
        ...(data && { data }),
        ...(children && { nodes }),
      };

      return [...acc, node];
    }, []),
  },
});

const isSecondaryAutomationNode = data => data && data.automationType === 'SECONDARY';
export const isPrimaryAutomationNode = data => data && data.automationType === 'PRIMARY';

const emptyBlock = {
  object: 'block',
  type: 'empty',
  data: {},
  nodes: [
    {
      object: 'text',
      text: '',
      marks: [],
    },
  ],
};

const convertMarks = (marks = []) => ({
  marks: marks.map(([key, value]) => ({
    object: 'mark',
    type: key,
    data: { ...(value !== true && { [key]: value }) },
  })),
});

const wrapListItemChildren = children =>
  children.map(child =>
    child.type === LIST_ITEM_CHILD
      ? child
      : {
          object: 'block',
          type: LIST_ITEM_CHILD,
          data: {},
          children: [child],
        },
  );

const convertChildren = children =>
  children.map(({ type, data, text, children: nestedChildren, ...rest }) => {
    const isTextNode = !type;
    const marks = isTextNode && Object.entries(rest);
    const isInlineNode = type && Object.values(inlineTypes).includes(type);
    const isListItem = type && type === LIST_ITEM;

    return {
      object: 'block',
      ...(isInlineNode && { object: 'inline' }),
      ...(isTextNode && { object: 'text', text }),
      ...(type && { type }),
      ...(data && { data }),
      ...(marks && convertMarks(marks)),
      ...(nestedChildren && {
        nodes: convertChildren(isListItem ? wrapListItemChildren(nestedChildren) : nestedChildren),
      }),
    };
  });

const getAdjacentSecondaryNodes = (document, currentIndex) => {
  const nextPrimaryAutomationIndex = document.findIndex(
    (node, nodeIndex) => nodeIndex > currentIndex && isPrimaryAutomationNode(node.data),
  );

  return document
    .slice(currentIndex + 1, nextPrimaryAutomationIndex && nextPrimaryAutomationIndex)
    .filter(node => isSecondaryAutomationNode(node.data))
    .map(node => ({
      object: 'block',
      type: node.type,
      data: node.data,
      nodes: [
        {
          object: 'text',
          text: '',
          marks: [],
        },
      ],
    }));
};

export default migrateValue;
