import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import useStorageImage from 'hooks/useStorageImage';
import close from 'assets/icons/systemicons/close_small.svg';
import useStyles from './styles';

const MemoisedIconButton = memo(IconButton);

const Thumbnail = ({ onThumbnailRemoveClick, thumbnail }) => {
  const { src, filePath } = thumbnail;
  const { data } = useStorageImage(src);

  const classes = useStyles();
  return (
    <div className={classes.thumbnail}>
      <img src={filePath || data} alt="" className={classes.image} />
      <MemoisedIconButton
        className={classes.closeButton}
        onClick={() => onThumbnailRemoveClick(thumbnail)}
      >
        <img src={close} alt="close" className={classes.closeImage} />
      </MemoisedIconButton>
    </div>
  );
};

Thumbnail.propTypes = {
  /** Callback to be invoked when remove button is clicked */
  onThumbnailRemoveClick: PropTypes.func,
  /** Information about the thumbnail */
  thumbnail: PropTypes.shape({
    /** Id of the image as asset */
    mId: PropTypes.string,
    /** unique Id of the image as asset */
    mRefId: PropTypes.string,
    /** url for recently uploaded thumbnail */
    filePath: PropTypes.string,
    /** mContentKey of the thumbnail image, is used to get the link of the image */
    src: PropTypes.string,
  }),
};

Thumbnail.defaultProps = {
  onThumbnailRemoveClick: () => {},
  thumbnail: {},
};

export default memo(Thumbnail);
