import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  input: {
    background: 'transparent',
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    margin: 0,
    padding: 0,
    color: palette.dina.mediumEmphasis,
    '&:focus': {
      backgroundColor: palette.dina.statusOnFocusedDiscreetFill,
      border: `1px solid ${palette.dina.onSelected}`,
    },
    '&:not(:focus)::selection': { background: 'transparent' },
    '&:not(:focus)': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    ...typography.dina.captionRegular,
  },
  paper: {
    position: 'absolute',
    width: '100%',
    top: '100%',
    left: 0,
    zIndex: 1000,
    backgroundColor: ({ background }) => background || palette.dina.surfaceDialogs,
  },
  list: { borderRadius: 4 },
  menuItem: { color: 'white' },
}));

export default useStyles;
