import { useApolloClient } from 'react-apollo';
import GET_RUNDOWN from 'graphql/queries/getRundown';

const useGetRundown = () => {
  const client = useApolloClient();

  const getRundown = (mId, mRefId) =>
    client
      .query({
        query: GET_RUNDOWN,
        variables: { input: { mId, mRefId } },
        fetchPolicy: 'network-only',
      })
      .then(r => {
        const { data } = r;

        if (data && data.getRundown) {
          return data.getRundown;
        }
        return null;
      });

  return [getRundown];
};

export default useGetRundown;
