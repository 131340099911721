import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .tick': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 10,
      lineHeight: '24px',
      'letter-spacing': '1.5px',
      textTransform: 'uppercase',
      color: theme.palette.dina.mediumEmphasis,
    },
    '& .tick line': {
      stroke: theme.palette.dina.mediumEmphasis,
    },
    '& .major': {
      strokeWidth: 3,
    },
  },
}));

export default useStyles;
