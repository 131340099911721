import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: ({ bold }) => (bold ? 'bold' : 'normal'),
    fontStyle: ({ italic }) => (italic ? 'italic' : 'normal'),
    textDecoration: ({ underline, strikeThrough }) => {
      if (underline) return 'underline';
      if (strikeThrough) return 'line-through';

      return 'none';
    },
    color: ({ color }) => color && color,
  },
}));

export default useStyles;
