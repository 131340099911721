import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  titleContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
  },
  titleDiv: {
    height: 35,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  title: {
    ...theme.typography.dina.listItemLabel,
    lineHeight: '16px',
    letterSpacing: '0.25px',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  options: {
    // width: 96,
    width: 60,
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  leftIcon: {
    marginRight: '8px',
    cursor: 'pointer',
    transition: '0.15s ease-out',
    '&:hover': {
      '& path': {
        transition: '0.15s ease-in',
        fillOpacity: 1,
      },
    },
  },
  rightIcon: {
    cursor: 'pointer',
    transition: '0.15s ease-out',
    '&:hover': {
      '& path': {
        transition: '0.15s ease-in',
        fillOpacity: 1,
      },
    },
  },
}));

export default useStyles;
