import gql from 'graphql-tag';

export default gql`
  query GetAnalytics($input: CreateStoryInput) {
    getAnalytics(input: $input) {
      label
      score
      categoryId
    }
  }
`;
