import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { UsersContext } from 'globalState/users';
import Instance from './instance-container';
import useInstances from './useInstances';
import SortBy from '../../utils/SortBy';

const WrapperStyle = {
  paddingTop: '10px',
  paddingBottom: '5px',
};

const StoryInstances = ({ storyId, containerRef, story, platforms, height }) => {
  const [activeInstance, setActiveInstance] = useState(null);

  const { instances, error, loading, refetch } = useInstances(storyId, 'cache-first');

  const { users: people } = useContext(UsersContext);

  if (loading) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }

  const onInstanceActivation = mId => setActiveInstance(mId);
  const storyRefetch = async () => {
    await refetch();
  };

  const instance = (storyInstance, platform) => (
    <Instance
      key={storyInstance.mId}
      id={storyInstance.mId}
      isActiveInstance={activeInstance === storyInstance.mId}
      backgroundImage={storyInstance.mThumbnailUrl || story.mThumbnailUrl} // in case of rundown image url is null
      {...{
        storyInstance,
        platform,
        storyId,
        containerRef,
        story,
        people,
        onInstanceActivation,
        storyRefetch,
        height,
      }}
    />
  );

  const sortedInstances = SortBy(
    instances.filter(i => i && i !== null),
    false,
  );

  return (
    <div style={WrapperStyle}>
      {platforms.length > 0 &&
        sortedInstances.map(storyInstance => {
          const platform = platforms.find(
            p => p.mProperties.platform === storyInstance.mProperties.platform,
          );
          return platform ? instance(storyInstance, platform) : null;
        })}
    </div>
  );
};

StoryInstances.propTypes = {
  storyId: PropTypes.string.isRequired,
  containerRef: PropTypes.objectOf(PropTypes.object),
  story: PropTypes.objectOf(PropTypes.oneOfType(PropTypes.string, PropTypes.array)),
  platforms: PropTypes.arrayOf(PropTypes.object),
};

StoryInstances.defaultProps = {
  containerRef: null,
  story: null,
  platforms: [],
};

export default StoryInstances;
