import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ChipInput from 'components/chipInput';
import useStyles from './styles';
import MemberList from './memberList';

const DialogView = props => {
  const {
    onOk,
    onCancel,
    variant,
    data,
    members,
    dialogTitle,
    showItems,
    height,
    disableEdit,
  } = props;
  const classes = useStyles();
  const [inputValues, setInputValues] = useState(members);
  const chipInputRef = useRef(null);
  const [input, setInput] = useState('');
  const [pressedKey, setPressedKey] = useState('');
  const inputHelperText = 'Tab or use arrow keys to navigate.\nHit Enter to select';

  useEffect(() => {
    chipInputRef.current.focus();
  }, [chipInputRef]);

  const handleOk = () => {
    onOk(inputValues);
  };

  return (
    <form className={classes.formContainer} style={{ height }}>
      <div className={classes.inputContainer}>
        <Typography classes={{ root: classes.textRoot }}>{dialogTitle}</Typography>
        <div className={classes.inputDiv}>
          <ChipInput
            placeholder={`Type to find ${variant}`}
            variant={variant === 'Host' ? 'People' : variant}
            disabled={variant === 'Host' && inputValues.length === 1}
            chipInputItems={inputValues}
            onDelete={(val, index) => {
              const newInputValues = inputValues.filter(
                (value, currentIndex) => currentIndex !== index,
              );
              setInputValues(newInputValues);
              chipInputRef.current.focus();
            }}
            inputRef={chipInputRef}
            onKeyDown={event => {
              if (event.keyCode === 13) {
                event.preventDefault();
                setPressedKey(event.key);
              } else if (event.key.length === 1) {
                setInput(input + event.key);
                setPressedKey('Character');
              } else if (event.keyCode === 8) {
                const newInput = input.slice(0, -1);
                setInput(newInput);
              } else setPressedKey(event.key);
            }}
            helperText={inputHelperText}
            disableEdit={disableEdit}
          />
        </div>
      </div>
      <Divider classes={{ root: classes.dividerRoot }} />
      <div className={classes.memberListDiv}>
        {(input || showItems) && (
          <MemberList
            data={data}
            variant={variant === 'Host' ? 'People' : variant}
            inputValues={inputValues}
            input={input}
            pressedKey={pressedKey}
            onPressKey={() => {
              setPressedKey('');
            }}
            addChipMember={member => {
              setInputValues([...inputValues, member]);
              chipInputRef.current.focus();
              setInput('');
            }}
            removeChipMember={member => {
              setInputValues(inputValues.filter(value => value.mId !== member.mId));
              chipInputRef.current.focus();
              setInput('');
            }}
          />
        )}
      </div>
      <div className={classes.footer}>
        <Divider classes={{ root: classes.dividerRoot }} />
        <div className={classes.buttonContainer}>
          <Button className={classes.cancelButton} onClick={onCancel} fullWidth>
            Cancel
          </Button>
          <Button
            disabled={!inputValues.length && !members.length}
            fullWidth
            className={classes.okButton}
            onClick={handleOk}
          >
            Ok
          </Button>
        </div>
      </div>
    </form>
  );
};

DialogView.propTypes = {
  /** List of existing members */
  members: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Cancel button */
  onCancel: PropTypes.func,
  /** Create button */
  onOk: PropTypes.func,
  /** variant: People, Team, Department */
  variant: PropTypes.string,
  /** Show the options of item */
  showItems: PropTypes.bool,
  /** Boolean that disables edit in chip input */
  disableEdit: PropTypes.bool,
};

DialogView.defaultProps = {
  members: [],
  onCancel: () => {},
  onOk: () => {},
  variant: 'People',
  showItems: true,
  disableEdit: false,
};

export default DialogView;
