import React from 'react';
import List from './list';
import Detail from './detail';
import MasterDetail from '../masterDetail';

const DepartmentsView = props => (
  <MasterDetail
    MasterComponent={List}
    DetailComponent={Detail}
    {...props}
    // initViewType="detail"
  />
);

export default DepartmentsView;
