import React, { useRef } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import useStyles from './current-time-indicator-styles';

const pillHeight = 16;
const pillWidth = 64;

const CurrentTimeIndicator = ({
  gridHeight,
  newXScaleRef,
  height,
  margin,
  width,
}) => {
  const classes = useStyles();
  const groupRef = useRef(null);
  const now = new Date();
  const translateX = newXScaleRef.current
    ? newXScaleRef.current(now) + margin
    : null;
  const isInView = translateX && translateX >= 0 && translateX <= width;

  return (
    isInView && (
      <g
        ref={groupRef}
        className="current-time-indicator"
        transform={`translate(${translateX},${height})`}
      >
        <line
          className={classes.line}
          x1={0}
          y1={0}
          x2={0}
          y2={-gridHeight}
          strokeWidth={1}
          fill="none"
        />

        <rect
          className={classes.rect}
          x={-pillWidth / 2}
          y={-(gridHeight + pillHeight)}
          width={pillWidth}
          height={pillHeight}
          rx={pillHeight / 2}
          ry={pillHeight / 2}
        />

        <text
          className={classes.text}
          x={0}
          y={-(gridHeight + pillHeight / 2)}
          fill="currentColor"
          textAnchor="middle"
          alignmentBaseline="middle"
          dominantBaseline="middle"
        >
          {format(now, 'HH:mm:ss')}
        </text>
      </g>
    )
  );
};

CurrentTimeIndicator.propTypes = {
  /** Specifies the height of the timeline grid */
  gridHeight: PropTypes.number.isRequired,
  /** Specifies the current d3 time scale */
  newXScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  /** Specifies the height of the timeline */
  height: PropTypes.number.isRequired,
  /** Specifies the margin for the timeline grid */
  margin: PropTypes.number.isRequired,
  /** Specifies the width of the timeline */
  width: PropTypes.number.isRequired,
};

export default CurrentTimeIndicator;
