import { Transforms } from 'slate';

/**
 * Inserts block element
 *
 * @param {Object} options
 * @param {Object} options.editor SlateJS editor instance
 * @param {Function} options.update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const { insertNodes } = Transforms;

const insertBlock = ({ editor, update, type, data = {} }) => {
  const blockElement = { type, data, children: [{ text: '' }] };
  insertNodes(editor, blockElement);

  update({
    type: `${type}-insert`,
    payload: {
      document: editor.children,
      insertedElement: blockElement,
    },
  });
  return editor;
};

export default insertBlock;
