import React, { memo, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as GifIcon } from 'assets/icons/systemicons/editor/gif_off.svg';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import generateName from 'components/editor/utils/generateName';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { removeBlock, updateBlock } from 'components/editor/utils';
import { menuOptions } from 'components/editor/constants';
import actionTypes from 'components/editor/constants/types/actionTypes';
import Button from 'components/buttons/button';
import useImageUpload from 'hooks/useImageUpload';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import useStorageImage from 'hooks/useStorageImage';
import ActionButton from '../addMedia/components/button';
import Menu from '../menu';
import useStyles from './styles';

const imageTypes = ['gif'];

const { setNodes } = Transforms;

const Gif = ({ attributes, children, element, readOnly }) => {
  const classes = useStyles();
  const [buttonType, setButtonType] = useState('default');

  const editor = useSlate();
  const { update } = useEditorContext();
  const { data } = element;
  const { src } = data;
  const cacheRef = useRef(null);
  const { data: imageData } = useStorageImage(src);
  const gifSrc = (src && imageData) || cacheRef.current;

  const onImageLoad = useCallback(async (file, fileUrl) => {
    const fileName = generateName(file.type);
    cacheRef.current = fileUrl;

    const result = await update({
      type: actionTypes.ASSET_INSERT,
      payload: { document: editor.children, file, fileName },
    });

    const updatedData = {
      ...element.data,
      ...result,
    };

    const path = ReactEditor.findPath(editor, element);
    setNodes(editor, { data: updatedData }, { at: path });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadGif = useImageUpload({
    disableResize: true,
    imageTypes,
    onImageLoad,
  });

  const onAddGIFClick = useCallback(event => {
    event.preventDefault();
    uploadGif();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const removeGif = useCallback(
    event => {
      event.preventDefault();
      updateBlock(editor, element, {}, update);
      cacheRef.current = null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const handleAction = useCallback(
    event => {
      if (buttonType === 'default') setButtonType('confirm');
      else removeGif(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buttonType],
  );

  const handleClickAway = useCallback(
    event => {
      if (buttonType === 'confirm') setButtonType('default');
    },
    [buttonType],
  );

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false} className={classes.contentContainer}>
        <div className={classes.iconWrapper}>
          <GifIcon className={classes.icon} />
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.withEllipsis}>
            <p className={classes.title}>GIF</p>
            <Menu className={classes.ellipsis} items={menuOptions} onSelect={onMenuSelect} />
          </div>
          {gifSrc ? (
            <div className={classes.gifWrapper}>
              <img className={classes.gifImage} src={gifSrc} alt="gif" />
              <ActionButton
                onClickAway={handleClickAway}
                type={buttonType}
                icon={<CloseIcon />}
                label="Remove Gif"
                onClick={handleAction}
              />
            </div>
          ) : (
            <Button
              type="dashedOutlined"
              className={classes.gif}
              onClick={onAddGIFClick}
              disabled={readOnly}
            >
              <AddIcon className={classes.addIcon} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Gif.propTypes = {
  /** Boolean that stops image upload */
  readOnly: PropTypes.bool,
};

Gif.defaultProps = {
  readOnly: false,
};

export default memo(Gif);
