import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from '@material-ui/core';
import { setMonth, setDate, getYear, setYear, getMonth } from 'date-fns';
import Toggle from './toggle/Toggle';
import DayPicker from './dayPicker/DayPicker';
import MonthPicker from './monthPicker/MonthPicker';
import Divider from './Divider';
import useStyles from './DatePickerStyles';

const date = new Date();

const DatePicker = ({ onDateChange, toggleValue, setToggle, ...rest }) => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const firstUpdate = useRef(true);
  const [on, setOn] = useState(toggleValue || false);
  const [rootWidth, setRootWidth] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const [calendarDate, setCalendarDate] = useState(date);
  const [selectedDates, setSelectedDates] = useState([date]);

  const init = () => {
    const { width } = rootRef.current.getBoundingClientRect();

    setRootWidth(width - 24 * 2);
  };

  const update = () => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    onDateChange(selectedDates);
  };

  useEffect(init, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(update, [selectedDates]);

  const onMonthSelect = ({ year, monthIndex }) => {
    const newYearTimeStamp = setYear(calendarDate, year);
    const newTimeStamp = setMonth(newYearTimeStamp, monthIndex);
    const newDate = new Date(newTimeStamp);

    setCalendarDate(newDate);
  };

  const onDaySelect = days => {
    const newTimeStamps = days.map(day => setDate(calendarDate, day));
    const newDates = newTimeStamps.map(newTimeStamp => new Date(newTimeStamp));

    setSelectedDates(newDates);
  };

  const onYearChange = offset => {
    const currentYear = getYear(calendarDate);
    const newYear = currentYear + offset;
    const newYearTimeStamp = setYear(calendarDate, newYear);
    const newMonth = offset === -1 ? 11 : 0;
    const newTimeStamp = setMonth(newYearTimeStamp, newMonth);
    const newDate = new Date(newTimeStamp);

    setCalendarDate(newDate);
  };

  const onMonthChange = offset => {
    const currentMonth = getMonth(calendarDate);
    const newMonth = currentMonth + offset;
    const newTimeStamp = setMonth(calendarDate, newMonth);
    const newDate = new Date(newTimeStamp);

    setCalendarDate(newDate);
  };

  const reset = () => {
    const newDate = new Date();
    setSelectedDates([newDate]);
    setCalendarDate(newDate);
  };

  return (
    <div className={`${classes.root} ${collapsed ? 'collapsed' : ''}`} ref={rootRef} {...rest}>
      {rootWidth && (
        <div>
          <Toggle
            {...{
              on: toggleValue || on,
              setOn: setToggle || setOn,
              collapsed,
              setCollapsed,
              selectedDates,
              calendarDate,
              setSelectedDates,
              setCalendarDate,
              reset,
            }}
          />

          <Divider />

          <Collapse in={!collapsed}>
            <MonthPicker
              {...{
                rootWidth,
                calendarDate,
                selectedDates,
                onMonthSelect,
                onYearChange,
              }}
            />

            <Divider />

            <DayPicker
              {...{
                rootWidth,
                calendarDate,
                selectedDates,
                onDaySelect,
                onMonthChange,
              }}
            />

            <Divider />
          </Collapse>
        </div>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  /** Callback to be invoked on date selection
   *  with currently selected date/dates passed in as an array */
  onDateChange: PropTypes.func,
};

DatePicker.defaultProps = {
  onDateChange: () => {},
};

export default DatePicker;
