import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './destination-indicator-styles';

const DestinationIndicator = ({ destination, onClick }) => {
  const classes = useStyles();

  return (
    <div role="presentation" onClick={onClick} className={classes.destination}>
      <Typography className={classes.title}>{destination}</Typography>
    </div>
  );
};

DestinationIndicator.propTypes = {
  /** Current destination of the instance */
  destination: PropTypes.string,
  /** Callback to be invoked when indicator is clicked */
  onClick: PropTypes.func,
};

DestinationIndicator.defaultProps = {
  destination: '',
  onClick: event => {},
};

export default DestinationIndicator;
