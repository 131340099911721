import { makeStyles } from '@material-ui/styles';

const useStyles = (writeLocked, readOnly) =>
  makeStyles(theme => {
    let borderColor = 'transparent';
    if (readOnly) borderColor = theme.palette.dina.statusWarning;
    if (writeLocked) borderColor = theme.palette.dina.statusApproved;
    return {
      root: {
        height: '100%',
        width: '100%',
      },
      paper: {
        border: `1px solid ${borderColor}`,
        borderRadius: 8,
        background: theme.palette.dina.surfaceCard,
      },
      divider: {
        background: ({ dividerColor }) =>
          dividerColor ? theme.palette.dina[dividerColor] : theme.palette.dina.kanbanTodo,
      },
      backdropRoot: {
        position: 'absolute',
      },
      popover: {
        margin: '-8px 0',
      },
      description: {
        ...theme.typography.dina.listItemLabel,
        padding: '16px 16px 16px 24px',
      },
      editorDiv: {
        outline: 'none',
      },
      tabWrapper: {
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8,
        marginRight: 8,
      },
      fadeIn: {
        animationName: '$fadeInOpacity',
        animationTimingFunction: 'ease-in',
        animationDuration: '2s',
        animationIterationCount: 2,
      },

      '@keyframes fadeInOpacity': {
        '0%': {
          boxShadow: 'none',
        },
        '50%': {
          boxShadow: `0 0 0 3px ${theme.palette.dina.onSelected}`,
        },
        '100%': {
          boxShadow: 'none',
        },
      },
    };
  });

export default useStyles;
