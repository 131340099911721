import colors from './colors';

const typography = {
  h1: {
    color: colors.highEmphasis,
    fontSize: '96px',
    letterSpacing: '-1.5',
    lineHeight: '114px',
    fontWeight: 300,
  },
  h2: {
    color: colors.highEmphasis,
    fontSize: '60px',
    letterSpacing: '-0.5',
    lineHeight: '90px',
    fontWeight: 300,
  },
  h3: {
    color: colors.highEmphasis,
    fontSize: '48px',
    letterSpacing: '0',
    lineHeight: '72px',
    fontWeight: 400,
  },
  h4: {
    color: colors.highEmphasis,
    fontSize: '34px',
    letterSpacing: '0.25px',
    lineHeight: '40px',
    fontWeight: 300,
  },
  h5: {
    color: colors.highEmphasis,
    fontSize: '24px',
    letterSpacing: '0.5px',
    lineHeight: '36px',
    fontWeight: 300,
  },
  h6: {
    color: colors.highEmphasis,
    fontSize: '20px',
    letterSpacing: '0.25px',
    lineHeight: '30px',
    fontWeight: 500,
  },
  body1: {
    color: colors.highEmphasis,
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '24px',
    fontWeight: 400,
    fontFamily: 'roboto',
  },
  body2: {
    color: colors.highEmphasis,
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    fontWeight: 400,
    fontFamily: 'roboto',
  },
  subtitle1: {
    color: colors.mediumEmphasis,
    fontSize: '16px',
    letterSpacing: '0.15',
    lineHeight: '24px',
    fontWeight: 500,
  },
  subtitle2: {
    color: colors.mediumEmphasis,
    fontSize: '14px',
    letterSpacing: '0.1',
    lineHeight: '21px',
    fontWeight: 500,
  },
  button: {
    color: colors.highEmphasis,
    fontFamily: 'roboto',
    fontStyle: 'normal',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '15px',
    fontWeight: 400,
    '&:hover': {
      color: colors.highEmphasis,
    },
  },
  headerTabButton: {
    color: colors.mediumEmphasis,
    fontStyle: 'normal',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '14px',
    fontWeight: 400,
    '&:hover': {
      color: colors.highEmphasis,
    },
  },
  overline: {
    color: colors.mediumEmphasis,
    fontStyle: 'normal',
    fontSize: '10px',
    letterSpacing: '1.5px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontFamily: 'roboto',
  },
  listItemLabel: {
    color: colors.highEmphasis,
    fontSize: '14px',
    letterSpacing: '0.4px',
    lineHeight: '15px',
    fontWeight: 400,
  },
  listItemGroupHeadersLabel: {
    color: colors.mediumEmphasis,
    fontSize: '14px',
    letterSpacing: '0.5px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  groupHeaderCounter: {
    fontSize: '12px',
    letterSpacing: '0.4',
    lineHeight: '13px',
    fontWeight: 400,
    fontFamily: 'roboto',
  },
  label: {
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    fontWeight: 400,
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  instanceFooterLabel: {
    fontSize: '14px',
    fontFamily: 'roboto',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: colors.mediumEmphasis,
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  instanceHeaderScheduleLabel: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily: 'roboto',
  },
  instanceHeaderTitle: {
    fontSize: '16px',
    letterSpacing: '0.15',
    lineHeight: '24px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFamily: 'roboto',
  },
  formTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '24px',
    lineHeight: 'normal',
    color: colors.highEmphasis,
  },
  rightColumnHeaderText: {
    fontSize: '14px',
    letterSpacing: '0.25',
    lineHeight: '20px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFamily: 'roboto',
    color: colors.mediumEmphasis,
  },
  teamInfoName: {
    fontSize: '24px',
    fontFamily: 'roboto',
    lineHeight: 'normal',
    color: colors.highEmphasis,
    fontStyle: 'normal',
    fontWeight: '300',
  },
  avatarHeaderTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '24px',
    lineHeight: '28px',
    color: colors.highEmphasis,
  },
  avatarHeaderSubtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: colors.highEmphasis,
  },
  teamInfoType: {
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFamily: 'roboto',
    color: colors.highEmphasis,
  },
  chipLabel: {
    fontSize: '12px',
    letterSpacing: '0.4',
    lineHeight: '13px',
    fontWeight: 'normal',
    fontFamily: 'roboto',
    fontStyle: 'normal',
    color: colors.mediumEmphasis,
  },
  storyCardUsername: {
    fontSize: '8px',
    letterSpacing: '0.4px',
    lineHeight: '13px',
    color: colors.mediumEmphasis,
  },
  storyCardUpdated: {
    fontSize: '10px',
    lineHeight: '13px',
    letterSpacing: '0.4px',
    color: colors.highEmphasis,
  },
  marketplaceHeader: {
    fontSize: '24px',
    fontWeight: '300',
    lineHeight: '28px',
    color: colors.highEmphasis,
  },
  tab: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: colors.mediumEmphasis,
  },
  caption: {
    color: colors.mediumEmphasis,
    fontSize: '12px',
    letterSpacing: '.4px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  captionRegular: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '14px',
    letterSpacing: '0.5px',
  },
  captionMedium: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '14px',
    letterSpacing: '0.25px',
  },
  captionSmall: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '11px',
    letterSpacing: '0.5px',
  },
  listItemLabelMedium: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '15px',
    letterSpacing: '0.4px',
  },
  durationLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    textAlign: 'center',
    color: colors.highEmphasis,
  },
  priorityLabel: {
    color: colors.highEmphasis,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: '13px',
    textTransform: 'uppercase',
  },
  storyCardAvatarText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '9px',
    lineHeight: '11px',
    color: colors.mediumEmphasis,
  },
  iconLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    color: colors.blackHighEmphasis,
  },
  instanceCardTitle: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '13px',
    letterSpacing: '0.25px',
    color: colors.highEmphasis,
  },
  onAir: {
    fontFamily: 'roboto',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '23px',
    letterSpacing: '0.15px',
    color: colors.highEmphasis,
  },
  planned: {
    fontFamily: 'roboto',
    fontStyle: 'normal',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '1px',
    color: colors.mediumEmphasis,
    textTransform: 'uppercase',
  },
  inputLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '10px',
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
};

export default typography;
