import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line max-len
import { ReactComponent as ArrowLeftSrc } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
// eslint-disable-next-line max-len
import { ReactComponent as ArrowRightSrc } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import { ReactComponent as TodayIcon } from 'assets/icons/systemicons/today.svg';
import { Button } from '@material-ui/core';
import {
  addDays,
  addWeeks,
  addMonths,
  addYears,
  subDays,
  subWeeks,
  subMonths,
  subYears,
} from 'date-fns';
import useStyles from './timeNavigator-styles';

const TimeNavigator = props => {
  const { timeVariant, time, onChange, buttonHeight, buttonWidth, resetTitle } = props;
  const classes = useStyles({ buttonHeight, buttonWidth });

  const handleChange = changedTime => {
    onChange(changedTime.toISOString(), timeVariant);
  };

  const handleIncrement = () => {
    if (timeVariant === 'date') handleChange(addDays(time, 1));
    else if (timeVariant === 'week') handleChange(addWeeks(time, 1));
    else if (timeVariant === 'month') handleChange(addMonths(time, 1));
    else if (timeVariant === 'year') handleChange(addYears(time, 1));
  };

  const handleReset = () => {
    handleChange(new Date());
  };

  const handleDecrement = () => {
    if (timeVariant === 'date') handleChange(subDays(time, 1));
    else if (timeVariant === 'week') handleChange(subWeeks(time, 1));
    else if (timeVariant === 'month') handleChange(subMonths(time, 1));
    else if (timeVariant === 'year') handleChange(subYears(time, 1));
  };

  return (
    <div className={classes.root}>
      <Button classes={{ root: classes.button }} onClick={handleDecrement} data-testid="decrement">
        <ArrowLeftSrc />
      </Button>
      <Button
        classes={{ root: classes.button }}
        onClick={handleReset}
        data-testid="reset"
        title={resetTitle}
      >
        <TodayIcon />
      </Button>
      <Button classes={{ root: classes.button }} onClick={handleIncrement} data-testid="increment">
        <ArrowRightSrc />
      </Button>
    </div>
  );
};

TimeNavigator.propTypes = {
  /** Variant of time navigator,
   *  defines time resolution
   */
  timeVariant: PropTypes.oneOf(['date', 'week', 'month', 'year']).isRequired,
  /** Initial time assigned to the components */
  time: PropTypes.string,
  /** Callback to be invoked when navigator's button clicked */
  onChange: PropTypes.func,
  /** Height of button */
  buttonHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Width of button */
  buttonWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Title of reset button */
  resetTitle: PropTypes.string,
};

TimeNavigator.defaultProps = {
  time: new Date().toISOString(),
  onChange: () => {},
  buttonHeight: 32,
  buttonWidth: 32,
  resetTitle: 'Today',
};

export default TimeNavigator;
