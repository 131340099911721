import gql from 'graphql-tag';

export default gql`
  query GetVersionAudits($input: AuditInput) {
    getAudits(input:$input) {
      mUpdatedAt
      mUpdatedBy
      mActionId
      mPayload {
        ... on AuditVersionType {
          mContentKey
        }
      }
    }
  }
`;
