import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  thumbVertical: {
    background: 'transparent',
    justifyContent: 'center',
    width: '100%',
    '&:hover': {
      '& > :first-child': {
        background: 'rgba(255,255,255,0.40)',
        borderLeft: '0.5px solid rgba(255,255,255,0.40)',
        borderRight: '0.5px solid rgba(255,255,255,0.40)',
      },
    },
    '&:active': {
      '& > :first-child': {
        background: 'rgba(255,255,255,0.40)',
        borderLeft: '0.5px solid rgba(255,255,255,0.40)',
        borderRight: '0.5px solid rgba(255,255,255,0.40)',
      },
    },
  },
  thumbVerticalInner: {
    width: '4px',
    height: '100%',
    background: 'rgba(255,255,255,0.15)',
    borderLeft: '0.5px solid rgba(255,255,255,0.07)',
    borderRight: '0.5px solid rgba(255,255,255,0.07)',
    borderRadius: '10px',
  },
  track: {
    transition: 'opacity 200ms ease',
    zIndex: 99,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    right: '0px',
  },
});

const Scrollbar = ({ children }, ref) => {
  const classes = useStyles();
  return (
    <Scrollbars
      ref={ref}
      hideTracksWhenNotNeeded
      id="scrollbar"
      style={{ height: '100%' }}
      renderThumbVertical={scrollProps => (
        <div {...scrollProps} className={classes.thumbVertical} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className={classes.thumbVerticalInner} />
        </div>
      )}
      renderTrackVertical={scrollProps => (
        <div {...scrollProps} className={classes.track} style={{ width: '16px' }} />
      )}
      renderThumbHorizontal={scrollProps => <div {...scrollProps} style={{ display: 'none' }} />}
      renderTrackHorizontal={scrollProps => <div {...scrollProps} style={{ display: 'none' }} />}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  valueChanged: PropTypes.string,
};

Scrollbar.defaultProps = {
  valueChanged: null,
};

const forwardedScrollbar = forwardRef(Scrollbar);
export default forwardedScrollbar;
