import React from 'react';
import PropTypes from 'prop-types';
import PlaySrc from 'assets/icons/systemicons/Play_WithCircleBackground.svg';
import useStyles from './thumbnail-styles';

const Thumbnail = ({ thumbnailImage, isVideo, onClick, width, height }) => {
  const classes = useStyles({ thumbnailImage, width, height });

  return (
    <div className={classes.thumbnail} onClick={onClick} role="presentation">
      {isVideo && <img src={PlaySrc} className={classes.playIcon} alt="" />}
    </div>
  );
};

Thumbnail.propTypes = {
  /** Thumbnail image  */
  thumbnailImage: PropTypes.string,
  /** Boolean that indicates thumbnail for a video asset */
  isVideo: PropTypes.bool,
  /** Callback to be invoked on click */
  onClick: PropTypes.func,
  /** Width of the thumbnail */
  width: PropTypes.number,
  /** height of the thumbnail */
  height: PropTypes.number,
};

Thumbnail.defaultProps = {
  thumbnailImage: null,
  isVideo: false,
  onClick: () => {},
  width: 40,
  height: 24,
};

export default Thumbnail;
