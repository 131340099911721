import React from 'react';
import PropTypes from 'prop-types';
import Select from '../select';
import GeneralLabel from '../generalLabel';
import SeverityIndicator from '../severityIndicator';
import ContainerBox from '../containerBox';

const DropdownView = ({ severity, label, usage, ...rest }) => {
  return (
    <ContainerBox
      Indicator={<SeverityIndicator {...{ severity }} />}
      Label={<GeneralLabel {...{ severity, label, usage }} />}
      Field={<Select severity={severity} itemHeight={40} {...rest} />}
      usage={usage}
    />
  );
};

DropdownView.propTypes = {
  /** label for the composite field */
  label: PropTypes.string,
  /** color for label, indicator and input underline */
  severity: PropTypes.string,
  /** input label  */
};

DropdownView.defaultProps = {
  label: '',
  severity: 'regular',
};
export default DropdownView;
