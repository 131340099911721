import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  thumbnail: {
    borderRadius: '2px',
    position: 'relative',
    width: ({ width }) => width,
    height: ({ height }) => height,
    backgroundImage: ({ thumbnailImage }) => `url(${thumbnailImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  playIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    width: '20px',
    height: '20px',
  },
}));

export default useStyles;
