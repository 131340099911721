/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addDays, subDays, isBefore, isEqual, format } from 'date-fns';
import { ReactComponent as ArrowLeftSrc } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { ReactComponent as ArrowRightSrc } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import { ReactComponent as TodayIconSrc } from 'assets/icons/systemicons/today.svg';
import { ClickAwayListener, Typography, Button } from '@material-ui/core';
import Divider from 'components/divider';
import Calendar from 'components/calendar';
import Popper from 'components/shared/popper';
import Select from 'components/select';
import useStyles from './datePickerButton-styles';

const DatePickerButton = ({ selectedDate, onSelectedDateChange }) => {
  const [selected, setSelected] = useState(selectedDate || new Date());
  const [dropdown, setDropdownValue] = useState('older');
  const [nowShowing, setNowShowing] = useState('Today');
  const [anchorEl, setAnchorEl] = useState(null);
  const [on, setOn] = useState(true);
  const classes = useStyles(on)();

  const items = [
    {
      value: 'all-time',
      title: 'Show All Time',
      selectionTitle: '—',
    },
    {
      value: 'selected-date',
      title: 'Just Selected Date',
      selectionTitle: 'Selected Date',
      showTopDivider: true,
    },
    {
      value: 'newer',
      title: 'Newer',
      selectionTitle: '& Newer',
      info: 'Show selected date\nand forwards in time',
      showTopDivider: true,
    },
    {
      value: 'older',
      title: 'Older',
      selectionTitle: '& Older',
      info: 'Show selected date\nand backwards in time',
      showTopDivider: true,
    },
  ];

  const setPopoverAnchor = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const changeNowShowing = date => {
    const today = format(new Date(), 'YYYY, MM, DD');
    if (isEqual(format(date, 'YYYY, MM, DD'), today)) {
      setNowShowing('Today');
    } else if (isEqual(format(addDays(date, 1), 'YYYY, MM, DD'), today)) {
      setNowShowing('Yesterday');
    } else if (isEqual(format(subDays(date, 1), 'YYYY, MM, DD'), today)) {
      setNowShowing('Tomorrow');
    } else {
      setNowShowing(format(date, 'dd. D MMM YY'));
    }
  };

  const changeSelectedDate = newTime => {
    setSelected(newTime);
    changeNowShowing(newTime);
    onClose();
  };

  const handleIncrement = () => {
    changeSelectedDate(addDays(selected, 1));
  };

  const handleReset = () => {
    changeSelectedDate(new Date());
    if (!on) {
      setDropdownValue('selected-date');
      setOn(true);
    }
  };

  const handleDecrement = () => {
    changeSelectedDate(subDays(selected, 1));
  };

  useEffect(() => {
    if (selectedDate) {
      setSelected(selectedDate);
      changeNowShowing(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selected && dropdown) {
      const date = selected.toISOString();
      const newer = new Date();
      switch (dropdown) {
        case 'all-time':
          if (on) {
            onSelectedDateChange([new Date()]);
            setNowShowing('All time');
            setOn(false);
          } else {
            onSelectedDateChange([date]);
            setDropdownValue('selected-date');
            changeNowShowing(date);
            setOn(true);
          }
          break;
        case 'selected-date':
          onSelectedDateChange([date, date]);
          setOn(true);
          break;
        case 'newer':
          if (isBefore(format(date, 'YYYY, MM, DD'), format(newer, 'YYYY, MM, DD'))) {
            onSelectedDateChange([date, newer]);
          } else {
            onSelectedDateChange([date, date]);
          }
          setOn(true);
          break;
        case 'older':
          onSelectedDateChange([date]);
          setOn(true);
          break;
        default:
          onSelectedDateChange([date]);
          setOn(true);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, dropdown]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.navigator}>
          <Button
            classes={{ root: classes.button }}
            onClick={handleDecrement}
            data-testid="decrement"
            disabled={!on}
          >
            <ArrowLeftSrc />
          </Button>
          <Button classes={{ root: classes.button }} onClick={handleReset} data-testid="reset">
            <TodayIconSrc />
          </Button>
          <Button
            classes={{ root: classes.button }}
            onClick={handleIncrement}
            data-testid="increment"
            disabled={!on}
          >
            <ArrowRightSrc />
          </Button>
          <Typography classes={{ root: classes.headerText }} onClick={setPopoverAnchor}>
            {nowShowing}
          </Typography>
        </div>
        <div className={classes.dropdown}>
          <Select
            selectedValue={dropdown}
            items={items}
            onChange={setDropdownValue}
            usage="datePicker"
            disabled={!on}
          />
        </div>

        <Popper {...{ anchorEl }}>
          <ClickAwayListener onClickAway={onClose}>
            <div className={classes.calendarContainer}>
              <Calendar
                selectedDate={selected.toISOString()}
                changeSelectedDate={changeSelectedDate}
                hideUnscheduleButton
              />
            </div>
          </ClickAwayListener>
        </Popper>
      </div>
      <Divider />
    </>
  );
};

DatePickerButton.propTypes = {
  publishingTime: PropTypes.string,
  onPublishingTimeChange: PropTypes.func,
};

DatePickerButton.defaultProps = {
  publishingTime: '',
  onPublishingTimeChange: () => {},
};

export default DatePickerButton;
