import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ReactComponent as ArrowDoubleIcon } from 'assets/icons/systemicons/arrows/arrow_double.svg';
import { ReactComponent as CloseSmallIcon } from 'assets/icons/systemicons/close_small.svg';
import { InputBase, IconButton } from '@material-ui/core';
import useStyles from './autocomplete-styles';

const CustomAutocomplete = ({ options, initialValue, onChange }) => {
  const classes = useStyles();
  const current = options.find(option => option.name === initialValue);
  const [value, setValue] = useState(current || null);

  const defaultProps = {
    options,
    getOptionLabel: option => option.name,
    getOptionSelected: (option, val) => {
      return option.name === val.name;
    },
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    if (newValue === null) {
      onChange(null);
      setValue(null);
    } else {
      onChange(newValue.name);
      setValue(newValue);
    }
  };
  return (
    <Autocomplete
      {...defaultProps}
      id="custom-input-demo"
      options={options}
      value={value}
      autoHighlight
      onChange={handleChange}
      classes={{
        inputFocused: classes.inputFocused,
        paper: classes.comboPaper,
        option: classes.comboOption,
        noOptions: classes.comboNoOptions,
      }}
      renderInput={params => {
        const endAdornmentProp = params.InputProps.endAdornment.props.children;
        const handleClose = endAdornmentProp[0].props.onClick;
        const handlePopperOpen = endAdornmentProp[1].props.onClick;
        return (
          <InputBase
            ref={params.InputProps.ref}
            classes={{
              root: classes.inputBase,
            }}
            inputProps={params.inputProps}
            fullWidth
            endAdornment={
              <div className={classes.endAdornment}>
                {value !== null && (
                  <IconButton
                    className={classes.iconButton}
                    tabIndex="-1"
                    size="small"
                    onClick={handleClose}
                  >
                    <CloseSmallIcon />
                  </IconButton>
                )}
                {
                  <IconButton
                    className={classes.iconButton}
                    tabIndex="-1"
                    size="small"
                    onClick={handlePopperOpen}
                  >
                    <ArrowDoubleIcon />
                  </IconButton>
                }
              </div>
            }
          />
        );
      }}
    />
  );
};
export default CustomAutocomplete;
