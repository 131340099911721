import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ICONS from 'assets/icons/icons';
import IconButton from 'components/buttons/iconButton';
import useStyles from './rundown-styles';

const RundownListItem = props => {
  const {
    title,
    image,
    inTime,
    plan,
    clickHandler,
    active,
    setPreviewRundown,
  } = props;
  const classes = useStyles(props)();

  return (
    <ListItem
      button
      selected={active}
      classes={{
        root: classes.listItem,
        button: classes.button,
        selected: classes.selected,
      }}
      onClick={clickHandler}
    >
      <Grid container className={classes.grid}>
        <Grid item className={classes.image}>
          <img className={classes.img} alt="complex" src={image} />
        </Grid>
        <Grid item xs>
          <Typography className={classes.title}>{title}</Typography>
          <Grid container justify="flex-start">
            <Grid item xs className={classes.timeItem}>
              <Typography className={classes.timeHeader}>IN</Typography>
              <Typography className={classes.timeValue}>{inTime}</Typography>
            </Grid>
            <Grid item xs className={classes.timeItem}>
              <Typography className={classes.timeHeader}>PLANNED</Typography>
              <Typography className={classes.timeValue}>{plan}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ListItemSecondaryAction>
        <Grid item className={classes.icon}>
          <IconButton
            className={classes.iconButton}
            title="Preview Rundown"
            icon={ICONS.DISCLOSURE_ARROW_RIGHT}
            onClick={setPreviewRundown}
          />
        </Grid>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

RundownListItem.propTypes = {
  /** Rundown title */
  title: PropTypes.string.isRequired,
  /** The in time, should be a string and container should take care of date formating */
  inTime: PropTypes.string,
  /** The plan time, should be a string and container should take care of date formating */
  plan: PropTypes.string,
  /** Url for thumbnail image */
  image: PropTypes.string.isRequired,
  /** Click handler function */
  clickHandler: PropTypes.func,
  /** Rundown active or not */
  active: PropTypes.bool,
  /** set the preview rundown */
  setPreviewRundown: PropTypes.func,
};

RundownListItem.defaultProps = {
  inTime: '--:--:--',
  plan: '--:--:--',
  clickHandler: () => {},
  active: false,
  setPreviewRundown: () => {},
};

export default RundownListItem;
