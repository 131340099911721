import getIndicatorNewPosition from '../../functions/getIndicatorNewPosition';

const showStaticInidicator = (indicatorNode, sliderWrapper) => {
  const newItem = document.querySelector('.selected-month');
  const newPosition = getIndicatorNewPosition(newItem, indicatorNode, sliderWrapper);

  Object.assign(indicatorNode.style, {
    visibility: 'hidden',
    transform: `translate(${newPosition}px, 0)`,
  });

  newItem.classList.add('static-selected');
};

export default showStaticInidicator;
