import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      height: 'calc(100% - 105px)',
      marginLeft: '21px',
      marginRight: '20px',
      '& > *:not(:last-child)': {
        marginRight: '7px',
      },
    },
    cell: {
      flexGrow: '1',
    },
  };
});

export default useStyles;
