import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/plus_ultraSmall_1px.svg';
import { useDrop } from 'react-dnd';
import dndTypes from 'utils/dndTypes';
// eslint-disable-next-line max-len
import { ReactComponent as ResetNumberingIcon } from 'assets/icons/systemicons/rundown/gridIcons/resetNumbering.svg';
import colors from 'theme/colors';
import styleEnum from '../gridItem/utils/styleEnum';
import useStyles from './gridHeader-styles';

const GridHeader = ({ fields, onResetOrderingClick, scrollingId, disableEdit, ...props }) => {
  const classes = useStyles();

  const [{ hovered, dragType }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.INSTANCE, dndTypes.GRID_INSTANCE],
    canDrop: (item, monitor) => !disableEdit,
    drop: (item, monitor) => {
      const { type, title, id, publishingAt } = monitor.getItem();
      if (type && type === dndTypes.GRID_INSTANCE) {
        const { payload } = monitor.getItem();
        const result = {
          source: {
            index: payload.index,
            droppableId: payload.scrollingId,
          },
          destination: {
            index: 0,
            droppableId: scrollingId,
          },
        };
        props.onDragEnd(result);
      } else if (type && type === dndTypes.INSTANCE) {
        const { payload } = monitor.getItem();
        const { id: instanceId, platform } = payload;
        if (platform === 'linear') {
          props.onMoveInstanceToRundown(instanceId, 0);
        }
      } else if (!props.showMasterEditView)
        props.onCreateInstanceForStory(id, title, publishingAt, 0);
    },
    collect: monitor => ({
      hovered: monitor.isOver(),
      dragType: monitor.getItemType(),
    }),
  });

  const showPlus = dragType !== dndTypes.GRID_INSTANCE;

  return (
    <div ref={dropRef} className={classes.root}>
      <div className={classes.mainDiv}>
        <div className={classes.iconDiv}>
          <ResetNumberingIcon className={classes.icon} onClick={onResetOrderingClick} />
        </div>
        <div className={classes.pseudoDivMeta} />

        <Typography classes={{ root: classes.titleRoot }}>Title</Typography>
        {fields.map((field, index) => {
          const fieldWidth = Number(field.width);
          const isHighlight = styleEnum.HIGHLIGHT === field.style;
          return (
            !!fieldWidth && (
              <Typography
                classes={{ root: classes.fieldTitle }}
                style={{
                  minWidth: fieldWidth + 4,
                  paddingLeft: !index ? '3px' : '4px',
                  color: isHighlight && colors.highEmphasis,
                }}
                key={field.id}
              >
                {field.label || field.name}
              </Typography>
            )
          );
        })}
        <div className={classes.pseudoDivEllipsis} />
      </div>
      {hovered && !disableEdit && (
        <div className={classes.fabDiv}>
          {showPlus && <AddIcon className={`${classes.addIcon} ${classes.selectionCount}`} />}
        </div>
      )}
      {!hovered && (
        <Divider
          classes={{
            root: classes.divider,
          }}
        />
      )}
    </div>
  );
};

export default GridHeader;
