import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    // height: '100%',
    // overflowY: 'scroll',
    // overflowX: 'hidden',
    // padding: 0,
    flex: 1,
  },
  sidebarHeader: {
    height: '40px',
    minHeight: '40px',
    margin: '0 0 0 12px',
    paddingBottom: '4px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  sidebarHeaderText: {
    ...theme.typography.dina.h5,
    lineHeight: '28px',
    color: theme.palette.dina.mediumEmphasis,
  },
}));

export default useStyles;
