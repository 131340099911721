import { makeStyles } from '@material-ui/styles';

const useStyles = (isToday, isWeekend) =>
  makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      minWidth: '100px',
    },
    columnHeader: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '64px',
    },
    date: {
      height: '24px',
      width: '80px',
      background: isToday ? theme.palette.dina.blackHoverOverlay : null,
      border: isToday ? `1px solid ${theme.palette.dina.onFocus}` : null,
      boxSizing: 'border-box',
      borderRadius: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dateText: {
      ...theme.typography.dina.listItemLabel,
    },
    dateBlock: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      cursor: 'pointer',
    },
    allDay: {
      border: `1px solid ${theme.palette.dina.dividerLight}`,
      borderRight: `1px solid transparent`,
      height: '22px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: isWeekend ? theme.palette.dina.blackHoverOverlay : null,
    },
    cardContainerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      marginBottom: '40px',
    },
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      borderLeft: `1px solid ${theme.palette.dina.dividerLight}`,
      background: isWeekend ? theme.palette.dina.blackHoverOverlay : null,
      height: '100%',
      padding: '8px',
    },
    createNewContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      opacity: 0,
      margin: '8px 0',
      '&:hover': {
        opacity: 1,
      },
    },
    button: {
      background: theme.palette.dina.surfaceCard,
      border: `1px solid ${theme.palette.dina.buttonBackgroundOutlined}`,
      boxSizing: 'border-box',
      borderRadius: '4px',
      transition: '0.3s',
      '&:hover': {
        backgroundColor: theme.palette.dina.whiteHoverOverlay,
      },
      '&:active': {
        border: `1px solid ${theme.palette.dina.onFocus}`,
      },
      height: '72px',
      minHeight: '72px',
      flexGrow: 1,
    },
    buttonChild: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    buttonText: {
      ...theme.typography.dina.captionRegular,
      fontSize: '10px',
      lineHeight: '11px',
      color: theme.palette.dina.mediumEmphasis,
      marginTop: '10px',
    },
    padding: {
      width: '4px',
    },
  }));

export default useStyles;
