import React, { useState, useRef, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ToolbarContext } from 'contexts/Toolbar';
import protoKanbanView from 'assets/images/prototypes/planning/Status.png';
import protoSwimlaneView from 'assets/images/prototypes/planning/Timeline.png';
import protoDayView from 'assets/images/prototypes/planning/Day.png';
import protoWeekView from 'assets/images/prototypes/planning/Week.png';
import protoMonthView from 'assets/images/prototypes/planning/Month.png';
import { PlanningContext } from 'globalState';
import Header from './components/header';
import Day from './components/day';
import Week from './components/week';
import Month from './components/month';
import Status from './components/status';
import Hour from './components/hour';
import Toolbar from './components/toolbar';
import Search from './components/search/search-container';
import useStyles from './planning-styles';

const defaultTabs = [
  { title: 'Status', timeVariant: 'date', fallbackImage: protoKanbanView },
  { title: 'Hours', timeVariant: 'date', fallbackImage: protoSwimlaneView },
  { title: 'Day', timeVariant: 'date', fallbackImage: protoDayView },
  { title: 'Week', timeVariant: 'week', fallbackImage: protoWeekView },
  { title: 'Month', timeVariant: 'month', fallbackImage: protoMonthView },
];

const PlanningView = ({ tabs }) => {
  const containerRef = useRef(null);

  const [, setToolbarElements] = useContext(ToolbarContext);
  const [onlyShowUsersItems, setOnlyShowUsersItems] = useState(false);
  const [showStory, setShowStory] = useState(true);
  const [showPitch, setShowPitch] = useState(true);

  const [filters, setFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState();

  const {
    view,
    setView,
    time,
    setTime,
    tabIndex,
    setTabIndex,
    timeVariant,
    setTimeVariant,
    savedFilters,
    setSavedFilters,
    stateCollapsed,
    setStateCollapsed,
  } = useContext(PlanningContext);

  const saveFilter = useCallback(
    label => {
      if (filters && filters.length > 0) {
        setSavedFilters([...savedFilters, { label, expression: filters }]);
      }
    },
    [filters, savedFilters, setSavedFilters],
  );

  useEffect(() => {
    setToolbarElements(
      <Toolbar
        {...{ showStory, setShowStory, showPitch, setShowPitch }}
        filters={savedFilters}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        onCreateFilter={saveFilter}
        updateSavedFilters={setSavedFilters}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveFilter, savedFilters, selectedFilter, showPitch, showStory]);

  const [, setDummy] = useState(protoKanbanView);
  const classes = useStyles();

  const handleTimeChange = (newTime, newTimeVariant) => {
    setTime(newTime);
    newTimeVariant && setTimeVariant(newTimeVariant);
  };

  const handleTabChange = (tab, index) => {
    setTabIndex(index);
    setDummy(tab.fallbackImage);
    setTimeVariant(tab.timeVariant);
    setView(tab.title);
  };

  const handleTransition = (newTime, newView) => {
    handleTimeChange(newTime);
    let index;
    const newTab = tabs.filter((tab, idx) => {
      if (tab.title === newView) {
        index = idx;
        return true;
      }
      return false;
    })[0];
    handleTabChange(newTab, index);
  };

  const handleChipChange = chips => setFilters(chips);

  const viewHelper = planningView => {
    switch (planningView) {
      case 'Status':
        return (
          <Status
            {...{
              time,
              timeVariant,
              onlyShowUsersItems,
              filters,
              selectedFilter,
              stateCollapsed,
              setStateCollapsed,
            }}
          />
        );
      case 'Day':
        return (
          <Day
            {...{
              time,
              timeVariant,
              containerRef,
              showStory,
              showPitch,
              onlyShowUsersItems,
              filters,
              selectedFilter,
            }}
          />
        );
      case 'Week':
        return (
          <Week
            time={time}
            timeVariant={timeVariant}
            onTransition={handleTransition}
            {...{
              showStory,
              showPitch,
              onlyShowUsersItems,
              filters,
              selectedFilter,
            }}
          />
        );
      case 'Month':
        return (
          <Month
            time={time}
            timeVariant={timeVariant}
            onTransition={handleTransition}
            {...{
              showStory,
              showPitch,
              onlyShowUsersItems,
              filters,
              selectedFilter,
            }}
          />
        );
      case 'Hours':
        return (
          <Hour
            {...{
              time,
              timeVariant,
              showStory,
              showPitch,
              onlyShowUsersItems,
              filters,
              selectedFilter,
            }}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <div ref={containerRef} className={classes.mainDiv}>
      <div className={classes.main}>
        <div className={classes.header}>
          <Header
            time={time}
            timeVariant={timeVariant}
            setTimeVariant={setTimeVariant}
            onTimeChange={handleTimeChange}
            tabs={defaultTabs}
            onTabChange={handleTabChange}
            tabIndex={tabIndex}
            {...{ onlyShowUsersItems, setOnlyShowUsersItems }}
          />
        </div>
        {viewHelper(view)}
        <Search
          view={view}
          time={time}
          chips={filters}
          chipChangeHandler={handleChipChange}
          customFilter={selectedFilter}
          toggleCustomFilter={() => setSelectedFilter(null)}
        />
      </div>
    </div>
  );
};

PlanningView.propTypes = {
  /** array of tab items that will be used for tab labels */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      /** Title to show on the tab */
      title: PropTypes.string,
      /** Time variant of the planning view
       * Describes the time resolution
       */
      timeVariant: PropTypes.oneOf(['date', 'week', 'month', 'year']),
      /** Dummy image to show if the planning view is not present */
      fallbackImage: PropTypes.any,
      /** Makes the tab disabled */
      disabled: PropTypes.bool,
    }),
  ),
};

PlanningView.defaultProps = {
  tabs: defaultTabs,
};

export default PlanningView;
