import variants from 'utils/instance/variants';
import elementTypes from './types/elementTypes';
import version from './version';

const initialValue = {
  version,
  document: [
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
};

const twitterInitialValue = {
  version,
  document: [
    {
      type: 'tweet-thread',
      data: {},
      children: [
        {
          type: 'description',
          data: {
            content: '',
          },
          children: [
            {
              text: '',
            },
          ],
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
};

const cmsInitialValue = {
  version,
  document: [
    {
      type: 'info',
      data: {},

      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
};

const youtubeInitialValue = {
  version,
  document: [
    {
      type: 'description',
      data: {
        content: '',
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'video',
      data: {
        showThumnail: true,
      },
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'tags',
      data: {},
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'privacy',
      data: {},
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text: '' }],
    },
  ],
};

const initialValues = (variant, isAllowed) => {
  if (variant === variants.TWITTER && isAllowed) return twitterInitialValue;
  if (variant === variants.CMS && isAllowed) return cmsInitialValue;
  if (variant === variants.YOUTUBE && isAllowed) return youtubeInitialValue;
  return initialValue;
};

export default initialValues;
