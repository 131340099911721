import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: 20,
    backgroundColor: theme.palette.dina.statusError,
    borderRadius: 10,
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.25), 0px 6px 6px rgba(0, 0, 0, 0.25)',
    visibility: ({ notificationCount }) => (notificationCount ? 'visible' : 'hidden'),
    width: ({ notificationCount }) =>
      notificationCount ? 20 + (notificationCount.toString().length - 1) * 10 : 20,
    border: `0.5px solid ${theme.palette.dina.highEmphasis}`,
  },
  text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    ...theme.typography.dina.listItemLabelMedium,
    letterSpacing: 'normal',
    color: theme.palette.dina.highEmphasis,
  },
}));

export default useStyles;
