import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Radio as MaterialRadio } from '@material-ui/core';
import useStyles from './RadioButtonStyle';

function RadioButtonCheckedSVG(props) {
  const { className, mousedown } = props;
  const { classIcon, classWrapper } = className;
  return (
    <div className={classWrapper}>
      <SvgIcon className={classIcon}>
        <circle cx="12" cy="12" r="11" stroke="#3997FF" />
        {!mousedown && (
          <circle cx="12" cy="12" r="6" fill="#3997FF" stroke="#3997FF" />
        )}
      </SvgIcon>
    </div>
  );
}

function RadioButtonUncheckedSVG({ className }) {
  const { classIcon, classWrapper } = className;
  return (
    <div className={classWrapper}>
      <SvgIcon className={classIcon}>
        <circle cx="12" cy="12" r="11" stroke="#3997FF" />
      </SvgIcon>
    </div>
  );
}

const RadioButton = props => {
  const { value, selected, disabled, onClick, size } = props;
  const [mouseDown, setMouseDown] = useState(false);
  const classes = useStyles({ size });

  const renderCheckedIcon = () =>
    mouseDown ? (
      <RadioButtonCheckedSVG
        mousedown={mouseDown}
        className={{
          classIcon: classes.checkedIcon,
          classWrapper: classes.iconWrapper,
        }}
      />
    ) : (
      <RadioButtonUncheckedSVG
        className={{
          classIcon: classes.uncheckedIcon,
          classWrapper: classes.iconWrapper,
        }}
      />
    );

  const renderUncheckedIcon = () =>
    disabled ? (
      <RadioButtonCheckedSVG
        mousedown={mouseDown}
        className={{
          classIcon: classes.uncheckedIcon,
          classWrapper: classes.iconWrapper,
        }}
      />
    ) : (
      <RadioButtonCheckedSVG
        mousedown={mouseDown}
        className={{
          classIcon: classes.checkedIcon,
          classWrapper: classes.iconWrapper,
        }}
      />
    );

  return (
    <MaterialRadio
      name={`name-${value}`}
      checked={selected}
      value={value}
      disabled={disabled}
      onChange={event => onClick(event.target.value)}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
      classes={{
        root: classes.root,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      icon={renderCheckedIcon()}
      checkedIcon={renderUncheckedIcon()}
    />
  );
};

RadioButton.propTypes = {
  /** Value of the radio button (text or number) */
  value: PropTypes.string,
  /** Values: true/false */
  selected: PropTypes.bool,
  /** Values: true/false */
  disabled: PropTypes.bool,
  /** onClick function: selects the value that is clicked */
  onClick: PropTypes.func,
  /** Size of the Radio group icon */
  size: PropTypes.number,
};

RadioButton.defaultProps = {
  value: '',
  selected: false,
  disabled: false,
  onClick: () => {},
  size: 40,
};

export default RadioButton;
