import { event, drag } from 'd3';
import { isFuture, closestTo, addSeconds } from 'date-fns';
import getTranslateValues from '../../../getTranslateValues';

const dragStartPos = {};

/* eslint-disable no-param-reassign */

/**
 * @typedef {Object} instanceProperties
 * @property {HTMLElement} instance The instance to schedule
 * @property {HTMLElement} element The element to which menu should be attached to
 * @property {Object} draggingRef Determines if the instances are being dragged or not
 * @property {Object} selfDraggingRef Determines if the instances are being dragged or not
 * @property {number} gridHeight Specifies the height of the timeline grid
 * @property {number} height Specifies the height of the timeline
 * @property {Object} rootRectRef Specifies the dimensions for the root timegrid element
 * @property {number} iconSize Specifies the size of the instance icons on timeline grid
 * @property {Object} newXScaleRef Specifies the current d3 time scale
 * @property {number} margin Specifies the margin for the timeline grid
 * @property {Function} onSchedule Callback to be invoked when an instance is scheduled/re-schduled
 * @property {Function} setNewTranslateX Callback to be invoked to set the dragged x position
 * @property {Function} setNewTranslateY Callback to be invoked to set the dragged y position
 */

/**
 * Creates draggability on instance
 *
 * @param {instanceProperties}
 * @returns {Object} D3 drag instance
 */

const createDragBehavior = ({
  instance,
  element,
  draggingRef,
  selfDraggingRef,
  gridHeight,
  height,
  rootRectRef,
  iconSize,
  newXScaleRef,
  margin,
  onSchedule,
  setNewTranslateX,
  setNewTranslateY,
}) => {
  const { translateX, translateY, ...rest } = instance;

  const onDragStart = () => {
    draggingRef.current = true;
    selfDraggingRef.current = true;

    dragStartPos.x = event.x;
    dragStartPos.y = event.y;
  };

  const onDrag = () => {
    const { x, y } = event;

    if (x !== dragStartPos.x || y !== dragStartPos.y) {
      const { clientX } = event.sourceEvent;
      const topBoundary = gridHeight - height;
      const rootRect = rootRectRef.current;
      const bottomBoundary = height - iconSize * 2 - gridHeight;
      const newXScale = newXScaleRef.current;
      const currentTimeCoordinate = newXScale(new Date());
      const leftBoundary = rootRect.left + margin + iconSize / 2;
      const rightBoundary = rootRect.right - margin - iconSize / 2;
      let xCoordinate = x - iconSize / 2;
      let yCoordinate = y + iconSize;

      if (y <= topBoundary) {
        yCoordinate = topBoundary + iconSize;
      } else if (y >= bottomBoundary) {
        yCoordinate = bottomBoundary + iconSize;
      }

      if (currentTimeCoordinate > 0 && xCoordinate <= currentTimeCoordinate) {
        xCoordinate = newXScale(addSeconds(new Date(), 5));
      } else if (clientX <= leftBoundary) {
        xCoordinate = 0;
      } else if (clientX >= rightBoundary) {
        xCoordinate = rightBoundary - leftBoundary;
      }
      setNewTranslateX(xCoordinate);
      setNewTranslateY(yCoordinate);
    }
  };

  const onDragEnd = () => {
    const { x, y } = event;

    if (x !== dragStartPos.x || y !== dragStartPos.y) {
      const newXScale = newXScaleRef.current;
      const newTransform = element.attr('transform');
      const { translateX: newXCoordinate } = getTranslateValues(newTransform);
      const newSchedule = newXScale.invert(newXCoordinate);
      const tickValues = newXScale.ticks();
      const futureTickValues = tickValues.filter(tickValue => isFuture(tickValue));
      const start = closestTo(newSchedule, futureTickValues);

      if (isFuture(start)) {
        onSchedule({ ...rest, start: start.toISOString() });
      } else {
        setNewTranslateX(translateX);
        setNewTranslateY(translateY);
      }
    }

    draggingRef.current = false;
    selfDraggingRef.current = false;
  };

  const dragInstance = drag()
    .on('start', onDragStart)
    .on('drag', onDrag)
    .on('end', onDragEnd);

  return dragInstance;
};

export default createDragBehavior;
