import React, { createContext, useState } from 'react';

export const CoverPhotoContext = createContext({
  coverPhoto: { mId: null, url: null },
});

const CoverPhotoStore = ({ children }) => {
  const [coverPhoto, setCoverPhoto] = useState({
    coverPhoto: { mId: null, url: null },
  });

  return (
    <CoverPhotoContext.Provider value={{ coverPhoto, setCoverPhoto }}>
      {children}
    </CoverPhotoContext.Provider>
  );
};

export default CoverPhotoStore;
