import React, { createContext, useState } from 'react';

export const DatePickerContext = createContext();

const DatePickerProvider = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <DatePickerContext.Provider value={[isExpanded, setIsExpanded]}>
      {children}
    </DatePickerContext.Provider>
  );
};

export { DatePickerProvider };
