/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/buttons/button';
import { ReactComponent as Delete } from 'assets/icons/systemicons/ellipsis_menu/delete.svg';
import { ReactComponent as ArchiveOffSrc } from 'assets/icons/systemicons/ellipsis_menu/archive_off.svg';
import { ReactComponent as UnarchiveOffSrc } from 'assets/icons/systemicons/ellipsis_menu/archive_unarchive_off.svg';
import { ReactComponent as BookmarkOffSrc } from 'assets/icons/systemicons/ellipsis_menu/favorite_off.svg';
import { ReactComponent as BookmarkOnSrc } from 'assets/icons/systemicons/ellipsis_menu/favorite_on.svg';
import { ReactComponent as JoinSrc } from 'assets/icons/systemicons/ellipsis_menu/participate_join.svg';
import { ReactComponent as LeaveSrc } from 'assets/icons/systemicons/ellipsis_menu/participate_leave.svg';
// import { ReactComponent as Share } from 'assets/icons/systemicons/ellipsis_menu/share_off.svg';
import useStyles from './subMenu-styles';

const SubMenu = ({ groupType, archived, bookmarked, joined, clickHandler }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonContainer}>
      <div className={classes.button}>
        <Button
          shape="action-button"
          type="danger"
          variant="text"
          size={60}
          onClick={event => {
            event.stopPropagation();
            clickHandler('archive');
          }}
        >
          {groupType === 'pitch' ? (
            <p>
              <Delete />
              <span className={classes.title}>Delete</span>
            </p>
          ) : (
            <p>
              {archived ? <UnarchiveOffSrc /> : <ArchiveOffSrc />}
              <span className={classes.title}>{archived ? 'Unarchive' : 'Archive'}</span>
            </p>
          )}
        </Button>
      </div>
      <div className={classes.button}>
        <Button
          shape="action-button"
          type="warning"
          variant="text"
          size={60}
          onClick={event => {
            event.stopPropagation();
            clickHandler('bookmark');
          }}
        >
          <p>
            {bookmarked ? <BookmarkOnSrc /> : <BookmarkOffSrc />}
            <span className={classes.title}>{bookmarked ? 'Unmark' : 'Bookmark'}</span>
          </p>
        </Button>
      </div>
      <div className={classes.button}>
        <Button
          shape="action-button"
          type="cta"
          variant="text"
          size={60}
          onClick={event => {
            event.stopPropagation();
            clickHandler('join');
          }}
        >
          <p>
            {joined ? <LeaveSrc /> : <JoinSrc />}
            <span className={classes.title}>{joined ? 'Leave' : 'Join'}</span>
          </p>
        </Button>
      </div>
      {/* <div className={classes.button}>
        <Button // This button is disabled for now. Share functionality will be added leter
          shape="action-button"
          type="significant"
          variant="text"
          size={60}
          onClick={event => {
            event.stopPropagation();
            clickHandler('share');
          }}
        >
          <p>
            <Share />
            <span className={classes.title}>Share</span>
          </p>
        </Button>
      </div> */}
    </div>
  );
};

SubMenu.propTypes = {
  clickHandler: PropTypes.func,
  bookmarked: PropTypes.bool,
};

SubMenu.defaultProps = {
  clickHandler: () => {},
  bookmarked: false,
};

export default SubMenu;
