const getMetadataWithMosTag = (formFields, metadataFields) => {
    if(!metadataFields) return [];

    const fields = [];
    metadataFields.forEach(field=>{
        const formField = (formFields || []).find(f=> f.id === field.key);
        const fieldWithMosTag = {...field};

        if(formField && formField.mostag) {
            fieldWithMosTag.mostag = formField.mostag;
        }
        
        fields.push(fieldWithMosTag);
    })

    return  fields;
}

export default getMetadataWithMosTag;