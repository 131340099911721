import { makeStyles } from '@material-ui/styles';

const useStyles = on =>
  makeStyles(theme => ({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 39,
    },
    formControlLabel: {
      margin: 0,
    },
    label: {
      color: on
        ? theme.palette.dina.onSelected
        : theme.palette.dina.iconInactive,
      flexShrink: 1,
    },
    disabled: {},
    outlined: {
      backdropFilter: 'blur(15px)',
      height: 24,
      padding: 0,
      color: theme.palette.dina.mediumEmphasis,
      borderColor: theme.palette.dina.buttonBorderMediumEmphasis,

      '&$disabled': {
        color: theme.palette.dina.disabled,
        borderColor: theme.palette.dina.buttonBorderMediumEmphasis,
      },
    },
    iconButton: {
      padding: 6,
      marginRight: 12,
      '&.disabled .icon': {
        color: theme.palette.dina.buttonBorderMediumEmphasis,
      },
      '&:not(.disabled) .icon': {
        color: theme.palette.dina.iconInactive,
      },
    },
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    icon: {
      width: 24,
      height: '100%',
      boxSizing: 'border-box',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 8,
      marginRight: 4,
    },
  }));

export default useStyles;
