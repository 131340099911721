import memberTypes from 'graphql/memberTypes';
import BookMarksOn from 'assets/icons/systemicons/ContainerLeft_Navbar/bookmarks_on.svg';
import BookMarksOff from 'assets/icons/systemicons/ContainerLeft_Navbar/bookmarks_off.svg';
import MyStoriesOn from 'assets/icons/systemicons/ContainerLeft_Navbar/mystories_on.svg';
import MyStoriesOff from 'assets/icons/systemicons/ContainerLeft_Navbar/mystories_off.svg';
import TeamsOn from 'assets/icons/systemicons/ContainerLeft_Navbar/teamStories_on.svg';
import TeamsOff from 'assets/icons/systemicons/ContainerLeft_Navbar/teamStories_off.svg';
import RundownsOn from 'assets/icons/systemicons/ContainerLeft_Navbar/rundowns_on.svg';
import RundownsOff from 'assets/icons/systemicons/ContainerLeft_Navbar/rundowns_off.svg';
import DepartmentsOn from 'assets/icons/systemicons/ContainerLeft_Navbar/departments_on.svg';
import DepartmentsOff from 'assets/icons/systemicons/ContainerLeft_Navbar/departments_off.svg';
import AllStoriesOn from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_on.svg';
import AllStoriesOff from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_off.svg';
import AllPitchesOn from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_on.svg';
import AllPitchesOff from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_off.svg';

export default [
  {
    title: 'Bookmarks',
    selectionType: memberTypes.USER_BOOKMARK,
    selectedImage: BookMarksOn,
    unselectedImage: BookMarksOff,
  },

  {
    title: 'My stories',
    selectionType: memberTypes.USER,
    selectedImage: MyStoriesOn,
    unselectedImage: MyStoriesOff,
  },
  {
    title: 'My Teams',
    selectionType: memberTypes.TEAM_USER,
    selectedImage: TeamsOn,
    unselectedImage: TeamsOff,
  },
  {
    title: 'My Departments',
    selectionType: memberTypes.DEPARTMENT_USER,
    selectedImage: DepartmentsOn,
    unselectedImage: DepartmentsOff,
  },
  {
    title: 'All Stories',
    selectionType: memberTypes.STORY,
    selectedImage: AllStoriesOn,
    unselectedImage: AllStoriesOff,
  },
  {
    title: 'All Pitches',
    selectionType: memberTypes.PITCH,
    selectedImage: AllPitchesOn,
    unselectedImage: AllPitchesOff,
  },
  {
    title: 'My Rundowns',
    selectionType: memberTypes.RUNDOWN_USER,
    selectedImage: RundownsOn,
    unselectedImage: RundownsOff,
  },
];
