/**
 *  Hook for sending a story to archive *
 */

import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import memberTypes from 'graphql/memberTypes';

const ARCHIVE_STORY = gql`
  mutation ArchiveStory($input: ArchiveStoryInput) {
    archiveStory(input: $input) {
      mId
    }
  }
`;

const GET_STORIES = gql`
  query GetStories($input: GetMemberInput, $limit: Int, $nextToken: String) {
    getMembersPagination(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
      }
      nextToken
    }
  }
`;

const GET_STORIES_BY_UPDATING_DATE = gql`
  query GetStoriesByUpdatedDate(
    $input: GetMembersByPublishingDateInput
    $limit: Int
    $nextToken: String
  ) {
    getMembersByUpdatedAtDate(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
      }
      nextToken
    }
  }
`;

const useArchiveStory = () => {
  const [mutate] = useMutation(ARCHIVE_STORY);

  const archive = async (mId, groupId, memberType, queryVariables) => {
    await mutate({
      variables: {
        input: {
          mId,
        },
      },
      update: proxy => {
        try {
          let query = GET_STORIES;
          let queryName = 'getMembersPagination';

          if (memberType === memberTypes.STORY || memberType === memberTypes.PITCH) {
            query = GET_STORIES_BY_UPDATING_DATE;
            queryName = 'getMembersByUpdatedAtDate'
          }

          const storyList = proxy.readQuery({
            query,
            variables: queryVariables,
          });

          const { items, nextToken } = storyList[queryName];
          proxy.writeQuery({
            query,
            variables: queryVariables,
            data: {
              [queryName]: {
                items: [...items].filter(story => story.mId !== mId),
                __typename: 'MemberType',
                nextToken,
              },
            },
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      },
    });
  };

  return [archive];
};

export default useArchiveStory;
