import { makeStyles } from '@material-ui/styles';

const useStyles = image =>
  makeStyles(theme => ({
    root: {
      marginBottom: '18px',
      marginTop: '16px',
      borderRadius: '8px',
      backgroundColor: theme.palette.dina.surfaceCard,
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
      minWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    inspector: {
      paddingBottom: '56px',
      width: '100%',
    },
    cover: {
      backgroundImage: `linear-gradient(180deg, rgba(50, 62, 77, 0) 0%, rgba(32, 42, 54, 0.95)), url("${image}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      borderRadius: '8px 8px 0 0',
      height: '400px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    chip: {
      margin: ' 0 0 35px 11px',
      minWidth: '40px',
      alignSelf: 'flex-start',
      backgroundColor: theme.palette.dina.chipBackground,
    },
    chipLabel: {
      ...theme.typography.dina.overline,
      color: theme.palette.dina.mediumEmphasis,
    },
    title: {
      ...theme.typography.dina.h4,
      padding: '16px',
    },
    info: {
      height: '64px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      margin: '0 24px 0 16px',
      borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
      padding: '13px 0',
    },
    source: {},
    sourceTitle: {
      ...theme.typography.dina.listItemLabel,
    },
    sourceUrl: {
      fontWeight: '400',
      color: theme.palette.dina.mediumEmphasis,
    },
    section: {
      ...theme.typography.dina.caption,
    },
    date: {
      ...theme.typography.dina.caption,
    },
    content: {
      ...theme.typography.dina.body1,
      margin: '12px 24px 12px 16px',
      '& a': {
        color: theme.palette.dina.onSelected,
      },
    },
    assets: {
      marginLeft: '16px',
      display: 'flex',
    },
    imageThumbnail: {
      width: '60px',
      height: '60px',
      borderRadius: '4px',
      marginRight: '8px',
      objectFit: 'cover',
    },
    fileAnchor: {
      textDecoration: 'none',
    },
    fileThumbnail: {
      width: '60px',
      height: '60px',
      marginRight: '8px',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.dina.dividerLight}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    fileDuration: {
      ...theme.typography.dina.caption,
      color: theme.palette.dina.mediumEmphasis,
    },
    buttons: {
      position: 'sticky',
      bottom: 0,
      minHeight: '56px',
      borderTop: `1px solid ${theme.palette.dina.dividerLight}`,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgb(72,92,114)',
      padding: '0px 4px',
      borderRadius: '0 0 8px 8px',
    },
  }));

export default useStyles;
