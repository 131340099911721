import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { makeStyles } from '@material-ui/styles';
import { useLoadingIndicator } from 'contexts/LoadingContext';
import { useScrollIntoView } from 'contexts/ScrollIntoViewContext';
import Fab from '@material-ui/core/Fab';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import CREATE_INSTANCE from 'graphql/mutations/createInstance';
import useGetRundown from 'hooks/useGetRundown';
import getTwitterMetaKey from 'utils/metadata/getTwitterMetaKey';
import Menu from 'components/menu/createInstanceMenu/CreateStoryInstanceMenu';
import updateStoryInstanceCache from './updateStoryInstanceCache';

const useStyles = makeStyles(theme => ({
  floatingButton: {
    zIndex: 13,
    background: 'linear-gradient(45deg, #27D171 0%, #0385D1 100%)',
    color: theme.palette.dina.iconInactive,
    position: 'absolute',
    height: '40px',
    width: '40px',
    top: 100,
    right: '50%',
    '&:hover': {
      '-webkit-filter': theme.palette.dina.onHover,
    },
  },
  menu: {
    position: 'relative',
    right: '100px',
  },
  fadeIn: {
    boxShadow: `0 0 0 3px ${theme.palette.dina.onFocus}`,
  },
}));

const ScrollInstanceIntoView = async (storyId, instanceId) => {
  setTimeout(() => {
    const target = document.getElementById(`${storyId}-${instanceId}`);

    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, 1000);
};

const CreateStoryInstance = ({ storydata, platforms, blankMetaData }) => {
  const classes = useStyles();
  const [, setCurrentClicked] = useScrollIntoView();

  const { getMember } = storydata;
  const [anchorEl, setAnchorEl] = useState(null);
  const { setLoadingIndicatorFor } = useLoadingIndicator();

  const [getRundown] = useGetRundown();

  const [createInstanceForStory] = useMutation(CREATE_INSTANCE, {
    update: async (proxy, mutationResult) => {
      const { createInstance } = mutationResult.data;

      await updateStoryInstanceCache(proxy, getMember.mId, createInstance);
      await ScrollInstanceIntoView(getMember.mId, createInstance.mId);
      setCurrentClicked(createInstance.mId);
    },
  });

  const createNewInstance = async (account, platform) => {
    const input = {
      mId: getMember.mId,
      mTitle: getMember.mTitle,
      mDescription: 'some description',
      mProperties: {
        __typename: 'PlatformType',
        platform,
        account: {
          accountUrl: account.accountUrl,
          accountLogo: account.accountLogo,
          accountTitle: account.accountTitle,
          accountId: account.accountId,
        },
      },
      mMetaData: [],
    };

    setLoadingIndicatorFor('create');

    if (account && platform) {
      if (platform === 'linear') {
        if (account.isUnassigned) {
          await createInstanceForStory({
            variables: {
              input,
            },
          });
          setLoadingIndicatorFor(null);
          return;
        }

        getRundown(account.accountId, account.accountId).then(async rundown => {
          if (rundown) {
            input.mProperties.account.accountRefId = rundown.mRefId;
            input.mPublishingAt = rundown.mPublishingAt;
            const hostMetaData =
              rundown.mMetaData && rundown.mMetaData.find(val => val.key === 'host')
                ? rundown.mMetaData.find(val => val.key === 'host')
                : null;
            const host =
              hostMetaData && hostMetaData.value !== 'null' ? JSON.parse(hostMetaData.value) : null;

            if (host && host.mProperties && host.mProperties.readSpeed) {
              input.mMetaData.push({
                key: 'hostReadSpeed',
                value: host.mProperties.readSpeed,
              });
            } else {
              input.mMetaData.push({
                key: 'hostReadSpeed',
                value: 150,
              });
            }
          } else {
            input.mProperties.account.accountId = null;
          }

          await createInstanceForStory({
            variables: {
              input,
            },
          });
          setLoadingIndicatorFor(null);
        });
        return;
      }

      if (platform === 'twitter') {
        input.mMetaData.push({
          key: getTwitterMetaKey(blankMetaData),
          value: 1,
        });
      }

      await createInstanceForStory({
        variables: {
          input,
        },
      });
      setLoadingIndicatorFor(null);
    }
  };

  return (
    <>
      <Fab
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
        className={classes.floatingButton}
      >
        <AddIcon />
      </Fab>
      <Menu
        onClose={data => {
          if (data.platform && data.account) createNewInstance(data.account, data.platform);
          setAnchorEl(null);
        }}
        position="top"
        anchorEl={anchorEl}
        data={platforms}
      />
    </>
  );
};

export default CreateStoryInstance;
