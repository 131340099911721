import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  rect: {
    fill: 'rgba(255, 0, 188, 0.1)',
  },
  text: {
    ...theme.typography.dina.captionSmall,
    fill: theme.palette.dina.mediumEmphasis,
  },
  line: {
    stroke: '#FF00BC',
  },
}));

export default useStyles;
