import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';
import IconButton from 'components/buttons/iconButton';
import ICONS from 'assets/icons/icons';
import useMenuAnchor from 'hooks/useMenuAnchor';
import postMessage from 'utils/postMessage';
import { useSidebarContext } from 'globalState';
import useStyles from './styles';

const graphicsOptions = [
  {
    title: 'Edit graphics',
    action: 'open-in-editor',
  },
];

const getDefaultOptions = hasPlaceholder => [
  {
    title: 'Details...',
    action: 'show-details',
  },
  ...[
    {
      ...(hasPlaceholder
        ? {
            title: 'Remove Placeholder',
            action: 'remove-placeholder',
          }
        : { title: 'Create Placeholder', action: 'create-placeholder' }),
    },
  ],
];

const EllipsisMenu = ({
  className,
  mosobj,
  isGraphic,
  hasPlaceholder,
  handleOpenDetails,
  openPlaceholderDialog,
  removePlaceholder,
}) => {
  const classes = useStyles();
  const { anchorEl, closeMenu, toggleMenu } = useMenuAnchor();

  const [, dispatch] = useSidebarContext();

  const onClick = useCallback(({ action }) => {
    closeMenu();

    if (action === 'show-details') handleOpenDetails();

    if (action === 'open-in-editor' && mosobj) {
      postMessage(mosobj, 'pilotedge');
      dispatch({
        type: 'openRightSidebar',
        payload: false,
      });
      dispatch({
        type: 'setRightSelection',
        payload: 'graphicsbox',
      });
    }

    if (action === 'create-placeholder') openPlaceholderDialog();

    if (action === 'remove-placeholder') removePlaceholder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = useMemo(() => (isGraphic ? graphicsOptions : getDefaultOptions(hasPlaceholder)), [
    hasPlaceholder,
    isGraphic,
  ]);

  return (
    <div className={`${classes.root} ${className}`}>
      <IconButton icon={ICONS.MORE_VERTICAL} onClick={toggleMenu} />

      <Menu
        {...{ anchorEl }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        classes={{ paper: classes.menu, list: classes.menuList }}
        PaperProps={{
          onMouseLeave: closeMenu,
        }}
      >
        {options &&
          options.length > 0 &&
          options.map(({ title, ...rest }) => (
            <MenuItem
              key={title}
              classes={{
                root: classes.menuItem,
                selected: classes.menuItemSelected,
              }}
              onClick={() => onClick({ title, ...rest })}
            >
              {title}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

EllipsisMenu.propTypes = {
  /** CSS class to override styling */
  className: PropTypes.string,
};

EllipsisMenu.defaultProps = {
  className: '',
};

export default memo(EllipsisMenu);
