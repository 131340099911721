import { makeStyles } from '@material-ui/styles';

const useStyles = props =>
  makeStyles(theme => ({
    listItem: {
      padding: 0,
      left: 0,
      backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
      maxHeight: '72px',
      minWidth: '327px',
      boxShadow: `72px -1px 0px ${theme.palette.dina.dividerLight}`,
      '&$selected, &$selected:hover': {
        backgroundColor: theme.palette.dina.onFocus,
      },
    },
    image: {
      width: '48px',
      height: '48px',
      margin: '12px',
    },
    img: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      borderRadius: '14px',
      border: () => {
        if (props.state === 'active') return '2px solid orange';
        if (props.state === 'ready') return '2px solid red';
        return '';
      },
    },
    grid: {
      display: 'flex',
      alignItems: 'center',
      minWidth: '350px',
    },
    iconButton: {
      padding: '8px',
    },
    title: {
      ...theme.typography.dina.teamInfoType,
      display: '-webkit-box',
      lineClamp: 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    timeItem: {
      maxWidth: '56px',
      marginRight: '8px',
    },
    timeHeader: {
      ...theme.typography.dina.caption,
    },
    timeValue: {
      ...theme.typography.dina.groupHeaderCounter,
      color: theme.palette.dina.highEmphasis,
    },
    button: {
      '&:hover': {
        background: theme.palette.dina.surfaceAppBackgroundMain,
      },
    },
    icon: {
      color: theme.palette.dina.iconInactive,
      cursor: 'pointer',
    },
    selected: {},
  }));

export default useStyles;
