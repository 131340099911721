import React, { memo } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line max-len
import ArrowDropDownUnboxedSrc from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet_unboxed.svg';
import useStyles from './styles';

const View = ({ icon, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.icon} role="presentation" {...{ onClick }}>
      {icon}
      <img src={ArrowDropDownUnboxedSrc} alt="expand" />
    </div>
  );
};

View.propTypes = {
  /** Transition icon to be shown */
  icon: PropTypes.node,
  /** Callback to be invoked on click */
  onClick: PropTypes.func,
};

View.defaultProps = {
  icon: null,
  onClick: () => {},
};

export default memo(View);
