import React, { createContext, useState, useEffect } from 'react';
import { DEFAULT_STATE } from 'utils/planningViews';

export const PlanningContext = createContext();

const SAVED_STATE = JSON.parse(window.localStorage.getItem('plans'));

const PlanningProvider = ({ children }) => {
  const state = { ...DEFAULT_STATE, ...SAVED_STATE };

  const [view, setView] = useState(state.view);
  const [time, setTime] = useState(state.time);
  const [tabIndex, setTabIndex] = useState(state.tabIndex);
  const [timeVariant, setTimeVariant] = useState(state.timeVariant);
  const [savedFilters, setSavedFilters] = useState(state.savedFilters);
  const [stateCollapsed, setStateCollapsed] = useState(state.stateCollapsed);

  useEffect(() => {
    window.localStorage.setItem(
      'plans',
      JSON.stringify({
        view,
        time,
        tabIndex,
        timeVariant,
        savedFilters,
        stateCollapsed,
      }),
    );
  }, [view, time, tabIndex, timeVariant, savedFilters, stateCollapsed]);

  return (
    <PlanningContext.Provider
      value={{
        view,
        setView,
        time,
        setTime,
        tabIndex,
        setTabIndex,
        timeVariant,
        setTimeVariant,
        savedFilters,
        setSavedFilters,
        stateCollapsed,
        setStateCollapsed,
      }}
    >
      {children}
    </PlanningContext.Provider>
  );
};

export { PlanningProvider };
