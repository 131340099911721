import gql from 'graphql-tag';

export default gql`
  query GetRundown($input: GetRundownInput) {
    getRundown(input: $input) {
      mId
      mRefId
      mOrder
      mPreorder
      mRefId
      mTitle
      mType
      mState
      mPreparingState
      startTime
      duration
      mPlannedDuration
      mPrePlannedDuration
      mPublishingAt
      mCoverPhotoUrl
      mCoverPhotoKey
      mThumbnailUrl
      mThumbnailKey
      mRundownTemplateId
      version
      recurrence {
        duration
        startTime
        timeZone
        start
        dailyExclusive
      }
      mMetaData {
        key
        value
      }
    }
  }
`;
