import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';

/**
 * Selects a given SlateJS element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element
 * @returns {Object} SlateJS editor instance
 */

const selectElement = (editor, element) => {
  const [target] = ReactEditor.findPath(editor, element);

  Transforms.select(editor, {
    anchor: {
      offset: 0,
      path: [target, 0],
    },
    focus: {
      offset: 0,
      path: [target, 0],
    },
  });

  return editor;
};

export default selectElement;
