import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from 'react-apollo';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import StoryTimeline from 'components/storyTimeline';
import UPDATE_INSTANCE from 'graphql/mutations/updateInstance';
import useUnscheduleInstance from 'hooks/useUnscheduleInstance';
import useInstances from './useInstances';

const useStyles = makeStyles(() => ({
  timelineContainer: {
    top: 0,
    left: 0,
    width: '100%',
  },
}));

const InstanceTimeline = props => {
  const classes = useStyles();
  const { storyId, platforms, storydata } = props;
  const { mPublishingAt: storyPulishingDate } = storydata.getMember;
  const { instances, error, loading } = useInstances(storyId, 'cache-only');
  const [updateInstance] = useMutation(UPDATE_INSTANCE);
  const [unschedule, unscheduleRundownInstances] = useUnscheduleInstance();

  const onSchedule = ({ id, start }) => {
    try {
      const instanceToUpdate = instances.find(({ mId }) => mId === id);
      const { mProperties } = instanceToUpdate;
      const { platform } = mProperties;
      
      if (!start) {
        if(platform === 'linear') {
          unscheduleRundownInstances(instanceToUpdate);
          return true;
        }
        
        unschedule(instanceToUpdate);
        return true;
      }

      const input = {
        mId: id,
        mPublishingAt: start,
      };

      const updatedInstance = {
        ...instanceToUpdate,
        mPublishingAt: start,
      };

      updateInstance({
        variables: {
          input,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateInstance: updatedInstance,
        },
      });

      return true;
    } catch (e) {
      return false;
    }
  };

  const memoizedStoryTimeline = useMemo(
    () => (
      <StoryTimeline
        instances={instances.map(({ mId, mPublishingAt, mProperties }) => ({
          id: mId,
          start: mPublishingAt,
          publishingPoint: mProperties.platform,
          publishingPlatform: platforms.find(
            platform => platform.mProperties.platform === mProperties.platform,
          ),
        }))}
        {...{ onSchedule, storyPulishingDate }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [instances],
  );

  if (loading)
    return (
      <div>
        <LoadingIndicator />
      </div>
    );

  if (error) return `Error! ${error.message}`;

  return (
    <div className={classes.timelineContainer}>{memoizedStoryTimeline}</div>
  );
};

export default InstanceTimeline;
