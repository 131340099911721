import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItemText, ListItemSecondaryAction, ListItem } from '@material-ui/core';
import Divider from 'components/divider';
import Popover from 'components/popover';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete_small.svg';
import { ReactComponent as Add } from 'assets/icons/systemicons/add_small.svg';
import CreateNew from '../createNew';
import ConfirmationComponent from '../confirmationComponent';
import useStyles from './templatesSubMenu-styles';

const SubMenu = props => {
  const {
    folderId,
    templates,
    anchorEl,
    onTemplateSelect,
    onTemplateSave,
    onDeleteTemplate,
    disabled,
    canCreateNewTemplate,
    canDeleteTemplate,
  } = props;

  const defaultTemplateValue = {
    mId: null,
    mRefId: null,
    mTitle: null,
    mContentKey: null,
  };

  const [anchor, setAnchor] = useState(null);
  const [currentTemplate, setCurrentTemplate] = useState(defaultTemplateValue);
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStyles();

  const sortedTemplates = templates.sort((a, b) =>
    a.mTitle.localeCompare(b.mTitle, undefined, { numeric: true }),
  );

  const hidePopover = () => {
    setAnchor(null);
  };

  const handleTemplateSelect = data => {
    onTemplateSelect(data);
    hidePopover();
  };

  const handleCreateNewTemplate = (title, description, overwriteData) => {
    onTemplateSave(folderId, title, overwriteData);
    hidePopover();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setCurrentTemplate(defaultTemplateValue);
  };

  const handleConfirm = () => {
    onDeleteTemplate(currentTemplate.mId, currentTemplate.mRefId, currentTemplate.mContentKey);
  };

  return (
    <div>
      <List disablePadding className={classes.root}>
        {sortedTemplates.map(template => {
          const { mRefId, mTitle, mContentKey } = template;
          return (
            <div key={mRefId} className={classes.listItem}>
              <ListItem
                button
                title={mTitle}
                disabled={disabled}
                onClick={() => handleTemplateSelect({ mContentKey })}
                classes={{ root: classes.listItem }}
              >
                <ListItemText primary={mTitle} classes={{ primary: classes.primary }} />
                {canDeleteTemplate && (
                  <ListItemSecondaryAction
                    classes={{ root: classes.secondaryAction }}
                    onClick={() => {
                      handleOpenDialog();
                      setCurrentTemplate(template);
                    }}
                    title="Delete Template"
                  >
                    <Delete />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </div>
          );
        })}
        {openDialog && (
          <Popover anchorEl={anchorEl} onClose={handleDialogClose}>
            <div className={classes.popover}>
              <ConfirmationComponent
                label={currentTemplate.mTitle}
                onOk={handleConfirm}
                onCancel={handleDialogClose}
              />
            </div>
          </Popover>
        )}
        {canCreateNewTemplate && (
          <>
            <Divider className={classes.divider} />
            <ListItem
              classes={{ root: classes.listItem }}
              button
              onClick={() => {
                setAnchor(anchorEl);
              }}
            >
              <Add className={classes.add} />
              <ListItemText
                primary="Save As New Template..."
                classes={{ primary: classes.primary }}
              />
            </ListItem>
          </>
        )}
      </List>
      <Popover
        anchorEl={anchor}
        onClose={hidePopover}
        position="left-center"
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.popover}>
          <CreateNew
            variant="template"
            onCancel={hidePopover}
            onOk={handleCreateNewTemplate}
            data={templates}
          />
        </div>
      </Popover>
    </div>
  );
};

SubMenu.propTypes = {
  /** List of templates to be shown */
  templates: PropTypes.arrayOf(PropTypes.any),
  /** Node for the popover to attach to */
  anchorEl: PropTypes.instanceOf(Element),
  /** Id of the folder */
  folderId: PropTypes.string,
  /** Callback to be invoked when a template is selected */
  onTemplateSelect: PropTypes.func,
  /** Callback to be invoked when new template is saved */
  onTemplateSave: PropTypes.func,
  /** Callback to be invoked when a template is deleted */
  onDeleteTemplate: PropTypes.func,
  /** boolean to disable the item */
  disabled: PropTypes.bool,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
};

SubMenu.defaultProps = {
  templates: [],
  anchorEl: null,
  folderId: null,
  onTemplateSelect: () => {},
  onTemplateSave: () => {},
  onDeleteTemplate: () => {},
  disabled: false,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
};

export default SubMenu;
