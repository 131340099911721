import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import returnState from 'screens/planning/components/status/utils/statusBoards/returnState';
import returnStates from 'screens/planning/components/status/utils/statusBoards/returnStates';
// eslint-disable-next-line max-len
import { ReactComponent as ArrowDoubleIcon } from 'assets/icons/systemicons/arrows/arrow_double_unboxed.svg';
import Icon from './components/icon';
import useStyles from './status-selector-styles';

const renderSelectIcon = () => null;

const StatusSelectorView = ({
  statusId,
  onChange,
  iconPosition,
  anchorOrigin,
  transformOrigin,
  disableEdit,
}) => {
  const classes = useStyles({ iconPosition });
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const state = returnState(statusId);
  const icon = state ? state.icon : null;

  const openSelectMenu = () => setIsOpen(true);
  const closeSelectMenu = () => setIsOpen(false);

  const handleChange = event => onChange(event.target.value);

  const stopPropagation = event => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const renderSelectOption = ({ id, name }) => (
    <MenuItem
      value={id}
      key={id}
      disabled={disableEdit}
      classes={{
        root: classes.menuItem,
        selected: classes.menuItemSelected,
      }}
    >
      {name}
    </MenuItem>
  );

  return (
    <div role="presentation" className={classes.root} onClick={openSelectMenu}>
      <div className={classes.statusIcon}>
        <Icon status={icon} />
      </div>

      {iconPosition === 'start' && (
        <div className={classes.selectIcon}>
          <ArrowDoubleIcon />
        </div>
      )}

      <div
        ref={containerRef}
        role="presentation"
        onKeyDown={stopPropagation}
        onClick={stopPropagation}
        className={classes.selectWrapper}
      >
        <Select
          disableUnderline
          open={isOpen}
          onOpen={openSelectMenu}
          onClose={closeSelectMenu}
          IconComponent={renderSelectIcon}
          value={statusId}
          onChange={handleChange}
          className={classes.inputBase}
          classes={{ root: classes.select }}
          MenuProps={{
            classes: {
              paper: classes.menu,
            },
            anchorEl: containerRef.current,
            getContentAnchorEl: null,
            anchorOrigin,
            transformOrigin,
          }}
          inputProps={{
            classes: {
              root: classes.input,
            },
          }}
        >
          {returnStates().map(renderSelectOption)}
        </Select>
      </div>

      {iconPosition === 'end' && (
        <div className={classes.selectIcon}>
          <ArrowDoubleIcon />
        </div>
      )}
    </div>
  );
};

StatusSelectorView.propTypes = {
  /** Current status id of the instance */
  statusId: PropTypes.string,
  /** Position of the arrow icons */
  iconPosition: PropTypes.oneOf(['start', 'end']),
  /** Anchor origin for Material UI popover */
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  /** Transform origin for Material UI popover */
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  /** Callback to be invoked on item selection */
  onChange: PropTypes.func,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
};

StatusSelectorView.defaultProps = {
  statusId: 'todo',
  iconPosition: 'end',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  onChange: newStatusId => {},
  disableEdit: false,
};

export default StatusSelectorView;
