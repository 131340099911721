/**
 * Hook for activating or disabling sync provider to a story
 */

import { useMutation } from 'react-apollo';
import { getMemberQuery } from 'graphql/queryVariables';
import activateStorySync from 'graphql/mutations/activateStorySync';
import disableStorySync from 'graphql/mutations/disableStorySync';
import GET_STORY from 'graphql/queries/getStory';

const useSetStorySync = () => {
  const [activateMutation] = useMutation(activateStorySync);
  const [disableMutation] = useMutation(disableStorySync);

  const updateStory = (proxy, storyId, syncActive) => {
    try {
      const story = proxy.readQuery({
        query: GET_STORY,
        variables: getMemberQuery(storyId),
      });
      const newStory = { ...story.getMember, mSyncActive: syncActive };

      proxy.writeQuery({
        query: GET_STORY,
        variables: getMemberQuery(storyId),
        data: {
          getMember: newStory,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  const setStorySync = async (mId, syncActive) => {
    syncActive.length > 0
      ? await activateMutation({
          variables: {
            input: {
              mId,
              activeProviders: syncActive,
            },
          },
          update: proxy => updateStory(proxy, mId, syncActive),
        })
      : await disableMutation({
          variables: {
            input: {
              mId,
            },
          },
          update: proxy => updateStory(proxy, mId, syncActive),
        });
  };

  return [setStorySync];
};

export default useSetStorySync;
