import memberTypes from 'graphql/memberTypes';
import updateRealatedMembersStories  from './storyListCache';
import updateStoryMembers from './storyMembers';

const updateCache = (proxy,  mutationResult, storyId, mType, userId) => {
    const { addMembers, removeMembers } = mutationResult.data;
    
    if(addMembers.length === 0 && removeMembers.length === 0) {
      return;
    }
    
    let memberType;
    switch(mType) {
      case memberTypes.STORY_CONTACT:
        memberType = memberTypes.CONTACT;
        break;
      case memberTypes.USER_STORY:
        memberType = memberTypes.USER;
        break;
      case memberTypes.TEAM_STORY:
        memberType = memberTypes.TEAM;
        updateRealatedMembersStories(proxy, storyId, addMembers, removeMembers,
          mType, memberTypes.TEAM_USER , userId);
        break;
      case memberTypes.DEPARTMENT_STORY:
        memberType = memberTypes.DEPARTMENT;
        updateRealatedMembersStories(proxy, storyId, addMembers, removeMembers,
          mType, memberTypes.DEPARTMENT_USER, userId);
        
        break;
      case memberTypes.MARKET_STORY:
        memberType = memberTypes.MARKET;
        break;
      default:
        break; 
    } 

     if(memberType) {
       updateStoryMembers(proxy, storyId, addMembers, removeMembers, 
        mType, memberType);
     }
  };

export default updateCache;
