import { Text } from 'slate';
import textColors from 'components/editor/constants/textColors';

/**
 * Finds non-cplored words given SlateJS document
 *
 * @param {Object[]} document SlateJS document instance
 * @returns {String[]} Colored words
 */

const getRedWords = (document, ignoreColoredText = true) => {
  const nodes = filterNonColoredNodes(document, ignoreColoredText, []);
  const redTextArr = nodes.join(' ').split(' ');
  return redTextArr.filter(redText => redText.includes('RUNS='));
};

const [_, red] = textColors;

const redColors = [red];

const filterNonColoredNodes = (nodes, ignoreColoredText = true, initialValue = []) =>
  nodes.reduce((accumulator, node) => {
    const { color, text, children } = node;
    if (Text.isText(node) && (!ignoreColoredText || !color || redColors.includes(color))) {
      return [...accumulator, text.trim()];
    }

    if (!children) return accumulator;

    return filterNonColoredNodes(children, ignoreColoredText, accumulator);
  }, initialValue);

export default getRedWords;
