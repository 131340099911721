import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import ICONS from 'assets/icons/icons';

const Assets = props => {
  const { assets, classes } = props;

  return (
    <span className={classes.assets}>
      {assets.map(asset => {
        if (asset.type === 'Image' && asset.renditions) {
          return (
            <a
              key={asset.renditions[0].href}
              href={asset.renditions[asset.renditions.length - 1].href}
              target="_blank"
              rel="noopener noreferrer"
              title={asset.headline || 'Image'}
            >
              <img
                className={classes.imageThumbnail}
                src={asset.renditions[0].href}
                alt={asset.title}
              />
            </a>
          );
        }

        if (asset.type === 'Sound File' && asset.renditions) {
          return (
            <a
              key={asset.renditions[0].href}
              href={asset.renditions[0].href}
              target="_blank"
              rel="noopener noreferrer"
              title={asset.headline || 'Audio file'}
              className={classes.fileAnchor}
            >
              <div
                className={classes.fileThumbnail}
                src={asset.renditions[0].href}
                alt={asset.title}
              >
                <Icon icon={ICONS.AUDIO} width={36} height={36} />
                <span className={classes.fileDuration}>
                  {asset.duration || null}
                </span>
              </div>
            </a>
          );
        }
      })}
    </span>
  );
};

Assets.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object),
};

Assets.defaultProps = {
  assets: [],
};

export default Assets;
