import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  home: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '20%',
  },
}));

export default useStyles;
