import React from 'react';
import SevenMountainsSrc from 'assets/images/initialLoading/sevenmLogo.png';
import LoadingIndicator from 'components/loadingIndicator';
import DinaLogo from 'assets/images/DinaIcon.svg';
import { Paper, Typography, Link } from '@material-ui/core';
import useStyles from './loading-styles';

const LoadingView = () => {
  const classes = useStyles();
  return (
    <Paper elevation={24} className={classes.root}>
      <div className={classes.loadingContainer}>
        <img className={classes.logo} src={DinaLogo} alt="Dina-icon" />
        <div className={classes.loadingIndicator}>
          <LoadingIndicator />
        </div>

        <Typography className={classes.loadingText}>Loading DiNA, please wait...</Typography>
      </div>
      <div className={classes.copyright}>
        <Typography className={classes.textCopyright}>
          Copyright © 2018–2020 7Mountains.
          <br />
          All rights reserved.
        </Typography>
        <Typography style={{ marginTop: 4 }} className={classes.textCopyright}>
          <Link
            href="https://7mountains.com/privacy-policy/"
            underline="always"
            color="inherit"
            target="_blank"
            rel="noopener"
          >
            Click here for legal notice
          </Link>
        </Typography>
      </div>
      <div className={classes.sevenMount}>
        <img src={SevenMountainsSrc} alt="7m" />
        <Typography className={classes.textMountains}>7MOUNTAINS</Typography>
      </div>
    </Paper>
  );
};

export default LoadingView;
