import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { mediaTypes } from 'utils/rundownItemTypes';
import dndTypes from 'utils/dndTypes';
import { actionTypes, elementTypes } from 'components/editor/constants/types';
import normalizeAssetData from 'utils/normalizeAssetData';
import normalizeIntoBlockData from 'components/editor/utils/normalizeIntoBlockData';
import variants from 'utils/instance/variants';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import DropZone from 'components/editor/components/dropZone';

const { CLIP } = mediaTypes;
const { ASSET } = dndTypes;
const { LINEAR } = variants;
const { select, collapse, insertNodes } = Transforms;

const AssetDropZone = ({ children, element }) => {
  const editor = useSlate();
  const { update, variant } = useEditorContext();

  const onDrop = useCallback(
    async ({ payload }) => {
      const assetData = normalizeAssetData(payload);

      try {
        const result = await update({
          type: actionTypes.CREATE_ASSET,
          payload: { document: editor.children, asset: assetData },
        });

        const asset = result.data.createAssets[0];

        if (asset) {
          const data = normalizeIntoBlockData({ ...payload, ...asset });

          const placeholderElement = {
            data,
            type: elementTypes.PLACEHOLDER,
            children: [{ text: '' }],
          };

          const path = ReactEditor.findPath(editor, element);

          select(editor, path);
          collapse(editor, { edge: 'end' });
          insertNodes(editor, placeholderElement);
        }
      } catch (error) {
        // console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  return (
    <DropZone accept={variant === LINEAR ? [] : [CLIP, ASSET]} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

AssetDropZone.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

AssetDropZone.defaultProps = {
  children: null,
  element: {
    children: [],
  },
};

export default memo(AssetDropZone);
