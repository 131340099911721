import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as OpenIcon } from 'assets/icons/systemicons/open.svg';
import capitalize from 'utils/capitalize';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import useStyles from './header-styles';

const HeaderNav = ({ smallHeader, onClose, onOpenStory, onBackClick, headerTitle }) => {
  const classes = useStyles();
  return (
    <div className={classes.navContainer}>
      {!onBackClick && !smallHeader && <div />}
      {onBackClick && (
        <div
          className={classes.backDiv}
          role="button"
          tabIndex="0"
          onClick={onBackClick}
          onKeyDown={() => {}}
        >
          <ArrowLeftIcon />
          <Typography classes={{ root: classes.backText }}>Back</Typography>
        </div>
      )}
      {smallHeader && (
        <Typography classes={{ root: classes.storyBoxText }}>
          {`${capitalize(headerTitle)}box`}
        </Typography>
      )}
      <div className={classes.buttonDiv}>
        {onOpenStory && (
          <IconButton classes={{ root: classes.button }} onClick={onOpenStory}>
            <OpenIcon className={classes.icon} />
          </IconButton>
        )}
        {onClose && (
          <IconButton classes={{ root: classes.button }} onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        )}
      </div>
    </div>
  );
};

HeaderNav.propTypes = {
  /** onClose callback for story preview */
  onClose: PropTypes.func,
  /** onOpenStory callback for story */
  onOpenStory: PropTypes.func,
  /** onBack callback function */
  onBackClick: PropTypes.func,
  /** header title */
  headerTitle: PropTypes.string,
};

HeaderNav.defaultProps = {
  onClose: undefined,
  onOpenStory: undefined,
  onBackClick: undefined,
  headerTitle: 'story',
};
export default HeaderNav;
