import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
      position: 'relative',
      width: '294px',
      height: ({itemHeight}) => itemHeight,
      minHeight: '42px',
      maxHeight: '84px',
      left: '10px',
      top: '5px',

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px 0px 20px',
  },

  event: {
    position: 'relative',
    width: '72px',
    height: '20px',
    left: '0px',
    top: '2px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px 0px',
  },

  boxedStateLayout: {
    position: 'relative',
    width: '72px',
    height: '20px',
    left: '0px',
    top: ({index}) => index === 0 ? '0px' : '4px',

    background: 'rgba(94, 114, 138, 0.5)',
    borderRadius: '2px',

    padding: '4px',
    margin: '0px 4px',
  },

  stateLabel: {
    ...theme.typography.dina.captionMedium,
    position: 'static',
    left: '4px',
    right: '12px',
    top: '1.5px',
    bottom: '1.5px',

    color: theme.palette.dina.mediumEmphasis,

    flex: 'none',
    order: '1',
    alignSelf: 'center',
  },

  customNameEvent: {
    position: 'relative',
    width: '139px',
    height: '20px',
    left: '0px',
    top: '8px',
    marginBottom: '4px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px 0px',
  },
  boxedCustomNameLayout: {
    position: 'relative',
    width: '139px',
    height: '18px',
    left: '0px',
    top: '0px',

    background: 'rgba(94, 114, 138, 0.5)',
    borderRadius: '2px',

    margin: '0px 4px',
  },
  customNameLabel: {
    ...theme.typography.dina.captionMedium,
    position: 'relative',
    left: '4px',
    right: '12px',
    top: '1.5px',
    bottom: '1.5px',

    color: theme.palette.dina.mediumEmphasis,

    flex: 'none',
    order: '1',
    alignSelf: 'center',
  },

  timestamp: {
    height: '13px',
    margin: '0px 2px',
    position: 'relative',
  },
  timestampLabel: {
    ...theme.typography.dina.captionRegular,
    position: 'relative',
    left: '4px',
    right: '0px',
    top: 'calc(50% - 13px / 2 + 4.5px)',
    bottom: '1.5px',

    color: theme.palette.dina.highEmphasis,

    flex: 'none',
    order: '1',
    alignSelf: 'center',
  },

  updatedBy: {
    height: '13px',
    margin: '0px 2px',
    position: 'relative',
  },
  updatedByLabel: {
    ...theme.typography.dina.captionSmall,
    position: 'relative',
    left: '4px',
    right: '0px',
    top: 'calc(50% - 11px / 2 + 7.5px)',

    color: theme.palette.dina.mediumEmphasis,

    flex: 'none',
    order: '1',
    alignSelf: 'center',
  },
}));

export default useStyles;