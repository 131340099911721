import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  buttonDiv: {
    height: '40px',
    background: theme.palette.dina.blackHoverOverlay,
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: '8px',
  },
  textRoot: {
    ...theme.typography.dina.listItemLabel,
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  buttonRoot: {
    borderRadius: '0%',
    height: '40px',
    width: '40px',
    padding: '8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
