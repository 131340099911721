import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';
import UPDATE_STORY from 'graphql/mutations/updateMember';
import UserContext from 'contexts/UserContext';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import StoryCardView from './storyCard-view';

const StoryCard = props => {
  const {
    id,
    priorityLevel,
    onlyShowUsersItems,
    image,
    categories,
    title,
    isDragging,
    isPitch,
    openStory,
    assignedMembers,
    updatedAt,
  } = props;
  const user = useContext(UserContext);
  const { mId: userId } = user;
  const [updateStory] = useMutation(UPDATE_STORY);
  const [assignedUsers, assignedTeams] = useGetAssignedMembers(assignedMembers || []);

  const handlePriorityChange = async priorityValue => {
    const input = { mId: id };
    if (priorityValue) {
      input.mPriority = priorityValue;
    } else if (priorityLevel) {
      input.mPriority = null;
    }
    try {
      await updateStory({ variables: { input } });
    } catch (err) {
      // console.log(err);
    }
  };

  const isAssignedToUser = Boolean(assignedUsers.find(usr => usr.mId === userId));
  if (onlyShowUsersItems && !isAssignedToUser) return null;

  return (
    <StoryCardView
      {...props}
      assignees={assignedUsers}
      teams={assignedTeams}
      onPriorityChange={handlePriorityChange}
      {...{
        id,
        priorityLevel,
        image,
        categories,
        title,
        isDragging,
        isPitch,
        openStory,
        updatedAt,
      }}
    />
  );
};

export default StoryCard;
