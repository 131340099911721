import { makeStyles } from '@material-ui/styles';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';

const useStyles = (width, height, src) =>
  makeStyles(theme => ({
    root: {
      position: 'relative',
      width,
      height,
      display: 'flex',
      border: `0.5px solid ${theme.palette.dina.iconInactive}`,
      borderRadius: '30%',
      boxSizing: 'border-box',
      backgroundColor: theme.palette.dina.outlineButtonOnHover,
      backgroundImage: src ? null : `url(${fallbackImage})`,
      backgroundSize: 'cover',
    },
    topic: {
      position: 'relative',
      width,
      height,
      borderRadius: '4px',
      border: `0.5px solid ${theme.palette.dina.inputBorderResting}`,
      boxSizing: 'border-box',
      backdropFilter: 'blur(15px)',
      backgroundColor: theme.palette.dina.blackHoverOverlay,
      backgroundImage: src ? null : `url(${fallbackImage})`,
      backgroundSize: 'cover',
    },
    div: {
      ...theme.typography.dina.instanceFooterLabel,
      fontWeight: '500',
      display: 'flex',
      margin: 'auto',
      color: theme.palette.dina.highEmphasis,
    },
    typography: {
      ...theme.typography.dina.storyCardAvatarText,
      color: theme.palette.dina.highEmphasis,
    },
    top: {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate3d(-50%, -100%, 0)',
    },
    right: {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translate3D(100%, -50%, 0)',
    },
    bottom: {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate3D(-50%, 100%, 0)',
    },
    left: {
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translate3D(-100%, -50%, 0)',
    },
    middle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate3D(-50%, -50%, 0)',
    },
  }));

export default useStyles;
