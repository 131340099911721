import React from 'react';
import Navbar from './navbar';
import Toolbar from './toolbar';

const Header = props => (
  <>
    <Navbar {...props} />
    <Toolbar />
  </>
);

export default Header;
