import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: 48,
    position: 'relative',
    width: 48,
  },
}));

export default useStyles;
