/** search with fuse, for frontend filtering of object arrays,
 * fuse.js documentation can be found on https://fusejs.io/
 */
import Fuse from 'fuse.js';

const useFuseSearch = () => {
  const search = (list, keys, expression) => {
    const options = {
      useExtendedSearch: true,
      shouldSort: false,
      threshold: 0.2,
      distance: 8000,
      keys,
    };

    const fuse = new Fuse(list, options);

    let results = list;
    if (expression.length > 0) {
      // With the use of map the output will be formatted the same way as the input.
      results = fuse.search(expression).map(res => res.item);
    }
    return results;
  };

  return search;
};

export default useFuseSearch;
