import React from 'react';
import Popover from 'components/popover/Popover';

const Menu = props => {
  const { onClose, position, anchorEl, type, children, ...other } = props;
  return (
    <Popover
      onClose={onClose}
      position={position}
      anchorEl={anchorEl}
      type={type}
      {...other}
    >
        {children}

      </Popover>
  );
};

export default Menu;
