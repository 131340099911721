import React, { useEffect, useCallback, useState } from 'react';
import { Typography, Input } from '@material-ui/core';
import ConvertDurationToMillisecond from 'screens/rundown/utils/convertDurationToMillisecond';
import { formatDuration, convertDifferenceInString } from 'utils/formatDuration';
import useStyles from './gridHeader-styles';

const DurationComponent = ({ label, duration }) => {
  const classes = useStyles();
  return (
    <div className={classes.durationDiv}>
      <Typography classes={{ root: classes.durationLabel }}>{label}</Typography>
      <Typography classes={{ root: classes.duration }}>{duration}</Typography>
    </div>
  );
};

const ListHeader = ({
  listHeaderTitle,
  plannedDuration,
  actualDuration,
  updatePlannedDuration,
}) => {
  const classes = useStyles();
  const [durationInputFocus, setDurationInputFocus] = useState(false);
  const [plannedDurationInput, setPlannedDurationInput] = useState(plannedDuration);
  const showDurationInput = () => setDurationInputFocus(true);

  const setPlannedDuration = useCallback(
    duration => {
      if (duration !== plannedDuration) updatePlannedDuration(duration);
    },
    [plannedDuration, updatePlannedDuration],
  );

  const onPlannedDurationInputChange = useCallback(e => {
    setPlannedDurationInput(ConvertDurationToMillisecond(e.target.value));
  }, []);

  const onPlannedDurationInputBlur = () => {
    setDurationInputFocus(false);
    if (!Number.isNaN(plannedDurationInput) && plannedDurationInput !== plannedDuration)
      setPlannedDuration(plannedDurationInput);
  };
  useEffect(() => {
    const logKey = event => {
      if (event.code === 'Enter') {
        if (durationInputFocus) {
          if (!Number.isNaN(plannedDurationInput)) setPlannedDuration(plannedDurationInput);
          setDurationInputFocus(false);
        }
      }

      if (event.code === 'Escape') {
        if (durationInputFocus) {
          setPlannedDurationInput(plannedDuration);
          setDurationInputFocus(false);
        }
      }
    };
    setPlannedDurationInput(plannedDurationInput || plannedDuration);
    document.addEventListener('keydown', logKey);
    return () => {
      document.removeEventListener('keydown', logKey);
    };
  }, [durationInputFocus, plannedDuration, plannedDurationInput, setPlannedDuration]);

  return (
    <div className={classes.rootDiv}>
      <div className={classes.onAirDiv}>
        <Typography classes={{ root: classes.textRoot }}>{listHeaderTitle}</Typography>
      </div>
      <div className={classes.timePlanned}>
        <Typography classes={{ root: classes.planned }}>planned</Typography>
        {durationInputFocus ? (
          <Input
            autoFocus
            disableUnderline
            rowsMax={1}
            placeholder="hh:mm:ss"
            classes={{
              root: classes.root,
              input: classes.input,
            }}
            onBlur={onPlannedDurationInputBlur}
            onChange={onPlannedDurationInputChange}
          />
        ) : (
          <div
            className={classes.plannedNormal}
            onClick={showDurationInput}
            onKeyPress={showDurationInput}
            role="button"
            tabIndex="0"
          >
            {formatDuration(plannedDuration) || '0'}
          </div>
        )}
      </div>
      <DurationComponent label="ACTUAL" duration={formatDuration(actualDuration) || '0'} />
      <DurationComponent
        label="TOTAL +/-"
        duration={convertDifferenceInString(plannedDuration, actualDuration) || 0}
      />
    </div>
  );
};

export default ListHeader;
