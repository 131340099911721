import React from 'react';
import PropTypes from 'prop-types';
import { Button as MaterialButton } from '@material-ui/core';
import useStyles from './button-styles';

const Button = props => {
  const { children, variant, type, disabled, onClick, shape, submit, size, ...rest } = props;
  const classes = useStyles(size)();

  let iconPlacement = '';
  if (Array.isArray(children)) {
    iconPlacement =
      typeof children[0] === 'string' ? (iconPlacement = classes.iconRight) : classes.iconLeft;
  }

  let fullWidth = false;
  let className;
  switch (shape) {
    case 'square':
      className = classes.buttonSquare;
      break;
    case 'lg-rectangle':
      className = classes.buttonRectanglelg;
      break;
    case 'sm-rectangle':
      className = classes.buttonRectanglesm;
      break;
    case 'full-width':
      className = classes.buttonFullWidth;
      fullWidth = true;
      break;
    case 'action-button':
      className = classes.actionButton;
      break;
    default:
      className = classes.buttonRectangle;
  }

  return (
    <MaterialButton
      data-testid="button"
      variant={variant}
      disabled={disabled}
      className={className}
      onClick={onClick}
      fullWidth={fullWidth}
      classes={{
        root: classes[type],
        outlined: classes.outlined,
        label: iconPlacement,
        contained: classes.contained,
      }}
      type={submit ? 'submit' : 'button'}
      {...rest}
    >
      {children}
    </MaterialButton>
  );
};

Button.propTypes = {
  /** Elements that should be rendered on the button (text or icon) */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]),
  /**  Values: text, outlined or contained */
  variant: PropTypes.string,
  /**  Values: true/false */
  disabled: PropTypes.bool,
  /**  Values: cta, danger, significant */
  type: PropTypes.string,
  /** Values: rectangle, square, lg-rectangle, full-width */
  shape: PropTypes.string,
  /**  onClick function */
  onClick: PropTypes.func,
  /** Values: true/false */
  submit: PropTypes.bool,
  /** Defines width and height of button */
  size: PropTypes.number,
};

Button.defaultProps = {
  children: '',
  variant: 'outlined',
  disabled: false,
  type: '',
  onClick: () => {},
  shape: 'rectangle',
  submit: false,
  size: 64,
};

export default Button;
