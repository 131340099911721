import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Close from '@material-ui/icons/CloseOutlined';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as EmailValidator from 'email-validator';
import telephoneValidator from 'utils/telephoneValidator/telephoneValidator';
import InputFieldTypes, { usageUtils } from './InputFieldTypes';
import useStyles from './InputFieldStyle';

const InputField = props => {
  const {
    type,
    label,
    description,
    severity,
    disabled,
    usage,
    value,
    placeholder,
    onChange,
    onClear,
    autoFocus,
    disableLabel,
    ...other
  } = props;

  const [fieldTouched, setFieldTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  const handleChangeValue = event => {
    event.preventDefault();
    onChange(event.target.value);
    setFieldTouched(event.target.value);
  };

  const handleClickIconButton = event => {
    event.preventDefault();
    if (severity !== 'error' && type === InputFieldTypes.PASSWORD) {
      setShowPassword(!showPassword);
    } else {
      onChange('');
      onClear(event);
      setFieldTouched(false);
    }
  };

  const getMandatoryUnderlineStyle = () => {
    return fieldTouched
      ? classes.cssUnderlineNotTouched
      : classes.cssUnderlineTouched;
  };

  const getEmailFieldUnderlineStyle = () => {
    if (EmailValidator.validate(fieldTouched)) {
      return severity === 'mandatory'
        ? classes.cssUnderlineNotTouched
        : classes.cssUnderlineRegular;
    }

    return classes.cssUnderlineTouched;
  };

  const getTelephoneFieldUnderlineStyle = () => {
    if (telephoneValidator(fieldTouched)) {
      return severity === 'mandatory'
        ? classes.cssUnderlineNotTouched
        : classes.cssUnderlineRegular;
    }

    return classes.cssUnderlineTouched;
  };

  const getEditingUnderlineStyle = () => {
    return classes.cssUnderlineEditing;
  };

  const ReturnUnderlineStyle = () => {
    if (type === InputFieldTypes.EMAIL && fieldTouched) {
      return getEmailFieldUnderlineStyle();
    }

    if (type === InputFieldTypes.TELEPHONE && fieldTouched) {
      return getTelephoneFieldUnderlineStyle();
    }

    if (severity === 'mandatory') {
      return getMandatoryUnderlineStyle();
    }

    if (severity === 'editing') {
      return getEditingUnderlineStyle();
    }

    return classes.cssUnderlineRegular;
  };

  const ReturnType = () => {
    if (type === InputFieldTypes.PASSWORD) {
      return showPassword ? InputFieldTypes.TEXT : InputFieldTypes.PASSWORD;
    }
    return type;
  };

  const isUsageMeta = usage === usageUtils.METADATA;
  const isUsageEditor = usage === usageUtils.EDITOR;

  const getFilledLabelStyle = () => (isUsageMeta || isUsageEditor ? classes.filledLabel : '');
  const getInputRootStyle = () => {
    if (isUsageMeta) return classes.filledInputRootMeta;
    if (isUsageEditor) return classes.filledInputRootEditor;
    return classes.filledInputRoot;
  };
  const getInputStyle = () => {
    if (isUsageMeta) return classes.filledInputMeta;
    if (isUsageEditor)
      return classes[disableLabel ? 'filledInputEditorNoLabel' : 'filledInputEditor'];
    return classes.filledInput;
  };
  const getHelperTextStyle = () => {
    if (isUsageMeta) return classes.formHelperRootMeta;
    if (isUsageEditor) return classes.formHelperRootEditor;
    return classes.formHelperRoot;
  };
  const getIconSize = () => (isUsageMeta || isUsageEditor ? 'small' : 'medium');

  return (
    <div className={classes.inputFieldDiv}>
      <FormControl
        variant="filled"
        error={severity === 'error' && true}
        disabled={disabled}
        fullWidth
      >
        {!disableLabel && (
          <InputLabel
            htmlFor={label}
            classes={{
              root: classes.labelRoot,
              filled: getFilledLabelStyle(),
              shrink: classes.shrink,
              focused: classes.focused,
              disabled: classes.disabled,
              error: classes.error,
            }}
          >
            {label}
          </InputLabel>
        )}
        <FilledInput
          data-testid="input"
          type={ReturnType()}
          value={value}
          placeholder={placeholder}
          onChange={handleChangeValue}
          disableUnderline={disabled}
          autoFocus={autoFocus}
          classes={{
            root: getInputRootStyle(),
            underline: ReturnUnderlineStyle(),
            input: getInputStyle(),
            error: classes.error,
            disabled: classes.disabled,
          }}
          endAdornment={
            value &&
            !disabled && (
              <InputAdornment position="end">
                <IconButton
                  className={classes.iconButton}
                  tabIndex="-1"
                  onClick={handleClickIconButton}
                  size={getIconSize()}
                >
                  {type === InputFieldTypes.PASSWORD &&
                    severity !== 'error' &&
                    (showPassword ? <VisibilityOff /> : <Visibility />)}
                    {(type !== InputFieldTypes.PASSWORD ||
                      severity === 'error') && <Close />}
                  </IconButton>
              </InputAdornment>
            )
          }
          {...other}
        />
        <FormHelperText
          classes={{
            root: getHelperTextStyle(),
            error: classes.error,
            disabled: classes.disabled,
          }}
        >
          {description}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

InputField.propTypes = {
  /** Values: text, email, tel or password */
  type: PropTypes.string,
  /** Label stating the purpose of the input field (text) */
  label: PropTypes.string,
  /** Assistive help text shown at the bottom if defined (text)  */
  description: PropTypes.string,
  /** Values: regular, mandatory, error, editing */
  severity: PropTypes.string,
  /** Values: true or false */
  disabled: PropTypes.bool,
  /** Indicates if the input field should get focus when it is loaded.
   * Values: true or false */
  autoFocus: PropTypes.bool,
  /** Value of the input field */
  value: PropTypes.string,
  /** A short hint displayed in the input */
  placeholder: PropTypes.string,
  /** onChange function */
  onChange: PropTypes.func,
  /** defines the usage of input field */
  usage: PropTypes.string,
  /** onClear callback when input value is cleared pressing icon button */
  onClear: PropTypes.func,
  /** disable the label of input field */
  disableLabel: PropTypes.bool,
};

InputField.defaultProps = {
  type: InputFieldTypes.TEXT,
  label: undefined,
  description: undefined,
  severity: 'regular',
  disabled: false,
  value: '',
  placeholder: '',
  autoFocus: false,
  onChange: () => {},
  usage: usageUtils.NORMAL,
  onClear: () => {},
  disableLabel: false,
};

export default InputField;
