import uuidv4 from 'uuid/v4';
import path from 'path';

const getAssetData = (storyId, data) => {
  const { id, itemType: mediaType, originalFileName: fileName, thumbnailUrl, proxy } = data;

  const rendition = {
    mId: storyId,
    mRefId: id,
    source: thumbnailUrl,
    fileName,
    variant: 'thumbnail',
  };

  const renditions = [];

  if (thumbnailUrl && (mediaType.includes('video') || mediaType.includes('image'))) {
    renditions.push(rendition);
  }

  // in case of image store both thumbnail and proxy.
  if (mediaType === 'image' && proxy) {
    renditions.push({
      ...rendition,
      source: proxy,
      variant: 'main',
    });
  }

  return {
    mId: storyId,
    mRefId: id,
    mediaType,
    renditions,
    data,
  };
};

const getFileAssetData = (storyId, file) => {
  const fileExtension = path.extname(file.name);
  const title = path.basename(file.name, fileExtension);

  const mediaType =
    file.type && file.type !== 'text/plain'
      ? file.type
      : `application/${fileExtension.replace('.', '')}`;

  return {
    mId: storyId,
    mRefId: uuidv4(),
    mTitle: title,
    extension: fileExtension,
    mediaType,
    data: {},
    file,
  };
};

export { getFileAssetData, getAssetData };
