import React, { useState, useContext, createContext, useEffect } from 'react';

const SAVED_STATE = JSON.parse(window.localStorage.getItem('screens'));

const MaximizedContext = createContext();

const MaximizedContextProvider = props => {
  const { story } = SAVED_STATE || {};

  const initialState = story && story.extended;

  const [isMaximized, setIsMaximized] = useState(initialState);

  useEffect(() => {
    window.localStorage.setItem(
      'screens',
      JSON.stringify({
        story: {
          extended: isMaximized,
        },
      }),
    );
  }, [isMaximized]);

  return <MaximizedContext.Provider value={{ isMaximized, setIsMaximized }} {...props} />;
};

export const useMaximized = () => useContext(MaximizedContext);

export default MaximizedContextProvider;
