import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Typography, Fade, CircularProgress } from '@material-ui/core';
import { ReactComponent as Edit } from 'assets/icons/systemicons/edit_on.svg';
import { ReactComponent as Padlock } from 'assets/icons/systemicons/padlock_on.svg';
import useStyles from './lockedIndicator-styles';

const LockedIndicator = ({
  readLock,
  writeLock,
  hasChanges,
  onBackgroundClick,
  onDone,
  lockedByUser,
  isSavingContent,
}) => {
  const classes = useStyles({ readLock, writeLock });

  const onDoneClick = event => {
    event.stopPropagation();
    onDone();
  };

  const iconComponent = () => {
    if (readLock) return <Padlock className={classes.icon} />;
    if (writeLock) return <Edit className={classes.icon} />;
    return null;
  };

  return (
    <div className={classes.lockedIndicator} onClick={onBackgroundClick} role="presentation">
      <div className={classes.components}>
        <div className={classes.leftComponents}>
          {iconComponent()}
          {readLock && (
            <Typography
              classes={{ root: classes.footerText }}
            >{`${lockedByUser} is editing this instance`}</Typography>
          )}
          {hasChanges && <Typography classes={{ root: classes.footerText }}>Not saved</Typography>}
        </div>
        {writeLock && (
          <ButtonBase classes={{ root: classes.button }} onClick={onDoneClick}>
            Done
          </ButtonBase>
        )}
      </div>
      <Fade in={isSavingContent} timeout={{ enter: 250, exit: 500 }}>
        <div className={classes.messageComponent}>
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className={classes.progress}
            size={24}
            thickness={1}
          />
          Saving
        </div>
      </Fade>
    </div>
  );
};

LockedIndicator.propTypes = {
  /** Name of the user who is currently editing this instance */
  lockedByUser: PropTypes.string,
  /** Boolean that indicates that the instance is locked by some other user */
  readLock: PropTypes.bool,
  /** Boolean that indicates that the user has a writeLock for this instance */
  writeLock: PropTypes.bool,
  /** Callback to be invoked when the footer is clicked */
  onBackgroundClick: PropTypes.func,
  /** Callback to be invoked when the done button is clicked */
  onDone: PropTypes.func,
  /** Boolean that indicates that the content is being saved */
  isSavingContent: PropTypes.bool,
};

LockedIndicator.defaultProps = {
  lockedByUser: 'Someone',
  readLock: false,
  writeLock: false,
  isSavingContent: false,
  onBackgroundClick: () => {},
  onDone: () => {},
};

export default LockedIndicator;
