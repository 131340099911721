import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { useSlate } from 'slate-react';
import { NativeTypes } from 'react-dnd-html5-backend';
import DropZone from 'components/editor/components/dropZone';

const TextDropZone = ({ children, element }) => {
  const editor = useSlate();

  const onDrop = useCallback(
    ({ text }) => text && Transforms.insertText(editor, text),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <DropZone accept={[NativeTypes.TEXT]} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

TextDropZone.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

TextDropZone.defaultProps = {
  children: null,
  element: {
    children: [],
  },
};

export default memo(TextDropZone);
