import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { isElementActive } from 'components/editor/utils';
import { elementTypes } from 'components/editor/constants';
import toggleList from 'components/editor/components/list/utils/toggleList';
import iconComponents from './constants/iconComponents';
import ButtonBase from '../buttonBase';

const { UNORDERED_LIST, ORDERED_LIST } = elementTypes;

const ListButton = ({ type }) => {
  const editor = useSlate();
  const isActive = isElementActive(editor, type);

  const onMouseDown = useCallback(
    event => {
      event.preventDefault();
      toggleList(editor, type, isActive);
    },
    [editor, isActive, type],
  );

  const IconComponent = iconComponents[type];

  return <ButtonBase {...{ IconComponent, type, isActive, onMouseDown }} />;
};

ListButton.propTypes = {
  /** Type for the button */
  type: PropTypes.oneOf([UNORDERED_LIST, ORDERED_LIST]),
};

ListButton.defaultProps = {
  type: UNORDERED_LIST,
};

export default ListButton;
