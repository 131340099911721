import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CurrentTabContext } from 'globalState';
import Tab from './navigation-view';

const NavigationTab = props => {
  const {
    type,
    IconOn,
    IconOff,
    breakingFeedNotifications,
    clearNotificationCount,
    ...rest
  } = props;

  const [currentTab, setCurrentTab] = useContext(CurrentTabContext);
  const iconStatus = type === currentTab.page;

  const pageHandler = () => {
    setCurrentTab({
      page: type,
    });
  };

  return (
    <Tab
      icon={iconStatus ? IconOn : IconOff}
      onClick={pageHandler}
      key={type}
      {...rest}
      notificationCount={breakingFeedNotifications}
      clearNotificationCount={clearNotificationCount}
    />
  );
};

NavigationTab.propTypes = {
  /** What type of navigation icon should be shown */
  type: PropTypes.string.isRequired,
  /** The icon that will be displayed when selected */
  IconOn: PropTypes.string.isRequired,
  /** The icon that will be displayed when not selected */
  IconOff: PropTypes.string.isRequired,
  /** number ofr breaking feed notifications  */
  breakingFeedNotifications: PropTypes.number,
  /** clear notification function */
  clearNotificationCount: PropTypes.func,
};

export default NavigationTab;
