import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  listHeader: {
    paddingRight: 0,
    paddingLeft: '12px',
    top: 0,
    position: 'sticky',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    zIndex: 10,
    height: '40px',
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    '&:hover': {
      background: theme.palette.dina.whiteHoverOverlay,
    },
  },
  buttonOverride: {
    '&:hover': {
      backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
      filter: 'brightness(1.08)',
    },
  },
  listHeaderText: {
    ...theme.typography.dina.listItemGroupHeadersLabel,
    marginLeft: '12px',
  },
  rightItems: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      ...theme.typography.dina.groupHeaderCounter,
      color: theme.palette.dina.iconInactive,
      marginRight: '12px',
    },
  },
  numberOf: {
    position: 'absolute',
    textAlign: 'right',
  },
  numberOfNotHovered: {
    right: '12px',
    transition: 'all 0.25s',
  },
  numberOfHovered: {
    right: '40px',
    transition: 'all 0.25s',
  },
  loadMoreButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
  },
}));

export default useStyles;
