import gql from 'graphql-tag';

export default gql`
  mutation CreateStoryForMember($input: CreateStoryForMemberInput) {
    createStoryForMember(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mAvatarUrl
      mAvatarKey
      mThumbnailKey
      mThumbnailUrl
      mContentKey
      mCreatedAt
      mUpdatedAt
      mState
      mPublishingAt
      mType
      mMetaData {
        key
        value
      }
      mAssignedMembers {
        mId
        mType
      }
      mCategories {
        label
        score
        categoryId
      }
    }
  }
`;
