/**
 *  Hook for unschedule instance state
 */

import { useMutation, useApolloClient } from 'react-apollo';
import UPDATE_INSTANCE from 'graphql/mutations/updateInstance';
import UPDATE_STORY_INSTANCE from 'graphql/mutations/updateStoryInstance';
import UPDATE_RUNDOWN_SYNC from 'graphql/mutations/updateRundownSync';
import GET_INSTANCE_FROM_CACHE from 'graphql/queries/getInstanceFromCache';
import GET_INSTANCE from 'graphql/queries/getInstance';
import { getStoryInstancesQuery } from 'graphql/queryVariables';
import memberTypes from 'graphql/memberTypes';
import convertToUnassignedInstance from 'utils/instance/unassignedInstance';

const useUnscheduleInstance = () => {
  const client = useApolloClient();
  const [updateInstance] = useMutation(UPDATE_INSTANCE);
  const [updateStoryInstance] = useMutation(UPDATE_STORY_INSTANCE);
  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);

  const updateCachedStoryInstances = (updatedInstances) => {
    updatedInstances
      .forEach((instance) => {
        if ( instance.mId[0] === '-' ) return; // ignoring empty placeholders
        try {
          // Checking if updated instance is available in cache.
          const rootQuery = client.cache.data.data.ROOT_QUERY;
          // Query to read from cache.
          const queryInCache =
              `getMembers({"input":{"mId":"${instance.mStoryId}","mType":"${memberTypes.STORY_INSTANCE}"}})`;
          // If query doesn't exists in cache return.
          if (rootQuery[queryInCache] === undefined) {
              return;
          }

          const instanceQueryProps = { 
            query: GET_INSTANCE, 
            variables: getStoryInstancesQuery(instance.mStoryId),
          }

          const data = client.cache.readQuery(instanceQueryProps);
          const updatingInstanceIndex = data.getMembers.findIndex(storyInstance => storyInstance.mId === instance.mId);
          
          if(updatingInstanceIndex > -1) {
            const instanceData = data.getMembers[updatingInstanceIndex];
            
            data.getMembers[updatingInstanceIndex] = convertToUnassignedInstance(instanceData);;

            client.cache.writeQuery({...instanceQueryProps, data: { ...data }})
          }
        } catch(error) {
          console.log(error);
        }
    });
  }

  const removeInstancesFromRundown = async (
    instances,
    removeInstanceCallback,
    params,
    orderType,
    rundownId,
  ) => {
    try {
      const mPayload = instances.filter((instance) => {
        if(instance.mId[0] === '-') return true; // passing the empty placeholder
        const { mProperties } = instance;
        const { account, platform } = mProperties;
        const { accountId: smId } = account;
        const mId = smId || rundownId;
        return platform === 'linear' && mId;
      }).map((instance) => {
        return {
          crudAction: 'REMOVE',
          value: {
            mId: instance.mId,
            destination: orderType,
          },
        };
      });
  
      const variables = {
        input: {
          mId: rundownId,
          mRefId: rundownId,
          mPayload,
        },
      };
      
      await updateRundownSync({
        variables,
      });
  
      updateCachedStoryInstances(instances);
      if (removeInstanceCallback) {
        removeInstanceCallback(params);
      }
    } catch(error) {
      console.log(error);
    }
  };

  const unschedule = async (
    instance,
    removeInstanceCallback,
    params,
    shouldChangePlatform,
    orderType,
    rundownId,
    type,
  ) => {
    let instanceToRemove = instance;
    const { mId, mProperties } = instanceToRemove;

    if (!mProperties) {
      const data = client.readFragment({
        id: mId,
        fragment: GET_INSTANCE_FROM_CACHE,
      });

      instanceToRemove = data;
    }

    const input = shouldChangePlatform ? convertToUnassignedInstance(instanceToRemove) : { mId, mPublishingAt: null };
      
      const updateInstanceCallback = 
        type && type === 'storyInstance' ? updateStoryInstance : updateInstance;
  
      updateInstanceCallback({
        variables: {
          input,
        },
      })   
  };

  const unscheduleRundownInstances = async (
    instanceIds,
    removeInstanceCallback,
    params,
    orderType,
    rundownId,
  ) => {
    try {
      const instances = instanceIds.map((mId) => {
        // generating dummy object for empty placeholders as we can not get them from cache.
        if(mId[0] === '-') {
          return {mId};
        }
  
        return client.readFragment({
          id: mId,
          fragment: GET_INSTANCE_FROM_CACHE,
        });
      });
  
      await removeInstancesFromRundown(
        instances,
        removeInstanceCallback,
        params,
        orderType,
        rundownId,
      );
    } catch(error) {
      console.log(error);
    }
  };


  return [unschedule, unscheduleRundownInstances];
};

export default useUnscheduleInstance;
