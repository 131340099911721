const priorityList = [
  { value: '', className: 'empty', text: 'Prio...' },
  {
    value: 'breaking',
    className: 'priorityBreaking',
    text: 'Breaking',
  },
  { value: 'prio1', className: 'priority1', text: 'Pri 1' },
  { value: 'prio2', className: 'priority2', text: 'Pri 2' },
  { value: 'prio3', className: 'priority3', text: 'Pri 3' },
];

export const findClassName = value => {
  const priorityObject = priorityList.find(item => value === item.value);
  return priorityObject ? priorityObject.className : null;
};

export default priorityList;
