import { makeStyles } from '@material-ui/styles';
import DraggableVerticalSrc from '../../../assets/icons/systemicons/DraggableVertical.svg';

const useStyles = makeStyles(() => ({
  root: {
    '& .Resizer': {
      zIndex: 100,
      boxSizing: 'border-box',
      backgroundClip: 'padding-box',
      cursor: 'col-resize',

      '&:before': {
        content: `url(${DraggableVerticalSrc})`,
      },

      '&:hover': {
        transition: 'all 2s ease',
      },
    },
  },
}));

export default useStyles;
