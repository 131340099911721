import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderStyle: 'hidden',
    boxShadow: `0 0 0 1px ${theme.palette.dina.dividerLight}`,
    borderRadius: '2px',
  },
  tableHeader: {
    height: '24px',
    ...theme.typography.dina.overline,
    lineHeight: '12px',
    letterSpacing: '1px',
    textAlign: 'left',
    paddingLeft: '8px',
    border: `1px solid ${theme.palette.dina.dividerLight}`,
    backgroundColor: theme.palette.dina.blackHoverOverlay,
  },
  tableData: {
    ...theme.typography.dina.listItemLabel,
    fontWeight: 'normal',
    border: `1px solid ${theme.palette.dina.dividerLight}`,
    height: '40px',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}));

export default useStyles;
