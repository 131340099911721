import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@material-ui/core';
import Divider from 'components/divider';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close_small.svg';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '16px',
    marginRight: '20px',
  },
  headerText: {
    ...theme.typography.dina.h5,
    lineHeight: '28px',
  },
  buttonRoot: {
    padding: '7px',
  },
}));

const HeaderView = ({ label, onClose, ...rest }) => {
  const classes = useStyles();
  return (
    <div {...rest}>
      <div className={classes.headerContainer}>
        <Typography classes={{ root: classes.headerText }}>{label}</Typography>
        {onClose && (
          <IconButton classes={{ root: classes.buttonRoot }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
      <Divider />
    </div>
  );
};
HeaderView.propTypes = {
  /** label of the header */
  label: PropTypes.string,
  /** onClose callback function: show when specified */
  onClose: PropTypes.func,
};

HeaderView.defaultProps = {
  label: '',
  onClose: undefined,
};

export default HeaderView;
