import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'tiny-slider/dist/tiny-slider.css';
import {
  startOfMonth,
  endOfMonth,
  eachDay,
  getDate,
  getMonth,
  getYear,
  isSameMonth,
} from 'date-fns';
import { isEqual } from 'lodash';
import Navs from '../Navs';
import useSliderStyles from '../useSliderStyles';
import {
  animateIndicator,
  resetAnimatedIndicator,
  showStaticIndicator,
  createSlider,
  createDragSelect,
} from './functions';
import useStyles from './DayPickerStyles';
import Indicator from './Indicator';
import HoverIndicator from './HoverIndicator';
import Day from './Day';

let slider;
let dragSelect;

const DayPicker = ({
  rootWidth,
  calendarDate,
  selectedDates,
  onDaySelect,
  onMonthChange,
}) => {
  const classes = useStyles();
  const sliderClasses = useSliderStyles();
  const sliderWrapper = useRef(null);
  const sliderContainer = useRef(null);
  const indicator = useRef(null);
  const hoverIndicatorRef = useRef(null);
  const selectorRef = useRef(null);
  const selectedDaysRef = useRef(null);
  const [arrowDirection, setArrowDirection] = useState('');
  const dayWidth = rootWidth / 7;
  const firstDayOfMonth = startOfMonth(calendarDate);
  const lastDayOfMonth = endOfMonth(calendarDate);
  const days = eachDay(firstDayOfMonth, lastDayOfMonth);

  const selectedDays = isSameMonth(calendarDate, selectedDates[0])
    ? selectedDates.map(selectedDate => getDate(selectedDate) - 1)
    : [];

  const resetIndicator = () => {
    resetAnimatedIndicator(indicator.current, sliderWrapper.current, dayWidth);
  };

  const init = () => {
    slider = createSlider(sliderContainer.current, dayWidth);

    dragSelect = createDragSelect({
      sliderContainer: sliderContainer.current,
      sliderWrapper: sliderWrapper.current,
      selector: selectorRef.current,
      hoverIndicatorNode: hoverIndicatorRef.current,
      dayWidth,
      setArrowDirection,
      onDaySelect,
    });

    slider.events.on('transitionEnd', resetIndicator);

    return () => {
      slider.events.off('transitionEnd', resetIndicator);
      dragSelect.stop();
    };
  };

  const update = () => {
    const isSameOldDays = isEqual(selectedDays, selectedDaysRef.current);
    const firstSelectedDay = selectedDays[0];
    const { index, slideCount } = slider.getInfo();
    const lastItemIndex = index + 6;
    const isActiveItem =
      firstSelectedDay >= index && firstSelectedDay <= lastItemIndex;

    if (isSameOldDays) {
      showStaticIndicator(indicator.current, sliderWrapper.current, dayWidth);
    } else {
      if (selectedDays.length === 1) {
        const isNotFirstDayOfMonth = firstSelectedDay !== 0;
        const isFirstActiveItem = firstSelectedDay === index;
        const isNotLastDayOfMonth = firstSelectedDay !== slideCount - 1;
        const isLastActiveItem = firstSelectedDay === lastItemIndex;

        if (isNotFirstDayOfMonth && isFirstActiveItem) {
          showStaticIndicator(
            indicator.current,
            sliderWrapper.current,
            dayWidth
          );
          slider.goTo(firstSelectedDay - 5);
        } else if (isNotLastDayOfMonth && isLastActiveItem) {
          showStaticIndicator(
            indicator.current,
            sliderWrapper.current,
            dayWidth
          );
          slider.goTo(firstSelectedDay - 1);
        } else if (isActiveItem) {
          animateIndicator(indicator.current, sliderWrapper.current, dayWidth);
        } else {
          showStaticIndicator(
            indicator.current,
            sliderWrapper.current,
            dayWidth
          );
          slider.goTo(firstSelectedDay - 1);
        }
      } else if (isActiveItem) {
        animateIndicator(indicator.current, sliderWrapper.current, dayWidth);
      } else {
        showStaticIndicator(indicator.current, sliderWrapper.current, dayWidth);
        slider.goTo(firstSelectedDay);
      }
    }

    return () => {
      selectedDaysRef.current = selectedDays;
    };
  };

  useEffect(init, [getMonth(calendarDate), getYear(calendarDate)]);
  useEffect(update, [selectedDays]);

  const onPrev = () => {
    const { index } = slider.getInfo();
    const isNotFirstItem = index > 0;

    if (isNotFirstItem) {
      showStaticIndicator(indicator.current, sliderWrapper.current, dayWidth);
      slider.goTo('prev');
    } else {
      onMonthChange(-1);
    }
  };

  const onNext = () => {
    const { index, slideCount } = slider.getInfo();
    const lastItemIndex = index + 6;
    const isNotLastItem = lastItemIndex < slideCount - 1;

    if (isNotLastItem) {
      showStaticIndicator(indicator.current, sliderWrapper.current, dayWidth);
      slider.goTo('next');
    } else {
      onMonthChange(1);
    }
  };

  return (
    <div className={classes.root}>
      <Navs {...{ onPrev, onNext }} />
      <div
        ref={selectorRef}
        className={`${classes.selector} ${arrowDirection}`}
      />

      <div className={sliderClasses.root} ref={sliderWrapper}>
        <HoverIndicator ref={hoverIndicatorRef} />
        <Indicator ref={indicator} />

        <div ref={sliderContainer} className={sliderClasses.sliderContainer}>
          {days.map((day, index) => (
            <div key={day}>
              <Day
                data-id={index + 1}
                isSelected={selectedDays.includes(index)}
                {...{ day }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

DayPicker.propTypes = {
  rootWidth: PropTypes.number.isRequired,
  calendarDate: PropTypes.instanceOf(Date).isRequired,
  selectedDates: PropTypes.arrayOf(Date).isRequired,
  onDaySelect: PropTypes.func.isRequired,
  onMonthChange: PropTypes.func.isRequired,
};

export default DayPicker;
