/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { useSelectionContext } from 'contexts/SelectionContext';
import UPDATE_RUNDOWN_SYNC from 'graphql/mutations/updateRundownSync';
import useDidMount from 'hooks/useDidMount';
import { move, returnPaneHeight } from './utils';
import useStyles from './rundown-list-styles';
import SplitView from '../splitView';
import List from '../list';

const listTypes = {
  readyList: 'mOrder',
  preparingList: 'mPreorder',
};

const getType = type => (type === 'readyList' ? 'ready' : 'preparing');

const RundownListsView = props => {
  const {
    mId,
    refId,
    id,
    data,
    title,
    toolbarToggles,
    updatedInstance,
    selecteddate,
    showMasterEditView,
    mOrder,
    mPreorder,
    canEditReady,
    updateCacheForOrderType,
    ...rest
  } = props;

  const [containerHeight, setContainerHeight] = useState(552);
  // Height of listHeader (46px+32px)
  const heightOffset = 78;
  const scrollThumbOffset = 14;
  const calculatePaneHeight = returnPaneHeight(heightOffset);
  // height of list when it's not in splitview
  const initialListHeight = containerHeight - heightOffset - scrollThumbOffset;
  // height of list when it's in splitview
  const [paneHeight, setPaneHeight] = useState(calculatePaneHeight(containerHeight));
  // ref for determining height of the split container
  const splitRef = useRef();
  const [selectedIds, setSelectedIds] = useSelectionContext();

  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);
  const { showPreparing, showReady, showEditor } = toolbarToggles;
  const classes = useStyles(showEditor);
  const componentIsMounted = useDidMount();

  const instanceList = {
    readyList: mOrder,
    preparingList: mPreorder,
  };

  const getListType = useCallback(
    insId => {
      if (mOrder.includes(insId)) return 'readyList';
      if (mPreorder.includes(insId)) return 'preparingList';
      return null;
    },
    [mOrder, mPreorder],
  );

  const cursorUp = () => {
    if (selectedIds.length === 1) {
      const currentInstance = selectedIds[0];
      const listType = getListType(currentInstance);

      if (listType) {
        const list = instanceList[listType];

        const currentIndex = list.indexOf(currentInstance);
        const nextIndex = currentIndex - 1;
        if (nextIndex >= 0) setSelectedIds([list[nextIndex]]);
      }
    }
  };

  const cursorDown = () => {
    if (selectedIds.length === 1) {
      const currentInstance = selectedIds[0];
      const listType = getListType(currentInstance);

      if (listType) {
        const list = instanceList[listType];

        const currentIndex = list.indexOf(currentInstance);
        const nextIndex = currentIndex + 1;
        if (nextIndex < list.length) setSelectedIds([list[nextIndex]]);
      }
    }
  };

  onkeydown = event => {
    if (!componentIsMounted) return;
    if (event.code === 'ArrowUp') {
      event.stopPropagation();
      event.preventDefault();
      event.view.event.preventDefault();
      cursorUp();
    }
    if (event.code === 'ArrowDown') {
      event.stopPropagation();
      event.preventDefault();
      event.view.event.preventDefault();
      cursorDown();
    }
  };

  onclick = event => {
    if (!componentIsMounted) return;
    if (event.defaultPrevented) {
      return;
    }

    // if (currentInstance.mId)
    if (selectedIds.length > 1) {
      unselectAll();
    }
  };

  const init = () => {
    let resizeObserver;

    if (splitRef.current) {
      const element = splitRef.current;

      if (window.ResizeObserver) {
        resizeObserver = new ResizeObserver(() => {
          const { height } = element.getBoundingClientRect();
          setContainerHeight(height);
        });

        resizeObserver.observe(element);
      } else {
        const { height } = element.getBoundingClientRect();
        setContainerHeight(height);
      }
    }

    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  };

  useEffect(init, []);

  useEffect(() => {
    setPaneHeight(calculatePaneHeight(containerHeight));
  }, [containerHeight]);

  const saveBothOrder = (variables, newMorder, newPreorder) => {
    updateRundownSync({
      variables,
      optimisticResponse: {
        __typename: 'Mutation',
        updateRundownSync: {
          __typename: 'Rundown',
          ...data.getRundown,
          mOrder: newMorder,
          mPreorder: newPreorder,
        },
      },
    });
  };

  const onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (selectedIds.length > 1) {
      if (source.droppableId === destination.droppableId) {
        const srcList = instanceList[source.droppableId];

        const newDropIndex = selectedIds.reduce((acc, current) => {
          const index = srcList.indexOf(current);
          if (index < destination.index) return acc - 1;
          return acc;
        }, destination.index);

        const newSrcList = srcList.filter(item => !selectedIds.includes(item));

        newSrcList.splice(newDropIndex, 0, ...selectedIds);

        const removedInput = [];
        const insertInput = [];

        selectedIds.forEach((insId, index) => {
          removedInput.push({
            crudAction: 'REMOVE',
            value: {
              mId: insId,
              destination: listTypes[source.droppableId],
            },
          });

          insertInput.push({
            crudAction: 'INSERT',
            value: {
              mId: insId,
              index: newDropIndex + index,
              destination: listTypes[destination.droppableId],
            },
          });
        });

        const variables = {
          input: { mId, mRefId: refId, mPayload: [...removedInput, ...insertInput] },
        };
        updateRundownSync({
          variables,
          optimisticResponse: {
            __typename: 'Mutation',
            updateRundownSync: {
              __typename: 'Rundown',
              ...data.getRundown,
              [listTypes[source.droppableId]]: newSrcList,
            },
          },
        });
      } else {
        const srcList = instanceList[source.droppableId];
        const destList = instanceList[destination.droppableId];

        const newDestList = [
          ...destList.slice(0, destination.index),
          ...selectedIds,
          ...destList.slice(destination.index),
        ];

        const newLists = {
          [listTypes[source.droppableId]]: srcList.filter(item => !selectedIds.includes(item)),
          [listTypes[destination.droppableId]]: newDestList,
        };

        const removeInput = [];
        const insertInput = [];

        selectedIds.forEach((sId, index) => {
          removeInput.push({
            crudAction: 'REMOVE',
            value: {
              mId: sId,
              destination: listTypes[source.droppableId],
            },
          });
          insertInput.push({
            crudAction: 'INSERT',
            value: {
              mId: sId,
              index: destination.index + index,
              destination: listTypes[destination.droppableId],
            },
          });
          updateCacheForOrderType(sId, getType(destination.droppableId));
        });

        const variables = {
          input: { mId, mRefId: refId, mPayload: [...removeInput, ...insertInput] },
        };

        saveBothOrder(variables, newLists.mOrder, newLists.mPreorder);
      }
    }

    if (selectedIds.length <= 1) {
      const sourceOrder = instanceList[source.droppableId];
      const sourceId = sourceOrder[source.index];
      // const type = getType(destination.droppableId);
      // setCurrentInstance({ mId: sourceId, type, index: destination.index });
      if (source.droppableId === destination.droppableId) {
        const { droppableId } = source;
        const dropIndex =
          source.index < destination.index ? destination.index - 1 : destination.index;

        if (source.index === dropIndex) return;

        const newSrcOrder = sourceOrder.filter(item => item !== sourceId);

        newSrcOrder.splice(dropIndex, 0, sourceId);

        const payloadInput = {
          crudAction: 'MOVE',
          value: {
            mId: sourceId,
            index: dropIndex,
            destination: listTypes[source.droppableId],
          },
        };
        const variables = { input: { mId, mRefId: refId, mPayload: [payloadInput] } };
        updateRundownSync({
          variables,
          optimisticResponse: {
            __typename: 'Mutation',
            updateRundownSync: {
              __typename: 'Rundown',
              ...data.getRundown,
              [listTypes[droppableId]]: newSrcOrder,
            },
          },
        });
      } else {
        const { preparingList: newPreorder, readyList: newMorder } = move(
          instanceList[source.droppableId],
          instanceList[destination.droppableId],
          source,
          destination,
        );
        const payloadSourceInput = {
          crudAction: 'REMOVE',
          value: {
            mId: sourceId,
            destination: listTypes[source.droppableId],
          },
        };
        const payloadDestInput = {
          crudAction: 'INSERT',
          value: {
            mId: sourceId,
            index: destination.index,
            destination: listTypes[destination.droppableId],
          },
        };
        const variables = {
          input: { mId, mRefId: refId, mPayload: [payloadSourceInput, payloadDestInput] },
        };
        saveBothOrder(variables, newMorder, newPreorder);
        updateCacheForOrderType(sourceId, getType(destination.droppableId));
      }
      if (selectedIds.length === 0) setSelectedIds([sourceId]);
    }

    // unselectAll();
  };

  const SortAccordingToOrder = (originalArr, newArr) =>
    newArr.sort((a, b) => originalArr.indexOf(a) - originalArr.indexOf(b));

  const toggleSelection = useCallback(
    instanceId => {
      const wasSelected = selectedIds.includes(instanceId);
      if (wasSelected && selectedIds.length === 1) return;

      const newInstanceIds = (() => {
        if (!wasSelected) {
          return [instanceId];
        }
        if (selectedIds.length > 1) {
          return [instanceId];
        }
        return [];
      })();

      setSelectedIds([...newInstanceIds]);
    },
    [selectedIds, setSelectedIds],
  );
  const selectionInGroup = useCallback(
    (instanceId, listId) => {
      if (!selectedIds.length) return [instanceId];

      const lastSelected = selectedIds[selectedIds.length - 1];
      const columnOfLast = getListType(lastSelected);
      if (!columnOfLast) return null;

      if (listId === columnOfLast) {
        const index = selectedIds.indexOf(instanceId);
        if (index === -1) return [...selectedIds, instanceId];

        const shallow = [...selectedIds];
        shallow.splice(index, 1);
        return [...shallow];
      }

      return [instanceId];
    },
    [getListType, selectedIds],
  );

  const toggleSelectionInGroup = useCallback(
    (instanceId, listId) => {
      const newList = selectionInGroup(instanceId, listId);

      if (newList) {
        setSelectedIds(SortAccordingToOrder(instanceList[listId], newList));
      }
    },
    [instanceList, selectionInGroup, setSelectedIds],
  );

  const multiSelect = useCallback(
    (newMId, listId) => {
      if (!selectedIds.length) return [newMId];

      const columnOfNew = instanceList[listId];
      const indexOfNew = columnOfNew.indexOf(newMId);

      const lastSelected = selectedIds[selectedIds.length - 1];
      const lastSelectedListId = getListType(lastSelected);
      if (!lastSelectedListId) return null;

      const columnOfLast = instanceList[lastSelectedListId];
      const indexOfLast = columnOfLast.indexOf(lastSelected);

      /* 
       multi selecting to another column
       select everything up to the index of the current item 
       */
      if (listId !== lastSelectedListId) return columnOfNew.slice(0, indexOfNew + 1);

      if (indexOfNew === indexOfLast) return null;

      const isSelectingForwards = indexOfNew > indexOfLast;
      const start = isSelectingForwards ? indexOfLast : indexOfNew;
      const end = isSelectingForwards ? indexOfNew : indexOfLast;

      const inBetween = columnOfNew.slice(start, end + 1);

      const toAdd = inBetween.filter(nId => {
        if (selectedIds.includes(nId)) {
          return false;
        }
        return true;
      });

      const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
      const combined = [...selectedIds, ...sorted];

      return combined;
    },
    [getListType, instanceList, selectedIds],
  );

  const multiSelectTo = useCallback(
    (newInstanceId, listId) => {
      const updated = multiSelect(newInstanceId, listId);

      if (updated == null) {
        return;
      }

      setSelectedIds(SortAccordingToOrder(instanceList[listId], updated));
    },
    [instanceList, multiSelect, setSelectedIds],
  );

  const unselectAll = () => {
    setSelectedIds([]);
  };

  const updatePaneHeight = size => {
    setPaneHeight({
      // height of (pane1 - heightOffset)
      pane1: size - heightOffset,
      // height of (containerHeight- pane1 - heightOffset - resizer - scrollthumboffset)
      pane2: containerHeight - size - heightOffset - 22,
    });
  };

  return (
    <div
      className={showMasterEditView ? classes.editRundownList : classes.rundownList}
      ref={splitRef}
    >
      {showPreparing && !showReady && (
        <List
          mid={mId}
          scrollingId="preparingList"
          listHeaderTitle="Preparing"
          height={initialListHeight}
          selecteddate={showMasterEditView ? mId : selecteddate}
          type="preparing"
          mOrder={mPreorder}
          {...{
            showMasterEditView,
            data,
            updatedInstance,
            refId,
            toggleSelectionInGroup,
            multiSelectTo,
            toggleSelection,
            onDragEnd,
          }}
          {...rest}
        />
      )}
      {showReady && !showPreparing && (
        <List
          mid={mId}
          scrollingId="readyList"
          listHeaderTitle="Ready"
          height={initialListHeight}
          selecteddate={showMasterEditView ? mId : selecteddate}
          type="ready"
          mOrder={mOrder}
          disableEdit={!canEditReady}
          {...{
            showMasterEditView,
            data,
            updatedInstance,
            refId,
            toggleSelectionInGroup,
            multiSelectTo,
            toggleSelection,
            onDragEnd,
          }}
          {...rest}
        />
      )}
      {showPreparing && showReady && (
        <SplitView
          pane1={
            <List
              mid={mId}
              scrollingId="readyList"
              listHeaderTitle="Ready"
              height={paneHeight.pane1}
              selecteddate={showMasterEditView ? mId : selecteddate}
              type="ready"
              mOrder={mOrder}
              disableEdit={!canEditReady}
              {...{
                showMasterEditView,
                data,
                updatedInstance,
                refId,
                toggleSelectionInGroup,
                multiSelectTo,
                toggleSelection,
                onDragEnd,
              }}
              {...rest}
            />
          }
          pane2={
            <List
              mid={mId}
              scrollingId="preparingList"
              listHeaderTitle="Preparing"
              height={paneHeight.pane2}
              selecteddate={showMasterEditView ? mId : selecteddate}
              type="preparing"
              mOrder={mPreorder}
              {...{
                showMasterEditView,
                data,
                updatedInstance,
                refId,
                toggleSelectionInGroup,
                multiSelectTo,
                toggleSelection,
                onDragEnd,
              }}
              {...rest}
            />
          }
          {...{ updatePaneHeight }}
        />
      )}
    </div>
  );
};

export default RundownListsView;
