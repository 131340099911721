import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  default: {
    height: 40,
    width: 220,
    border: `0.5px solid ${theme.palette.dina.buttonBorderOutlined}`,
    borderRadius: 4,
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },
  confirm: {
    height: 40,
    width: 220,
    borderRadius: 4,
    backgroundColor: theme.palette.dina.statusError,
    '&:hover': {
      backgroundColor: theme.palette.dina.statusError,
      '-webkit-filter': theme.palette.dina.onHover,
    },
  },
  startIcon: {
    marginLeft: 0,
    marginRight: '4px',
    height: 24,
    width: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    justifyContent: 'start',
    textTransform: 'none',
  },
  defaultLabel: {
    ...theme.typography.dina.button,
    fontWeight: 'normal',
    color: theme.palette.dina.mediumEmphasis,
    '&:hover': {
      color: theme.palette.dina.mediumEmphasis,
    },
  },
  confirmLabel: {
    ...theme.typography.dina.button,
    fontWeight: 'normal',
    color: theme.palette.dina.highEmphasis,
  },
}));

export default useStyles;
