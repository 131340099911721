import rundownItemTypes, { primaryTypes, secondaryTypes } from 'utils/rundownItemTypes';

const blockTypes = {
  DEFAULT_NODE: 'paragraph',
  EMPTY: 'empty',
  HEADING_FOUR: 'heading-four',
  HEADING_ONE: 'heading-one',
  HEADING_THREE: 'heading-three',
  HEADING_TWO: 'heading-two',
  HORIZONTAL_RULE: 'horizontal-rule',
  IMAGE: 'image',
  LIST_ITEM_CHILD: 'list-item-child',
  LIST_ITEM: 'list-item',
  MOS: 'mos',
  ORDERED_LIST: 'ordered-list',
  PLACEHOLDER: 'placeholder',
  QUOTE: 'block-quote',
  UNORDERED_LIST: 'unordered-list',
  VIDEO: 'video',
};

export default {
  ...blockTypes,
  ...rundownItemTypes,
  primaryTypes,
  secondaryTypes,
};
