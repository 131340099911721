import { useMutation } from 'react-apollo';
import CREATE_PLACEHOLDER from 'graphql/mutations/createPlaceholder';

const useCreatePlaceholder = () => {
  const [createPlaceholder] = useMutation(CREATE_PLACEHOLDER);

  const createStoryPlaceholder = async (storyId, data) => {
    const { account } = data.mProperties;
    const mProperties = {
      __typename: 'PlatformType',
      platform: data.mProperties.platform,
      account: {
        accountUrl: account.accountUrl,
        accountLogo: account.accountLogo,
        accountTitle: account.accountTitle,
        accountId: account.accountId,
      },
    };
    const input = {
      mId: storyId,
      mTitle: data.mTitle,
      mProperties,
    };

    const reposnse = await createPlaceholder({
      variables: { input },
    });
    return reposnse.data.createPlaceholder;
  };

  return [createStoryPlaceholder];
};

export default useCreatePlaceholder;
