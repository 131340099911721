import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { format, getISOWeek, getISOYear, startOfDay, isWithinRange } from 'date-fns';
import gql from 'graphql-tag';
import { getStoriesQuery } from 'graphql/queryVariables';
import { useSidebarContext } from 'globalState';
import { SidebarDatePickerContext } from 'globalState/sidebarDatePicker';
import { SidebarSubTabContext } from 'globalState/sidebarSubTab';
import useApolloSubscription from 'hooks/useApolloSubscription';
import GET_STORY_BY_PUBLISHING_DATE from 'graphql/queries/getStoryByPublishingDate';
import useGetMembersByPublishingDate from 'hooks/useGetMembersByPublishingDate';
import UPDATE_WEEK_VIEW_SUBSCRIPTION from 'graphql/subscriptions/updateWeekView';
import CREATE_STORY from 'graphql/mutations/createStoryFromWeekView';
import CREATE_PITCH from 'graphql/mutations/createPitch';
import UserCtx from 'contexts/UserContext';
import memberTypes from 'graphql/memberTypes';
import LoadingIndicator from 'components/loadingIndicator';
import useUpdatePublishingDate from 'hooks/useUpdatePublishingDate';
import useFuseSearch from 'hooks/useFuseSearch';
import useOpenStory from 'hooks/useOpenStory';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useSetStorySync from 'hooks/useSetStorySync';
import getDates from 'utils/getDates';
import frontendFiltering from '../../utils/frontendFiltering';
import getUniqueFilters from '../../utils/getUniqueFilters';
import WeekActionContainer from './week-action-container';
import updateCache from '../day/updateCache';

const GET_STORIES = gql`
  query GetStories($input: GetMemberInput, $limit: Int, $nextToken: String) {
    getMembersPagination(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
      }
      nextToken
    }
  }
`;

const GET_STORIES_BY_UPDATING_DATE = gql`
  query GetStoriesByUpdatedDate(
    $input: GetMembersByPublishingDateInput
    $limit: Int
    $nextToken: String
  ) {
    getMembersByUpdatedAtDate(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
      }
      nextToken
    }
  }
`;

const Week = ({ time, timeVariant, showStory, showPitch, filters, selectedFilter, ...rest }) => {
  const { startDate, endDate, data: stories } = getDates(time, timeVariant);
  const user = useContext(UserCtx);
  const { mId: userId } = user;
  const [createStoryOnWeekday] = useMutation(CREATE_STORY);
  const [createPitchOnWeekDay] = useMutation(CREATE_PITCH);
  const openStory = useOpenStory();
  const [updatePublishingDate] = useUpdatePublishingDate();
  const search = useFuseSearch();
  const [checkUserRight] = useCheckUserRight();
  const enableSynchronize = checkUserRight('feature', 'story-auto-synchronize');
  const [setSync] = useSetStorySync();
  // Week view: Week (yyyy-ISO week)
  const weekView = `${getISOYear(startDate)}-${getISOWeek(startDate)}`;
  const [subscribe, unSubscribe] = useApolloSubscription(UPDATE_WEEK_VIEW_SUBSCRIPTION, {
    variables: {
      weekView,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      // console.log('subscription data week view:', subscriptionData);
      const story = subscriptionData.data.updateWeekViewSubscription;
      updateCache(client, story, startDate, endDate);
    },
  });

  const [{ isLeftHidden, leftSelection }] = useSidebarContext();
  const [selectedDates] = useContext(SidebarDatePickerContext);
  const [{ scheduleType }] = useContext(SidebarSubTabContext);

  const { startDate: start, endDate: end, ignoreStartDate } = selectedDates;
  const formatedDate = format(new Date(), 'YYYY-MM-DD');
  const sDate = format(start, 'YYYY-MM-DD');
  const eDate = format(end, 'YYYY-MM-DD');
  useEffect(() => {
    subscribe();

    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const [data, error, loading] = useGetMembersByPublishingDate(
    startDate,
    endDate,
    'story',
    !showStory,
  );

  const [pData, pError, pLoading] = useGetMembersByPublishingDate(
    startDate,
    endDate,
    'pitch',
    !showPitch,
  );

  if (loading) return <LoadingIndicator />;
  if (pLoading) return <LoadingIndicator />;
  if (error) return <div>ERROR! {error.message}</div>;
  if (pError) return <div>ERROR! {pError.message}</div>;

  const { getMembersByPublishingDate: storyData } = data;
  const { getMembersByPublishingDate: pitchData } = pData;

  const filterList = getUniqueFilters(filters, selectedFilter);

  const filteredData = frontendFiltering([...storyData, ...pitchData], filterList, search);

  filteredData.forEach(item => {
    const date = format(item.mPublishingAt, 'YYYY-MM-DD');
    stories[date] && stories[date].push(item);
  });

  const onCreateStory = async (title, date) => {
    try {
      await createStoryOnWeekday({
        variables: {
          input: {
            mId: userId,
            mTitle: title,
            mPublishingAt: startOfDay(date).toISOString(),
            mType: memberTypes.USER_STORY,
            mCreatedById: userId,
          },
        },
        update: (proxy, mutationResult) => {
          const { createStoryForMember } = mutationResult.data;
          if (enableSynchronize) setSync(createStoryForMember.mId, ['*']);

          if (leftSelection === memberTypes.STORY && scheduleType === 'scheduled') {
            return;
          }

          try {
            if (isWithinRange(formatedDate, sDate, eDate) && !isLeftHidden) {
              let query = GET_STORIES;
              let variables = getStoriesQuery(userId, 'usr_str');
              variables.input.startDate = start.toISOString();
              variables.input.endDate = end.toISOString();
              variables.input.ignoreStartDate = ignoreStartDate;

              if (leftSelection === memberTypes.STORY) {
                query = GET_STORIES_BY_UPDATING_DATE;
                variables = {
                  input: {
                    mType: 'story',
                    startDate: start.toISOString(),
                    endDate: end.toISOString(),
                    scheduleType,
                    ignoreStartDate,
                  },
                  limit: 50,
                };
              }

              let hasQueryInCache = true;
              let storyList;

              try {
                storyList = proxy.readQuery({
                  query,
                  variables,
                });
              } catch (err) {
                // eslint-disable-next-line no-console
                hasQueryInCache = false;
              }

              if (hasQueryInCache) {
                const exists = story => {
                  return story !== null && story.mId === createStoryForMember.mId;
                };

                if (
                  leftSelection === 'story' &&
                  !storyList.getMembersByUpdatedAtDate.items.some(exists)
                ) {
                  storyList.getMembersByUpdatedAtDate.items.unshift(createStoryForMember);
                } else if (!storyList.getMembersPagination.items.some(exists)) {
                  storyList.getMembersPagination.items.unshift(createStoryForMember);
                }

                proxy.writeQuery({
                  query,
                  variables,
                  data: storyList,
                });

                const elm = document.getElementById('scrollbar');

                if (elm && elm.firstChild) {
                  setTimeout(() => {
                    elm.firstChild.scrollTop = 0;
                  }, 1000);
                }
              }
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const onCreatePitch = async (title, date) => {
    try {
      await createPitchOnWeekDay({
        variables: {
          input: {
            mId: userId,
            mTitle: title,
            mPublishingAt: startOfDay(date).toISOString(),
          },
        },
        update: (proxy, mutationResult) => {
          const { createPitch } = mutationResult.data;
          try {
            const publishingPitchList = proxy.readQuery({
              query: GET_STORY_BY_PUBLISHING_DATE,
              variables: {
                input: {
                  mType: 'pitch',
                  startDate,
                  endDate,
                },
              },
            });

            const updatingPitchList = proxy.readQuery({
              query: GET_STORIES_BY_UPDATING_DATE,
              variables: {
                input: {
                  mType: 'pitch',
                  startDate: start.toISOString(),
                  endDate: end.toISOString(),
                  scheduleType,
                  ignoreStartDate,
                },
                limit: 50,
              },
            });

            const exists = pitch => {
              return pitch !== null && pitch.mId === createPitch.mId;
            };

            if (!publishingPitchList.getMembersByPublishingDate.some(exists)) {
              publishingPitchList.getMembersByPublishingDate.unshift(createPitch);
            }

            if (!updatingPitchList.getMembersByUpdatedAtDate.items.some(exists)) {
              updatingPitchList.getMembersByUpdatedAtDate.items.unshift(createPitch);
            }

            proxy.writeQuery({
              query: GET_STORY_BY_PUBLISHING_DATE,
              variables: {
                input: {
                  mType: 'pitch',
                  startDate,
                  endDate,
                },
              },
              data: publishingPitchList,
            });

            proxy.writeQuery({
              query: GET_STORIES_BY_UPDATING_DATE,
              variables: {
                input: {
                  mType: 'pitch',
                  startDate: start.toISOString(),
                  endDate: end.toISOString(),
                  scheduleType,
                  ignoreStartDate,
                },
                limit: 50,
              },
              data: updatingPitchList,
            });
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
          }
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const updateDroppedMember = (mId, date) => {
    updatePublishingDate(mId, date, startDate, endDate);
  };

  return (
    <WeekActionContainer
      stories={stories}
      startDate={startDate}
      endDate={endDate}
      onCreateStory={onCreateStory}
      onCreatePitch={onCreatePitch}
      openStory={openStory}
      time={time}
      {...{ showStory, showPitch, updateDroppedMember }}
      {...rest}
    />
  );
};

Week.propTypes = {
  /** A time within the Week */
  time: PropTypes.string.isRequired,
  /** Time variant of the view */
  timeVariant: PropTypes.string,
  showStory: PropTypes.bool,
  showPitch: PropTypes.bool,
  searchString: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.object),
  selectedFilter: PropTypes.shape({
    label: PropTypes.string,
    expression: PropTypes.arrayOf(PropTypes.object),
  }),
};

Week.defaultProps = {
  timeVariant: 'week',
  showPitch: true,
  showStory: true,
  searchString: '',
  filters: [],
  selectedFilter: {},
};

export default Week;
