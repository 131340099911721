import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Toolbar, Slide, ClickAwayListener } from '@material-ui/core';
import InputField from 'components/inputField';
import { ReactComponent as Warning } from 'assets/icons/systemicons/warning.svg';
import { format, isToday, differenceInCalendarDays } from 'date-fns';
import fallBackImage from 'assets/images/default/defaultCoverPhoto.png';
import ValidateTimeInput from '../../../utils/validateTimeInput';
import useStyles from './default-header-styles';

const HeaderView = ({
  title: initialTitle,
  image,
  startTime,
  setStartTime,
  selecteddate,
  isOnAir,
  updateRundownTitle,
  startDate,
}) => {
  const classes = useStyles(image || fallBackImage, !isToday(startDate), isOnAir)();
  const [startTimeInput, setStartTimeInput] = useState(startTime);
  const [startInputFocus, setStartInputFocus] = useState(false);
  const [showInputField, setShowInputField] = useState(false);
  const [title, setTitle] = useState(initialTitle);

  const handleStartTimeSave = useCallback(
    e => {
      const validatedTimeInput = ValidateTimeInput(e.target.value);
      if (validatedTimeInput && validatedTimeInput !== startTime) {
        setStartTime(validatedTimeInput);
        setStartTimeInput(validatedTimeInput);
      } else {
        setStartTimeInput(startTime);
      }
    },
    [setStartTime, startTime],
  );
  const showStartInput = () => setStartInputFocus(true);

  useEffect(() => {
    setStartTimeInput(startTime);
    setTitle(initialTitle);
    const logKey = event => {
      if (event.code === 'Escape') {
        if (startInputFocus) {
          setStartTimeInput(startTime);
          setStartInputFocus(false);
        }
      }
    };
    document.addEventListener('keydown', logKey);
    return () => {
      document.removeEventListener('keydown', logKey);
    };
  }, [handleStartTimeSave, startInputFocus, startTime, initialTitle]);

  const onStartInputChange = useCallback(e => {
    setStartTimeInput(e.target.value);
    setStartInputFocus(true);
  }, []);

  const onStartInputBlur = e => {
    setStartInputFocus(false);
    handleStartTimeSave(e);
  };

  const timeDistanceHelper = date => {
    const value = differenceInCalendarDays(new Date(), date);
    if (value === 0) return '';
    if (value === -1) return ` (in 1 day)`;
    if (value < -1) return ` (in ${-value} days)`;
    if (value === 1) return ' (1 day ago)';
    return ` (${value} days ago)`;
  };

  const handleClickAway = () => {
    handleStartTimeSave({ target: { value: startTimeInput } });
  };

  const onTitleClick = () => {
    setShowInputField(true);
  };

  const onTitleChange = value => {
    setTitle(value);
  };

  const handleEnterKey = e => {
    if (e.keyCode === 13 && title !== '') {
      updateRundownTitle(title);
      setShowInputField(false);
    }
    if (e.keyCode === 27) {
      setTitle(initialTitle);
      setShowInputField(false);
    }
  };

  return (
    <div className={classes.rootContainer}>
      {(!isToday(startDate) || isOnAir) && (
        <Slide direction="down" in timeout={{ enter: 500 }} mountOnEnter unmountOnExit>
          <Toolbar className={classes.toolbar}>
            <Warning className={classes.icon} />
            <Typography classes={{ root: classes.toolbarText }}>
              {isOnAir ? 'Rundown is ON Air' : 'Rundown is not scheduled for today.'}
            </Typography>
          </Toolbar>
        </Slide>
      )}
      <div className={classes.content}>
        <div className={classes.titleBlock}>
          <div className={classes.title}>
            {showInputField ? (
              <InputField
                value={title}
                autoFocus
                onChange={onTitleChange}
                onKeyDown={handleEnterKey}
              />
            ) : (
              <Typography classes={{ root: classes.titleText }} onClick={onTitleClick}>
                {title}
              </Typography>
            )}
          </div>
          <Typography classes={{ root: classes.label }}>Rundown Title</Typography>
        </div>
        <div className={classes.dateBlock}>
          <Typography classes={{ root: classes.date }} className={classes.dateText}>
            {isToday(startDate) ? 'Today' : format(startDate, 'ddd. D MMM. YYYY')}
          </Typography>
          <Typography classes={{ root: classes.label }}>
            Date
            {timeDistanceHelper(startDate)}
          </Typography>
        </div>
        <div className={classes.startTimeBlock}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <form
              onSubmit={event => {
                event.preventDefault();
                handleClickAway();
              }}
            >
              <input
                className={classes.input}
                value={startTimeInput}
                onBlur={onStartInputBlur}
                onClick={showStartInput}
                onChange={onStartInputChange}
              />
            </form>
          </ClickAwayListener>
          <Typography classes={{ root: classes.label }}>Start Time</Typography>
        </div>
      </div>
    </div>
  );
};

HeaderView.propTypes = {
  /** Title of the Rundown */
  title: PropTypes.string,
  /** Background image of the header */
  image: PropTypes.string,
  /** Starting time of the rundown */
  startTime: PropTypes.string,
  /** callback function to change the startTime */
  setStartTime: PropTypes.func,
  /** Date of the rundown */
  selecteddate: PropTypes.string,
  /** Boolean that indicates that rundown is on air */
  isOnAir: PropTypes.bool,
  /** callback function to update rundown title */
  updateRundownTitle: PropTypes.func,
  /** Starting date-time for the rundown */
  startDate: PropTypes.string,
};

HeaderView.defaultProps = {
  title: '',
  image: '',
  startTime: '00:00:00',
  setStartTime: () => {},
  selecteddate: new Date().toISOString(),
  isOnAir: false,
  updateRundownTitle: () => {},
  startDate: new Date().toISOString(),
};

export default HeaderView;
