import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { ReactComponent as Maximize } from 'assets/icons/systemicons/fullscreen.svg';
import configCtx from 'contexts/configContext';
import useStyles from './iframe-style';

const IframeView = props => {
  const { externalUrls } = useContext(configCtx);
  const classes = useStyles();
  const { otherprops, setExpand, isRightExpanded } = props;
  const conf = externalUrls.find(u => u.mTitle === otherprops.ExternalName);
  const url = conf ? conf.externalUrl : 'http://';
  const onExpand = () => {
    setExpand(!isRightExpanded);
  };
  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        title={!isRightExpanded ? 'Expand' : 'collapse'}
        className={`${classes.openStoryBoxButton} ${isRightExpanded ? '' : classes.fadeIn}`}
        classes={{ label: classes.icon }}
        onClick={onExpand}
      >
        <Maximize />
      </Button>
      <iframe
        id={otherprops.ExternalName}
        src={url}
        title={otherprops.ExternalName}
        className={classes.root}
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      />
    </div>
  );
};

export default IframeView;
