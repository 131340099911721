import uuidv1 from 'uuid/v1';
import { inTimingTypes, outTimingTypes, blockTypes } from '../types';
import getTypeValues from './getTypeValues';
import insertBlock from './insertBlock';
import returnTemplateType from './returnTemplateType';

/**
 * Inserts secondary automation block
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} type Type of the block
 * @param {Object} options Options for the item
 * @param {Object} parentNode SlateJS node of the current insertion point
 * @returns {Object} SlateJS editor instance
 */

const defaultOptions = {
  templateVariant: 'VARIANT',
  automationType: 'SECONDARY',
  inTiming: inTimingTypes.MANUAL_IN,
  outTiming: outTimingTypes.AUTO_OUT,
  startTime: 0,
  duration: 5000,
};

const primaryTypeValues = getTypeValues(blockTypes.primaryTypes);

const insertSecondaryAutomationBlock = (
  editor,
  type,
  options = {},
  parentNode
) => {
  const block = {
    type,
    data: {
      ...defaultOptions,
      ...options,
      itemId: uuidv1(),
      typeId: blockTypes.properties[type].id,
      templateType: returnTemplateType(type),
    },
  };

  if (parentNode) {
    const { nodes } = editor.value.document;
    const previousNodes = nodes.takeUntil(({ key }) => key === parentNode.key);

    const previousPrimaries = previousNodes.filter(item =>
      primaryTypeValues.includes(item.type)
    );

    const lastPrimaryNode = previousPrimaries.last();

    if (lastPrimaryNode) editor.moveToEndOfNode(lastPrimaryNode).focus();
  }

  return insertBlock(editor, block);
};

export default insertSecondaryAutomationBlock;
