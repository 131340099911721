import returnStates from 'screens/planning/components/status/utils/statusBoards/returnStates';

export const planningViews = {
  STATUS: 'Status',
  TIMELINE: 'Timeline',
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
};

export const timeVariant = {
  DAY: 'date',
  WEEK: 'week',
  MONTH: 'month',
};

export const getDefaultStateCollapsed = () => {
  const states = returnStates('status-normal');
  const defaultStateColumns = {};
  states.forEach(({ id }) => {
    defaultStateColumns[id] = false;
  });
  return defaultStateColumns;
};

export const DEFAULT_STATE = {
  view: planningViews.STATUS,
  time: new Date().toISOString(),
  tabIndex: 0,
  timeVariant: timeVariant.DAY,
  savedFilters: [],
  stateCollapsed: getDefaultStateCollapsed(),
};
