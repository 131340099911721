import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import InputField from 'components/inputField/InputField';
import ShowItem from '../showItem';
import AddItem from '../addItem';
import useStyles from './add-content-styles';

const AddContentView = ({
  addItemTitle,
  showItemTitle,
  content,
  helperText,
  onUpdate,
  type,
  inputLabel,
}) => {
  const classes = useStyles();
  const [showInputField, setShowInputField] = useState(false);
  const [contentValue, setContentValue] = useState(content || '');

  const onChange = newValue => {
    setContentValue(newValue);
    onUpdate(newValue);
  };

  return (
    <div
      onBlur={event => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setShowInputField(false);
        }
      }}
    >
      {!showInputField && contentValue && (
        <ShowItem
          title={showItemTitle}
          content={contentValue}
          onClick={() => setShowInputField(true)}
        />
      )}

      {!showInputField && !contentValue && (
        <AddItem title={addItemTitle} onClick={() => setShowInputField(true)} />
      )}

      {showInputField && (
        <div>
          <Typography classes={{ root: classes.inputHeadline }}>{inputLabel}</Typography>

          <div className={classes.textAreaDiv}>
            <InputField
              type={type}
              autoFocus
              value={contentValue}
              description={helperText}
              {...{ onChange }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

AddContentView.propTypes = {
  /** Defines the title text for AddItem component  */
  addItemTitle: PropTypes.string,
  /** Defines the title text for ShowItem component  */
  showItemTitle: PropTypes.string,
  /** Defines the content  */
  content: PropTypes.string,
  /** Defines the content for TextArea component */
  helperText: PropTypes.string,
  /** Defines the label when input is shown */
  inputLabel: PropTypes.string,
  /** Defines the type of content
   *  Values: "email" or "tel" or "text"
   */
  type: PropTypes.string,
  /** Callback to be invoked on input change */
  onUpdate: PropTypes.func,
};

AddContentView.defaultProps = {
  addItemTitle: '',
  showItemTitle: '',
  content: '',
  helperText: '',
  inputLabel: '',
  type: 'text',
  onUpdate: () => {},
};

export default AddContentView;
