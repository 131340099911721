import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Vertical } from 'assets/icons/systemicons/RectangleUnionVertical.svg';
// eslint-disable-next-line max-len
import { ReactComponent as Horizontal } from 'assets/icons/systemicons/RectangleUnionHorizontal.svg';
import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import MaterialAvatar from '../material-avatar';
import Container from '../container/Container';
import useStyles from './styles';

const Department = ({ children, width, height, type, src, uploadEnabled, ...rest }) => {
  const classes = useStyles(width, height, src)();

  return (
    <Container {...{ width, height }} {...rest}>
      <MaterialAvatar classes={classes[type]} src={src}>
        <div className={type === 'root' ? classes.div : classes.typography}>
          {src || uploadEnabled ? null : children}
        </div>
      </MaterialAvatar>
      {uploadEnabled && !src ? <Add className={classes.middle} /> : null}
      <Horizontal className={classes.top} />
      <Vertical className={classes.right} />
      <Horizontal className={classes.bottom} />
      <Vertical className={classes.left} />
    </Container>
  );
};

Department.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  type: PropTypes.oneOf(['root', 'topic']),
  uploadEnabled: PropTypes.bool,
};

Department.defaultProps = {
  children: null,
  src: '',
  type: 'root',
  uploadEnabled: false,
};

export default Department;
