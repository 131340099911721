import { makeStyles } from '@material-ui/styles';

const useStyles = image =>
  makeStyles(theme => ({
    root: {
      // eslint-disable-next-line max-len
      backgroundImage: `linear-gradient(to bottom, rgba(50, 62, 77, 0.8), rgba(50, 62, 77, 0.9)), url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: 327,
      minHeight: 112,
      height: 112,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderBottom: '1px solid #464b5b',
    },
    image: {
      width: 46,
      height: 46,
      margin: '2px 12px 12px 12px',
    },
    img: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
    },
    title: {
      ...theme.typography.dina.listItemLabel,
      color: theme.palette.dina.highEmphasis,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingTop: '4px',
    },
    top: {
      paddingTop: '12px',
    },
    icon: {
      margin: '0 12px',
    },
    buttonRoot: {
      ...theme.typography.dina.rightColumnHeaderText,
      textTransform: 'none',
      borderRadius: '4px',
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    bottom: {
      display: 'flex',
    },
  }));

export default useStyles;
