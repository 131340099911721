import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Switch from 'components/switch/Switch';
import { Typography, Divider, FormControlLabel, Button } from '@material-ui/core';
import useStyles from './scratchPad-styles';
import MediaCard from './components/mediaCard';

const ScratchPad = props => {
  const { data, onCancel, keepDialogOpen, setKeepDialogOpen } = props;
  const classes = useStyles();
  const [currentItem, setCurrentItem] = useState(null);

  // const handleInsert = () => {
  //   console.log(currentItem);
  //   // onInsert();
  // };

  // const handleKeepDialogOpen = () => {
  //   setKeepDialogOpen(!keepDialogOpen);
  // };

  return (
    <div className={classes.root}>
      <div className={classes.triangle} />
      <div className={classes.container}>
        <Typography classes={{ root: classes.scratchPad }}>Scratchpad</Typography>
        <Divider className={classes.divider} />
        <div className={classes.cardContainer}>
          {data &&
            data.length > 0 &&
            data.map(item => {
              return (
                <div
                  className={classes.card}
                  key={item.id}
                  onKeyPress={() => {
                    setCurrentItem(item.item);
                  }}
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    setCurrentItem(item.item);
                  }}
                >
                  <MediaCard {...{ ...item }} />
                </div>
              );
            })}
        </div>
        {/* <Divider className={classes.divider} />
        <div className={classes.footer}>
          <FormControlLabel
            control={
              <Switch
                selected={keepDialogOpen}
                onClick={handleKeepDialogOpen}
              />
            }
            classes={{
              root: classes.formControlLabel,
              label: classes.label,
            }}
            label="Keep Dialog open"
          />
          <div className={classes.buttonContainer}>
            <Button className={classes.cancelButton} onClick={onCancel}>
              Cancel
            </Button>
            <Button className={classes.okButton} onClick={handleInsert}>
              Insert
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

ScratchPad.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fileName: PropTypes.string,
      date: PropTypes.string,
      fileSize: PropTypes.string,
      image: PropTypes.string,
      variant: PropTypes.string,
      isNew: PropTypes.bool,
      duration: PropTypes.number,
      onPlay: PropTypes.func,
    }),
  ),
  onCancel: PropTypes.func,
  onInsert: PropTypes.func,
};

ScratchPad.defaultProps = {
  data: [],
  onCancel: () => {},
  onInsert: () => {},
};

export default ScratchPad;
