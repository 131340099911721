import React, { createContext, useState, useEffect } from 'react';

export const StoryTabsContext = createContext([]);
export const CurrentTabContext = createContext();

const FIRST_CONTENT_TAB_INDEX = 4;

const SAVED_STATE = JSON.parse(window.localStorage.getItem('tabs'));
const DEFAULT_STATE = {
  tabs: [],
  current: { page: 'home' },
  index: 1,
};

const ContentStoreProvider = ({ children }) => {
  const state = SAVED_STATE || DEFAULT_STATE;

  const [storyTabs, setStoryTabs] = useState(state.tabs);
  const [currentTab, setCurrentTab] = useState(state.current);
  const [tabIndex, setTabIndex] = useState(state.index);

  useEffect(() => {
    window.localStorage.setItem(
      'tabs',
      JSON.stringify({
        tabs: storyTabs,
        current: currentTab,
        index: tabIndex,
      }),
    );
  }, [storyTabs, currentTab, tabIndex]);

  const setIndexToRelevantStoryTab = (id, tabs) => {
    if (tabs) {
      const index = FIRST_CONTENT_TAB_INDEX + tabs.findIndex(tab => tab.id === id);
      setTabIndex(index);
    } else {
      const index = FIRST_CONTENT_TAB_INDEX + storyTabs.findIndex(tab => tab.id === id);
      setTabIndex(index);
    }
  };

  const closeStoryTab = (id, mid) => {
    let index = -1;
    let updatedTabs = [];
    if (id) {
      index = storyTabs.findIndex(tab => tab.id === id);
      updatedTabs = storyTabs.filter(tab => tab.id !== id);
    }

    if (mid) {
      index = storyTabs.findIndex(tab => tab.mid === mid);
      updatedTabs = storyTabs.filter(tab => tab.mid !== mid);
    }

    setStoryTabs(updatedTabs);

    if (updatedTabs.length < 1) {
      setCurrentTab({ page: 'home' });
      setTabIndex(1);
    } else if (index <= 0) {
      setCurrentTab(updatedTabs[0]);
      setIndexToRelevantStoryTab(updatedTabs[0].id, updatedTabs);
    } else {
      setCurrentTab(updatedTabs[index - 1]);
      setIndexToRelevantStoryTab(updatedTabs[index - 1].id);
    }
  };

  const pinTab = id => {
    const tabs = storyTabs;
    const index = tabs.findIndex(t => t.id === id);
    const pinned = tabs.find(t => t.id === id);
    pinned.pinned = true;
    tabs[index] = pinned;

    setStoryTabs(tabs);
  };

  const updateTabTitle = (id, title) => {
    const index = storyTabs.findIndex(tab => tab.id === id);
    const tabs = storyTabs;
    const tab = tabs[index];

    if (!tab) return;
    tab.title = title;
    tabs[index] = tab;
    setStoryTabs([...tabs]);
  };

  const setIndexToFirstStoryTab = () => {
    setTabIndex(FIRST_CONTENT_TAB_INDEX);
  };

  const currentTabHandler = tab => {
    if (tab.page === 'create') {
      setStoryTabs([tab, ...storyTabs]);
      setIndexToFirstStoryTab();
    } else if (tab.page === 'story' || tab.page === 'rundown' || tab.page === 'pitch') {
      if (storyTabs.length < 1) {
        setStoryTabs([tab]);
        setIndexToFirstStoryTab();
      } else {
        const exists = storyTabs.some(t => t.id === tab.id);
        if (!exists && !storyTabs[0].pinned) {
          setStoryTabs([tab, ...storyTabs.slice(1, 4)]);
          setIndexToFirstStoryTab();
        } else if (!exists && storyTabs[0].pinned) {
          setStoryTabs([tab, ...storyTabs.slice(0, 3)]);
          setIndexToFirstStoryTab();
        } else if (exists) {
          setIndexToRelevantStoryTab(tab.id);
        }
      }
      if (tab.id === currentTab.id) {
        pinTab(tab.id);
      }
    }

    setCurrentTab(tab);
  };

  return (
    <StoryTabsContext.Provider value={[storyTabs, setStoryTabs, closeStoryTab, pinTab]}>
      <CurrentTabContext.Provider
        value={[currentTab, currentTabHandler, tabIndex, setTabIndex, updateTabTitle]}
      >
        {children}
      </CurrentTabContext.Provider>
    </StoryTabsContext.Provider>
  );
};

export { ContentStoreProvider };
