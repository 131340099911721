import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import GET_STORY_CONTACTS from 'graphql/queries/getStoryContacts';
import useShareStory from 'hooks/useShareStory';
import { getMembersOfQuery, getMembersQuery } from 'graphql/queryVariables';
import memberTypes from 'graphql/memberTypes';
import UserCtx from 'contexts/UserContext';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import ResourceView from './resource-view';

const ResourceContainer = ({ storyId, ...rest }) => {
  const { mId: currentUserId } = useContext(UserCtx);
  const { data, error, loading } = useQuery(GET_MEMBERS_OF, {
    variables: getMembersOfQuery(storyId),
    fetchPolicy: 'cache-and-network',
  });

  const [, shareStoryWith] = useShareStory();

  const { data: contactData, error: contactError, loading: contactLoading } = useQuery(
    GET_STORY_CONTACTS,
    {
      variables: getMembersQuery(storyId, memberTypes.STORY_CONTACT),
      fetchPolicy: 'cache-and-network',
    },
  );

  if (loading || contactLoading) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }
  if (error || contactError) {
    return <div>no data</div>;
  }

  const existingMembers = data && data.getMembersOf ? data.getMembersOf : [];
  const users = existingMembers.filter(member => member && member.mType === memberTypes.USER);
  const teams = existingMembers.filter(member => member && member.mType === memberTypes.TEAM);
  const departments = existingMembers.filter(
    member => member && member.mType === memberTypes.DEPARTMENT,
  );
  const contacts = contactData && contactData.getMembers ? contactData.getMembers : [];

  const removeResourceFromStory = (id, type) => {
    let previousMembers = users;
    switch (type) {
      case memberTypes.TEAM_STORY:
        previousMembers = teams;
        break;
      case memberTypes.DEPARTMENT_STORY:
        previousMembers = departments;
        break;
      default:
        previousMembers = users;
        break;
    }

    const members = previousMembers.filter(m => m.mId !== id);
    shareStoryWith(storyId, members, previousMembers, type);
  };

  const removeContactsFromStory = userId => {
    const members = contacts.filter(m => m.mId !== userId);
    shareStoryWith(storyId, members, contacts, memberTypes.STORY_CONTACT);
  };

  const shareStory = async (members, previousMembers, mType) => {
    shareStoryWith(storyId, members, previousMembers, mType);
  };

  return (
    <ResourceView
      userData={users}
      contactData={contacts}
      teamData={teams}
      departmentData={departments}
      storyId={storyId}
      currentUserId={currentUserId}
      shareStory={shareStory}
      removeUserFromStory={id => removeResourceFromStory(id, memberTypes.USER_STORY)}
      removeContactsFromStory={removeContactsFromStory}
      removeTeamFromStory={id => removeResourceFromStory(id, memberTypes.TEAM_STORY)}
      removeDepartmentFromStory={id => removeResourceFromStory(id, memberTypes.DEPARTMENT_STORY)}
      {...rest}
    />
  );
};

export default ResourceContainer;
