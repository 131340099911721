import React from 'react';
import ContainerBox from '../containerBox/ContainerBox';
import useStyles from '../styles';

const Away = () => {
  const classes = useStyles('statusError')();

  return (
    <ContainerBox>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          className={classes.shape}
          x="1"
          y="1"
          width="10"
          height="10"
          rx="1.5"
          strokeWidth="2"
        />
      </svg>
    </ContainerBox>
  );
};

export default Away;
