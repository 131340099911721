import React, { useMemo, useContext, useState } from 'react';
import useTextStorage from 'hooks/useTextStorage';
import InstanceCard from 'components/instanceCard';
import configCtx from 'contexts/configContext';
import { isOlderSlateValue, migrateValue } from 'components/editor';
import respectHostReadSpeed from 'screens/rundown/utils/respectHostReadSpeed';
import { durationTypes, getDuration } from 'screens/rundown/components/editor/utils';
import getHostReadSpeed from 'components/instanceCard/utils/getHostReadSpeed';

const InstancePreviewContainer = props => {
  const {
    mContentKey,
    title,
    image,
    onOpenStory,
    id,
    assignees,
    state,
    publishingAt,
    metaData,
    destination,
    platform,
  } = props;

  const [showMetadata, setShowMetadata] = useState(false);
  const { data, loading } = useTextStorage(mContentKey, false);
  const value = useMemo(() => (data && isOlderSlateValue(data) ? migrateValue(data) : data), [
    data,
  ]);
  const hostReadSpeed = getHostReadSpeed(metaData);
  const metadata = respectHostReadSpeed(metaData, hostReadSpeed);
  const { metadataForms } = useContext(configCtx);
  const form = metadataForms[0];

  return (
    <InstanceCard
      fixedHeight={488}
      disableEdit
      hideToolbar
      loading={loading}
      title={title}
      backgroundImage={image}
      assignees={assignees}
      editorValue={value}
      instance={{ mId: id }}
      onOpenStory={onOpenStory}
      variant={platform}
      statusId={state}
      publishingPoint={platform}
      publishingPointIcon={platform}
      currentDestination={{ title: destination }}
      schedule={publishingAt}
      mMetaData={metadata}
      clipDuration={getDuration(metadata, durationTypes.CLIP_DURATION)}
      scriptDuration={getDuration(metadata, durationTypes.SPEAK_DURATION)}
      totalDuration={getDuration(metadata, durationTypes.TOTAL_DURATION)}
      showMetadata={showMetadata}
      setShowMetadata={setShowMetadata}
      form={form}
    />
  );
};

export default InstancePreviewContainer;
