import { useContext } from 'react';
import configCtx from 'contexts/configContext';

const useSettingsValue = () => {
  const config = useContext(configCtx);

  const settings = config.settings[0];
  const getSettings = (propertyName, defaultValue) => {
    if (settings) {
      const property = settings[propertyName];
      if (property || property === 0) return property;
    }
    return defaultValue;
  };

  return [getSettings];
};

export default useSettingsValue;
