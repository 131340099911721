import React from 'react';
import PropTypes from 'prop-types';
import { publishingPoints } from 'assets/icons/publishingPoints';
import RepeatIcon from 'assets/icons/systemicons/rundown/Repeat_small.svg';
import MaterialAvatar from '../material-avatar';
import Container from '../container/Container';
import useStyles from './rundown-styles';

const Rundown = ({
  children,
  src,
  width,
  height,
  isRecurring,
  defaultSrc,
  ...rest
}) => {
  const classes = useStyles(width, height)();

  return (
    <Container {...{ width, height }} {...rest}>
      <MaterialAvatar
        classes={src ? classes.root : classes.noSrcRoot}
        {...{ src, defaultSrc }}
      >
        {children}
      </MaterialAvatar>
      {isRecurring && (
        <div className={classes.templateDiv}>
          <div className={classes.template}>
            <img src={RepeatIcon} alt="union" className={classes.image} />
          </div>
        </div>
      )}
      <div className={classes.publishingPoint}>
        <img src={publishingPoints.linear} alt="linear" />
      </div>
    </Container>
  );
};

Rundown.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  defaultSrc: PropTypes.string,
  isRecurring: PropTypes.bool,
};

Rundown.defaultProps = {
  children: null,
  src: '',
  isRecurring: false,
  defaultSrc: null,
};

export default Rundown;
