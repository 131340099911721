import React from 'react';
import { Typography } from '@material-ui/core';
import { isSameMonth, eachDay, startOfISOWeek, endOfISOWeek, format } from 'date-fns';
import Scrollbar from 'screens/main/components/scrollbar';
import DayCell from './dayCell';
import useStyles from './month-styles';

const MonthView = props => {
  const { stories, time, ...rest } = props;
  const classes = useStyles();
  const dayNames = eachDay(startOfISOWeek(time), endOfISOWeek(time)).map(day =>
    format(day, 'dddd'),
  );

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {dayNames.map(dayName => (
          <div className={classes.dayCellHeader} key={dayName}>
            <Typography
              classes={{ root: classes.dayCellHeaderText }}
              className={classes.dayCellHeaderTextBlock}
            >
              {dayName}
            </Typography>
          </div>
        ))}
      </div>
      <Scrollbar>
        <div className={classes.body}>
          {Object.entries(stories).map(date => (
            <div className={classes.dayCell} key={date[0]}>
              <DayCell
                date={date[0]}
                stories={date[1]}
                isSameMonth={isSameMonth(time, date[0])}
                {...rest}
              />
            </div>
          ))}
        </div>
      </Scrollbar>
    </div>
  );
};

export default MonthView;
