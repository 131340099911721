import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './rundown-styles';
import RundownDetails from './details';
import RundownList from './list';

function Sidebar(props) {
  const [previewRundown, setPreviewRundown] = useState(null);
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${previewRundown ? classes.details : ''}`}>
      {previewRundown === null ? (
        <RundownList {...{ setPreviewRundown }} />
      ) : (
        <RundownDetails {...{ previewRundown, setPreviewRundown }} />
      )}
    </div>
  );
}

Sidebar.propTypes = {
  /** the rundown that should be previewed if present */
  previewRundown: PropTypes.objectOf(PropTypes.string),
  /** set the preview rundown */
  setPreviewRundown: PropTypes.func,
};

Sidebar.defaultProps = {
  previewRundown: null,
  setPreviewRundown: () => {},
};

export default Sidebar;
