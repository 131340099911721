import gql from 'graphql-tag';

export default gql`
  mutation CreateStoryFromFeed($input: CreateStoryFromFeedInput) {
    createStoryFromFeed(input: $input) {
      mId
      mRefId
      mTitle
      mState
      mDescription
      mContent
      mContentKey
      mAvatarUrl
      mThumbnailUrl
      mCreatedAt
      mUpdatedAt
      mPublishingAt
    }
  }
`;
