import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  tooltip: {
    ...theme.typography.dina.caption,
    fontWeight: '400',
    color: theme.palette.dina.highEmphasis,
    backgroundColor: theme.palette.dina.tooltip,
  },
  arrow: {
    color: theme.palette.dina.tooltip,
  },
}));

export default useStyles;
