import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import DatePicker from 'components/datePicker/DatePicker';
import Scrollbar from 'screens/main/components/scrollbar';
import useStyles from './list-styles';
import RundownGroupMaster from './listGroup-master';
import RundownGroup from './listGroup';

function RundownList(props) {
  const { onDateChanged, selectedDates, setPreviewRundown, hideMasterRundowns } = props;
  const classes = useStyles();

  const memoizedDatePicker = useMemo(() => <DatePicker onDateChange={onDateChanged} />, [
    onDateChanged,
  ]);

  return (
    <>
      <div className={classes.sidebarHeader}>
        <Typography className={classes.sidebarHeaderText}>Rundowns</Typography>
      </div>
      {memoizedDatePicker}
      <Scrollbar>
        <RundownGroup key={1} title="Rundowns" id={1} {...{ selectedDates, setPreviewRundown }} />
        {!hideMasterRundowns && (
          <RundownGroupMaster
            key={2}
            title="Master Rundowns"
            id={2}
            {...{ selectedDates, setPreviewRundown }}
          />
        )}
      </Scrollbar>
    </>
  );
}

RundownList.propTypes = {
  /** change date function */
  onDateChanged: PropTypes.func,
  /** function set the rundown that should be previewed */
  setPreviewRundown: PropTypes.func,
  /** selected dates */
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  /** boolean that indicates hides the master rundows group */
  hideMasterRundowns: PropTypes.bool,
};

RundownList.defaultProps = {
  onDateChanged: () => {},
  setPreviewRundown: () => {},
  selectedDates: [],
  hideMasterRundowns: false,
};

export default RundownList;
