import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@material-ui/core';
import NotificationIndicator from 'components/statusIndicators/notification/Notification';
import useStyles from './navigation-styles';

const NavigationTab = props => {
  const { icon, notificationCount, clearNotificationCount, onClick, ...rest } = props;
  const classes = useStyles();

  if (notificationCount && notificationCount > 0) {
    return (
      <>
        <Tab
          onClick={() => {
            clearNotificationCount();
            onClick();
          }}
          icon={<img src={icon} alt="menu icon" />}
          {...rest}
        />
        <div className={classes.notification}>
          <NotificationIndicator notificationCount={notificationCount} />
        </div>
      </>
    );
  }

  return <Tab onClick={onClick} icon={<img src={icon} alt="menu icon" />} {...rest} />;
};

NavigationTab.propTypes = {
  /** The icon that will be displayed */
  icon: PropTypes.string.isRequired,
  /** notification counter */
  notificationCount: PropTypes.number,
  /** clear notification function */
  clearNotificationCount: PropTypes.func,
};

NavigationTab.defaultProps = {
  notificationCount: 0,
  clearNotificationCount: () => {},
};

export default NavigationTab;
