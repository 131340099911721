import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    right: 0,
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    display: 'flex',
    minHeight: 'calc(100vh - 96px)',
    borderRight: '1px solid rgba(255, 255, 255, 0.12)',
    borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
    width: '48px',
    zIndex: 999,
  },
  menuList: {
    marginTop: 'auto',
  },
  menuItem: {
    height: '48px',
    paddingLeft: '12px',
    paddingRight: '11px',
    '&:focus': {
      '& $icon': {
        color: theme.palette.dina.iconSelectedWhite,
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.dina.onFocus,
      '&:hover': {
        backgroundColor: theme.palette.dina.onFocus,
      },
    },
  },
  selected: {},
  icon: {
    margin: '0',
    color: theme.palette.dina.iconInactive,
  },
}));

export default useStyles;
