import React from 'react';
import PropTypes from 'prop-types';
// import fallbackImage from 'assets/images/defaultStoryCoverPhoto.jpg';
import InstanceTimeline from '../instance/InstanceTimeline';
import CreateStoryInstance from '../instance/CreateStoryInstance';
import useStyles from './header-styles';

const HeaderView = ({ image, id, hover, data, platforms, hideTimeline, blankMetaData }) => {
  const styleProps = { image, hover };
  const classes = useStyles(styleProps);

  return (
    <div className={classes.root}>
      {!hideTimeline && (
        <>
          <InstanceTimeline storyId={id} platforms={platforms} storydata={data} />
          <CreateStoryInstance
            platforms={platforms}
            storydata={data}
            blankMetaData={blankMetaData}
          />
        </>
      )}
    </div>
  );
};

HeaderView.propTypes = {
  /** Background image of the header */
  // image: PropTypes.string,
  /** The Id of the story */
  id: PropTypes.string.isRequired,
  /** Hovered with drop item */
  hover: PropTypes.bool,
  /** data */
  data: PropTypes.objectOf(PropTypes.object),
  /** platforms */
  platforms: PropTypes.arrayOf(PropTypes.object),
};

HeaderView.defaultProps = {
  // image: '',
  hover: false,
  data: {},
  platforms: [{}],
};

export default HeaderView;
