import { makeStyles } from '@material-ui/styles';

const useStyles = (isToday, isWeekend, isThisWeek, isSameMonth) =>
  makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderTop: `1px solid ${theme.palette.dina.dividerLight}`,
      borderLeft: `1px solid ${theme.palette.dina.dividerLight}`,
      background: isWeekend ? theme.palette.dina.blackHoverOverlay : null,
      height: '100%',
      minHeight: '108px',
      width: '100%',
      opacity: isSameMonth ? 1 : 0.5,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '4px 4px 0 4px',
    },
    body: {
      display: 'flex',
      flexGrow: 1,
      margin: '0 4px',
      height: 'calc(100% - 30px)',
    },
    week: {
      height: '16px',
      width: '40px',
      background: isThisWeek ? theme.palette.dina.blackHoverOverlay : null,
      border: isThisWeek ? `1px solid ${theme.palette.dina.onFocus}` : null,
      boxSizing: 'border-box',
      borderRadius: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '2px',
      cursor: 'pointer',
    },
    weekText: {
      ...theme.typography.dina.captionRegular,
      color: isThisWeek
        ? theme.palette.dina.highEmphasis
        : theme.palette.dina.mediumEmphasis,
    },
    date: {
      height: '24px',
      width: '24px',
      background: isToday ? theme.palette.dina.blackHoverOverlay : null,
      border: isToday ? `1px solid ${theme.palette.dina.onFocus}` : null,
      boxSizing: 'border-box',
      borderRadius: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '2px',
      cursor: 'pointer',
    },
    dateText: {
      ...theme.typography.dina.listItemLabel,
      color: isToday
        ? theme.palette.dina.highEmphasis
        : theme.palette.dina.mediumEmphasis,
    },
  }));

export default useStyles;
