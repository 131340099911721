import gql from 'graphql-tag';

export default gql`
  fragment currentInstance on MemberType {
    mId
    mRefId
    mTitle
    mAvatarUrl
    mThumbnailUrl
    mAvatarKey
    mThumbnailKey
    mStoryId
    mCreatedAt
    mUpdatedAt
    mContentKey
    mDefaultContentKey
    locked
    mState
    mProperties {
      __typename
      ... on PlatformType {
        platform
        account {
          accountUrl
          accountLogo
          accountTitle
          accountId
          accountRefId
          orderType
        }
      }
    }
    items {
      itemId
      title
      templateType
      templateVariant
      subItems {
        itemId
        title
        templateType
        templateVariant
      }
    }
    mMetaData {
      key
      value
    }
    mAssignedMembers {
      mId
      mType
    }
}
`;
