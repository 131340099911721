import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  container: {
    maxHeight: 'calc(100% - 14px)',
    overflow: 'hidden',
  },
  moreStories: {
    cursor: 'pointer',
  },
  moreStoriesText: {
    ...theme.typography.dina.storyCardUpdated,
    color: theme.palette.dina.mediumEmphasis,
  },
}));

export default useStyles;
