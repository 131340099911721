import StoryBoxOn from 'assets/icons/systemicons/ContainerRight_Navbar/storybox_on.svg';
import StoryBoxOff from 'assets/icons/systemicons/ContainerRight_Navbar/storybox_off.svg';

import MyContactsOn from 'assets/icons/systemicons/ContainerRight_Navbar/contacts_on.svg';
import MyContactsOff from 'assets/icons/systemicons/ContainerRight_Navbar/contacts_off.svg';
import TeamsOn from 'assets/icons/systemicons/ContainerRight_Navbar/teams_on.svg';
import TeamsOff from 'assets/icons/systemicons/ContainerRight_Navbar/teams_off.svg';
import DepartmentsOn from 'assets/icons/systemicons/ContainerRight_Navbar/departments_on.svg';
import DepartmentsOff from 'assets/icons/systemicons/ContainerRight_Navbar/departments_off.svg';
import GraphicsOn from 'assets/icons/systemicons/ContainerRight_Navbar/graphics_on.svg';
import GraphicsOff from 'assets/icons/systemicons/ContainerRight_Navbar/graphics_off.svg';

import selectionTypes from '../selectionTypes';

export default [
  {
    title: 'Graphics',
    selectionType: selectionTypes.GRAPHICSBOX,
    selectedImage: GraphicsOn,
    unselectedImage: GraphicsOff,
  },
  {
    title: 'Assets',
    selectionType: selectionTypes.STORYBOX,
    selectedImage: StoryBoxOn,
    unselectedImage: StoryBoxOff,
  },
  {
    title: 'Contacts',
    selectionType: selectionTypes.CONTACTS,
    selectedImage: MyContactsOn,
    unselectedImage: MyContactsOff,
  },
  {
    title: 'Teams',
    selectionType: selectionTypes.TEAMS,
    selectedImage: TeamsOn,
    unselectedImage: TeamsOff,
  },
  {
    title: 'Departments',
    selectionType: selectionTypes.DEPARTMENT,
    selectedImage: DepartmentsOn,
    unselectedImage: DepartmentsOff,
  },
];
