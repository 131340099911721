import React from 'react';
import { groupBy } from 'lodash';
import { useQuery } from 'react-apollo';
import { getAssetData } from 'utils/assetData';
import normalizeAssetData from 'utils/normalizeAssetData';
import memberTypes from 'graphql/memberTypes';
import useCreateAsset from 'hooks/useCreateAsset';
import { getMembersQuery } from 'graphql/queryVariables';
import LoadingIndicator from 'components/loadingIndicator';
import GET_ASSETS from 'graphql/queries/getAssets';
import AssetsView from './assets-view';

const AssetsContainer = ({ storyId, ...rest }) => {
  const [createStoryAsset] = useCreateAsset();

  const { data, error, loading } = useQuery(GET_ASSETS, {
    variables: getMembersQuery(storyId, memberTypes.STORY_ASSET),
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <div>Error on Asset Container...</div>;
  if (loading) return <LoadingIndicator />;

  const groupedData = groupBy(data.getAssets, 'mediaType');

  const addItemToStoryAsset = droppedData => {
    const exist = data.getAssets.find(t => {
      return t.assetId === droppedData.id;
    });

    if (!exist) {
      const asset = getAssetData(storyId, normalizeAssetData(droppedData));
      createStoryAsset(storyId, asset, true);
    }
  };

  return <AssetsView assetData={groupedData} {...{ storyId, addItemToStoryAsset }} {...rest} />;
};

export default AssetsContainer;
