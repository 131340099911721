import React from 'react';
import PropTypes from 'prop-types';
import { isToday } from 'date-fns';
import { Tabs } from '@material-ui/core';
import HomeOn from 'assets/icons/systemicons/HeaderNavbar/home_on.svg';
import HomeOff from 'assets/icons/systemicons/HeaderNavbar/home_off.svg';
import FeedsOn from 'assets/icons/systemicons/HeaderNavbar/feeds_on.svg';
import FeedsOff from 'assets/icons/systemicons/HeaderNavbar/feeds_off.svg';
import NavigateOn from 'assets/icons/systemicons/HeaderNavbar/navigate_on.svg';
import NavigateOff from 'assets/icons/systemicons/HeaderNavbar/navigate_off.svg';
import memberTypes from 'graphql/memberTypes';
import CreateTab from './tab/create-container';
import NavigationTab from './tab/navigation-container';
import ContentTab from './tab/content-container';
import useStyles from './tabs-styles';

const HeaderTabs = props => {
  const classes = useStyles();
  const {
    storyTabs,
    tabIndex,
    setTabIndex,
    breakingFeedNotifications,
    clearNotificationCount,
  } = props;

  function handleChange(event, newValue) {
    setTabIndex(newValue);
  }

  const styleHelper = (page, selecteddate, mType) => {
    if (page === 'pitch') return classes.pitch;
    if (page !== 'rundown') return classes.rootStory;
    if (mType === memberTypes.RUNDOWN_TEMPLATE) return classes.rootRundownTemplate;
    return isToday(selecteddate) ? classes.rootRundown : classes.rootRundownNotToday;
  };

  return (
    <Tabs
      value={tabIndex}
      onChange={handleChange}
      className={classes.tabs}
      classes={{
        indicator: classes.tabsIndicator,
      }}
    >
      <CreateTab
        classes={{
          root: classes.tabNavRoot,
          selected: classes.tabSelected,
        }}
      />
      <NavigationTab
        type="home"
        title="Home"
        IconOn={HomeOn}
        IconOff={HomeOff}
        classes={{
          root: classes.tabNavRoot,
          selected: classes.tabSelected,
        }}
      />
      <NavigationTab
        type="feeds"
        title="Feeds"
        IconOn={FeedsOn}
        IconOff={FeedsOff}
        breakingFeedNotifications={breakingFeedNotifications}
        clearNotificationCount={clearNotificationCount}
        classes={{
          root: classes.tabNavRoot,
          selected: classes.tabSelected,
        }}
      />
      <NavigationTab
        type="plans"
        title="StoryHub"
        IconOn={NavigateOn}
        IconOff={NavigateOff}
        className={classes.lastTab}
        classes={{
          root: classes.tabNavRoot,
          selected: classes.tabSelected,
        }}
      />
      {storyTabs.map(tab => {
        const { pinned = false, title, id, image, page, selecteddate, mid, mType } = tab;
        return (
          <ContentTab
            data-testid="story-tab"
            selecteddate={selecteddate}
            classes={{
              root: styleHelper(page, selecteddate, mType),
              selected: classes.tabSelected,
            }}
            pinned={pinned}
            label={title}
            key={id}
            id={id}
            mid={mid}
            title={title}
            image={image}
            type={page}
            mType={mType}
          />
        );
      })}
    </Tabs>
  );
};

HeaderTabs.propTypes = {
  storyTabs: PropTypes.arrayOf(PropTypes.object),
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
  /** number of breaking feed notifications */
  breakingFeedNotifications: PropTypes.number,
  /** clear the notifications */
  clearNotificationCount: PropTypes.func,
};

HeaderTabs.defaultProps = {
  storyTabs: [],
  breakingFeedNotifications: 0,
  clearNotificationCount: () => {},
};

export default HeaderTabs;
