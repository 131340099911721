import gql from 'graphql-tag';

export default gql`
  mutation lockMember($input: LockMemberInput) {
    lockMember(input: $input) {
      mId
      mRefId
      mContentUrl
      locked
    }
  }
`;
