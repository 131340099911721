import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContactsOffSrc from 'assets/icons/systemicons/contacts_off.svg';
import TeamsOffSrc from 'assets/icons/systemicons/teams_off.svg';
import DepartmentsOffSrc from 'assets/icons/systemicons/departments_off.svg';
import Dialog from 'components/dialog';
import AddMemberDialog from 'components/addMemberDialog';
import memberTypes from 'graphql/memberTypes';
import useStyles from './share-story-styles';
import SpeedDial from './speedDial';

const actions = [
  {
    icon: <img src={ContactsOffSrc} alt="People" />,
    name: 'Share with People...',
    type: 'SHARE_WITH_PEOPLE',
  },
  {
    icon: <img src={TeamsOffSrc} alt="Teams" />,
    name: 'Share with Teams...',
    type: 'SHARE_WITH_TEAMS',
  },
  {
    icon: <img src={DepartmentsOffSrc} alt="Departments" />,
    name: 'Share with Departments...',
    type: 'SHARE_WITH_DEPARTMENTS',
  },
];

const ShareStory = props => {
  const {
    people,
    teams,
    departments,
    markets,
    containerRef,
    shareStoryWith,
    onOpen,
    ...rest
  } = props;
  const classes = useStyles();
  const [openPeopleDialog, setOpenPeopleDialog] = useState(false);
  const [openTeamsDialog, setOpenTeamsDialog] = useState(false);
  const [openDepartmentsDialog, setOpenDepartmentsDialog] = useState(false);
  const [openMarketDialog, setOpenMarketDialog] = useState(false);

  const handleItemSelection = type => {
    if (type === 'SHARE_WITH_PEOPLE') setOpenPeopleDialog(true);
    if (type === 'SHARE_WITH_TEAMS') setOpenTeamsDialog(true);
    if (type === 'SHARE_WITH_DEPARTMENTS') setOpenDepartmentsDialog(true);
    if (type === 'SHARE_WITH_MARKETPLACE') setOpenMarketDialog(true);
  };

  return (
    <div className={classes.root}>
      <SpeedDial {...{ actions }} onClose={handleItemSelection} onOpen={onOpen} {...rest} />

      <Dialog
        open={openPeopleDialog}
        container={containerRef.current}
        onClose={() => setOpenPeopleDialog(false)}
      >
        <AddMemberDialog
          members={people}
          variant="People"
          showItems={false}
          dialogTitle="People"
          onCancel={() => setOpenPeopleDialog(false)}
          onOk={members => {
            shareStoryWith(members, people, memberTypes.USER_STORY);
            setOpenPeopleDialog(false);
          }}
        />
      </Dialog>

      <Dialog
        open={openTeamsDialog}
        container={containerRef.current}
        onClose={() => setOpenTeamsDialog(false)}
      >
        <AddMemberDialog
          members={teams}
          variant="Team"
          dialogTitle="Teams"
          onCancel={() => setOpenTeamsDialog(false)}
          onOk={members => {
            shareStoryWith(members, teams, memberTypes.TEAM_STORY);
            setOpenTeamsDialog(false);
          }}
        />
      </Dialog>

      <Dialog
        open={openDepartmentsDialog}
        container={containerRef.current}
        onClose={() => setOpenDepartmentsDialog(false)}
      >
        <AddMemberDialog
          members={departments}
          variant="Department"
          dialogTitle="Departments"
          onCancel={() => setOpenDepartmentsDialog(false)}
          onOk={members => {
            shareStoryWith(members, departments, memberTypes.DEPARTMENT_STORY);
            setOpenDepartmentsDialog(false);
          }}
        />
      </Dialog>

      <Dialog
        open={openMarketDialog}
        container={containerRef.current}
        onClose={() => setOpenMarketDialog(false)}
      >
        <AddMemberDialog
          variant="Market"
          dialogTitle="Marketplace"
          members={markets}
          onCancel={() => setOpenMarketDialog(false)}
          onOk={members => {
            shareStoryWith(members, markets, memberTypes.MARKET_STORY);
            setOpenMarketDialog(false);
          }}
        />
      </Dialog>
    </div>
  );
};

ShareStory.propTypes = {
  people: PropTypes.arrayOf(PropTypes.any),
  teams: PropTypes.arrayOf(PropTypes.any),
  departments: PropTypes.arrayOf(PropTypes.any),
  markets: PropTypes.arrayOf(PropTypes.any),
  shareStoryWith: PropTypes.func,
  onOpen: PropTypes.func,
};

ShareStory.defaultProps = {
  people: [],
  teams: [],
  departments: [],
  markets: [],
  shareStoryWith: () => {},
  onOpen: () => {},
};

export default ShareStory;
