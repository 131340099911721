import React, { useRef, useContext } from 'react';
import { useMutation } from 'react-apollo';
import UPDATE_STORY from 'graphql/mutations/updateMember';
import UserContext from 'contexts/UserContext';
import useShareStory from 'hooks/useShareStory';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import useOpenStory from 'hooks/useOpenStory';
import StoryCardView from './storyCard-view';

const StoryCard = props => {
  const { storyData, onlyShowUsersItems, ...rest } = props;
  const { mId, mTitle, mUpdatedAt, mThumbnailUrl, mPriority, mAssignedMembers, mType } = storyData;

  const containerRef = useRef(null);
  const [, shareStoryWith] = useShareStory();
  const openStory = useOpenStory();
  const user = useContext(UserContext);

  const { mId: userId } = user;

  const [updateStory] = useMutation(UPDATE_STORY);
  const [
    assignedUsers,
    assignedTeams,
    assignedDepartments,
    assignedMarkets,
  ] = useGetAssignedMembers(mAssignedMembers || []);

  const handlePriorityChange = async priorityValue => {
    const input = { mId };
    if (priorityValue) {
      input.mPriority = priorityValue;
    } else if (mPriority) {
      input.mPriority = null;
    }
    try {
      await updateStory({ variables: { input } });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const isAssignedToUser = Boolean(assignedUsers.find(usr => usr.mId === userId));

  if (onlyShowUsersItems && !isAssignedToUser) return null;

  const shareStory = (members, currentMembers, type) => {
    shareStoryWith(mId, members, currentMembers, type);
  };

  const categories = storyData.mCategories || [];

  return (
    <StoryCardView
      id={mId}
      assignees={assignedUsers}
      updatedAt={mUpdatedAt}
      title={mTitle}
      image={mThumbnailUrl}
      story={storyData}
      markets={assignedMarkets}
      shareStoryWith={shareStory}
      categories={categories}
      priorityLevel={mPriority}
      onPriorityChange={handlePriorityChange}
      teams={assignedTeams}
      departments={assignedDepartments}
      {...{ mId, containerRef, openStory, ...rest }}
      isPitch={mType === 'pitch'}
    />
  );
};

export default StoryCard;
