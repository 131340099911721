import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Typography, Fade } from '@material-ui/core';
import Group from 'screens/storybox/components/group';
import LockedIndicator from 'components/instanceCard/components/footer/components/lockedIndicator';
import useInterval from 'hooks/useInterval';
import Editor from './editor-container';
import useStyles from './content-styles';

const height = '100%';
const background = 'transparent';

const Content = props => {
  const {
    content,
    description,
    handleDescriptionChange,
    hostReadSpeed,
    isSavingContent,
    lockedByUser,
    mId,
    onChange,
    onFocus,
    readLock,
    releaseWriteLock,
    shouldResetEditorSelection,
    writeLock,
  } = props;

  const classes = useStyles(writeLock, readLock)();
  const editorRef = useRef();
  const timeOutRef = useRef(false);

  const [descriptionValue, setDescriptionValue] = useState(description);

  useInterval(
    () => {
      if (timeOutRef.current) {
        timeOutRef.current = false;
      } else {
        releaseWriteLock();
      }
    },
    writeLock ? 60000 : null,
  );

  useEffect(() => {
    (readLock || writeLock) &&
      editorRef.current &&
      editorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
  }, [readLock, writeLock]);

  useEffect(() => {
    const resetTimeOut = () => {
      timeOutRef.current = true;
    };
    editorRef.current.addEventListener('keydown', resetTimeOut, false);
    editorRef.current.addEventListener('mousedown', resetTimeOut, false);
    return () => {
      editorRef.current.removeEventListener('keydown', resetTimeOut, false);
      editorRef.current.removeEventListener('mousedown', resetTimeOut, false);
    };
  }, [writeLock]);

  useEffect(() => {
    setDescriptionValue(description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  const onDescriptionChange = event => {
    const val = event.target.value;
    setDescriptionValue(val);
    handleDescriptionChange(val);
  };

  return (
    <Group label="Editor" variant="underlined">
      <div className={classes.contentContainer}>
        <Typography variant="overline" className={classes.storyHeaderText}>
          Description
        </Typography>
        <Input
          fullWidth
          multiline
          disableUnderline
          placeholder="Write a short description..."
          rowsMax={2}
          className={classes.description}
          onChange={onDescriptionChange}
          value={descriptionValue || ''}
        />
        <div className={classes.editor} onClick={onFocus} role="presentation" ref={editorRef}>
          <Editor
            isStoryEditor
            readOnly={!writeLock}
            {...{
              background,
              content,
              height,
              hostReadSpeed,
              mId,
              onChange,
              shouldResetEditorSelection,
            }}
          />
          <div className={classes.footer}>
            <Fade in={writeLock || readLock} timeout={{ enter: 250, exit: 250 }}>
              <LockedIndicator
                {...{
                  readLock,
                  writeLock,
                  lockedByUser,
                  isSavingContent,
                }}
                onDone={releaseWriteLock}
              />
            </Fade>
          </div>
        </div>
      </div>
    </Group>
  );
};

Content.propTypes = {
  description: PropTypes.string,
  content: PropTypes.shape({}),
  handleDescriptionChange: PropTypes.func,
  handleContentChange: PropTypes.func,
  mId: PropTypes.string,
  mContentKey: PropTypes.string,
  lockedByUser: PropTypes.string,
  onFocus: PropTypes.func,
  writeLock: PropTypes.bool,
  readLock: PropTypes.bool,
  releaseWriteLock: PropTypes.func,
};

Content.defaultProps = {
  description: '',
  content: null,
  handleDescriptionChange: () => {},
  handleContentChange: () => {},
  mId: null,
  mContentKey: null,
  lockedByUser: 'someone',
  onFocus: () => {},
  writeLock: false,
  readLock: false,
  releaseWriteLock: () => {},
};

export default Content;
