import GET_INSTANCE_FROM_CACHE from 'graphql/queries/getInstanceFromCache';


const emptyData = {
  mTitle: "loading...",
  mAvatarUrl: null,
  mThumbnailUrl: null,
  mAvatarKey: null,
  mThumbnailKey: null,
  mStoryId: null,
  mCreatedAt: null, 
  mUpdatedAt: null,
  mContentKey: null,
  mDefaultContentKey: null,
  locked: null,
  mState:'todo',
  mProperties: {
    __typename: 'PlatformType',
    platform: 'linear',
    account:  {
        accountUrl: null,
        accountLogo: null,
        accountTitle: null,
        accountId: null,
        accountRefId: null,
        orderType: null,
        __typename: 'AccountType',
      },
   
  },
  items: [],
  mMetaData: [],
  mAssignedMembers: [],
};

const resolveInstanceCache = (parent, args, { cache, getCacheKey }) => {
  const id = getCacheKey({ ...args.input, __typename: 'MemberType' });
  const tempData = { ...args.input, ...emptyData,  __typename: 'MemberType' };
  try {
   const data = cache.readFragment({
      id,
      fragment: GET_INSTANCE_FROM_CACHE,
    });
    
    if(data) return data;
  } catch(e) {
     // eslint-disable-next-line no-console
     console.error(e);
  }

  // to make sure we can load the missing rundown instance
  // added by other user.
  cache.writeFragment({
    id,
    fragment: GET_INSTANCE_FROM_CACHE,
    data: tempData,
  });

  return tempData;
  
};

export default resolveInstanceCache;
