import { makeStyles } from '@material-ui/styles';
import styleEnum from '../../../utils/styleEnum';

const useStyles = makeStyles(theme => ({
  inputDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    ...theme.typography.dina.listItemLabel,
    color: ({ style }) =>
      style === styleEnum.HIGHLIGHT
        ? theme.palette.dina.highEmphasis
        : theme.palette.dina.mediumEmphasis,
    height: 28,
    paddingLeft: '4px',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
      boxShadow: `inset 0 0 0 1px ${theme.palette.dina.inputBorderResting}`,
    },
    '&:focus': {
      outline: 'blue',
      backgroundColor: theme.palette.dina.blackHoverOverlay,
      boxShadow: `inset 0 0 0 1px ${theme.palette.dina.whiteMediumEmphasis}`,
    },
  },
  verticalDivider: {
    height: 31,
    width: '4px',
    backgroundColor: 'transparent',
  },
}));

export default useStyles;
