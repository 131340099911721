import React from 'react';
import PropTypes from 'prop-types';
import { publishingPoints } from 'assets/icons/publishingPoints';
import SplitPane from './splitPane';
import useStyles from './story-timeline-styles';
import Timeline from './timeline';
import UnscheduledInstances from './unscheduledInstances';

const StoryTimeline = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SplitPane
        TimelineComponent={Timeline}
        UnscheduledInstancesComponent={UnscheduledInstances}
        {...props}
      />
    </div>
  );
};

StoryTimeline.propTypes = {
  /** List of all story instances, scheduled and unscheduled */
  instances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      publishingPoint: PropTypes.oneOf(Object.keys(publishingPoints)),
      publishingPlatform: PropTypes.shape({
        mTitle: PropTypes.string,
        mProperties: PropTypes.shape({
          platform: PropTypes.string,
          platformIcon: PropTypes.string,
        }),
      }),
    }),
  ),
  /** Callback to be invoked when an instance is scheduled/re-schduled,
   *  with the newly scheduled instance passed in */
  onSchedule: PropTypes.func,
};

StoryTimeline.defaultProps = {
  instances: [],
  onSchedule: () => {},
};

export default StoryTimeline;
