import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Group from 'components/listGroup';
import Divider from 'components/divider';
import useDidMount from 'hooks/useDidMount';
import Scrollbar from 'screens/main/components/scrollbar';
import image from 'assets/images/default/defaultThumbnail.png';
import { useQuery } from 'react-apollo';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { getRundownsQuery } from 'graphql/queryVariables';
import { CurrentTabContext } from 'globalState';
import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'graphql/queries/getRundownByPublishingDate';
import GET_RUNDOWNS from 'graphql/queries/getRundowns';
import Popover from 'components/popover/Popover';
import useCreateRundown from 'hooks/useCreateRundown';
import { format, startOfDay, endOfDay } from 'date-fns';
import { formatDuration } from 'utils/formatDuration';
import CreateRundown from 'components/createNew';
import memberTypes from 'graphql/memberTypes';
import { Typography } from '@material-ui/core';
import { formatDateToLocaleString } from 'utils/formatDate';
import Rundown from '../listItem';
import NewRundown from '../listItem-new-rundown';
import useStyles from './group-styles';

const GroupContainer = props => {
  const classes = useStyles();
  const { title, selectedDates, setPreviewRundown } = props;
  const [, setCurrentTab] = useContext(CurrentTabContext);
  const didMount = useDidMount();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [input, setInput] = useState(null);
  const [createRundown] = useCreateRundown();

  const { 0: sDate, length: l, [l - 1]: eDate } = selectedDates;
  const startDate = startOfDay(sDate).toISOString();
  const endDate = endOfDay(eDate).toISOString();

  const query = GET_RUNDOWNS_BY_PUBLISHING_DATE;
  const queryMasters = GET_RUNDOWNS;

  let variables = {
    input: {
      mType: 'rundown',
      startDate,
      endDate,
    },
  };

  const { data, error, loading } = useQuery(query, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  variables = getRundownsQuery('');
  const { data: masterRundowns, error: error1, loading: loading1 } = useQuery(queryMasters, {
    variables,
    fetchPolicy: 'cache-and-network',
  });
  const onInitializeRundown = (selectedRundown, anchor) => {
    setInput(selectedRundown);
    setAnchorEl(null);
    setAnchorE2(anchor);
  };

  const onCreate = async newName => {
    if (!input) return;
    const newInput = input;
    newInput.mTitle = newName;
    const newRundown = await createRundown(newInput);
    setAnchorE2(null);
    setInput(null);

    if (!newRundown) return;
    const { mId: id, mTitle, mType, mPublishingAt } = newRundown;
    setCurrentTab({
      page: memberTypes.RUNDOWN,
      title: mTitle,
      id,
      mid: id,
      selecteddate: mPublishingAt,
      mType,
    });
  };

  useEffect(() => {
    if (didMount) {
      const elm = document.getElementById('scrollbar');
      if (elm) {
        setTimeout(() => {
          elm.scrollTop = 2;
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading || loading1) return <LoadingIndicator />;
  if (error || error1) return <div>{error ? error.message : error1.message}</div>;

  const { getRundownsByPublishingDate } = data;

  const sortedRundowns =
    getRundownsByPublishingDate.sort((a, b) => {
      if (a.mPublishingAt > b.mPublishingAt) return 1;
      if (a.mPublishingAt < b.mPublishingAt) return -1;

      if (a.mTitle.toLowerCase() > b.mTitle.toLowerCase()) return 1;
      if (a.mTitle.toLowerCase() < b.mTitle.toLowerCase()) return -1;
      return [];
    }) || [];

  const getPublishingAt = rundown => {
    if (!rundown.mPublishingAt) return '00:00:00';
    return format(rundown.mPublishingAt, 'HH:mm:ss');
  };

  const getPlannedDuration = rundown => {
    const ret = rundown.mPlannedDuration ? formatDuration(rundown.mPlannedDuration) : 0;
    return ret;
  };

  const getUnInitializedRundowns = (masters, iRundows) => {
    let ret = [];
    if (!masters) return ret;
    ret = [].concat(
      masters.filter(obj1 =>
        iRundows.every(obj2 => {
          return obj1.mId !== obj2.mRundownTemplateId;
        }) || !obj1.recurrence.dailyExclusive,
      ),
      iRundows.filter(obj2 =>
        masters.every(obj1 => {
          return obj2.mRundownTemplateId !== obj1.mId;
        }),
      ),
    );
    
    return ret;
  };

  const getGroupTitle = groupTitel => {
    let ftDate = formatDateToLocaleString(sDate.toISOString(), true);
    if (sDate !== eDate) ftDate += `  -  ${formatDateToLocaleString(eDate.toISOString(), true)}`;
    return `${groupTitel}   ${ftDate}`;
  };

  return (
    <>
      <Group
        title={getGroupTitle(title)}
        type="left"
        handleCreateClicked={e => setAnchorEl(e.currentTarget.parentNode)}
        toolTipTitle="create rundown from"
        id="rundown"
      >
        {data ? (
          <List disablePadding>
            {sortedRundowns.map(
              rundown =>
                rundown && (
                  <Rundown
                    key={`${rundown.mId}${rundown.mRefId}`}
                    title={rundown.mTitle}
                    image={rundown.mThumbnailUrl || image}
                    startTime={getPublishingAt(rundown)}
                    timeZone={rundown.recurrence && rundown.recurrence.timeZone}
                    plan={getPlannedDuration(rundown)}
                    difference={rundown.difference}
                    id={rundown.mId}
                    rundowntype={rundown.mType}
                    state={rundown.mState}
                    selectedDates={selectedDates}
                    startDate={rundown.mPublishingAt}
                    {...{ setPreviewRundown }}
                  />
                ),
            )}
          </List>
        ) : (
          <div>NO DATA</div>
        )}
      </Group>

      <Popover
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        position="right"
      >
        <div className={classes.listContainer}>
          <Typography className={classes.heading}>Select master rundown</Typography>
          <Divider />
          <Divider />
          <List disablePadding className={classes.list}>
            <Scrollbar>
              <div>
                {getUnInitializedRundowns(
                  masterRundowns ? masterRundowns.getRundowns : undefined,
                  sortedRundowns,
                ).map(
                  rundown =>
                    rundown && (
                      <NewRundown
                        key={rundown.mId}
                        title={rundown.mTitle}
                        image={rundown.mThumbnailUrl || image}
                        startTime={
                          (rundown.recurrence && rundown.recurrence.startTime) || '00:00:00'
                        }
                        timeZone={rundown.recurrence && rundown.recurrence.timeZone}
                        plan={rundown.recurrence.duration}
                        difference={rundown.difference}
                        id={rundown.mId}
                        selectedDates={selectedDates}
                        initializeRundown={e => onInitializeRundown(e, anchorEl)}
                        {...{ setPreviewRundown }}
                      />
                    ),
                )}
              </div>
            </Scrollbar>
          </List>
        </div>
      </Popover>

      <Popover onClose={() => setAnchorE2(null)} anchorEl={anchorE2} position="right">
        <CreateRundown
          variant={memberTypes.RUNDOWN}
          onCancel={() => setAnchorE2(null)}
          onCreate={onCreate}
          defaultTitle={input ? input.mTitle : 'rundown title'}
        />
      </Popover>
    </>
  );
};

GroupContainer.propTypes = {
  /** Title of the list group */
  title: PropTypes.string,
  /** selected dates */
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  /** set the preview rundown */
  setPreviewRundown: PropTypes.func,
};

GroupContainer.defaultProps = {
  title: 'Group',
  selectedDates: [],
  setPreviewRundown: () => {},
};

export default GroupContainer;
