import { elementTypes } from 'components/editor/constants';
import { Editor, Transforms } from 'slate';

const { nodes } = Editor;

/**
 * Handles onKeyDown event on link element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} event React synthetic event
 * @returns {Object} SlateJS editor instance
 */

const onLinkKeyDown = (editor, event) => {
  const [match] = nodes(editor, {
    match: ({ type }) => type === elementTypes.LINK,
  });

  if (match && event.key === 'Backspace') {
    const [element, path] = match;
    const { children } = element;
    const [{ text }] = children;

    if (text.length === 1) Transforms.removeNodes(editor, { at: path });
  }

  return editor;
};

export default onLinkKeyDown;
