import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import findLastValidNode from './findLastValidNode';

const { insertNodes } = Transforms;

/**
 * Inserts block element
 *
 * @param {Object} options
 * @param {Object} options.editor SlateJS editor instance
 * @param {Function} options.update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const insertWithNoSelection = ({ editor, type, data = {}, update }) => {
  const lastNode = findLastValidNode(editor.children);

  if (lastNode) {
    const [pathIndex] = ReactEditor.findPath(editor, lastNode);

    const blockElement = { type, data, children: [{ text: '' }] };
    insertNodes(editor, blockElement, { at: [pathIndex + 1] });

    update({
      type: `${type}-insert`,
      payload: {
        document: editor.children,
        insertedElement: blockElement,
      },
    });
  }
  return editor;
};

export default insertWithNoSelection;
