import {
  findStoryFromUserStories,
  getStories,
} from 'graphql/utils/cache/share-story/userStories';
import GET_STORIES from 'graphql/queries/getStoriesPagination';
import { getStoriesQuery } from 'graphql/queryVariables';

const updateStoryListCache = (proxy, item, type) => {
  /** Find if the story belongs to user or to the teams/departments
   * the user is a member of. */
  const storyId = item.story.mId;
  const userId = item.loggedInUserId;
  const story = findStoryFromUserStories(proxy, userId, storyId);

  /** The story is not related to the user. Skip cache update. */
  if (!story) {
    return;
  }

  const stories = getStories(proxy, item.memberId, type);
  const { items, nextToken } = stories.getMembersPagination;
  let storyItems = [...items];
  const list = stories.getMembersPagination.items.filter(
    s => s && s.mId === story.mId,
  );
  if (item.crudAction === 'INSERT') {
    if (list.length === 0) {
      storyItems.unshift(story);
    }
  }
  if (item.crudAction === 'REMOVE') {
     storyItems = storyItems.filter(
      s => s && s.mId !== story.mId,
    );
  }

  proxy.writeQuery({
    query: GET_STORIES,
    variables: getStoriesQuery(item.memberId, type),
    data: {
      getMembersPagination: {
        items: storyItems,
        __typename: 'PaginatedMemberType',
        nextToken,
      },
    },
  });
};

export default updateStoryListCache;
