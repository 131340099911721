import React, { memo } from 'react';
import PropTypes from 'prop-types';
import InputField from 'components/inputField';
import Box from '../box';
import useStyles from './styles';

const Input = memo(InputField);

const InputBlock = ({
  readOnly,
  value,
  onChange,
  icon,
  label,
  description,
  placeholder,
  hideEllipsisButton,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box
      iconComponent={icon}
      title={label}
      hideEllipsisButton={hideEllipsisButton}
      readOnly={readOnly}
    >
      <div className={classes.inputWrapper}>
        <Input
          description={description}
          disableLabel
          onChange={onChange}
          value={value}
          usage="editor"
          disabled={readOnly}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    </Box>
  );
};

InputBlock.propTypes = {
  /**
   * whether the field is readonly or not
   */
  readOnly: PropTypes.bool,
  /**
   * value of the input field
   */
  value: PropTypes.string,
  /**
   * setValue callback for the input field
   */
  onChange: PropTypes.func,
  /**
   * icon to show on the box
   */
  icon: PropTypes.element,
  /**
   * label of the input field
   */
  label: PropTypes.string,
  /**
   * assitive text to show below the field
   */
  description: PropTypes.string,
  /**
   * placeholder value
   */
  placeholder: PropTypes.string,
};

InputBlock.defaultProps = {
  readOnly: false,
  value: '',
  onChange: () => {},
  icon: '',
  label: '',
  description: '',
  placeholder: '',
};

export default memo(InputBlock);
