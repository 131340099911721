import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import MenuCreator from 'screens/main/components/rightArea/menuCreator/MenuCreator';
import DeleteIcon from '@material-ui/icons/Delete';
import SurfaceOverlay from 'assets/images/SurfaceOverlay.png';
import { ReactComponent as Upload } from 'assets/icons/systemicons/upload.svg';
import useImageUpload from 'hooks/useImageUpload';
import useArchiveMember from 'hooks/useArchiveMember';
import memberTypes from 'graphql/memberTypes';
import Dialog from 'components/dialog';
import ConfirmDialog from 'components/confirmDialog';
import TopMenuRow from './topMenuRow/TopMenuRow';
import AvatarRow from './avatar/AvatarRow';

const topRowOptions = [{ label: 'Archive Team', action: 'archiveTeam', Icon: DeleteIcon }];

const avatarOptions = [{ label: 'Upload from Computer...', action: 'upload', Icon: Upload }];

const useStyles = makeStyles(theme => ({
  headerDiv: {
    position: 'relative',
    overflow: 'hidden',
    background: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    paddingBottom: '12px',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectPosition: '0% 25%',
  },
  avatar: {
    opacity: 0.5,
    filter: 'blur(15px)',
  },
}));
const Header = ({
  title,
  type,
  noOfMembers,
  onBackButtonClick,
  avatarUrl,
  updateAvatar,
  teamId,
  updateTeamTitle,
}) => {
  const [showTopMenu, setShowTopMenu] = useState(null);
  const [showAvatarMenu, setShowAvatarMenu] = useState(null);
  const [uploadedAvatar, setUploadedAvatar] = useState(null);
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);

  const classes = useStyles();

  const onImageLoad = imageFile => {
    const imageSrc = URL.createObjectURL(imageFile);

    setUploadedAvatar(imageSrc);
    updateAvatar(imageFile);
  };

  const captureImage = useImageUpload({ onImageLoad });
  const [archiveMember] = useArchiveMember();

  const avatarImage = uploadedAvatar || avatarUrl;

  const archiveTeam = id => {
    archiveMember(id, memberTypes.TEAM).then(() => {
      setArchiveDialogOpen(false);
      onBackButtonClick();
    });
  };

  return (
    <div className={classes.headerDiv}>
      {avatarImage ? (
        <img
          alt="header-cover"
          src={avatarImage}
          className={`${classes.image} ${classes.avatar}`}
        />
      ) : (
        <img alt="header-cover" src={SurfaceOverlay} className={classes.image} />
      )}

      <TopMenuRow
        onBackButtonClick={onBackButtonClick}
        onMenuButtonClick={event => {
          setShowTopMenu(event.currentTarget);
        }}
      />
      <MenuCreator
        onClose={({ action }) => {
          if (action) {
            if (action === 'archiveTeam') setArchiveDialogOpen(true);
          }
          setShowTopMenu(null);
        }}
        anchorEl={showTopMenu}
        menuOptions={topRowOptions}
        position="right-top"
      />

      <AvatarRow
        teamId={teamId}
        title={title}
        avatarUrl={avatarImage}
        type={type}
        noOfMembers={noOfMembers}
        onAvatarClick={event => setShowAvatarMenu(event.currentTarget)}
        updateTeamTitle={updateTeamTitle}
      />
      <MenuCreator
        onClose={({ action }) => {
          if (action) {
            if (action === 'upload') captureImage();
          }
          setShowAvatarMenu(null);
        }}
        anchorEl={showAvatarMenu}
        menuOptions={avatarOptions}
        position="left-center"
      />
      <Dialog open={isArchiveDialogOpen} onClose={() => setArchiveDialogOpen(false)}>
        <ConfirmDialog
          onCancel={() => setArchiveDialogOpen(false)}
          onConfirm={() => {
            archiveTeam(teamId);
          }}
          title="Archive team?"
          confirmButtonLabel="Archive"
          confirmButtonType="danger"
          info={`Team: "${title}" will be archived.`}
        />
      </Dialog>
    </div>
  );
};

export default Header;
