import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    minWidth: 328,
    '&:hover': {
      '& $menu': { opacity: 1 },
      '& $icon': { opacity: 0 },
    },
    pointerEvents: ({ readOnly }) => (readOnly ? 'none' : 'all'),
    userSelect: 'none',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: ({ isGraphic }) => (isGraphic ? 'flex-start' : 'center'),
    width: '100%',
    height: '100%',
    paddingTop: 4,
    paddingBottom: 4,
  },
  selects: {
    borderRight: `1px solid ${palette.dina.dividerLight}`,
    minWidth: 92,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  titleBox: {
    width: 'calc(100% - 92px - 24px)',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  iconMenuWrapper: {
    width: 24,
    height: 24,
    paddingRight: 4,
    position: 'relative',
  },
  iconMenuWrapperGraphic: {
    width: 24,
    height: 24,
    paddingRight: 4,
    position: 'relative',
    alignSelf: 'flex-start',
  },
  icon: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 1,
    transition: 'opacity 0.3s ease',
  },
  menu: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    opacity: 0,
    transition: 'opacity 0.3s ease-in',
  },
}));

export default useStyles;
