import React, { useState, useContext, createContext } from 'react';

const SelectionContext = createContext();

const InstanceContextProvider = ({ children }) => {
  const [selectedIds, setSelectedIds] = useState([]);

  return (
    <SelectionContext.Provider value={[selectedIds, setSelectedIds]}>
      {children}
    </SelectionContext.Provider>
  );
};

export const useSelectionContext = () => useContext(SelectionContext);

export default InstanceContextProvider;
