import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '96px',
    width: '100%',
    marginBottom: '8px',
  },
  mediaContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  placeholder: {
    width: ({ mediaWidth }) => mediaWidth,
    height: '88px',
    marginRight: '8px',
    border: `1px dashed ${theme.palette.dina.statusApproved}`,
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeholderIcon: {
    '& path': {
      fill: theme.palette.dina.statusApproved,
      fillOpacity: 1,
    },
  },
  placeholderButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '88px',
  },
  button: {
    padding: 0,
    height: '40px',
  },
  buttonChild: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  buttonText: {
    color: theme.palette.dina.mediumEmphasis,
  },
  buttonIcon: {
    marginRight: '4px',
  },
  addButton: {
    height: '88px',
    width: ({ mediaWidth }) => mediaWidth,
    borderRadius: '2px',
    outline: 'none',
    marginRight: '8px',
    '&:hover': {
      '& $addIcon': {
        '& path': {
          fill: theme.palette.dina.highEmphasis,
          fillOpacity: 1,
          transition: '0.3s ease',
        },
      },
    },
  },
  addIcon: {},
  media: {
    position: 'relative',
    borderRadius: 4,
    marginRight: '8px',
  },
  playButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate3d(-50%, -50%,0)',
  },
  playImage: {
    width: '32px',
    height: '32px',
  },
  closeButton: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    top: '4px',
    right: '4px',
    backdropFilter: 'blur(15px)',
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    '&:hover': {
      backgroundColor: theme.palette.dina.blackHoverOverlay,
    },
  },
  closeImage: {
    width: '10px',
    height: '10px',
  },
  video: {
    borderRadius: '4px',
    outline: 'none',
    background: 'linear-gradient(180deg, rgba(1,0,5,0.7) 0%, rgba(1,0,5,0.7) 0%)',
  },
}));

export default useStyles;
