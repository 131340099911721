import React from 'react';
import { makeStyles } from '@material-ui/styles';
import imageData from 'assets/images/login/images';
import SignUpLink from '../links/Signup';
import ImageCredit from './ImageCredit';

const images = imageData;
const imageIndex = Math.floor(Math.random() * images.length);
const image = images[imageIndex];
// eslint-disable-next-line import/no-dynamic-require
const imageUrl = require(`../../../assets/images/login/${image.title}`);

const useStyles = makeStyles(() => ({
  image: {
    position: 'absolute',
    right: '0',
    width: '100%',
    height: '100vh',
    background: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  gradient: {
    position: 'absolute',
    right: '0',
    width: '100%',
    height: '100vh',
    background:
      'linear-gradient(360deg, #1F3D77 13.21%, rgba(31, 61, 119, 0.35) 100%)',
    opacity: '0.75',
  },
}));

const ImageContainer = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.image} data-testid="divImage">
        <div className={classes.gradient} />
        <SignUpLink />
        <ImageCredit image={image} />
      </div>
    </React.Fragment>
  );
};

export default ImageContainer;
