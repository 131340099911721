/* eslint-disable jsx-a11y/media-has-caption */
import React, { memo, useState, useCallback } from 'react';
import { IconButton as MaterialIconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import close from 'assets/icons/systemicons/close_small.svg';
import edit from 'assets/icons/systemicons/edit_alt.svg';
import tag from 'assets/icons/systemicons/editor/user_tag.svg';
import play from 'assets/icons/systemicons/Play_WithCircleBackground.svg';
import getTimeString from 'utils/getTimeString';
import MediaDialog from 'components/editor/components/primaryAutomation/components/mediaDialog';
import useStyles from './thumbnail-styles';

const IconButton = memo(MaterialIconButton);

const Thumbnail = ({
  id,
  type,
  size,
  width,
  height,
  src,
  onClose,
  onEdit,
  onTag,
  hidePlayButton,
  hideCloseButton,
  showEditButton,
  showTagButton,
  duration,
  durationDataType,
  canPlayVideo,
  videoThumbnailImage,
  usage,
}) => {
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

  const isImage = type === 'image';
  const isVideo = type === 'video';

  const isScratchPad = usage === 'scratchPad';

  const thumbnailImage = () => {
    if (isImage) return src;
    if (isVideo && videoThumbnailImage) return videoThumbnailImage;
    return null;
  };

  const classes = useStyles({
    width: size || width,
    height: size || height,
    src: thumbnailImage(),
    isScratchPad,
  });

  const handleClose = useCallback(() => {
    onClose(id);
  }, [id, onClose]);

  const closeMediaDialog = useCallback(() => {
    setMediaDialogOpen(false);
  }, []);

  const openMediaDialog = useCallback(() => {
    setMediaDialogOpen(true);
  }, []);

  const VideoComponents = () => (
    <>
      {!videoThumbnailImage && (
        <video width={size || width} height={size || height} className={classes.video}>
          <source src={src} type="video/mp4" />
        </video>
      )}
      {!hidePlayButton && (
        <IconButton className={classes.playButton} onClick={openMediaDialog}>
          <img src={play} alt="close" className={classes.playImage} />
        </IconButton>
      )}
      <div className={classes.duration}>
        {durationDataType === 'milliSeconds' ? getTimeString(duration) : duration}
      </div>
      {canPlayVideo && mediaDialogOpen && src && (
        <MediaDialog
          asset={{ mediaType: 'video', mContentUrl: src }}
          open={mediaDialogOpen}
          onClose={closeMediaDialog}
        />
      )}
    </>
  );

  const ImageComponents = () => {
    if (!showTagButton) return null;
    return (
      <IconButton className={classes.tagButton} onClick={onTag}>
        <img src={tag} alt="close" className={classes.tagImage} />
      </IconButton>
    );
  };

  const CommonComponents = () => (
    <>
      {!hideCloseButton && (
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <img src={close} alt="close" className={classes.closeImage} />
        </IconButton>
      )}
      {showEditButton && (
        <IconButton className={classes.editButton} onClick={onEdit}>
          <img src={edit} alt="close" className={classes.editImage} />
        </IconButton>
      )}
    </>
  );

  return (
    <div className={classes.root}>
      {isVideo && <VideoComponents />}
      {isImage && <ImageComponents />}
      <CommonComponents />
    </div>
  );
};

Thumbnail.propTypes = {
  /** id of the asset */
  id: PropTypes.string,
  /** size of the thumbnail, overwrites both width & height props */
  size: PropTypes.number,
  /** Width of the component */
  width: PropTypes.number,
  /** Height of the component */
  height: PropTypes.number,
  /** Component type */
  type: PropTypes.oneOf(['image', 'video']),
  /** Boolean to hide close button */
  hideCloseButton: PropTypes.bool,
  /** Boolean to show edit button for image component */
  showEditButton: PropTypes.bool,
  /** Boolean to show tag button for image component */
  showTagButton: PropTypes.bool,
  /** Callback for clicking close button */
  onClose: PropTypes.func,
  /** Callback for edit button click */
  onEdit: PropTypes.func,
  /** Callback for tag button click */
  onTag: PropTypes.func,
  /** Duration of video for video component */
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Type of duration data */
  durationDataType: PropTypes.oneOf(['milliSeconds', 'string']),
  /** Boolean to play video of component */
  canPlayVideo: PropTypes.bool,
  /** Boolean to hide play button of video thumbnail */
  hidePlayButton: PropTypes.bool,
  /** Thumbnail image for video */
  videoThumbnailImage: PropTypes.string,
  /** Type of usage of thumbnail component */
  usage: PropTypes.string,
};

Thumbnail.defaultProps = {
  id: '',
  size: 0,
  width: 96,
  height: 64,
  type: 'image',
  hideCloseButton: false,
  showEditButton: false,
  showTagButton: false,
  onClose: () => {},
  onEdit: () => {},
  onTag: () => {},
  duration: 0,
  durationDataType: 'milliSeconds',
  canPlayVideo: true,
  hidePlayButton: false,
  videoThumbnailImage: '',
  usage: 'default',
};

export default memo(Thumbnail);
