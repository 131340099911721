/**
 *  Hook for getting owners data
 */

import { useContext } from 'react';
import { intersectionBy } from 'lodash';
import memberTypes from 'graphql/memberTypes';
import { MembersContext } from 'globalState/members';

const useGetAssignedMembers = mAssignedMembers => {
  const { getMembers = () => {} } = useContext(MembersContext);
  const allMembers = getMembers();
  const assignedMembers = Array.isArray(mAssignedMembers) ? mAssignedMembers : [];

  const usersData = assignedMembers.filter(m => m.mType === memberTypes.USER);
  const teamsData = assignedMembers.filter(m => m.mType === memberTypes.TEAM);
  const departmentsData = assignedMembers.filter(m => m.mType === memberTypes.DEPARTMENT);
  const marketsData = assignedMembers.filter(m => m.mType === memberTypes.MARKET);

  const assignedUsers = intersectionBy(allMembers, usersData, 'mId');
  const assignedTeams = intersectionBy(allMembers, teamsData, 'mId');
  const assignedDepartments = intersectionBy(allMembers, departmentsData, 'mId');
  const assignedMarkets = intersectionBy(allMembers, marketsData, 'mId');

  return [assignedUsers, assignedTeams, assignedDepartments, assignedMarkets];
};

export default useGetAssignedMembers;
