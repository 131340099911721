/* eslint-disable no-useless-catch */
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { useMutation } from 'react-apollo';
import { CurrentTabContext } from 'globalState';
import CREATE_INSTANCE from 'graphql/mutations/createRundownInstance';
import COPY_INSTANCE from 'graphql/mutations/moveInstanceToRundown';
import RundownListContext from 'contexts/RundownListContext';
import { useLoadingIndicator } from 'contexts/LoadingContext';
import { useSelectionContext } from 'contexts/SelectionContext';
import memberTypes from 'graphql/memberTypes';
import uuidv1 from 'uuid/v1';
import useUnscheduleInstance from 'hooks/useUnscheduleInstance';
import LoadingIndicator from 'components/loadingIndicator';
import Popover from 'components/popover/Popover';
import CreateStory from 'components/createNew';
import GET_INSTANCE_FROM_CACHE from 'graphql/queries/getInstanceFromCache';
import updateStoryInstanceCache from 'utils/instance/updateStoryInstanceCache';
import GridView from './gridView/grid-view';
import GridHeader from './gridHeader';
import useStyles from './list-styles';

import rundownListTypes from '../utils/rundownListTypes';

const ListActionContainer = props => {
  const {
    mid,
    refId,
    setMoved,
    selecteddate,
    saveRunDown,
    data,
    listHeaderTitle,
    type,
    hostReadSpeed,
    mOrder,
    form,
    height,
    toggleSelectionInGroup,
    multiSelectTo,
    toggleSelection,
    onDragEnd,
    ...others
  } = props;

  const classes = useStyles();
  const [moveInstanceToRundown] = useMutation(COPY_INSTANCE);
  const [createRundownInstance] = useMutation(CREATE_INSTANCE);
  const [, setCurrentTab] = useContext(CurrentTabContext);
  const [selectedIds, setSelectedIds] = useSelectionContext();
  const [, unscheduleRundownInstances] = useUnscheduleInstance();
  const { loadingIndicatorFor, setLoadingIndicatorFor } = useLoadingIndicator();
  const [anchor, setAnchor] = useState({ position: 0, type, anchorEl: null });
  const componentIsMounted = useRef(true);
  
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const addNewBlankItem = (index, listType) => {
    const newId = `-${uuidv1()}`;
    let destination = type === rundownListTypes.READY ? 'mOrder' : 'mPreorder';
    if (listType) destination = listType === 'readyList' ? 'mOrder' : 'mPreorder';
    const payloadInput = {
      crudAction: 'INSERT',
      value: {
        mId: newId,
        index,
        destination,
      },
    };
    saveRunDown(payloadInput);
  };

  const removeInstance = (index, mId) => {
    setTimeout(() => setLoadingIndicatorFor(null), 1000);
  };

  const updateSelection = (selectedInstanceIds, instanceIdsOnRundown) => {
    let updatedSelection = [];
    const indexOfLastSelectedInstance = instanceIdsOnRundown.findIndex(instanceId => {
      return instanceId === selectedInstanceIds[selectedInstanceIds.length - 1];
    });

    if(indexOfLastSelectedInstance > -1 && indexOfLastSelectedInstance < instanceIdsOnRundown.length - 1) {
      updatedSelection = [instanceIdsOnRundown[indexOfLastSelectedInstance + 1]];
    }

    setSelectedIds(updatedSelection);
  } 

  const unscheduleInstance = async (index, instance, selectedInstanceIds, instanceIdsOnRundown) => {
    const orderType = type === rundownListTypes.READY ? 'mOrder' : 'mPreorder';
    // If the current instance is not in the selectedInstanceIds we will remove the current instance only.
    const instanceIds = selectedInstanceIds.includes(instance.mId) ? selectedInstanceIds : [ instance.mId ];
    await unscheduleRundownInstances(instanceIds, removeInstance, index, orderType, mid);
    updateSelection(instanceIds, instanceIdsOnRundown); // updating instance selection after removing the instances.
    setTimeout(() => setLoadingIndicatorFor(null), 1000);
  };

  const addInstanceToCache = (proxy, instance) => {
    try {
      proxy.writeFragment({
        id: instance.mId,
        fragment: GET_INSTANCE_FROM_CACHE,
        data: { ...instance },
      });
    } catch (e) {
      // console.log(e);
    }
  };

  const onCreateInstanceForStory = (id, title, publishingAt, position, destinationType = type) => {
    const rundown = data.getRundown;
    const destination = destinationType === rundownListTypes.READY ? 'mOrder' : 'mPreorder';
    const account = {
      accountUrl: null,
      accountLogo: null,
      accountTitle: rundown.mTitle,
      accountId: rundown.mId,
      accountRefId: rundown.mRefId,
      orderType: destinationType === rundownListTypes.READY ? 'ready' : 'preparing',
    };

    const hostMetaData =
      rundown.mMetaData && rundown.mMetaData.find(val => val.key === 'host')
        ? rundown.mMetaData.find(val => val.key === 'host')
        : null;

    const host =
      hostMetaData && hostMetaData.value !== 'null' ? JSON.parse(hostMetaData.value) : null;

    const input = {
      mId: rundown.mId,
      mTitle: title,
      isTemplateInstance: rundown.mType !== memberTypes.RUNDOWN,
      index: position,
      mProperties: {
        __typename: 'PlatformType',
        platform: 'linear',
        account,
      },
      mMetaData: [
        {
          key: 'hostReadSpeed',
          value:
            host && host.mProperties && host.mProperties.readSpeed
              ? host.mProperties.readSpeed
              : 150,
        },
      ],
    };

    if (rundown.mType === memberTypes.RUNDOWN) {
      input.mPublishingAt = rundown.mPublishingAt;
      input.mStoryId = id;
    }

    setLoadingIndicatorFor(destinationType);

    createRundownInstance({
      variables: {
        input,
      },
      update: async (proxy, mutationResult) => {
        const newInstance = mutationResult.data.createRundownInstance;
        const payloadInput = {
          crudAction: 'INSERT',
          value: {
            mId: newInstance.mId,
            index: position,
            destination,
          },
        };

        addInstanceToCache(proxy, newInstance);
        saveRunDown(payloadInput);
        await updateStoryInstanceCache(proxy, newInstance.mStoryId, newInstance);

        setTimeout(() => setLoadingIndicatorFor(null), 1000);
      },
    }).catch(e => {
      // console.log(e);
      setLoadingIndicatorFor(null);
    });
  };

  const onMoveInstanceToRundown = (instanceId, position) => {
    const input = {
      mId: instanceId,
      targetRundown: {
        mId: mid,
        mRefId: refId,
      },
      orderType: type,
      index: position,
      copy: true,
    };
    setLoadingIndicatorFor(type);
    moveInstanceToRundown({
      variables: {
        input,
      },
      update: (proxy, mutationResult) => {
        const newInstance = mutationResult.data.moveInstanceToRundown;
        const payloadInput = {
          crudAction: 'NONE',
          value: {
            mId: newInstance.mId,
            index: position,
            destination: type === rundownListTypes.READY ? 'mOrder' : 'mPreorder',
          },
        };
        addInstanceToCache(proxy, newInstance);
        saveRunDown(payloadInput);
        setTimeout(() => setLoadingIndicatorFor(null), 1000);
      },
    });
  };

  const createNewInstanceOnKeyUp = () => {
    if (selectedIds.length === 1) {
      const current = selectedIds[0];
      const targetElement = document.getElementById(current);
      const index = mOrder.indexOf(current);
      if (index !== -1) {
        setAnchor({ position: index, type, anchorEl: targetElement });
      }
    }
  };

  useEffect(() => {
    const logKey = event => {
      if (!selectedIds.length) return;
      if (selectedIds.length === 1) {
        if (
          (event.ctrlKey && event.code === 'KeyI') ||
          (!event.shiftKey && event.code === 'Insert')
        ) {
          event.preventDefault();
          event.stopPropagation();
          createNewInstanceOnKeyUp();
        }

        if (event.shiftKey && event.code === 'Insert') {
          event.preventDefault();
          event.stopPropagation();
          const index = mOrder.indexOf(selectedIds[0]);
          if (index !== -1) {
            addNewBlankItem(index, `${type}List`);
          }
        }
      }
    };
    document.addEventListener('keyup', logKey);
    return () => {
      document.removeEventListener('keyup', logKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds, mOrder]);

  const openStory = instance => {
    setCurrentTab({
      page: memberTypes.STORY,
      title: instance.mTitle,
      id: instance.mStoryId,
      image: instance.mStoryId,
    });
  };

  const onCreateStoryClick = (event, position) => {
    setAnchor({ position, type, anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setAnchor({ ...anchor, anchorEl: null });
  };

  const handleCreate = storyTitle => {
    handleClose();
    onCreateInstanceForStory(null, storyTitle, null, anchor.position, anchor.type);
  };

  const memoizedGrid = useMemo(() => {
    return (
      <RundownListContext>
        <GridView
          onAddButtonClick={addNewBlankItem}
          onRemoveClick={unscheduleInstance}
          onOpenStoryClick={openStory}
          onCreateStoryClick={onCreateStoryClick}
          {...{
            onCreateInstanceForStory,
            onMoveInstanceToRundown,
            hostReadSpeed,
            mOrder,
            form,
            height,
            toggleSelectionInGroup,
            multiSelectTo,
            toggleSelection,
            onDragEnd,
          }}
          {...others}
        />
      </RundownListContext>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(mOrder),
    form,
    height,
    toggleSelectionInGroup,
    multiSelectTo,
    toggleSelection,
    onDragEnd,
  ]);

  return (
    <div className={classes.listContainerDiv}>
      <GridHeader
        {...{
          listHeaderTitle,
          mid,
          refId,
          selecteddate,
          data,
          type,
          hostReadSpeed,
          mOrder,
        }}
      />
      <div style={{ position: 'relative' }}>
        {memoizedGrid}
        <Popover position="right-top" onClose={handleClose} anchorEl={anchor.anchorEl}>
          <CreateStory variant="rundownInstance" onCancel={handleClose} onCreate={handleCreate} />
        </Popover>
        {Boolean(loadingIndicatorFor) && type === loadingIndicatorFor && <LoadingIndicator />}
      </div>
    </div>
  );
};

export default ListActionContainer;
