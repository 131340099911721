export const toolbarButtonIds = {
  READY: 'ready',
  PREPARING: 'preparing',
  EDITOR: 'editor',
};

export const toolbarButtonLabels = [
  { id: 'ready', label: 'Ready' },
  { id: 'preparing', label: 'Preparing' },
  { id: 'editor', label: 'Editor' },
];

export const findButtonLabel = id => toolbarButtonLabels.find(button => button.id === id).label;

export const RUNDOWN_TOOLBAR_BUTTON_DEFAULT_STATE = {
  toolbarButtons: [
    { id: 'ready', selected: true },
    { id: 'preparing', selected: true },
    { id: 'editor', selected: true },
  ],
};
