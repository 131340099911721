/**
 *  Hook for creating a new story and attach it to a user, team or department.
 *  It takes the mId and type of the member, the title of the story and the creators user id.
 *  Returns the story id of the created story.
 */

import { useContext } from 'react';
import { format, isWithinRange } from 'date-fns';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { getStoriesQuery } from 'graphql/queryVariables';
import CREATE_STORY_FOR_MEMBER from 'graphql/mutations/createStoryForMember';
import { SidebarDatePickerContext } from 'globalState/sidebarDatePicker';
import { SidebarSubTabContext } from 'globalState/sidebarSubTab';
import memberTypes from 'graphql/memberTypes';

const GET_STORIES = gql`
  query GetStories($input: GetMemberInput, $limit: Int, $nextToken: String) {
    getMembersPagination(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
      }
      nextToken
    }
  }
`;

const GET_STORIES_BY_UPDATING_DATE = gql`
  query GetStoriesByUpdatedDate(
    $input: GetMembersByPublishingDateInput
    $limit: Int
    $nextToken: String
  ) {
    getMembersByUpdatedAtDate(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
      }
      nextToken
    }
  }
`;

const useCreateStoryMutation = () => {
  const [createStory] = useMutation(CREATE_STORY_FOR_MEMBER);
  const [selectedDates] = useContext(SidebarDatePickerContext);
  const [{ scheduleType }] = useContext(SidebarSubTabContext);
  let createdStoryId;

  const { startDate, endDate, ignoreStartDate } = selectedDates;
  const date = format(new Date(), 'YYYY-MM-DD');
  const sDate = format(startDate, 'YYYY-MM-DD');
  const eDate = format(endDate, 'YYYY-MM-DD');

  const create = async (mId, mType, mTitle, userId, memberType) => {
    await createStory({
      variables: {
        input: {
          mId,
          mTitle,
          mType,
          mCreatedById: userId,
        },
      },
      update: (proxy, mutationResult) => {
        const { createStoryForMember } = mutationResult.data;
        createdStoryId = createStoryForMember.mId;

        if (memberType === memberTypes.STORY && scheduleType === 'scheduled') {
          return;
        }

        try {
          if (isWithinRange(date, sDate, eDate)) {
            let query = GET_STORIES;
            let variables = getStoriesQuery(mId, mType);
            variables.input.startDate = startDate.toISOString();
            variables.input.endDate = endDate.toISOString();
            variables.input.ignoreStartDate = ignoreStartDate;

            if (memberType === memberTypes.STORY) {
              query = GET_STORIES_BY_UPDATING_DATE;
              variables = {
                input: {
                  mType: memberTypes.STORY,
                  startDate: startDate.toISOString(),
                  endDate: endDate.toISOString(),
                  scheduleType,
                  ignoreStartDate,
                },
                limit: 50,
              };
            }

            const storyList = proxy.readQuery({
              query,
              variables,
            });

            const exists = story => {
              return story !== null && story.mId === createStoryForMember.mId;
            };

            if (
              memberType === memberTypes.STORY &&
              !storyList.getMembersByUpdatedAtDate.items.some(exists)
            ) {
              storyList.getMembersByUpdatedAtDate.items.unshift(createStoryForMember);
            } else if (!storyList.getMembersPagination.items.some(exists)) {
              storyList.getMembersPagination.items.unshift(createStoryForMember);
            } else {
              return;
            }

            proxy.writeQuery({
              query,
              variables,
              data: storyList,
            });
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      },
    });

    return createdStoryId;
  };

  return [create];
};

export default useCreateStoryMutation;
