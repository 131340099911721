import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  hostContainter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '198px',
  },
  addMorePeople: {
    width: '32px',
    height: '32px',
    top: 'calc(50% - 32px/2)',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
  },
  hostAvatar: {
    width: '32px',
    height: '32px',
    top: 'calc(50% - 32px/2)',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
  },
  hostLabel: {
    height: '12px',
    left: '0px',
    top: 'calc(50% - 12px/2 - 6px)',

    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '14px',
    /* or 140% */

    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    letterSpacing: '0.5px',
    justifyContent: 'flex-end',

    /* whiteMediumEmphasis */

    color: 'rgba(255, 255, 255, 0.7)',
    borderRadius: '4px',
  },
  hostName: {
    height: '12px',
    top: 'calc(50% - 12px/2 + 6px)',

    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    /* or 140% */

    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    letterSpacing: '0.5px',
    justifyContent: 'flex-end',
    /* whiteMediumEmphasis */

    color: 'rgba(255, 255, 255, 0.7)',
    borderRadius: '4px',
  },
}));

export default useStyles;
