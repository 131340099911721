import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Typography, Button, FormControlLabel } from '@material-ui/core';
import Switch from 'components/switch/Switch';
import TimeNavigator from 'screens/planning/components/header/timeNavigator';
import useStyles from './header-styles';

const Header = ({
  date,
  setDate,
  onUnschedule,
  openDrawer,
  selectRange,
  hideUnscheduleButton,
  toggleSelectRange,
  isSelectingRange,
}) => {
  const classes = useStyles({ openDrawer });
  const [showRangeSwitch, setShowRangeSwitch] = useState();

  const handleTimeNavigation = newDate => {
    setDate(newDate);
  };

  useEffect(() => {
    let timeOut;
    if (openDrawer)
      timeOut = setTimeout(() => {
        setShowRangeSwitch(true);
      }, 200);
    else setShowRangeSwitch(false);
    return () => {
      timeOut && clearTimeout(timeOut);
    };
  }, [openDrawer]);

  return (
    <div className={classes.header}>
      <div className={classes.leftComponents}>
        <TimeNavigator
          time={date}
          timeVariant="month"
          buttonHeight={48}
          onChange={handleTimeNavigation}
          resetTitle=""
        />
        <Typography classes={{ root: classes.headerText }}>{format(date, 'MMMM YYYY')}</Typography>
      </div>
      <div>
        {selectRange && showRangeSwitch && (
          <FormControlLabel
            control={<Switch selected={isSelectingRange} onChange={toggleSelectRange} />}
            classes={{
              root: classes.formControlLabel,
              label: classes.label,
            }}
            label="Select a range"
          />
        )}
        {!hideUnscheduleButton && (
          <Button
            classes={{ root: classes.unscheduleButton, label: classes.unscheduleText }}
            onClick={onUnschedule}
          >
            Unschedule
          </Button>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  date: PropTypes.string.isRequired,
  setDate: PropTypes.func,
  onUnschedule: PropTypes.func,
  openDrawer: PropTypes.bool,
  selectRange: PropTypes.bool,
  hideUnscheduleButton: PropTypes.bool,
  toggleSelectRange: PropTypes.func,
  isSelectingRange: PropTypes.bool,
};

Header.defaultProps = {
  setDate: () => {},
  onUnschedule: () => {},
  openDrawer: false,
  selectRange: false,
  hideUnscheduleButton: false,
  toggleSelectRange: () => {},
  isSelectingRange: false,
};

export default Header;
