import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/avatar';
import Popover from 'components/popover';
import getInitials from 'utils/getInitials';
import { Button } from '@material-ui/core';
import PopoverList from './popoverList-view';
import useStyles from './groups-styles';

const Groups = props => {
  const { width, height, size, variant, items, onClick } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const type = variant === 'Department' ? 'topic' : null;
  const classes = useStyles(width, height, size)();

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
    onClick(event);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const propagationHandler = event => {
    event.stopPropagation();
  };
  return (
    <div
      className={classes.container}
      onClick={propagationHandler}
      onKeyPress={propagationHandler}
      role="button"
      tabIndex="0"
    >
      {items.length > 0 && (
        <Avatar
          variant={variant}
          type={type}
          width={width}
          height={height}
          size={size}
          src={items[0].mAvatarUrl}
          onClick={handlePopoverOpen}
        >
          {getInitials(items[0].mTitle)}
        </Avatar>
      )}
      {items.length > 1 && (
        <Button
          variant="text"
          classes={{ root: classes.button, text: classes.buttonText }}
          onClick={handlePopoverOpen}
          data-testid="button"
        >
          {`+${items.length - 1}`}
        </Button>
      )}
      <Popover
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        position="bottom-center"
      >
        <PopoverList {...{ items, variant, type }} />
      </Popover>
    </div>
  );
};

Groups.propTypes = {
  /** Variant of avatar. Defines the size and shape of avatar & button */
  variant: PropTypes.string,
  /** Callback to be invoked when avatar or button is clicked */
  onClick: PropTypes.func,
  /** List of items to be shown */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
    })
  ),
  /** Width of the avatar */
  width: PropTypes.number,
  /** Height of the avatar */
  height: PropTypes.number,
  /** Size of the avatar */
  size: PropTypes.number,
};

Groups.defaultProps = {
  variant: 'Team',
  onClick: () => {},
  items: [],
  width: 20,
  height: 20,
  size: null,
};

export default Groups;
