import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { ReactComponent as VisibilityIcon } from 'assets/icons/systemicons/editor/visibility.svg';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { ReactComponent as PublicIcon } from 'assets/icons/systemicons/world.svg';
import { ReactComponent as UnlistedIcon } from 'assets/icons/systemicons/visibility_off.svg';
import { ReactComponent as PrivateIcon } from 'assets/icons/systemicons/padlock_on.svg';
import updateBlock from 'components/editor/utils/updateBlock';
import SelectComponent from 'components/select';
import Box from '../box';
import useStyles from './styles';

const Select = memo(SelectComponent);

const items = [
  {
    value: 'public',
    title: 'Public',
    icon: <PublicIcon />,
    info: 'Everyone can\nfind, see and share',
  },
  {
    value: 'unlisted',
    title: 'Unlisted',
    icon: <UnlistedIcon />,
    info: 'Only people with the link\ncan see and share',
  },
  {
    value: 'private',
    title: 'Private',
    icon: <PrivateIcon />,
    info: 'Only you or the people\nyou choose can see',
    showTopDivider: true,
  },
];

const Privacy = ({ attributes, children, element, readOnly }) => {
  const classes = useStyles();
  const { data } = element;
  const editor = useSlate();
  const { update } = useEditorContext();

  const initialValue = data.content || '';

  const [privacyValue, setPrivacyValue] = useState(initialValue);

  const onChange = useCallback(newValue => {
    setPrivacyValue(newValue);

    const updatedData = {
      ...data,
      content: newValue,
    };
    updateBlock(editor, element, updatedData, update);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div {...attributes}>
      {children}
      <Box
        iconComponent={<VisibilityIcon className={classes.icon} />}
        title="Privacy"
        height={92}
        readOnly={readOnly}
        hideEllipsisButton
      >
        <Select selectedValue={privacyValue} items={items} onChange={onChange} usage="editor" />
      </Box>
    </div>
  );
};

Privacy.propTypes = {
  readOnly: PropTypes.bool,
};

Privacy.defaultProps = {
  readOnly: false,
};

export default memo(Privacy);
