import React from 'react';
import PropTypes from 'prop-types';
import StatusIndicator from 'components/statusIndicators/StatusIndicator';
import Container from '../container/Container';
import MaterialAvatar from '../material-avatar';
import useStyles from './styles';

const People = ({
  title,
  children,
  src,
  activityStatus,
  checked,
  width,
  height,
  type,
  ...rest
}) => {
  const classes = useStyles(width, height)();

  return (
    <Container {...{ width, height }} {...rest}>
      <MaterialAvatar classes={classes[type]} {...{ src, title }}>
        {src ? null : children}
      </MaterialAvatar>

      {checked && (
        <div className={classes.checked}>
          <StatusIndicator variant="Checked" />
        </div>
      )}

      {activityStatus && (
        <div className={classes.activityStatus} data-testid="indicator">
          <StatusIndicator variant={activityStatus} />
        </div>
      )}
    </Container>
  );
};

People.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  activityStatus: PropTypes.oneOf(['', 'Available', 'Away', 'Idle']),
  checked: PropTypes.bool,
  /** Type of avatar based on border */
  type: PropTypes.PropTypes.oneOf(['root', 'bordered', 'thinBordered']),
};

People.defaultProps = {
  children: null,
  src: '',
  activityStatus: '',
  checked: false,
  type: 'root',
};

export default People;
