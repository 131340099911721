import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { mediaTypes } from 'utils/rundownItemTypes';
import dndTypes from 'utils/dndTypes';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import getTypeValues from 'components/editor/utils/getTypeValues';
import DropZone from 'components/editor/components/dropZone';
import addGraphics from './utils/addGraphics';
import addClip from './utils/addClip';

const mediaTypeValues = getTypeValues(mediaTypes);
const { GRAPHICS, CLIP } = mediaTypes;
const { ASSET } = dndTypes;

const MediaDropZone = ({ children, element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();

  const onDrop = useCallback(
    ({ type, payload }) => {
      if (type === GRAPHICS) addGraphics(editor, element, payload, update);
      if (type === CLIP || type === ASSET) addClip(editor, element, payload, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  return (
    <DropZone accept={[ASSET, ...mediaTypeValues]} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

MediaDropZone.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

MediaDropZone.defaultProps = {
  children: null,
  element: {
    children: [],
  },
};

export default memo(MediaDropZone);
