import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import UPDATE_RUNDOWN_SYNC from 'graphql/mutations/updateRundownSync';
import UPDATE_RUNDOWN from 'graphql/mutations/updateRundown';
import rundownStates from 'screens/rundown/utils/rundownStates';
import updateTimeOfISODate from '../../../utils/updateTimeOfISODate';
import Header from './default-header-view';

const HeaderContainer = ({ mid, refId, selecteddate, title, mState, data, startTime, ...rest }) => {
  const [startingTime, setStartingTime] = useState(startTime);
  const [updateRundown] = useMutation(UPDATE_RUNDOWN);
  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);
  useEffect(() => {
    setStartingTime(startTime);
  }, [startTime]);

  const onUpdateRundownSync = (time, plan) => {
    const payloadInput = {
      crudAction: 'UPDATE',
    };
    const mPayload = [payloadInput];
    const input = {
      mId: mid,
      mRefId: refId,
      mPublishingAt: startingTime,
      mPayload,
    };
    if (time) input.mPublishingAt = time;
    if (plan) input.mPlannedDuration = plan;

    const variables = { input };
    updateRundownSync({ variables });
  };

  const handleStartTimeChange = newStartTime => {
    if (newStartTime) {
      const updatedTime = updateTimeOfISODate(selecteddate, newStartTime);
      setStartingTime(updatedTime);
      onUpdateRundownSync(updatedTime, null);
    }
  };

  const updateRundownTitle = updatedTitle => {
    updateRundown({
      variables: {
        input: {
          mId: mid,
          mRefId: refId,
          mTitle: updatedTitle,
        },
      },
    });
  };

  return (
    <Header
      startDate={startingTime}
      startTime={format(startingTime, 'HH:mm:ss')}
      setStartTime={handleStartTimeChange}
      readyToAir={mState && mState === rundownStates.READY}
      updateRundownTitle={updateRundownTitle}
      {...{
        title,
        selecteddate,
      }}
      {...rest}
    />
  );
};

HeaderContainer.propTypes = {
  /** Id of the current rundown */
  id: PropTypes.string,
};

HeaderContainer.defaultProps = {
  id: '',
};

export default HeaderContainer;
