import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  children: {
    paddingTop: '32px',
    paddingBottom: '16px',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 32,
    width: '100%',
    display: 'flex',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  thread: {
    width: '100%',
    height: '100%',
  },
  labelWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 31,
    alignItems: 'center',
  },
  threadText: {
    ...typography.dina.overline,
    lineHeight: '12px',
    letterSpacing: '1px',
  },
  iconButton: {
    cursor: 'pointer',
  },
  confirmButton: {
    ...typography.dina.caption,
    fontWeight: 'normal',
    lineHeight: '13px',
    textTransform: 'none',
    letterSpacing: '0.25px',
    color: palette.dina.highEmphasis,
    padding: 0,
    height: 24,
    width: 144,
    backgroundColor: palette.dina.statusError,
    '&:hover': {
      '-webkit-filter': palette.dina.onHover,
      backgroundColor: palette.dina.statusError,
    },
  },
  avatar: {
    width: 32,
    height: 32,
    position: 'relative',
    marginRight: '8px',
  },
  thumbnail: {
    width: 26,
    height: 26,
    borderRadius: '2px',
    objectFit: 'cover',
  },
  publishingPoint: {
    width: 22,
    height: 22,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
}));

export default useStyles;
