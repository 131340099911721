import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  sidebar: {
    height: '100%',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
  },
}));

export default useStyles;
