import gql from 'graphql-tag';

export default gql`
  query GetConfigs($input: GetMemberInput) {
    getMemberFromId(input: $input) {
      mTitle
      mId
      mRefId
      mType
      mStoryId
      configs {
        type
        name
        key
        value
      }
      mProperties {
        __typename
        ... on AutomationTemplateConfigType {
          templateSets {
            name
            defaultSet
            created
            templates {
              name
              type
              variant
              defaultVariant              
              description
              disabled
              requires {
                video
                graphics
                audio
              }
              transition {
                type
                value
              }
              fields {
                name
                value
                type
              }
            }
          }
          resources {
            effects {
              name
              value
            }
            mixerInputs {
              name
              value
            }
          }
        }
      }
    }
  }
`;
