import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    minWidth: '860px',
  },
  extendedContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  extendedGrid: {
    background: 'transparent',
    width: '100%',
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  list: {
    outline: 'none',
    '& > :first-child': {
      overflow: 'visible !important',
    },
  },
  fabDiv: {
    height: 3,
    backgroundColor: theme.palette.dina.surfaceToolBarStoryView,
    width: '100%',
    borderRadius: '1px',
    position: 'relative',
    marginTop: '-1px',
    zIndex: 99,
  },
  selectionCount: {
    position: 'absolute',
    zIndex: 100,

    transform: 'translate3d(6px, -9px, 0)',
    width: '21px',
    height: '21px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.dina.highEmphasis,
    justifyContent: 'center',
    borderRadius: '50%',
    background: theme.palette.dina.statusOnFocused,
  },
  addIcon: {
    '& path': {
      fill: theme.palette.dina.iconActive,
      fillOpacity: '1',
    },
  },
}));

export default useStyles;
