import React, { useRef, useState, useCallback, memo } from 'react';
import { useSlate } from 'slate-react';
import { Transforms } from 'slate';
import { actionTypes, elementTypes } from 'components/editor/constants/types';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import Tooltip from 'components/tooltip';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { ReactComponent as HourglassIcon } from 'assets/icons/systemicons/hourglass.svg';
import Dialog from 'components/dialog';
import CreateNew from 'components/createNew';
import useStyles from './styles';

const { select, insertNodes } = Transforms;

const PlaceholderButton = () => {
  const classes = useStyles();
  const editor = useSlate();
  const { selection } = editor;
  const selectionCacheRef = useRef(selection);
  const { containerRef, defaultPlaceholderTitle, update } = useEditorContext();
  const [dialogOpen, setDialogOpen] = useState(false);

  const closeDialog = useCallback(() => setDialogOpen(false), []);
  const openDialog = useCallback(() => setDialogOpen(true), []);

  const handleOpenDialog = useCallback(
    event => {
      event.preventDefault();

      selectionCacheRef.current = selection;
      openDialog();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selection],
  );

  const handleCreate = useCallback(
    async title => {
      closeDialog();

      try {
        if (selectionCacheRef.current) select(editor, selectionCacheRef.current);

        const result = await update({
          type: actionTypes.CREATE_PLACEHOLDER,
          payload: { document: editor.children, title },
        });

        const element = {
          type: elementTypes.PLACEHOLDER,
          data: {
            ...result,
            mTitle: title,
            itemDuration: 0,
            itemType: 'video',
            mediaType: 'video/placeholder',
          },
          children: [{ text: '' }],
        };

        insertNodes(editor, element);
      } catch (error) {
        // console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleCancel = useCallback(() => {
    if (selectionCacheRef.current) select(editor, selectionCacheRef.current);
    closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Tooltip title={tooltipEnum.PLACEHOLDER}>
        <div className={classes.icon}>
          <HourglassIcon alt="Insert Placeholder" onMouseDown={handleOpenDialog} />
        </div>
      </Tooltip>

      <Dialog container={containerRef.current} open={dialogOpen} onClose={handleCancel}>
        <CreateNew
          variant="placeholder"
          defaultTitle={defaultPlaceholderTitle}
          onCreate={handleCreate}
          onCancel={handleCancel}
        />
      </Dialog>
    </>
  );
};

export default memo(PlaceholderButton);
