import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: '10px',
    paddingRight: '12px',
    paddingTop: '12px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    // backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
  },
  content: {
    flexGrow: 1,
  },
  sidebarHeader: {
    height: '36px',
    minHeight: '36px',
    display: 'flex',
    alignItems: 'flex-end',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
  },
  sidebarHeaderText: {
    ...theme.typography.dina.h5,
    lineHeight: '28px',
    color: theme.palette.dina.mediumEmphasis,
  },
}));

export default useStyles;
