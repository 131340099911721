import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '420px',
      height: '200px',
      backgroundColor: theme.palette.dina.surfaceCard,
      backdropFilter: 'blur(30px)',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    header: {
      position: 'absolute',
      height: '64px',
      left: '16px',
      right: '8px',
      top: '8px',
    },
    dividerTop: {
      position: 'absolute',
      height: '1px',
      left: '0px',
      right: '1px',
      bottom: '0px',
      top: '64px',
    },
    dividerBottom: {
      position: 'absolute',
      height: '1px',
      left: '0px',
      right: '1px',
      bottom: '0px',
      top: '143px',
    },
    headerText: {
      ...theme.typography.dina.h5,
      lineHeight: '28px',
      height: '64px',
      display: 'flex',
      alignItems: 'center',
    },
    body: {
      position: 'absolute',
      top: '64px',
      flexGrow: 1,
      marginLeft: '16px',
      maxHeight: '240px',
    },
    checkboxContainer: {
      position: 'absolute',
      width: '388px',
      height: '24px',
      left: '10px',
      top: '16px',
    },
    checkBoxText: {
      ...theme.typography.dina.caption,
      lineHeight: '14px',
      fontSize: '12px',
      letterSpacing: '0.25px',
      marginTop: '7px',
      marginLeft: '7px',
      width: '100%',
      height: '24px',
      color: theme.palette.dina.highEmphasis,
    },
    labelText: {
      ...theme.typography.dina.caption,
      lineHeight: '14px',
      fontSize: '12px',
      fontStyle: 'italic',
      letterSpacing: '0.25px',
      marginTop: '7px',
      width: '100%',
      height: '24px',
      color: theme.palette.dina.mediumEmphasis,
    },
    labelTextContainer: {
      position: 'absolute',
      height: '24px',
      left: '20px',
      top: '20px',
    },
    buttonContainer: {
      position: 'absolute',
      top: '143px',
      width: '100%',
      height: '56px',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    cancelButton: {
      ...theme.typography.dina.labelText,
      color: theme.palette.dina.mediumEmphasis,
      position: 'absolute',
      width: '136px',
      height: '40px',
      right: '152px',
      top: '8px',
      bottom: '8px',
    },
    createButton: {
      backgroundColor: theme.palette.dina.statusError,
      '&:hover': {
        backgroundColor: theme.palette.dina.statusError,
      },
      '&:disabled': {
        opacity: '0.35',
      },
      position: 'absolute',
      width: '136px',
      height: '40px',
      right: '8px',
      top: '8px',
      bottom: '8px',
    },
    cancelButtonLabel: {
      ...theme.typography.dina.labelText,
      fontWeight: '500px',
      textTransform: 'none',
    },
    okButtonLabel: {
      ...theme.typography.dina.button,
      fontWeight: '500px',
      textTransform: 'none',
    },
  };
});

export default useStyles;
