/**
 *  Hook for creating a new story and attach it to a user, team or department.
 *  It takes the mId and type of the member, the title of the story and the creators user id.
 *  Returns the story id of the created story.
 */

import { useContext } from 'react';
import { format, isWithinRange } from 'date-fns';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import CREATE_PITCH from 'graphql/mutations/createPitch';
import { SidebarDatePickerContext } from 'globalState/sidebarDatePicker';
import { SidebarSubTabContext } from 'globalState/sidebarSubTab';

const GET_STORIES_BY_UPDATING_DATE = gql`
  query GetStoriesByUpdatedDate(
    $input: GetMembersByPublishingDateInput
    $limit: Int
    $nextToken: String
  ) {
    getMembersByUpdatedAtDate(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
      }
      nextToken
    }
  }
`;

const useCreatePitchMutation = () => {
  const [createPitchMutation] = useMutation(CREATE_PITCH);
  const [selectedDates] = useContext(SidebarDatePickerContext);
  const [{ scheduleType }] = useContext(SidebarSubTabContext);

  const { startDate, endDate, ignoreStartDate } = selectedDates;
  const date = format(new Date(), 'YYYY-MM-DD');
  const sDate = format(startDate, 'YYYY-MM-DD');
  const eDate = format(endDate, 'YYYY-MM-DD');
  let createdStoryId;

  const create = async (mId, mType, mTitle, userId) => {
    await createPitchMutation({
      variables: {
        input: {
          mId: userId,
          mTitle,
        },
      },
      update: (proxy, mutationResult) => {
        const { createPitch } = mutationResult.data;
        createdStoryId = createPitch.mId;

        if (scheduleType === 'scheduled') {
          return;
        }

        try {
          if (isWithinRange(date, sDate, eDate)) {
            const variables = {
              input: {
                mType: 'pitch',
                startDate,
                endDate,
                scheduleType,
                ignoreStartDate,
              },
              limit: 50,
            };
            const pitchList = proxy.readQuery({
              query: GET_STORIES_BY_UPDATING_DATE,
              variables,
            });

            const exists = pitch => {
              return pitch !== null && pitch.mId === createPitch.mId;
            };

            if (!pitchList.getMembersByUpdatedAtDate.items.some(exists)) {
              pitchList.getMembersByUpdatedAtDate.items.unshift(createPitch);
            }

            proxy.writeQuery({
              query: GET_STORIES_BY_UPDATING_DATE,
              variables,
              data: pitchList,
            });
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      },
    });

    return createdStoryId;
  };

  return [create];
};

export default useCreatePitchMutation;
