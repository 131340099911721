/* eslint-disable no-param-reassign */
import { Transforms, Element, Node } from 'slate';
import insertParagraph from 'components/editor/components/paragraph/utils/insertParagraph';
import { elementTypes } from 'components/editor/constants/types';
import isList from 'components/editor/components/list/utils/isList';

const { PARAGRAPH, LIST_ITEM, UNORDERED_LIST, ORDERED_LIST } = elementTypes;
const { select, setNodes, removeNodes, insertNodes } = Transforms;
const { isElement } = Element;

/**
 * Wraps editor with overriden normalization plugin functionalites
 *
 * @param {Object} editor SlateJS editor instance
 * @returns {Object} SlateJS editor instance
 */

const withNormalization = editor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry;
    const isRoot = path.length === 0;

    if (isRoot) {
      const { children } = node;
      const numberOfChildren = children.length;
      const lastChild = children[numberOfChildren - 1];

      if (!lastChild) return insertParagraph(editor, { mode: 'highest' });

      if (lastChild && lastChild.type !== PARAGRAPH) {
        const {
          selection: { focus },
        } = editor;

        insertParagraph(editor, { mode: 'highest' });

        return select(editor, focus);
      }
    }

    if (isElement(node) && editor.isVoid(node) && node.children[0].text !== '') {
      removeNodes(editor, { at: path });
      return insertNodes(editor, { ...node, children: [{ text: '' }] });
    }

    if (isElement(node) && isList(node)) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (
          child.type !== LIST_ITEM ||
          child.type !== UNORDERED_LIST ||
          child.type !== ORDERED_LIST
        )
          return setNodes(editor, { type: LIST_ITEM }, { at: childPath });
      }
    }

    return normalizeNode(entry);
  };

  return editor;
};

export default withNormalization;
