import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  select: {
    height: '100%',
    width: 96 + 4 + 4,
    borderRight: `1px solid ${palette.dina.dividerLight}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectContainer: {
    height: '100%',
    paddingTop: 4,
    paddingBottom: 4,
  },
}));

export default useStyles;
