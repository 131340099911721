import React from 'react';
import ContainerBox from '../containerBox/ContainerBox';
import useStyles from '../styles';

const Idle = () => {
  const classes = useStyles('statusWarning')();

  return (
    <ContainerBox>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={classes.shape}
          cx="7"
          cy="7"
          r="5.5"
          strokeWidth="2"
          strokeLinejoin="bevel"
        />
      </svg>
    </ContainerBox>
  );
};

export default Idle;
