import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { AuthConsumer } from 'contexts/AuthContext';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import LoginForm from './forms/LoginForm';
import ImageContainer from './splashImage/ImageContainer';

const useStyles = makeStyles(() => ({
  mainDiv: {
    width: '100%',
    height: '100vh',
    position: 'relative',
  },

  left: {
    width: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    '@media (max-width:768px)': {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },
  right: {
    width: '50%',
    position: 'absolute',
    top: 0,
    right: 0,
    '@media (max-width:768px)': {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <AuthConsumer>
        {context => (
          <div className={classes.mainDiv}>
            <div className={classes.left}>
              <LoginForm context={context} setLoading={setLoading} />
            </div>
            <div className={classes.right}>
              <ImageContainer data-testid="imagecontainer" />
            </div>
            {loading ? <LoadingIndicator /> : <div />}
          </div>
        )}
      </AuthConsumer>
    </>
  );
};

export default Login;
