/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Input, Typography } from '@material-ui/core';
import { useMaximized } from 'contexts/MaximizeContext';
import { ReactComponent as PlusUltraIcon } from 'assets/icons/systemicons/plus_ultraSmall_circle.svg';
import UploadMediaInput from '../../utils/coverPhoto/uploadMediaInput';
import useStyles from './cover-styles';

const CoverView = ({
  data,
  storyId,
  titleChangeHandler,
  title,
  image,
  onUploadCompleted,
  coverPhotoSelected,
  isPitch,
}) => {
  const { isMaximized } = useMaximized();
  const classes = useStyles({ isMaximized });
  const [storyTitle, setStoryTitle] = useState(title);
  useEffect(() => {
    setStoryTitle(title);
  }, [title]);

  const handleTitleChange = event => {
    const newTitle = event.target.value.trim();
    setStoryTitle(newTitle);
    titleChangeHandler(newTitle);
  };

  const buttonLabel = `${image ? 'Change' : 'Add'} Cover photo`;
  const { mAvatarKey, mThumbnailKey } = data.getMember;
  const MediaInput = (
    <UploadMediaInput
      coverPhoto
      coverPhotoKey={mAvatarKey}
      thumbnailKey={mThumbnailKey}
      {...{ storyId, onUploadCompleted, coverPhotoSelected }}
    />
  );
  return (
    <div className={`${classes.input} ${isPitch ? classes.paddedInput : ''}`}>
      <div className={classes.coverContainer}>
        <label
          className={classes.buttonDiv}
          role="button"
          onClick={() => {}}
          tabIndex="0"
          onKeyDown={() => {}}
        >
          <PlusUltraIcon />
          {MediaInput}
          <Typography classes={{ root: classes.buttonText }}>{buttonLabel}</Typography>
        </label>
        <Input
          fullWidth
          multiline
          disableUnderline
          placeholder="Give your Story a title..."
          rowsMax={2}
          className={classes.title}
          classes={{
            inputMultiline: classes.titleMultiline,
          }}
          value={storyTitle}
          onBlur={handleTitleChange}
          onChange={e => {
            setStoryTitle(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default CoverView;
