import gql from 'graphql-tag';

export default gql`
  query BatchGetMembers($input: BatchGetMembersInput) {
    batchGetMembers(input: $input) {
      mId
      mRefId
      mTitle
      mAvatarUrl
      mThumbnailUrl
      mAvatarKey
      mThumbnailKey
      mCreatedAt
      mUpdatedAt
      mContentKey
      mDefaultContentKey
      mPublishingAt
      mType
      mMetaData {
        key
        value
      }
      mAssignedMembers {
        mId
        mType
      }
    }
  }
`;
