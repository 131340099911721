import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { scaleTime } from 'd3';
import { addYears, closestTo, isFuture, subYears } from 'date-fns';
import { useDrop } from 'react-dnd';
import types from 'utils/dndTypes';
import { publishingPoints } from 'assets/icons/publishingPoints';
import CurrentTimeIndicator from './currentTimeIndicator';
import HoverScroller from './hoverScroller';
import MiniMap from './miniMap';
import TimeGrid from './timeGrid';
import useStyles from './timeline-styles';
import ZoomController from './zoomController';
import CurrentDatesRow from './currentDatesRow';

const margin = 20;
const now = new Date();
const startDate = subYears(now, 1);
const endDate = addYears(now, 1);
const gridHeight = 56;
const iconSize = 24;

const TimelineView = forwardRef((props, ref) => {
  const { timelineSize, instances, onSchedule, storyPulishingDate } = props;
  const classes = useStyles();
  const svgRef = useRef(null);
  const xScaleRef = useRef(null);
  const newXScaleRef = useRef(null);
  const zoomControllerRef = useRef(null);
  const draggingRef = useRef(false);
  const rootRectRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [zoomTransform, setZoomTransform] = useState({});

  const [{ isOver, didDrop }, dropRef] = useDrop({
    accept: [types.INSTANCE],
    drop({ instance }, monitor) {
      if (!didDrop) onDrop(monitor.getClientOffset(), instance);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      didDrop: monitor.didDrop(),
    }),
  });

  const init = () => {
    const rect = svgRef.current.getBoundingClientRect();
    const calculatedWidth = rect.width.toFixed(0) - 2 * margin;
    const calculatedHeight = rect.height.toFixed(0) - 1;

    xScaleRef.current = scaleTime()
      .domain([startDate, endDate])
      .range([0, calculatedWidth]);

    rootRectRef.current = rect;

    setWidth(calculatedWidth);
    setHeight(calculatedHeight);
  };

  const onDrag = () => {
    draggingRef.current = isOver;
  };

  const onDrop = (clientOffset, droppedItem) => {
    const xOffset = clientOffset.x - rootRectRef.current.x - iconSize;
    const newXScale = newXScaleRef.current;
    const newSchedule = newXScale.invert(xOffset);
    const tickValues = newXScale.ticks();

    const futureTickValues = tickValues.filter(tickValue => isFuture(tickValue));

    const start = closestTo(newSchedule, futureTickValues);

    onSchedule({ ...droppedItem, start: start.toISOString() });
  };

  useEffect(init, [timelineSize]);
  useEffect(onDrag, [isOver]);

  useImperativeHandle(ref, () => ({
    onDrop,
  }));

  const onZoomChange = transform => {
    newXScaleRef.current = transform.rescaleX(xScaleRef.current);
    setZoomTransform(transform);
  };

  const scheduledInstances = instances.filter(instance => !!instance.start);

  return (
    <div ref={dropRef} className={classes.root}>
      <svg
        width="100%"
        height={96}
        ref={svgRef}
        className={`${classes.svg} ${zoomTransform.k > 1 ? 'zoomed' : ''}`}
      >
        <ZoomController
          ref={zoomControllerRef}
          {...{
            width,
            margin,
            height,
            xScaleRef,
            newXScaleRef,
            endDate,
            startDate,
            onZoomChange,
            draggingRef,
            storyPulishingDate,
          }}
        />

        <CurrentDatesRow {...{ zoomTransform, newXScaleRef, margin }} />

        <TimeGrid
          instances={scheduledInstances}
          {...{
            width,
            xScaleRef,
            newXScaleRef,
            gridHeight,
            margin,
            height,
            zoomTransform,
            iconSize,
            draggingRef,
            rootRectRef,
            onSchedule,
          }}
        />

        <CurrentTimeIndicator {...{ gridHeight, newXScaleRef, height, margin, width }} />

        <HoverScroller
          {...{
            iconSize,
            height,
            zoomControllerRef,
            draggingRef,
            newXScaleRef,
          }}
          left={margin}
          direction="right"
        />

        <HoverScroller
          {...{
            iconSize,
            height,
            zoomControllerRef,
            draggingRef,
            newXScaleRef,
          }}
          left={width + margin}
          direction="left"
        />
      </svg>

      <MiniMap {...{ zoomControllerRef }} />
    </div>
  );
});

TimelineView.propTypes = {
  /** Defines the size for the timeline pane */
  timelineSize: PropTypes.number.isRequired,
  /** List of all story instances, scheduled and unscheduled */
  instances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      publishingPoint: PropTypes.oneOf(Object.keys(publishingPoints)),
    }),
  ).isRequired,
  /** Callback to be invoked when an instance is scheduled/re-schduled,
   *  with the newly scheduled instance passed in */
  onSchedule: PropTypes.func.isRequired,
};

export default TimelineView;
