import { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { EditorContext } from 'globalState';
import LOCK_INSTANCE from 'graphql/mutations/lockInstance';
import useReleaseEditorLock from 'hooks/useReleaseEditorLock';
import { getUserLockToken } from 'utils/lock/lockToken';

const useLockInstance = () => {
  const [lockInstanceMutation] = useMutation(LOCK_INSTANCE);
  const [, , , setEditorLockedData] = useContext(EditorContext);
  const [releaseLock] = useReleaseEditorLock();

  const lock = async (instanceId, userId) => {
    const input = {
      mId: instanceId,
      userId: getUserLockToken(userId),
    };

    try {
      releaseLock(instanceId);
      const result = await lockInstanceMutation({
        variables: { input },
      });
      setEditorLockedData({
        mId: instanceId,
        locked: userId,
        mType: 'instance',
      });
      return result;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return [lock];
};

export default useLockInstance;
