import React from 'react';
import Button from 'components/buttons/button';
import SearchIcon from 'assets/icons/systemicons/search.svg';
import Popover from 'components/popover';
import useStyles from './dialog-styles';

const Dialog = props => {
  const { anchorEl, closeDialog, children, submit, clearInput } = props;
  const classes = useStyles();

  return (
    <Popover classes={{ paper: classes.popover }} onClose={closeDialog} anchorEl={anchorEl}>
      <div className={classes.dialog}>{children}</div>
      <div className={classes.buttons}>
        <Button variant="text" classes={{ text: classes.cancelButton }} onClick={closeDialog}>
          Cancel
        </Button>
        <>
          <Button
            classes={{ outlined: classes.clearButton }}
            variant="outlined"
            onClick={clearInput}
          >
            Clear all
          </Button>
          <Button
            variant="contained"
            type="cta"
            onClick={() => {
              closeDialog();
              submit();
              clearInput();
            }}
          >
            <img src={SearchIcon} alt="Search icon" />
            Search
          </Button>
        </>
      </div>
    </Popover>
  );
};

export default Dialog;
