import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContentCard from 'screens/main/components/rightArea/sidebar/popup-cards';
import Dialog from 'components/dialog';
import Scrollbar from 'screens/main/components/scrollbar';
import AddMemberDialog from 'components/addMemberDialog';
import DetailList from '../../../detailList';
import AddDescription from '../../../addDescription';
import AddItem from '../../../addItem';
import useStyles from './info-styles';

const InfoView = ({
  description,
  updateDescription,
  teams,
  addTeamsToDepartment,
  people,
  addPeopleToDepartment,
  containerRef,
}) => {
  const classes = useStyles();
  const [openTeamsDialog, setOpenTeamsDialog] = useState(false);
  const [openPeopleDialog, setOpenPeopleDialog] = useState(false);
  const [cardContent, setCardContent] = useState(null);

  const onListItemClick = item => {
    setCardContent(item);
  };

  return (
    <div className={classes.root}>
      <Scrollbar>
        <AddDescription
          addItemTitle="Add Description..."
          onUpdate={updateDescription}
          helperText="Short description of what the Department does"
          {...{ description }}
        />

        <DetailList
          listTitle="Teams"
          members={teams}
          avatarVariant="Team"
          onItemClick={onListItemClick}
        />

        <AddItem title="Teams..." onClick={() => setOpenTeamsDialog(true)} />

        <Dialog
          open={openTeamsDialog}
          container={containerRef.current}
          BackdropProps={{ classes: { root: classes.backdropRoot } }}
          onClose={() => setOpenTeamsDialog(false)}
        >
          <AddMemberDialog
            members={teams}
            variant="Team"
            dialogTitle="Teams"
            onCancel={() => setOpenTeamsDialog(false)}
            onOk={members => {
              addTeamsToDepartment(members);
              setOpenTeamsDialog(false);
            }}
          />
        </Dialog>

        <DetailList
          listTitle="People"
          members={people}
          avatarVariant="People"
          onItemClick={onListItemClick}
        />

        <AddItem title="People..." onClick={() => setOpenPeopleDialog(true)} />

        <Dialog
          open={openPeopleDialog}
          container={containerRef.current}
          BackdropProps={{ classes: { root: classes.backdropRoot } }}
          onClose={() => setOpenPeopleDialog(false)}
        >
          <AddMemberDialog
            members={people}
            variant="People"
            dialogTitle="People"
            showItems={false}
            onCancel={() => setOpenPeopleDialog(false)}
            onOk={members => {
              addPeopleToDepartment(members);
              setOpenPeopleDialog(false);
            }}
          />
        </Dialog>

        <ContentCard item={cardContent} containerRef={containerRef} />
      </Scrollbar>
    </div>
  );
};

InfoView.propTypes = {
  /** Defines the for the department  */
  description: PropTypes.string,
  /** Callback to be invoked on description change  */
  updateDescription: PropTypes.func,
  /** Defines the list of teams that belong to this department  */
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      mCreatedAt: PropTypes.string,
      description: PropTypes.string,
      handleDrop: PropTypes.func,
    }),
  ),
  /** Callback to be invoked on addTeamsToDepartment dialog confirmation  */
  addTeamsToDepartment: PropTypes.func,
  /** Defines the list of people that belong to this department  */
  people: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
      mCreatedAt: PropTypes.string,
      description: PropTypes.string,
      handleDrop: PropTypes.func,
    }),
  ),
  /** Callback to be invoked on addPeopleToDepartment dialog confirmation  */
  addPeopleToDepartment: PropTypes.func,
};

InfoView.defaultProps = {
  description: '',
  updateDescription: () => {},
  teams: [],
  addTeamsToDepartment: () => {},
  people: [],
  addPeopleToDepartment: () => {},
};

export default InfoView;
