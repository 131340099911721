import React from 'react';
import { Popper, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: 1300,
  },
}));

const PopperView = ({ children, anchorEl, ...rest }) => {
  const classes = useStyles();
  return (
    <Popper
      open={Boolean(anchorEl)}
      placement="top"
      anchorEl={anchorEl}
      className={classes.popper}
      transition
      {...rest}
    >
      {({ TransitionProps }) => <div>{children}</div>}
    </Popper>
  );
};

export default PopperView;
