import { makeStyles } from '@material-ui/styles';

const menuWidth = 232;

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    width: 16,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectWrapper: { width: '100%', position: 'relative' },
  selectRoot: {
    height: 24,
    display: ({ showInput }) => (showInput ? 'none' : 'flex'),
    alignItems: 'center',

    '&$select': { padding: 0 },
  },
  select: {},
  checkIcon: { minWidth: 0, paddingRight: 16 },
  selectInput: {
    width: '100%',
    color: palette.dina.mediumEmphasis,
    ...typography.dina.captionRegular,

    '& $checkIcon': { display: 'none' },
  },
  menu: {
    width: menuWidth,
    padding: 24,
    paddingLeft: 12,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  menuList: {
    width: menuWidth - 12 - 24,
    backgroundColor: palette.dina.surfaceDialogs,
    borderRadius: 4,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2),' +
      '0px 8px 10px 1px rgba(0,0,0,0.14),' +
      '0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  menuItem: {
    ...typography.dina.body2,
    '& $checkIcon svg': { opacity: 0 },
    '&:hover': {
      backgroundColor: palette.dina.whiteHoverOverlay,
    },
    '&$menuItemSelected, &$menuItemSelected:hover, &$menuItemSelected:focus': {
      backgroundColor: palette.dina.onSelected,
      '& $checkIcon svg': {
        opacity: 1,
        transition: 'opacity 1s',
        '& path': { fillOpacity: 1 },
      },
    },
  },
  menuItemSelected: {},
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
  },
}));

export default useStyles;
