/* eslint-disable camelcase */
import React, { useContext } from 'react';
import UserCtx from 'contexts/UserContext';
import { NotificationContext } from 'contexts/NotificationContext';
import { AuthConsumer } from 'contexts/AuthContext';
import useOpenStory from 'hooks/useOpenStory';
import Navbar from './navbar-view';
import Tabs from './tabs';

const NavbarContainer = props => {
  const user = useContext(UserCtx);
  const { attributes } = user;
  const { mTitle, mAvatarUrl } = attributes;
  const { message } = useContext(NotificationContext);
  const openStory = useOpenStory();

  return (
    <AuthConsumer>
      {context => (
        <Navbar
          avatar={mAvatarUrl}
          username={mTitle}
          logout={context.logout}
          tabs={<Tabs />}
          messages={message}
          openStory={openStory}
          {...props}
        />
      )}
    </AuthConsumer>
  );
};

export default NavbarContainer;
