import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, IconButton } from '@material-ui/core';
import { Rnd } from 'react-rnd';
import Divider from 'components/divider';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import close from 'assets/icons/systemicons/close_small.svg';
import useStyles from './advancedDialog-styles';
import LeftMenu from './components/leftMenu';
import Content from './components/content';

const AdvancedDialog = props => {
  const { 
    variant,
    title, 
    subtitle, 
    menuHeaderTitle, 
    contentHeaderTitle, 
    menus, 
    content, 
    onOk, 
    onCancel,
    onLeftMenuItemSelect,
    selectedLeftMenu,
    submitButtonText,
    isDisruptiveAction,
    disruptiveActionText,
    isProcessingSubmit,
    isContentLoading,
    isMenuLoading,
  } = props;

  const [currentWidth, setCurrentWidth] = useState('840px');
  const [currentHeight, setCurrentHeight] = useState('640px');
  const classes = useStyles({ currentHeight, currentWidth });
  const [currentX, setCurrentX] = useState(0);
  const [currentY, setCurrentY] = useState(0);

  const [submitButtonLabel, setSubmitButtonLabel] = useState(submitButtonText);
  const [submitConfirmed, setSubmitConfirmed] = useState(false);

  const handleSubmit = () => {
    if(isDisruptiveAction){
      if(!submitConfirmed){
        setSubmitConfirmed(true);
        setSubmitButtonLabel(disruptiveActionText);
      }
      if(submitConfirmed){
        onOk(content);
        setSubmitConfirmed(false);
        setSubmitButtonLabel(submitButtonText);
      }
    }
    else{
      onOk();
    }
  }

  return (
    <Rnd
      style={{position: 'relative'}}
      size={{ width: currentWidth, height: currentHeight }}
      position={{ x: currentX, y: currentY }}
      onResize={(e, direction, ref, delta, position) => {
        setCurrentHeight(ref.style.height);
        setCurrentWidth(ref.style.width);
        setCurrentX(position.x);
        setCurrentY(position.y);
      }}
      onDrag={(e, d) => {
        setCurrentX(d.x);
        setCurrentY(d.y);
      }}
      minWidth={840}
      minHeight={640}
      dragHandleClassName="dragHandler"
    >
      <div className={classes.root}>
        <form>
          <div className={`${classes.headerContainer} dragHandler`}>
            {subtitle && (
              <div className={classes.headerWithSubHeaderContainer}>
                <Typography className={classes.subHeading}>{subtitle}</Typography>
                <Typography 
                  className={classes.headingWithSubtitle}
                  display='inline-block'
                  noWrap={true}
                >
                  {title}
                </Typography>
              </div>
            )}
            {!subtitle && (
              <Typography className={classes.headingOnly}>
                {title}
              </Typography>
            )}
            <IconButton className={classes.closeButton} onClick={onCancel}>
              <img src={close} alt="close" className={classes.closeImage} />
            </IconButton>
            <Divider className={classes.divider} />
          </div>
          <div className={classes.leftMenu}>
            <div className={classes.leftMenuContainer}>
              <LeftMenu 
                variant={variant}
                height={currentHeight}
                headerTitle={menuHeaderTitle}
                menuItems={menus}
                onItemClick={onLeftMenuItemSelect}
                selectedLeftMenu={selectedLeftMenu}
                loading={isMenuLoading} />
            </div>
          </div>
          <div className={classes.content}>
            <Content 
              headerTitle={contentHeaderTitle}
              content={content}
              variant={variant}
              loading={isContentLoading} />
          </div>
          <div>
            <Divider className={classes.bottomDivider} />
            <div className={classes.footerContainer}>
              <div className={classes.footerButtonContainer}>
                <div className={classes.cancelButtonContainer}>
                  <Button
                    onClick={onCancel}
                    classes={{ root: classes.cancelButton, label: classes.cancelButtonLabel }}
                  >
                    Cancel
                  </Button>
                </div>
                <div className={classes.okButtonContainer}>
                  <Button
                    onClick={handleSubmit}
                    disabled={!selectedLeftMenu}
                    classes={{ root: `${classes.okButton} ${isDisruptiveAction ? (submitConfirmed ? 'confirmWarning' : 'disruptive') : '' }`, label: classes.okButtonLabel }}
                  >
                    {isProcessingSubmit && (<LoadingIndicator />)}
                    {submitButtonLabel}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Rnd>
  );
};

AdvancedDialog.propTypes = {
  /** Title of the dialog */
  title: PropTypes.string,
  /** Title of the dialog */
  subtitle: PropTypes.string,
  /** Title of the menu sidebar */
  menuHeaderTitle: PropTypes.string,
  /** Title of the content header */
  contentHeaderTitle: PropTypes.string,
  /** menu items sidebar */
  menus: PropTypes.arrayOf(PropTypes.object),
  /** content of the dialog */
  content: PropTypes.string,
  /** onClick ok button */
  onOk: PropTypes.func,
  /** onClick cancel button */
  onCancel: PropTypes.func,
  /** variant of advanced dialog */
  variant: PropTypes.string,
  /** submit button text */
  submitButtonText: PropTypes.string,
};

AdvancedDialog.defaultProps = {
  title: 'Title (e.g. "Settings" or "Name of Instance")',
  menuHeaderTitle: 'Header for Menu',
  contentHeaderTitle: 'Header for Content',
  menus: [
    {
      subtitle: 'subtitle(optional)',
      items: ['Menu Item 1', 'Menu Item 2', 'Menu Item 3'],
    },
    {
      subtitle: 'subtitle(optional)',
      items: ['Menu Item 1', 'Menu Item 2', 'Menu Item 3'],
    },
    {
      subtitle: 'subtitle(optional)',
      items: ['Menu Item 1', 'Menu Item 2', 'Menu Item 3'],
    },
    {
      subtitle: 'subtitle(optional)',
      items: ['Menu Item 1', 'Menu Item 2', 'Menu Item 3'],
    },
    {
      subtitle: 'subtitle(optional)',
      items: ['Menu Item 1'],
    },
  ],
  content: 'Placeholder for content',
  onOk: () => {},
  onCancel: () => {},
  variant: 'default',
  subtitle: '',
  submitButtonText: 'Ok'
};

export default AdvancedDialog;
