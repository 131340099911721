const getDayOfYear = date => {
  const onejan = new Date(date.getFullYear(), 0, 1);
  const today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const dayOfYear = (today - onejan + 86400000) / 86400000;
  return Math.ceil(dayOfYear);
};

export const formatDateToLocaleString = (date, returnToday) => {
  if (!date) return '';
  const nDate = new Date();
  const pDate = new Date(date);

  const nDateOfYear = getDayOfYear(nDate);
  const pDateOfYear = getDayOfYear(pDate);
  if (!returnToday) {
    if (nDateOfYear === pDateOfYear) return '';
  }

  const nWday = nDate.getDay();
  const pWday = pDate.getDay();
  const nDay = nDateOfYear - (nWday !== 0 ? nWday : 7);
  const pDay = pDateOfYear - (pWday !== 0 ? pWday : 7);

  const lt = navigator.language;

  const subDays = nDay - pDay;

  let options = {};
  if (subDays < 7 && subDays >= 0) {
    options = { weekday: 'long' };
  }

  if (subDays >= 7 || subDays < 0) {
    options = { weekday: 'short', day: 'numeric', month: 'short' };
  }

  return new Intl.DateTimeFormat(lt, options).format(new Date(date));
};

export const formatDateToLocaleString2 = date => {
  return date;
};
