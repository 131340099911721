import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'utils/capitalize';
import { formatDateToLocaleString } from 'utils/formatDate';
import { Button, Typography } from '@material-ui/core';
import LeftArrowSrc from 'assets/icons/systemicons/disclosurearrow_left.svg';
import useStyles from './header-styles';

const PreviewItem = props => {
  const { title, image, backButtonClicked, publishingtime } = props;
  const classes = useStyles(image)();
  const formatedPublishingTime = capitalize(
    formatDateToLocaleString(publishingtime)
  );
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Button
          variant="text"
          classes={{ root: classes.buttonRoot }}
          onClick={backButtonClicked}
          disableRipple
        >
          <img src={LeftArrowSrc} alt="Go Back" className={classes.icon} />
          Back
        </Button>
      </div>
      <div className={classes.bottom}>
        <div className={classes.image}>
          <img className={classes.img} alt="instance avatar" src={image} />
        </div>
        <div>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.title}>
            {formatedPublishingTime}
          </Typography>
        </div>
      </div>
    </div>
  );
};

PreviewItem.propTypes = {
  /** Rundown title */
  title: PropTypes.string.isRequired,
  /** Publishing time */
  publishingtime: PropTypes.string,
  /** back button click handler */
  backButtonClicked: PropTypes.func,
};

PreviewItem.defaultProps = {
  publishingtime: '',
  backButtonClicked: () => {},
};

export default PreviewItem;
