import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import capitalize from 'utils/capitalize';
import { categoryAssetLoader } from 'utils/categoryIconLoader';
import useStyles from './categoryIndicator-styles';

const CategoryIndicator = props => {
  const { category, disableTooltip, ...rest } = props;
  const { label, categoryId } = category;
  const classes = useStyles(categoryAssetLoader(categoryId).color)();

  return (
    <Tooltip
      title={label && capitalize(label)}
      placement="top"
      classes={{ tooltip: classes.tooltip }}
      disableFocusListener={disableTooltip}
      disableHoverListener={disableTooltip}
      disableTouchListener={disableTooltip}
    >
      <div className={classes.root} {...rest}>
        <div className={classes.backgroundDiv} />
      </div>
    </Tooltip>
  );
};

CategoryIndicator.propTypes = {
  /** An object defining a category */
  category: PropTypes.shape({
    /** Title of category */
    label: PropTypes.string,
    /** Id of the category */
    categoryId: PropTypes.string,
  }),
  /** Disables Showing up category label */
  disableTooltip: PropTypes.bool,
};

CategoryIndicator.defaultProps = {
  category: {},
  disableTooltip: false,
};

export default CategoryIndicator;
