import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  buttonRoot: {
    ...theme.typography.dina.rightColumnHeaderText,
    textTransform: 'none',
    borderRadius: '4px',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {},
  iconButton: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
