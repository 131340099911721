import React, { useState } from 'react';
import Master from './master';
import Detail from './details';
import viewTypes from './viewTypes';

const TeamsView = ({ containerRef }) => {
  const [viewType, setViewType] = useState(viewTypes.MASTER);
  const [selectedItem, setSelectedItem] = useState({});
  const showDetail = item => {
    setSelectedItem(item);
    setViewType(viewTypes.DETAIL);
  };

  const showMaster = () => {
    setViewType(viewTypes.MASTER);
    setSelectedItem(null);
  };

  return viewType === viewTypes.MASTER ? (
    <Master {...{ showDetail, containerRef }} />
  ) : (
    <Detail onBackButtonClick={showMaster} {...selectedItem} {...{ containerRef }} />
  );
};

export default TeamsView;
