/* eslint-disable react/no-array-index-key */
import React from 'react';
import ThumbVideo from './thumbVideo';
import ThumbGraphic from './thumbGraphic';
import useStyles from './thumbnail-style';

const ThumbnailView = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {data.map((thumb, index) => {
        if (thumb.variant === 'video') {
          const { image, duration, item } = thumb;
          return (
            <div key={index} className={classes.thumbnail}>
              <ThumbVideo {...{ image, duration }} data={item} />
            </div>
          );
        }
        if (thumb.variant === 'graphic')
          return (
            <div key={index} className={classes.thumbnail}>
              <ThumbGraphic title={thumb.title} data={thumb.item} />
            </div>
          );
        return null;
      })}
    </div>
  );
};

export default ThumbnailView;
