import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    position: 'relative',
    width: '100%',
    height: 39,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  month: {
    cursor: 'pointer',
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.dina.iconInactive,

    '&.animated-selected': {
      color: 'white',
    },

    '&.static-selected': {
      color: 'white',
      position: 'relative',

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 93,
        height: '100%',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        background: theme.palette.dina.onSelected,
        zIndex: -1,
        borderRadius: 12,
      },
    },
  },
}));

export default useStyles;
