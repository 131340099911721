/* eslint-disable consistent-return */
/**
 *
 * @param {String [][]} array consisting of nested array where
 * each array will consist of string value
 *
 * returns new array by removing nested array with all empty strings value
 *
 */

const FilterEmptyRow = array => {
  return array.reduce((rows, row) => {
    const newRow = row.filter(item => Boolean(item.value.trim()));
    if (newRow.length) rows.push(newRow);
    return rows;
  }, []);
};

export default FilterEmptyRow;
