import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: 24,
    display: 'flex',
    justifyContent: 'flex-end',

    '& div': {
      minWidth: 48,
      borderLeft: `1px solid ${theme.palette.dina.dividerLight}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      lineHeight: '13px',
      letterSpacing: '0.4px',
      color: theme.palette.dina.mediumEmphasis,
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
