import React, { useState, useCallback, useMemo, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { useSlate, ReactEditor, useReadOnly } from 'slate-react';
import configCtx from 'contexts/configContext';
import { elementTypes } from 'components/editor/constants/types';
import SelectedElement from 'components/editor/components/selectedElement';
import AddSrc from 'assets/icons/systemicons/placeholders_add.svg';
import PlaySrc from 'assets/icons/systemicons/Play_WithCircleBackground_small.svg';
import HourglassSrc from 'assets/icons/systemicons/hourglass.svg';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { useSidebarContext } from 'globalState';
import PlaceholderDialog from 'components/editor/components/placeholderDialog';
// eslint-disable-next-line max-len
import removePlaceholder from 'components/editor/components/placeholderDialog/utils/removePlaceholder';
import postMessage from 'utils/postMessage';
import PrimaryDropZone from './components/primaryDropZone';
import MediaDropZone from './components/mediaDropZone';
import AutomationIcon from './components/automationIcon';
import Select from './components/select';
import Menu from '../menu';
import Title from './components/title';
import MediaDialog from './components/mediaDialog';
import DetailsDialog from './components/detailsDialog';
import getInitialData from './utils/getInitialData';
import useStyles from './styles';

const PrimaryAutomation = ({ attributes, children, element }) => {
  const { update } = useEditorContext();
  const readOnly = useReadOnly();
  const { data, type } = element;
  const { itemId } = data;
  const editor = useSlate();
  const classes = useStyles({ readOnly });
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [placeholderDialogOpen, setPlaceholderDialogOpen] = useState(false);
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const initialData = useMemo(() => getInitialData(data), [data]);
  const { assets, templateVariant } = initialData;
  const [assetItem] = assets;
  const { automationTemplateConfigs } = useContext(configCtx);
  const [, dispatch] = useSidebarContext();
  const placeholder = useMemo(
    () => assets.find(({ mediaType }) => mediaType === 'video/placeholder'),
    [assets],
  );

  const hasPlaceholder = Boolean(placeholder);

  const { templates } = automationTemplateConfigs[0]
    ? automationTemplateConfigs[0].templateSets[0]
    : {};

  const template = useMemo(
    () =>
      templates && templates.find(item => item.type === type && item.variant === templateVariant),
    [templateVariant, templates, type],
  );

  const { video = false, graphics = false } = template ? template.requires : {};
  const showCreatePlaceholderOption = Boolean(video);
  const showEditGraphicsOption = Boolean(graphics);

  const menuItems = useMemo(
    () => [
      {
        title: 'Details...',
        action: 'show-details',
        divider: showCreatePlaceholderOption || showEditGraphicsOption,
      },
      ...(showCreatePlaceholderOption
        ? [
            {
              ...(hasPlaceholder
                ? {
                    title: 'Remove Placeholder',
                    action: 'remove-placeholder',
                  }
                : { title: 'Create Placeholder', action: 'create-placeholder' }),
              divider: showEditGraphicsOption,
            },
          ]
        : []),
      ...(showEditGraphicsOption
        ? [
            {
              title: 'Edit graphics',
              action: 'edit-graphics',
            },
          ]
        : []),
    ],
    [hasPlaceholder, showCreatePlaceholderOption, showEditGraphicsOption],
  );

  /* Determine when to show add button, functionality to be implemented */
  const showAdd = false;

  const openDetailsDialog = useCallback(() => setDetailsDialogOpen(true), []);

  const closeDetailsDialog = useCallback(() => setDetailsDialogOpen(false), []);

  const openPlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(true), []);

  const closePlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(false), []);

  const openMediaDialog = useCallback(() => setMediaDialogOpen(true), []);

  const closeMediaDialog = useCallback(() => setMediaDialogOpen(false), []);

  const handleEditGraphics = () => {
    if (!assetItem && assetItem.provider !== 'PILOT') return;
    const message = assetItem.mosobj;
    dispatch({
      type: 'openRightSidebar',
      payload: false,
    });
    dispatch({
      type: 'setRightSelection',
      payload: 'graphicsbox',
    });
    if (message) {
      postMessage(message, 'pilotedge');
    }
  };

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'show-details') openDetailsDialog();

      if (action === 'edit-graphics') handleEditGraphics();

      if (action === 'create-placeholder') openPlaceholderDialog();

      if (action === 'remove-placeholder')
        removePlaceholder(editor, initialData, placeholder, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialData, placeholder, openDetailsDialog, openPlaceholderDialog],
  );

  const [index] = ReactEditor.findPath(editor, element);

  const renderContent = useCallback(
    provided => {
      const { draggableProps, innerRef, dragHandleProps } = provided;

      return (
        <div {...attributes}>
          <div ref={innerRef} {...draggableProps}>
            <SelectedElement {...{ element }}>
              <PrimaryDropZone {...{ element }}>
                <MediaDropZone {...{ element }}>
                  {children}
                  <div contentEditable={false} className={classes.root} {...dragHandleProps}>
                    <div className={classes.box}>
                      <AutomationIcon {...{ type }} />

                      <div className={classes.content}>
                        <Select {...{ initialData, element }} />
                        <div className={classes.titleBox}>
                          <Title {...{ initialData, type, templates }} />
                        </div>

                        <div className={classes.placheholders}>
                          {(showAdd || assetItem) && (
                            <>
                              {showAdd && <img alt="add" src={AddSrc} />}

                              {hasPlaceholder && (
                                <div className={classes.asset}>
                                  <img
                                    alt="placeholder"
                                    className={classes.icon}
                                    src={HourglassSrc}
                                  />
                                </div>
                              )}

                              {!hasPlaceholder && assetItem && (
                                <div
                                  className={classes.asset}
                                  onClick={openMediaDialog}
                                  role="presentation"
                                >
                                  {assetItem.thumbnailUrl && (
                                    <img
                                      src={assetItem.thumbnailUrl}
                                      alt="asset"
                                      className={classes.thumbnail}
                                    />
                                  )}

                                  <img alt="play" className={classes.icon} src={PlaySrc} />
                                </div>
                              )}
                            </>
                          )}
                        </div>

                        <Menu className={classes.menu} items={menuItems} onSelect={onMenuSelect} />
                      </div>
                    </div>
                  </div>
                </MediaDropZone>
              </PrimaryDropZone>
            </SelectedElement>
          </div>
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      attributes,
      element,
      children,
      type,
      initialData,
      templates,
      showAdd,
      assetItem,
      hasPlaceholder,
      menuItems,
      onMenuSelect,
    ],
  );

  return (
    <div>
      <Draggable draggableId={itemId} {...{ index }}>
        {renderContent}
      </Draggable>

      {detailsDialogOpen && (
        <DetailsDialog
          open={detailsDialogOpen}
          onClose={closeDetailsDialog}
          {...{ initialData, type, element }}
        />
      )}

      {placeholderDialogOpen && (
        <PlaceholderDialog
          open={placeholderDialogOpen}
          onClose={closePlaceholderDialog}
          {...{ element }}
        />
      )}

      {mediaDialogOpen && (
        <MediaDialog asset={assetItem} open={mediaDialogOpen} onClose={closeMediaDialog} />
      )}
    </div>
  );
};

PrimaryAutomation.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

PrimaryAutomation.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.CAMERA,
    children: [],
  },
};

export default memo(PrimaryAutomation);
