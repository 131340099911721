import React from 'react';
import AddMorePeopleSrc from 'assets/icons/systemicons/AddMorePeople.svg';
import Container from '../container/Container';
import useStyles from './placeholder-styles';

const Placeholder = props => {
  const classes = useStyles();

  return (
    <Container {...props}>
      <img src={AddMorePeopleSrc} alt="placeholder" className={classes.root} />
    </Container>
  );
};

export default Placeholder;
