import { Editor, Transforms, Range } from 'slate';
import { elementTypes } from 'components/editor/constants';

const { removeNodes, insertNodes, wrapNodes, unwrapNodes, collapse } = Transforms;
const { LINK } = elementTypes;

/**
 * Wraps given text in link node
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} href URL of the link
 * @param {String} linkText Text for the link
 * @returns {Object} SlateJS editor instance
 */

const isLink = ({ type }) => type === LINK;

const wrapLink = (editor, href, linkText) => {
  const [match] = Editor.nodes(editor, { match: isLink });
  const isActive = Boolean(match);
  const activeLinkText = isActive && match[0].children[0].text;

  if (isActive && !href) return unwrapNodes(editor, { match: isLink });

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);

  const element = {
    type: LINK,
    data: { href },
    children: isCollapsed ? [{ text: linkText || activeLinkText || href }] : [],
  };

  if (!isActive && isCollapsed) insertNodes(editor, element);
  else if (!isCollapsed) {
    unwrapNodes(editor, { match: isLink });
    wrapNodes(editor, element, { split: true });
    collapse(editor, { edge: 'end' });
  } else {
    removeNodes(editor, { match: isLink });
    insertNodes(editor, element);
  }

  return editor;
};

export default wrapLink;
