import { makeStyles } from '@material-ui/styles';

const commonButtonStyle = theme => {
  return {
    ...theme.typography.dina.listItemLabelMedium,
    color: theme.palette.dina.highEmphasis,
    textTransform: 'none',
    borderRadius: 0,
    width: '132px',
    boxShadow: 'none',
    justifyContent: 'flex-start',
    padding: '0px',
  };
};

const useStyles = makeStyles(theme => ({
  buttonContanier: {
    display: 'flex',
    flexDirection: 'row',
    height: '32px',
  },
  removeRoot: {
    ...commonButtonStyle(theme),
    backgroundColor: theme.palette.dina.statusError,
    '&:hover': {
      '-webkit-filter': theme.palette.dina.whiteHoverOverlay,
      backgroundColor: theme.palette.dina.statusError,
    },
  },
  emptyRoot: {
    ...commonButtonStyle(theme),
    backgroundColor: theme.palette.dina.statusWarning,
    '&:hover': {
      '-webkit-filter': theme.palette.dina.whiteHoverOverlay,
      backgroundColor: theme.palette.dina.statusWarning,
    },
  },
  createRoot: {
    ...commonButtonStyle(theme),
    width: '138px',
    backgroundColor: theme.palette.dina.statusApproved,
    '&:hover': {
      '-webkit-filter': theme.palette.dina.whiteHoverOverlay,
      backgroundColor: theme.palette.dina.statusApproved,
    },
  },
  openRoot: {
    ...commonButtonStyle(theme),
    backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
    '&:hover': {
      '-webkit-filter': theme.palette.dina.whiteHoverOverlay,
      backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
    },
  },
  floatRoot: {
    ...commonButtonStyle(theme),
    backgroundColor: theme.palette.dina.buttonBackgroundCyan,
    '&:hover': {
      '-webkit-filter': theme.palette.dina.whiteHoverOverlay,
      backgroundColor: theme.palette.dina.buttonBackgroundCyan,
    },
  },
  iconButton: {
    padding: 0,
    paddingLeft: '2px',
    width: 32,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  closeIcon: {
    '&:hover': {
      '& path': {
        fillOpacity: 1,
      },
    },
  },
  icon: {
    marginRight: '4px',
    marginLeft: '8px',
  },
}));

export default useStyles;
