/**
 * Hook for highlighting text. Can i.e be used for highlighting searched words
 *
 */

import { findAll } from 'highlight-words-core';

function useHighLightWords() {
  const highlightWords = (textToHighlight = '', searchWords = []) => {
    searchWords = searchWords.map(word => {
      // findAll function does not handle strings starting with: *
      return word.replace(/[\x00-\x1f?./*\\]+/g, '');
    });

    const chunks = findAll({
      searchWords,
      textToHighlight,
    });

    const highlightedText = chunks
      .map(({ end, highlight, start }) => {
        const text = textToHighlight.substr(start, end - start);
        if (highlight) {
          return `<mark>${text}</mark>`;
        }
        return text;
      })
      .join('');

    return highlightedText;
  };

  return highlightWords;
}

export default useHighLightWords;
