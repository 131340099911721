import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  mandatory: {
    width: '4px',
    height: '100%',
    backgroundColor: theme.palette.dina.statusWarning,
    borderRadius: '0px 2px 2px 0px',
  },
  regular: {
    width: '4px',
    height: '100%',
    backgroundColor: 'transparent',
  },
}));

const SeverityIndicator = ({ severity, ...rest }) => {
  const classes = useStyles();
  return <div className={classes[severity]} {...rest} />;
};

SeverityIndicator.propTypes = {
  /** defined the color of indicator depeding on severity */
  severity: PropTypes.string,
};

SeverityIndicator.defaultProps = {
  severity: 'regular',
};

export default SeverityIndicator;
