import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minWidth: '960px',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftComponents: {
    display: 'flex',
    alignItems: 'center',
  },
  rightComponents: {
    display: 'flex',
    alignItems: 'center',
  },
  switchBox: {
    minWidth: '230px',
    display: 'flex',
    alignItems: 'center',
  },
  switchText: {
    ...theme.typography.dina.captionRegular,
    letterSpacing: '0.25px',
    color: theme.palette.dina.highEmphasis,
    cursor: 'pointer',
  },
  timeNavigator: {
    marginRight: '16px',
  },
  settings: {
    marginLeft: '14px',
  },
}));

export default useStyles;
