/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_off.svg';
import { ReactComponent as CalendarOn } from 'assets/icons/systemicons/calendar_on.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/systemicons/time.svg';
import { ReactComponent as UnionIcon } from 'assets/icons/systemicons/Union.svg';
import Divider from 'components/divider';
import Select from 'components/select';
import Avatar from 'components/avatar';
import Popover from 'components/popover';
import RadioButton from 'components/buttons/radioButton';
import { isPast, isThisMinute } from 'date-fns';
import getRundownPublishingTime from 'utils/getRundownPublishingTime';
import useGetPlatforms from 'hooks/useGetPlatforms';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Picker from './picker-view';
import useStyles from './publishSettings-styles';

const getProperties = variant => {
  if (variant === 'Linear') {
    return {
      subTitleText: 'Account',
      isLinear: true,
      pickerClassName: 'timePickerDisabled',
    };
  }
  return {
    subTitleText: '     Account',
    isLinear: false,
    pickerClassName: 'timePicker',
  };
};

const PublishSettings = ({
  variant,
  instanceType,
  selectedDestination,
  publishingTime,
  onOK,
  onCancel,
  anchorEl,
}) => {
  /**
   * Returns the initial timestamp to be used to set the time value
   * If the initial publishing time is in the past, the value is set to now().
   */
  const getInitialTimeISOString = forceReset => {
    const idatestr = publishingTime || selectedDestination.publishingTime;
    if (!idatestr || forceReset) return new Date().toISOString();
    const pdate = new Date(idatestr);
    const idate = isPast(pdate) ? new Date() : pdate;
    return idate.toISOString();
  };

  /**
   * Returns true if the date indicates to publish now.
   * This is true for timestamps in the past or within the current minute
   * @param {string} tstr - time as ISO string
   */
  const scheduleNow = tstr => {
    const date = new Date(tstr);
    return isPast(date) || isThisMinute(date);
  };

  const [newPublishingTime, setNewPublishingTime] = useState(getInitialTimeISOString());

  const [publishNow, setPublishNow] = useState(scheduleNow(newPublishingTime));

  const [selectedRadioButton, setSelectedRadioButton] = useState(
    publishingTime ? 'schedule' : 'unschedule',
  );

  const [confirmPublish, setConfirmPublish] = useState(false);

  const [, error, loading, destinations] = useGetPlatforms(
    newPublishingTime,
    instanceType,
    !anchorEl,
  );

  const items = destinations.map(item => ({
    ...item,
    icon: <Avatar size={24} publishingPoint={instanceType} variant="Publish" />,
  }));

  const [selectedValue, setSelectedValue] = useState(
    selectedDestination.value || (destinations.length > 0 && destinations[0].value),
  );

  const { subTitleText, isLinear, pickerClassName } = getProperties(variant);

  const onResetDateTime = () => {
    setNewPublishingTime(getInitialTimeISOString(true));
  };
  const getLinearPublishingTime = (destination, date) => {
    const { startTime, timeZone } = destination;
    return getRundownPublishingTime(new Date(date), startTime, timeZone);
  };

  const handleOK = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!isLinear && selectedRadioButton !== 'unschedule' && publishNow && !confirmPublish) {
      setConfirmPublish(true);
      return;
    }
    const destination = destinations.find(d => d.value === selectedValue);

    if (isLinear && !destination.id) {
      onOK({
        selectedDestination: destination,
        publishingTime: null,
      });
      return;
    }

    onOK({
      selectedDestination: destination,
      // eslint-disable-next-line no-nested-ternary
      publishingTime: isLinear
        ? getLinearPublishingTime(destination, newPublishingTime)
        : selectedRadioButton === 'unschedule'
        ? null
        : newPublishingTime,
    });
  };

  const handleCancel = event => {
    event.preventDefault();
    event.stopPropagation();
    setNewPublishingTime(getInitialTimeISOString());
    setConfirmPublish(false);
    onCancel();
  };

  /**
   * Sets the time component of given timestamp
   * @param {string} tiso - timestamp (ISO string)
   * @param {Date} tvalue - date with time information
   * @returns updated timestamp (ISO string)
   */
  const setHoursIsoString = (tiso, tvalue) => {
    const ttime = new Date(tiso);
    const dValue = new Date(tvalue);
    ttime.setHours(
      dValue.getHours(),
      dValue.getMinutes(),
      dValue.getSeconds(),
      dValue.getMilliseconds(),
    );
    const ntime = isPast(ttime) ? new Date() : ttime;
    return ntime.toISOString();
  };

  /**
   * Sets the date component of given timestamp
   * @param {string} tiso - timestamp (ISO string)
   * @param {Date} tvalue - date with date information
   * @returns updated timestamp (ISO string)
   */
  const setDateIsoString = (tiso, tvalue) => {
    const ttime = new Date(tiso);
    const dValue = new Date(tvalue);
    ttime.setFullYear(dValue.getFullYear());
    ttime.setMonth(dValue.getMonth());
    ttime.setDate(dValue.getDate());
    return ttime.toISOString();
  };

  const handleDateChange = value => {
    if (!value) return;
    setNewPublishingTime(setDateIsoString(newPublishingTime, value));
  };

  const handleTimeChange = value => {
    if (!value) return;

    setNewPublishingTime(setHoursIsoString(newPublishingTime, value));
  };

  const onDestinationChange = value => {
    setSelectedValue(value);
  };

  useEffect(() => {
    setPublishNow(scheduleNow(newPublishingTime));
    !selectedValue &&
      setSelectedValue(
        selectedDestination.value || (destinations.length > 0 && destinations[0].value),
      );
  }, [newPublishingTime, selectedDestination, destinations, selectedValue]);

  const shouldDisablePublish = destinations.some(
    destination => destination.value === selectedValue,
  );

  const getHeight = () => {
    if (isLinear) return { height: '341px' };
    return selectedRadioButton === 'unschedule' || isLinear
      ? { height: '298px' }
      : { height: '397px' };
  };
  const [height, setHeight] = useState(getHeight());

  useLayoutEffect(() => {
    setHeight(getHeight);
  }, [selectedRadioButton, isLinear]);

  const classes = useStyles({ height });

  if (error) return <div>{error.message}</div>;
  return (
    <Popover anchorEl={anchorEl} position="publish-setting-custom" onClose={handleCancel}>
      <div className={classes.container} style={height}>
        <div className={classes.topComponents}>
          <Typography classes={{ root: classes.title }}>Schedule Settings</Typography>
          <Divider />
          <Typography classes={{ root: classes.subTitle }}>{subTitleText}</Typography>
          {loading && <LoadingIndicator />}
          <div className={classes.selectContainer}>
            <Select
              items={items}
              hideLabel
              selectedValue={selectedValue || null}
              onChange={onDestinationChange}
            />
          </div>

          {!isLinear && (
            <div className={classes.radioButtonContainer}>
              <div className={classes.unscheduleRadioButton}>
                <RadioButton
                  value="unschedule"
                  selected={selectedRadioButton === 'unschedule'}
                  onClick={value => {
                    setSelectedRadioButton(value);
                    setConfirmPublish(false);
                  }}
                  size={22}
                />
                <span className={classes.radioText}>Unschedule</span>
              </div>

              <div className={classes.scheduleRadioButton}>
                <RadioButton
                  value="schedule"
                  selected={selectedRadioButton === 'schedule'}
                  onClick={value => setSelectedRadioButton(value)}
                  size={22}
                />
                <span className={classes.radioText}>Schedule or publish</span>
              </div>
            </div>
          )}

          {!isLinear && selectedRadioButton === 'schedule' ? (
            <>
              <div className={classes.dateTimePicker}>
                <div className={classes.datePicker}>
                  <Picker
                    Icon={CalendarOff}
                    SecondaryIcon={CalendarOn}
                    displaySecondaryIcon={publishingTime || !isThisMinute(newPublishingTime)}
                    type="date"
                    label="Publish Date"
                    timeValue={newPublishingTime}
                    onChange={handleDateChange}
                  />
                </div>
                <div className={classes[pickerClassName]}>
                  <Picker
                    Icon={TimeIcon}
                    type="time"
                    label="Publish Time"
                    timeValue={newPublishingTime}
                    disable={isLinear}
                    onChange={handleTimeChange}
                  />
                </div>
              </div>
              <div className={classes.resetDiv}>
                <span className={classes.unionIcon}>
                  <UnionIcon />
                </span>
                <span className={classes.resetText}>
                  <Typography
                    classes={{ root: classes.reset }}
                    onClick={onResetDateTime}
                    disabled={publishNow}
                  >
                    Set to Current Time
                  </Typography>
                </span>
              </div>
            </>
          ) : (
            <div className={classes.blank} />
          )}

          {isLinear && (
            <>
              <div className={classes.linearDateTimePicker}>
                <div className={classes.linearDatePicker}>
                  <Picker
                    Icon={CalendarOff}
                    SecondaryIcon={CalendarOn}
                    displaySecondaryIcon={publishingTime || !isThisMinute(newPublishingTime)}
                    type="date"
                    label="Publish Date"
                    timeValue={newPublishingTime}
                    onChange={handleDateChange}
                  />
                </div>
              </div>

              <div className={classes.linearResetDiv}>
                <span className={classes.unionIcon}>
                  <UnionIcon />
                </span>
                <span className={classes.resetText}>
                  <Typography
                    classes={{ root: classes.reset }}
                    onClick={onResetDateTime}
                    disabled={publishNow}
                  >
                    Set to Today
                  </Typography>
                </span>
              </div>
            </>
          )}
        </div>
        <div className={classes.footer}>
          <Divider />
          <div className={classes.buttonContainer}>
            <Button className={classes.cancelButton} onClick={handleCancel}>
              Cancel
            </Button>
            {!isLinear && selectedRadioButton === 'unschedule' ? (
              <Button className={classes.okButton} onClick={handleOK}>
                Ok
              </Button>
            ) : (
              <Button
                className={
                  publishNow && !isLinear && !confirmPublish
                    ? classes.publishButton
                    : publishNow && !isLinear && confirmPublish
                    ? classes.confirmPublishButton
                    : classes.scheduleButton
                }
                onClick={handleOK}
                disabled={!shouldDisablePublish}
              >
                {publishNow && !isLinear && !confirmPublish
                  ? 'Publish now'
                  : publishNow && !isLinear && confirmPublish
                  ? 'Click again to Publish'
                  : 'Schedule'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Popover>
  );
};

PublishSettings.propTypes = {
  /** Variant of component */
  variant: PropTypes.oneOf(['Linear', 'General', 'Twitter', 'Cms', 'Youtube']),
  /** Publishing time of the instance */
  publishingTime: PropTypes.string,
  /** Type of the instance */
  instanceType: PropTypes.string,
  /** Selected destination of this instance */
  selectedDestination: PropTypes.shape({}),
  /** Callback to be invoked when ok button is clicked */
  onOK: PropTypes.func,
  /** Callback to be invoked when cancel button is clicked */
  onCancel: PropTypes.func,
  /** Default DOM element to anchor */
  anchorEl: PropTypes.objectOf(PropTypes.object),
};

PublishSettings.defaultProps = {
  variant: 'General',
  publishingTime: '',
  instanceType: '',
  selectedDestination: {},
  onOK: () => {},
  onCancel: () => {},
  anchorEl: null,
};

export default PublishSettings;
