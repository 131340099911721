import React, { createContext, useState } from 'react';

export const UsersContext = createContext({});

export function UsersProvider ({ children }) {
  const [users, setUsers] = useState([]);

  const setUsersHandler = (data) => {
    console.log(data);
    setUsers(data);
  }

  return (
    <UsersContext.Provider value={{ users, 
    setUsers: setUsersHandler }}>
      {children}
    </UsersContext.Provider>
  );
};