import gql from 'graphql-tag';

export default gql`
  query GetMember($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mAvatarUrl
      mAvatarKey
      mCreatedAt
      mUpdatedAt
      locked
      mProperties {
        __typename
        ... on ContactType {
          firstName
          surname
          email
          phone
          dateOfBirth
          jobTitle
          readSpeed
        }
      }
    }
  }
`;
