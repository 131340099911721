import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import GET_RUNDOWN_INSTANCES from 'graphql/queries/getInstancesOfRundownOrder';
import UPDATE_RUNDOWN_SYNC from 'graphql/mutations/updateRundownSync';
import { timeToSeconds } from 'screens/rundown/utils/updateDuration';
import ListHeaderView from './gridHeader-view';
import rundownListTypes from '../../utils/rundownListTypes';
import respectHostReadSpeed from '../../../utils/respectHostReadSpeed';

const HeaderContainer = props => {
  const { mid, refId, selecteddate, listHeaderTitle, mOrder, data, type, hostReadSpeed } = props;
  const [plannedDuration, setPlannedDuration] = useState();
  const [actualDuration, setActualDuration] = useState();
  const queryInitializedRef = useRef(false);

  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);
  const durationField =
    type === rundownListTypes.READY ? 'mPlannedDuration' : 'mPrePlannedDuration';

  const orderField = type === rundownListTypes.READY ? 'mOrder' : 'mPreorder';

  const { data: instancesData, refetch } = useQuery(GET_RUNDOWN_INSTANCES, {
    variables: {
      input: {
        mId: data.getRundown.mId,
        mRefId: orderField,
      },
    },
    fetchPolicy: 'network-only',
  });

  const updateRundown = plan => {
    const payloadInput = {
      crudAction: 'UPDATE',
    };
    const mPayload = [payloadInput];
    const input = {
      mId: mid,
      mRefId: refId,
      mPayload,
    };
    if (plan) input[durationField] = plan;
    const variables = { input };
    updateRundownSync({ variables });
  };

  const calculateAndSetDuration = instances => {
    let duration = 0;
    instances.forEach(instance => {
      if (instance) {
        const { mMetaData } = instance;
        const isFloatCheck = () => {
          const floatField = mMetaData.find(field => field.key.includes('isFloat'));
          if (floatField) {
            try {
              return JSON.parse(floatField.value);
            } catch (err) {
              return false;
            }
          }
          return false;
        };
        if (mMetaData && !isFloatCheck()) {
          respectHostReadSpeed(mMetaData, hostReadSpeed).forEach(meta => {
            if (meta.key.includes('total-duration')) duration += timeToSeconds(meta.value);
          });
        }
      }
    });

    setActualDuration(duration * 1000);
    return duration * 1000;
  };

  useEffect(() => {
    if (instancesData) {
      queryInitializedRef.current = true;
      calculateAndSetDuration(instancesData.rundownInstances);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instancesData]);

  const handlePlannedDurationChange = newDuration => {
    setPlannedDuration(newDuration);
    updateRundown(newDuration);
  };

  useEffect(() => {
    setPlannedDuration(Number(data.getRundown[durationField] || 0));
    if (queryInitializedRef.current) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mOrder]);

  useEffect(() => {
    queryInitializedRef.current = false;
  }, [mid]);

  return (
    <ListHeaderView
      updatePlannedDuration={handlePlannedDurationChange}
      selectedDate={selecteddate}
      actualDuration={actualDuration}
      plannedDuration={plannedDuration}
      {...{
        listHeaderTitle,
      }}
      {...props}
    />
  );
};

export default HeaderContainer;
