import React from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'components/scrollbar';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import ItemTable from './itemTable';
import useStyles from './panel-styles';

const Panel = props => {
  const classes = useStyles();
  const { items, onInspect, loading, fetchMoreResults } = props;

  return (
    <div className={classes.panel}>
      {loading ? (
        <div className={classes.loadingIndicatorContainer}>
          <LoadingIndicator />
        </div>
      ) : null}
      <Scrollbar closeToBottom={fetchMoreResults}>
        <ItemTable items={items} {...{ onInspect, loading }} />
      </Scrollbar>
    </div>
  );
};

Panel.propTypes = {
  /** feed items */
  items: PropTypes.arrayOf(PropTypes.object),
  /** inspect element function */
  onInspect: PropTypes.func,
  /** data loading? */
  loading: PropTypes.bool,
  /** fetchMoreResults function */
  fetchMoreResults: PropTypes.func,
};

Panel.defaultProps = {
  items: [],
  onInspect: () => {},
  loading: false,
  fetchMoreResults: () => {},
};

export default Panel;
