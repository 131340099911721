const tokenName = 'lockToken';

const getUserLockToken = userId => {
  const lockToken = window.sessionStorage.getItem(tokenName);
  if (lockToken) return lockToken;
  
  const timeStamp = new Date().toISOString();
  const token = `${userId}||${timeStamp}`;
  window.sessionStorage.setItem(tokenName, token);

  return token;
};

const resetLockToken = () => {
  window.sessionStorage.setItem(tokenName, '');
};

const getUserIdFromLockedId = lockedId => {
  if (!lockedId) return null;
  return lockedId.split('||')[0];
};

export { getUserLockToken, getUserIdFromLockedId, resetLockToken };
