import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    marginLeft: '8px',
    marginTop: '6px',
    width: '100%',
  },
  dragHandle: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: 0,
    bottom: '10px',
  },
}));

export default useStyles;
