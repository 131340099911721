import React, { createContext, useState } from 'react';

export const EditorContext = createContext();

const EditorProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editorLockedData, setEditorLockedData] = useState({
    mId: null,
    mType: null,
    locked: null,
  });
  return (
    <EditorContext.Provider
      value={[
        isModalOpen,
        setIsModalOpen,
        editorLockedData,
        setEditorLockedData,
      ]}
    >
      {children}
    </EditorContext.Provider>
  );
};

export { EditorProvider };
