import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: 'transparent',
    color: theme.palette.dina.iconInactive,
    width: 48,
    height: 48,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.dina.highEmphasis,
      '& > :first-child': {
        '& > :first-child': {
          '& path': {
            fillOpacity: 1,
          },
        },
      },
    },
  },
  clicked: {
    backgroundColor: 'transparent',
    width: 48,
    height: 48,
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'white',
      '& > :first-child': {
        '& > :first-child': {
          '& path': {
            fillOpacity: 1,
          },
        },
      },
    },
  },
  list: {
    backgroundColor: theme.palette.dina.fabBackground,
    color: theme.palette.dina.iconInactive,
    zIndex: 999,
    '&:hover': {
      backgroundColor: theme.palette.dina.fabBackground,
      color: theme.palette.dina.highEmphasis,
    },
  },
  tooltip: {
    textShadow:
      '0px 8px 8px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    ...theme.typography.dina.listItemLabel,
  },
}));

export default useStyles;
