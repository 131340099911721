import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { IconButton } from '@material-ui/core';
import Thumbnail from 'components/thumbnail';
import drag from 'assets/icons/systemicons/drag.svg';
import { mediaTypes } from 'utils/rundownItemTypes';
import useStyles from './thumbVideo-styles';

const ThumbVideo = props => {
  const { data, image, duration, onClose } = props;
  const classes = useStyles({ image });

  const [, dragRef] = useDrag({
    item: { type: mediaTypes.CLIP, payload: data },
  });

  return (
    <div className={classes.container} ref={dragRef}>
      <Thumbnail
        hidePlayButton
        videoThumbnailImage={image}
        width={96}
        height={64}
        type="video"
        duration={duration}
        usage="scratchPad"
        onClose={onClose}
      />
      <IconButton className={classes.dragButton}>
        <img src={drag} alt="drag" className={classes.dragImage} />
      </IconButton>
    </div>
  );
};

ThumbVideo.propTypes = {
  /** duration of the video */
  duration: PropTypes.number,
  /** Thumbnail of the video */
  image: PropTypes.string,
  /** Callback to be invoked on close button click */
  onClose: PropTypes.func,
};

ThumbVideo.defaultProps = {
  duration: 0,
  image: null,
  onClose: () => {},
};

export default ThumbVideo;
