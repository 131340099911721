import gql from 'graphql-tag';

export default gql`
  mutation UpdateRundown($input: UpdateRundownInput) {
    updateRundown(input: $input) {
      mTitle
      mOrder
      mPreorder
      mId
      mRefId
      mState
      mPreparingState
      mPlannedDuration
      mPrePlannedDuration
      mPublishingAt
      version
      recurrence {
        duration
        startTime
        timeZone
        start
      }
      mMetaData {
        key
        value
      }
    }
  }
`;
