import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Divider from 'components/divider';
import { publishingPoints as publishingPointIcons } from 'assets/icons/publishingPoints';
import { ReactComponent as ArrowRightSmall } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import uuidv1 from 'uuid/v1';
import RundownIcon, { iconUsage } from 'components/rundownIcons';
import useStyles from './instanceItem-styles';

const PreviewItem = ({ title, image, showFocus, items, timingInfo, showDivider, onClick }) => {
  const classes = useStyles();

  return (
    <div
      role="presentation"
      className={`${classes.root} ${showFocus ? classes.showFocus : ''}`}
      onClick={onClick}
    >
      <div className={classes.avatar}>
        <img src={image} alt="rundown-thumbnail" className={classes.thumbnail} />
        <img
          className={classes.publishingPoint}
          src={publishingPointIcons.linear}
          alt="publishing-point"
        />
      </div>
      <div className={classes.centerContent}>
        <Typography className={classes.title}>{title}</Typography>
        <div className={classes.icons}>
          {items &&
            items.length > 0 &&
            items.map((item, index) => (
              <div key={uuidv1()} className={index > 0 ? classes.icon : classes.firstIcon}>
                <RundownIcon variant={item.title} width={16} height={16} usage={iconUsage.META} />
              </div>
            ))}
        </div>
      </div>
      <div className={classes.timingDiv}>
        <Typography className={classes.timingText}>{timingInfo}</Typography>
        <ArrowRightSmall />
      </div>
      {showDivider && <Divider className={classes.divider} />}
    </div>
  );
};

PreviewItem.propTypes = {
  /** Instance title */
  title: PropTypes.string,
  /** Url for thumbnail */
  image: PropTypes.string,
  /** automation items for that instance */
  items: PropTypes.arrayOf(PropTypes.object),
  /** last updated time */
  timingInfo: PropTypes.string,
};

PreviewItem.defaultProps = {
  title: '',
  image: fallbackImage,
  items: [],
  timingInfo: '',
};

export default PreviewItem;
