/* eslint-disable max-len */
import React, { createContext, useState, useEffect } from 'react';
import BREAKING_FEED from 'graphql/subscriptions/notifyBreakingFeeds';
import useApolloSubscription from 'hooks/useApolloSubscription';

export const BreakingFeedContext = createContext([]);

const BreakingFeedProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(0);

  const onBreakingFeedData = ({ client, subscriptionData }) => {
    setNotifications(notifications => notifications + 1);
  };

  const [subscribe, unsubscribe] = useApolloSubscription(BREAKING_FEED, {
    variables: {},
    onSubscriptionData: onBreakingFeedData,
  });

  const clearNotificationCount = () => {
    setNotifications(0);
  };

  useEffect(() => {
    subscribe();
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <BreakingFeedContext.Provider value={[notifications, clearNotificationCount]}>
      {children}
    </BreakingFeedContext.Provider>
  );
};
export default BreakingFeedProvider;
