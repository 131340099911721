import gql from 'graphql-tag';

export default gql`
  mutation updateInstanceOrder($input: BatchUpdateInstanceInput) {
    batchUpdateInstance(input: $input) {
      mId
      mRefId
      mTitle
      mState
      mMetaData {
        key
        value
      }
    }
  }
`;
