import React from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItem } from '@material-ui/core';
import Divider from 'components/divider';
import useStyles from './show-item-styles';

const ShowItem = ({ title, content, onClick }) => {
  const classes = useStyles();

  return (
    <div>
      <ListItem
        button
        classes={{ root: classes.listItem, button: classes.button }}
        {...{ onClick }}
      >
        <Typography classes={{ root: classes.title }}>{title}</Typography>
        <Typography classes={{ root: classes.content }}>{content}</Typography>
      </ListItem>

      <div className={classes.dividerWrapper}>
        <Divider />
      </div>
    </div>
  );
};

ShowItem.propTypes = {
  /** Defines the title of the item  */
  title: PropTypes.string,
  /** Defines the content of the item  */
  content: PropTypes.node,
};

ShowItem.defaultProps = {
  title: 'title',
  content: 'content',
};

export default ShowItem;
