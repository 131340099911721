/**
 * 
 * @param {*} form Metadata form
 * returns empty metadata field value for a form 
 */
const getEmptyMetadataForForm = (form) => {
    if(!(form && form.fields)) return [];

    return form.fields.map(({ id: key, value }) => ({
        key,
        value,
      }));
}

export default getEmptyMetadataForForm;