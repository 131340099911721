import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SplitPane from 'react-split-pane';
import theme from 'theme/theme';
import useStyles from './splitView-styles';

const SplitView = ({ pane1, pane2, updatePaneHeight }) => {
  const [dragColor, setDragColor] = useState(
    theme.palette.dina.whiteHoverOverlay
  );
  const classes = useStyles({ dragColor });
  return (
    <SplitPane
      className={classes.splitPane}
      style={{
        position: 'relative',
        overflowX: 'auto',
        minWidth: '860px',
      }}
      split="horizontal"
      minSize={45}
      maxSize={-55}
      pane1Style={{
        background: 'linear-gradient(218.14deg, #516379 -3.77%, #415063 100%)',
      }}
      pane2Style={{
        background: 'linear-gradient(218.14deg, #516379 -3.77%, #415063 100%)',
      }}
      defaultSize="50%"
      onDragStarted={() => {
        setDragColor(theme.palette.dina.onFocus);
      }}
      onDragFinished={size => {
        // on drag finished size returns the height of the first pane
        setDragColor(theme.palette.dina.whiteHoverOverlay);
        updatePaneHeight(size);
      }}
    >
      {pane1}
      {pane2}
    </SplitPane>
  );
};

SplitView.propTypes = {
  /** pane1 component of the split pane */
  pane1: PropTypes.element,
  /** pane2 component of the split pane */
  pane2: PropTypes.element,
  /** fucntion for setting the height on drag finished */
  updatePaneHeight: PropTypes.func,
};

SplitView.defaultProps = {
  pane1: <div />,
  pane2: <div />,
  updatePaneHeight: () => {},
};
export default SplitView;
