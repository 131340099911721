import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import memberTypes from 'graphql/memberTypes';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import Popover from 'components/popover';
import StoryBox from 'screens/storybox';
import Card from '../../card';
import useStyles from './storyCard-styles';

const StoryCard = props => {
  const {
    id,
    priorityLevel,
    image,
    categories,
    title,
    isDragging,
    isPitch,
    openStory,
    assignees,
    teams,
    onPriorityChange,
  } = props;
  const classes = useStyles(isPitch, isDragging)();
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);
  const page = isPitch ? memberTypes.PITCH : memberTypes.STORY;

  const handleStoryOpen = () => {
    openStory(title, id, page);
  };

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const [handleClick, handleDoubleClick] = useMouseClickEvents(handleOpenPopover, handleStoryOpen);

  const handleClose = () => {
    setOpenPopover(false);
  };

  const onInstanceClick = (sId, instanceId) => {
    handleClose();
    openStory(title, id, page, instanceId);
  };

  return (
    <div className={classes.storyCard} ref={anchorRef}>
      <Card
        {...{
          handleClick,
          handleDoubleClick,
          categories,
          isPitch,
          image,
          priorityLevel,
          onPriorityChange,
          assignees,
          title,
          teams,
        }}
      />
      <Popover
        onClose={handleClose}
        anchorEl={openPopover ? anchorRef.current : null}
        position="right"
        noMargin
      >
        <div className={classes.storyBox}>
          <StoryBox
            storySpec={{ id, image }}
            dialogHeight="810px"
            onClose={handleClose}
            onOpenStory={handleStoryOpen}
            onInstanceClick={onInstanceClick}
          />
        </div>
      </Popover>
    </div>
  );
};

StoryCard.propTypes = {
  /** mId of the story */
  id: PropTypes.string,
  /** Defines the priority */
  priorityLevel: PropTypes.string,
  /** Story title */
  title: PropTypes.string,
  /** the image of the story */
  image: PropTypes.string,
  /** the categories selected for the story */
  categories: PropTypes.arrayOf(PropTypes.any),
  /** Boolean that indicates if a storyCard is being dragged */
  isDragging: PropTypes.bool,
  /** Callback to invoke when opening a story */
  openStory: PropTypes.func,
  /** boolean that indicates pitch */
  isPitch: PropTypes.bool,
  /** list of users the story/pitch is assigned to */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      /** name of assignee */
      mTitle: PropTypes.string,
      /** avatar image of assignee */
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** list of teams the story/pitch is assigned to */
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      /** name of team */
      mTitle: PropTypes.string,
      /** avatar image of team */
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** callback to be invoked when priority is changed */
  onPriorityChange: PropTypes.func,
};

StoryCard.defaultProps = {
  id: '',
  priorityLevel: '',
  title: '',
  image: fallbackImage,
  categories: [],
  isDragging: false,
  openStory: () => {},
  isPitch: false,
  assignees: [],
  teams: [],
  onPriorityChange: () => {},
};

export default StoryCard;
