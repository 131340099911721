import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line max-len
import { ReactComponent as FactBoxIcon } from 'assets/icons/systemicons/editor/blocks_FactBox_off.svg';
import { elementTypes } from 'components/editor/constants/types';
import InputGroup from '../inputGroup';
import Box from '../box';
import useStyles from './styles';

const FactBox = ({ attributes, children, element }) => {
  const classes = useStyles();
  const readOnly = false;

  const [stingerValue, setStingerValue] = useState('');
  const [contentValue, setContentValue] = useState('');

  const handleStingerValueChange = useCallback(newStingerValue => {
    setStingerValue(newStingerValue);
  }, []);

  const handleContentValueChange = useCallback(newContentValue => {
    setContentValue(newContentValue);
  }, []);

  const stingerInputProps = {
    disableLabel: true,
    onChange: handleStingerValueChange,
    value: stingerValue,
    placeholder: 'Type Stinger here...',
  };

  const contentInputProps = {
    disableLabel: true,
    onChange: handleContentValueChange,
    value: contentValue,
    placeholder: 'Type Content here...',
  };

  const inputProps = [stingerInputProps, contentInputProps];

  return (
    <div {...attributes}>
      {children}
      <Box
        iconComponent={<FactBoxIcon className={classes.icon} />}
        title="Fact Box"
        height={140}
        readOnly={readOnly}
      >
        <InputGroup {...{ readOnly, inputProps }} />
      </Box>
    </div>
  );
};

FactBox.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

FactBox.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: {},
    type: elementTypes.FACT,
  },
};

export default memo(FactBox);
