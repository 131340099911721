import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import { getMembersOfQuery ,getStoriesQuery} from 'graphql/queryVariables';
import memberTypes from 'graphql/memberTypes';
import updateStoryList from 'graphql/utils/cache/notification/updateStoryList';
import GET_STORIES from 'graphql/queries/getStoriesPagination';

const getUpdatedList = (list, item) => {
    const memberUpdated = item.team;
    const updatedList = list;
    if (item.crudAction === "INSERT") {
        memberUpdated.__typename = "MemberType";
        memberUpdated.mDescription = item.team.mDescription || null;
        memberUpdated.mAvatarUrl = item.team.mAvatarUrl || null;
        const memberExist = updatedList.filter(u => u.mId === item.team.mId);
        if (memberExist.length === 0) {
            updatedList.push(memberUpdated);
        }
        return updatedList;
    }
    // crudAction is REMOVE if it is not INSERT.
    return updatedList.filter(t => t.mId !== item.team.mId);
}

const updateCahe = (proxy, item, query, variables) => {
    try {
        const list = proxy.readQuery({
            query,
            variables,
        });
        const updatedList  = getUpdatedList(list.getMembersOf, item);
        // Write updated member list in the cache.
        proxy.writeQuery({
            query,
            variables,
            data: {
                getMembersOf: updatedList,
              },
        });
    }
    catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
}

const shareStoryWithTeam =
    (proxy, item) => {
        const variables = getMembersOfQuery(item.story.mId);
        updateCahe(proxy, item, GET_MEMBERS_OF, variables);
        const variablesForStoryList = getStoriesQuery(
            item.team.mId,
            memberTypes.TEAM_STORY,
          );
        updateStoryList(proxy, item, GET_STORIES, variablesForStoryList);
    }

export default shareStoryWithTeam;
