import React from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import ContextMenu from 'components/contextMenu';
import MenuItem from 'components/menu/menuItem/MenuItem';
import { useRundownListContext } from 'contexts/RundownListContext';
import { ReactComponent as InsertEmptyIcon } from 'assets/icons/systemicons/rundown/gridIcons/insertEmpty.svg';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/rundown/gridIcons/add.svg';
import { ReactComponent as OpenIcon } from 'assets/icons/systemicons/rundown/gridIcons/open.svg';
import { ReactComponent as FloatIcon } from 'assets/icons/systemicons/rundown/gridIcons/float.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/systemicons/rundown/gridIcons/minus.svg';
import useStyles from './contextMenu-styles';

const GridContextMenu = ({
  onRemoveClick,
  onOpenStoryClick,
  onFloatClick,
  onCreateInstanceClick,
  onInsertEmptyClick,
  handleContextClose,
  isFloat,
  isPlaceholder,
  disableEdit,
  ...rest
}) => {
  const { setOpenId } = useRundownListContext();
  const classes = useStyles();

  const returnMenuItem = (label, Icon, handleClick, disable = false) => {
    return (
      <MenuItem
        image={FloatIcon}
        label={label}
        onClick={event => {
          event.stopPropagation();
          handleContextClose();
          setOpenId('');
          handleClick(event);
        }}
        disabled={disable}
      >
        <Icon className={classes.icon} />
      </MenuItem>
    );
  };

  return (
    <ContextMenu handleContextClose={handleContextClose} {...rest}>
      {!isPlaceholder && (
        <>
          {returnMenuItem(isFloat ? 'Unfloat' : 'Float', FloatIcon, onFloatClick, disableEdit)}
          <Divider
            classes={{
              root: classes.divider,
            }}
          />
        </>
      )}
      {returnMenuItem('Insert New Instance', AddIcon, onCreateInstanceClick, disableEdit)}
      {returnMenuItem('Insert Empty Placeholder', InsertEmptyIcon, onInsertEmptyClick, disableEdit)}
      <Divider
        classes={{
          root: classes.divider,
        }}
      />
      {!isPlaceholder && (
        <>
          {returnMenuItem('Open Story', OpenIcon, onOpenStoryClick)}
          <Divider
            classes={{
              root: classes.divider,
            }}
          />
        </>
      )}
      {returnMenuItem('Remove From Rundown', RemoveIcon, onRemoveClick, disableEdit)}
    </ContextMenu>
  );
};

GridContextMenu.propTypes = {
  onRemoveClick: PropTypes.func,
  onOpenStoryClick: PropTypes.func,
  onFloatClick: PropTypes.func,
  onCreateInstanceClick: PropTypes.func,
  onInsertEmptyClick: PropTypes.func,
  isFloat: PropTypes.bool,
  /** Boolean that stops user from editing a ready rundown */
  disableEdit: PropTypes.bool,
};

GridContextMenu.defaultProps = {
  onRemoveClick: () => {},
  onOpenStoryClick: () => {},
  onFloatClick: () => {},
  onCreateInstanceClick: () => {},
  onInsertEmptyClick: () => {},
  isFloat: false,
  disableEdit: false,
};

export default GridContextMenu;
