import {
  durationTypes,
  getTime,
  getDuration,
  getSeconds,
} from '../components/editor/utils';

const updateDurationField = (durationsRef, key, value) => {
  const field = durationsRef.find(f => f.key.includes(key));

  if (field) {
    field.value = value;
  } else {
    durationsRef.push({
      key,
      value,
    });
  }
};

const respectHostReadSpeed = (mMetaData, hostReadSpeed) => {
  const metaData = mMetaData ? [...mMetaData] : [];
  const wordCount = getDuration(metaData, durationTypes.WORD_COUNT);
  if (!wordCount) return metaData;

  const readRate = hostReadSpeed; // words per minute
  const wordsPerSecond = readRate / 60;
  const newSpeakDuration = Math.ceil(wordCount / wordsPerSecond);
  
  updateDurationField(
    metaData,
    durationTypes.HOST_READ_SPEED,
    hostReadSpeed,
  );


  updateDurationField(
    metaData,
    durationTypes.SPEAK_DURATION,
    getTime(newSpeakDuration)
  );

  const clipDuration = getDuration(metaData, durationTypes.CLIP_DURATION);
  const newTotalDuration = getSeconds(clipDuration) + newSpeakDuration;

  updateDurationField(
    metaData,
    durationTypes.TOTAL_DURATION,
    getTime(newTotalDuration)
  );

  return metaData;
};

export default respectHostReadSpeed;
