import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    userSelect: 'none',
  },
  svg: {
    '&.zoomed': {
      cursor: 'move',
    },
    '& .domain': {
      display: 'none',
    },
  },
}));

export default useStyles;
