import { makeStyles } from '@material-ui/styles';
import CheckerImage from 'assets/images/Checker.png';

const useStyles = (image, color) =>
  makeStyles(theme => {
    const colorValue =
      theme.palette.dina[color === 'surfaceCard' ? color : `${color}CardBackground`];
    return {
      ref: {
        width: '100%',
      },
      container: {
        position: 'relative',
        height: '100%',
        width: '100%',
        minHeight: '56px',
        padding: '6px',
        borderRadius: '2px',
        backgroundColor: colorValue,
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
        '&:hover': {
          '-webkit-filter': theme.palette.dina.onHover,
          backgroundColor: colorValue,
        },
      },
      title: {
        ...theme.typography.dina.listItemLabel,
        letterSpacing: '0.25px',
        lineHeight: '16px',
      },
      detailsRow: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '4px',
      },
      detailsLeft: {
        display: 'flex',
        alignItems: 'center',
      },
      editionIcon: {
        position: 'relative',
        width: '24px',
        minWidth: '24px',
        height: '24px',
        marginRight: '4px',
      },
      imageContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        backgroundImage: `url(${image || CheckerImage})`,
        width: '20px',
        height: '20px',
        backgroundSize: 'cover',
        borderRadius: '2px',
      },
      publishingPoint: {
        position: 'absolute',
        left: '8px',
        top: '8px',
        borderRadius: '4px',
        boxShadow:
          // eslint-disable-next-line max-len
          '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
      },
      destination: {
        ...theme.typography.dina.captionRegular,
        letterSpacing: '0.25px',
        color: theme.palette.dina.mediumEmphasis,
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      preview: {
        width: '360px',
        borderRadius: '8px',
        overflow: 'hidden',
        backgroundColor: theme.palette.dina.surfaceDialogs,
        position: 'relative',
      },
      close: {
        position: 'absolute',
        right: 0,
        top: 0,
        margin: '3px',
      },
    };
  });
export default useStyles;
