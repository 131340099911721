import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  headerContainer: {
    position: 'absolute',
    height: '40px',
    left: '0px',
    right: '0px',
    top: '0px',
  },

  headerTitle: {
    position: 'absolute',
    left: '16px',
    right: '49px',
    top: 'calc(50% - 18px/2)',
    bottom: '0px',

    /* Subtitles/Subtitle 1 */

    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',
    /* or 112% */

    letterSpacing: '0.15px',

    /* textHighEmphasis */

    color: '#FFFFFF',
  },

  headerDivider: {
    position: 'absolute',
    height: '1px',
    left: '0px',
    right: '0px',
    bottom: '0px',
  },

  contentContainer: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    top: '40px',
    bottom: '0px',
  },

  contentOutline: {
    height: '100%',
    width: '100%',
  },
  contentInline: {
  },
}));

export default useStyles;
