import React, { useState, useReducer, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Header from 'components/shared/header';
import Footer from 'components/shared/footer';
import { sortBy } from 'lodash';
import Divider from 'components/divider';
import TransitionSelect from '../transitionSelect';
import List from './list';
import Table from './table';
import useStyles from './details-view-styles';
import reducer, { actionTypes } from './utils/reducer';

const FilterBySpec = (specArray, metadataArray) => {
  return sortBy(
    specArray.map(field => {
      const target = metadataArray.find(tl => tl.name === field.name);
      return target ? { ...field, ...target } : field;
    }),
    'name',
  );
};

const DetailsView = ({
  initialVariant,
  initialTransition,
  metaData,
  transitionData,
  onOk,
  onClose,
  spec,
  mixerInputs,
}) => {
  const classes = useStyles();

  const [state, dispatch] = useReducer(reducer, {
    transitionData,
    metaData,
  });

  const initialIndex = useMemo(
    () => spec.findIndex(template => template.variant === initialVariant),
    [initialVariant, spec],
  );

  const [selectedListIndex, setSelectedListIndex] = useState(initialIndex >= 0 ? initialIndex : 0);
  const [selectedOption, setSelectedOption] = useState(initialTransition || transitionData[0]);

  const onTransitionUpdate = useCallback(transitionObj => {
    dispatch({
      payload: transitionObj,
      type: actionTypes.UPDATE_TRANSITION,
    });
    setSelectedOption(transitionObj);
  }, []);

  const onFieldUpdate = useCallback(payload => {
    dispatch({
      payload,
      type: actionTypes.UPDATE_META,
    });
  }, []);

  const { variant, fields } = spec[selectedListIndex];

  const fieldsBySpec = useMemo(() => FilterBySpec(fields, state.metaData), [
    fields,
    state.metaData,
  ]);

  return (
    <div>
      <div className={classes.contentContainer}>
        <div className={classes.variants}>
          <Header label="Variants" />
          <div className={classes.centerContent}>
            <List
              data={spec}
              onSelectionChange={val => setSelectedListIndex(val)}
              selectedIndex={selectedListIndex}
            />
          </div>
        </div>
        <Divider orientation="vertical" />
        <div className={classes.details}>
          <Header label="Details" {...{ onClose }} />
          <div className={classes.centerContent}>
            <div className={classes.content}>
              {transitionData.length !== 0 && (
                <>
                  <Typography classes={{ root: classes.textContent }}>In Transition</Typography>
                  <div className={classes.select}>
                    <TransitionSelect
                      update={onTransitionUpdate}
                      value={selectedOption}
                      arrowPosition="end"
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                    />
                  </div>
                </>
              )}
              {fieldsBySpec.length !== 0 && (
                <>
                  <Typography classes={{ root: classes.textContent }}>Content</Typography>
                  <Table
                    fields={fieldsBySpec}
                    onFieldUpdate={onFieldUpdate}
                    mixerInputs={mixerInputs}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer
        onOk={() => {
          onOk(variant, fieldsBySpec, selectedOption);
        }}
        onCancel={onClose}
      />
    </div>
  );
};

DetailsView.propTypes = {
  /** Initial Variant to be selected on the list */
  initialVariant: PropTypes.string,
  /** Initial transition value to show on the In transition */
  initialTransition: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  /** metaData that comes from the primary items */
  metaData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  /** transition data to show on the options of transition selection  */
  transitionData: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  /** onOk Callback function */
  onOk: PropTypes.func,
  /** on Close Callback Function */
  onClose: PropTypes.func,
};

DetailsView.defaultProps = {
  initialVariant: 'VARIANT',
  initialTransition: null,
  metaData: [],
  transitionData: [],
  onOk: () => {},
  onClose: () => {},
};

export default memo(DetailsView);
