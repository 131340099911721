import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './timelineEntry-styles';

const TimelineEntry = props => {
    const {item, index} = props;
    let itemHeight = 48;
    if(item.state) itemHeight += 16;
    if(item.customName) itemHeight += 16;
    const classes = useStyles({itemHeight, index});

    return (
        <div className={classes.listItem}>
            {item.state && (
                <div className={classes.event}>
                    <div className={classes.boxedStateLayout}>
                        <Typography className={classes.stateLabel}>{item.state}</Typography>
                    </div>

                </div>
            )}

            {item.customName && (
                <div className={classes.customNameEvent}>
                    <div className={classes.boxedCustomNameLayout}>
                        <Typography className={classes.customNameLabel}>
                            {item.customName}
                        </Typography>
                    </div>
                    
                </div>
            )}

            {item.timestamp && (
                <div className={classes.timestamp}>
                    <Typography className={classes.timestampLabel}>{item.timestamp}</Typography>
                </div>
            )}

            {item.updatedBy && (
                <div className={classes.updatedBy}>
                    <Typography className={classes.updatedByLabel}>{item.updatedBy}</Typography>
                </div>
            )}
        </div>
    );
};

export default TimelineEntry;