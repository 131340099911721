import gql from 'graphql-tag';

export default gql`
  mutation uploadMemberImage($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mAvatarUrl
      mThumbnailUrl
      mUpdatedAt
    }
  }
`;
