import { intersection } from 'lodash';
import GET_STORIES from 'graphql/queries/getStoriesPagination';
import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import { getStoriesQuery, getMembersOfQuery } from 'graphql/queryVariables';
import { findStoryFromUserStories, getStories } from './userStories';

/** Update story list cache for a given user, team or department */
const updateStoryListCache = (proxy, memberId, storyId, userId, type) => {
  /** Find if the story belongs to user or to the teams/departments
   * the user is a member of. */
  const story = findStoryFromUserStories(proxy, userId, storyId);

  /** The story is not related to the user. Skip cache update. */
  if (!story) {
    return;
  }
  // Old solution is not working for updating the frontend on add or remove items in the cache.
  // Need to write data with typename and nextToken.
  const stories = getStories(proxy, memberId, type);
  const { items, nextToken }=  stories.getMembersPagination;
  const storyItems = [...items];
  const list = stories.getMembersPagination.items.filter(
    s => s && s.mId === story.mId,
  );
  if (list.length === 0) {
    storyItems.unshift(story);
  }
 
  proxy.writeQuery({
    query: GET_STORIES,
    variables: getStoriesQuery(memberId, type),
    data: {
      getMembersPagination: {
        items: storyItems,
        __typename:'PaginatedMemberType',
        nextToken,
      },
 },
  });
};

/** remove story from cache list for a given user, team or department */
const removeStoryFromCache = (proxy, memberId, storyId, userId, type) => {
  /** Find if the story belongs to user or to the teams/departments
   * the user is a member of. */
  const story = findStoryFromUserStories(proxy, userId, storyId);

  /** The story is not related to the user. Skip cache update. */
  if (!story) {
    return;
  }
   // Old solution is not working for updating the frontend on add or remove items in the cache.
  // Need to write data with typename and nextToken.
  const stories = getStories(proxy, memberId, type);
  const { items, nextToken }=  stories.getMembersPagination;

  proxy.writeQuery({
    query: GET_STORIES,
    variables: getStoriesQuery(memberId, type),
    data: {
      getMembersPagination: {
        items: [...items].filter(
         s => s && s.mId !== story.mId),
         __typename:'PaginatedMemberType',
        nextToken,
      },
 },
  });
};

/** Update teams' and departments' story list for the user */
const updateRealatedMembersStories = (
  proxy,
  storyId,
  addedMembers,
  removedMembers,
  storyMemberType,
  userMemberType,
  userId
) => {
  try {
    const userMembers = proxy.readQuery({
      query: GET_MEMBERS_OF,
      variables: getMembersOfQuery(userId, userMemberType),
    });

    const userMembersIds = userMembers.getMembersOf.map(({ mId }) => mId);
    const addedMembersIds = addedMembers.map(({ mId }) => mId);
    const removedMembersIds = removedMembers.map(({ mId }) => mId);

    /** Find the added and removed departments/teams where user is a member */
    const memberIds = intersection(userMembersIds, addedMembersIds);
    const membersToRemove = intersection(userMembersIds, removedMembersIds);

    /** Add story to the user's departments/teams cache  */
    memberIds.forEach(memberId => {
      updateStoryListCache(proxy, memberId, storyId, userId, storyMemberType);
    });

    /** Remove story from user's departments/teams cache  */
    membersToRemove.forEach(memberId => {
      removeStoryFromCache(proxy, memberId, storyId, userId, storyMemberType);
    });
  } catch (e) {
    console.log(e);
  }
};

export default updateRealatedMembersStories;
