import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Divider from 'components/divider';
import Dropdown from './ellipsisDropdown';
import PublishingDatePickerButton from './publishingDatePickerButton';
import ShareStory from './shareStory';
import useStyles from './toolbar-styles';

const StoryToolbar = ({
  data,
  storyId,
  platforms,
  containerRef,
  isPitch,
  onCreateStory,
  ...rest
}) => {
  const classes = useStyles();
  const { mPublishingAt, mThumbnailKey, mAvatarKey, mSyncActive } = data.getMember;

  return (
    <div className={classes.toolbar}>
      <div className={classes.datePicker}>
        <PublishingDatePickerButton publishingTime={mPublishingAt} storyId={storyId} />
      </div>
      {isPitch && (
        <Button variant="contained" onClick={onCreateStory} classes={{ root: classes.pitchButton }}>
          Create Story from Pitch
        </Button>
      )}
      <div className={classes.leftTools}>
        <ShareStory platforms={platforms} storydata={data} containerRef={containerRef} />
        <Divider orientation="vertical" className={classes.divider} />
        <Dropdown
          {...rest}
          storyId={storyId}
          thumbnailKey={mThumbnailKey}
          coverPhotoKey={mAvatarKey}
          mSyncActive={mSyncActive}
        />
      </div>
    </div>
  );
};

StoryToolbar.propTypes = {
  /** selected tab */
  tabValue: PropTypes.string,
  /** set selected tab */
  setTabValue: PropTypes.func,
  /** onCreateStory from pitch callback */
  onCreateStory: PropTypes.func,
  /** whether story is isPitch or not */
  isPitch: PropTypes.bool,
};

StoryToolbar.defaultProps = {
  tabValue: '',
  setTabValue: () => {},
  onCreateStory: () => {},
  isPitch: false,
};

export default StoryToolbar;
