import { curry } from 'lodash/fp';
import { isObject, isEmpty, isArray, has } from 'lodash';

/**
 * Sorts an array of object alphabetically on ascending order by default
 *
 * @param {Object[]} items Array of objects that will be sorted
 * @param {String} sortBy Property of object that should be used to sort items
 * @param {Boolean} [descending = false] If true returns sort on descending order
 * @param {Object} [options = {}] Configuration options argument for localeCompare
 * @returns {Object[]} Sorted array of objects
 */

const alphaSort = (items, sortBy, descending = false, options = {}) => {
  if (!items || !sortBy || items.length === 0) return [];

  const isValidByProperty = isValid(sortBy);

  if (items.length === 1) {
    const [item] = items;

    return isValidByProperty(item) ? items : [];
  }

  return items.filter(isValidByProperty).sort(compare(sortBy, descending, options));
};

/**
 * Checks for validity of the item
 *
 * @param {String} sortBy Property of object that should be used to sort items
 * @param {Object} item Object to be sorted by property for alphaSort
 * @returns {Boolean} true if valid, otherwise false
 */

export const isValid = curry(
  (sortBy, item) => isObject(item) && !isArray(item) && !isEmpty(item) && has(item, sortBy),
);

/**
 * Compares two items with localeCompare
 *
 * @param {String} sortBy Property of object that should be used to sort items
 * @param {Boolean} descending If true returns sort on descending order
 * @param {Object} options Configuration options argument for localeCompare
 * @returns {Number}
 */

const compare = curry(
  (sortBy, descending, options, a, b) =>
    (descending ? -1 : 1) * a[sortBy].localeCompare(b[sortBy], options),
);

export default alphaSort;
