import { addHours, subHours, isFuture, isToday, setHours } from 'date-fns';
import { zoomIdentity } from 'd3';

/**
 * Creates initial zoom on timeline
 *
 * @param {HTMLElement} rectElement Root dom element for the zoom controller
 * @property {Object} zoomInstance D3 zoom instance
 * @property {Object} xScaleRef Specifies the initial d3 time scale
 * @property {number} width Specifies the width of the timeline
 */

const makeInitialZoom = (rectElement, zoomInstance, xScaleRef, width, storyPulishingDate) => {
  let now = storyPulishingDate ? new Date(storyPulishingDate) : new Date();

  const windowOfTime = 24;
  // eslint-disable-next-line one-var
  let nextFactor, prevFactor;

  if (storyPulishingDate && isToday(now)) {
    // if date is today, then timeline will start from (current time - 3 hours)
    now = new Date();
    nextFactor = windowOfTime - 3;
    prevFactor = 3;
  } else {
    // for the rest of the cases timeline will start on midnight.
    now = setHours(now, 0);
    nextFactor = windowOfTime - 1;
    prevFactor = 0.1;
  }

  const next = addHours(now, nextFactor);
  const prev = subHours(now, prevFactor);
  const xScale = xScaleRef.current;

  rectElement.call(
    zoomInstance.transform,
    zoomIdentity.scale(width / (xScale(next) - xScale(prev))).translate(-xScale(prev), 0),
  );
};

export default makeInitialZoom;
