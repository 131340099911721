import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import AdvancedDialog from 'components/advancedDialog';
import Popover from 'components/popover';
import Warning from 'components/instanceCard/components/warning';
import useStyles from './instanceVersionHistory-styles';

const InstanceVersionHistory = props => {
    const {
      open,
      title, 
      onCancel, 
      onOk,
      checkVersionRestorability,
      menuHeaderTitle, 
      content, 
      versions,
      getSpecificVersionContent,
      versionRestoreDisabled,
      lockedByUser,
      isSavingContent,
      auditListLoading,
      versionContentLoading,
    } = props;

  const menuItems = [
    {
      subtitle: 'subtitle',
      items: versions,
    },
  ];
  const initialVersion = versions.length > 0 && versions[0];
  const initialContentHeader = initialVersion && initialVersion.timestamp;
  const [selectedVersion, setSelectedVersion] = useState(initialVersion);
  const [contentHeader, setContentHeader] =  useState(initialContentHeader);
  const [showWarning, setShowWarning] = useState(false);
  const rootRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isProcessingSubmit, setIsProcessingSubmit] = useState(isSavingContent);

  const onVersionSelect = async item => {
    setSelectedVersion(item);
    setContentHeader(item.timestamp);
    getSpecificVersionContent(item.contentKey);
  };

  const handleOnOk = async (content) => {
    setIsProcessingSubmit(true);
    const versionRestorable = await checkVersionRestorability();
    if(versionRestorable){
      onOk(content);
    }
    else{
      setAnchorEl(rootRef.current);
      setShowWarning(true);
    }
    setIsProcessingSubmit(false);
  }
  const handleHideWarning = () => {
    setAnchorEl(null);
  };

    const classes = useStyles();

    return (
        <div ref={rootRef}>
            <Modal 
                open={open}
                style={{position: 'absolute', top: '20%', left: '25%', right: '25%'}}
            >
              <AdvancedDialog
               variant='version-history'
               title={title}
               subtitle="version history"
               menuHeaderTitle={menuHeaderTitle}
               contentHeaderTitle={contentHeader}
               onCancel={onCancel}
               onOk={handleOnOk}
               menus={menuItems}
               onLeftMenuItemSelect={onVersionSelect}
               selectedLeftMenu={selectedVersion}
               content={content}
               submitButtonText="Restore this version"
               isDisruptiveAction={true}
               disruptiveActionText="Click again to Restore"
               isProcessingSubmit={isProcessingSubmit}
               isContentLoading={versionContentLoading}
               isMenuLoading={auditListLoading}
              />
            </Modal>
            {showWarning && (
              <Popover
                anchorEl={anchorEl}
                onClose={handleHideWarning}
                position="center"
              >
                <div className={classes.warning}>
                  <Warning
                    title={lockedByUser}
                    onClose={handleHideWarning}
                    onConfirm={handleHideWarning}
                    variant='instanceLocked'
                  />
                </div>
              </Popover>
            )}
        </div>
    );
};

InstanceVersionHistory.propTypes = {
  /** Flag to set dialog open */
  open: PropTypes.string,
  /** Title of the instance */
  title: PropTypes.string,
  /** Title of the menu sidebar */
  menuHeaderTitle: PropTypes.string,
  /** on cancel button press from dialog */
  onCancel: PropTypes.func,
};

InstanceVersionHistory.defaultProps = {
  open: false,
  title: 'instance name',
  menuHeaderTitle: 'Versions',
  onCancel: () => {},
};

export default InstanceVersionHistory;
