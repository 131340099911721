/* eslint-disable react/no-array-index-key */
import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ButtonComponent from 'components/buttons/button';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import useImageUpload from 'hooks/useImageUpload';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { actionTypes } from 'components/editor/constants/types';
import { generateName } from 'components/editor/utils';
import { useSlate } from 'slate-react';
import Thumbnail from './thumbnail';
import useStyles from './styles';

const imageTypes = ['jpeg', 'png'];
const Button = memo(ButtonComponent);

const AddThumbnails = ({
  thumbnails: thumbs,
  readOnly,
  addThumbnails,
  removeThumbnail,
  maxThumbnails,
}) => {
  const editor = useSlate();
  const classes = useStyles();
  const { update } = useEditorContext();

  const [thumbnails, setThumbnails] = useState(thumbs);

  const onImageLoad = async files => {
    const updatedThumbnails = [...thumbnails];
    const noOfAllowedThumbnails = maxThumbnails - thumbnails.length;
    Promise.all(
      await files.slice(0, noOfAllowedThumbnails).map(async ({ file, filePath }) => {
        const fileName = generateName(file.type);
        const response = await update({
          type: actionTypes.ASSET_INSERT,
          payload: { document: editor.children, file, fileName },
        });

        if (response) updatedThumbnails.push({ ...response, filePath });
        return response;
      }),
    ).then(async responses => {
      if (responses) {
        await addThumbnails(responses);
        setThumbnails(updatedThumbnails);
      }
    });
  };

  const onThumbnailRemoveClick = useCallback(
    thumbnail => {
      const updatedThumbnails = thumbnails.filter(({ mRefId }) => mRefId !== thumbnail.mRefId);
      setThumbnails(updatedThumbnails);
      const updatedThumbs = updatedThumbnails.map(({ mId, mRefId, src }) => ({ mId, mRefId, src }));
      removeThumbnail(updatedThumbs);
    },
    [removeThumbnail, thumbnails],
  );

  const uploadImage = useImageUpload({
    disableResize: true,
    imageTypes,
    onImageLoad,
    multiple: true,
  });

  const onAddThumbnailClick = useCallback(event => {
    event.preventDefault();
    uploadImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      {thumbnails.map((thumbnail, index) => (
        <Thumbnail {...{ onThumbnailRemoveClick, thumbnail }} key={index} />
      ))}
      {thumbnails.length < maxThumbnails && (
        <Button
          type="dashedOutlined"
          className={classes.addButton}
          onClick={onAddThumbnailClick}
          disabled={readOnly}
        >
          <AddIcon className={classes.addIcon} />
        </Button>
      )}
    </div>
  );
};

AddThumbnails.propTypes = {
  /** Boolean that indicates that component is readOnly */
  readOnly: PropTypes.bool,
  /** List of thumbnails */
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      /** id of image as asset */
      mId: PropTypes.string,
      /** Unique id of image as asset */
      mRefId: PropTypes.string,
      /** mContentKey of thumbnail */
      src: PropTypes.string,
    }),
  ),
  /** callback to add one or multiple thumbnail */
  addThumbnails: PropTypes.func,
  /** maximum number of allowed thumbnail */
  maxThumbnails: PropTypes.number,
  /** callback to remove thumbnail */
  removeThumbnail: PropTypes.func,
};

AddThumbnails.defaultProps = {
  readOnly: false,
  thumbnails: [],
  addThumbnails: () => {},
  maxThumbnails: 4,
  removeThumbnail: () => {},
};

export default memo(AddThumbnails);
