const saveFeedOnContext = (feedResult, config) => {
  config.feeds = [
    ...feedResult.data.getMemberFromId.map(({ mTitle, mRefId }) => ({
      mTitle,
      mRefId,
    })),
  ];
};

export default saveFeedOnContext;
