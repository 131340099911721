import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    color: 'white',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '24px',
    'letter-spacing': '1.5px',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
