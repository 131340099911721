import { makeStyles } from '@material-ui/styles';
import image from 'assets/images/PitchBackgroundOverlay.png';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: '1px',
  },
  root: {
    height: '20px',
    width: '100%',
  },
  list: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.dina.surfaceCard,
    boxShadow:
      // eslint-disable-next-line max-len
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '2px',
    cursor: 'pointer',
    backgroundImage: ({ isPitch }) => (isPitch ? `url(${image})` : ''),
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },
  leftComponents: {
    display: 'flex',
    alignItems: 'center',
  },
  pitchIndicator: {
    margin: '2px 0 2px 2px',
  },
  marketIcon: {
    width: '100%',
    height: '100%',
  },
  image: {
    minWidth: '16px',
    maxWidth: '16px',
    minHeight: '16px',
    maxHeight: '16px',
    borderRadius: '2px',
    objectFit: 'cover',
    margin: '2px 0 2px 2px',
  },
  title: {
    marginLeft: '4px',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  titleText: {
    ...theme.typography.dina.instanceCardTitle,
  },
  avatar: {
    marginRight: '4px',
  },
  avatarText: {
    ...theme.typography.dina.instanceCardTitle,
    color: theme.palette.dina.mediumEmphasis,
  },
  storyBox: {
    width: '327px',
    height: '830px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
}));

export default useStyles;
