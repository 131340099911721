import { makeStyles } from '@material-ui/styles';

const useStyles = containerWidth =>
  makeStyles(theme => ({
    container: {
      display: 'flex',
      position: 'relative',
      width: containerWidth,
    },
    avatar0: {
      position: 'absolute',
    },
    avatar1: {
      position: 'absolute',
      zIndex: '1',
      transform: 'translateX(-4px)',
    },
    avatar2: {
      position: 'absolute',
      zIndex: '2',
      transform: 'translateX(-8px)',
    },
    avatar3: {
      position: 'absolute',
      zIndex: '3',
      transform: 'translateX(-12px)',
    },
    avatarText: {
      ...theme.typography.dina.durationLabel,
      color: theme.palette.dina.mediumEmphasis,
    },
  }));

export default useStyles;
