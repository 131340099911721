import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  grid: {
    minWidth: '100vw',
    width: 'auto',
    flex: 1,
    display: 'grid',
    gridTemplateColumns: '24px auto auto auto',
  },
  tableHead: {
    height: '24px',
  },
  headerTableCell: {
    padding: '0 8px',
    backgroundColor: 'rgb(62,75,93)',
    ...theme.typography.dina.overline,
    borderBottom: `1px solid ${theme.palette.dina.dividerDark}`,
    borderTop: `1px solid ${theme.palette.dina.dividerDark}`,
  },
  evenRow: { backgroundColor: theme.palette.dina.blackHoverOverlay },
  bodyTableCell: {
    height: '31px',
    padding: '0 8px',
    ...theme.typography.dina.listItemLabelMedium,
    color: theme.palette.dina.highEmphasis,
    borderBottom: 'none',
    lineHeight: 0,
    cursor: 'pointer',
    maxWidth: '300px',
  },
  chip: {
    minWidth: '40px',
    maxWidth: '90px',
    height: '20px',
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.dina.chipBackground,
  },
  chipLabel: {
    ...theme.typography.dina.overline,
    color: theme.palette.dina.mediumEmphasis,
  },
  coverPhoto: {
    width: '24px',
    height: '24px',
    marginLeft: '16px',
    borderRadius: '2px',
  },
  photoCell: {
    width: '24px',
    height: '24px',
    padding: 0,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '90%',
  },
  source: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '140px',
  },
  date: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '140px',
  },
  read: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.mediumEmphasis,
    '&:hover': {
      color: theme.palette.dina.highEmphasis,
    },
  },
  hover: {
    color: theme.palette.dina.highEmphasis,
    border: `1px solid ${theme.palette.dina.onFocus}`,
  },
  active: {
    color: theme.palette.dina.highEmphasis,
    backgroundColor: theme.palette.dina.onFocus,
    '&.MuiTableRow-root:hover': {
      background: theme.palette.dina.onFocus,
    },
  },
}));

export default useStyles;
