import React from 'react';
import PropTypes from 'prop-types';
import useMenuAnchor from 'hooks/useMenuAnchor';
import Button from 'components/buttons/iconButton';
import ICONS from 'assets/icons/icons';
import ActionMenu from '../actionMenu';

const EllipsisMenu = ({ menuItems, onMenuItemClick }) => {
  const { anchorEl, closeMenu, toggleMenu } = useMenuAnchor();

  return (
    <div>
      <Button icon={ICONS.MORE_VERTICAL} width={4} onClick={toggleMenu} />

      <ActionMenu
        {...{
          menuItems,
          anchorEl,
          closeMenu,
          onMenuItemClick,
        }}
        position="right-top"
      />
    </div>
  );
};

EllipsisMenu.propTypes = {
  /** Items to be shown as popover options on ellipsis click */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      action: PropTypes.string,
    })
  ),
  /** Callback to be invoked on menu item select,
   * with the action type passed in as argument
   */
  onMenuItemClick: PropTypes.func,
};

EllipsisMenu.defaultProps = {
  menuItems: [],
  onMenuItemClick: () => {},
};

export default EllipsisMenu;
