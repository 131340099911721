import gql from 'graphql-tag';

export default gql`
  mutation CreatePitch($input: CreatePitchInput) {
    createPitch(input: $input) {
      mId
      mRefId
      mType
      mState
      mTitle
      mDescription
      mContent
      mContentKey
      mAvatarUrl
      mAvatarKey
      mThumbnailUrl
      mThumbnailKey
      mCreatedAt
      mUpdatedAt
      mPublishingAt
      mPriority
      mMetaData {
        key
        value
      }
      mCategories {
        label
        score
        categoryId
      }
      mAssignedMembers {
        mId
        mType
      }
    }
  }
`;
