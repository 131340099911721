import { zoomIdentity } from 'd3';
import { addSeconds } from 'date-fns';

/**
 * @typedef {Object} zoomControllerProperties
 * @property {HTMLElement} rectElement Root dom element for the zoom controller
 * @property {Object} zoomInstance D3 zoom instance
 * @property {Object} newXScaleRef Specifies the current d3 time scale
 * @property {Object} zoomingRef Determines if zoom controller is being changed or not
 * @property {Object} kRef Determines the zoom factor of the zoom controller
 * @property {Object} xScaleRef Specifies the initial d3 time scale
 * @property {Object} draggingRef Determines if the instances are being dragged or not
 */

/**
 * Creates per second auto rolling
 *
 * @param {zoomControllerProperties}
 * @returns {Object} Timer reference to cancel rolling behavior
 */

const createRollingBehavior = ({
  rectElement,
  zoomInstance,
  newXScaleRef,
  zoomingRef,
  kRef,
  xScaleRef,
  draggingRef,
}) => {
  const timer = setInterval(() => {
    const domain = newXScaleRef.current.domain();
    const start = domain[0];

    if (!zoomingRef.current && !draggingRef.current) {
      rectElement
        .call(zoomInstance)
        .call(
          zoomInstance.transform,
          zoomIdentity
            .scale(kRef.current)
            .translate(-xScaleRef.current(addSeconds(start, 1)), 0)
        );
    }
  }, 1000);

  return timer;
};

export default createRollingBehavior;
