import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  showFocus: {
    opacity: '0.8',
    boxShadow: `inset 0 0 0 1px ${theme.palette.dina.onFocus}`,
    backgroundColor: theme.palette.dina.statusOnFocusedDiscreetFill,
    '&:hover': {
      backgroundColor: theme.palette.dina.statusOnFocusedDiscreetFill,
    },
  },
  avatar: {
    width: 32,
    height: 32,
    position: 'relative',
    marginLeft: '12px',
    marginRight: '8px',
  },
  thumbnail: {
    width: 26,
    height: 26,
    borderRadius: '2px',
    objectFit: 'cover',
  },
  publishingPoint: {
    width: 22,
    height: 22,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  centerContent: {
    flex: 1,
    height: 40,
    minWidth: 0,
  },
  title: {
    ...theme.typography.dina.captionMedium,
    color: theme.palette.dina.highEmphasis,
    lineHeight: '16px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    marginTop: '3px',
  },
  firstIcon: {
    // marginLeft: '8px',
  },
  icon: {
    marginLeft: '2px',
  },
  timingDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    outline: 'none',
    marginTop: '-22px',
    marginRight: '12px',
    marginLeft: '5px',
  },
  timingText: {
    ...theme.typography.dina.caption,
    lineHeight: '14px',
    letterSpacing: '0.25px',
    fontWeight: 'normal',
    textAlign: 'right',
  },
  divider: {
    marginLeft: '52px',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  instancePreview: {
    width: '360px',
    position: 'relative',
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: '3px',
  },
}));

export default useStyles;
