import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  mainDiv: {
    border: `5px solid ${theme.palette.dina.statusWarning}`,
    minHeight: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    // overflowY: 'scroll',
  },
  emptyDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  masterTitle: {
    ...theme.palette.dina.tab,
    color: 'black',
    background: theme.palette.dina.statusWarning,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    top: 0,
  },
  footer: {
    // marginTop: 'auto',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    background: theme.palette.dina.statusWarning,
  },
  closeDiv: {
    padding: '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  deleteButton: {
    ...theme.palette.dina.tab,
    lineHeight: 0,
    color: theme.palette.dina.highEmphasis,
    backgroundColor: theme.palette.dina.statusError,
    textTransform: 'none',
    height: '24px',
    width: '142px',
    '&:hover': {
      backgroundColor: theme.palette.dina.statusError,
      '-webkit-filter': theme.palette.dina.onHover,
    },
  },
  doneButton: {
    ...theme.palette.dina.tab,
    lineHeight: 0,
    color: theme.palette.dina.highEmphasis,
    backgroundColor: theme.palette.dina.blackMediumEmphasis,
    textTransform: 'none',
    height: '24px',
    width: '120px',
    marginLeft: '4px',
    '&:hover': {
      backgroundColor: theme.palette.dina.blackMediumEmphasis,
      '-webkit-filter': theme.palette.dina.onHover,
    },
  },
}));

export default useStyles;
