import { makeStyles } from '@material-ui/styles';

const useStyles = selected =>
  makeStyles(theme => ({
    button: {
      margin: '0 4px',
      height: '32px',
      padding: '1px 11px 0 11px',
      background:
        theme.palette.dina[selected ? 'onSelected' : 'blackHoverOverlay'],
      ...theme.typography.dina.listItemLabel,
      color: theme.palette.dina[selected ? 'highEmphasis' : 'mediumEmphasis'],
      border: `1px solid ${
        theme.palette.dina[selected ? 'onSelected' : 'mediumEmphasis']
      }`,
      textTransform: 'capitalize',
      '&:hover': {
        background:
          theme.palette.dina[selected ? 'onSelected' : 'blackHoverOverlay'],
      },
      '&:hover $arrowDown': {
        opacity: 1,
      },
    },
    root: {
      borderRadius: '20px',
    },
    startIcon: {
      color: () => {
        if (!selected) return theme.palette.dina.mediumEmphasis;
        return theme.palette.dina.onSelected;
      },
    },
    arrowDown: {
      opacity: 0,
      minWidth: '9px',
    },
  }));

export default useStyles;
