import FILTER_PREFIX from 'utils/constants/filterPrefix';

const frontendFiltering = (data = [], filters = [], search = () => {}, isInstance = false) => {
  const { TITLE, DESCRIPTION, DEPARTMENT, TEAM, USER, PLATFORM } = FILTER_PREFIX;

  const freeTextSearchKeys = ['mTitle', 'mDescription'];
  freeTextSearchKeys.push(isInstance ? 'mProperties.platform' : 'mContent');

  let list = [...data];

  filters.forEach(({ expression }) => {
    if (expression.startsWith(TITLE)) {
      list = search(list, ['mTitle'], expression.substring(TITLE.length));
    } else if (expression.startsWith(DESCRIPTION)) {
      list = search(list, ['mDescription'], expression.substring(DESCRIPTION.length));
    } else if (expression.startsWith(DEPARTMENT)) {
      list = search(list, ['mAssignedMembers.mId'], expression.substring(DEPARTMENT.length));
    } else if (expression.startsWith(TEAM)) {
      list = search(list, ['mAssignedMembers.mId'], expression.substring(TEAM.length));
    } else if (expression.startsWith(USER)) {
      list = search(list, ['mAssignedMembers.mId'], expression.substring(USER.length));
    } else if (expression.startsWith(PLATFORM)) {
      list = isInstance
        ? search(list, ['mProperties.platform'], expression.substring(PLATFORM.length))
        : list;
    } else {
      // free text
      list = search(list, freeTextSearchKeys, expression);
    }
  });

  return list;
};

export default frontendFiltering;
