import React from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/tabs';
import Switch from 'components/switch';
import { Typography } from '@material-ui/core';
import TimeNavigator from './timeNavigator';
import TimeIndicatorPicker from './timeIndicatorPicker';
import useStyles from './header-styles';

const Header = ({
  time,
  timeVariant,
  onTimeChange,
  tabs,
  onTabChange,
  tabIndex,
  onlyShowUsersItems,
  setOnlyShowUsersItems,
}) => {
  const classes = useStyles();

  const handleTimeChange = (newTime, newTimeVariant) => {
    onTimeChange(newTime, newTimeVariant);
  };
  const handleTabChange = (tab, index) => {
    onTabChange(tab, index);
  };
  const handleSwitchClick = () => {
    setOnlyShowUsersItems(pre => !pre);
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftComponents}>
        <div className={classes.timeNavigator}>
          <TimeNavigator timeVariant={timeVariant} time={time} onChange={handleTimeChange} />
        </div>
        <TimeIndicatorPicker timeVariant={timeVariant} time={time} onChange={handleTimeChange} />
      </div>
      <div className={classes.rightComponents}>
        <div className={classes.switchBox}>
          <Switch selected={onlyShowUsersItems} onClick={handleSwitchClick} />
          <Typography classes={{ root: classes.switchText }} onClick={handleSwitchClick}>
            Only items related to you
          </Typography>
        </div>
        <Tabs variant="outlined" tabs={tabs} onTabChange={handleTabChange} tabIndex={tabIndex} />
      </div>
    </div>
  );
};

Header.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      /** Title to show on the tab */
      title: PropTypes.string,
      /** Time variant of the planning view
       * Describes the time resolution
       */
      timeVariant: PropTypes.string,
      /** Dummy image to show if the planning view is not present */
      fallbackImage: PropTypes.any,
      /** Makes the tab disabled */
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  /** The initial time assigned to the components */
  time: PropTypes.string,
  /** Time variant of the planning view
   * Describes the time resolution
   */
  timeVariant: PropTypes.oneOf(['date', 'week', 'month', 'year']),
  /** Callback to be invoked when different planning view is chosen by Tabs */
  onTabChange: PropTypes.func,
  /** Callback to be invoked when time is changed by TimeIndicatorPicker or TimeNavigator */
  onTimeChange: PropTypes.func,
  /** boolean that shows only users stories & pitches the user is assigned to */
  onlyShowUsersItems: PropTypes.bool,
  /** callback to revert onlyShowUsersItems  */
  setOnlyShowUsersItems: PropTypes.func,
};

Header.defaultProps = {
  time: new Date().toISOString(),
  timeVariant: 'date',
  onTabChange: () => {},
  onTimeChange: () => {},
  onlyShowUsersItems: false,
  setOnlyShowUsersItems: () => {},
};
export default Header;
