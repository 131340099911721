import { createContext } from 'react';

// export const BookmarksContext = createContext([]);

const ConfigContext = createContext(
  {
    iptcMediaTopics: [],
    externalUrls: [],
    feeds: [],
    automationTemplateConfigs: [],
    metadataForms: [],
    userRights: [],
    settings: [],
  },
  () => {},
);
export default ConfigContext;
