import React, { memo } from 'react';
import { elementTypes } from 'components/editor/constants';
import { ReactComponent as VideoIcon } from 'assets/icons/systemicons/editor/video_small.svg';
import { ReactComponent as PhotoIcon } from 'assets/icons/systemicons/editor/photo_small.svg';
import TextButton from '../textButton';
import Wrapper from '../wrapper';

const { IMAGE, VIDEO } = elementTypes;

const CmsToolbar = () => {
  return (
    <Wrapper showRightMargin>
      <TextButton icon={<VideoIcon />} label="Add Video" type={VIDEO} />
      <TextButton icon={<PhotoIcon />} label="Add Photo" type={IMAGE} />
    </Wrapper>
  );
};

export default memo(CmsToolbar);
