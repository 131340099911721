import React from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import Input from 'components/shared/input';
import AutoComplete from '../autocomplete';
import inputTypes from '../utils/inputTypes';
import useStyles from './table-view-styles';

const TableHeader = ({ children, classes, ...rest }) => (
  <th className={classes.tableHeader} {...rest}>
    {children}
  </th>
);
const TableData = ({ children, classes, ...rest }) => (
  <td className={classes.tableData} {...rest}>
    {children}
  </td>
);

const TableRow = ({ field, onFieldUpdate, mixerInputs, classes }) => {
  const { name, value, type } = field;

  const handleSelection = selectedValue => {
    onFieldUpdate({ name, value: selectedValue });
  };
  const items = [...sortBy(mixerInputs, 'name')];

  const fieldInput = () => {
    switch (type) {
      case inputTypes.SELECT:
        return <AutoComplete initialValue={value} options={items} onChange={handleSelection} />;
      default:
        return (
          <Input
            value={value || ''}
            onUpdate={inputValue => {
              if (inputValue !== value) onFieldUpdate({ name, value: inputValue });
            }}
          />
        );
    }
  };
  return (
    <tr>
      <TableData {...{ classes }} style={{ width: '20%' }}>
        {name}
      </TableData>
      <TableData {...{ classes }}>{fieldInput()}</TableData>
    </tr>
  );
};

const TableView = ({ fields, mixerInputs, onFieldUpdate, ...rest }) => {
  const classes = useStyles();

  return (
    <table className={classes.table} {...rest}>
      <tbody>
        <tr>
          <TableHeader {...{ classes }} style={{ width: '30%' }}>
            key
          </TableHeader>
          <TableHeader {...{ classes }}>value</TableHeader>
        </tr>
        {fields.map((field, index) => (
          <TableRow
            // eslint-disable-next-line react/no-array-index-key
            key={`table-${index}`}
            {...{ field, index, onFieldUpdate, mixerInputs, classes }}
          />
        ))}
      </tbody>
    </table>
  );
};

TableView.propTypes = {
  /** data that will be mapped on to the table */
  fields: PropTypes.arrayOf(PropTypes.object),
  /** onFieldUpdate callback function */
  onFieldUpdate: PropTypes.func,
  /** mixerInputs options */
  mixerInputs: PropTypes.arrayOf(PropTypes.object),
};

TableView.defaultProps = {
  fields: [],
  onFieldUpdate: () => {},
  mixerInputs: [],
};
export default TableView;
