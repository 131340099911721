import React from 'react';
import Title from './title';
import Instances from './instances';
import useStyles from './unscheduled-instances-styles';

const UnscheduledInstances = ({ instances }) => {
  const classes = useStyles();
  const unscheduledInstances = instances.filter(instance => !instance.start);

  return (
    <div className={classes.root}>
      <Title />
      <Instances instances={unscheduledInstances} />
    </div>
  );
};

export default UnscheduledInstances;
