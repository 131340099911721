import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Group from 'components/listGroup';
import UserCtx from 'contexts/UserContext';
import image from 'assets/images/default/defaultThumbnail.png';
import { useQuery } from 'react-apollo';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { getRundownsQuery } from 'graphql/queryVariables';
import GET_RUNDOWNS from 'graphql/queries/getRundowns';
import Popover from 'components/popover/Popover';
import CreateRundown from 'components/createNew';
import useCreateMasterRundown from 'hooks/useCreateMasterRundown';
import Rundown from '../listItem';

const GroupContainer = props => {
  const { title, selectedDates, setPreviewRundown } = props;
  const user = useContext(UserCtx);
  const { mId: userId } = user;

  const [anchorEl, setAnchorEl] = useState(null);

  const query = GET_RUNDOWNS;
  const variables = getRundownsQuery('');
  const [createMasterRundown] = useCreateMasterRundown();

  const { data, error, loading } = useQuery(query, {
    variables,
  });
  if (loading) return <LoadingIndicator />;
  if (error || !data) return <div>no data</div>;

  const { getRundowns } = data;

  const onCreateMasterRundown = async rundownTitle => {
    try {
      setAnchorEl(null);
      await createMasterRundown(rundownTitle, userId);
    } catch (e) {
      console.log(e);
    }
  };

  const sortedRundowns =
    getRundowns.sort((a, b) => {
      if (a.recurrence.startTime > b.recurrence.startTime) return 1;
      if (a.recurrence.startTime < b.recurrence.startTime) return -1;

      if (a.mTitle.toLowerCase() > b.mTitle.toLowerCase()) return 1;
      if (a.mTitle.toLowerCase() < b.mTitle.toLowerCase()) return -1;
    }) || [];

  return (
    <>
      <Group
        title={title}
        type="left"
        handleCreateClicked={e => setAnchorEl(e.currentTarget.parentNode)}
        toolTipTitle="Create Master Rundown"
        id="masterrundown"
      >
        <List disablePadding>
          {sortedRundowns.map(
            rundown =>
              rundown && (
                <Rundown
                  key={rundown.mId}
                  title={rundown.mTitle}
                  image={rundown.mThumbnailUrl || image}
                  startTime={(rundown.recurrence && rundown.recurrence.startTime) || '00:00:00'}
                  timeZone={rundown.recurrence && rundown.recurrence.timeZone}
                  plan={rundown.recurrence.duration}
                  difference={rundown.difference}
                  id={rundown.mId}
                  rundowntype={rundown.mType}
                  selectedDates={selectedDates}
                  {...{ setPreviewRundown }}
                />
              ),
          )}
        </List>
      </Group>
      <Popover onClose={() => setAnchorEl(null)} anchorEl={anchorEl} position="right">
        <CreateRundown
          variant="masterRundown"
          onCancel={() => setAnchorEl(null)}
          onCreate={onCreateMasterRundown}
        />
      </Popover>
    </>
  );
};

GroupContainer.propTypes = {
  /** Title of the list group */
  title: PropTypes.string,
  /** selected dates */
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  /** set the preview rundown */
  setPreviewRundown: PropTypes.func,
};

GroupContainer.defaultProps = {
  title: 'Group',
  selectedDates: [],
  setPreviewRundown: () => {},
};

export default GroupContainer;
