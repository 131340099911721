import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '0 16px',
  },
  svg: {
    '& .zoomed': {
      cursor: 'move',
    },
    '& .domain': {
      display: 'none',
    },
  },
}));

export default useStyles;
