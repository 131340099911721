const params =
  'utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels';

const imageData = [
  {
    title: 'JacobMorch01.png',
    credit: 'Jacob Morch',
    url: `https://www.pexels.com/@jacobmorch?${params}`,
  },
  {
    title: 'LukasKloeppel.png',
    credit: 'Lukas Kloeppel',
    url: `https://www.pexels.com/@lkloeppel?${params}`,
  },
  {
    title: 'TobiasBjorkli01.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli02.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli03.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli04.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli05.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli06.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli07.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli08.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli09.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli10.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli11.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli12.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'TobiasBjorkli13.png',
    credit: 'Tobias Bjørkli',
    url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
  },
  {
    title: 'ValdemarasD01.png',
    credit: 'Valdemaras D.',
    url: `https://www.pexels.com/@valdemaras-d-784301?${params}`,
  },
];

export default imageData;
