import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    backgroundColor: palette.dina.timelineVideoClip,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    '& > :first-child': {
      marginLeft: 8,
      marginRight: 4,
    },
  },
  label: {
    ...typography.dina.button,
    color: palette.dina.blackHighEmphasis,
    fontWeight: 'normal',
    '&:hover': {
      color: palette.dina.blackHighEmphasis,
    },
  },
}));

export default useStyles;
