import gql from 'graphql-tag';

export default gql`
  mutation updateStoryPublishingDate($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mTitle
      mUpdatedAt
      mPublishingAt
      mCategories {
        label
        categoryId
      }
      mThumbnailUrl
    }
  }
`;
