import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '32px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
      '& $ellipsisButton': {
        opacity: 1,
      },
    },
  },
  boxShadow: {
    '&:focus': {
      boxShadow: `inset 0 0 0 1px ${theme.palette.dina.onFocus}`,
      backgroundColor: theme.palette.dina.inputBackgroundFocused,
    },
  },
  thumbnail: {
    width: '56px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallThumbnail: {
    width: 24,
    height: 24,
  },
  fileName: {
    flex: 1,
    ...theme.typography.dina.captionRegular,
    color: theme.palette.dina.highEmphasis,
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  coverPhotoContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '24px',
  },
  coverPhotoIcon: {
    width: '24px',
    height: '24px',
  },
  fileSize: {
    width: '52px',
    display: 'flex',
    justifyContent: 'flex-end',
    ...theme.typography.dina.captionRegular,
    color: theme.palette.dina.disabled,
  },
  ellipsisButton: {
    width: '24px',
    height: '24px',
    margin: '0 16px 0 8px',
    opacity: 0,
  },
  popover: {
    position: 'relative',
  },
  triangle: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate3d(0, -100%, 0)',
    borderBottom: `16px solid ${theme.palette.dina.surfaceDialogs}`,
    borderLeft: `16px solid transparent`,
  },
  divider: {
    margin: '0 16px 8px',
  },
}));

export default useStyles;
