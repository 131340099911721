import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MenuView from '../menu';

const useStyles = makeStyles(theme => ({
  verticalDivider: {
    height: '31px',
    width: '4px',
    backgroundColor: 'transparent',
  },
  selectDiv: { display: 'flex' },
}));

const RenderSelectView = ({
  items,
  value,
  updateInputField,
  showIconInput,
  width,
  disableEdit,
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const stopPropagation = event => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const classes = useStyles();
  return (
    <div
      role="presentation"
      onKeyDown={stopPropagation}
      onClick={stopPropagation}
      className={classes.selectDiv}
    >
      <MenuView
        {...{ items, showIconInput, selectedValue, width, disableEdit }}
        onChange={val => {
          updateInputField(val);
          setSelectedValue(val);
        }}
      />
      <div className={classes.verticalDivider} />
    </div>
  );
};

export default RenderSelectView;
