import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    flexGrow: 1,
    '& span:first-child': {
      flex: 1,
    },
    '& span:last-child': {
      flex: 1,
    },
  },
  date: {
    ...theme.typography.dina.h6,
    textTransform: 'capitalize',
  },
  toggleButtons: {
    '& span': {
      margin: '0 4px',
    },
  },
  dropdown: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
