import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  scheduleButton: {
    border: `0.5px solid ${theme.palette.dina.buttonBackgroundOutlined}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    height: 32,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    '&:hover': {
      '& > :first-child': {
        '& path': {
          transition: '0.2s ease-in',
          fillOpacity: 1,
        },
      },
      '& > :last-child': {
        transition: '0.2s ease-in',
        color: theme.palette.dina.highEmphasis,
      },
    },
  },
  calendarIcon: {
    marginLeft: '8px',
    marginRight: '4px',
  },
  schedule: {
    ...theme.typography.dina.button,
    fontWeight: 'normal',
    marginRight: '16px',
    color: theme.palette.dina.mediumEmphasis,
    '&:hover': {
      color: theme.palette.dina.mediumEmphasis,
    },
  },
  backdropRoot: {
    position: 'absolute',
  },
}));

export default useStyles;
