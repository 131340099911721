import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { getMonth, setMonth, getYear, setYear, isAfter, startOfDay, endOfDay } from 'date-fns';
// eslint-disable-next-line max-len
import { ReactComponent as ArrowLeft } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
// eslint-disable-next-line max-len
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import Header from './header';
import DateSelector from './dateSelector';
import MonthSelector from './monthSelector';
import YearSelector from './yearSelector';
import useStyles from './calendar-styles';

const Calendar = ({
  selectedDate,
  changeSelectedDate,
  selectedDateRange,
  changeSelectedDateRange,
  selectRange,
  hideUnscheduleButton,
  onUnschedule,
  isExpanded,
  setIsExpanded,
  ...rest
}) => {
  const dateValue = selectedDate || new Date().toISOString();
  const [date, setDate] = useState(dateValue);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [openDrawer, setOpenDrawer] = useState(isExpanded);
  const [isSelectingRange, setIsSelectingRange] = useState(false);
  const classes = useStyles({ openDrawer });

  const { startDate: sDate, endDate: eDate } = selectedDateRange;
  const [startDate, setStartDate] = useState(sDate);
  const [endDate, setEndDate] = useState(eDate);

  useEffect(() => {
    setSelectedMonth(getMonth(date));
    setSelectedYear(getYear(date));
  }, [date]);

  useEffect(() => {
    setStartDate(sDate);
    setEndDate(eDate);
  }, [eDate, sDate]);

  const handleToggleSelectRange = () => {
    setIsSelectingRange(!isSelectingRange);
  };

  const handleToggleDrawer = () => {
    setIsExpanded(!isExpanded);
    setOpenDrawer(!openDrawer);
  };

  const handleRangeSelect = newDate => {
    if (!startDate || endDate) {
      setStartDate(startOfDay(newDate).toISOString());
      setEndDate(null);
      return;
    }
    let firstDate;
    let lastDate;
    if (isAfter(newDate, startDate)) {
      firstDate = startDate;
      lastDate = endOfDay(newDate).toISOString();
    } else {
      firstDate = startOfDay(newDate).toISOString();
      lastDate = endOfDay(startDate).toISOString();
    }
    setStartDate(firstDate);
    setEndDate(lastDate);
    changeSelectedDateRange({ startDate: firstDate, endDate: lastDate });
  };

  const handleDateSelect = newDate => {
    if (isSelectingRange) handleRangeSelect(newDate);
    else changeSelectedDate(newDate);
  };

  const onMonthSelect = newMonth => {
    setSelectedMonth(newMonth);
    setDate(setMonth(date, newMonth).toISOString());
  };

  const onYearSelect = newYear => {
    setSelectedYear(newYear);
    setDate(setYear(date, newYear).toISOString());
  };

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Header
          {...{ date, setDate, openDrawer, selectRange, onUnschedule, isSelectingRange }}
          toggleSelectRange={handleToggleSelectRange}
          hideUnscheduleButton={hideUnscheduleButton || !(selectedDate || sDate || eDate)}
        />
        <div className={classes.body}>
          <div className={classes.dateSelector}>
            <DateSelector
              {...{
                date,
                setDate,
                selectedDate,
                startDate,
                endDate,
                isSelectingRange,
                ...rest,
              }}
              selectRange={selectRange}
              onDateSelect={handleDateSelect}
            />
          </div>
          <div className={classes.selectors}>
            <div className={classes.monthSelector}>
              <MonthSelector month={selectedMonth} onMonthSelect={onMonthSelect} />
            </div>
            <div className={classes.yearSelector}>
              <YearSelector year={selectedYear} onYearSelect={onYearSelect} />
            </div>
          </div>
        </div>
      </div>
      <Button classes={{ root: classes.button }} onClick={handleToggleDrawer}>
        {openDrawer ? <ArrowLeft /> : <ArrowRight />}
      </Button>
    </div>
  );
};

Calendar.propTypes = {
  /** selected date of the calendar */
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** callback to change the selected date */
  changeSelectedDate: PropTypes.func,
  /** Boolean that shows select range button */
  selectRange: PropTypes.bool,
  /** Boolean that hides unschedule button */
  hideUnscheduleButton: PropTypes.bool,
  /** Callback on unschedule button click */
  onUnschedule: PropTypes.func,
  /** Boolean to make the calendar component expanded */
  isExpanded: PropTypes.bool,
  /** SetState callback to set isExpanded in the globalState */
  setIsExpanded: PropTypes.func,
  /** callback to change selected date */
  changeSelectedDateRange: PropTypes.func,
  /** range of selected date */
  selectedDateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
};

Calendar.defaultProps = {
  selectedDate: '',
  changeSelectedDate: date => {},
  selectRange: false,
  hideUnscheduleButton: false,
  onUnschedule: () => {},
  isExpanded: false,
  setIsExpanded: () => {},
  changeSelectedDateRange: () => {},
  selectedDateRange: {
    startDate: null,
    endDate: null,
  },
};

export default Calendar;
