import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Assignees from 'components/avatarGroup/assignees';
import Dialog from 'components/dialog';
import AddMember from 'components/addMemberDialog';

const AssigneesView = ({ assignees, onAssigneeUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const handleAssignment = updatedAssignees => {
    closeDialog();
    onAssigneeUpdate(updatedAssignees);
  };

  return (
    <div>
      <Assignees showEmptyPlaceholder size={30} onClick={openDialog} {...{ assignees }} />

      <Dialog {...{ open }} onClose={closeDialog}>
        <AddMember
          members={assignees}
          variant="People"
          dialogTitle="People"
          showItems={false}
          onCancel={closeDialog}
          onOk={handleAssignment}
          {...rest}
        />
      </Dialog>
    </div>
  );
};

AssigneesView.propTypes = {
  /** List of users assigned to current instance */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Callback to be invoked user assignees are updated  */
  onAssigneeUpdate: PropTypes.func,
};

AssigneesView.defaultProps = {
  assignees: [],
  onAssigneeUpdate: updatedAssignees => {},
};

export default AssigneesView;
