/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { outTimingTypes } from 'components/editor/constants/types';
import useStyles from './styles';

const { MANUAL_OUT, ITEM_OUT, INSTANCE_OUT, AUTO_OUT } = outTimingTypes;

const middleStyle = {
  [MANUAL_OUT]: 'manual',
  [AUTO_OUT]: null,
  [ITEM_OUT]: 'itemOut',
  [INSTANCE_OUT]: 'instanceOut',
};

const OutIconView = ({ outTimingType, isGraphic, ...rest }) => {
  const isManual = outTimingType === MANUAL_OUT;
  const classes = useStyles({ isGraphic, isManual });
  const middleStyleName = middleStyle[outTimingType];

  return (
    <div {...rest}>
      <div className={classes.root}>
        <div className={classes.top} />
        <div className={classes[middleStyleName]} />
        <div className={classes.bottom} />
      </div>
    </div>
  );
};

OutIconView.propTypes = {
  /** Specifies if the type is 'OVERLAY_GRAPHICS' */
  isGraphic: PropTypes.bool,
  /** Timing type for out */
  outTimingType: PropTypes.oneOf(Object.values(outTimingTypes)),
};

OutIconView.defaultProps = {
  isGraphic: false,
  outTimingType: MANUAL_OUT,
};

export default memo(OutIconView);
