import React from 'react';
import { Button } from '@material-ui/core';
import useStyles from './edit-master-styles';

const EditModeWrapper = ({
  children,
  title,
  showMasterEditView,
  onDoneEditing,
  onArchiveMasterRundown,
}) => {
  const classes = useStyles();
  return showMasterEditView ? (
    <div className={classes.mainDiv}>
      <div className={classes.masterTitle}>
        <span>
          Editing Rundown Master: <b>{title}</b>
        </span>
      </div>
      {children}
      <div className={classes.footer}>
        <div className={classes.closeDiv}>
          <Button
            variant="contained"
            classes={{ root: classes.deleteButton }}
            onClick={onArchiveMasterRundown}
          >
            Archive Master
          </Button>
          <Button
            variant="contained"
            classes={{ root: classes.doneButton }}
            onClick={onDoneEditing}
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className={classes.emptyDiv}>{children}</div>
  );
};

export default EditModeWrapper;
