import React, { useState } from 'react';
import Divider from 'components/divider';
import PropTypes from 'prop-types';
import {
  Typography,
  ListItem as MaterialListItem,
  ClickAwayListener,
} from '@material-ui/core';
import ICONS from 'assets/icons/icons';
import IconButton from 'components/buttons/iconButton';
import Avatar from 'components/avatar';
import useStyles from './listItem-styles';
import ActionButton from './listAction-button';

const ListItem = ({
  variant,
  src,
  name,
  info,
  durationInfo,
  taskInfo,
  onDelete,
  index,
  length,
  ...rest
}) => {
  const classes = useStyles();
  const initialTranslateX = 112;
  const [translateX, setTranslateX] = useState(initialTranslateX);

  return (
    <ClickAwayListener onClickAway={() => setTranslateX(initialTranslateX)}>
      <div>
        <MaterialListItem button classes={{ root: classes.root }}>
          <Avatar
            size={40}
            variant={variant}
            src={src}
            className={classes.avatar}
            {...rest}
          />
          <div className={classes.info}>
            <Typography classes={{ root: classes.name }}>{name}</Typography>
            <Typography classes={{ root: classes.title }}>{info}</Typography>
          </div>
          <div>
            <Typography classes={{ root: classes.secondaryInfo }}>
              {durationInfo}
            </Typography>
            <Typography classes={{ root: classes.secondaryInfo }}>
              {taskInfo}
            </Typography>
          </div>
          <div className={classes.ellipsis}>
            <IconButton
              icon={ICONS.MORE_VERTICAL}
              width={4}
              height={16}
              onClick={event => setTranslateX(0)}
            />
          </div>
          <IconButton
            className={classes.iconButton}
            title="Preview story"
            icon={ICONS.DISCLOSURE_ARROW_RIGHT}
            onClick={() => []}
          />
          <ActionButton
            tabIndex="-1"
            translateX={translateX}
            onClose={() => setTranslateX(initialTranslateX)}
            onDelete={() => {
              onDelete();
              setTranslateX(initialTranslateX);
            }}
          />
        </MaterialListItem>
        {index < length - 1 && <Divider style={{ marginLeft: '60px' }} />}
      </div>
    </ClickAwayListener>
  );
};

ListItem.propTypes = {
  variant: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  info: PropTypes.string,
  durationInfo: PropTypes.string,
  taskInfo: PropTypes.string,
  onDelete: PropTypes.func,
  length: PropTypes.number,
};

ListItem.defaultProps = {
  variant: 'People',
  src: '',
  name: '',
  info: '',
  durationInfo: '',
  taskInfo: '',
  onDelete: () => {},
  length: 0,
};
export default ListItem;
