import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './notificationsList-styles';
import NotificationCard from '../card';

const NotificationsList = props => {
  const classes = useStyles();
  const { messages, openStory } = props;

  const emptyNotification = {
    message: [''],
    messageType: 'share',
    updatedBy: 'No notifications',
    messageTypeText: '-',
    time: new Date().toISOString(),
  };

  const emptyState = <NotificationCard key="1" {...{ ...emptyNotification }} />;

  const displayMessages = messages.sort((a, b) => (a.time < b.time ? 1 : -1));

  const List = ({ items }) => {
    const listItems = displayMessages.map((notification, index) => (
      <NotificationCard
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        avatar={notification.avatar}
        time={notification.time}
        updatedBy={notification.updatedBy}
        message={notification.message}
        messageType={notification.messageType}
        messageTypeText={notification.messageTypeText}
        itemId={notification.itemId}
        itemTitle={notification.itemTitle}
        itemType={notification.itemType}
        openStory={openStory}
      />
    ));

    return (
      <ul className={classes.list}>
        {listItems.length < 1 ? emptyState : listItems}
      </ul>
    );
  };

  return (
    <div className={classes.listContainer}>
      <Typography className={classes.header}>Notifications</Typography>
      <List />
    </div>
  );
};

NotificationsList.propTypes = {
  /** notifications */
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      time: PropTypes.string,
      updatedBy: PropTypes.string,
      message: PropTypes.arrayOf(PropTypes.string),
      messageType: PropTypes.string,
      messageTypeText: PropTypes.string,
      itemId: PropTypes.string,
      itemTitle: PropTypes.string,
      itemType: PropTypes.string,
    }),
  ),
};

NotificationsList.defaultProps = {
  messages: [],
};

export default NotificationsList;
