import React, { memo, useState, useCallback } from 'react';
import { useSlate } from 'slate-react';
import PropTypes from 'prop-types';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import useInputEvents from 'hooks/useInputEvents';
import { ReactComponent as TagFriendsIcon } from 'assets/icons/systemicons/editor/user_tag.svg';
import { ReactComponent as TagIcon } from 'assets/icons/systemicons/editor/label.svg';
import updateBlock from 'components/editor/utils/updateBlock';
import InputBlock from '../inputBlock';
import useStyles from './styles';

const getLegends = isFacebook => {
  if (isFacebook)
    return {
      Icon: TagFriendsIcon,
      title: 'Tag Friends',
      description: '',
      placeholder: 'Start typing to find...',
    };
  return {
    Icon: TagIcon,
    title: 'Tags',
    description: 'Example: “politics, election, president”',
    placeholder: 'Enter tags here...',
  };
};

const Tags = ({ attributes, children, element, readOnly, type }) => {
  const isFacebook = type === 'facebook';
  const { data } = element;
  const editor = useSlate();
  const { update } = useEditorContext();
  const classes = useStyles();
  const initialValue = data.content || '';
  const [value, setValue] = useState(initialValue);
  const { Icon, title, description, placeholder } = getLegends(isFacebook);

  const updateTags = useCallback(
    newTitle => {
      const updatedData = {
        ...data,
        content: newTitle,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const onUpdate = useCallback(
    newValue => {
      if (newValue === initialValue) setValue(initialValue);
      else updateTags(newValue);
    },
    [initialValue, updateTags],
  );

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(onUpdate, value, initialValue);

  const handleChange = useCallback(val => {
    setValue(val);
  }, []);

  return (
    <div {...attributes}>
      {children}
      <InputBlock
        type={type}
        label={title}
        icon={<Icon className={classes.icon} />}
        hideEllipsisButton
        description={description}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        inputRef={inputRef}
        onKeyDown={handleKeydown}
        onBlur={handleBlur}
      />
    </div>
  );
};

Tags.propTypes = {
  /**
   * whether tags is readonly on not
   */
  readOnly: PropTypes.bool,
  /**
   * type of the tag box
   */
  type: PropTypes.string,
};

Tags.defaultProps = {
  readOnly: false,
  type: '',
};

export default memo(Tags);
