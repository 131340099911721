import React, { useContext, useState, useEffect, useRef } from 'react';
import { CurrentTabContext, useSidebarContext } from 'globalState';
import useReleaseEditorLock from 'hooks/useReleaseEditorLock';
import memberTypes from 'graphql/memberTypes';
import Thumbnail from './components/header/navbar/scratchPad/components/thumbnail';
import Header from './components/header';
import LeftArea from './components/leftArea';
import RightArea from './components/rightArea';
import Story from '../story';
import Rundown from '../rundown';
import Feeds from '../feed';
import Plans from '../planning';
import Home from '../home';
import useStyles from './main-styles';

const Main = () => {
  const classes = useStyles();
  const [currentTab] = useContext(CurrentTabContext);
  const [thumbnailData, setThumbnailData] = useState([]);
  const { page, id, mid, image, title, selecteddate } = currentTab;
  const [
    { isLeftHidden, leftSelection, isRightHidden, rightSelection, isRightExpanded, contactTab },
    dispatch,
  ] = useSidebarContext();
  const timeOut = useRef(null);
  const [releaseEditorLock] = useReleaseEditorLock();

  useEffect(() => {
    releaseEditorLock(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, id, selecteddate]);

  let mainContent;
  switch (page) {
    case 'home':
      mainContent = <Home />;
      break;
    case 'feeds':
      mainContent = <Feeds />;
      break;
    case 'plans':
      mainContent = <Plans />;
      break;
    case 'create':
      mainContent = id && <Story storyId={id} imgUrl={image} />;
      break;
    case 'story':
      mainContent = id && <Story storyId={id} imgUrl={image} />;
      break;
    case 'pitch':
      mainContent = id && <Story variant={memberTypes.PITCH} storyId={id} imgUrl={image} />;
      break;
    case 'rundown':
      mainContent = <Rundown rundownId={id} mid={mid} title={title} selecteddate={selecteddate} />;
      break;
    default:
      mainContent = <Home />;
      break;
  }

  const Center = mainContent;

  useEffect(() => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
      timeOut.current = null;
    }
    timeOut.current =
      thumbnailData.length &&
      setTimeout(() => {
        setThumbnailData([]);
      }, 12000);
  }, [thumbnailData, setThumbnailData]);

  const getClassName = (rightHidden, rightExpanded) => {
    if (rightHidden) return classes.rightMarginClear;
    if (rightExpanded) return classes.rightMarginExp;
    return classes.rightMargin;
  };
  return (
    <>
      <Header {...{ thumbnailData, setThumbnailData }} />
      <div className={classes.content}>
        <div className={classes.sideContent}>
          <LeftArea isLeftHidden={isLeftHidden} leftSelection={leftSelection} dispatch={dispatch} />
        </div>

        <div
          className={`${classes.center} ${
            !isRightHidden ? classes.rightMargin : classes.rightMarginClear
          } ${!isLeftHidden ? classes.leftMargin : classes.leftMarginClear} ${getClassName(
            isRightHidden,
            isRightExpanded,
          )}`}
        >
          <div className={classes.centerContent}>
            {
              <div className={classes.thumb}>
                {thumbnailData.length > 0 && <Thumbnail data={thumbnailData} />}
              </div>
            }
            {Center}
          </div>
        </div>
        <div className={classes.sideContent}>
          <RightArea
            isRightHidden={isRightHidden}
            isRightExpanded={isRightExpanded}
            rightSelection={rightSelection}
            contactTab={contactTab}
            dispatch={dispatch}
          />
        </div>
      </div>
    </>
  );
};

export default Main;
