import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/IconButton';
import Tooltip from 'components/tooltip';
import Icon from 'components/icon';
import useHover from 'hooks/useHover';
import useStyles from './iconButton-styles';

const IconButton = ({ onClick, icon, title, height, width, ...rest }) => {
  const classes = useStyles();
  const [hoverRef, isHovered] = useHover();

  return (
    <Tooltip title={title}>
      <Button ref={hoverRef} classes={{ root: classes.buttonRoot }} onClick={onClick} {...rest}>
        <Icon icon={icon} height={height} width={width} hovered={isHovered} />
      </Button>
    </Tooltip>
  );
};

IconButton.propTypes = {
  /** Callback to be invoked on icon button is clicked */
  onClick: PropTypes.func,
  /** icon to use */
  icon: PropTypes.string.isRequired,
};

IconButton.defaultProps = {
  onClick: () => {},
};

export default IconButton;
