import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/lab/ToggleButton';
import useStyles from './toggleButton-styles';

const ToggleButton = props => {
  const { selected, rundown, onClick, children } = props;
  const classes = useStyles(selected, rundown)();

  return (
    <Button
      data-testid="toggleButton"
      onClick={onClick}
      className={classes.button}
      classes={{ root: classes.root }}
      value={selected}
    >
      {children}
    </Button>
  );
};

ToggleButton.propTypes = {
  // selected value
  selected: PropTypes.bool,
  // button value
  children: PropTypes.string,
  // callback to the onClick function
  onClick: PropTypes.func,
  /** Is it part of rundown? the rundown has a different color */
  rundown: PropTypes.bool,
};

ToggleButton.defaultProps = {
  selected: false,
  children: '',
  onClick: () => {},
  rundown: false,
};

export default ToggleButton;
