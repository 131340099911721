import { makeStyles } from '@material-ui/styles';

const useStyles = image =>
  makeStyles(theme => ({
    searchbar: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '40px',
      background: theme.palette.dina.blackHoverOverlay,
      padding: '4px',
      display: 'flex',
      justifyContent: 'stretch',
    },
    inputContainer: {
      display: 'flex',
      flexGrow: 1,
      position: 'relative',
    },
    input: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      '& input': {
        width: '100%',
        height: '30px',
        background: theme.palette.dina.blackHoverOverlay,
        border: `1px solid ${theme.palette.dina.blackHoverOverlay}`,
        borderRadius: '4px',
        ...theme.typography.dina.caption,
        color: theme.palette.dina.highEmphasis,
        paddingLeft: '32px',
        padding: 0,
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '16px 16px',
        backgroundPosition: '12px center',
      },
    },
    inputForm: {
      width: '100%',
      '& input': {
        width: '100%',
        height: '32px',
        background: theme.palette.dina.blackHoverOverlay,
        border: `1px solid ${theme.palette.dina.blackHoverOverlay}`,
        borderRadius: '4px',
        ...theme.typography.dina.caption,
        color: theme.palette.dina.highEmphasis,
        paddingLeft: '32px',
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '16px 16px',
        backgroundPosition: '12px center',
        '&::placeholder': {
          color: theme.palette.dina.watermark,
        },
      },
    },
    chipContainer: {
      marginBottom: 0,
    },
    chip: {
      margin: '0 4px',
      alignSelf: 'center',
      backgroundColor: theme.palette.dina.blackHoverOverlay,
      borderRadius: '4px',
      ...theme.typography.dina.caption,
      '&:hover': {
        backgroundColor: theme.palette.dina.whiteHoverOverlay,
      },
      '& svg': {
        color: 'transparent',
        '&:hover': {
          color: theme.palette.dina.mediumEmphasis,
        },
      },
    },
    clearInputButton: {
      position: 'absolute',
      right: '0',
      alignSelf: 'center',
      width: '48px',
      height: '32px',
    },
    searchBarButton: {
      background: theme.palette.dina.blackHoverOverlay,
      width: '48px',
      height: '32px',
      borderRadius: '4px',
      marginLeft: '4px',
      padding: 0,
      '&:hover': {
        background: theme.palette.dina.blackHoverOverlay,
      },
    },
    searchbarIcon: {
      width: 20,
    },
  }));

export default useStyles;
