import React from 'react';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/plus_ultraSmall_1px.svg';
import { useDrop } from 'react-dnd';
import dndTypes from 'utils/dndTypes';
import useStyles from './grid-styles';

const ExtendedGrid = ({ scrollingId, remainingGridHeight, disableEdit, ...props }) => {
  const classes = useStyles();
  const [{ hovered, dragType }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.INSTANCE, dndTypes.GRID_INSTANCE],
    canDrop: (item, monitor) => !disableEdit,
    drop: (item, monitor) => {
      const { type, title, id, publishingAt } = monitor.getItem();
      if (type && type === dndTypes.GRID_INSTANCE) {
        const { payload } = monitor.getItem();
        const result = {
          source: {
            index: payload.index,
            droppableId: payload.scrollingId,
          },
          destination: {
            index: props.listLength,
            droppableId: scrollingId,
          },
        };
        props.onDragEnd(result);
      } else if (type && type === dndTypes.INSTANCE) {
        const { payload } = monitor.getItem();
        const { id: instanceId, platform } = payload;
        if (platform === 'linear') {
          props.onMoveInstanceToRundown(instanceId, props.listLength);
        }
      } else if (!props.showMasterEditView)
        props.onCreateInstanceForStory(id, title, publishingAt, props.listLength);
    },
    collect: monitor => ({
      hovered: monitor.isOver(),
      dragType: monitor.getItemType(),
    }),
  });
  /** Show Extended div if remaining grid height more than 50 px */
  const shouldShowExtendedDiv = remainingGridHeight > 50;
  const showPlus = dragType !== dndTypes.GRID_INSTANCE;

  return (
    shouldShowExtendedDiv && (
      <div
        ref={dropRef}
        id="extended-div"
        className={classes.extendedGrid}
        style={{
          height: remainingGridHeight,
        }}
      >
        {hovered && !disableEdit && (
          <div className={classes.fabDiv}>
            {showPlus && <AddIcon className={`${classes.addIcon} ${classes.selectionCount}`} />}
          </div>
        )}
      </div>
    )
  );
};

export default ExtendedGrid;
