import { makeStyles } from '@material-ui/styles';

const width = 24;
const height = 24;
const fontSize = 16;

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width,
    height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    fontSize,
    color: ({ isActive }) =>
      isActive ? palette.dina.onSelected : palette.dina.iconInactive,
  },
}));

export default useStyles;
