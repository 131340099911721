const notifyConfig = {
  usr_str: { notify: true, currentUserOnly: true },
  tea_str: { notify: false, currentUserOnly: false },
  dep_str: { notify: false, currentUserOnly: false },
  dep_tea: { notify: false, currentUserOnly: false },
  str_ins: { notify: false, currentUserOnly: false },
  usr_ins: { notify: true, currentUserOnly: true },
  tea_usr: { notify: false, currentUserOnly: false },
  usr_pitch:{ notify: true, currentUserOnly: true },
};

export default notifyConfig;
