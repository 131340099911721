import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '32px',
    display: 'flex',
    justifyContent: 'space-between',
    transform: 'translate3d(0, 0, 0)',
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },
  avatar: {
    marginLeft: '12px',
  },
  middleComponents: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    width: 'calc(100% - 72px)',
  },
  title: {
    ...theme.typography.dina.captionRegular,
    color: theme.palette.dina.highEmphasis,
    letterSpacing: '0.25px',
    marginLeft: '8px',
    width: 'calc(100% - 74px)',
  },
  schedule: {
    ...theme.typography.dina.captionRegular,
    width: '74px',
    display: '-webkit-box',
    lineClamp: 2,
    '-webkit-box-orient': 'vertical',
    '-webkit-box-align': 'center',
    overflow: 'hidden',
    textAlign: 'right',
    letterSpacing: '0.25px',
    color: theme.palette.dina.disabled,
  },
  warning: {
    color: theme.palette.dina.statusWarning,
  },
  statusComponent: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
  },
}));

export default useStyles;
