import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  assignmentInfo: {
    marginRight: '4px',
    textAlign: 'right',
  },
  updatedAt: {
    ...theme.typography.dina.storyCardUpdated,
  },
  assigneeName: {
    ...theme.typography.dina.storyCardUsername,
  },
  emptyDiv: {
    height: '12px',
  },
}));

export default useStyles;
