import { makeStyles } from '@material-ui/styles';

const useStyles = (width, height) => {
  return makeStyles(theme => ({
    noSrcRoot: {
      width,
      height,
      backgroundColor: theme.palette.dina.blackHoverOverlay,
      border: `1px dashed ${theme.palette.dina.inputBorderResting}`,
      borderRadius: '8px',
    },
    root: {
      width,
      height,
      borderRadius: '8px',
    },
    templateDiv: {
      position: 'absolute',
      height: 40,
      width: 40,
      borderRadius: '0px 0px 0px 8px',
      overflow: 'hidden',
      bottom: 0,
      left: 0,
    },
    template: {
      borderBottom: `42px solid ${theme.palette.dina.statusWarning}`,
      borderRight: '42px solid transparent',
    },
    image: {
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    publishingPoint: {
      position: 'absolute',
      width: '20px',
      height: '20px',
      bottom: '60%',
      right: '60%',
    },
  }));
};

export default useStyles;
