import React, { useState, useEffect } from 'react';
import Divider from 'components/divider';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
// import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
// import { ReactComponent as DockIcon } from 'assets/icons/systemicons/dock.svg';
import Tooltip from 'components/tooltip';
import TitleInput from '../titleInput';
import useStyles from './titleBox-styles';

const TitleBox = ({ initialTitle, instanceId, onTitleUpdate, onDock, onClose, disableEdit }) => {
  const classes = useStyles();
  const [title, setTitle] = useState(initialTitle);
  const [showTitleInput, setShowTitleInput] = useState(false);

  useEffect(() => setTitle(initialTitle), [initialTitle]);
  useEffect(() => {
    setShowTitleInput(false);
  }, [instanceId]);

  const handleShowInput = event => {
    event.preventDefault();
    event.stopPropagation();
    !disableEdit && setShowTitleInput(!showTitleInput);
  };

  const handleCloseInput = event => {
    setShowTitleInput(!showTitleInput);
  };

  const onClickAway = () => {
    setTitle(initialTitle);
    setShowTitleInput(!showTitleInput);
  };

  return showTitleInput ? (
    <TitleInput
      {...{ title, setTitle, initialTitle, onClickAway }}
      onClose={handleCloseInput}
      onUpdate={onTitleUpdate}
    />
  ) : (
    <div className={classes.titleContainer}>
      <div className={classes.titleDiv}>
        <Typography onClick={handleShowInput} classes={{ root: classes.title }}>
          {title}
        </Typography>
      </div>
      <div className={classes.options}>
        {/* Removed for now, as User might get confused with the close icon */}
        {/* {onDock && <DockIcon className={classes.leftIcon} onClick={onDock} />} */}
        {/* <CloseIcon className={classes.rightIcon} onClick={onClose} /> */}
      </div>
      <Divider className={classes.divider} />
    </div>
  );
};

TitleBox.propTypes = {
  /** Initial title for the instance */
  initialTitle: PropTypes.string,
  /** mId of the instance */
  instanceId: PropTypes.string,
  /** onTitleUpdate function */
  onTitleUpdate: PropTypes.func,
  /** onDock callback: if not defined won't show */
  onDock: PropTypes.func,
  /** onMaximize callback: if not defined won't show */
  onMaximize: PropTypes.func,
  /** onClose callback: will always show */
  onClose: PropTypes.func,
};

TitleBox.defaultProps = {
  initialTitle: '',
  instanceId: '',
  onTitleUpdate: () => {},
  onDock: undefined,
  onMaximize: undefined,
  onClose: () => {},
};

export default TitleBox;
