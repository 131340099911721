import { v1 } from 'uuid';

/**
 * Generates unique name for editor asset
 *
 * @param {String} type MimeType for the asset
 * @returns {String} Name for the asset
 */

const generateName = type => {
  const [, extention] = type.split('/');

  return `editor-${v1()}.${extention}`;
};

export default generateName;
