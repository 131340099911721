import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => {
  const buttonStyle = {
    ...theme.typography.dina.button,
    width: '100%',
    borderRadius: '4px',
    height: '40px',
    textTransform: 'none',
    '&:focus': {
      boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
    },
  };
  return {
    container: {
      width: '380px',
      height: ({ size }) => size,
      minHeight: '298px',
      maxHeight: '397px',
      background: theme.palette.dina.surfaceCard,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: '8px',
      margin: '-8px 0',
    },
    topComponents: {
      marginLeft: '16px',
    },
    selectContainer: {
      position: 'absolute',
      height: '48px',
      left: '16px',
      right: '16px',
      top: '108px',
    },
    title: {
      ...theme.typography.dina.marketplaceHeader,
      margin: '24px 0 16px 0',
    },
    subTitle: {
      ...theme.typography.dina.overline,
      marginTop: '7px',
    },
    radioText: {
      ...theme.typography.dina.captionRegular,
      color: theme.palette.dina.highEmphasis,
      marginLeft: '5px',
    },
    blank: {
      height: '16px',
    },
    dateTimePicker: {
      display: 'flex',
      position: 'absolute',
      justifyContent: 'space-between',
      height: '69px',
      left: '20px',
      right: '16px',
      top: '244px',
    },
    linearDateTimePicker: {
      display: 'flex',
      position: 'absolute',
      justifyContent: 'space-between',
      height: '48px',
      left: '20px',
      right: '16px',
      top: '175px',
    },
    linearDatePicker: {
      width: '100%',
    },
    datePicker: {
      width: '202px',
    },
    timePicker: {
      width: '134px',
    },
    timePickerDisabled: {
      width: '150px',
      opacity: 0.35,
    },
    radioButtonContainer: {
      position: 'absolute',
      width: '192px',
      height: '64px',
      left: '17px',
      top: '164px',
      display: 'flex',
      flexDirection: 'column',
    },
    unscheduleRadioButton: {
      position: 'static',
      width: '192px',
      height: '32px',
      left: '0px',
      top: '0px',
      flex: 'none',
      margin: '0px 0px',
    },
    scheduleRadioButton: {
      position: 'static',
      width: '192px',
      height: '32px',
      left: '0px',
      top: '32px',
      flex: 'none',
      margin: '0px 0px',
    },
    reset: {
      ...theme.typography.dina.captionRegular,
      color: theme.palette.dina.mediumEmphasis,
      paddingRight: '12px',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.dina.highEmphasis,
      },
      '&:disabled': {
        opacity: 0.35,
      },
      fontSize: '14px',
      letterSpacing: '.25px',
      fontWeight: '500',
    },
    resetDiv: {
      width: '180px',
      height: '32px',
      right: '10px',
      top: '300px',
      position: 'absolute',
    },
    linearResetDiv: {
      width: '169px',
      height: '32px',
      right: '-24px',
      top: '230px',
      position: 'absolute',
    },
    resetText: {
      position: 'absolute',
      left: '40px',
      right: '0px',
      top: 'calc(50% - (24px/2))',
      bottom: '0%',
    },
    unionIcon: {
      position: 'absolute',
      width: '24px',
      height: '24px',
      left: '12px',
      top: 'calc(50% - (24px/2))',
    },
    footer: {},
    buttonContainer: {
      display: 'flex',
    },
    cancelButton: {
      ...buttonStyle,
      margin: '8px 4px 8px 8px',
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
      },
    },
    okButton: {
      ...buttonStyle,
      margin: '8px 8px 8px 8px',
      backgroundColor: theme.palette.dina.paletteAccentPurple,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        backgroundColor: theme.palette.dina.paletteAccentPurple,
      },
    },
    publishButton: {
      ...buttonStyle,
      margin: '8px 8px 8px 4px',
      backgroundColor: theme.palette.dina.paletteAccentOrange,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        backgroundColor: theme.palette.dina.paletteAccentOrange,
      },
      '&:disabled': {
        color: theme.palette.dina.highEmphasis,
        opacity: 0.35,
      },
    },
    confirmPublishButton: {
      ...buttonStyle,
      margin: '8px 8px 8px 4px',
      backgroundColor: theme.palette.dina.paletteAccentRed,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        backgroundColor: theme.palette.dina.paletteAccentRed,
      },
    },
    scheduleButton: {
      ...buttonStyle,
      margin: '8px 8px 8px 4px',
      backgroundColor: theme.palette.dina.statusApproved,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        backgroundColor: theme.palette.dina.statusApproved,
      },
      '&:disabled': {
        color: theme.palette.dina.highEmphasis,
        opacity: 0.35,
      },
    },
  };
});

export default useStyles;
