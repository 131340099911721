import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import capitalize from 'utils/capitalize';
import useStyles from './datePicker-styles';

const DatePicker = ({
  text,
  isToday,
  isSelected,
  isOtherMonth,
  isWeekend,
  todaySelectorType,
  rangePosition,
}) => {
  const classes = useStyles();
  let containerClassName = null;
  let rootClassName = 'root';
  if (isToday) rootClassName = 'rootToday';
  if (isToday && todaySelectorType === 'diamond') rootClassName = 'rootTodaySelected';
  if (isToday && todaySelectorType === 'cropped' && !isSelected) containerClassName = 'container';
  if (isSelected) rootClassName = 'rootSelected';
  if (isSelected && rangePosition) rootClassName = `rootSelected${capitalize(rangePosition)}`;

  let textClassName = 'text';
  if (isWeekend && !isSelected) textClassName = 'textWeekend';
  if (isOtherMonth) textClassName = 'textDimmed';
  if (isToday && todaySelectorType === 'diamond' && !isSelected)
    textClassName = 'textTodaySelectedDiamond';
  if (isSelected) textClassName = 'textSelected';

  return (
    <div className={classes[containerClassName]}>
      <div className={classes[rootClassName]}>
        <Typography classes={{ root: classes[textClassName] }}>{text}</Typography>
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  /** text within the date picker */
  text: PropTypes.string,
  /** boolean that indicates today's date */
  isToday: PropTypes.bool,
  /** boolean that indicates a selected date */
  isSelected: PropTypes.bool,
  /** boolean that indicates that the date is of different month */
  isOtherMonth: PropTypes.bool,
  /** boolean that indicates a weekend */
  isWeekend: PropTypes.bool,
  /** defines the type of today selector */
  todaySelectorType: PropTypes.oneOf(['default', 'cropped', 'diamond']),
  /** Position of datePicker in the selected range  */
  rangePosition: PropTypes.string,
};

DatePicker.defaultProps = {
  text: '',
  isToday: false,
  isSelected: false,
  isOtherMonth: false,
  isWeekend: false,
  todaySelectorType: 'diamond',
  rangePosition: '',
};

export default DatePicker;
