import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import useStyles from './yearSelector-styles';

const YearSelector = ({ year, onYearSelect }) => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
  const years = range(Math.min(currentYear - 5, year), Math.max(currentYear + 5, year), 1);

  return (
    <div className={classes.root}>
      <div className={classes.years}>
        {years.map(y => (
          <Button
            key={y}
            classes={{
              root: classes[year === y ? 'yearSelected' : 'year'],
              text: classes[year === y ? 'yearTextSelected' : 'yearText'],
            }}
            onClick={() => {
              onYearSelect(y);
            }}
          >
            {y}
          </Button>
        ))}
      </div>
    </div>
  );
};

YearSelector.propTypes = {
  year: PropTypes.number,
  onYearSelect: PropTypes.func,
};
YearSelector.defaultProps = {
  year: new Date().getFullYear(),
  onYearSelect: () => {},
};

export default YearSelector;
