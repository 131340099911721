import statusBoard from './statusBoard';

const returnState = stateId => {
  const { states } = statusBoard;
  const state = states.filter(s => s.id === stateId);
  if (state.length === 0) {
    return {
      id: 'undefined',
      name: 'Undefined',
      description: 'Undefined',
      backgroundColor: 'kanbanArchived',
      icon: 'todo',
    };
  }
  return state[0];
};

export default returnState;
