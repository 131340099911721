import { filter, sortBy } from 'lodash';

/**
 * Returns array that is filtered by filterCriteria and sorted by sorCriteria
 *
 * @param {Object[]} array that will be filtered and sorted
 * @param {Object} filterCriteria based on which the array will be filtered
 * @param {String} sortCriteria based on which the array will be sorted
 * @returns {Object[]} array that is filtered and sorted
 *
 */

const SortAfterFilter = (array, filterCriteria, sortCriteria) => {
  return sortBy(filter(array, filterCriteria), sortCriteria);
};

export default SortAfterFilter;
