import React, { useState, createContext } from 'react';

export const FeedsContext = createContext();

const SAVED_STATE_FEED = JSON.parse(window.localStorage.getItem('feedItems'));
const DEFAULT_STATE_FEED = {};

const SAVED_STATE_FILTERS = JSON.parse(
  window.localStorage.getItem('feedFilters')
);
const DEFAULT_STATE_FILTERS = [];

export function FeedsProvider({ children }) {
  const state = SAVED_STATE_FEED || DEFAULT_STATE_FEED;
  const [readItems, setReadItems] = useState(state);

  const filterState = SAVED_STATE_FILTERS || DEFAULT_STATE_FILTERS;
  const [savedFilters, setSavedFilters] = useState(filterState);

  const setReadItemsHandler = data => {
    setReadItems(data);
    window.localStorage.setItem('feedItems', JSON.stringify(readItems));
  };

  const setSavedFiltersHandler = data => {
    setSavedFilters(data);
    window.localStorage.setItem('feedFilters', JSON.stringify(data));
  };

  return (
    <FeedsContext.Provider
      value={{
        readItems,
        setReadItems: setReadItemsHandler,
        savedFilters,
        setSavedFilters: setSavedFiltersHandler,
      }}
    >
      {children}
    </FeedsContext.Provider>
  );
}
