import React from 'react';
import { useSelectionContext } from 'contexts/SelectionContext';
import { useQuery } from 'react-apollo';
import GET_INSTANCE_FROM_CACHE from 'graphql/queries/getInstanceFromLocalResolver';
import EditorContainer from './editor-container';
import EmptyState from './emptyState';
import MultiSelectState from './multiSelectState';

const DataContainer = ({ currentInstance, ...rest }) => {
  const skip = currentInstance[0] === '-';

  const { data } = useQuery(GET_INSTANCE_FROM_CACHE, {
    variables: {
      input: {
        mId: currentInstance,
        mRefId: currentInstance,
      },
    },
    skip,
  });

  if (currentInstance[0] === '-' || !data) return <EmptyState />;

  return <EditorContainer currentInstance={data.instance} {...rest} />;
};

const EditorDataContainer = props => {
  const [selectedIds] = useSelectionContext();

  if (selectedIds.length === 0) return <EmptyState />;
  if (selectedIds.length > 1) return <MultiSelectState selectionCount={selectedIds.length} />;

  return <DataContainer currentInstance={selectedIds[0]} {...props} />;
};

export default EditorDataContainer;
