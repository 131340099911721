import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useStyles from './sidebar-styles';
import selectionTypes from '../selectionTypes';
import Iframe from './iframe';
import Contacts from './contacts';
import Teams from './teams';
import Departments from './departments';

const viewComponents = {
  [selectionTypes.CONTACTS]: Contacts,
  [selectionTypes.TEAMS]: Teams,
  [selectionTypes.DEPARTMENT]: Departments,
};

const SidebarView = props => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const { selectionType } = props;
  const ViewComponent = viewComponents[selectionType];
  const OtherProps = {};

  const getHide = sType => {
    let ret = 'none';
    switch (true) {
      case sType === selectionType:
        ret = 'block';
        break;
      case selectionType === 'dpr_usr' && sType === 'others':
      case selectionType === 'tea_usr' && sType === 'others':
      case selectionType === 'contacts' && sType === 'others':
        ret = 'block';
        break;
      default:
        ret = 'none';
    }

    return ret;
  };

  return (
    <div className={classes.sidebar}>
      <div className={classes.sidebar} style={{ display: getHide('graphicsbox') }}>
        <Iframe {...props} containerRef={rootRef} otherprops={{ ExternalName: 'pilotedge' }} />
      </div>
      <div className={classes.sidebar} style={{ display: getHide('storybox') }}>
        <Iframe {...props} containerRef={rootRef} otherprops={{ ExternalName: 'mimir' }} />
      </div>
      <div className={classes.sidebar} style={{ display: getHide('others') }}>
        {ViewComponent && (
          <ViewComponent {...props} containerRef={rootRef} otherprops={OtherProps} />
        )}
      </div>
    </div>
  );
};

SidebarView.propTypes = {
  /** Currently selected view type,
   * for each type a corresponding component is rendered
   * that's specified on viewComponents */
  selectionType: PropTypes.oneOf(Object.values(selectionTypes)),
  /** Show/hide the sidebar list  */
  sidebarHidden: PropTypes.bool,
};

SidebarView.defaultProps = {
  selectionType: null,
  sidebarHidden: false,
};

export default SidebarView;
