import React, { useCallback, useContext, memo } from 'react';
import { useSlate, ReactEditor } from 'slate-react';
import { useDrag } from 'react-dnd';
import configCtx from 'contexts/configContext';
import Tooltip from 'components/tooltip';
// eslint-disable-next-line max-len
import insertPrimaryAutomationElement from 'components/editor/components/primaryAutomation/utils/insertPrimaryAutomationElement';
// eslint-disable-next-line max-len
import insertSecondaryAutomationElement from 'components/editor/components/secondaryAutomation/utils/insertSecondaryAutomationElement';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import primaryTypeValues from 'components/editor/constants/types/primaryTypeValues';
import { getDefaultTemplate } from 'components/editor/utils';
import { secondaryTypes } from 'screens/rundown/utils/RundownItemTypes';
import iconComponents from './constants/iconComponents';
import useStyles from './styles';

const stopPropagation = event => event.stopPropagation();

const MemoizedTooltip = memo(Tooltip);

const AutomationButton = ({ type }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const [, dragRef] = useDrag({ item: { type } });
  const classes = useStyles();
  const { automationTemplateConfigs } = useContext(configCtx);
  const IconComponent = iconComponents[type];

  const [checkUserRight] = useCheckUserRight();
  const canShowStarCG = checkUserRight('feature', 'starcg');

  const { templates } = automationTemplateConfigs[0]
    ? automationTemplateConfigs[0].templateSets[0]
    : {};

  const insertAutomationItem = useCallback(
    () => {
      const isPrimary = primaryTypeValues.includes(type);
      const { variant: templateVariant, name, description } = getDefaultTemplate(type, templates);
      const data = { templateVariant, name, description };
      if (isPrimary) insertPrimaryAutomationElement({ editor, type, update, data });
      else {
        if (type === secondaryTypes.OVERLAY_GRAPHICS && canShowStarCG) {
          data.starcg = { graphicsTemplateType: '', fields: [] };
          data.protocol = 'starcg';
        }
        insertSecondaryAutomationElement(editor, type, data);
      }

      ReactEditor.focus(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <MemoizedTooltip title={tooltipEnum[type]}>
      <div
        className={classes.root}
        ref={dragRef}
        onClick={insertAutomationItem}
        onMouseDown={stopPropagation}
        role="presentation"
      >
        <IconComponent />
      </div>
    </MemoizedTooltip>
  );
};

export default memo(AutomationButton);
