import { tns } from 'tiny-slider/src/tiny-slider';

const createSlider = (sliderContainer, dayWidth) => {
  const slider = tns({
    container: sliderContainer,
    items: 7,
    slideBy: 7,
    loop: false,
    controls: false,
    nav: false,
    fixedWidth: dayWidth,
  });

  return slider;
};

export default createSlider;
