import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import ButtonComponent from 'components/buttons/button';
import Thumbnail from 'components/thumbnail';
import useFileUpload from 'hooks/useFileUpload';
import { generateName } from 'components/editor/utils';
import { elementTypes } from 'components/editor/constants/types';
import { ReactComponent as VideoIcon } from 'assets/icons/systemicons/editor/video_off.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/systemicons/editor/photo_off.svg';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import Box from '../box';
import useStyles from './styles';

const Button = memo(ButtonComponent);
const menuItems = [];
const mediaTypes = ['video/mp4', 'image/gif', 'image/jpeg', 'image/png'];

const Icons = () => {
  const classes = useStyles();
  return (
    <div className={classes.mediaIcons}>
      <VideoIcon className={classes.icon} />
      <ImageIcon className={classes.icon} />
    </div>
  );
};

const Media = ({ attributes, children, element }) => {
  const readOnly = false;
  const classes = useStyles();
  const [assets, setAssets] = useState([]);

  // this function needs to be updated
  const addAssets = uploadedFiles => {
    const updatedAssets = [...assets, ...uploadedFiles];
    setAssets(updatedAssets);
  };

  // this function needs to be updated
  const removeAsset = id => {
    const updatedAssets = assets.filter(asset => asset.name !== id);
    setAssets(updatedAssets);
  };

  const onFileUpload = async files => {
    const uploadedFiles = [];
    await files.forEach(({ file, filePath }) => {
      const fileName = generateName(file.type);
      const [fileType] = file.type.split('/');
      const newAsset = { name: fileName, type: fileType, src: filePath };
      uploadedFiles.push(newAsset);
    });
    addAssets(uploadedFiles);
  };

  const uploadMedia = useFileUpload(mediaTypes, onFileUpload, true);
  const handleMediaSelect = () => uploadMedia();

  return (
    <div {...attributes}>
      {children}
      <Box
        iconComponent={<Icons />}
        title="Add Photos and/or Videos"
        readOnly={readOnly}
        menuItems={menuItems}
        type="media"
      >
        <div className={classes.contentContainer}>
          {assets.map((asset, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={classes.thumbnail} key={index}>
              <Thumbnail
                src={asset.src}
                size={88}
                type={asset.type}
                id={asset.name}
                onClose={removeAsset}
              />
            </div>
          ))}
          <Button
            type="dashedOutlined"
            onClick={handleMediaSelect}
            disabled={readOnly}
            shape="square"
            size={88}
          >
            <AddIcon />
          </Button>
        </div>
      </Box>
    </div>
  );
};

Media.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Media.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { src: '' },
    type: elementTypes.MEDIA,
  },
};

export default memo(Media);
