import React from 'react';
import PropTypes from 'prop-types';
import Group from 'screens/storybox/components/group';
import LoadingIndicator from 'components/loadingIndicator';
import FallbackHeaderThumb from 'assets/images/default/defaultThumbnail.png';
import Header from './header';
import InstanceItem from './instanceItem';
import useStyles from './details-styles';

const Details = props => {
  const { setPreviewRundown, loading, data, title, publishingtime, image } = props;
  const classes = useStyles();
  if (loading) return <LoadingIndicator />;

  const { mOrder, mPreorder } = data.getRundown;

  const filteredMorder = mOrder ? mOrder.filter(mId => mId[0] !== '-') : [];
  const filteredPreorder = mPreorder ? mPreorder.filter(mId => mId[0] !== '-') : [];

  return (
    <>
      <Header
        title={title}
        image={image || FallbackHeaderThumb}
        publishingtime={publishingtime}
        backButtonClicked={() => setPreviewRundown(null)}
      />
      <div className={classes.groupContainer}>
        <Group label="Ready To Air" itemCount={filteredMorder.length}>
          <div>
            {filteredMorder.map((mId, index) => (
              <InstanceItem
                key={mId}
                mId={mId}
                showDivider={index < filteredMorder.length - 1}
                defaultThumb={image || FallbackHeaderThumb}
                publishingtime={publishingtime}
              />
            ))}
          </div>
        </Group>
        <Group label="Preparing" itemCount={filteredPreorder.length}>
          <div>
            {filteredPreorder.map(mId => (
              <InstanceItem
                key={mId}
                mId={mId}
                showDivider
                defaultThumb={image || FallbackHeaderThumb}
                publishingtime={publishingtime}
              />
            ))}
          </div>
        </Group>
      </div>
    </>
  );
};

Details.propTypes = {
  setPreviewRundown: PropTypes.func,
  title: PropTypes.string,
  publishingtime: PropTypes.string,
  image: PropTypes.string,
  loading: PropTypes.bool,
};

Details.defaultProps = {
  setPreviewRundown: () => {},
  title: '',
  publishingtime: '',
  image: null,
  loading: false,
};

export default Details;
