import { makeStyles } from '@material-ui/styles';

const commonLabelStyle = theme => ({
  height: '30px',
  display: 'flex',
  alignItems: 'center',
});

const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography.dina.listItemGroupHeadersLabel,
    fontWeight: 'normal',
    lineHeight: '15px',
    color: theme.palette.dina.mediumEmphasis,
    ...commonLabelStyle(theme),
    width: '100%',
  },
  groupHovered: {
    right: '48px',
    transition: 'all 0.15s',
  },
  groupInitial: {
    right: '24px',
    transition: 'all 0.15s',
  },
  count: {
    ...theme.typography.dina.captionRegular,
    color: theme.palette.dina.mediumEmphasis,
    letterSpacing: '0.25px',
    ...commonLabelStyle(theme),
    position: 'absolute',
  },
  addButton: {
    height: 30,
    width: 30,
  },
  labelContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '0px 0px 0px 10px',
  },
  icon: {
    marginRight: '6px',
  },
  withBackground: {},
  listItem: {
    // experimental styles: might be used later
    // top: 0,
    // zIndex: 10,
    // position: 'sticky',
    // background: ({ background }) =>
    //   background || theme.palette.dina.surfaceAppBackgroundNavLevel2,
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    padding: '0px',
    paddingRight: '14px',
    borderTop: `1px solid ${theme.palette.dina.dividerDark}`,
    borderBottom: `1px solid ${theme.palette.dina.dividerDark}`,
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },
  underlinedItem: {
    padding: '0px',
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },
}));

export default useStyles;
