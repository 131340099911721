import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: 326,
    height: '100%',
    maxWidth: '327px',
    padding: 0,
    overflow: 'hidden',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
  },
}));

export default useStyles;
