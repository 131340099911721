import React from 'react';
import PropTypes from 'prop-types'
import useStyles from './iframe-styles';

const IframeView = ({ height, provider }) => {
  const classes = useStyles({height});
  const srcUrl = provider ? provider.embeddedEndpoint : null;

  return (
    <div className={classes.root}>
      <iframe
        id="cms"
        src={srcUrl}
        title="cms"
        className={classes.iframe}
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      />
    </div>
  );
};

IframeView.propTypes={
  /** Height of iFrame */
  height: PropTypes.number,
  /** Provider object to show on iframe */
  provider:PropTypes.objectOf(PropTypes.string),
}

IframeView.defaultProps={
  height:'100%',
  provider:null,
}

export default IframeView;
