import React, { memo, useState, useCallback } from 'react';
import { Transforms } from 'slate';
import { useSlate } from 'slate-react';
import InputField from 'components/inputField';
import TextAreaComponent from 'components/textArea';
import { ReactComponent as DragHandle } from 'assets/icons/systemicons/dragHandle.svg';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import { actionTypes } from 'components/editor/constants/types';
import processInput from './utils/processInput';
import processOutput from './utils/processOutput';
import useStyles from './styles';

const { setNodes } = Transforms;
const Input = memo(InputField);
const TextArea = memo(TextAreaComponent);

const CharacterGraphics = ({ data }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const { starcg } = data;
  const { graphicsTemplateType, fields } = starcg;
  const [typeInputValue, setTypeInputValue] = useState(graphicsTemplateType);
  const [textInputValue, setTextInputValue] = useState(processOutput(fields));
  const classes = useStyles();

  const onTextChange = e => {
    setTextInputValue(e);
  };

  const onTypeChange = e => {
    setTypeInputValue(e);
  };

  const onBlur = useCallback(() => {
    const textOutput = processInput(textInputValue);
    setTextInputValue(processOutput(textOutput));
    const typeOutput = typeInputValue;
    const newStarCg = { graphicsTemplateType: typeOutput, fields: textOutput };
    const updatedData = { ...data, starcg: newStarCg };

    setNodes(editor, { data: updatedData });
    update({
      type: actionTypes.AUTOMATION_UPDATE,
      payload: { document: editor.children, updatedData },
    });
  }, [data, editor, textInputValue, typeInputValue, update]);

  return (
    <div className={classes.container}>
      <Input
        usage="editor"
        label="Graphics Template Type"
        value={typeInputValue}
        onChange={onTypeChange}
        onBlur={onBlur}
      />
      <TextArea
        type="editor"
        label="Text"
        optionalLabel="Text"
        value={textInputValue}
        onChange={onTextChange}
        onBlur={onBlur}
      />
      <DragHandle className={classes.dragHandle} />
    </div>
  );
};

export default memo(CharacterGraphics);
