import React from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import Button from 'components/buttons/button';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  buttonDiv: {
    height: '56px',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  buttonWidth: {
    width: '144px',
    marginRight: '8px',
  },
}));

const Footer = ({ onOk, onCancel, ...rest }) => {
  const classes = useStyles();

  return (
    <div>
      <Divider />
      <div className={classes.buttonDiv} {...rest}>
        <div className={classes.buttonWidth}>
          <Button
            variant="contained"
            type="significant"
            shape="full-width"
            onClick={onOk}
          >
            Ok
          </Button>
        </div>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

Footer.propTypes = {
  /** onOk callback function */
  onOk: PropTypes.func,
  /** onClose callback function  */
  onClose: PropTypes.func,
};

Footer.defaultProps = {
  onOk: () => {},
  onClose: () => {},
};

export default Footer;
