import { makeStyles } from '@material-ui/styles';
import image from 'assets/images/Tile_DiagonalPattern.png';
import PitchImageSrc from 'assets/images/PitchBackgroundOverlay.png';

const useStyles = makeStyles(theme => {
  const rootStyles = {
    ...theme.typography.dina.headerTabButton,
    maxWidth: '192px',
    textTransform: 'none',
    textAlign: 'left',
    padding: '6px 24px 6px 16px',
  };
  return {
    tabsIndicator: {
      display: 'none',
    },
    rootStory: {
      ...rootStyles,
      '&$tabSelected': {
        backgroundColor: theme.palette.dina.onSelected,
        color: theme.palette.dina.highEmphasis,
      },
    },
    pitch: {
      ...rootStyles,
      '&$tabSelected': {
        backgroundColor: theme.palette.dina.primary700,
        color: theme.palette.dina.highEmphasis,
        backgroundImage: `url(${PitchImageSrc})`,
        backgroundPositionX: '24%',
      },
    },
    rootRundown: {
      ...rootStyles,
      '&$tabSelected': {
        backgroundColor: theme.palette.dina.paletteAccentPurple,
        color: theme.palette.dina.highEmphasis,
      },
    },
    rootRundownTemplate: {
      ...rootStyles,
      '&$tabSelected': {
        backgroundColor: theme.palette.dina.paletteAccentOrange,
        color: theme.palette.dina.highEmphasis,
      },
    },
    rootRundownNotToday: {
      ...rootStyles,
      '&$tabSelected': {
        backgroundColor: theme.palette.dina.paletteAccentPurple,
        color: theme.palette.dina.highEmphasis,
        backgroundImage: `url(${image})`,
      },
    },
    tabSelected: {
      borderRadius: '8px 8px 0 0',
    },
    tabNavRoot: {
      minWidth: 0,
      width: '48px',
      minHeight: '44px',
      height: '44px',
      marginTop: '4px',
      ...theme.typography.dina.button,
      '&$tabSelected': {
        backgroundColor: theme.palette.dina.primary700,
        color: theme.palette.dina.highEmphasis,
      },
    },
    lastTab: {
      boxShadow: `10px 1px 0px -9px ${theme.palette.dina.dividerLight}`,
    },
  };
});

export default useStyles;
