import React from 'react';
import { Typography } from '@material-ui/core';
import { ReactComponent as Icon } from 'assets/icons/systemicons/zoomCustom.svg';
import useStyles from './emptyState-styles';

const EmptyState = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Icon className={classes.icon} />
        <Typography classes={{ root: classes.text }}>
          Select an Instance to show details
        </Typography>
      </div>
    </div>
  );
};

export default EmptyState;
