import { useState, useReducer } from 'react';

export const useModel = () =>
  useState(() => {
    return new Model({});
  });

export const useForceUpdate = () => useReducer(state => !state, false)[1];

export const useModelProperty = function(model, property) {
  const forceUpdate = useForceUpdate();

  function getterSetter(val) {
    if (arguments.length > 0) {
      model[property] = val;
      forceUpdate();
    }

    return model[property];
  }

  return getterSetter;
};

function Model(attrs) {
  this.property = attrs.property;
}
