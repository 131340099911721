const normalizeIntoBlockData = data => ({
  mId: data.mId,
  mRefId: data.mRefId,
  mAssetId: data.mAssetId || data.mRefId,
  mTitle: data.title,
  mProvider: data.provider,
  thumbnailUrl: data.thumbnailUrl,
  itemDuration: data.itemDuration,
  itemType: data.itemType,
  mediaType: data.mediaType || data.itemType,
  proxy: data.proxy,
  mContentUrl: data.mContentUrl,
});

export default normalizeIntoBlockData;
