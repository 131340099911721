import React from 'react';
import Searchbar from 'components/searchbar';
import { useModel, useModelProperty } from 'hooks/useDataModel';
import ICONS from 'assets/icons/icons';
import FILTER_PREFIX from 'utils/constants/filterPrefix';
import FreeTextInput from 'components/searchbar/advanced/freeTextInput';

const SearchContainer = props => {
  const { chips, chipChangeHandler, customFilter, toggleCustomFilter } = props;

  // Advanced search data model
  const myModel = useModel()[0];
  const category = useModelProperty(myModel, 'category');
  const location = useModelProperty(myModel, 'location');

  const { LOCATION, CATEGORY } = FILTER_PREFIX;

  // advanced search dialog input fields
  const inputFields = (
    <>
      <FreeTextInput
        icon={ICONS.IPTC}
        label="News Categories"
        value={category()}
        onChange={val => category(val)}
      />
      <FreeTextInput
        icon={ICONS.LOCATION_OFF}
        label="Locations"
        value={location()}
        onChange={val => location(val)}
      />
    </>
  );

  // concat filters from the advanced input box with filters shown on the searchbar
  const addAdvancedFilters = () => {
    const advancedFilters = [];
    Object.keys(myModel).forEach(e => {
      if (e === 'category' && myModel[e] && myModel[e].length > 0) {
        advancedFilters.push({
          label: `${CATEGORY}${myModel[e]}`,
          expression: `${myModel[e]}`,
        });
      } else if (e === 'location' && myModel[e] && myModel[e].length > 0) {
        advancedFilters.push({
          label: `${LOCATION}${myModel[e]}`,
          expression: `${myModel[e]}`,
        });
      }
    });

    chipChangeHandler([...new Set([...chips, ...advancedFilters])]);
  };

  const clearAdvancedInput = () => {
    category('');
    location('');
  };

  return (
    <Searchbar
      chips={chips}
      chipChangeHandler={updatedChips => chipChangeHandler(updatedChips)}
      customFilter={customFilter}
      toggleCustomFilter={toggleCustomFilter}
      advancedInputFields={inputFields}
      submitAdvanced={addAdvancedFilters}
      clearAdvancedInput={clearAdvancedInput}
    />
  );
};

export default SearchContainer;
