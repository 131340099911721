import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Photo } from 'assets/icons/systemicons/photo_off.svg';
import { useMaximized } from 'contexts/MaximizeContext';
import { ReactComponent as Asset } from 'assets/icons/systemicons/upload.svg';
import { ReactComponent as Unlock } from 'assets/icons/systemicons/padlock_off.svg';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import EllipsisDropdown from 'components/ellipsisDropdown';
import Switch from 'components/switch/Switch';
import UploadMediaInput from '../../../utils/coverPhoto/uploadMediaInput';

const EllipsisDropdownContainer = props => {
  const [anchorEl, setAnchorEl] = useState(null);

  const uploadComplete = val => {
    if (val === true) {
      setAnchorEl(null);
    }
  };
  const { mSyncActive, onSyncSettingsChange, onForceUnlock } = props;
  const { isMaximized, setIsMaximized } = useMaximized();
  const [syncActive, setSyncActive] = useState(
    mSyncActive && mSyncActive.length > 0 ? mSyncActive : [],
  );

  useEffect(() => {
    setSyncActive(mSyncActive && mSyncActive.length > 0 ? mSyncActive : []);
  }, [mSyncActive]);

  const handleSynchronizeStateChange = val => {
    let currentSyncState;
    if (val.length > 0) {
      currentSyncState = [];
    } else {
      currentSyncState = ['*'];
    }
    setSyncActive(currentSyncState);
    onSyncSettingsChange(currentSyncState);
  };

  const handleForceUnlock = () => {
    setAnchorEl(null);
    onForceUnlock();
  };

  const listItems = [
    <ListItem
      text="Upload cover photo"
      iconChild={<Photo />}
      key="photo"
      firstChild={<UploadMediaInput {...props} complete={val => uploadComplete(val)} coverPhoto />}
    />,
    <ListItem
      text="Upload media assets"
      iconChild={<Asset />}
      key="assets"
      firstChild={<UploadMediaInput {...props} complete={val => uploadComplete(val)} />}
    />,
    <ListItem
      text="Synchronize"
      key="synchronize"
      iconChild={
        <Switch
          {...{ selected: syncActive.length > 0 }}
          onClick={() => handleSynchronizeStateChange(syncActive)}
        />
      }
    />,
    <ListItem
      text="Extend Layout"
      key="extendlayout"
      iconChild={
        <Switch {...{ selected: isMaximized }} onClick={() => setIsMaximized(!isMaximized)} />
      }
    />,
    <ListItem
      text="Force unlock"
      iconChild={<Unlock />}
      key="forceunlock"
      onClick={handleForceUnlock}
      excludeDivider
    />,
  ];

  return <EllipsisDropdown anchorEl={anchorEl} setAnchorEl={setAnchorEl} listItems={listItems} />;
};

EllipsisDropdownContainer.propTypes = {
  uploadComplete: PropTypes.func,
};

EllipsisDropdownContainer.defaultProps = {
  uploadComplete: () => {},
};

export default EllipsisDropdownContainer;
