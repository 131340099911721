/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useRef, useCallback, useContext, useEffect } from 'react';
import useUnlockInstance from 'hooks/useUnlockInstance';
import memberTypes from 'graphql/memberTypes';
import PropTypes from 'prop-types';
import { useLoadingIndicator } from 'contexts/LoadingContext';
import { useScrollIntoView } from 'contexts/ScrollIntoViewContext';
import { unionBy } from 'lodash';
import fallbackImage from 'assets/images/default/defaultCoverPhoto.png';
import useGetPlatforms from 'hooks/useGetPlatforms';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { ToolbarContext } from 'contexts/Toolbar';
import Scrollbar from 'screens/main/components/scrollbar';
import { useMaximized } from 'contexts/MaximizeContext';
import useStyles from './story-styles';
import Content from './components/content';
import StoryInstances from './components/instance/StoryInstances';
import StoryToolbar from './components/toolbar';
import Header from './components/header';
import Storybox from './components/storybox';
import Cover from './components/cover';

const Story = ({
  storyId,
  image,
  title,
  onCreateStory,
  imageUploadCompleted,
  data,
  variant,
  ...rest
}) => {
  const containerRef = useRef(null);
  const [assets, setAssets] = useState([]);
  const { loadingIndicatorFor } = useLoadingIndicator();
  const [, setCurrentClicked] = useScrollIntoView();
  const [, setToolbarElements] = useContext(ToolbarContext);
  const [dimensions, setDimensions] = useState(null);
  const isPitch = variant === memberTypes.PITCH;
  const { isMaximized } = useMaximized();
  const styleProps = { image, isMaximized };
  const classes = useStyles(styleProps);

  const [unlockInstance] = useUnlockInstance();
  const storySpec = {
    id: storyId,
    image,
  };

  const publishingAt =
    data && data.getMember && data.getMember.mPublishingAt
      ? data.getMember.mPublishingAt
      : new Date().toISOString();
  const [platforms, platformsLoadingError, platformsLoading] = useGetPlatforms(publishingAt);

  const onUploadCompleted = (url, key) => {
    const updatedAssets = unionBy(assets, [{ key }], 'key');
    setAssets(updatedAssets);
  };

  const onForceUnlock = async () => {
    unlockInstance(storyId);
  };

  useEffect(() => {
    setToolbarElements(
      <StoryToolbar
        {...rest}
        {...{ storyId, data, platforms, onCreateStory, containerRef, onForceUnlock, isPitch }}
      />,
    );
  }, [storyId, data]);

  const callBackRef = useCallback(domNode => {
    if (domNode) {
      const { height, width } = domNode.getBoundingClientRect();
      setDimensions({ height, width });
    }
  }, []);

  const onInstanceClick = (sId, instanceId) => {
    const target = document.getElementById(`${sId}-${instanceId}`);
    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
      setTimeout(() => {
        setCurrentClicked(instanceId);
      }, 500);
    }
  };

  const memoizedStoryBox = useMemo(
    () => (
      <Storybox
        storySpec={storySpec}
        storyContainerRef={containerRef}
        onInstanceClick={onInstanceClick}
        platforms={platforms}
        isPitch={isPitch}
      />
    ),
    [storySpec],
  );

  if (platformsLoading)
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  if (platformsLoadingError) return <div>{platformsLoadingError.message}</div>;

  const height = dimensions ? dimensions.height - 16 : null;

  return (
    <div ref={containerRef} className={classes.root}>
      <Header
        {...rest}
        hideTimeline={isPitch}
        image={image || fallbackImage}
        id={storyId}
        data={data}
        platforms={platforms}
      />
      <div className={isPitch ? classes.withoutHeader : classes.mainContent}>
        <div className={classes.content}>
          {memoizedStoryBox}
          <div className={classes.centerContent} ref={callBackRef}>
            <Scrollbar>
              <Cover
                {...{
                  title,
                  image,
                  data,
                  storyId,
                  onUploadCompleted,
                  isPitch,
                }}
                {...rest}
              />
              <div className={classes.contentWrapper}>
                <div className={classes.contentDiv}>
                  <Content member={data.getMember} />
                </div>
              </div>
              <div className={classes.subscreen}>
                <div className={classes.tabContent}>
                  <StoryInstances
                    containerRef={containerRef}
                    storyId={data.getMember ? data.getMember.mId : null}
                    story={data.getMember ? data.getMember : null}
                    platforms={platforms}
                    height={height}
                  />
                </div>
              </div>
            </Scrollbar>
            {loadingIndicatorFor && loadingIndicatorFor === 'create' && <LoadingIndicator />}
          </div>
        </div>
      </div>
    </div>
  );
};

Story.propTypes = {
  storyId: PropTypes.string.isRequired,
  subScreen: PropTypes.string,
  title: PropTypes.string,
  platforms: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.object),
  setSubScreen: PropTypes.func,
  imageUploadCompleted: PropTypes.func,
  titleChangeHandler: PropTypes.func,
  startPolling: PropTypes.func,
  stopPolling: PropTypes.func,
};

Story.defaultProps = {
  title: '',
  subScreen: '',
  platforms: [],
  data: [],
  imageUploadCompleted: () => {},
  setSubScreen: () => {},
  titleChangeHandler: () => {},
  startPolling: () => {},
  stopPolling: () => {},
};

export default Story;
