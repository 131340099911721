import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    width: ({ variant }) => (variant === 'placeholder' ? '100%' : '506px'),
    height: '242px',
    background: theme.palette.dina.surfaceCard,
    backdropFilter: 'blur(30px)',
    borderRadius: '8px',
    margin: '-8px 0',
  },
  formContainer: {
    height: '100%',
  },
  formDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    borderRadius: '8px',
  },
  topComponents: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
  },
  heading: {
    ...theme.typography.dina.marketplaceHeader,
    margin: '24px 48px 8px 0',
  },
  subHeading: {
    ...theme.typography.dina.overline,
    marginTop: '7px',
  },
  inputContainer: {
    marginRight: '16px',
  },
  bottomComponents: {},
  buttonLayout: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '5px 15px 6px 0',
  },
  button: {
    minHeight: '40px',
    width: '168px',
    marginLeft: '8px',
  },
}));

export default useStyles;
