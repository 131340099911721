import gql from 'graphql-tag';

/*  Query to search for stories in the indexed storysearchtable
    $Searchfilter variables
    ## the search string can contains spaces for AND search and | for OR search 
    searchString:String
    ## the mUpdated at is where the search shall start e.g 2020-08-02
    mUpdatedAt:String
    ## assignedMembers is an array of user ids, team ids department ids.
    assignedMembers:[string]
*/
export default gql`
  query SearchApi($filter: SearchFilter, $nextToken: String, $limit: Int) {
    searchApi(filter: $filter, nextToken: $nextToken, limit: $limit) {
      nextToken
      items {
        mId
        mRefId
        mTitle
        mDescription
        mContentKey
        mAvatarUrl
        mThumbnailUrl
        mCreatedAt
        mUpdatedAt
        mState
        mPublishingAt
        mType
        mMetaData {
          key
          value
        }
        mCategories {
          label
          score
          categoryId
        }
        mAssignedMembers {
          mId
          mType
        }
      }
    }
  }
`;
