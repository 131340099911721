/* eslint-disable max-len */
import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Select as MaterialSelect, InputBase, MenuItem } from '@material-ui/core';
import stopAllPropagation from 'components/editors/utils/stopAllPropagation';
import useStyles from './select-styles';

const renderSelectIcon = () => null;

const Select = ({
  isOpen,
  items,
  onChange,
  onClose,
  selectedValue,
  showIconInput,
  width,
  ...rest
}) => {
  const classes = useStyles({ width });

  const handleChange = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      onChange(event.target.value);
    },
    [onChange],
  );

  const renderValue = useCallback(() => {
    const selectedItem = items.find(({ variant }) => variant === selectedValue);
    if (!selectedItem) return <div />;

    return (
      <MenuItem
        disableGutters
        classes={{
          root: classes.inputMenuItem,
        }}
      >
        <div className={classes.title}>{selectedItem.variant}</div>
      </MenuItem>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const stopPropagationWhenOpen = useCallback(
    event => {
      if (isOpen) stopAllPropagation(event);
    },
    [isOpen],
  );

  return (
    <div role="presentation" className={classes.selectContainer} onClick={stopPropagationWhenOpen}>
      <MaterialSelect
        value={selectedValue || ''}
        onChange={handleChange}
        classes={{
          select: classes.select,
        }}
        IconComponent={renderSelectIcon}
        renderValue={renderValue}
        input={<InputBase id="input" />}
        inputProps={{
          className: classes.input,
        }}
        MenuProps={{
          classes: {
            paper: classes.menu,
            list: classes.list,
          },
        }}
        open={isOpen}
        {...{ onClose }}
      >
        {items && items.length > 0 ? (
          items.map(({ variant }) => {
            return (
              <MenuItem
                disableGutters
                value={variant}
                key={variant}
                classes={{
                  root: classes.menuItem,
                  selected: classes.menuItemSelected,
                }}
                data-testid={`input-${variant}`}
              >
                <div className={classes.optionTitle}>{variant}</div>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem
            value=""
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
          >
            No variant available
          </MenuItem>
        )}
      </MaterialSelect>
    </div>
  );
};

Select.propTypes = {
  /** Specifies the open state for Material UI select */
  isOpen: PropTypes.bool,
  /** Items that should be rendered as select options. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
    }),
  ),
  /** Callback to be invoked when an option is selected.
   * Gets the currently selected value as argument.
   */
  onChange: PropTypes.func,
  /** Callback to be invoked for Material UI select onClose event */
  onClose: PropTypes.func,
  /** Currently selected option's value. */
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** usage of the select input: default or gridView */
  usage: PropTypes.string,
};

Select.defaultProps = {
  isOpen: false,
  items: [],
  onChange: () => {},
  onClose: () => {},
  selectedValue: '',
  usage: 'default',
};

export default memo(Select);
