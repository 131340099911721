import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    opacity: ({ isMuted }) => (isMuted ? 0.5 : 1),
    '&:hover $delete': {
      display: 'flex',
    },
  },
  root: {
    height: '40px',
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },
  number: {
    ...theme.typography.dina.caption,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
  },
  delete: {
    display: 'none',
  },
  popover: {
    margin: '-8px 0',
  },
}));

export default useStyles;
