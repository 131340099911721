/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AppBar, Toolbar, ClickAwayListener } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Avatar from 'components/avatar';
import ListItem from 'components/ellipsisDropdown/listItem-view';
// eslint-disable-next-line max-len
import { ReactComponent as User } from 'assets/icons/systemicons/ContainerRight_Navbar/contacts_on.svg';
import Popover from 'components/popover/Popover';
import PropTypes from 'prop-types';
import getInitials from 'utils/getInitials';
import ScratchPadOff from 'assets/icons/systemicons/scratchpad_off.svg';
import NotificationsOff from 'assets/icons/systemicons/HeaderNavbar/notifications_off.svg';
import NotificationsOn from 'assets/icons/systemicons/HeaderNavbar/notifications_on.svg';
import NotificationIndicator from 'components/statusIndicators/notification/Notification';
import {
  isMos,
  parseMos,
  getMosAbstract,
  getMosId,
  getMosChanged,
} from 'components/editors/plugins/mos/utils';
import ScratchPad from './scratchPad';
import NotificationsList from './notifications/list';
import useStyles from './navbar-styles';

const Navbar = props => {
  const classes = useStyles();
  const {
    username,
    logout,
    tabs,
    avatar,
    messages,
    thumbnailData,
    setThumbnailData,
    openStory,
  } = props;
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [openScratchPad, setOpenScratchPad] = useState(false);
  const [keepDialogOpen, setKeepDialogOpen] = useState(false);
  const [numberOfNotifications, setNumberOfNotifications] = useState(0);
  const [newNotifications, setNewNotifications] = useState(0);
  const [newScratchPadItems, setNewScratchPadItems] = useState(0);
  const [scratchPadData, setScratchPadData] = useState([]);

  const addMimirItemToScratchPad = item => {
    const itemExistsInScratchPad = scratchPadData.find(data => {
      return data.id === item.id;
    });
    if (!itemExistsInScratchPad) {
      const newItem = {
        item,
        id: item.id,
        fileName: item.title,
        date: item.itemCreatedOn,
        // fileSize: null,
        image: item.thumbnailUrl,
        variant: 'video',
        isNew: true,
        duration: item.itemDuration,
      };
      const newScratchPadData = [newItem, ...scratchPadData];
      setScratchPadData(newScratchPadData);
      setNewScratchPadItems(newScratchPadItems + 1);
    }
  };

  const addMimirItemToThumbnail = item => {
    const filteredThumbnailData = thumbnailData.filter(t => t.id !== item.id);
    setThumbnailData([
      {
        id: item.id,
        variant: 'video',
        image: item.thumbnailUrl,
        duration: item.itemDuration,
        item,
      },
      ...filteredThumbnailData,
    ]);
  };

  const verifyMimirItem = data => {
    let mimirItem;
    try {
      mimirItem = JSON.parse(data);
    } catch {
      mimirItem = undefined;
    }
    if (!mimirItem) return false;

    if (mimirItem && mimirItem.self && mimirItem.provider === 'Mimir') {
      addMimirItemToScratchPad(mimirItem);
      addMimirItemToThumbnail(mimirItem);
      return true;
    }
    return false;
  };

  const addMosItemToThumbnail = item => {
    const xmlDoc = parseMos(item);
    const abstract = getMosAbstract(xmlDoc);
    const id = getMosId(xmlDoc);
    const filteredThumbnailData = thumbnailData.filter(t => t.id !== id);

    id &&
      setThumbnailData([
        {
          id,
          variant: 'graphic',
          title: abstract,
          item,
        },
        ...filteredThumbnailData,
      ]);
  };

  const addMosItemToScratchPad = item => {
    const xmlDoc = parseMos(item);
    const abstract = getMosAbstract(xmlDoc);
    const id = getMosId(xmlDoc);
    const changed = getMosChanged(xmlDoc);
    const itemExistsInScratchPad = scratchPadData.find(data => {
      return data.id === id;
    });

    if (!itemExistsInScratchPad) {
      const newItem = {
        item,
        id,
        fileName: abstract,
        date: changed,
        variant: 'graphic',
        isNew: true,
      };
      const newScratchPadData = [newItem, ...scratchPadData];
      setScratchPadData(newScratchPadData);
      setNewScratchPadItems(newScratchPadItems + 1);
    }
  };

  const verifyMosItem = data => {
    if (!isMos(data)) return false;
    addMosItemToThumbnail(data);
    addMosItemToScratchPad(data);
    return true;
  };

  const addItemToScratchPad = event => {
    const { data } = event;
    if (data && typeof data === 'string') {
      if (verifyMimirItem(data)) return;
      if (verifyMosItem(data)) return;
      console.log('not mimir or mos data');
    }
  };

  useEffect(() => {
    window.addEventListener('message', addItemToScratchPad, false);

    return () => {
      window.removeEventListener('message', addItemToScratchPad);
    };
  });

  useEffect(() => {
    if (messages.length > 0) {
      setNewNotifications(
        newNotifications + messages.length - numberOfNotifications
      );
      setNumberOfNotifications(messages.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const listItems = [
    <ListItem
      text="Log out"
      iconChild={<User className={classes.listIcon} />}
      key="logout"
      onClick={logout}
    />,
  ];

  const handleScratchPadClose = () => {
    setOpenScratchPad(false);
    setNewScratchPadItems(0);
    const newScratchPadData = scratchPadData.map(data => {
      return data.isNew ? { ...data, isNew: false } : data;
    });
    setScratchPadData(newScratchPadData);
  };

  const handleScratchPadClickAway = () => {
    !keepDialogOpen && handleScratchPadClose();
  };

  return (
    <AppBar className={classes.appBar} elevation={0} position="static">
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftMenu}>{tabs}</div>
        <div className={classes.rightMenu}>
          <div className={classes.scratchPadIcon}>
            <img
              src={ScratchPadOff}
              alt="scratchPad"
              title="ScratchPad"
              onClick={e => {
                setOpenScratchPad(!openScratchPad);
              }}
            />
            <div className={classes.notificationIndicator}>
              <NotificationIndicator notificationCount={newScratchPadItems} />
            </div>
          </div>
          {openScratchPad && (
            <ClickAwayListener onClickAway={handleScratchPadClickAway}>
              <div className={classes.scratchPad}>
                <ScratchPad
                  data={scratchPadData}
                  onCancel={handleScratchPadClose}
                  {...{ keepDialogOpen, setKeepDialogOpen }}
                />
              </div>
            </ClickAwayListener>
          )}

          <div className={classes.notificationIcon}>
            <img
              src={newNotifications > 0 ? NotificationsOn : NotificationsOff}
              alt="notification bell"
              title="Notifications"
              onClick={e => {
                setNotificationsAnchorEl(e.currentTarget);
              }}
            />
            <div className={classes.notificationIndicator}>
              <NotificationIndicator notificationCount={newNotifications} />
            </div>
          </div>
          <Popover
            className={classes.popover}
            anchorEl={notificationsAnchorEl}
            onClose={() => {
              setNotificationsAnchorEl(null);
              setNewNotifications(0);
            }}
          >
            <div className={classes.container}>
              <NotificationsList {...{ username, avatar, messages, openStory }} />
            </div>
          </Popover>
          <Avatar
            size={29}
            src={avatar}
            onClick={e => {
              setUserAnchorEl(e.currentTarget);
            }}
          >
            {getInitials(username)}
          </Avatar>
          <Popover
            className={classes.popover}
            anchorEl={userAnchorEl}
            onClose={() => {
              setUserAnchorEl(null);
            }}
          >
            <div className={classes.container}>{listItems}</div>
          </Popover>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {
  /** the username of the user */
  username: PropTypes.string,
  /** logout function */
  logout: PropTypes.func,
  /** navbar tabs */
  tabs: PropTypes.element,
  /** avatar url */
  avatar: PropTypes.string,
  /** Callback to open a story */
  openStory: PropTypes.func,
};

Navbar.defaultProps = {
  username: '',
  logout: () => {},
  tabs: React.createElement('div'),
  avatar: '',
  openStory: () => {},
};

export default Navbar;
