import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import LoadingIndicator from 'components/loadingIndicator';
import useApolloSubscription from 'hooks/useApolloSubscription';
import UPDATE_DAY_VIEW_SUBSCRIPTION from 'graphql/subscriptions/updateDayView';
import useGetMembersByPublishingDate from 'hooks/useGetMembersByPublishingDate';
import useUpdatePublishingDate from 'hooks/useUpdatePublishingDate';
import useFuseSearch from 'hooks/useFuseSearch';
import dndTypes from 'utils/dndTypes';
import getDates from 'utils/getDates';
import frontendFiltering from '../../utils/frontendFiltering';
import getUniqueFilters from '../../utils/getUniqueFilters';
import Day from './day-view';
import useStyles from './day-styles';
import updateCache from './updateCache';

const DayContainer = props => {
  const {
    time,
    timeVariant,
    showStory,
    showPitch,
    filters,
    selectedFilter,
    onlyShowUsersItems,
  } = props;
  const { startDate, endDate } = getDates(time, timeVariant);
  const classes = useStyles();
  const search = useFuseSearch();
  const publishingDate = startDate.split("T")[0];
  const [subscribe, unSubscribe] = useApolloSubscription(UPDATE_DAY_VIEW_SUBSCRIPTION, {
    variables: {
      publishingDate,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      console.log("subscription data for day view:", subscriptionData);
      const story = subscriptionData.data.updateDayViewSubscription;
      updateCache(client, story, startDate, endDate);
    },
  });

  useEffect(() => {
    subscribe();

    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const [updatePublishingDate] = useUpdatePublishingDate();
  const [data, error, loading] = useGetMembersByPublishingDate(
    startDate,
    endDate,
    'story',
    !showStory,
  );

  const [pData, pError, pLoading] = useGetMembersByPublishingDate(
    startDate,
    endDate,
    'pitch',
    !showPitch,
  );

  const [{ didDrop }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.PITCH],
    drop(item, monitor) {
      if (!didDrop) {
        item.id && updatePublishingDate(item.id, time, startDate, endDate);
      }
    },
  });

  if (loading || pLoading) return <LoadingIndicator />;
  if (error) return <div>ERROR! {error.message}</div>;
  if (pError) return <div>ERROR! {pError.message}</div>;

  const { getMembersByPublishingDate: storyData } = data;
  const { getMembersByPublishingDate: pitchData } = pData;

  const filterList = getUniqueFilters(filters, selectedFilter);

  return (
    <div ref={dropRef} className={classes.root}>
      <Day
        data={frontendFiltering([...storyData, ...pitchData], filterList, search)}
        {...{
          onlyShowUsersItems,
        }}
      />
    </div>
  );
};

DayContainer.propTypes = {
  /** A time within the day */
  time: PropTypes.string.isRequired,
  /** Time variant of the view */
  timeVariant: PropTypes.string,
  showStory: PropTypes.bool,
  showPitch: PropTypes.bool,
  filters: PropTypes.arrayOf(PropTypes.object),
  onlyShowUsersItems: PropTypes.bool,
  searchString: PropTypes.string,
  selectedFilter: PropTypes.shape({
    label: PropTypes.string,
    expression: PropTypes.arrayOf(PropTypes.object),
  }),
};

DayContainer.defaultProps = {
  timeVariant: 'date',
  showStory: true,
  showPitch: true,
  filters: [],
  onlyShowUsersItems: false,
  searchString: null,
  selectedFilter: {},
};

export default DayContainer;
