import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    width: 24,
    height: 24,
    position: 'relative',

    '& > *': {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
}));

export default useStyles;
