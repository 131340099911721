import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    // overflowY: 'auto',
  },
  backdropRoot: {
    position: 'absolute',
  },
  text: {
    ...theme.typography.dina.overline,
    paddingTop: '8px',
    marginLeft: '16px',
  },
  divider: {
    pbackgroundColor: theme.palette.dina.dividerLight,
    width: 'calc(100% - 16px)',
    marginLeft: '16px',
  },
}));

export default useStyles;
