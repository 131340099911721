import React, { createContext, useState } from 'react';

export const ToolbarContext = createContext();


const ToolbarStore = ({ children }) => {
  const [toolbarElements, setToolbarElements] = useState(<div />);
  return (
    <ToolbarContext.Provider
      value={[toolbarElements, setToolbarElements]}
    >
      {children}
    </ToolbarContext.Provider>
  );
};

export default ToolbarStore;