import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/buttons/button';
import useStyles from './selectRundown-styles';

const SelectRundown = ({ rundowns, onSelect }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        {rundowns.length === 0 ? 'There is no rundown' : 'Select Rundown'}
      </div>
      <div>
        {rundowns.map(r => {
          return (
            <Button
              variant="text"
              type="cta"
              key={r.mId}
              onClick={() => {
                const { mId, mRefId } = r;
                onSelect({ mId, mRefId });
              }}
            >
              {r.mTitle}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

SelectRundown.propTypes = {
  /** list of rundowns for selected date */
  rundowns: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mRefId: PropTypes.string,
      mTitle: PropTypes.string,
    }),
  ),
  /** callback to be invoked when a rundown is selected */
  onSelect: PropTypes.func,
};

SelectRundown.defaultProps = {
  rundowns: [],
  onSelect: () => {},
};

export default SelectRundown;
