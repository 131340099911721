import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    margin: '8px 6px',
  },
  textareaWrapper: {
    marginBottom: '16px',
  },
  overshooting: {
    display: 'flex',
    height: '26',
    marginTop: 4,
    marginRight: 8,
    opacity: ({ isExceeded }) => (!isExceeded ? 0.35 : 1),
  },
  overshootingText: {
    ...theme.typography.dina.caption,
    width: 114,
    letterSpacing: '0.25px',
    lineHeight: '13px',
    textAlign: 'right',
  },
  button: {
    width: 56,
    height: 32,
    borderRadius: 4,
    border: `0.5px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
    background: theme.palette.dina.blackHoverOverlay,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    // marginTop: '8px',
    marginBottom: '8px',
    '&:disabled': {
      opacity: 0.35,
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  checkbox: {
    marginLeft: 4,
  },
}));

export default useStyles;
