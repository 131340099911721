import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import uuidv1 from 'uuid/v1';
import RundownIcon, { iconUsage } from 'components/rundownIcons';

const useStyles = makeStyles(() => ({
  icons: {
    display: 'flex',
    width: '128px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconContainer: {
    maxWidth: '144px',
    minWidth: '144px',
    display: 'flex',
  },
  firstIcon: {
    marginLeft: '8px',
  },
  icon: {
    marginLeft: '4px',
  },
}));

const IconView = ({ items }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconContainer}>
      <div className={classes.icons}>
        {items &&
          items.length > 0 &&
          items.slice(0, 3).map((item, index) => (
            <div key={uuidv1()} className={index > 0 ? classes.icon : classes.firstIcon}>
              <RundownIcon
                variant={item.title}
                type="grid"
                label={item.templateVariant}
                width={40}
                usage={iconUsage.META}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

IconView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

IconView.defaultProps = {
  items: [],
};

export default IconView;
