import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import memberTypes from 'graphql/memberTypes';
import DELETE_CONTACT from 'graphql/mutations/deleteContact';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';

const GET_USERS = gql`
  query GetUsers($input: GetMemberOfTypeInput) {
    getMembersOftype(input: $input) {
      mId
      mRefId
    }
  }
`;

const useDeleteContact = () => {
  const [deleteContactMutation] = useMutation(DELETE_CONTACT);

  const deleteContact = async id => {
    const deleteMember = {
      mId: id,
    };

    const deleteSingleMember = {
      mId: id,
      mRefId: id,
    };

    try {
      await deleteContactMutation({
        variables: {
          deleteMember,
          deleteSingleMember,
        },
        update: proxy => {
          const members = proxy.readQuery({
            query: GET_USERS,
            variables: getMembersOfTypeQuery(memberTypes.CONTACT),
          });

          const updatedMembers = members.getMembersOftype.filter(member => member.mId !== id);

          proxy.writeQuery({
            query: GET_USERS,
            variables: getMembersOfTypeQuery(memberTypes.CONTACT),
            data: { getMembersOftype: updatedMembers },
          });
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
  return [deleteContact];
};

export default useDeleteContact;
