import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  thumbVertical: {
    background: 'transparent',
    justifyContent: 'center',
    width: '100%',
    '&:hover': {
      '& > :first-child': {
        background: 'rgba(255,255,255,0.40)',
        borderLeft: '0.5px solid rgba(255,255,255,0.40)',
        borderRight: '0.5px solid rgba(255,255,255,0.40)',
      },
    },
    '&:active': {
      '& > :first-child': {
        background: 'rgba(255,255,255,0.40)',
        borderLeft: '0.5px solid rgba(255,255,255,0.40)',
        borderRight: '0.5px solid rgba(255,255,255,0.40)',
      },
    },
  },
  thumbVerticalInner: {
    width: '4px',
    height: '100%',
    background: 'rgba(255,255,255,0.15)',
    borderLeft: '0.5px solid rgba(255,255,255,0.07)',
    borderRight: '0.5px solid rgba(255,255,255,0.07)',
    borderRadius: '10px',
  },
  track: {
    transition: 'opacity 200ms ease',
    zIndex: 99,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    right: '0px',
  },
});

const Scrollbar = props => {
  const { children, valueChanged, closeToBottom } = props;
  const scrollbar = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    if (scrollbar && valueChanged) {
      scrollbar.current.scrollToTop();
    }
  }, [valueChanged]);

  const handleUpdate = values => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const buffer = 100; // 100px from scroll bottom

    // the value will be greater than one just before the scroll reaches the bottom
    const val = (scrollTop + buffer) / (scrollHeight - clientHeight);
    if (val > 1) closeToBottom();
  };

  return (
    <Scrollbars
      onUpdate={handleUpdate}
      ref={scrollbar}
      style={{ height: '100%' }}
      renderThumbVertical={scrollProps => (
        <div {...scrollProps} className={classes.thumbVertical} style={{ display: 'flex' }}>
          <div className={classes.thumbVerticalInner} />
        </div>
      )}
      renderTrackVertical={scrollProps => (
        <div {...scrollProps} className={classes.track} style={{ width: '16px' }} />
      )}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  valueChanged: PropTypes.string,
  closeToBottom: PropTypes.func,
};

Scrollbar.defaultProps = {
  valueChanged: null,
  closeToBottom: () => {},
};

export default Scrollbar;
