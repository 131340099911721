/**
 *  Hook for creating a new story and attach it to a user.
 *  It takes the mId of the user and the title of the story .
 *  Returns the story id of the created story.
 *
 */

import { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { format, isWithinRange } from 'date-fns';
import { getStoriesQuery } from 'graphql/queryVariables';
import memberTypes from 'graphql/memberTypes';
import CREATE_STORY from 'graphql/mutations/createStory';
import gql from 'graphql-tag';
import { useSidebarContext } from 'globalState';
import { SidebarDatePickerContext } from 'globalState/sidebarDatePicker';
import { SidebarSubTabContext } from 'globalState/sidebarSubTab';

const GET_STORIES = gql`
  query GetStories($input: GetMemberInput, $limit: Int, $nextToken: String) {
    getMembersPagination(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
      }
      nextToken
    }
  }
`;

const GET_STORIES_BY_UPDATING_DATE = gql`
  query GetStoriesByUpdatedDate(
    $input: GetMembersByPublishingDateInput
    $limit: Int
    $nextToken: String
  ) {
    getMembersByUpdatedAtDate(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
      }
      nextToken
    }
  }
`;

const useCreateStory = () => {
  const [{ isLeftHidden, leftSelection }] = useSidebarContext();
  const [selectedDates] = useContext(SidebarDatePickerContext);
  const [{ scheduleType }] = useContext(SidebarSubTabContext);
  const [createStoryForUser] = useMutation(CREATE_STORY);
  let createdStoryId;

  const { startDate, endDate, ignoreStartDate } = selectedDates;
  const date = format(new Date(), 'YYYY-MM-DD');
  const sDate = format(startDate, 'YYYY-MM-DD');
  const eDate = format(endDate, 'YYYY-MM-DD');

  const create = async (mId, mTitle, mType, userId) => {
    await createStoryForUser({
      variables: {
        input: {
          mId,
          mTitle,
          mType,
          mCreatedById: userId,
        },
      },
      update: (proxy, mutationResult) => {
        const { createStoryForMember } = mutationResult.data;
        createdStoryId = createStoryForMember.mId;

        if (leftSelection === memberTypes.STORY && scheduleType === 'scheduled') {
          return;
        }

        try {
          if (isWithinRange(date, sDate, eDate) && !isLeftHidden) {
            let query = GET_STORIES;
            let variables = getStoriesQuery(mId, mType);
            variables.input.startDate = startDate.toISOString();
            variables.input.endDate = endDate.toISOString();
            variables.input.ignoreStartDate = ignoreStartDate;

            if (leftSelection === memberTypes.STORY) {
              query = GET_STORIES_BY_UPDATING_DATE;
              variables = {
                input: {
                  mType: 'story',
                  startDate: startDate.toISOString(),
                  endDate: endDate.toISOString(),
                  scheduleType,
                  ignoreStartDate,
                },
                limit: 50,
              };
            }
            let hasQueryInCache = true;
            let storyList;
            try {
              storyList = proxy.readQuery({
                query,
                variables,
              });
            } catch (err) {
              // eslint-disable-next-line no-console
              hasQueryInCache = false;
            }
            if (hasQueryInCache) {
              const exists = story => {
                return story !== null && story.mId === createStoryForMember.mId;
              };

              if (
                leftSelection === 'story' &&
                !storyList.getMembersByUpdatedAtDate.items.some(exists)
              ) {
                storyList.getMembersByUpdatedAtDate.items.unshift(createStoryForMember);
              } else if (!storyList.getMembersPagination.items.some(exists)) {
                storyList.getMembersPagination.items.unshift(createStoryForMember);
              }

              proxy.writeQuery({
                query,
                variables,
                data: storyList,
              });
            }
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      },
    });

    return createdStoryId;
  };

  return [create];
};

export default useCreateStory;
