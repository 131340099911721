import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: ({ currentWidth }) => currentWidth,
    height: ({ currentHeight }) => currentHeight,

    background: theme.palette.dina.surfaceCardDark,
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.25), 0px 12px 24px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
  },

  headerContainer: {
    position: 'relative',
    height: '64px',
    left: '0px',
    right: '0px',
    top: '0px',
    background: theme.palette.dina.blackHoverOverlay,

    cursor: 'move',
  },

  headingOnly: {
    ...theme.typography.dina.marketplaceHeader,
    position: 'absolute',
    left: '16px',
    right: '8px',
    top: '12px',
    bottom: '0px',
  },

  headerWithSubHeaderContainer: {
    position: 'absolute',
    left: '16px',
    top: '14px',
    width: ({ currentWidth }) => `calc(${currentWidth} - 60px)`,
  },
  headingWithSubtitle: {
    ...theme.typography.dina.marketplaceHeader,
    position: 'static',
    left: '16px',
    right: '0px',
    top: '14px',
    bottom: '0px',
    paddingTop: '2px',
  },
  subHeading: {
    ...theme.typography.dina.h5,
    position: 'static',
    left: '16px',
    right: '88px',
    top: '12px',
    bottom: '30px',

    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '12px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: theme.palette.dina.mediumEmphasis,

  },

  closeButton: {
    position: 'absolute',
    width: '25px',
    height: '25px',
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    top: 'calc(50% - 25px / 2 + 0.5px)',
    right: '15px',
    backdropFilter: 'blur(15px)',
  },
  closeImage: {
    position: 'absolute',
  },

  divider: {
    position: 'absolute',
    height: '1px',
    left: '0px',
    right: '1px',
    bottom: '0px',
  },

  bottomDivider: {
    position: 'absolute',
    height: '1px',
    left: '0px',
    right: '1px',
    bottom: '57px',
  },
  footerContainer: {
    position: 'absolute',
    width: '408px',
    height: '57px',
    right: '0px',
    bottom: '0px',
  },
  footerButtonContainer: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    top: '1px',
    bottom: '0px',
  },
  okButtonContainer: {
    position: 'absolute',
    left: '50%',
    right: '0%',
    top: '8px',
    bottom: '8px',
  },
  cancelButtonContainer: {
    position: 'absolute',
    left: '0%',
    right: '50%',
    top: '8px',
    bottom: '8px',
  },

  okButton: {
    backgroundColor: theme.palette.dina.statusApproved,
    '&:hover': {
      backgroundColor: theme.palette.dina.statusApproved,
    },
    '&.disruptive': {
      backgroundColor: theme.palette.dina.statusWarning,
    },
    '&.confirmWarning': {
      backgroundColor: theme.palette.dina.statusError,
    },
    '&:disabled': {
      opacity: '0.35',
      color: theme.palette.dina.disabled,
    },
    position: 'absolute',
    left: '4px',
    right: '8px',
    top: '0px',
    bottom: '0px',
    width: '192px',
  },

  cancelButton: {
    position: 'absolute',
    left: '8px',
    right: '4px',
    top: '0px',
    bottom: '0px',
    width: '192px',
  },

  okButtonLabel: {
    ...theme.typography.dina.button,
    textTransform: 'none',
    fontWeight: 'normal',
  },

  cancelButtonLabel: {
    ...theme.typography.dina.button,
    color: theme.palette.dina.mediumEmphasis,
    textTransform: 'none',
    fontWeight: 'normal',
  },

  leftMenu: {
    position: 'absolute',
    width: '256px',
    left: '0px',
    top: '64px',
    bottom: '58px',
  },

  leftMenuContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',

    position: 'absolute',
    width: '257px',
    height: '800px',
    left: '0px',
    top: '0px',
  },

  content: {
    position: 'absolute',
    left: '256px',
    right: '0px',
    top: '64px',
    bottom: '58px',
  },
}));

export default useStyles;
