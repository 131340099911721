import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  input: {
    height: 130,
    display: 'flex',
    justifyContent: 'center',
  },
  paddedInput: {
    height: 250,
  },
  coverContainer: {
    width: ({ isMaximized }) => (isMaximized ? 'calc(100% - 112px)' : '645px'),
    transition: 'width 0.3s ease-out',
    maxWidth: 1058,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  buttonDiv: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
      '& > :first-child': {
        '& path': {
          fillOpacity: 1,
        },
      },
      '& > :last-child': {
        color: theme.palette.dina.highEmphasis,
      },
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonText: {
    ...theme.typography.dina.listItemLabel,
    fontWeight: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.25px',
    color: theme.palette.dina.mediumEmphasis,
    marginLeft: '8px',
  },
  title: {
    ...theme.typography.dina.h4,
    fontWeight: '300',
    lineHeight: '40px',
    minHeight: '80px',
    maxHeight: '80px',
  },
  titleMultiline: {
    paddingTop: 0,
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
}));

export default useStyles;
