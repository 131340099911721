import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 24px 0px 12px',
  },
  inputLabel: {
    display: 'block',
    fontSize: 10,
    fontWeight: 500,
    letterSpacing: '1.5px',
    color: theme.palette.dina.mediumEmphasis,
    marginBottom: 8,
    marginLeft: 8,
    textTransform: 'uppercase',
  },
  icon: {
    color: theme.palette.dina.iconInactive,
  },
  gridSelectIcon: {
    marginRight: '4px',
    pointerEvents: 'none',
    cursor: 'pointer',
    height: '10px',
    width: '24px',
    top: 'calc(50% - 10px/2)',
    color: theme.palette.dina.iconInactive,
  },
  outlinedInput: {
    height: ({ isDatePicker }) => (isDatePicker ? 25 : 40),
    color: 'white',
    fontSize: 14,
    letterSpacing: '0.25px',
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    '& $notchedOutline': {
      borderColor: theme.palette.dina.buttonBorderMediumEmphasis,
    },
    '&$focused $notchedOutline': {
      border: `0.5px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: theme.palette.dina.buttonBorderMediumEmphasis,
    },
    '&:hover:not($disabled):not($error)': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
    '&:hover:not($disabled):not($error) $title': {
      color: theme.palette.dina.highEmphasis,
    },
    '&:hover:not($disabled):not($error) $gridSelectIcon': {
      '& path': {
        'fill-opacity': 1,
      },
    },
    '&$disabled $notchedOutline': {
      border: `0.5px solid ${theme.palette.dina.buttonBorderOutlined}`,
      borderRadius: '4px',
    },
    '&$disabled $gridSelectIcon': {
      '& path': {
        'fill-opacity': 0.25,
      },
    },
  },
  modifiedOutlinedInput: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.mediumEmphasis,
    width: 70,
    height: 32,
    '& $notchedOutline': {
      borderColor: 'transparent',
    },
    '&$focused $notchedOutline': {
      borderColor: 'transparent',
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: 'transparent',
    },
  },
  input: {
    padding: '0px 24px 0px 0px',
    // paddingRight: '5px',
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  notchedOutline: {},
  focused: {},
  disabled: {},
  error: {},
  menu: {
    background: ({ isEditor }) =>
      theme.palette.dina[isEditor ? 'surfaceCardDark' : 'surfaceAppBackgroundMain'],
    width: '300px',
  },
  menuItem: {
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    lineHeight: '16px',
    color: theme.palette.dina.mediumEmphasis,
    letterSpacing: '0.25px',
    '&$menuItemSelected, &$menuItemSelected:hover, &$menuItemSelected:focus': {
      backgroundColor: theme.palette.dina.onSelected,
    },
  },
  title: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.mediumEmphasis,
    minWidth: ({ isDatePicker }) => (isDatePicker ? '80px' : 'auto'),
  },
  publishTitle: {
    ...theme.typography.dina.listItemLabel,
    color: ({ isEditor, isDatePicker }) =>
      theme.palette.dina[isEditor || isDatePicker ? 'highEmphasis' : 'mediumEmphasis'],
  },
  menuItemSelected: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.mediumEmphasis,
  },
  buttonHoverOverlay: {
    '&:hover ': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },
  buttonHoverTransparent: {
    '&:hover ': {
      backgroundColor: 'transparent',
    },
  },
  listItemIcon: {
    minWidth: '32px',
  },
  listItemIconEditor: {
    minWidth: '32px',
    '& path': {
      fill: theme.palette.dina.mediumEmphasis,
    },
  },
  infoText: {
    ...theme.typography.dina.captionRegular,
    lineHeight: '13px',
    letterSpacing: '0.25px',
    whiteSpace: 'pre-line',
  },
  check: {
    padding: '4px 2px 4px 4px',
    '& path': {
      'fill-opacity': 1,
    },
  },
  divider: {
    width: '100%',
    margin: '4px 0',
    backgroundColor: theme.palette.dina.dividerLight,
  },
}));

export default useStyles;
