import React from 'react';
import PropTypes from 'prop-types';
import { Paper, MenuList, MenuItem } from '@material-ui/core';
import selectionTypes from '../selectionTypes';
import useStyles from './verticalNavbar-styles';
import menuItems from './menuItems';

const VerticalNavbarView = props => {
  const { toggleSidebar, selectionChanged, selectionType, hidden } = props;

  const classes = useStyles();

  const hideSidebar = () => {
    selectionChanged(null);
    toggleSidebar();
  };

  const showSidebar = val => {
    selectionChanged(val);
    toggleSidebar();
  };

  function onSelected(val) {
    if (selectionType === val) {
      hideSidebar();
    } else if (hidden) {
      showSidebar(val);
    } else {
      selectionChanged(val);
    }
  }

  return (
    <Paper className={classes.paper} square>
      <MenuList className={classes.menuList}>
        {menuItems.map(viewItem => {
          const { title, selectedImage, unselectedImage } = viewItem;
          const isSelected = selectionType === viewItem.selectionType;

          return (
            <MenuItem
              {...{ title }}
              key={title}
              selected={isSelected}
              classes={{ root: classes.menuItem, selected: classes.selected }}
              onClick={() => onSelected(viewItem.selectionType)}
            >
              <img
                src={isSelected ? selectedImage : unselectedImage}
                alt={title}
              />
            </MenuItem>
          );
        })}
      </MenuList>
    </Paper>
  );
};

VerticalNavbarView.propTypes = {
  /** Currently selected view type */
  selectionType: PropTypes.oneOf(Object.values(selectionTypes)),
  /** Set currently selected view type */
  selectionChanged: PropTypes.func,
  /** is the sidebar hidden */
  hidden: PropTypes.bool,
  /** Toggle the sidebar */
  toggleSidebar: PropTypes.func,
};

VerticalNavbarView.defaultProps = {
  toggleSidebar: () => {},
  selectionChanged: () => {},
  selectionType: null,
  hidden: false,
};

export default VerticalNavbarView;
