import React from 'react';
import { sortBy } from 'lodash';
import Scrollbar from 'screens/main/components/scrollbar';
import StoryCard from './dayStoryCard';
import useStyles from './day-styles';

const Day = ({ data, onlyShowUsersItems }) => {
  const classes = useStyles();

  const sortedData = sortBy(data, ['mPriority']);

  return (
    <div className={classes.container}>
      <Scrollbar>
        {sortedData.map(story => {
          return <StoryCard storyData={story} {...{ onlyShowUsersItems }} key={story.mId} />;
        })}
      </Scrollbar>
    </div>
  );
};

export default Day;
