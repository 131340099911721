/**
 * Finds object based on prop, then replaces if exists or pushes if not
 *
 * @param {Object[]} oldArray to find the object from
 * @param {Object} newObj that will be replaced
 * @returns {Object[]} updated oldArray
 *
 */

const findAndReplace = (oldArray = [], newObj, prop) => {
  const objIndex = oldArray.findIndex(obj => obj[prop] === newObj[prop]);

  if (objIndex >= 0)
    return [
      ...oldArray.slice(0, objIndex),
      newObj,
      ...oldArray.slice(objIndex + 1),
    ];

  return [...oldArray, newObj];
};

export default findAndReplace;
