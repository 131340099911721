import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    marginBottom: '8px',
  },
  thumbnail: {
    position: 'relative',
    marginRight: '8px',
    height: '58px',
  },
  image: {
    width: '58px',
    height: '58px',
    borderRadius: '5px',
  },
  closeButton: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    top: '4px',
    right: '4px',
    backdropFilter: 'blur(15px)',
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    '&:hover': {
      backgroundColor: theme.palette.dina.blackHoverOverlay,
    },
  },
  closeImage: {
    width: '10px',
    height: '10px',
  },
  addButton: {
    height: '58px',
    width: '58px',
    borderRadius: '2px',
    outline: 'none',
    '&:hover': {
      '& $addIcon': {
        '& path': {
          fill: theme.palette.dina.highEmphasis,
          fillOpacity: 1,
          transition: '0.3s ease',
        },
      },
    },
  },
  addIcon: {},
}));

export default useStyles;
