import { makeStyles } from '@material-ui/styles';

const eighth = 100 / 8;
const sixth = 100 / 6;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  calendarHeader: {
    display: 'flex',
    width: '100%',
    height: '32px',
    alignItems: 'center',
  },
  calendarBody: {
    flexGrow: 1,
  },
  calendarRow: {
    width: '100%',
    height: `${sixth}%`,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  row: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  cell: {
    width: `${eighth}%`,
    display: 'flex',
    justifyContent: 'center',
  },
  weekText: {
    ...theme.typography.dina.captionSmall,
    color: theme.palette.dina.disabled,
  },
  cellText: {
    ...theme.typography.dina.listItemGroupHeadersLabel,
    color: theme.palette.dina.mediumEmphasis,
    letterSpacing: '0.25px',
    fontWeight: 'normal',
  },
  cellTextToday: {
    ...theme.typography.dina.listItemGroupHeadersLabel,
    color: theme.palette.dina.onSelected,
    letterSpacing: '0.25px',
  },
  cellTextHoliday: {
    ...theme.typography.dina.listItemGroupHeadersLabel,
    color: theme.palette.dina.datePickerHoliday,
    letterSpacing: '0.25px',
    fontWeight: 'normal',
  },
  dateDivider: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: `calc(100% - ${eighth}%)`,
    marginLeft: `${eighth}%`,
  },
}));

export default useStyles;
