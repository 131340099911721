import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  textField: {
    margin: '0px',
    width: '100%',
    height: 40,
  },
  labelRoot: {
    color: theme.palette.dina.highEmphasis,
    '&$focused': {
      color: theme.palette.dina.disabled,
    },
  },
  focused: {},
  disabled: {},
  error: {},
  inputRoot: {},

  input: {
    color: theme.palette.dina.highEmphasis,
  },
  underline: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.dina.inputBorderResting}`,
    },

    '&:after': {
      borderBottom: `2px solid ${theme.palette.dina.onFocus}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
    '&:before': {
      borderBottom: `1px solid ${theme.palette.dina.disabled}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
  },
}));

const DateAndTimePicker = props => {
  const classes = useStyles();
  const { onChange, defaultDate, type, ...other } = props;

  return (
    <TextField
      type={type}
      onChange={onChange}
      defaultValue={defaultDate}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
          focused: classes.focused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.input,
          underline: classes.underline,
        },
      }}
      data-testid="date-time"
      {...other}
    />
  );
};

DateAndTimePicker.propTypes = {
  /** Callback to be invoked on date selection
   *  with currently selected date/dates passed in as an array */
  onChange: PropTypes.func,
  /** Default date or datetime to be shown */
  defaultDate: PropTypes.string,
  /** Type of picker */
  type: PropTypes.oneOf(['datetime-local', 'date', 'time']),
};

DateAndTimePicker.defaultProps = {
  onChange: () => {},
  defaultDate: '',
  type: 'datetime-local',
};

export default DateAndTimePicker;
