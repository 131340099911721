import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format, isToday, isWeekend } from 'date-fns';
import { Typography, Button } from '@material-ui/core';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useDrop } from 'react-dnd';
import { sortBy } from 'lodash';
import Scrollbar from 'screens/main/components/scrollbar';
import { ReactComponent as Pitch } from 'assets/icons/systemicons/storymarket_off.svg';
import { ReactComponent as Story } from 'assets/icons/systemicons/HeaderNavbar/stories_off.svg';
import { planningViews } from 'utils/planningViews';
import dndTypes from 'utils/dndTypes';
import Popover from 'components/popover/Popover';
import CreateStory from 'components/createNew';
import StoryCard from '../weekStoryCard';
import useStyles from './dayCell-styles';

const DayCell = props => {
  const {
    date,
    stories,
    onTransition,
    onCreateStory,
    onCreatePitch,
    openStory,
    showStory,
    showPitch,
    onlyShowUsersItems,
    updateDroppedMember,
  } = props;
  const classes = useStyles(isToday(date), isWeekend(date))();
  const [createNewVariant, setCreateNewVariant] = useState('story');
  const [anchorEl, setAnchorEl] = useState(null);

  const sortedStories = sortBy(stories, ['mPriority']);

  const [{ didDrop }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.PITCH],
    drop(item, monitor) {
      if (!didDrop) {
        item.id && updateDroppedMember(item.id, date);
      }
    },
  });

  const handleTransition = () => {
    onTransition(new Date(date).toISOString(), planningViews.DAY);
  };

  return (
    <div ref={dropRef} className={classes.root}>
      <div className={classes.columnHeader}>
        <div
          className={classes.dateBlock}
          onClick={handleTransition}
          onKeyPress={handleTransition}
          role="button"
          tabIndex="0"
        >
          <Typography classes={{ root: classes.dateText }} className={classes.date}>
            {format(date, 'ddd D')}
          </Typography>
        </div>
        <div className={classes.allDay}>{}</div>
      </div>
      <Droppable droppableId={date}>
        {provided => (
          <div className={classes.cardContainerWrapper}>
            <Scrollbar>
              <div
                className={classes.cardContainer}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {sortedStories.map((story, index) => {
                  const {
                    mId,
                    mTitle,
                    mThumbnailUrl,
                    mCategories,
                    mPriority,
                    mAssignedMembers,
                    mType,
                    mUpdatedAt,
                  } = story;
                  return (
                    <Draggable draggableId={mId} index={index} key={mId}>
                      {(draggableProvided, snapshot) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                        >
                          <StoryCard
                            id={mId}
                            assignedMembers={mAssignedMembers || []}
                            title={mTitle}
                            image={mThumbnailUrl}
                            categories={mCategories || []}
                            isDragging={snapshot.isDragging}
                            openStory={openStory}
                            priorityLevel={mPriority}
                            isPitch={mType === 'pitch'}
                            onlyShowUsersItems={onlyShowUsersItems}
                            updatedAt={mUpdatedAt}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}

                <div className={classes.createNewContainer}>
                  {showStory && (
                    <Button
                      classes={{ root: classes.button }}
                      onClick={e => {
                        setCreateNewVariant('story');
                        setAnchorEl(e.currentTarget.parentNode);
                      }}
                    >
                      <div className={classes.buttonChild}>
                        <Story />
                        <Typography classes={{ root: classes.buttonText }}>New Story</Typography>
                      </div>
                    </Button>
                  )}
                  {showPitch && showStory && <div className={classes.padding} />}
                  {showPitch && (
                    <Button
                      classes={{ root: classes.button }}
                      onClick={e => {
                        setCreateNewVariant('pitch');
                        setAnchorEl(e.currentTarget.parentNode);
                      }}
                    >
                      <div className={classes.buttonChild}>
                        <Pitch />
                        <Typography classes={{ root: classes.buttonText }}>New Pitch</Typography>
                      </div>
                    </Button>
                  )}
                </div>

                {provided.placeholder}
              </div>
            </Scrollbar>
          </div>
        )}
      </Droppable>
      <Popover onClose={() => setAnchorEl(null)} anchorEl={anchorEl} position="right">
        <CreateStory
          onCancel={() => setAnchorEl(null)}
          variant={createNewVariant}
          onCreate={title => {
            if (createNewVariant === 'story') onCreateStory(title, date);
            else onCreatePitch(title, date);
            setAnchorEl(null);
          }}
          title="Create Story"
        />
      </Popover>
    </div>
  );
};

DayCell.propTypes = {
  /** Date of the DayCell */
  date: PropTypes.string.isRequired,
  /** List of stories of the date  */
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      /** Id of the story */
      mId: PropTypes.string,
      /** Title of the story */
      mTitle: PropTypes.string,
      /** Image of the story */
      mThumbnailUrl: PropTypes.string,
      /** Categories of the story */
      mCategories: PropTypes.arrayOf(PropTypes.any),
    }),
  ),
  /** boolean that indicates to show story */
  showStory: PropTypes.bool,
  /** boolean that indicates to show pitch */
  showPitch: PropTypes.bool,
  /** boolean that shows only users stories & pitches the user is assigned to */
  onlyShowUsersItems: PropTypes.bool,
  /** callback to be invoked when a story is dropped from left sidebar */
  updateDroppedMember: PropTypes.func,
};

DayCell.defaultProps = {
  stories: [],
  showPitch: true,
  showStory: true,
  onlyShowUsersItems: false,
  updateDroppedMember: () => {},
};

export default DayCell;
