import gql from 'graphql-tag';

export default gql`
  query GetPlatforms {
    getPlatforms(input: { mType: platform }) {
      mTitle
      mProperties {
        __typename
        ... on PlatformType {
          platform
          platformIcon
          accounts {
            accountUrl
            accountLogo
            accountTitle
            accountId
            recurrence {
              startTime
              duration
              timeZone
              rRule
            }
          }
        }
      }
    }
  }
`;
