import React from 'react';
import { useMutation } from 'react-apollo';
import UPDATE_STORY from 'graphql/mutations/updateMember';
import Scrollbar from 'screens/main/components/scrollbar';

import CardContainerView from './cardContainer-view';

const CardContainer = ({ stories, openStory, instances, platforms, xScaleRef, margin }) => {
  const [updateStory] = useMutation(UPDATE_STORY);

  const handlePriorityChange = async (priorityValue, id, priorityLevel) => {
    const input = { mId: id };
    if (priorityValue) {
      input.mPriority = priorityValue;
    } else if (priorityLevel) {
      input.mPriority = null;
    }
    try {
      await updateStory({ variables: { input } });
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <div style={{ height: `calc(100vh - 240px)` }}>
      <Scrollbar>
        {stories.map(story => {
          const { mId } = story;
          return (
            <CardContainerView
              key={mId}
              instances={instances[mId]}
              story={story}
              openStory={openStory}
              xScaleRef={xScaleRef}
              platforms={platforms}
              onPriorityChange={handlePriorityChange}
              margin={margin}
            />
          );
        })}
      </Scrollbar>
    </div>
  );
};

export default CardContainer;
