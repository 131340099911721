import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    // marginLeft: '6px',
    display: 'flex',
    position: 'relative',
  },
  avatarWrapper: {
    padding: '8px 16px 0',
  },
  typographyDiv: {
    marginTop: 20,
  },
  title: {
    ...theme.typography.dina.avatarHeaderTitle,
  },
  subtitle: {
    ...theme.typography.dina.avatarHeaderSubtitle,
    margin: '4px 0',
  },
  teamInfo: {
    ...theme.typography.dina.captionMedium,
    fontWeight: 'normal',
    fontSize: '13px',
    color: theme.palette.dina.mediumEmphasis,
  },
}));

export default useStyles;
