import React, { memo } from 'react';
import { markTypes, elementTypes } from 'components/editor/constants';
import useCheckUserRight from 'hooks/useCheckUserRight';
import MarkButton from '../markButton';
import ColorButton from '../colorButton';
import AutomationButton from '../automationButton';
import Wrapper from '../wrapper';

const { BOLD, ITALIC, UNDERLINE } = markTypes;

const {
  CAMERA,
  PACKAGE,
  VOICE_OVER,
  FULLSCREEN_GRAPHICS,
  LIVE,
  DVE,
  JINGLE,
  BREAK,
  TELEPHONE,
  ADLIB,
  AUDIO,
  ACCESSORY,
  OVERLAY_GRAPHICS,
} = elementTypes;

const LinearToolbar = () => {
  const [checkUserRight] = useCheckUserRight();
  const canShowStarCG = checkUserRight('feature', 'starcg');

  return (
    <>
      <Wrapper showRightMargin>
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <ColorButton />
      </Wrapper>

      <Wrapper showRightMargin>
        <AutomationButton type={CAMERA} />
        <AutomationButton type={PACKAGE} />
        <AutomationButton type={VOICE_OVER} />
        <AutomationButton type={FULLSCREEN_GRAPHICS} />
        <AutomationButton type={LIVE} />
        <AutomationButton type={DVE} />
        <AutomationButton type={JINGLE} />
        <AutomationButton type={BREAK} />
        <AutomationButton type={TELEPHONE} />
        <AutomationButton type={ADLIB} />
        {canShowStarCG && <AutomationButton type={OVERLAY_GRAPHICS} />}
        <AutomationButton type={AUDIO} />
        <AutomationButton type={ACCESSORY} />
      </Wrapper>
    </>
  );
};

export default memo(LinearToolbar);
