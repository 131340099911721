import { makeStyles } from '@material-ui/styles';
import ArrowRightSrc from 'assets/icons/systemicons/arrows/disclosurearrow_right_opaque.svg';
import ArrowLeftSrc from 'assets/icons/systemicons/arrows/disclosurearrow_left_opaque.svg';

const useStyles = makeStyles(() => ({
  root: {
    color: 'white',
    position: 'relative',
    width: '100%',
    height: 55,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .dragging .ds-hover': { color: 'white' },
  },
  selector: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 1,
    borderTop: '1px dashed white',
    transform: 'translate3d(24px, 15px, 0)',

    '&.right:after': {
      content: `url(${ArrowRightSrc})`,
      display: 'block',
      position: 'absolute',
      top: 0,
      left: '100%',
      height: 24,
      transform: 'translate3d(-50%,-12px,0)',
    },

    '&.left:after': {
      content: `url(${ArrowLeftSrc})`,
      display: 'block',
      position: 'absolute',
      top: 0,
      right: '100%',
      height: 24,
      transform: 'translate3d(50%,-12px,0)',
    },
  },
}));

export default useStyles;
