import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isPast } from 'date-fns';
import { select } from 'd3';
import { storyTimelinePublishingPoints, publishingPoints } from 'assets/icons/publishingPoints';
import useStyles from './instance-styles';
import createDragBehavior from './createDragBehavior';

const Instance = ({
  instance,
  gridHeight,
  iconSize,
  draggingRef,
  height,
  margin,
  rootRectRef,
  newXScaleRef,
  onSchedule,
  width,
}) => {
  const classes = useStyles();
  const groupRef = useRef(null);
  const selfDraggingRef = useRef(false);
  const { start, translateX, translateY, publishingPlatform } = instance;
  const published = isPast(start);
  const [newTranslateX, setNewTranslateX] = useState(0);
  const [newTranslateY, setNewTranslateY] = useState(0);
  const isInView = selfDraggingRef.current || (translateX >= 0 && translateX <= width);
  const { mProperties } = publishingPlatform;
  const { platformIcon } = mProperties;

  const init = () => {
    const element = select(groupRef.current);

    if (!published && isInView) {
      const dragInstance = createDragBehavior({
        instance,
        element,
        draggingRef,
        selfDraggingRef,
        gridHeight,
        height,
        rootRectRef,
        iconSize,
        newXScaleRef,
        margin,
        onSchedule,
        setNewTranslateX,
        setNewTranslateY,
      });

      element.call(dragInstance);
    }

    return () => {
      element.on('.drag', null);
    };
  };

  const update = () => {
    if (!selfDraggingRef.current) {
      setNewTranslateX(translateX);
      setNewTranslateY(translateY);
    }
  };

  useEffect(init, [isInView]);
  useEffect(update, [translateX, translateY]);

  return (
    isInView && (
      <g
        ref={groupRef}
        className={`${classes.root} ${published ? 'published' : ''}`}
        transform={`translate(${newTranslateX},${newTranslateY})`}
        tabIndex={0}
      >
        <image
          width={iconSize}
          height={iconSize}
          x={0}
          y={-gridHeight / 2 - iconSize / 2}
          href={
            storyTimelinePublishingPoints[platformIcon]
              ? storyTimelinePublishingPoints[platformIcon]
              : storyTimelinePublishingPoints.default
          }
        />
      </g>
    )
  );
};

Instance.propTypes = {
  /** Specifies the instance */
  instance: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    publishingPoint: PropTypes.oneOf(Object.keys(publishingPoints)),
    publishingPlatform: PropTypes.shape({
      mTitle: PropTypes.string,
      mProperties: PropTypes.shape({
        platform: PropTypes.string,
        platformIcon: PropTypes.string,
      }),
    }),
    translateX: PropTypes.number,
    translateY: PropTypes.number,
  }).isRequired,
  /** Specifies the height of the timeline grid */
  gridHeight: PropTypes.number.isRequired,
  /** Specifies the size of the instance icons on timeline grid */
  iconSize: PropTypes.number.isRequired,
  /** Determines if zoom controller is being dragged or not */
  draggingRef: PropTypes.shape({
    current: PropTypes.bool,
  }).isRequired,
  /** Specifies the height of the timeline */
  height: PropTypes.number.isRequired,
  /** Specifies the margin for the timeline grid */
  margin: PropTypes.number.isRequired,
  /** Specifies the dimensions for the root timegrid element */
  rootRectRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  /** Specifies the current d3 time scale */
  newXScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  /** Callback to be invoked when an instance is scheduled/re-schduled,
   *  with the newly scheduled instance passed in */
  onSchedule: PropTypes.func.isRequired,
  /** Specifies the width of the timeline */
  width: PropTypes.number.isRequired,
};

export default Instance;
