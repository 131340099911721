import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { scaleTime } from 'd3';
import { startOfDay, endOfDay, addSeconds } from 'date-fns';
import TimeGrid from './timeGrid';
import CurrentTimeIndicator from './currentTimeIndicator';
import CardContainer from './cardContainer';
import RundownArea from './rundownArea';
import useStyles from './timeline-styles';

const Timeline = ({ stories, instances, time, timelineSize, platforms, rundowns, openStory }) => {
  const classes = useStyles();
  const svgRef = useRef(null);
  const xScaleRef = useRef(null);
  const topGutterHeight = 34;
  const margin = 5;
  const storyCardWidth = stories.length ? 240 : 0;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const init = () => {
    const rect = svgRef.current.getBoundingClientRect();
    const calculatedWidth = rect.width.toFixed(0);
    const calculatedHeight = rect.height.toFixed(0);

    xScaleRef.current = scaleTime()
      .domain([startOfDay(time), addSeconds(endOfDay(time), 1)])
      .range([0, calculatedWidth - storyCardWidth - 2 * margin]);

    setWidth(Number(calculatedWidth));
    setHeight(Number(calculatedHeight));
  };

  useEffect(init, [timelineSize, time, storyCardWidth]);

  return (
    
    <div className={classes.root}>
      <svg
        width="100%"
        height="calc(100vh - 200px)"
        ref={svgRef}
        className={classes.svg}
      >
        <foreignObject width="100%" height="calc(100vh - 200px)" y={`${topGutterHeight}px`}>
          <CardContainer stories={stories} openStory={openStory} {...{
              xScaleRef,
              instances,
              platforms,
              margin,
            }} />
        </foreignObject>
        {rundowns.map(rundown => (
          <RundownArea
            key={rundown.mId}
            {...{ xScaleRef, height, rundown, margin, storyCardWidth }}
          />
        ))}
        <TimeGrid
          {...{
            xScaleRef,
            height,
            margin,
            topGutterHeight,
            storyCardWidth,
          }}
        />
        <CurrentTimeIndicator
          {...{ xScaleRef, height, width, margin, storyCardWidth }}
          gridHeight={height - topGutterHeight + 16}
        />
      </svg>
    </div>
  );
};

Timeline.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.shape({ mId: PropTypes.string })),
  time: PropTypes.string,
  timelineSize: PropTypes.number.isRequired,
  instances: PropTypes.objectOf(PropTypes.any),
  platforms: PropTypes.arrayOf(PropTypes.object),
  rundowns: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
    }),
  ),
  openStory: PropTypes.func,
};

Timeline.defaultProps = {
  stories: [],
  time: new Date().toISOString(),
  instances: {},
  platforms: [],
  rundowns: [],
  openStory: () => {},
};

export default Timeline;
