import GET_MEMBERS_OF from 'graphql/queries/getMembersOf';
import GET_STORIES from 'graphql/queries/getStoriesPagination';
import GET_STORY from 'graphql/queries/getStory';
import memberTypes from 'graphql/memberTypes';
import {
  getStoriesQuery,
  getMembersOfQuery,
  getMemberQuery,
} from 'graphql/queryVariables';

/** Get stories from cache for a member of type user/team/department  */
const getStories = (proxy, memberId, type) => {
  try {
    const stories = proxy.readQuery({
      query: GET_STORIES,
      variables: getStoriesQuery(memberId, type),
    });

    return stories;
  } catch (error) {
    console.log(error);
  }

  return { getMembersPagination: { items: [] } };
};

/** Find the story from the cache of the members'(user, teams, departments) stories */
const findStory = (proxy, storyId, memberIds, memberType) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const id of memberIds) {
    const stories = getStories(proxy, id, memberType);
    const story = stories.getMembersPagination.items.find(
      s => s.mId === storyId
    );
    if (story) {
      return story;
    }
  }

  return null;
};

/** Find the given story from user stories, team and department stories cache
 * in which the user is a member
 */
const findStoryFromUserStories = (proxy, userId, storyId) => {
  let story = findStory(proxy, storyId, [userId], memberTypes.USER_STORY);

  /** If story is not present in user story list then find the
   * story in teams story list cache. */
  if (!story) {
    try {
      const userTeams = proxy.readQuery({
        query: GET_MEMBERS_OF,
        variables: getMembersOfQuery(userId, memberTypes.TEAM_USER),
      });

      const teamIds = userTeams.getMembersOf.map(t => t.mId);
      story = findStory(proxy, storyId, teamIds, memberTypes.TEAM_STORY);
    } catch (error) {
      console.log(error);
    }
  }

  /** If story is not present in team stories then find the
   * story in departments story cache. */
  if (!story) {
    try {
      const userDepartments = proxy.readQuery({
        query: GET_MEMBERS_OF,
        variables: getMembersOfQuery(userId, memberTypes.DEPARTMENT_USER),
      });

      const deptIds = userDepartments.getMembersOf.map(d => d.mId);
      story = findStory(proxy, storyId, deptIds, memberTypes.DEPARTMENT_STORY);
    } catch (e) {
      console.log(e);
    }
  }

  /** Check if the story is the story displayed in the center area */
  if (!story) {
    try {
      const storyInfo = proxy.readQuery({
        query: GET_STORY,
        variables: getMemberQuery(storyId),
      });

      story = storyInfo.getMember;
    } catch (e) {
      console.log(e);
    }
  }

  return story;
};

export { findStoryFromUserStories, getStories };
