import React, { useContext } from 'react';
import UserContext from 'contexts/UserContext';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import { Fade } from '@material-ui/core';
import StoryCardView from './storyCard-view';
import useStyles from './storyCard-styles';

const StoryCard = props => {
  const {
    id,
    isPitch,
    onlyShowUsersItems,
    onHideItem,
    image,
    title,
    assignedMembers,
    openStory,
    updatedAt,
  } = props;
  const userData = useContext(UserContext);
  const { mId: userId } = userData;
  const classes = useStyles();

  const [assignedUsers] = useGetAssignedMembers(assignedMembers || []);
  const users = assignedUsers;
  const user = users.find(usr => usr.mId === userId);
  if (!user && onlyShowUsersItems) {
    onHideItem(id);
    return null;
  }

  return (
    <Fade in timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
      <div className={classes.card}>
        <StoryCardView
          {...{ id, image, title, isPitch, openStory, updatedAt }}
          assignee={users.length ? users[0] : {}}
        />
      </div>
    </Fade>
  );
};

export default StoryCard;
