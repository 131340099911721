import React, { useState, useCallback, useRef } from 'react';
import { useSlate } from 'slate-react';
import { Transforms, Range, Editor } from 'slate';
import { elementTypes } from 'components/editor/constants';
import Dialog from 'components/dialog';
import { ReactComponent as IconComponent } from 'assets/icons/systemicons/editor/linkSmall_off.svg';
import wrapLink from 'components/editor/components/link/utils/wrapLink';
import ButtonBase from '../buttonBase';
import DialogContent from './components/dialogContent';
import actionTypes from './constants/actionTypes';

const LinkButton = () => {
  const editor = useSlate();
  const { selection } = editor;
  const selectionCacheRef = useRef(selection);
  const isCollapsed = selection && Range.isCollapsed(selection);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [match] = Editor.nodes(editor, {
    match: ({ type }) => type === elementTypes.LINK,
  });

  const initialLink = match && match[0].data.href;
  const initialLinkText = match && match[0].children[0].text;

  const closeDialog = useCallback(() => setDialogOpen(false), []);
  const openDialog = useCallback(() => setDialogOpen(true), []);

  const onMouseDown = useCallback(
    event => {
      event.preventDefault();

      if (selection) {
        selectionCacheRef.current = selection;
        openDialog();
      }
    },
    [selection, openDialog],
  );

  const update = useCallback(
    ({ type, payload }) => {
      closeDialog();

      Transforms.select(editor, selectionCacheRef.current);

      if (type === actionTypes.CONFIRM) {
        const { href, linkText } = payload;

        wrapLink(editor, href, linkText);
      }
    },
    [closeDialog, editor],
  );

  return (
    <>
      <ButtonBase
        isActive={Boolean(match)}
        type={elementTypes.LINK}
        {...{ IconComponent, onMouseDown }}
      />

      <Dialog onClose={closeDialog} open={dialogOpen}>
        <DialogContent {...{ initialLink, initialLinkText, isCollapsed, update }} />
      </Dialog>
    </>
  );
};

export default LinkButton;
