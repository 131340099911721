import gql from 'graphql-tag';

export default gql`
  query GetDownload($input: ExportContentInput) {
    exportContent(input: $input) {
      mId
      mRefId
      mTitle
      data
    }
  }
`;
