import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    height: '100%',
    alignItems: 'center',
  },
  leftComponents: {
    flexGrow: 1,
  },
  rightComponents: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
  storyIcon: {
    '& path': {
      fill: ({ showStory }) =>
        showStory
          ? theme.palette.dina.primary700
          : theme.palette.dina.iconInactive,
    },
  },
  button: {
    minWidth: '32px',
    maxWidth: '32px',
    height: '32px',
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    borderRadius: '20px',
    boxSizing: 'border-box',
    border: `1px dashed ${theme.palette.dina.buttonBorderMediumEmphasis}`,
    marginRight: '4px',
    backdropFilter: 'blur(15px)',
  },
  divider: {
    height: '32px',
    width: '1px',
    marginRight: '12px',
    border: 'none',
    flexShrink: 0,
    backgroundColor: theme.palette.dina.dividerLight,
  },
}));

export default useStyles;
