import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants/types';
import useStyles from './styles';

const Quote = ({ attributes, children, element }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...attributes}>
      {children}
    </div>
  );
};

Quote.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Quote.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.QUOTE,
    children: [],
  },
};

export default memo(Quote);
