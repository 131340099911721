const InputFieldTypes = {
  TEXT: 'text',
  PASSWORD: 'passsword',
  EMAIL: 'email',
  TELEPHONE: 'tel',
};

export const usageUtils = {
  NORMAL: 'normal',
  METADATA: 'metadata',
  EDITOR: 'editor',
};

export default InputFieldTypes;
