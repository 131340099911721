import React, { useCallback, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor } from 'slate-react';
import configCtx from 'contexts/configContext';
import DropZone from 'components/editor/components/dropZone';
import getTypeValues from 'components/editor/utils/getTypeValues';
import getDefaultTemplate from 'components/editor/utils/getDefaultTemplate';
import { elementTypes } from 'components/editor/constants/types';
import useEditorContext from 'components/editor/hooks/useEditorContext';
// eslint-disable-next-line max-len
import insertPrimaryAutomationElement from 'components/editor/components/primaryAutomation/utils/insertPrimaryAutomationElement';

const primaryTypeValues = getTypeValues(elementTypes.primaryTypes);

const PrimaryDropZone = ({ children, element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const { automationTemplateConfigs } = useContext(configCtx);

  const { templates } = automationTemplateConfigs[0]
    ? automationTemplateConfigs[0].templateSets[0]
    : {};

  const onDrop = useCallback(
    ({ type }) => {
      const { variant: templateVariant, name, description } = getDefaultTemplate(type, templates);
      const data = { templateVariant, name, description };

      insertPrimaryAutomationElement({
        editor,
        type,
        update,
        data,
        isNewline: false,
      });

      ReactEditor.focus(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  return (
    <DropZone accept={primaryTypeValues} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

PrimaryDropZone.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

PrimaryDropZone.defaultProps = {
  children: null,
  element: {
    children: [],
  },
};

export default memo(PrimaryDropZone);
