import { makeStyles } from '@material-ui/styles';

const useStyles = (width, height) => {
  return makeStyles(theme => ({
    root: {
      width,
      height,
      borderRadius: '4px',
    },
  }));
};

export default useStyles;
