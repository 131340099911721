import memberTypes from 'graphql/memberTypes';

const getUpdatedStorylist = (list, story) => {
    const updatedList = list;
    const { crudAction } = story.message;
    if (crudAction === "INSERT") {
        const memberExist = updatedList.filter(s => s.mId === story.mId);
        if (memberExist.length === 0) {
            updatedList.unshift(story);
        }
        return updatedList;
    }
    if (crudAction === "REMOVE") {
        return updatedList.filter(s => s.mId !== story.mId);
    }
    return list;
}

const updateStoryList = (proxy, story, query, variables) => {
    try {
       
        const list = proxy.readQuery({
            query,
            variables,
        });
        const { items, nextToken } = list.getMembersPagination;
        let storyItems = [...items];
        storyItems = getUpdatedStorylist(storyItems, story);
        // Write updated member list in the cache.
        proxy.writeQuery({
            query,
            variables,
            data: {
                getMembersPagination:
                    { items: storyItems, __typename: 'PaginatedMemberType', nextToken },
            },
        });
    }
    catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
}

export default updateStoryList;