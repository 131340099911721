import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from 'components/dialog';
import useArchiveMember from 'hooks/useArchiveMember';
import ConfirmDialog from 'components/confirmDialog';
import memberTypes from 'graphql/memberTypes';
import useCheckUserRight from 'hooks/useCheckUserRight';
import AvatarHeader from '../../avatarHeader';
import DetailWrapper from '../../detailWrapper';
import Info from './info';

const DetailView = ({ showMaster, selectedItem, ...rest }) => {
  const { name, image } = selectedItem;
  const menuItems = [
    {
      action: 'ARCHIVE',
      label: 'Archive Department',
      icon: <DeleteIcon />,
    },
  ];
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [noOfTeams, setNoOfTeams] = useState(0);

  const [archiveMember] = useArchiveMember();
  const [checkUserRight] = useCheckUserRight();
  const canDeleteDepartment = checkUserRight('folder', 'delete-departments');

  const filteredMenuItems = canDeleteDepartment
    ? menuItems
    : menuItems.filter(item => item.action !== 'ARCHIVE');

  const archiveDepartment = id => {
    archiveMember(id, memberTypes.DEPARTMENT).then(() => {
      setArchiveDialogOpen(false);
      showMaster(true);
    });
  };

  const handleMenuItemClick = action => {
    switch (action) {
      case 'ARCHIVE':
        setArchiveDialogOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <DetailWrapper
      header={
        <div>
          <AvatarHeader
            {...selectedItem}
            {...rest}
            onMenuItemClick={handleMenuItemClick}
            menuItems={filteredMenuItems}
            onBackButtonClick={showMaster}
            backButtonLabel="Departments"
            variant="Department"
            hideIcons
            title={name}
            subtitle="Department"
            avatarUrl={image}
            additionalInfo={`${noOfTeams} team${noOfTeams > 1 ? 's' : ''}`}
          />

          <Dialog open={isArchiveDialogOpen} onClose={() => setArchiveDialogOpen(false)}>
            <ConfirmDialog
              onCancel={() => setArchiveDialogOpen(false)}
              onConfirm={() => {
                archiveDepartment(selectedItem.id);
              }}
              title="Archive department?"
              confirmButtonLabel="Archive"
              confirmButtonType="danger"
              info={`Department: "${selectedItem.name}" will be archived.`}
            />
          </Dialog>
        </div>
      }
      body={<Info {...selectedItem} {...{ setNoOfTeams, ...rest }} />}
    />
  );
};

export default DetailView;
