import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as VideoIcon } from 'assets/icons/systemicons/editor/video_off.svg';
import { menuOptions } from 'components/editor/constants';
import AddMedia from '../addMedia';
import AddThumbnails from '../addThumbnails';
import Box from '../box';
import useStyles from './styles';

const maxThumbnails = 4;

const VideoBase = ({
  readOnly,
  uploadVideo,
  removeVideo,
  videoSrc,
  thumbnails,
  addThumbnails,
  removeThumbnail,
  showThumbnail,
  onMenuSelect,
  hideEllipsisButton,
  ...others
}) => {
  const classes = useStyles();

  return (
    <Box
      type="media"
      title="Video"
      readOnly={readOnly}
      menuItems={menuOptions}
      onMenuSelect={onMenuSelect}
      hideEllipsisButton={hideEllipsisButton}
      iconComponent={<VideoIcon className={classes.icon} />}
    >
      <AddMedia
        addMedia={uploadVideo}
        removeMedia={removeVideo}
        mediaSrc={videoSrc}
        mediaType="video"
        mediaWidth={156}
        {...others}
      />
      {showThumbnail && (
        <div className={classes.thumbnail}>
          <p className={classes.thumbnailText}>{`Add video thumbnails (max. ${maxThumbnails})`}</p>
          <AddThumbnails {...{ thumbnails, addThumbnails, maxThumbnails, removeThumbnail }} />
        </div>
      )}
    </Box>
  );
};

VideoBase.propTypes = {
  /** Boolean that indicates a read only component */
  readOnly: PropTypes.bool,
  /** Callback to upload a video */
  uploadVideo: PropTypes.func,
  /** Callback to remove a video */
  removeVideo: PropTypes.func,
  /** Source of the video */
  videoSrc: PropTypes.string,
  /** A list of thumbnails */
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      /** Id of the image as asset */
      mId: PropTypes.string,
      /** unique Id of the image as asset */
      mRefId: PropTypes.string,
      /** mContentKey of the thumbnail image, is used to get the link of the image */
      src: PropTypes.string,
    }),
  ),
  /** Callback to add thumbnail */
  addThumbnails: PropTypes.func,
  /** Callback to remove thumbnail */
  removeThumbnail: PropTypes.func,
  /** Boolean to show thumbnail */
  showThumbnail: PropTypes.bool,
  /** Callback to be invoked when a menu is selected */
  onMenuSelect: PropTypes.func,
};

VideoBase.defaultProps = {
  readOnly: false,
  uploadVideo: () => {},
  removeVideo: () => {},
  videoSrc: '',
  thumbnails: [],
  addThumbnails: () => {},
  removeThumbnail: () => {},
  showThumbnail: true,
  onMenuSelect: () => {},
};

export default memo(VideoBase);
