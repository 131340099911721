import gql from 'graphql-tag';

export default gql`
  mutation CreateContentTemplate($input: CreateContentTemplateInput) {
    createContentTemplate(input: $input) {
      mId
      mRefId
      mTitle
      mContentKey
    }
  }
`;
