import { useState, useEffect } from 'react';

// Our hook
export default function useDebounce(value, duration = 1000) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, duration);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}
