import React from 'react';
import PropTypes from 'prop-types';
import GotoPreviousInstanceSrc from 'assets/icons/systemicons/GotoPreviousInstance.svg';
import GotoNextInstanceSrc from 'assets/icons/systemicons/GotoNextInstance.svg';
import ZoomOutSrc from 'assets/icons/systemicons/ZoomOut.svg';
import ZoomInSrc from 'assets/icons/systemicons/ZoomIn.svg';
import useStyles from './mini-map-styles';

const MiniMap = ({ zoomControllerRef }) => {
  const classes = useStyles();

  const zoomIn = () => zoomControllerRef.current.zoomIn();
  const zoomOut = () => zoomControllerRef.current.zoomOut();

  return (
    <div className={classes.root}>
      <div onClick={zoomOut} onKeyPress={zoomOut} role="button" tabIndex={0}>
        <img src={ZoomOutSrc} alt="ZoomOut" />
      </div>

      <div onClick={zoomIn} onKeyPress={zoomIn} role="button" tabIndex={0}>
        <img src={ZoomInSrc} alt="ZoomIn" />
      </div>
    </div>
  );
};

MiniMap.propTypes = {
  /** Specifies the ImperativeHandle for ZoomController component */
  zoomControllerRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
};

export default MiniMap;
