import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    margin: '0px 6px',
  },
  titles: {
    width: 'calc(100% - 24px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    ...typography.dina.caption,
    color: 'white',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    lineHeight: '114%',
  },
  subtitle: {
    ...typography.dina.caption,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    lineHeight: '114%',
  },
}));

export default useStyles;
