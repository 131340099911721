import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from 'react-apollo';
import { debounce, isEqual, isNull } from 'lodash';
import useLockMember from 'hooks/useLockMember';
import useUnlockMember from 'hooks/useUnlockMember';
import useInterval from 'hooks/useInterval';
import GET_USER from 'graphql/queries/getUser';
import GET_MEMBER_LOCK from 'graphql/queries/getMemeberLock';
import UserCtx from 'contexts/UserContext';
import { uploadToS3 } from 'utils/s3Utils';
import useTextStorage from 'hooks/useTextStorage';
import updateMember from 'graphql/mutations/updateMember';
import { getMemberQuery } from 'graphql/queryVariables';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import configCtx from 'contexts/configContext';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import { isOlderSlateValue, migrateValue } from 'components/editor';
import getHostReadSpeed from 'components/instanceCard/utils/getHostReadSpeed';
import Content from './content-view';

const ContentContainer = props => {
  const { member } = props;
  const { mId, mContentKey, locked: memberLocked, mDescription: description, mMetaData } = member;
  // const [description, setDescription] = useState(member.mDescription);
  const { metadataForms } = useContext(configCtx);
  const blankMetaData = getEmptyMetadataForForm(metadataForms[0]);
  const hostReadSpeed = getHostReadSpeed(mMetaData || blankMetaData);
  const [content, setContent] = useState(null);
  const user = useContext(UserCtx);
  const { mId: userId } = user;
  const [writeLock, setWriteLock] = useState(false);
  const [readLock, setReadLock] = useState(false);
  const [lockedByUser, setLockedByUser] = useState('someone');
  const [updateStory] = useMutation(updateMember);
  const [lockStory] = useLockMember();
  const [unlockStory] = useUnlockMember();
  const client = useApolloClient();
  const [isSavingContent, setIsSavingContent] = useState(false);
  const { data: s3Data, loading } = useTextStorage(mContentKey, false);
  
  useEffect(() => {
    if (s3Data) resetEditorValue(s3Data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Data]);

  const editorValueRef = useRef(s3Data);
  
  const resetEditorValue = useCallback(
    newValue => {
      if (newValue && !isEqual(newValue, content)) {
        const value = isOlderSlateValue(newValue) ? migrateValue(newValue) : newValue;

        setContent(value);
        editorValueRef.current = value;
      } else if (isNull(newValue)) {
        setContent(null);
        editorValueRef.current = null;
      }
    },
    [content],
  );

  const updateLock = useCallback(
    lockedId => {
      if (lockedId) {
        setWriteLock(lockedId === userId);
        setReadLock(lockedId !== userId);
      } else {
        window.requestAnimationFrame(() => {
          setWriteLock(false);
          setReadLock(false);
        });
      }
    },
    [userId],
  );

  const getLockedByUser = useCallback(
    async lockedId => {
      const result = await client.query({
        query: GET_USER,
        variables: getMemberQuery(lockedId),
      });

      setLockedByUser(result.data.getMember.mTitle);
    },
    [client],
  );

  useEffect(() => {
    updateLock(memberLocked);
    memberLocked && getLockedByUser(memberLocked);
  }, [getLockedByUser, mId, memberLocked, updateLock]);

  const fetchContent = useCallback(
    async url => {
      if (url) {
        const response = await fetch(url);
        const updatedContent = await response.json();

        resetEditorValue(updatedContent);
      }
    },
    [resetEditorValue],
  );

  useInterval(
    async () => {
      if (readLock) {
        const currentLockedData = await getStoryLockedData();
        if (currentLockedData && currentLockedData.data && currentLockedData.data.getMember) {
          const { locked, mContentUrl } = currentLockedData.data.getMember;
          if (readLock && locked === null) {
            fetchContent(mContentUrl); // lock is released by other user. fetch content.
          }
          updateLock(locked);
        }
      }
    },
    readLock ? 5000 : null,
  );

  useEffect(() => {
    const unloadFunc = e => {
      e.preventDefault();
      writeLock && handleUnlockStory();
      delete e.returnValue;
    };

    writeLock && window.addEventListener('beforeunload', unloadFunc);

    return () => {
      writeLock && window.addEventListener('beforeunload', unloadFunc);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeLock]);

  const debouncedDescriptionChange = debounce(newDescription => {
    try {
      const variables = {
        input: {
          mId,
          mDescription: newDescription,
        },
      };

      updateStory({
        variables,
      });
    } catch (error) {
      // console.log(error);
    }
  }, 2000);

  const handleUnlockStory = useCallback(async () => {
    await unlockStory(mId, editorValueRef.current);
    updateLock();
  }, [mId, unlockStory, updateLock]);

  const getStoryLockedData = async () => {
    const lockedData = await client.query({
      query: GET_MEMBER_LOCK,
      variables: getMemberQuery(mId),
      fetchPolicy: 'network-only',
    });

    return lockedData;
  };

  const handleFocus = async event => {
    if (!writeLock && !readLock) {
      const result = await lockStory(mId, userId);

      if (result && result.data && result.data.lockMember) {
        const { locked, mContentUrl } = result.data.lockMember;

        await fetchContent(mContentUrl);
        updateLock(locked);

        if (locked) getLockedByUser(locked);
      } else updateLock(null);
    }
  };

  const save = useCallback(
    newContent => {
      const file = new window.File([JSON.stringify(newContent)], 'content.data', {
        type: 'text/plain',
      });

      return uploadToS3(mContentKey, file);
    },
    [mContentKey],
  );

  const saveContent = useCallback(
    async newContent => {
      if (!loading && writeLock) {
        setIsSavingContent(true);
        await save(newContent);
        setIsSavingContent(false);
      }
    },
    [loading, writeLock, save],
  );

  const [debouncedSaveContent] = useDebouncedCallback(saveContent, 3000);

  const saveContentAndRelease = useCallback(() => {
    if (editorValueRef.current) save(editorValueRef.current).then(handleUnlockStory);
    else handleUnlockStory();
  }, [handleUnlockStory, save]);

  const onChange = useCallback(
    updatedContent => {
      if (writeLock) {
        editorValueRef.current = updatedContent;
        debouncedSaveContent(updatedContent);
      }
    },
    [debouncedSaveContent, writeLock],
  );

  if (!member) return <div>No content data</div>;

  if (loading) return <LoadingIndicator />;

  return (
    <Content
      handleDescriptionChange={debouncedDescriptionChange}
      onFocus={handleFocus}
      releaseWriteLock={saveContentAndRelease}
      {...{
        content,
        description,
        hostReadSpeed,
        isSavingContent,
        lockedByUser,
        mContentKey,
        mId,
        onChange,
        readLock,
        writeLock,
      }}
    />
  );
};

ContentContainer.propTypes = {
  member: PropTypes.objectOf(PropTypes.any),
};

ContentContainer.defaultProps = {
  member: {},
};

export default ContentContainer;
