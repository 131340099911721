import React from 'react';
import PropTypes from 'prop-types';
import { ICON_INTERNALS } from 'assets/icons/icons';
import colors from 'theme/colors';

const Icon = props => {
  const { icon, color, hoverColor, hovered, width, height } = props;

  return (
    <svg width={width} height={height} viewBox={ICON_INTERNALS[icon].viewBox}>
      <path
        d={ICON_INTERNALS[icon].path}
        fillRule={ICON_INTERNALS[icon].fillRule}
        fill={hovered ? hoverColor : color}
      />
    </svg>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  hovered: PropTypes.bool,
};

Icon.defaultProps = {
  width: 8,
  height: 16,
  color: colors.iconInactive,
  hoverColor: colors.iconActive,
  hovered: false,
};

export default Icon;
