import React, { useContext } from 'react';
import { CurrentTabContext } from 'globalState';
import { ToolbarContext } from 'contexts/Toolbar';
import Toolbar from './toolbar-view';

const ToolbarContainer = () => {
  const [currentTab] = useContext(CurrentTabContext);
  const [toolbarElements] = useContext(ToolbarContext);
  const { page, selecteddate, mType } = currentTab;

  return (
    <Toolbar mType={mType || page} {...{ page, selecteddate }}>
      {toolbarElements}
    </Toolbar>
  );
};

export default ToolbarContainer;
