import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  rundownList: {
    position: 'absolute',
    left: 0,
    width: showEditor => (showEditor ? 'calc(100% - 490px)' : '100%'),
    height: '100%',
    overflow: 'auto',
    borderRight: `1px solid ${theme.palette.dina.dividerLight}`,
  },
  editRundownList: {
    position: 'absolute',
    left: 0,
    width: showEditor => (showEditor ? 'calc(100% - 490px)' : '100%'),
    height: '100%',
    overflow: 'auto',
    borderRight: `1px solid ${theme.palette.dina.dividerLight}`,
  },
}));

export default useStyles;
