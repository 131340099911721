import React from 'react';
import Jimp from 'jimp';
import PropTypes from 'prop-types';
import Storage from '@aws-amplify/storage';
import { useMutation } from 'react-apollo';
import UPLOAD_MEMBER_IMAGE from 'graphql/mutations/uploadMemberImage';
import useCreateAsset from 'hooks/useCreateAsset';
import { getFileAssetData } from 'utils/assetData';
import { uploadToS3 } from 'utils/s3Utils';
import getScaledImageSize from '../getScaledImageSize';
import UploadMediaInputView from './uploadMediaInput-view';

const UploadMediaInput = ({
  storyId,
  onUploadCompleted,
  coverPhotoKey,
  thumbnailKey,
  coverPhotoSelected,
  coverPhoto,
  complete,
  inputRef,
}) => {
  const [uploadImage] = useMutation(UPLOAD_MEMBER_IMAGE);

  const [createStoryAsset] = useCreateAsset();

  const updateStory = async () => {
    try {
      await uploadImage({
        variables: {
          input: {
            mId: storyId,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createAndUploadThumbnail = (file, assetKey, assetId) => {
    if (file) {
      const _URL = window.URL || window.webkitURL;
      const img = new window.Image();
      img.onload = obj => {
        const key = assetKey || thumbnailKey;

        Jimp.read(_URL.createObjectURL(file))
          .then(image => {
            const { width, height } = getScaledImageSize(obj.path[0], 256);
            image.resize(width, height).quality(100);

            image.getBufferAsync(Jimp.MIME_JPEG).then(async r => {
              const thumbnail = new window.File([r], `thumb_${assetId || storyId}.jpg`, {
                type: 'image/jpeg',
              });

              uploadToS3(key, thumbnail).catch(e => console.log(e));
            });
          })
          .catch(err => {
            console.log(`Failed to create thumbnail. Uploading source image. Error: ${err}`);
            uploadToS3(key, file).catch(e => console.log(e));
          });
      };

      img.src = _URL.createObjectURL(file);
    }
  };

  const createAsset = (file, url) => {
    const assetData = getFileAssetData(storyId, file);

    if (url) {
      assetData.renditions = [
        {
          mId: storyId,
          source: url,
          variant: 'main',
          fileName: file.name,
        },
      ];
    }

    createStoryAsset(storyId, assetData, true);
  };

  const uploadFile = async event => {
    const file = event.target.files[0];
    createAndUploadThumbnail(file);
    const key = coverPhotoKey;

    if (coverPhotoSelected) {
      const _URL = window.URL || window.webkitURL;
      coverPhotoSelected(_URL.createObjectURL(file));
    }

    complete(true);
    uploadToS3(key, file).then(() => {
      if (onUploadCompleted) {
        Storage.get(key, {
          customPrefix: {
            public: '',
          },
        })
          .then(url => {
            createAsset(file, url);
            onUploadCompleted(url, key, true);
            updateStory();
          })
          .catch(e => {
            console.log(e);
          });
      }
    });
  };

  const uploadMediaFile = async event => {
    const file = event.target.files[0];
    createAsset(file);
  };

  const handleInput = event => {
    if (coverPhoto) uploadFile(event);
    else uploadMediaFile(event);
  };

  const handleClick = event => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
  };

  return (
    <UploadMediaInputView
      coverPhoto={coverPhoto}
      onInput={handleInput}
      onClick={handleClick}
      inputRef={inputRef}
    />
  );
};

UploadMediaInput.propTypes = {
  storyId: PropTypes.string.isRequired,
  coverPhotoKey: PropTypes.string,
  thumbnailKey: PropTypes.string,
  onUploadCompleted: PropTypes.func,
  coverPhotoSelected: PropTypes.func,
  coverPhoto: PropTypes.bool,
  complete: PropTypes.func,
};

UploadMediaInput.defaultProps = {
  coverPhotoKey: '',
  thumbnailKey: '',
  coverPhoto: false,
  onUploadCompleted: () => {},
  complete: () => {},
  coverPhotoSelected: () => {},
};

export default UploadMediaInput;
