import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    background: 'transparent',
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    ...typography.dina.captionRegular,
  },
  input: {
    color: palette.dina.mediumEmphasis,
    '&:not(:focus)::selection': { background: 'transparent' },
    '&:not(:focus)': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&:focus': {
      fontStyle: 'italic',
      color: palette.dina.highEmphasis,
    },
  },
  textarea: {
    padding: '4px 0',
    resize: 'none',
    color: palette.dina.highEmphasis,
  },
}));

export default useStyles;
