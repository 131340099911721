import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ContainerBox from '../containerBox/ContainerBox';

const useStyles = makeStyles(theme => ({
  circle: {
    fill: theme.palette.dina.onSelected,
  },
  path: {
    fill: theme.palette.dina.highEmphasis,
  },
}));

const Checked = () => {
  const classes = useStyles();
  return (
    <ContainerBox>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="10" className={classes.circle} />
        <path
          // eslint-disable-next-line max-len
          d="M4.18875 7.93875L1.59187 5.34187C1.29882 5.04882 0.823681 5.04882 0.530625 5.34188C0.237569 5.63493 0.237569 6.11007 0.530625 6.40313L4.18875 10.0612L12.6581 1.59187C12.9512 1.29882 12.9512 0.823681 12.6581 0.530625C12.3651 0.237569 11.8899 0.237569 11.5969 0.530625L4.18875 7.93875Z"
          className={classes.path}
          transform="translate(4,5)"
        />
      </svg>
    </ContainerBox>
  );
};

export default Checked;
