/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import { useQuery } from 'react-apollo';
import configCtx from 'contexts/configContext';
import usePublishDateUpdate from 'hooks/usePublishDateUpdate';
import memberTypes from 'graphql/memberTypes';
import useBookmarkStory from 'hooks/useBookmarkStory';
import distanceInWord from 'utils/distanceInWords';
import Calendar from 'components/calendar';
import Popper from 'components/shared/popper';
import { ClickAwayListener } from '@material-ui/core';
import UserContext from 'contexts/UserContext';
import useGetPlatforms from 'hooks/useGetPlatforms';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import GET_STORY from 'graphql/queries/getStoryForStorybox';
import { getMemberQuery } from 'graphql/queryVariables';
import useTextStorage from 'hooks/useTextStorage';
import Metadata from './components/metadata';
import useStyles from './storybox-styles';
import Content from './components/content';
import Assets from './components/assets';
import Resources from './components/resources';
import StoryBox from './storybox-view';

const normalizeMetadata = array => array.map(({ key, value }) => ({ key, value }));

const StoryboxView = ({
  image,
  storyData,
  background,
  onInstanceClick,
  storyContainerRef,
  platforms,
  dialogHeight,
  ...rest
}) => {
  const classes = useStyles();
  const containerRef = useRef(null);

  const {
    mId,
    mTitle,
    mContentKey,
    mDescription,
    mMetaData,
    mUpdatedAt,
    mType,
    mCategories,
    mPublishingAt,
  } = storyData;

  const timeString = distanceInWord(mUpdatedAt);
  const initialMetadata = normalizeMetadata(mMetaData || []);
  const { data: content, loading: contentLoading } = useTextStorage(mContentKey);
  const { metadataForms } = useContext(configCtx);
  const user = useContext(UserContext);
  const [popAnchorEl, setPopAnchorEl] = useState(null);
  const [updatePublishDate] = usePublishDateUpdate();
  const { bookmarks, mId: userId } = user;
  const [bookmark] = useBookmarkStory();
  const [bookmarked, setBookmarked] = useState(!!bookmarks[mId]);
  const [metadata, setMetadata] = useState(initialMetadata);

  const onUpdatePublishDate = newTime => {
    const publishingDate = new Date(newTime);
    updatePublishDate(mId, publishingDate);
  };

  const onBookmarkClick = () => {
    // eslint-disable-next-line no-prototype-builtins
    if (bookmarks.hasOwnProperty(mId)) {
      setBookmarked(false);
      delete bookmarks[mId];
    } else {
      setBookmarked(true);
      bookmarks[mId] = {
        bookmarkedId: mId,
        bookmarkedType: memberTypes.STORY,
      };
    }
    bookmark(userId, bookmarks);
  };

  useEffect(() => {
    setMetadata(initialMetadata);
  }, [JSON.stringify(initialMetadata)]);

  const updateMetadataState = newMetadata => {
    setMetadata(
      metadata.map(meta => {
        const updatedMeta = newMetadata.find(nMeta => nMeta.key === meta.key);
        return updatedMeta || meta;
      }),
    );
  };

  const onScheduleClick = event => {
    setPopAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setPopAnchorEl(null);
  };

  const handleChange = newTime => {
    onUpdatePublishDate(newTime.toISOString());
    handleClose();
  };

  const handleUnschedule = () => {
    onUpdatePublishDate();
    handleClose();
  };

  const tabs = [
    {
      label: 'Content',
      content: (
        <Content
          storyId={mId}
          title={mTitle}
          content={content}
          description={mDescription}
          contentLoading={contentLoading}
          {...{ onInstanceClick, platforms }}
        />
      ),
    },
    {
      label: 'Assets',
      content: <Assets storyId={mId} storyContainerRef={storyContainerRef} />,
    },
    {
      label: 'Resources',
      content: <Resources storyId={mId} dialogHeight={dialogHeight} containerRef={containerRef} />,
    },
    {
      label: 'Metadata',
      content: (
        <Metadata
          storyId={mId}
          dialogHeight={dialogHeight}
          form={metadataForms[0]}
          analyticInput={content || mDescription}
          containerRef={containerRef}
          mMetadata={metadata}
          categories={mCategories}
          updateMetadataState={updateMetadataState}
        />
      ),
    },
  ];

  const isPitch = mType === memberTypes.PITCH;
  return (
    <div
      ref={containerRef}
      style={{ backgroundColor: background }}
      className={classes.storyboxContainer}
    >
      <StoryBox
        noBorderRadius
        isHovered
        image={image || fallbackImage}
        title={mTitle}
        timingInfo={timeString}
        isPitch={isPitch}
        headerTitle={mType}
        publishingAt={mPublishingAt}
        {...{ tabs, bookmarked, onBookmarkClick, onScheduleClick }}
        {...rest}
      />
      <Popper placement="right-end" anchorEl={popAnchorEl}>
        <ClickAwayListener onClickAway={handleClose}>
          <div className={classes.calendarContainer}>
            <Calendar
              selectedDate={mPublishingAt}
              changeSelectedDate={handleChange}
              onUnschedule={handleUnschedule}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

const StoryboxContainer = ({ storySpec, storyContainerRef, ...rest }) => {
  const { data, error, loading } = useQuery(GET_STORY, {
    variables: getMemberQuery(storySpec.id),
    fetchPolicy: 'cache-and-network',
  });

  const [platforms, platformsLoadingError, platformsLoading] = useGetPlatforms(null);

  if (error || platformsLoadingError) return `${error.message}`;
  if ((!data && loading) || (!platforms && platformsLoading)) return <LoadingIndicator />;

  return (
    <StoryboxView
      storyData={data && data.getMember}
      image={storySpec.image}
      storyContainerRef={storyContainerRef}
      platforms={platforms}
      {...rest}
    />
  );
};

StoryboxContainer.propTypes = {
  /** onInstanceClick Callback function */
  onInstanceClick: PropTypes.func,
  /** Dialog Height */
  dialogHeight: PropTypes.string,
  /** story container ref */
  storyContainerRef: PropTypes.objectOf(PropTypes.object),
};

StoryboxContainer.defaultProps = {
  onInstanceClick: () => {},
  dialogHeight: '87vh',
  storyContainerRef: null,
};

export default StoryboxContainer;
