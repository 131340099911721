import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, Collapse, Fade, Input } from '@material-ui/core';
import Metadata from 'components/metadata';
import getFormBySpec from 'utils/rundown/getFormBySpec';
import variants from 'utils/instance/variants';
import useDidMount from 'hooks/useDidMount';
import { useScrollIntoView } from 'contexts/ScrollIntoViewContext';
import { entityTypes, specTypes } from 'utils/metadata/defaultViewTypes';
import returnFieldPairs from 'utils/rundown/returnFieldPairs';
import Editor from 'components/editor';
import { publishingPoints } from 'assets/icons/publishingPoints';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Download from 'components/download/download';
import Header from './components/header';
import Footer from './components/footer';
import Iframe from './components/iframe';
import Tabs from './components/tabs';
import scrollAfterTimeout from './utils/scrollAfterTimeout';
import useStyles from './instance-card-styles';

const editorHeightOffset = 88 + 40;
const descriptionHeight = 77;
const lockedIndicatorHeight = 40;

const InstanceCardView = ({
  assignees,
  backgroundImage,
  canShowNewDesign,
  canCreateNewTemplate,
  canDeleteTemplate,
  canDeleteTemplateFolder,
  canSeeVersionHistory,
  clipDuration,
  currentDestination,
  defaultPlaceholderTitle,
  description,
  disableEdit,
  editorValue,
  fixedHeight,
  folders,
  form,
  handleUpdateMetadata,
  hostReadSpeed,
  hasChanges,
  initiallyCollapsed,
  instance,
  isSavingContent,
  isStoryEditor,
  loading,
  lockedByUser,
  lockedByCurrentUser,
  mContentKey,
  mId,
  mMetaData,
  onAssetInsert,
  onAssetRemove,
  onAssigneeUpdate,
  onClickAway,
  onClipDurationChange,
  onCreateDuplicate,
  onCreateFolder,
  onDeleteFolder,
  onDeleteInstance,
  onDeleteTemplate,
  onDescriptionChange,
  onDone,
  onEditorKeyDown,
  onCmsEditingActivated,
  onEditorUpdate,
  onEditorValueChange,
  onFocus,
  onForceUnlock,
  onLockInstance,
  onOpenStory,
  onPublishSettingsChange,
  onSaveTemplate,
  onSelectTemplate,
  onStatusChange,
  onTitleUpdate,
  onUnlockInstance,
  publishingPoint,
  publishingPointIcon,
  readLock,
  schedule,
  scriptDuration,
  setShowMetadata,
  setSkipDownload,
  shouldResetEditorSelection,
  showMetadata,
  statusId,
  storyId,
  summarize,
  templates,
  title,
  totalDuration,
  users,
  variant,
  writeLock,
  hideToolbar,
  disableCollapse,
  twitterThreadCount,
  provider,
  versions,
  currentVersionContent,
  refetchVersionList,
  refetchVersionContent,
  onRestoreVersion,
  checkVersionRestorability,
  auditListLoading,
  versionContentLoading,
  isMaximized,
}) => {
  const containerRef = useRef();
  const [collapsed, setCollapsed] = useState(initiallyCollapsed);
  const [tabIndex, setTabIndex] = useState(0);
  const [currentClicked, setCurrentClicked] = useScrollIntoView();
  const [calculatedHeight, setCalculatedHeight] = useState(0);
  const classes = useStyles(writeLock, readLock)();
  const didMount = useDidMount();
  const [isShowingDownload, setIsShowingDownload] = useState(false);
  const [download, setDownload] = useState({});
  const [descriptionValue, setDescriptionValue] = useState(description);
  const isCMSInstance = publishingPoint === 'cms';

  const [fields, parameterFields] = useMemo(
    () =>
      getFormBySpec(form, {
        target: publishingPoint,
        entity: entityTypes.INSTANCE,
        type: specTypes.METADATA,
      }),
    [form, publishingPoint],
  );

  const metadata = useMemo(() => returnFieldPairs(mMetaData, fields), [fields, mMetaData]);

  const setupContainerHeight = () => {
    let resizeObserver;
    const containerElement = containerRef.current;

    if (!fixedHeight && containerElement) {
      if (window.ResizeObserver) {
        resizeObserver = new ResizeObserver(() => {
          const { height } = containerElement.getBoundingClientRect();
          setCalculatedHeight(height);
        });

        resizeObserver.observe(containerElement);
      } else {
        const { height } = containerElement.getBoundingClientRect();
        setCalculatedHeight(height);
      }
    }

    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  };

  useEffect(setupContainerHeight, []);

  useEffect(() => {
    if (didMount) {
      const el = document.getElementById(`${storyId}-${mId}`);
      if (el) {
        if (!collapsed) scrollAfterTimeout(el, 800);

        if (setSkipDownload) setSkipDownload(collapsed);

        if (currentClicked) setCurrentClicked(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  useEffect(() => {
    if (didMount && currentClicked === mId) {
      const target = document.getElementById(`${storyId}-${mId}`);
      if (target) scrollAfterTimeout(target, 500);

      setCollapsed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didMount, currentClicked]);

  useEffect(() => {
    setDescriptionValue(description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  const editorRef = useRef();
  const timeOutRef = useRef();

  // useInterval(
  //   () => {
  //     if (timeOutRef.current) {
  //       timeOutRef.current = false;
  //     } else {
  //       writeLock && onUnlockInstance();
  //     }
  //   },
  //   writeLock ? 10000 : null,
  // );

  useEffect(() => {
    const resetTimeOut = () => {
      timeOutRef.current = true;
    };
    if (editorRef.current) {
      editorRef.current.addEventListener('keydown', resetTimeOut, false);
      editorRef.current.addEventListener('mousedown', resetTimeOut, false);
    }
    return () => {
      if (editorRef.current) {
        editorRef.current.removeEventListener('keydown', resetTimeOut, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        editorRef.current.removeEventListener('mousedown', resetTimeOut, false);
      }
    };
  }, [writeLock]);

  useEffect(() => {
    if (writeLock) {
      window.onbeforeunload = async e => {
        e.preventDefault();
        await onUnlockInstance();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeLock]);

  const onMetadataSelect = useCallback(() => setShowMetadata(!showMetadata), [
    setShowMetadata,
    showMetadata,
  ]);

  const updateInstanceMetadata = useCallback(
    newMetadata => handleUpdateMetadata(newMetadata, instance),
    [handleUpdateMetadata, instance],
  );

  const toggleCollapse = useCallback(() => {
    if (!disableCollapse) setCollapsed(previousState => !previousState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = useCallback(
    contentType => {
      if (!instance || !instance.mId) return;
      const reqData = { mId: instance.mId, mRefId: instance.mId, contentType, rundownSection: '-' };
      setDownload(reqData);
      setIsShowingDownload(true);
    },
    [instance],
  );

  const calculateHeight = useCallback(() => {
    let height =
      fixedHeight > 0 ? fixedHeight - editorHeightOffset : calculatedHeight - editorHeightOffset;

    if (readLock || writeLock) height -= lockedIndicatorHeight;

    return [isCMSInstance ? height - descriptionHeight : height, height];
  }, [calculatedHeight, fixedHeight, isCMSInstance, readLock, writeLock]);

  const [centerContentHeight, metadataContentHeight] = useMemo(calculateHeight, [
    calculatedHeight,
    fixedHeight,
    readLock,
    writeLock,
  ]);

  const handleOnEditorClick = useCallback(() => {
    !readLock && !writeLock && !disableEdit && onLockInstance();
  }, [onLockInstance, readLock, writeLock, disableEdit]);

  const handleDone = useCallback(
    e => {
      onDone(e);
      isCMSInstance && onDescriptionChange(descriptionValue);
    },
    [descriptionValue, isCMSInstance, onDescriptionChange, onDone],
  );

  const handleSelectTemplate = useCallback(
    templateObj => {
      writeLock && onSelectTemplate(templateObj);
    },
    [onSelectTemplate, writeLock],
  );

  const handleDescriptionChange = useCallback(e => setDescriptionValue(e.target.value), []);

  const handleBackClick = useCallback(() => setShowMetadata(!showMetadata), [
    setShowMetadata,
    showMetadata,
  ]);

  const handleTabChange = useCallback((val, index) => {
    if (isCMSInstance && index === 1) {
      onCmsEditingActivated();
    }

    setTabIndex(index);
  }, []);

  const renderedEditor = (
    <Editor
      height={centerContentHeight}
      readOnly={!writeLock}
      shouldResetSelection={shouldResetEditorSelection}
      update={onEditorUpdate}
      thumbnail={backgroundImage}
      value={editorValue}
      isAllowed={canShowNewDesign}
      renderToolbar={hideToolbar ? () => null : undefined}
      onTabChange={handleTabChange}
      {...{ users, variant, defaultPlaceholderTitle, hostReadSpeed }}
    />
  );

  const tabs = [
    {
      label: 'DiNA Content Blocks',
      content: renderedEditor,
    },
    {
      label: 'CMS Editing',
      content: (
        <Iframe provider={provider} instanceId={instance.mId} height={centerContentHeight} />
      ),
    },
  ];

  return (
    <div className={classes.root} ref={containerRef}>
      {(fixedHeight || !!calculatedHeight) && (
        <Paper elevation={24} classes={{ root: classes.paper }} id={`${storyId}-${mId}`}>
          {loading ? <LoadingIndicator /> : null}
          <Header
            instanceId={instance.mId}
            onBackgroundClick={toggleCollapse}
            onDownload={handleDownload}
            onSelectTemplate={handleSelectTemplate}
            {...{
              backgroundImage,
              canCreateNewTemplate,
              canDeleteTemplate,
              canDeleteTemplateFolder,
              canSeeVersionHistory,
              containerRef,
              currentDestination,
              folders,
              onCreateDuplicate,
              onCreateFolder,
              onDeleteFolder,
              onDeleteInstance,
              onDeleteTemplate,
              onForceUnlock,
              onMetadataSelect,
              onOpenStory,
              onPublishSettingsChange,
              onSaveTemplate,
              onTitleUpdate,
              publishingPoint,
              publishingPointIcon,
              schedule,
              showMetadata,
              statusId,
              summarize,
              templates,
              title,
              variant,
              writeLock,
              lockedByUser,
              disableEdit,
              canShowNewDesign,
              versions,
              currentVersionContent,
              refetchVersionList,
              refetchVersionContent,
              onRestoreVersion,
              checkVersionRestorability,
              isSavingContent,
              auditListLoading,
              versionContentLoading,
              isMaximized,
            }}
          />
          <div ref={editorRef}>
            <Collapse
              in={!collapsed}
              classes={{ container: classes.collapseContainer }}
              mountOnEnter
            >
              {showMetadata ? (
                <div style={{ height: metadataContentHeight }}>
                  <Fade in={showMetadata} {...(showMetadata ? { timeout: 500 } : {})}>
                    <Metadata
                      fields={fields}
                      metadata={metadata}
                      parameterFields={parameterFields}
                      onUpdateMeta={updateInstanceMetadata}
                      onBackClick={handleBackClick}
                      disableEdit={disableEdit}
                    />
                  </Fade>
                </div>
              ) : (
                <div
                  onClick={handleOnEditorClick}
                  role="presentation"
                  className={classes.editorDiv}
                >
                  {isCMSInstance && !canShowNewDesign && (
                    <>
                      <Input
                        className={classes.description}
                        placeholder="Write a short description..."
                        fullWidth
                        multiline
                        disableUnderline
                        onChange={handleDescriptionChange}
                        value={descriptionValue}
                        rows={3}
                        readOnly={!writeLock || readLock}
                      />
                      {renderedEditor}
                    </>
                  )}
                  {isCMSInstance && canShowNewDesign && (
                    <>
                      <div
                        className={classes.tabWrapper}
                        style={{
                          pointerEvents: !writeLock || readLock ? 'none' : 'auto',
                        }}
                      >
                        <Tabs tabs={tabs} tabIndex={tabIndex} onTabChange={handleTabChange} />
                      </div>
                      {tabs[tabIndex].content}
                    </>
                  )}

                  {!isCMSInstance && renderedEditor}
                </div>
              )}
            </Collapse>
          </div>
          <Footer
            {...{
              assignees,
              onAssigneeUpdate,
              collapsed,
              variant,
              statusId,
              onStatusChange,
              onClipDurationChange,
              clipDuration,
              scriptDuration,
              totalDuration,
              isSavingContent,
              onUnlockInstance,
              readLock,
              writeLock,
              lockedByUser,
              publishingPoint,
              disableEdit,
              hasChanges,
              twitterThreadCount,
              canShowNewDesign,
            }}
            onDone={handleDone}
            onBackgroundClick={toggleCollapse}
          />
        </Paper>
      )}
      {isShowingDownload ? (
        <Download download={download} setIsShowingDownload={setIsShowingDownload} />
      ) : null}
    </div>
  );
};

InstanceCardView.propTypes = {
  /** mId of the instance */
  mId: PropTypes.string,
  /** Variant of the instance card */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Current status id of the instance */
  statusId: PropTypes.string,
  /** Callback to be invoked on status change */
  onStatusChange: PropTypes.func,
  /** Publishing platform of the instance */
  publishingPoint: PropTypes.oneOf(Object.keys(publishingPoints)),
  /** Currently selected destination of this instance */
  currentDestination: PropTypes.shape({
    title: PropTypes.string,
    publishingTime: PropTypes.string,
  }),
  /** Callback to be invoked on publish settings change */
  onPublishSettingsChange: PropTypes.func,
  /** Title of the instance */
  title: PropTypes.string,
  /** Callback to be invoked on title update */
  onTitleUpdate: PropTypes.func,
  /** Publishing time for general instance variant */
  schedule: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** List of users assigned to current instance */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Callback to be invoked on assignee update */
  onAssigneeUpdate: PropTypes.func,
  /** Instance templates to show on ellipsis menu for linear variant */
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),
  /** Callback to be invoked on template selection,
   * with the selected template passed in
   */
  onSelectTemplate: PropTypes.func,
  /** Callback to be invoked when save as template option is clicked,
   * with the templateName passed in
   */
  onSaveTemplate: PropTypes.func,
  /** Callback to be invoked on template deletion,
   * with the template to be deleted passed in
   */
  onDeleteTemplate: PropTypes.func,
  /** Clip duration for the instance in milliseconds */
  clipDuration: PropTypes.string,
  /** Callback to be invoked on clip duration change */
  onClipDurationChange: PropTypes.func,
  /** Script duration for the instance in milliseconds */
  scriptDuration: PropTypes.string,
  /** Total duration for the instance in milliseconds */
  totalDuration: PropTypes.string,
  /** Initial of the editor */
  editorValue: PropTypes.shape({}),
  /** List of users that can be mentioned on the editor */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      mTitle: PropTypes.string,
    }),
  ),
  /** Callback to be invoked when text editor's value changes,
   * with the updated value passed in as JSON. It is invoked
   * after certain duration of actual value changes in the
   * editor.
   */
  onEditorValueChange: PropTypes.func,
  /** Callback to be invoked when text editor's value changes,
   * with the updated value passed in as JSON
   */
  onEditorValueChangeSync: PropTypes.func,
  /** Callback to be invoked when keypress occurs on the editor */
  onEditorKeyDown: PropTypes.func,
  /** Callback to invoked when user clicks away from focused editor */
  onClickAway: PropTypes.func,
  /** Callback to invoked when mos automation item is inserted */
  onEditorAutomationInsert: PropTypes.func,
  /** Callback to invoked when mos automation item is deleted */
  onEditorAutomationRemove: PropTypes.func,
  /** Defines if the card should start collpased */
  initiallyCollapsed: PropTypes.bool,
  /** Defines if the card should have a predetermined height */
  fixedHeight: PropTypes.number,
  /** Call to be invoked when an asset in inserted in general variants */
  onAssetInsert: PropTypes.func,
  /** Call to be invoked when an asset in removed in general variants */
  onAssetRemove: PropTypes.func,
  /** List of template folders */
  folders: PropTypes.arrayOf(PropTypes.any),
  /** Callback to be invoked while creating new folder */
  onCreateFolder: PropTypes.func,
  /** Callback to be invoked when opening a story */
  onOpenStory: PropTypes.func,
  /** Callback to be invoked while deleting a folder */
  onDeleteFolder: PropTypes.func,
  /** Callback to be invoked while cms editing is activated */
  onCmsEditingActivated: PropTypes.func,
  /** boolean to indicate tat user cab only read the value */
  readLock: PropTypes.bool,
  /** Callback to be invoked to lock the instance */
  onLockInstance: PropTypes.func,
  /** Callback to be invoked to unlock the instance */
  onUnlockInstance: PropTypes.func,
  /** Indicated that current instance is locked by others */
  accessLostBy: PropTypes.string,
  /** Boolean that indicates that editor value is saving */
  isSavingContent: PropTypes.bool,
  /** Name of the instance's locking user */
  lockedByUser: PropTypes.string,
  /** Boolean to indicates that user can edit the value */
  writeLock: PropTypes.bool,
  /** callback function to be invoked wen done button is clicked */
  onDone: PropTypes.func,
  /** description of the cms instance */
  description: PropTypes.string,
  /** Callback to invoke when description changes */
  onDescriptionChange: PropTypes.func,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  /** boolean that hides delete template from menu */
  canDeleteTemplate: PropTypes.bool,
  /** boolean that hides delete template folder from menu */
  canDeleteTemplateFolder: PropTypes.bool,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
  /** Hides the toolbar of editor */
  hideToolbar: PropTypes.bool,
  /** function to open metadata view */
  setShowMetadata: PropTypes.func,
};

InstanceCardView.defaultProps = {
  accessLostBy: null,
  assignees: [],
  clipDuration: undefined,
  currentDestination: {},
  description: '',
  editorValue: undefined,
  fixedHeight: null,
  folders: [],
  initiallyCollapsed: false,
  isSavingContent: false,
  lockedByUser: 'Someone',
  mId: undefined,
  onAssetInsert: (file, fileName) => {},
  onAssetRemove: storageKey => {},
  onAssigneeUpdate: updatedAssignees => {},
  onClickAway: value => {},
  onClipDurationChange: newDuration => {},
  onCmsEditingActivated: () => {},
  onCreateFolder: () => {},
  onDeleteFolder: () => {},
  onDeleteTemplate: template => {},
  onDescriptionChange: () => {},
  onDone: () => {},
  onEditorAutomationInsert: value => {},
  onEditorAutomationRemove: value => {},
  onEditorKeyDown: (event, value) => {},
  onEditorValueChange: value => {},
  onEditorValueChangeSync: content => {},
  onLockInstance: () => {},
  onOpenStory: () => {},
  onPublishSettingsChange: newPublishingSettings => {},
  onSaveTemplate: templateName => {},
  onSelectTemplate: template => {},
  onStatusChange: newStatusId => {},
  onTitleUpdate: newTitle => {},
  onUnlockInstance: () => {},
  publishingPoint: 'linear',
  readLock: false,
  schedule: null,
  scriptDuration: undefined,
  statusId: 'todo',
  templates: [],
  title: '',
  totalDuration: undefined,
  users: [],
  variant: variants.LINEAR,
  writeLock: false,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canDeleteTemplateFolder: false,
  disableEdit: false,
  hideToolbar: false,
  setShowMetadata: () => {},
};

export default InstanceCardView;
