import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants/types';

const HorizontalRule = ({ attributes, children, element }) => {
  return (
    <div {...attributes}>
      {children}

      <div contentEditable={false}>
        <hr />
      </div>
    </div>
  );
};

HorizontalRule.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

HorizontalRule.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.HORIZONTAL_RULE,
    children: [],
  },
};

export default memo(HorizontalRule);
