const saveIptcMediaOnContext = (medtopResult, config) => {
  config.iptcMediaTopics = [
    ...medtopResult.data.getMemberFromId.map(({ mRefId, mTitle }) => ({
      categoryId: mRefId,
      label: mTitle,
    })),
  ];
};

export default saveIptcMediaOnContext;
