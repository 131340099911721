import GET_MEMBERS_BY_PUBLISHING_DATE from 'graphql/queries/getStoryByPublishingDate';

const getUpdatedList = (list, story) => {
  const { crudAction } = story;
  const updatedList = list;
  if (crudAction === "INSERT") {
      const memberExist = updatedList.filter(m => m.mId === story.mId);
      if (memberExist.length === 0) {
          updatedList.push(story);
      }
      return updatedList;
  }
  if (crudAction === "REMOVE") {
      return updatedList.filter(m => m.mId !== story.mId);
  }
  return list;
}

const updateCache = (proxy, story, startDate, endDate) => {
  const { mType } = story;
  try {
    const list = proxy.readQuery({
      query: GET_MEMBERS_BY_PUBLISHING_DATE,
      variables: {
        input: {
          mType,
          startDate,
          endDate,
        },
      },
    });
    const updatedList = getUpdatedList(list.getMembersByPublishingDate, story);

    proxy.writeQuery({
      query: GET_MEMBERS_BY_PUBLISHING_DATE,
      variables: {
        input: {
          mType,
          startDate,
          endDate,
        },
      },
      data: { getMembersByPublishingDate: updatedList },
    });
  } catch (e) {
    console.log(e);
  }
}

export default updateCache;