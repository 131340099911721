import { makeStyles } from '@material-ui/styles';

const menuWidth = 232;

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    minWidth: 96,
    height: '100%',
    minHeight: 24,
    position: 'relative',
  },
  icon: {
    width: 24 + 8,
    marginRight: 10,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 100,
  },
  selectWrapper: { width: '100%' },
  select: {
    height: '100%',
    padding: 0,
    display: 'none',
    alignItems: 'center',
  },
  listItemIcon: { width: 24 + 16 },
  selectInput: {
    textTransform: 'uppercase',
    color: palette.dina.mediumEmphasis,
    ...typography.dina.listItemLabelMedium,

    '& $listItemIcon': { display: 'none' },
  },
  menu: {
    width: menuWidth,
    padding: 24,
    paddingLeft: ({ anchorOrigin }) => (anchorOrigin.horizontal === 'left' ? 12 : 24),
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  menuList: {
    width: menuWidth - 12 - 24,
    backgroundColor: palette.dina.surfaceDialogs,
    borderRadius: 4,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2),' +
      '0px 8px 10px 1px rgba(0,0,0,0.14),' +
      '0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  menuItem: {
    color: palette.dina.highEmphasis,
    ...typography.dina.listItemLabel,
    '&:hover, &:focus': {
      backgroundColor: palette.dina.whiteHoverOverlay,
    },
    '&$menuItemSelected, &$menuItemSelected:hover, &$menuItemSelected:focus': {
      backgroundColor: palette.dina.onSelected,
      '& $listItemIcon svg': {
        opacity: 1,
        transition: 'opacity 1s',
        '& path': { fillOpacity: 1 },
      },
    },
  },
  menuItemSelected: {},
  input: {
    padding: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
    width: 'calc(100% - 2px)',
    height: '100%',
    paddingRight: ({ arrowPosition }) => (arrowPosition === 'end' ? 24 + 8 + 10 : 0),
    paddingLeft: ({ arrowPosition }) => (arrowPosition === 'start' ? 24 + 8 + 10 : 8),
    '&:focus': {
      backgroundColor: palette.dina.statusOnFocusedDiscreetFill,
      border: `1px solid ${palette.dina.onSelected}`,
    },
  },
  autocompleteWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'calc(100% - 2px)',
    height: '100%',
  },
  autocomplete: {
    paddingRight: ({ arrowPosition }) => (arrowPosition === 'end' ? 24 + 8 + 10 : 0),
    paddingLeft: ({ arrowPosition }) => (arrowPosition === 'start' ? 24 + 8 + 10 : 8),
  },
}));

export default useStyles;
