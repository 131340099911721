import gql from 'graphql-tag';

export default gql`
  fragment currentInstance on MemberType {
    mId
    mMetaData {
      key
      value
    }
  }
`;
