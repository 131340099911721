import React, { useState } from 'react';
import InitialLoadingScreen from 'screens/loading';
import initialQueries from 'graphql/global-functions/initialQueries';
import MainContainer from './main-container';

const MainInitialContainer = ({ user }) => {
  const [initialLoading, setInitialLoading] = useState(true);

  initialQueries(user.mId, user.groups, setInitialLoading);

  return initialLoading ? <InitialLoadingScreen /> : <MainContainer user={user} />;
};

export default MainInitialContainer;
