import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  inputMenuItem: {
    width: ({ width }) => width - 16,
    height: 27,
    color: 'white',
    fontSize: 14,
    letterSpacing: '0.25px',
    lineHeight: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  title: {
    ...theme.typography.dina.listItemLabelMedium,
    color: theme.palette.dina.highEmphasis,
    paddingLeft: '2px',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  optionTitle: {
    ...theme.typography.dina.listItemLabelMedium,
    color: theme.palette.dina.highEmphasis,
    lineHeight: '20px',
    paddingLeft: '10px',
    paddingRight: '15px',
  },
  icon: {
    paddingLeft: '10px',
  },

  gridSelectIcon: {
    height: 31,
    top: '-2px',
  },

  selectContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  modifiedOutlinedInput: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.mediumEmphasis,
    borderRadius: '0px',
    width: ({ width }) => width,
    height: 27,
    '& $notchedOutline': {
      borderColor: 'transparent',
    },
    '&$focused $notchedOutline': {
      borderColor: 'transparent',
      // borderColor: theme.palette.dina.whiteMediumEmphasis,
      // borderWidth: 1,
      // backgroundColor: theme.palette.dina.blackHoverOverlay,
      backgroundColor: 'transparent',
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: theme.palette.dina.inputBorderResting,
      borderWidth: 1,
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },

  menu: {
    background: theme.palette.dina.surfaceAppBackgroundMain,
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  menuItem: {
    color: 'white',
    fontSize: 14,
    letterSpacing: '0.25px',
    lineHeight: '0',
    '&$menuItemSelected, &$menuItemSelected:hover, &$menuItemSelected:focus': {
      backgroundColor: theme.palette.dina.onSelected,
    },
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },

  listItemIcon: {
    height: 24,
    width: 24,
    minWidth: 24,
  },

  input: {
    height: '100%',
    padding: '0px 24px 0px 0px',
  },
  notchedOutline: {},
  focused: {},
  disabled: {},
  error: {},
  menuItemSelected: {},
}));

export default useStyles;
