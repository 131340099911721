import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  listContainer: {
    width: '240px',
    position: 'relative',
    backgroundColor: theme.palette.dina.surfaceDialogs,
    margin: '-8px 0',
    borderRadius: '8px',
  },
  avatar: {
    marginRight: '22px',
  },
  triangle: {
    position: 'absolute',
    width: 0,
    height: 0,
    right: 0,
    transform: 'translateY(-50%)',
    borderBottom: `16px solid ${theme.palette.dina.surfaceDialogs}`,
    borderLeft: '16px solid transparent',
  },
  listItemsContainer: {
    width: '240px',
  },
  typeText: {
    ...theme.typography.dina.caption,
  },
}));

export default useStyles;
