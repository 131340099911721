import { useQuery } from 'react-apollo';
import GET_MEMBERS_BY_PUBLISHING_DATE from 'graphql/queries/getStoryByPublishingDate';

const useGetMembersByPublishingDate = (
  startDate,
  endDate,
  mType,
  skipQuery = false
) => {
  const { data, error, loading } = useQuery(GET_MEMBERS_BY_PUBLISHING_DATE, {
    variables: {
      input: {
        mType,
        startDate,
        endDate,
      },
    },
    fetchPolicy: 'cache-and-network',
    // skip: skipQuery,
  });

  if (skipQuery) return [{ getMembersByPublishingDate: [] }];
  return [data, error, loading];
};

export default useGetMembersByPublishingDate;
