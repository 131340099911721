import uuidv1 from 'uuid/v1';
import { findKey } from 'lodash';
import { Transforms, Editor } from 'slate';
import { elementTypes, inTimingTypes, outTimingTypes } from 'components/editor/constants/types';

const { secondaryTypes, properties } = elementTypes;

const defaultOptions = {
  templateVariant: 'VARIANT',
  automationType: 'SECONDARY',
  inTiming: inTimingTypes.MANUAL_IN,
  outTiming: outTimingTypes.AUTO_OUT,
  startTime: 0,
  duration: 5000,
};

const { insertNodes, setNodes } = Transforms;

/**
 * Inserts secondary automation element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} type Type of the element
 * @param {Object} data Data properties for the element
 * @param {Boolean} isNewline Should the element be inserted on a newline
 * @returns {Object} SlateJS editor instance
 */

const insertSecondaryAutomationElement = (editor, type, data = {}, isNewline = true) => {
  const element = {
    type,
    data: {
      ...defaultOptions,
      ...data,
      itemId: uuidv1(),
      typeId: properties[type].id,
      templateType: findKey(secondaryTypes, value => value === type),
    },
    children: [{ text: '' }],
  };

  if (isNewline) {
    insertNodes(editor, element);
  } else {
    const [{ text }] = Editor.node(editor, editor.selection);
    if (text !== '') {
      // Transforms.delete(editor, { unit: 'block' });
      insertNodes(editor, element);
    }
    setNodes(editor, element);
  }
  return editor;
};

export default insertSecondaryAutomationElement;
