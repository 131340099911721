/**
 *  Hook for fetching list of platforms
 */

import { useQuery } from 'react-apollo';
import { startOfDay, endOfDay } from 'date-fns';
import GET_PLATFORMS from 'graphql/queries/getPlatforms';
import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'graphql/queries/getRundownByPublishingDate';

const useGetPlatforms = (date, instanceType, skip) => {
  const { data, error, loading } = useQuery(GET_PLATFORMS, {
    skip,
  });
  // console.log(`date: ${date} instanceType: ${instanceType} Skip: ${skip}`);
  // const sDate = format(date, 'YYYY-MM-DD');
  const startDate = startOfDay(date).toISOString();
  const endDate = endOfDay(date).toISOString();

  const inp = {
    input: {
      mType: 'rundown',
      startDate,
      endDate,
    },
  };

  const {
    data: rundownsByDateData,
    error: errorrundownsByDate,
    loading: loadingrundownsByDate,
  } = useQuery(GET_RUNDOWNS_BY_PUBLISHING_DATE, {
    variables: inp,
    skip: !data || !date || skip,
  });

  let ret;
  let destinations = [];
  if (data) {
    ret = [...data.getPlatforms];
  }

  if (!date && data) {
    const emptyAccount = {
      accountId: null,
      accountTitle: 'Unassigned',
      accountUrl: 'Unassigned',
      accountLogo: 'url@tolog',
      isUnassigned: true,
      recurrence: {},
    };

    const linear = {
      mTitle: 'current rundowns',
      mProperties: {
        platform: 'linear',
        platformIcon: 'linear',
        accounts: [emptyAccount],
      },
    };
    ret.push(linear);
  }

  if (rundownsByDateData && rundownsByDateData.getRundownsByPublishingDate) {
    let existAccounts = [];
    const { getRundownsByPublishingDate } = rundownsByDateData;
    existAccounts = getRundownsByPublishingDate.map(rundown => {
      return {
        accountId: rundown.mId,
        accountTitle: rundown.mTitle,
        accountUrl: rundown.mTitle,
        accountLogo: 'url@tolog',
        recurrence: rundown.recurrence,
      };
    });

    const emptyAccount = {
      accountId: null,
      accountTitle: 'Unassigned',
      accountUrl: 'Unassigned',
      accountLogo: 'url@tolog',
      isUnassigned: true,
      recurrence: {},
    };

    const linearExists = {
      mTitle: 'current rundowns',
      mProperties: {
        platform: 'linear',
        platformIcon: 'linear',
        accounts: [emptyAccount, ...existAccounts],
      },
    };

    ret.push(linearExists);

    if (instanceType) {
      const platformsByType = ret.find(p => p.mProperties.platform === instanceType);
      destinations = platformsByType.mProperties.accounts.map(
        ({ accountId, accountUrl, accountTitle, recurrence }) => {
          const rundown = getRundownsByPublishingDate.find(r => r.mId === accountId);
          const mPublishingAt = rundown && rundown.mPublishingAt;
          return {
            id: accountId,
            value: instanceType === 'linear' && accountId ? accountId : accountTitle,
            title: accountTitle,
            publishingTime: instanceType === 'linear' && mPublishingAt ? mPublishingAt : null,
            startTime: recurrence ? recurrence.startTime : null,
            timeZone: recurrence ? recurrence.timeZone : null,
          };
        },
      );
    }
  }

  return [ret, error || errorrundownsByDate, loading || loadingrundownsByDate, destinations];
};

export default useGetPlatforms;
