const schema = {
  id: 'main-settings',
  name: 'Settings',
  categories: [
    {
      title: 'MAM',
      description: 'Media Asset Management',
      properties: {
        'mam.placeholderName': {
          type: 'string',
          default: null,
          description:
            'Specifies the naming convention to be used as default for creating asset placeholders',
        },
      },
    },
  ],
};

export default schema;
