/**
 * Given a duration in "mm:ss" format, the function returns total duration in seconds
 *
 * @param {string} time string representation in "mm:ss" format
 * @returns {number} duration in seconds
 */

const timeToSeconds = time => {
  if (time === null) return 0;
  const [b1, b2] = time.split(':');
  return Number(b1) * 60 + Number(b2);
};

/**
 * Given a duration in seconds, the function returns string representation in "mm:ss" format
 *
 * @param {number} seconds represented as integer number
 * @returns {string} representation of the seconds in "mm:ss" format
 */

const timeFromSeconds = seconds => {
  const z = n => {
    return (n < 10 ? '0' : '') + n;
  };
  // eslint-disable-next-line no-bitwise
  const m = (seconds / 60) | 0;
  const s = seconds % 60;
  return `${z(m)}:${z(s)}`;
};

/**
 * Given two duration in "mm:ss" format, the function returns sum of two durations in "mm:ss"
 * format
 *
 * @param {string} duration1 represented in "mm:ss" format
 * @param {string} duration2 represented in "mm:ss" format
 * @returns {string} representation of the sum of duration1 and duration2 in "mm:ss" format
 */
const addDuration = (duration1, duration2) => {
  const var1 = timeToSeconds(duration1 || '00:00');
  const var2 = timeToSeconds(duration2 || '00:00');
  return timeFromSeconds(var1 + var2);
};

/**
 * Given two duration in "mm:ss" format, the function returns subtract of two durations in "mm:ss"
 * format
 *
 * @param {string} duration1 represented in "mm:ss" format
 * @param {string} duration2 represented in "mm:ss" format
 * @returns {string} representation of the subtract of duration1 and duration2 in "mm:ss" format
 * with corresponding sign
 */
const subtractDuration = (duration1, duration2) => {
  const var1 = timeToSeconds(duration1 || '00:00');
  const var2 = timeToSeconds(duration2 || '00:00');
  return `${var1 > var2 ? '-' : ''}${timeFromSeconds(Math.abs(var2 - var1))}`;
};

export { addDuration, subtractDuration, timeToSeconds, timeFromSeconds };
