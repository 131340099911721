import React, { useCallback, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { findKey } from 'lodash';
import configCtx from 'contexts/configContext';
import DropZone from 'components/editor/components/dropZone';
import getTypeValues from 'components/editor/utils/getTypeValues';
import getDefaultTemplate from 'components/editor/utils/getDefaultTemplate';
import { elementTypes, actionTypes } from 'components/editor/constants/types';
import useEditorContext from 'components/editor/hooks/useEditorContext';

const { primaryTypes } = elementTypes;
const primaryTypeValues = getTypeValues(primaryTypes);

const PrimaryDropZone = ({ children, element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const { automationTemplateConfigs } = useContext(configCtx);

  const { templates } = automationTemplateConfigs[0]
    ? automationTemplateConfigs[0].templateSets[0]
    : {};

  const onDrop = useCallback(
    ({ type }) => {
      const { variant: templateVariant, name, description } = getDefaultTemplate(type, templates);
      const { data } = element;

      const updatedData = {
        ...data,
        templateVariant,
        name,
        description,
        templateType: findKey(primaryTypes, value => value === type),
      };

      Transforms.setNodes(editor, { type, data: updatedData });
      ReactEditor.focus(editor);

      update({
        type: actionTypes.AUTOMATION_UPDATE,
        payload: { document: editor.children, updatedData },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  return (
    <DropZone accept={primaryTypeValues} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

PrimaryDropZone.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

PrimaryDropZone.defaultProps = {
  children: null,
  element: {
    children: [],
  },
};

export default memo(PrimaryDropZone);
