import React from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItem } from '@material-ui/core';
import useStyles from './listItem-styles';

const ListItemComponent = props => {
  const { iconImage, text, onClick } = props;
  const classes = useStyles();
  return (
    <ListItem
      classes={{ root: classes.root }}
      button
      disableGutters
      onClick={onClick}
    >
      <img src={iconImage} alt="" className={classes.icon} />
      <Typography classes={{ root: classes.text }}>{text}</Typography>
    </ListItem>
  );
};

ListItemComponent.propTypes = {
  iconImage: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

ListItemComponent.defaultProps = {
  iconImage: null,
  text: null,
  onClick: () => {},
};

export default ListItemComponent;
