import { makeStyles } from '@material-ui/styles';

const restrictPointerEvents = {
  userSelect: 'none',
  pointerEvents: 'none',
};

const useStyles = makeStyles(theme => ({
  childrenWrapper: {
    height: 270,
    overflowY: 'auto',
    width: '100%',
    background: theme.palette.dina.inputBackground,
    borderRadius: '1px 1px 0px 0px',
    borderBottom: `1px solid ${theme.palette.dina.buttonBackgroundOutlined}`,
  },
  children: {
    width: '100%',
    padding: '5px',
    '&:focus': {
      outline: 'none',
    },
  },
  assistiveText: {
    ...theme.typography.dina.captionMedium,
    fontWeight: 'normal',
    lineHeight: '13px',
    color: theme.palette.dina.mediumEmphasis,
    marginBottom: '16px',
    marginTop: '2px',
    marginLeft: '4px',
    ...restrictPointerEvents,
  },
  contentContainer: {
    cursor: 'default',
    display: 'flex',
    height: ({ height }) => height || 'auto',
    transition: '0.3s ease',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.dina.blackHoverOverlay}`,
    borderRadius: '4px',
    background: theme.palette.dina.blackHoverOverlay,
    '&:hover': {
      background: theme.palette.dina.backgroundHover,
      border: `1px solid ${theme.palette.dina.borderHover}`,
      '& $ellipsis': {
        opacity: 1,
      },
    },
    position: 'relative',
  },
  icon: {
    margin: '8px 6px',
  },
  iconWrapper: {
    width: '40px',
    backgroundColor: ({ type }) =>
      theme.palette.dina[type === 'media' ? 'timelineVideoClip' : 'buttonBackgroundHighEmphasis'],
    borderRadius: '4px 0 0 4px',
    margin: 0,
    ...restrictPointerEvents,
  },
  contentWrapper: {
    width: '100%',
    padding: '0px 16px',
    overflow: 'hidden',
  },
  withEllipsis: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    alignItems: 'center',
    height: 36,
    ...restrictPointerEvents,
  },
  titleWrapper: {
    width: 'calc(100% - 32px)',
  },
  title: {
    ...theme.typography.dina.overline,
    fontWeight: '500',
    lineHeight: '12px',
    letterSpacing: '1px',
    margin: 0,
  },
  ellipsis: {
    marginRight: '-10px',
    opacity: 0,
    transition: '0.3s ease',
  },
}));

export default useStyles;
