import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-apollo';
import GET_STORY from 'graphql/queries/getStory';
import memberTypes from 'graphql/memberTypes';
import UserCtx from 'contexts/UserContext';
import LoadingContext from 'contexts/LoadingContext';
import useCreateStoryFromPitch from 'hooks/useCreateStoryFromPitch';
import { getMemberQuery } from 'graphql/queryVariables';
import updateMember from 'graphql/mutations/updateMember';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useSetStorySync from 'hooks/useSetStorySync';
import useApolloSubscription from 'hooks/useApolloSubscription';
import { CurrentTabContext } from 'globalState';
import { CoverPhotoContext } from 'contexts/CoverPhotoContext';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { UNTITLED_STORY } from 'utils/constants';
import syncSyncProviders from 'utils/syncSyncProviders';
import updateCacheWithTransaction from 'graphql/utils/cache/notification/transaction/updateCacheWithTransaction';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'graphql/subscriptions/notifyMemberUpdate';
import Story from './story-view';

const StoryContainer = ({ storyId, ...rest }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const { mId: userId } = useContext(UserCtx);
  const { setCoverPhoto } = useContext(CoverPhotoContext);
  const [createLoading, setCreateLoading] = useState(false);

  const [storyTitle, setStoryTitle] = useState('');
  const { data, error, loading, refetch: storyRefetch } = useQuery(GET_STORY, {
    variables: getMemberQuery(storyId),
    fetchPolicy: 'cache-and-network',
  });
  const [updateStory] = useMutation(updateMember);
  const [checkUserRight] = useCheckUserRight();
  const enableSynchronize = checkUserRight('feature', 'story-auto-synchronize');
  const [setSync] = useSetStorySync();
  const [currentTab, setCurrentTab, , , updateTabTitle] = useContext(CurrentTabContext);
  const createStoryFromPitch = useCreateStoryFromPitch();

  const [subscribe, unSubscribe] = useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: storyId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      // console.log('subscription data:', subscriptionData);
      const item = subscriptionData.data.notifyMemberUpdateSubscription;
      updateCacheWithTransaction(client, { ...item, storyId });
    },
  });

  useEffect(() => {
    subscribe();

    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId]);

  useEffect(() => {
    if (data && data.getMember) {
      const { mTitle } = data.getMember;
      setStoryTitle(mTitle || '');

      if (currentTab.title !== mTitle) {
        updateTabTitle(storyId, mTitle);
      }
      setImageUrl(data.getMember.mAvatarUrl);
      syncSyncProviders(data.getMember.mSyncProviders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleTitleChange = async newTitle => {
    if (data.getMember) {
      data.getMember.mTitle = newTitle;
    }

    setStoryTitle(newTitle);
    updateTabTitle(storyId, newTitle);

    const mTitle = newTitle || UNTITLED_STORY;

    try {
      await updateStory({
        variables: {
          input: {
            mId: storyId,
            mTitle,
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSyncStateChange = async mSyncActive => {
    setSync(storyId, mSyncActive);
  };

  const onCreateStory = async () => {
    setCreateLoading(true);
    const createdStoryId = await createStoryFromPitch(storyId, userId);
    setCreateLoading(false);
    if (enableSynchronize) setSync(createdStoryId, ['*']);
    setCurrentTab({
      page: memberTypes.STORY,
      title: storyTitle,
      id: createdStoryId,
    });
    storyRefetch();
  };

  const onCoverPhotoSelected = url => {
    setImageUrl(url);
    setCoverPhoto({
      mId: storyId,
      url,
    });
  };

  if (loading || createLoading) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }

  if (error) {
    return <div> Error!{error.message} </div>;
  }
  if (!data.getMember) {
    return <div> no data </div>;
  }

  const coverPhotoUrl = imageUrl || data.getMember.mAvatarUrl;

  const headerTitle = storyTitle === UNTITLED_STORY ? '' : storyTitle;

  return (
    <LoadingContext>
      <Story
        data={data}
        storyId={storyId}
        image={coverPhotoUrl}
        coverPhotoSelected={onCoverPhotoSelected}
        titleChangeHandler={handleTitleChange}
        title={headerTitle}
        onCreateStory={onCreateStory}
        onSyncSettingsChange={handleSyncStateChange}
        {...rest}
      />
    </LoadingContext>
  );
};

StoryContainer.propTypes = {
  storyId: PropTypes.string.isRequired,
};

StoryContainer.defaultProps = {};

export default StoryContainer;
