import { useMutation } from 'react-apollo';
import CREATE_RUNDOWN from 'graphql/mutations/createRundownFromTemplate';

import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'graphql/queries/getRundownByPublishingDate';
import { startOfDay, endOfDay } from 'date-fns';

const useCreateRundown = () => {
  const [createRundownMutation] = useMutation(CREATE_RUNDOWN);

  const createRundown = async inputObject => {
    const { mId, mTitle, selectedDate } = inputObject;
    const input = {
      mId,
      mRefId: mId,
      mTitle,
      mPublishingAt: selectedDate,
    };
    let ret;
    await createRundownMutation({
      variables: { input },
      update: (proxy, mutationResult) => {
        const { createRundownFromTemplate } = mutationResult.data;
        const startDate = startOfDay(
          createRundownFromTemplate.mPublishingAt
        ).toISOString();
        const endDate = endOfDay(
          createRundownFromTemplate.mPublishingAt
        ).toISOString();

        const inp = {
          input: {
            mType: 'rundown',
            startDate,
            endDate,
          },
        };

        let existingRundowns;
        try {
          existingRundowns = proxy.readQuery({
            query: GET_RUNDOWNS_BY_PUBLISHING_DATE,
            variables: inp,
          });
        } catch (err) {
          console.log(err);
        }
        const { getRundownsByPublishingDate } = existingRundowns;
        getRundownsByPublishingDate.push(createRundownFromTemplate);
        proxy.writeQuery({
          query: GET_RUNDOWNS_BY_PUBLISHING_DATE,
          variables: inp,
          data: { getRundownsByPublishingDate },
        });
        ret = createRundownFromTemplate;
      },
    });
    return ret;
  };

  return [createRundown];
};

export default useCreateRundown;
