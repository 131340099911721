import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '8px',
    marginRight: '13px',
    height: 36,
  },
  statusIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  selectWrapper: {},
  select: {
    height: 24,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    width: 65,
    maxWidth: 65,
    paddingLeft: '4px',
  },
  inputBase: {
    '& > :first-child': {
      paddingRight: '8px',
    },
  },
  selectIcon: {
    color: palette.dina.iconInactive,
    height: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menu: { background: palette.dina.surfaceAppBackgroundMain },
  menuItem: {
    color: 'white',
    fontSize: 14,
    letterSpacing: '0.25px',
    '&:hover': {
      backgroundColor: palette.dina.whiteHoverOverlay,
    },
    '&$menuItemSelected, &$menuItemSelected:hover, &$menuItemSelected:focus': {
      backgroundColor: palette.dina.onSelected,
    },
  },
  menuItemSelected: {},
  input: {
    ...typography.dina.captionRegular,
    color: palette.dina.mediumEmphasis,
  },
}));

export default useStyles;
