import React, { useState, useEffect } from 'react';
import { addMilliseconds } from 'date-fns';
import useStyles from './rundownArea-styles';

const RundownArea = ({ xScaleRef, height, rundown, margin, storyCardWidth }) => {
  const classes = useStyles();
  const { mTitle, mPublishingAt, mPlannedDuration } = rundown;

  const startPosition = xScaleRef.current
    ? xScaleRef.current(new Date(mPublishingAt)) + margin
    : margin;

  const endPosition = xScaleRef.current
    ? xScaleRef.current(addMilliseconds(mPublishingAt, mPlannedDuration)) + margin
    : margin;
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(endPosition - startPosition);
  }, [endPosition, startPosition]);

  return (
    <g
      className="rundown-area-indicator"
      transform={`translate(${startPosition + storyCardWidth},0)`}
    >
      <rect className={classes.rect} x={0} y={0} width={width} height={height} />

      <text
        className={classes.text}
        x={0}
        y={8}
        textAnchor="left"
        alignmentBaseline="middle"
        dominantBaseline="middle"
      >
        {mTitle}
      </text>

      <line className={classes.line} x1={0} y1={16} x2={width} y2={16} strokeWidth={1} />
    </g>
  );
};

export default RundownArea;
