import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, ListItem, Fade, IconButton } from '@material-ui/core';
import useHover from 'hooks/useHover';
import ArrowDown from 'assets/icons/systemicons/arrows/disclosurearrow_down.svg';
import PlusSmallCircle from 'assets/icons/systemicons/plus_ultraSmall_circle.svg';
import ArrowUp from 'assets/icons/systemicons/arrows/disclosurearrow_up.svg';
import useStyles from './group-styles';

const GroupView = ({
  label,
  children,
  background,
  variant,
  collapsed,
  setCollapsed,
  itemCount,
  onAddClick,
  Input,
}) => {
  const classes = useStyles({ background });
  const [open, setOpen] = useState(collapsed);
  const [hoverRef, isHovered] = useHover();

  return (
    <div>
      <ListItem
        button
        onClick={() => {
          setOpen(!open);
          setCollapsed(!collapsed);
        }}
        classes={{
          root: variant ? classes.underlinedItem : classes.listItem,
        }}
        ref={hoverRef}
      >
        <div className={classes.labelContainer}>
          <img
            src={!open ? ArrowUp : ArrowDown}
            alt="expand/collapse icon"
            title="Expand / Collapse"
            className={classes.icon}
          />
          <div className={classes.label}>{label}</div>
          {itemCount >= 0 && (
            <div
              className={`${classes.count} ${
                isHovered && onAddClick ? classes.groupHovered : classes.groupInitial
              }`}
            >
              {itemCount}
            </div>
          )}
          {onAddClick && (
            <Fade in={isHovered} timeout={{ enter: 250, exit: 250 }} mountOnEnter unmountOnExit>
              <IconButton
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  onAddClick(event);
                }}
                className={classes.addButton}
                size="small"
                tabIndex="0"
              >
                <img src={PlusSmallCircle} alt="add" />
              </IconButton>
            </Fade>
          )}
        </div>
      </ListItem>
      {Input}
      <Collapse in={!open} timeout="auto" mountOnEnter>
        {children}
      </Collapse>
    </div>
  );
};

GroupView.propTypes = {
  /** label to show on the input field */
  label: PropTypes.string,
  /** defines whether group header will be initially collapsed or not  */
  collapsed: PropTypes.bool,
  /** children to be rendered in collapsable group header */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  /** counting the number of children */
  itemCount: PropTypes.number,
  /** variant of the group header */
  variant: PropTypes.string,
  /** onAddButtonClick */
  onAddClick: PropTypes.func,
  /** callback that sets the collapsed boolean */
  setCollapsed: PropTypes.func,
};

GroupView.defaultProps = {
  label: '',
  collapsed: false,
  children: null,
  itemCount: undefined,
  variant: '',
  onAddClick: undefined,
  setCollapsed: () => {},
};

export default GroupView;
