import { Editor } from 'slate';

/**
 * Toggles the specified mark
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} type Type for the mark to toggle
 * @param {Boolean} isActive Specifies if the given mark type is active or not
 * @param {(Boolean|String)} value Value for the mark
 * @returns {Object} SlateJS editor instance
 */

const toggleMark = (editor, type, isActive = false, value = true) => {
  const { removeMark, addMark } = Editor;

  if (isActive) removeMark(editor, type);
  else addMark(editor, type, value);

  return editor;
};

export default toggleMark;
