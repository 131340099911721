import memberTypes from 'graphql/memberTypes';
import { useMutation } from 'react-apollo';
import ASSIGN_USER_TO_INSTANCE from 'graphql/mutations/assignUserToInstance';

const useAssignInstance = () => {
  const [assignUserToInstance] = useMutation(ASSIGN_USER_TO_INSTANCE);

  const addUsersToInstance = async (instanceId, addedIds, removedIds, updatedIds) => {
    const addedMembersInput = addedIds.map(id => ({
      mId: id,
      mRefId: instanceId,
      mType: memberTypes.USER_INSTANCE,
    }));

    const removedMembersInput = removedIds.map(id => ({
      mId: id,
      mRefId: instanceId,
      mType: memberTypes.USER_INSTANCE,
    }));

    const updateAssigneesInput = {
      mId: instanceId,
      mRefId: instanceId,
      mAssignedMembers: updatedIds.map(id => ({
        mId: id,
        mType: memberTypes.USER,
      })),
    };

    const newMembers = {
      members: addedMembersInput,
    };

    const removedMembers = {
      members: removedMembersInput,
    };

    try {
      await assignUserToInstance({
        variables: { newMembers, removedMembers, updateAssigneesInput },
        optimisticResponse: {
          __typename: 'Mutation',
          addMembers: newMembers.members.map(member => ({
            ...member,
            __typename: 'MemberType',
          })),
          removeMembers: removedMembers.members.map(member => ({
            ...member,
            __typename: 'MemberType',
          })),
          updateAssignees: {
            ...updateAssigneesInput,
            mAssignedMembers: updateAssigneesInput.mAssignedMembers.map(member => ({
              ...member,
              __typename: 'AssignedMemberType',
            })),
            mUpdatedAt: new Date().toISOString(),
            __typename: 'MemberType',
          },
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
  return [addUsersToInstance];
};

export default useAssignInstance;
