import colors from 'theme/colors';

const getTextWidth = (ctx, style, text) => {
  if (typeof ctx.measureText === 'function') {
    return ctx.measureText(text).width;
  }
  const perCharWidth = style.fontSize / 1.7;
  return text.length * perCharWidth;
};

const style = {
  backgroundColor: colors.statusOnFocused,
  color: colors.highEmphasis,
  fontSize: 12,
  paddingTop: 5,
  paddingRight: 8,
  paddingBottom: 5,
  paddingLeft: 8,
};

const createDragPreview = text => {
  if (!text) text = '...';

  const rectHeight = style.paddingTop + style.fontSize + style.paddingBottom;
  const rectStrokeWidth = 1;

  const c = document.createElement('canvas');
  c.height = rectHeight;
  const ctx = c.getContext('2d');

  ctx.font = `${style.fontSize}px roboto`; // once before for measurement
  const textWidth = getTextWidth(ctx, style, text);
  const rectWidth = style.paddingLeft + textWidth + style.paddingRight;

  ctx.canvas.width = style.paddingLeft + textWidth + style.paddingRight + rectStrokeWidth * 2;
  ctx.font = `${style.fontSize}px roboto`; // once after for actually styling

  ctx.rect(0, 0, rectWidth, rectHeight);

  ctx.save();
  ctx.fillStyle = style.backgroundColor;
  ctx.fill();

  ctx.fillStyle = style.color;
  ctx.fillText(text, style.paddingLeft, style.paddingTop * 0.75 + style.fontSize);

  return c.toDataURL();
};

export default createDragPreview;
