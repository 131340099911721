import { makeStyles } from '@material-ui/styles';

const height = 40;
const padding = 8;
const assetButtonsMargin = 4;

const useStyles = makeStyles(({ palette }) => ({
  root: {
    height,
    background: palette.dina.blackHoverOverlay,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${padding}px`,
    pointerEvents: ({ readOnly }) => (readOnly ? 'none' : 'all'),
    borderBottom: `1px solid ${palette.dina.dividerLight}`,
  },

  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  assetWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& > *': { marginRight: assetButtonsMargin },
  },
}));

export default useStyles;
