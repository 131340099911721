import { initial, values } from 'lodash';
import findAndReplace from 'utils/findAndReplace';
import { blockTypes } from 'components/editors/types';
import { getParent } from 'components/editors/utils';
import parseMos from './parseMos';
import convertIntoMosAsset from './convertIntoMosAsset';

const primaryTypeValues = initial(values(blockTypes.primaryTypes));

/**
 * Inserts mos block
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} mosobj XML for the mos item
 * @returns {Object} SlateJS editor instance
 */

const insertMos = (editor, mosobj) => {
  const xmlDoc = parseMos(mosobj);
  const asset = convertIntoMosAsset(xmlDoc);

  if (asset) {
    const {
      value,
      insertFullscreenGraphics,
      insertOverlayGraphics,
      removeNodeByKey,
    } = editor;

    const parent = getParent(value);
    const { type, key, data } = parent;
    const newAsset = { ...asset, mosobj };
    const previousAssets = data.toJS().assets;

    const options = {
      assets: findAndReplace(previousAssets, newAsset, 'assetType'),
    };

    if (type === blockTypes.FULLSCREEN_GRAPHICS) {
      removeNodeByKey(key);
      insertFullscreenGraphics(options);
    } else if (primaryTypeValues.includes(type)) {
      insertOverlayGraphics(asset.title, mosobj);
    } else {
      insertFullscreenGraphics(options);
    }
  }
};

export default insertMos;
