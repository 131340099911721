import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  openStoryBoxButton: {
    position: 'absolute',
    top: '24px',
    left: 0,
    minWidth: '48px',
    maxWidth: '48px',
    height: '40px',
    backgroundColor: theme.palette.dina.fabBackground,
    boxShadow:
      '0px 6px 12px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '0 8px 8px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'opacity 0.5s ease-in',
    '&:hover': {
      backgroundColor: theme.palette.dina.fabBackground,
      '& > :first-child': {
        '& > :first-child': {
          '& path': {
            fillOpacity: 1,
          },
        },
      },
    },
    zIndex: 1,
  },
  fadeIn: {
    opacity: 1,
    animationName: '$fadeInOpacity',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in',
    animationDuration: '.5s',
  },

  '@keyframes fadeInOpacity': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  storyBox: {
    height: '100%',
    width: '327px',
    position: 'relative',
    backdropFilter: 'blur(8px)',
    borderRight: `1px solid ${theme.palette.dina.dividerDark}`,
  },
}));

export default useStyles;
