import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItem as MaterialListItem } from '@material-ui/core';
import CoverPhoto from 'assets/icons/systemicons/coverPhoto.svg';
import { useDrag } from 'react-dnd';
import dndTypes from 'utils/dndTypes';
// import { mediaTypes } from 'utils/rundownItemTypes';
// import dndTypes from 'utils/dndTypes';
import DownloadSrc from 'assets/icons/systemicons/download.svg';
import Close from 'assets/icons/systemicons/close.svg';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
// import Edit from 'assets/icons/systemicons/edit.svg';
import EllipsisButton from 'components/buttons/ellipsisButton';
import Popover from 'components/popover';
import Divider from 'components/divider';
import Thumbnail from '../thumbnail';
import MenuOption from '../listItem';
import useStyles from './assetItem-styles';

const AssetItem = ({
  thumbnailImage,
  fileName,
  isCoverPhoto,
  fileInfo,
  extractValueFor,
  onRemove,
  isSmallThumbnail,
  isVideo,
  onClick,
  onDoubleClick,
  onDownload,
  asset,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [handleClick, handleDoubleClick] = useMouseClickEvents(onClick, onDoubleClick);

  const onStopPropagation = e => e.stopPropagation();

  const onEllipsisClick = e => {
    onStopPropagation(e);
    setAnchorEl(e.currentTarget);
  };

  const onPopoverClose = () => {
    setAnchorEl(null);
  };

  const handleRemove = () => {
    onRemove();
    onPopoverClose();
  };

  // const handleSelectAdobePremier = () => {
  //   onSelectAdobePremier();
  //   onPopoverClose();
  // };

  // const handleSelectVimondIO = () => {
  //   OnSelectVimondIo();
  //   onPopoverClose();
  // };

  // const handleMakeCoverPhoto = () => {
  //   onMakeCoverPhoto();
  //   onPopoverClose();
  // };

  const payload = {
    id: asset.mRefId,
    mAssetId: asset.mAssetId,
    title: asset.mTitle,
    thumbnailUrl: asset.mThumbnailUrl || asset.mContentUrl,
    itemType: asset.mediaType,
    itemDuration: extractValueFor('duration'),
    provider: extractValueFor('provider'),
    size: extractValueFor('size'),
    extension: extractValueFor('extension'),
    mediaType: asset.mediaType,
  };

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: dndTypes.ASSET, payload },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <MaterialListItem
      button
      className={isDragging ? classes.boxShadow : ''}
      classes={{ root: classes.root }}
      disableGutters
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      ref={dragRef}
    >
      <div className={classes.thumbnail}>
        {isSmallThumbnail ? (
          <img src={thumbnailImage} className={classes.smallThumbnail} alt="" />
        ) : (
          <Thumbnail width={40} height={24} isVideo={isVideo} thumbnailImage={thumbnailImage} />
        )}
      </div>
      <Typography classes={{ root: classes.fileName }}>{fileName}</Typography>
      <div className={classes.coverPhotoContainer}>
        {isCoverPhoto && <img src={CoverPhoto} alt="" className={classes.coverPhotoIcon} />}
      </div>
      <Typography classes={{ root: classes.fileSize }}>{fileInfo}</Typography>
      <EllipsisButton className={classes.ellipsisButton} padding="0px" onClick={onEllipsisClick} />
      <Popover
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        position="bottom-center"
        type="surfaceCardDark"
        onClick={onStopPropagation}
      >
        <div className={classes.popover}>
          <div className={classes.triangle} />
          {/* <ListItem iconImage={Edit}
            text="Edit in Adobe Premiere"
            onClick={handleSelectAdobePremier}
          />
          <ListItem
            iconImage={Edit}
            text="Edit in Vimond IO"
            onClick={handleSelectVimondIO}
          />
          */}
          {onDownload && (
            <>
              <MenuOption
                iconImage={DownloadSrc}
                text="Download"
                onClick={() => onDownload(asset)}
              />
              <Divider className={classes.divider} />
            </>
          )}
          <MenuOption iconImage={Close} text="Remove from story" onClick={handleRemove} />
        </div>
      </Popover>
    </MaterialListItem>
  );
};

AssetItem.propTypes = {
  /** thumbanail of the asset */
  thumbnailImage: PropTypes.string,
  /** name of the file */
  fileName: PropTypes.string,
  /** Boolean that indicates if this photo is cover photo */
  isCoverPhoto: PropTypes.bool,
  /** Size of file */
  fileInfo: PropTypes.string,
  /** Callback to remove asset */
  onRemove: PropTypes.func,
  /** whether asset is video or not */
  isVideo: PropTypes.bool,
  /** whether the thumbnail is small or not */
  isSmallThumbnail: PropTypes.bool,
  /** onClick callback function */
  onClick: PropTypes.func,
  /** onDoubleClick callback function */
  onDoubleClick: PropTypes.func,
  /** onDownload callback function */
  onDownload: PropTypes.func,
  // /** callback to make image cover photo */
  // onMakeCoverPhoto: PropTypes.func,
  // /** callback to edit photo in adobe premier */
  // onSelectAdobePremier: PropTypes.func,
  // /** Callback to edit photo in vimond io */
  // OnSelectVimondIo: PropTypes.func,
};

AssetItem.defaultProps = {
  thumbnailImage: null,
  fileName: null,
  isCoverPhoto: false,
  fileInfo: null,
  onRemove: () => {},
  isVideo: false,
  isSmallThumbnail: false,
  onClick: () => {},
  onDoubleClick: () => {},
  onDownload: undefined,

  // onMakeCoverPhoto: () => {},
  // onSelectAdobePremier: () => {},
  // OnSelectVimondIo: () => {},
  // onMakeCoverPhoto,
  // onSelectAdobePremier,
  // OnSelectVimondIo,
};

export default AssetItem;
