import React, { useEffect } from 'react';
import { useApolloClient } from 'react-apollo';
import UPDATE_RUNDOWN_SUBSCRIPTION from 'graphql/subscriptions/updateRundown';
import BATCH_GETMEMBERS from 'graphql/queries/batchGetRundownInstances';
import GET_INSTANCE_FROM_CACHE from 'graphql/queries/getInstanceFromCache';
import useApolloSubscription from 'hooks/useApolloSubscription';
import useCheckUserRight from 'hooks/useCheckUserRight';
import RundownLists from './rundown-lists-view';
import newBlankItem from '../utils/newBlankItem';

const notEmptyArray = array => Array.isArray(array) && array.length > 0;

const RundownListsContainer = props => {
  const { mId, data } = props;
  const client = useApolloClient();

  const [checkUserRight] = useCheckUserRight();
  const canEditReady = checkUserRight('rundown', 'edit-ready');

  const getMissingInstances = async mIds => {
    const missingInstances = [];

    mIds
      .filter(id => id[0] !== '-')
      .forEach(id => {
        try {
          const existingInstance = client.readFragment({
            id,
            fragment: GET_INSTANCE_FROM_CACHE,
          });

          if (!existingInstance) {
            missingInstances.push(id);
          }
        } catch {
          missingInstances.push(id);
        }
      });

    if (missingInstances.length > 0) {
      const result = await client.query({
        variables: {
          input: {
            mIds: missingInstances,
          },
        },
        query: BATCH_GETMEMBERS,
      });

      const { data: addedInstancesData } = result;

      addedInstancesData.batchGetMembers.forEach(item => {
        const { account } = item.mProperties;
        if (account) account.__typename = 'AccountType';

        client.writeFragment({
          id: item.mId,
          fragment: GET_INSTANCE_FROM_CACHE,
          data: item,
        });
      });
    }
  };

  const onSubscriptionData = async ({ subscriptionData }) => {
    const { updateRundownSubscription } = subscriptionData.data;
    const { version, mOrder, mPreorder } = updateRundownSubscription;
    const ids = [...mOrder, ...mPreorder];
    await getMissingInstances(ids);
  };

  const [subscribe, unsubscribe] = useApolloSubscription(UPDATE_RUNDOWN_SUBSCRIPTION, {
    variables: {
      mId,
      mRefId: mId,
    },
    onSubscriptionData,
  });

  useEffect(() => {
    subscribe();

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mId]);

  /*
  useSubscription(UPDATE_RUNDOWN_SUBSCRIPTION, {
    variables: {
      mId,
      mRefId: mId

    },
    skip,
    onSubscriptionData: async ({ subscriptionData }) => {
      const { updateRundownSubscription } = subscriptionData.data;
      const { version, mOrder, mPreorder } = updateRundownSubscription;

      const ids = [...mOrder, ...mPreorder];

      await getMissingInstances(ids);
    },
  });  */

  const { mOrder, mPreorder } = data.getRundown;

  const updateCacheForOrderType = (instanceId, newOrderType) => {
    const instance = client.readFragment({
      id: instanceId,
      fragment: GET_INSTANCE_FROM_CACHE,
    });

    if (instance && instance.mProperties && instance.mProperties.account)
      instance.mProperties.account.orderType = newOrderType;

    client.writeFragment({
      id: instanceId,
      fragment: GET_INSTANCE_FROM_CACHE,
      data: instance,
    });
  };

  return (
    <RundownLists
      mOrder={notEmptyArray(mOrder) ? mOrder : [newBlankItem()]}
      mPreorder={notEmptyArray(mPreorder) ? mPreorder : [newBlankItem()]}
      canEditReady={canEditReady}
      updateCacheForOrderType={updateCacheForOrderType}
      {...props}
    />
  );
};

export default RundownListsContainer;
