import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/avatar';
import getInitials from 'utils/getInitials';
import { Typography } from '@material-ui/core';
import useStyles from './users-styles';

const isEqualTo3 = index => index === 3;

const Users = props => {
  const { data, size, onClick, ...rest } = props;
  const quantity = data.length;
  const displacement = 4;
  const styleHelper = (number, avatarWidth, shift) => {
    return number > 3 ? 4 * avatarWidth - 3 * shift : number * avatarWidth - (number - 1) * shift;
  };

  const getTitle = (index, title) => {
    if (!isEqualTo3(index)) return title;
    const count = quantity - index - 1;
    const more = `${count > 1 ? `${count} others` : `1 other`}`;

    return count > 0 ? `${title} and ${more}` : title;
  };

  const containerWidth = styleHelper(quantity, size, displacement);
  const classes = useStyles(containerWidth)();
  return (
    <div className={classes.container}>
      {data.slice(0, 4).map((avatarData, index) => {
        return (
          <Avatar
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={classes[`avatar${index}`]}
            src={index < 3 ? avatarData.mAvatarUrl : null}
            type={isEqualTo3(index) ? 'thinBordered' : 'bordered'}
            size={size}
            title={getTitle(index, avatarData.mTitle)}
            onClick={onClick}
            {...rest}
          >
            {avatarData.mTitle && !isEqualTo3(index) && (
              <Typography classes={{ root: classes.avatarText }}>
                {getInitials(avatarData.mTitle)}
              </Typography>
            )}
            {isEqualTo3(index) && (
              <Typography classes={{ root: classes.avatarText }} data-testid="overflow">
                {`+${quantity - index}`}
              </Typography>
            )}
          </Avatar>
        );
      })}
    </div>
  );
};

Users.propTypes = {
  /** size of the avatar */
  size: PropTypes.number,
  /** Callback function to be invoked when overflow avatar is clicked */
  onClick: PropTypes.func,
  /** Contains the information about the avatars */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
};
Users.defaultProps = {
  size: 48,
  onClick: () => {},
  data: [],
};

export default Users;
