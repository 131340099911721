import gql from 'graphql-tag';

export default gql`
  query GetContentTemplates($mId: String) {
    getContentTemplates(mId: $mId) {
      mRefId
      mTitle
      mContentKey
    }
  }
`;
