import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  rootDiv: {
    position: 'absolute',
    top: 0,
    right: 0,
    transition: 'transform 0.2s ease-out',
  },
}));

const SlidingButtonWrapper = ({ translateX, children }) => {
  const classes = useStyles();
  const [renderChildren, setRenderChildren] = useState(false);

  useEffect(() => {
    if (!renderChildren && translateX === 0) {
      setRenderChildren(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translateX]);

  return (
    <div
      className={classes.rootDiv}
      style={{ transform: `translateX(${translateX}px)` }}
    >
      {renderChildren ? children : <></>}
    </div>
  );
};

export default SlidingButtonWrapper;
