import { makeStyles } from '@material-ui/styles';

const commonStyle = {
  display: 'flex',
  justifyContent: 'center',
  height: '12px',
  backdropFilter: 'blur(15px)',
};

const useStyles = makeStyles(theme => ({
  vertical: {
    ...commonStyle,
    width: '48px',
    borderRadius: '0 0 4px 4px',
    transform: 'rotate(90deg) translateY(150%)',
  },
  horizontal: {
    ...commonStyle,
    width: '40px',
    borderRadius: '4px 0',
  },
  small: {
    ...commonStyle,
    width: '12px',
    borderRadius: '4px 0',
  },
  round: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    border: `0.5px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
    backdropFilter: 'blur(15px)',
  },
  minimal: {
    ...commonStyle,
    alignItems: 'center',
    height: '16px',
    width: '16px',
    borderRadius: '50%',
  },
  smallBreaking: {
    ...commonStyle,
    width: '40px',
    borderRadius: '0 0 4px 4px',
    transform: 'rotate(90deg) translate3d( 32.5%, 125%, 0)',
  },
  labelText: {
    ...theme.typography.dina.priorityLabel,
  },
  priorityBreaking: {
    backgroundColor: theme.palette.dina.prioBreaking,
  },
  priority1: {
    backgroundColor: theme.palette.dina.prio1,
  },
  priority2: {
    backgroundColor: theme.palette.dina.prio2,
  },
  priority3: {
    backgroundColor: theme.palette.dina.prio3,
  },
  1: {
    backgroundColor: theme.palette.dina.prioBreaking,
  },
  2: {
    backgroundColor: theme.palette.dina.prio1,
  },
  3: {
    backgroundColor: theme.palette.dina.prio2,
  },
  4: {
    backgroundColor: theme.palette.dina.prio3,
  },
  5: {
    backgroundColor: theme.palette.dina.prio3,
  },
  6: {
    backgroundColor: theme.palette.dina.prio3,
  },
}));

export default useStyles;
