import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants/types';
import useStyles from './styles';

const Mention = ({ attributes, children, element }) => {
  const classes = useStyles();
  const { mTitle } = element.data;

  return (
    <span className={classes.root} {...attributes}>
      <span>
        @{mTitle}
        {children}
      </span>
    </span>
  );
};

Mention.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Mention.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.MENTION,
    data: { mTitle: '' },
    children: [],
  },
};

export default memo(Mention);
