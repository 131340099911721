import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import colors from 'theme/colors';
import Icon from 'components/icon';
import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet.svg';
import useStyles from './toolbarButton-styles';

const ToolbarButton = props => {
  const {
    selected,
    onClick,
    icon,
    children,
    title,
    showArrow,
    ...rest
  } = props;
  const classes = useStyles(selected)();

  const startIconSelector = () => {
    if (icon) {
      return typeof icon === 'string' ? (
        <Icon
          icon={icon}
          height={24}
          width={24}
          hovered={selected}
          hoverColor={colors.highEmphasis}
          {...rest}
        />
      ) : (
        icon
      );
    }
    return null;
  };

  return (
    <Button
      data-testid="toolbarButton"
      onClick={onClick}
      className={classes.button}
      classes={{ root: classes.root, startIcon: classes.startIcon }}
      value={selected}
      title={title}
      startIcon={startIconSelector()}
    >
      {children}
      <div className={classes.arrowDown}>{showArrow && <ArrowDown />}</div>
    </Button>
  );
};

ToolbarButton.propTypes = {
  // is the button currently selected
  selected: PropTypes.bool,
  // what text should be rendered on the button
  children: PropTypes.string,
  // callback to the onClick function
  onClick: PropTypes.func,
  /** the icon that should be displayed before the text */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** title that can be displayed on hover */
  title: PropTypes.string,
  /** boolean that shows dropdown button on hover */
  showArrow: PropTypes.bool,
};

ToolbarButton.defaultProps = {
  selected: false,
  children: '',
  onClick: () => {},
  icon: null,
  title: '',
  showArrow: false,
};

export default ToolbarButton;
