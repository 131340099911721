import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.dina.dividerLight}`,
  },
  list: {
    width: '100%',
    height: '100%',
  },
  listContainerDiv: {
    height: '100%',
    width: '100%',
    minWidth: '860px',
  },
}));

export default useStyles;
