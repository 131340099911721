import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  tabsDefault: {
    width: '100%',
  },
  tabsDefaultIndicator: {
    backgroundColor: theme.palette.dina.highEmphasis,
  },
  tabsSmall: {
    width: '100%',
    minHeight: '32px',
  },
  tabsSmallIndicator: {
    backgroundColor: theme.palette.dina.highEmphasis,
  },
  tabsIcon: {
    width: '100%',
    minHeight: '48px',
  },
  tabsIconIndicator: {
    backgroundColor: theme.palette.dina.highEmphasis,
  },
  tabsOutlined: { width: '100%', minHeight: '32px' },
  tabsOutlinedIndicator: {
    display: 'none',
  },
  smallContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  outlinedContainer: {
    '& > :first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '& > :last-child': {
      borderRadius: '0 4px 4px 0',
    },
  },
  smallScroller: {
    height: '32px',
  },

  tabDefault: {
    ...theme.typography.dina.button,
    '@media (min-width:780px)': {
      // eslint-disable-line no-useless-computed-key
      minWidth: '0',
    },
    '&:hover': {
      color: theme.palette.dina.highEmphasis,
      opacity: 1,
    },
  },

  tabDefaultSelected: {
    color: theme.palette.dina.highEmphasis,
  },

  tabSmall: {
    ...theme.typography.dina.overline,
    lineHeight: '12px',
    letterSpacing: '1px',
    minHeight: '32px',
    maxHeight: '32px',
    padding: '6px',
    '@media (min-width:780px)': {
      // eslint-disable-line no-useless-computed-key
      minWidth: '0',
    },
    '&:hover': {
      color: theme.palette.dina.highEmphasis,
      opacity: 1,
    },
  },

  tabSmallSelected: {
    color: theme.palette.dina.highEmphasis,
  },
  tabIcon: {
    ...theme.typography.dina.captionSmall,
    color: theme.palette.dina.mediumEmphasis,
    lineHeight: '12px',
    minHeight: '48px',
    maxHeight: '48px',
    '@media (min-width:780px)': {
      // eslint-disable-line no-useless-computed-key
      minWidth: '0',
    },
    '&:hover': {
      color: theme.palette.dina.highEmphasis,
      opacity: 1,
    },
    textTransform: 'none',
  },

  tabIconSelected: {
    color: theme.palette.dina.highEmphasis,
  },

  tabOutlined: {
    ...theme.typography.dina.label,
    color: theme.palette.dina.mediumEmphasis,
    border: `1px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
    minWidth: '75px',
    minHeight: '32px',
    maxHeight: '32px',
    transition: '0.3s ease-out',
    '&:hover': {
      transition: '0.1s ease-in',
      color: theme.palette.dina.highEmphasis,
      opacity: 1,
    },
  },
  tabOutlinedSelected: {
    transition: '0.3s ease-in',
    backgroundColor: theme.palette.dina.onSelected,
    color: theme.palette.dina.highEmphasis,
  },
}));

export default useStyles;
