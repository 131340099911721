import { useContext } from 'react';
import { PlanningContext } from 'globalState';
import { DEFAULT_STATE } from 'utils/planningViews';

const useResetPlans = () => {
  const { view, time, tabIndex, timeVariant, savedFilters, stateCollapsed } = DEFAULT_STATE;
  const {
    setView,
    setTime,
    setTabIndex,
    setTimeVariant,
    setSavedFilters,
    setStateCollapsed,
  } = useContext(PlanningContext);

  const resetPlans = () => {
    window.localStorage.setItem('plans', JSON.stringify({}));
    setView(view);
    setTime(time);
    setTabIndex(tabIndex);
    setTimeVariant(timeVariant);
    setSavedFilters(savedFilters);
    setStateCollapsed(stateCollapsed);
  };
  return resetPlans;
};

export default useResetPlans;
