import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {},
  menu: {
    padding: '8px 7px 6px',
    position: 'absolute',
    zIndex: '1',
    top: '-10000px',
    left: '-10000px',
    marginTop: '-6px',
    opacity: '0',
    backgroundColor: '#222',
    color: '#eee',
    borderRadius: '4px',
    transition: 'opacity 0.75s',

    '& > *': {
      display: 'inline-block',
    },
    '& > * + *': {
      marginLeft: '15px',
    },
  },
}));

export default useStyles;
