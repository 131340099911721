const SortBy = (arr, ascending) => {
  return arr.sort((a, b) => {
    if (!a.mPublishingAt && !b.mPublishingAt) {
      if (ascending) return a.mCreatedAt > b.mCreatedAt ? 1 : -1;
      return a.mCreatedAt > b.mCreatedAt ? -1 : 1;
    }
    if (!a.mPublishingAt) return ascending ? 1 : -1;
    if (!b.mPublishingAt) return ascending ? -1 : 1;
    if (ascending) return a.mPublishingAt > b.mPublishingAt ? 1 : -1;
    let now = new Date();
    now = now.toISOString();

    // scheduled - asc - 0
    // published - desc - 1

    // already published vs already published 1-1
    if (a.mPublishingAt < now && b.mPublishingAt < now) {
      return a.mPublishingAt > b.mPublishingAt ? -1 : 1;
    }
    // scheduled vs scheduled 0-0
    if (a.mPublishingAt > now && b.mPublishingAt > now) {
      return a.mPublishingAt < b.mPublishingAt ? -1 : 1;
    }
    // published vs scheduled 1-0
    if (a.mPublishingAt < now && b.mPublishingAt > now) {
      return 1;
    }
    // scheduled vs published 0-1
    if (a.mPublishingAt > now && b.mPublishingAt < now) {
      return -1;
    }
  });
};

export default SortBy;
