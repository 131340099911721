import React from 'react';
import fieldTypes from 'utils/rundown/fieldTypes';
import Input from '../inputs/input';
import Select from '../inputs/select';

const FieldView = ({
  instanceId,
  fieldId,
  fieldValue,
  type,
  width,
  style,
  options,
  updateField,
  disableEdit,
}) => {
  if (!width) return null;

  switch (type) {
    case fieldTypes.SELECT:
      return (
        <Select
          key={fieldId}
          width={width}
          value={fieldValue}
          items={options}
          updateInputField={updateField}
          disableEdit={disableEdit}
        />
      );
    case fieldTypes.MENU:
      return (
        <Select
          key={fieldId}
          showIconInput
          width={width}
          value={fieldValue}
          items={options}
          updateInputField={updateField}
          disableEdit={disableEdit}
        />
      );
    default:
      return (
        <Input
          key={fieldId}
          id={`${instanceId}${fieldId}`}
          value={fieldValue}
          style={style}
          width={width}
          updateInputField={updateField}
          disableEdit={disableEdit}
        />
      );
  }
};

export default FieldView;
