import React, { useState } from 'react';
import StoryBox from 'screens/storybox';
import theme from 'theme/theme';
import Tooltip from 'components/tooltip';
import { Button, Slide } from '@material-ui/core';
import { ReactComponent as StoryBoxIcon } from 'assets/icons/systemicons/Left_inspect_off.svg';
import useStyles from './storybox-styles';

const StoryboxView = ({ storySpec, onInstanceClick, storyContainerRef, platforms, isPitch }) => {
  const classes = useStyles();
  const [showStorybox, setShowStorybox] = useState(false);
  const handleOpenStorybox = () => {
    setShowStorybox(true);
  };
  const handleCloseStorybox = () => {
    setShowStorybox(false);
  };

  return (
    <div>
      {!showStorybox && (
        <Tooltip title="Open Storybox">
          <Button
            variant="contained"
            className={`${classes.openStoryBoxButton} ${showStorybox ? '' : classes.fadeIn}`}
            classes={{ label: classes.icon }}
            onClick={handleOpenStorybox}
          >
            <StoryBoxIcon />
          </Button>
        </Tooltip>
      )}
      <Slide
        direction="right"
        timeout={{ enter: 250, exit: 250 }}
        in={Boolean(showStorybox)}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.storyBox}>
          <StoryBox
            smallHeader
            dialogHeight={!isPitch ? 'calc(100vh - 260px)' : 'calc(100vh - 116px)'}
            onClose={handleCloseStorybox}
            background={theme.palette.dina.blackHoverOverlay}
            storySpec={storySpec || {}}
            {...{ onInstanceClick, storyContainerRef, platforms }}
          />
        </div>
      </Slide>
    </div>
  );
};

export default StoryboxView;
