import React from 'react';
import uuidv1 from 'uuid/v1';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Divider from 'components/divider';
import useStyles from './filledGrid-styles';

const FilledRow = ({ label, value }) => {
  const classes = useStyles();
  return (
    <div style={{ display: 'flex' }}>
      <div className={classes.labelContainer}>
        <Typography
          classes={{ root: classes.labelRoot }}
        >{`${label}:`}</Typography>
      </div>
      <div className={classes.valueContainer}>
        <Typography classes={{ root: classes.valueRoot }}>{value}</Typography>
      </div>
    </div>
  );
};

const FilledGrid = ({ columns, rows }) => {
  const classes = useStyles();

  const FindColumnLabel = id => {
    const value = columns.find(field => field.id === id);
    return value.label;
  };

  return rows.map(mRow => (
    <div key={uuidv1()} className={classes.container}>
      {mRow.map(column => (
        <FilledRow
          key={uuidv1()}
          label={FindColumnLabel(column.key)}
          value={column.value}
        />
      ))}
      <Divider className={classes.divider} />
    </div>
  ));
};

FilledGrid.propTypes = {
  /** Columns for Grid */
  columns: PropTypes.arrayOf(PropTypes.object),
  /** Rows for Grid */
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
};

FilledGrid.defaultProps = {
  columns: [],
  rows: [[], []],
};

export default FilledGrid;
