import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Video } from 'assets/icons/systemicons/editor/video.svg';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import Tooltip from 'components/tooltip';
import { useSlate } from 'slate-react';
import useFileUpload from 'hooks/useFileUpload';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import insertVideoBlock from 'components/editor/components/video/utils/insertVideoBlock';
import insertVideo from 'components/editor/components/video/utils/insertVideo';

const VideoButton = () => {
  const editor = useSlate();
  const { update, isAllowed } = useEditorContext();

  const onFileUpload = useCallback(
    (file, fileUrl) => insertVideo(editor, update, file, fileUrl),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const uploadFile = useFileUpload(['video/mp4'], onFileUpload);

  const onMouseDown = useCallback(
    event => {
      event.preventDefault();
      if (isAllowed) insertVideoBlock(editor, update);
      else uploadFile();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Tooltip title={tooltipEnum.VIDEO}>
      <Video {...{ onMouseDown }} />
    </Tooltip>
  );
};

VideoButton.propTypes = {
  /** Callback to invoked when text editor's value updates,
   * with the update type and relevant payload passed in
   */
  update: PropTypes.func,
};

VideoButton.defaultProps = {
  update: ({ type, payload }) => {},
};

export default VideoButton;
