import getIndicatorNewPosition from '../../functions/getIndicatorNewPosition';
import resetAnimatedIndicator from './resetAnimatedIndicator';

const showStaticIndicator = (indicatorNode, sliderWrapper, dayWidth) => {
  resetAnimatedIndicator(indicatorNode, sliderWrapper, dayWidth);

  const newItems = Array.from(document.querySelectorAll('.selected-day'));
  const newItemsLength = newItems.length;

  if (newItemsLength > 0) {
    const firstItem = newItems[0];
    const newPosition = getIndicatorNewPosition(firstItem, indicatorNode, sliderWrapper);

    indicatorNode.style.left = `${newPosition}px`;

    newItems.forEach((newItem, index) => {
      newItem.classList.remove(
        'animated-selected',
        'static-selected-first',
        'static-selected-last'
      );
      newItem.classList.add('static-selected');

      if (index === 0) {
        newItem.classList.add('static-selected-first');
      }

      if (index === newItemsLength - 1) {
        newItem.classList.add('static-selected-last');
      }
    });
  }
};

export default showStaticIndicator;
