import React from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItem } from '@material-ui/core';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import CategoryIndicator from 'components/categoryIndicator';
import Assignees from 'components/avatarGroup/assignees';
import Groups from 'components/avatarGroup/groups';
import StoryImportanceSelect from 'components/storyImportance';
import useStyles from './card-styles';
import PitchIndicator from '../pitchIndicator';

const Card = ({
  handleClick,
  handleDoubleClick,
  categories,
  isPitch,
  image,
  priorityLevel,
  onPriorityChange,
  assignees,
  title,
  titleLineClamp,
  teams,
}) => {
  const classes = useStyles({ titleLineClamp });
  return (
    <ListItem
      button
      disableGutters
      className={classes.root}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      <div className={classes.categoryIndicatorContainer}>
        {categories &&
          categories.map((category, index) => (
            <CategoryIndicator
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              category={category}
              className={classes.categoryIndicator}
            />
          ))}
      </div>
      <div className={classes.topRow}>
        <div className={classes.topLeft}>
          {isPitch && <PitchIndicator className={classes.pitchIndicator} />}
          <img src={image || fallbackImage} alt="" className={classes.image} />
          {!isPitch && (
            <StoryImportanceSelect value={priorityLevel || ''} onChange={onPriorityChange} />
          )}
        </div>
        <div className={classes.topRight}>
          <Assignees assignees={assignees} size={18} />
          {teams.length !== 0 && (
            <div className={classes.groups}>
              <Groups variant="Team" items={teams} size={18} />
            </div>
          )}
        </div>
      </div>
      <div className={classes.title}>
        <Typography classes={{ root: classes.titleText }}>{title}</Typography>
      </div>
    </ListItem>
  );
};

Card.propTypes = {
  /** Defines the priority */
  priorityLevel: PropTypes.string,
  /** Story title */
  title: PropTypes.string,
  /** the image of the story */
  image: PropTypes.string,
  /** the categories selected for the story */
  categories: PropTypes.arrayOf(PropTypes.any),
  /** boolean that indicates pitch */
  isPitch: PropTypes.bool,
  /** list of users the story/pitch is assigned to */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      /** name of assignee */
      mTitle: PropTypes.string,
      /** avatar image of assignee */
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** list of teams the story/pitch is assigned to */
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      /** name of team */
      mTitle: PropTypes.string,
      /** avatar image of team */
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** callback to be invoked when priority is changed */
  onPriorityChange: PropTypes.func,
  /** callback to be invoked when card is clicked */
  handleClick: PropTypes.func,
  /** callback to be invoked when card is double clicked */
  handleDoubleClick: PropTypes.func,
  /** Maximum number of line in which the title is shown */
  titleLineClamp: PropTypes.number,
};

Card.defaultProps = {
  priorityLevel: '',
  title: '',
  image: fallbackImage,
  categories: [],
  isPitch: false,
  assignees: [],
  teams: [],
  onPriorityChange: () => {},
  handleClick: () => {},
  handleDoubleClick: () => {},
  titleLineClamp: 3,
};

export default Card;
