import { useContext } from 'react';
import { useMutation } from 'react-apollo';
import configCtx from 'contexts/configContext';
import UPDATE_INSTANCE from 'graphql/mutations/updateInstance';
import getMetadataWithMosTag from 'utils/metadata/addMostag';

const useUpdateInstanceMetadata = () => {
  const { metadataForms } = useContext(configCtx);
  const [updateInstanceMutation] = useMutation(UPDATE_INSTANCE);
  const form = metadataForms[0];

  const saveMetaData = async (
    instanceId,
    metadataFields,
    fetchPolicy,
    onMetadataUpdated,
    items,
    setUnlocked,
    content,
  ) => {
    try {
      const formFields = (form && form.fields) || [];

      const metadataFieldsWithMosTag = getMetadataWithMosTag(formFields, metadataFields);

      const input = {
        mId: instanceId,

        items,
      };
      if (setUnlocked) {
        input.locked = null;
      }

      if(setUnlocked && content) {
        input.content = JSON.stringify(content); // to save history on release write lock.
      }

      if (metadataFieldsWithMosTag && metadataFieldsWithMosTag.length > 0) {
        input.mMetaData = metadataFieldsWithMosTag;
      }

      const options = {
        variables: {
          input,
        },
      };

      await updateInstanceMutation({
        ...options,
      }).then(r => {
        if (onMetadataUpdated) {
          onMetadataUpdated(instanceId, metadataFields);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return [saveMetaData];
};

export default useUpdateInstanceMetadata;
