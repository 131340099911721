import { makeStyles } from '@material-ui/styles';

const commmonActionButton = {
  height: 56,
  width: 56,
  borderRadius: '0%',
};

const commonTitleStyles = theme => ({
  ...theme.typography.dina.captionRegular,
  letterSpacing: '0.25px',
  color: theme.palette.dina.mediumEmphasis,
});

const useStyles = makeStyles(theme => ({
  root: {
    height: '56px',
    display: 'flex',
    padding: 0,
    paddingLeft: '12px',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
      '& $ellipsis': {
        opacity: 1,
      },
    },
  },
  info: {
    flex: 1,
    marginLeft: '8px',
  },
  name: {
    ...theme.typography.dina.listItemLabelMedium,
    letterSpacing: '0.25px',
    lineHeight: '16px',
    color: theme.palette.dina.highEmphasis,
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  title: {
    ...commonTitleStyles(theme),
  },
  secondaryInfo: {
    ...commonTitleStyles(theme),
    textAlign: 'right',
  },
  iconButton: {
    marginRight: '8px',
  },
  ellipsis: {
    width: '20px',
    opacity: 0,
  },
  delete: {
    ...commmonActionButton,
    backgroundColor: theme.palette.dina.statusError,
    '&:hover': {
      backgroundColor: theme.palette.dina.statusError,
      '& > :first-child': {
        '& > :first-child': {
          '& path': {
            fillOpacity: 1,
          },
        },
      },
    },
  },
  close: {
    ...commmonActionButton,
    backgroundColor: theme.palette.dina.onFocus,
    '&:hover': {
      backgroundColor: theme.palette.dina.onFocus,
      '& > :first-child': {
        '& > :first-child': {
          '& path': {
            fillOpacity: 1,
          },
        },
      },
    },
  },
}));

export default useStyles;
