import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import { CoverPhotoContext } from 'contexts/CoverPhotoContext';
import UserContext from 'contexts/UserContext';
import { CurrentTabContext, StoryTabsContext } from 'globalState';
import { useScrollIntoView } from 'contexts/ScrollIntoViewContext';
import memberTypes from 'graphql/memberTypes';
import StoryBox from 'screens/storybox';
import distanceInWord from 'utils/distanceInWords';
import usePublishDateUpdate from 'hooks/usePublishDateUpdate';
import { UNTITLED_STORY } from 'utils/constants';
import useShareStory from 'hooks/useShareStory';
import Popover from 'components/popover';
import useBookmarkStory from 'hooks/useBookmarkStory';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import useArchiveStory from 'hooks/useArchiveStory';
import Story from './story-view';

const StoryContainer = ({
  title,
  updatedAt,
  publishingAt,
  assignedMembers,
  id,
  imgUrl,
  setShowStorybox,
  mContentKey,
  groupId,
  groupType,
  storyType,
  openStory,
  queryVariables,
}) => {
  const timeString = distanceInWord(updatedAt);
  const [currentTab] = useContext(CurrentTabContext);
  const [, , closeStoryTab] = useContext(StoryTabsContext);
  const [assignedUsers] = useGetAssignedMembers(assignedMembers || []);
  const [assignMemberToStory, shareStoryWith] = useShareStory();
  const [bookmark] = useBookmarkStory();
  const [archive] = useArchiveStory();
  const [updatePublishDate] = usePublishDateUpdate();
  const user = useContext(UserContext);
  const { bookmarks, mId: userId } = user;
  // TODO need to work on setArchived function
  const [archived] = useState(storyType === 'archived_story');
  const [bookmarked, setBookmarked] = useState(!!bookmarks[id]);
  const [joined, setJoined] = useState(assignedUsers.map(usr => usr.mId).includes(userId));
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { coverPhoto } = useContext(CoverPhotoContext);
  const [, setCurrentClicked] = useScrollIntoView();

  useEffect(() => {
    setImageUrl(imgUrl);
  }, [imgUrl]);

  const selected = id === currentTab.id;

  useEffect(() => {
    if (coverPhoto && coverPhoto.mId === id && coverPhoto.url) {
      setImageUrl(coverPhoto.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverPhoto]);

  const addMemberToStory = async (memberId, storyId, memberType) => {
    assignMemberToStory(storyId, memberId, memberType);
  };

  const joinStory = async () => {
    if (joined) {
      try {
        await shareStoryWith(
          id,
          assignedUsers.filter(usr => usr.mId !== userId),
          assignedUsers,
          memberTypes.USER_STORY,
        );
        setJoined(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    } else {
      try {
        await assignMemberToStory(id, userId, memberTypes.USER_STORY);
        setJoined(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const archiveStory = () => {
    archive(id, groupId, groupType, queryVariables);
    closeStoryTab(id);
  };

  const bookmarkStory = () => {
    // eslint-disable-next-line no-prototype-builtins
    if (bookmarks.hasOwnProperty(id)) {
      setBookmarked(false);
      delete bookmarks[id];
    } else {
      setBookmarked(true);
      bookmarks[id] = {
        bookmarkedId: id,
        bookmarkedType: memberTypes.STORY,
      };
    }
    bookmark(userId, bookmarks);
  };

  const onUpdatePublishDate = newTime => {
    const publishingDate = new Date(newTime);
    updatePublishDate(id, publishingDate);
  };

  const subMenuActionHandler = label => {
    switch (label) {
      case 'bookmark':
        bookmarkStory();
        break;
      case 'archive':
        archiveStory();
        break;
      case 'join':
        joinStory();
        break;
      default:
        break;
    }
  };

  const isPitch = storyType === memberTypes.PITCH;
  const page = isPitch ? memberTypes.PITCH : memberTypes.STORY;

  const handleOpenStory = () => openStory(title, id, page);
  const handleClose = () => setAnchorEl(null);
  const image = imageUrl || fallbackImage;

  const onInstanceClick = async (sId, instanceId) => {
    handleClose();
    await openStory(title, id, page).then(() => {
      setCurrentClicked(instanceId);
    });
  };

  const onSetShowStorybox = () =>
    setShowStorybox({
      id,
      image: imageUrl,
      title,
      page: storyType,
    });

  return (
    <div>
      <Story
        title={title || UNTITLED_STORY}
        updatedAt={timeString}
        publishingAt={publishingAt}
        image={image}
        id={id}
        anchorEl={anchorEl}
        selected={selected}
        onDoubleClick={handleOpenStory}
        onClick={setAnchorEl}
        isPitch={isPitch}
        handleDrop={(memberId, storyId, memberType) => {
          addMemberToStory(memberId, storyId, memberType);
        }}
        setShowStorybox={onSetShowStorybox}
        onUpdatePublishDate={onUpdatePublishDate}
        assignedUsers={assignedUsers}
        subMenuActionHandler={subMenuActionHandler}
        groupType={groupType}
        archived={archived}
        bookmarked={bookmarked}
        joined={joined}
        dropTargetType={page}
      />
      <Popover
        position="right"
        anchorEl={anchorEl}
        noMargin
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        type="surfaceCardDark"
      >
        <div
          style={{
            width: '327px',
            height: '830px',
            borderRadius: '8px',
            overflow: 'hidden',
          }}
        >
          <StoryBox
            storySpec={{ id, image }}
            dialogHeight="810px"
            onClose={handleClose}
            onOpenStory={() => {
              handleClose();
              handleOpenStory();
            }}
            onInstanceClick={onInstanceClick}
          />
        </div>
      </Popover>
    </div>
  );
};

StoryContainer.propTypes = {
  /** Story id */
  id: PropTypes.string.isRequired,
  /** Story title */
  title: PropTypes.string,
  /** Thumbnail Url */
  imgUrl: PropTypes.string,
  /** id of the group that is calling */
  groupId: PropTypes.string,
};

StoryContainer.defaultProps = {
  title: UNTITLED_STORY,
  imgUrl: null,
  groupId: '',
};

export default StoryContainer;
