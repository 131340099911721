import React, { useState } from 'react';
import dndTypes from 'utils/dndTypes';
import Divider from 'components/divider';
import Scrollbar from 'screens/main/components/scrollbar';
import Dialog from 'components/dialog';
import memberTypes from 'graphql/memberTypes';
import AddMember from 'components/addMemberDialog';
import { useDrop } from 'react-dnd';
import getInitials from 'utils/getInitials';
import Group from '../group';
import ListItem from './listItem';

const CommonGroup = ({
  label,
  data,
  currentUserId,
  removeMemberFromStory,
  containerRef,
  dialogHeight,
  shareStory,
  memberType,
  variant,
  ...rest
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => setOpenDialog(!openDialog);
  const handleOk = members => {
    shareStory(members, data, memberType);
    handleOpenDialog();
  };
  return (
    <Group
      label={label}
      itemCount={data.length}
      onAddClick={memberType ? handleOpenDialog : undefined}
    >
      <div>
        {data.map((item, index) => (
          <ListItem
            key={item.mId}
            index={index}
            length={data.length}
            src={item.mAvatarUrl}
            name={item.mTitle}
            taskInfo={currentUserId && currentUserId === item.mId ? `(You)` : ''}
            info={item.mType}
            onDelete={() => removeMemberFromStory(item.mId)}
            {...{ variant }}
            {...rest}
          >
            {getInitials(item.mTitle)}
          </ListItem>
        ))}
        <Dialog
          disableScrollLock
          disableRestoreFocus
          open={openDialog}
          container={containerRef.current}
          onClose={handleOpenDialog}
        >
          <AddMember
            variant={variant}
            dialogTitle={label}
            onCancel={handleOpenDialog}
            height={dialogHeight}
            onOk={handleOk}
            members={data}
          />
        </Dialog>
      </div>
    </Group>
  );
};

const ResourceView = ({
  storyId,
  userData,
  contactData,
  teamData,
  departmentData,
  currentUserId,
  removeUserFromStory,
  removeContactsFromStory,
  removeTeamFromStory,
  removeDepartmentFromStory,
  containerRef,
  dialogHeight,
  shareStory,
}) => {
  const [, dropRef] = useDrop({
    accept: dndTypes.MEMBER,
    drop: (item, monitor) => ({ id: storyId }),
    collect: monitor => ({
      hovered: monitor.isOver(),
      item: monitor.getItem(),
    }),
  });

  return (
    <Scrollbar>
      <div style={{ height: '100%' }} ref={dropRef}>
        <CommonGroup
          label="Internal People"
          variant="People"
          data={userData}
          currentUserId={currentUserId}
          removeMemberFromStory={removeUserFromStory}
          memberType={memberTypes.USER_STORY}
          {...{ containerRef, dialogHeight, shareStory }}
        />
        <CommonGroup
          label="External People"
          variant="Contact"
          data={contactData}
          memberType={memberTypes.STORY_CONTACT}
          removeMemberFromStory={removeContactsFromStory}
          {...{ containerRef, dialogHeight, shareStory }}
        />
        <CommonGroup
          label="Team"
          variant="Team"
          memberType={memberTypes.TEAM_STORY}
          data={teamData}
          removeMemberFromStory={removeTeamFromStory}
          {...{ containerRef, dialogHeight, shareStory }}
        />
        <CommonGroup
          label="Department"
          variant="Department"
          memberType={memberTypes.DEPARTMENT_STORY}
          data={departmentData}
          removeMemberFromStory={removeDepartmentFromStory}
          {...{ containerRef, dialogHeight, shareStory }}
        />
        <Divider />
      </div>
    </Scrollbar>
  );
};

export default ResourceView;
