import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import ToggleButton from 'components/buttons/toolbarToggleButton';
import { useRundownContext } from 'globalState';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { formatDateToLocaleString } from 'utils/formatDate';
import { findButtonLabel } from './toolbarButtons';
import EllipsisDropdown from './ellipsisDropdown';
import HostsView from './hosts';
import useStyles from './toolbar-styles';

const RundownToolbar = props => {
  const {
    mosState,
    preparingState,
    onEditMasterClick,
    selectedDate,
    onRundownStateChange,
    onRundownHostUpdate,
    onDownloadClick,
    host,
    isRundownExists,
    isInitializeState,
    onPreparingStateChange,
    batchUpdateRundownInstanceState,
  } = props;
  const classes = useStyles();

  const [{ toolbarButtons }, dispatch] = useRundownContext();

  const [checkUserRight] = useCheckUserRight();
  const canEditReady = checkUserRight('rundown', 'edit-ready');

  const handleAssignment = updatedAssignees => {
    onRundownHostUpdate(updatedAssignees);
  };

  return (
    <div className={classes.toolbar}>
      <span>
        <Typography className={classes.date}>
          {formatDateToLocaleString(selectedDate, true)}
        </Typography>
      </span>
      <span>
        <div className={classes.toggleButtons}>
          {toolbarButtons.map(toggle => (
            <span key={toggle.id}>
              <ToggleButton
                onClick={() => {
                  dispatch({
                    type: 'setToolbarButtons',
                    payload: toolbarButtons.map(toggleButton =>
                      toggle.id === toggleButton.id
                        ? { ...toggleButton, selected: !toggle.selected }
                        : { ...toggleButton },
                    ),
                  });
                }}
                rundown
                selected={toggle.selected}
              >
                {findButtonLabel(toggle.id)}
              </ToggleButton>
            </span>
          ))}
        </div>
      </span>
      <span className={classes.dropdown}>
        <HostsView onHostUpdate={handleAssignment} {...{ host, isRundownExists }} />
        {isRundownExists && (
          <EllipsisDropdown
            {...{
              isInitializeState,
              mosState,
              preparingState,
              onRundownStateChange,
              onPreparingStateChange,
              onEditMasterClick,
              onDownloadClick,
            }}
            disableStateChange={!canEditReady}
            onChangeState={batchUpdateRundownInstanceState}
          />
        )}
      </span>
    </div>
  );
};

RundownToolbar.propTypes = {
  /** Boolean indicator for Ready to air switch */
  isReadyToAir: PropTypes.bool,
  /** Function that is called when edit master rundown is clicked */
  onEditMasterClick: PropTypes.func,
  /** The selected date */
  selectedDate: PropTypes.string,
  /** callback that updates mState of all instances of rundown's mOrder */
  batchUpdateRundownInstanceState: PropTypes.func,
};

RundownToolbar.defaultProps = {
  isReadyToAir: false,
  onEditMasterClick: () => {},
  selectedDate: '',
  batchUpdateRundownInstanceState: () => {},
};

export default RundownToolbar;
