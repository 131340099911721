import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, Typography } from '@material-ui/core';
import Avatar from 'components/avatar/Avatar';
import { distanceInWordsToNow } from 'date-fns';
import capitalize from 'utils/capitalize';
import { notifications } from 'assets/icons/iconSelector';
import memberTypes from 'graphql/memberTypes';
import useStyles from './notificationCard-styles';

const NotificationCard = props => {
  const {
    avatar,
    time,
    updatedBy,
    message,
    messageType,
    messageTypeText,
    itemId,
    itemTitle,
    itemType,
    openStory,
  } = props;

  const classes = useStyles();

  const updated = capitalize(distanceInWordsToNow(time || new Date(), { addSuffix: true }));

  const handleOpen = () => {
    if (itemId) {
      const page = itemType === memberTypes.PITCH ? memberTypes.PITCH : memberTypes.STORY;
      openStory(itemTitle, itemId, page);
    }
  };

  return (
    <ListItem classes={{ root: classes.root }} button onClick={handleOpen}>
      <div className={classes.top}>
        <div className={classes.user}>
          <div className={classes.avatarContainer}>
            <Avatar
              size={30}
              className={classes.avatar}
              variant="People"
              src={avatar || 'default'}
            />
          </div>
          <Typography className={classes.username}>{updatedBy}</Typography>
        </div>
        <Typography className={classes.updated}>{updated}</Typography>
      </div>
      <div className={classes.typeTextContainer}>
        <div className={classes.messageType}>
          <div>
            <img
              width="14"
              height="14"
              viewBox="0 0 14 14"
              alt="todo"
              src={notifications[messageType]}
            />
          </div>
          <Typography className={classes.typeText}>{messageTypeText}</Typography>
        </div>
      </div>

      <Typography className={classes.message}>{message}</Typography>
    </ListItem>
  );
};

NotificationCard.propTypes = {
  avatar: PropTypes.string,
  time: PropTypes.string,
  updatedBy: PropTypes.string,
  message: PropTypes.arrayOf(PropTypes.string),
  messageType: PropTypes.string,
  messageTypeText: PropTypes.string,
  itemId: PropTypes.string,
  itemTitle: PropTypes.string,
  itemType: PropTypes.string,
  openStory: PropTypes.func,
};

NotificationCard.defaultProps = {
  avatar: '',
  time: new Date().toISOString(),
  updatedBy: '',
  message: [''],
  messageType: 'share',
  messageTypeText: '',
  itemId: null,
  itemTitle: '',
  itemType: '',
  openStory: () => {},
};

export default NotificationCard;
