import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  primary: {
    ...theme.typography.dina.b2,
    color: theme.palette.dina.highEmphasis,
  },
  listItem: {
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.dina.outlineButtonOnHover,
    },
    maxHeight: '48px',
  },
}));

export default useStyles;
