import { isBefore, startOfDay } from 'date-fns';

/**
 * Finds if the given date is before today considering only date not time
 *
 * @param {String} date a date string to be evaluated
 * @returns {Boolean} is the date is before today
 */

const isBeforeToday = date =>
  isBefore(startOfDay(date), startOfDay(new Date()));

export default isBeforeToday;
