import React, { createContext, useState } from 'react';

export const SidebarDatePickerContext = createContext();

const SidebarDatePickerProvider = ({ children }) => {
  const [selectedDates, setSelectedDates] = useState([new Date(Date.now())]);
  const { 0: sDate, length: l, [l - 1]: eDate } = selectedDates;
  const startDate = new Date(sDate);
  const endDate = new Date(eDate);
  const ignoreStartDate = l < 2;
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 500);

  return (
    <SidebarDatePickerContext.Provider
      value={[{startDate, endDate, ignoreStartDate}, setSelectedDates]}
    >
      {children}
    </SidebarDatePickerContext.Provider>
  );
};

export { SidebarDatePickerProvider };
