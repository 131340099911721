import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Leaf = ({ attributes, children, leaf }) => {
  const classes = useStyles({ ...leaf });

  return (
    <span className={classes.root} {...attributes}>
      {children}
    </span>
  );
};

Leaf.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
};

Leaf.defaultProps = {
  attributes: {},
  children: null,
};

export default memo(Leaf);
