import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    border: `1px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
      '& $button': {
        opacity: 1,
      },
    },
  },
  avatarDiv: {
    width: 24,
    height: 24,
    marginLeft: '8px',
  },

  chipLabel: {
    ...theme.typography.dina.chipLabel,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: '8px',
    padding: 0,
    width: 'calc(100% - 64px)',
  },
  noAvatar: {
    width: 'calc(100% - 32px)',
  },
  closeIcon: {
    '&:hover': {
      '& path': {
        fillOpacity: 1,
      },
    },
  },
  button: {
    padding: 6,
    marginRight: 6,
    opacity: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
