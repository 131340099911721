/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropTarget, DragSource } from 'react-dnd';
import Calendar from 'components/calendar';
import dndTypes from 'utils/dndTypes';
import useHover from 'hooks/useHover';
import memberTypes from 'graphql/memberTypes';
import Popper from 'components/shared/popper';
import Dialog from 'components/dialog';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import ConfirmDialog from 'components/confirmDialog';
import StoryCard from 'components/storyCard';
import { ListItem, Slide, ClickAwayListener } from '@material-ui/core';
import useStyles from './story-styles';
import SubMenu from './subMenu';

const storySource = {
  beginDrag(props) {
    return props;
  },
};

const storyTarget = {
  drop(props, monitor) {
    props.handleDrop(monitor.getItem().id, props.id, monitor.getItem().type);
  },
};

const Story = ({
  title,
  updatedAt,
  publishingAt,
  image,
  onClick,
  onDoubleClick,
  selected,
  hovered,
  assignedUsers,
  setShowStorybox,
  groupType,
  archived,
  bookmarked,
  joined,
  subMenuActionHandler,
  connectDropTarget,
  connectDragSource,
  onUpdatePublishDate,
  anchorEl,
  isPitch,
}) => {
  const classes = useStyles({ hovered, isPitch });
  const [hoverRef, isHovered] = useHover();
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [handleClick, handleDoubleClick] = useMouseClickEvents(onClick, onDoubleClick);
  const [popAnchorEl, setPopAnchorEl] = useState(null);
  const closeDialog = () => setDialog(null);
  const openDialog = btn => setDialog(btn);

  const subMenuClickHandler = btn => {
    closeSubMenu();
    if (btn === 'archive' || btn === 'join') {
      openDialog(btn);
    } else {
      subMenuActionHandler(btn);
    }
  };

  const onMoreClick = () => {
    setSubMenuOpen(true);
  };

  const closeSubMenu = () => setSubMenuOpen(false);

  const confirmDialog = () => {
    closeDialog();
    subMenuActionHandler(dialog);
  };

  const handleClose = () => {
    setPopAnchorEl(null);
  };

  const handleScheduleClick = event => {
    setPopAnchorEl(event.currentTarget);
  };

  const handleChange = newTime => {
    onUpdatePublishDate(newTime.toISOString());
    handleClose();
  };

  const handleUnschedule = () => {
    onUpdatePublishDate();
    handleClose();
  };

  const DialogContent = () => {
    if (dialog === 'archive') {
      return groupType === memberTypes.PITCH ? (
        <Dialog open={!!dialog} onClose={closeDialog}>
          <ConfirmDialog
            onCancel={closeDialog}
            onConfirm={confirmDialog}
            title="Delete Pitch?"
            confirmButtonLabel="Delete"
            confirmButtonType="danger"
            info={`Are you sure you want to delete "${title}".?`}
          />
        </Dialog>
      ) : (
        <Dialog open={!!dialog} onClose={closeDialog}>
          <ConfirmDialog
            onCancel={closeDialog}
            onConfirm={archived ? closeDialog : confirmDialog}
            title={`${archived ? 'Un-archive' : 'Archive'} Story?`}
            confirmButtonLabel={archived ? 'Ok' : 'Archive'}
            confirmButtonType={archived ? 'significant' : 'danger'}
            info={
              archived
                ? `Right now it is not possible to un-archive an archived story. This feature will be added soon.`
                : `Story: "${title}" will be archived. Are you sure you want to archive the story?`
            }
          />
        </Dialog>
      );
    }
    if (dialog === 'join') {
      return (
        <Dialog open={!!dialog} onClose={closeDialog}>
          <ConfirmDialog
            onCancel={closeDialog}
            onConfirm={confirmDialog}
            title={`${joined ? 'Leave' : 'Join'} Story?`}
            confirmButtonLabel={joined ? 'Leave' : 'Join'}
            confirmButtonType={joined ? 'danger' : 'significant'}
            info={`Are you sure you want to ${joined ? 'leave' : 'join'} the story?`}
          />
        </Dialog>
      );
    }
    return <></>;
  };

  return (
    connectDragSource &&
    connectDropTarget &&
    connectDragSource(
      connectDropTarget(
        <div className={classes.focusDiv}>
          <ListItem
            button
            selected={selected}
            className={`${(anchorEl || popAnchorEl) && classes.popoverItem}`}
            classes={{
              root: classes.listItem,
              selected: classes.selected,
            }}
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            ref={hoverRef}
            disableGutters
          >
            <StoryCard
              title={title}
              image={image}
              selected={selected}
              isPitch={isPitch}
              isHovered={isHovered}
              archived={archived}
              bookmarked={bookmarked}
              timingInfo={updatedAt}
              publishingAt={publishingAt}
              assignedUsers={assignedUsers}
              onMoreClick={onMoreClick}
              onArrowClick={setShowStorybox}
              onBookmarkClick={subMenuClickHandler}
              onScheduleClick={handleScheduleClick}
            />
            <Slide
              in={isSubMenuOpen}
              timeout={{ enter: 250, exit: 250 }}
              mountOnEnter
              unmountOnExit
              direction="left"
            >
              <div className={classes.subMenu} onMouseLeave={closeSubMenu}>
                <SubMenu
                  groupType={groupType}
                  archived={archived}
                  bookmarked={bookmarked}
                  joined={joined}
                  clickHandler={subMenuClickHandler}
                />
              </div>
            </Slide>
          </ListItem>
          <Popper placement="right-end" anchorEl={popAnchorEl}>
            <ClickAwayListener onClickAway={handleClose}>
              <div className={classes.calendarContainer}>
                <Calendar
                  selectedDate={publishingAt}
                  changeSelectedDate={handleChange}
                  onUnschedule={handleUnschedule}
                />
              </div>
            </ClickAwayListener>
          </Popper>
          <DialogContent />
        </div>,
      ),
    )
  );
};

Story.propTypes = {
  /** Story title */
  title: PropTypes.string.isRequired,
  /** Url for thumbnail */
  image: PropTypes.string,
  /** function for setting preview story */
  setPreviewStory: PropTypes.func,
  /** function when sub menu item is clicked */
  subMenuClickHandler: PropTypes.func,
  /** bookmarked */
  bookmarked: PropTypes.bool,
};

Story.defaultProps = {
  image: '',
  setPreviewStory: () => {},
  subMenuClickHandler: () => {},
  bookmarked: false,
};

export default DropTarget(dndTypes.MEMBER, storyTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  hovered: monitor.isOver(),
  contact: monitor.getItem(),
}))(
  DragSource(
    props => props.dropTargetType,
    storySource,
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(Story),
);
