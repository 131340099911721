import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { differenceInCalendarDays, addDays } from 'date-fns';
import { DragDropContext } from 'react-beautiful-dnd';
import UPDATE_STORY from 'graphql/mutations/updateStoryPublishingDate';
import WeekView from './week-view';

const WeekActionContainer = props => {
  const { stories, startDate, endDate, time, ...rest } = props;
   const [updateStory] = useMutation(UPDATE_STORY);

  const getNewPublishingDate = (oldDate, newDate) =>
    addDays(oldDate, differenceInCalendarDays(newDate, new Date(oldDate)));

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId) return;

    const draggingStory = stories[source.droppableId].filter(story => story.mId === draggableId)[0];

    stories[source.droppableId].splice(source.index, 1);
    stories[destination.droppableId].splice(destination.index, 0, draggingStory);

    updateStory({
      variables: {
        input: {
          mId: draggableId,
          mPublishingAt: getNewPublishingDate(
            draggingStory.mPublishingAt,
            destination.droppableId,
          ).toISOString(),
        },
      },
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <WeekView {...{ stories, ...rest }} />
    </DragDropContext>
  );
};

WeekActionContainer.propTypes = {
  /** The stories that should be shown */
  stories: PropTypes.objectOf(PropTypes.array),
  /** The start date */
  startDate: PropTypes.string,
  /** The end date */
  endDate: PropTypes.string,
};

WeekActionContainer.defaultProps = {
  startDate: '',
  endDate: '',
  stories: null,
};

export default WeekActionContainer;
