import React, { useState, useEffect } from 'react';
import Assignees from 'components/avatarGroup/assignees';
import Dialog from 'components/dialog';
import Avatar from 'components/avatar';
import AddMember from 'components/addMemberDialog';
import useStyles from './hosts-style';

const HostsView = props => {
  const { host, onHostUpdate, isRundownExists } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [currentHost, setCurrentHost] = useState(host);

  const openDialog = () => (isRundownExists ? setOpen(true) : null);
  const closeDialog = () => setOpen(false);

  useEffect(() => {
    setCurrentHost(host);
  }, [host]);

  const handleAssignment = updatedHost => {
    closeDialog();
    setCurrentHost(updatedHost[0]);
    onHostUpdate(updatedHost[0]);
  };

  return (
    <div className={classes.hostContainter}>
      <div className={classes.host}>
        <span className={classes.hostLabel}>Host</span>
        <span className={classes.hostName}>
          {currentHost && currentHost.mTitle ? currentHost.mTitle : '-'}
        </span>
      </div>
      <div>
        {currentHost && currentHost.mAvatarUrl && (
          <div className={classes.hostAvatar}>
            <Avatar src={currentHost.mAvatarUrl} size={30} />
          </div>
        )}
      </div>
      <div className={classes.addMorePeople}>
        <Assignees showEmptyPlaceholder size={30} onClick={openDialog} />

        <Dialog {...{ open }} onClose={closeDialog}>
          <AddMember
            members={currentHost ? [currentHost] : []}
            variant="Host"
            dialogTitle="Host"
            showItems
            onCancel={closeDialog}
            onOk={handleAssignment}
          />
        </Dialog>
      </div>
    </div>
  );
};

export default HostsView;
