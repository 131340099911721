import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import InputField from 'components/inputField/InputField';
import useStyles from './LoginFormStyle';

const PasswordReset = props => {
  const defaultpasswordDescription = 'Type your Password here...';
  const severityMandatory = 'mandatory';
  const severityError = 'error';
  const classes = useStyles();
  const { passwordError, passwordErrorText, onSubmit } = props;
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [passwordDescription, setPasswordDescription] = useState(
    defaultpasswordDescription
  );
  const [passwordSeverity, setPasswordSeverity] = useState(severityMandatory);

  const setPasswordSeverityWithDescription = (severity, description) => {
    setPasswordSeverity(severity);
    setPasswordDescription(description);
  };

  useEffect(() => {
    if (passwordError) {
      setPasswordSeverityWithDescription(severityError, passwordErrorText);
    } else {
      setPasswordSeverityWithDescription(
        severityMandatory,
        defaultpasswordDescription
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordError]);

  const handleSubmit = async event => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(firstName, lastName, password);
    }
  };

  const onPasswordChange = value => {
    if (passwordSeverity === severityError) {
      setPasswordSeverityWithDescription(
        severityMandatory,
        defaultpasswordDescription
      );
    }
    setPassword(value);
  };

  return (
    <React.Fragment>
      <div>
        <Typography variant="h6" className={classes.resetText}>
          Please Reset your password to complete Sign up
        </Typography>
        <form onSubmit={handleSubmit} data-testid="passwordReset">
          <InputField
            label="First name"
            description="Type your first name here..."
            severity={severityMandatory}
            value={firstName}
            onChange={value => setFirstName(value)}
          />
          <div className={classes.inputPadding} />

          <InputField
            label="Last name"
            description="Type your last name here..."
            severity={severityMandatory}
            value={lastName}
            onChange={value => setLastName(value)}
          />
          <div className={classes.inputPadding} />

          <InputField
            label="Password"
            type="password"
            description={passwordDescription}
            severity={passwordSeverity}
            value={password}
            onChange={value => onPasswordChange(value)}
          />
          <Button
            type="submit"
            size="large"
            data-testid="btnSubmit"
            fullWidth
            disabled={!(firstName && lastName && password)}
            classes={{
              root: classes.buttonRoot,
              disabled: classes.buttonDisabled,
            }}
          >
            SUBMIT
          </Button>
        </form>
      </div>
    </React.Fragment>
  );
};

PasswordReset.propTypes = {
  /* To set error status for password. Values: true/false */
  passwordError: PropTypes.bool,

  /* Error message for password */
  passwordErrorText: PropTypes.string,

  /*  onSubmit function */
  onSubmit: PropTypes.func,
};

PasswordReset.defaultProps = {
  passwordError: false,
  passwordErrorText: '',
  onSubmit: () => {},
};

export default PasswordReset;
