import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/tooltip';
import { Avatar } from '@material-ui/core';

/** Wrapper over material avatar that sets default src in case of provided src is broken */
const MaterialAvatar = ({ children, src, title, defaultSrc, classes }) => {
  return (
    <Tooltip title={title}>
      <Avatar
        classes={{ root: classes }}
        imgProps={{
          onError: e => {
            e.target.src = defaultSrc;
          },
        }}
        {...{ src }}
      >
        {children}
      </Avatar>
    </Tooltip>
  );
};

MaterialAvatar.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  defaultSrc: PropTypes.string,
  title: PropTypes.string,
};

MaterialAvatar.defaultProps = {
  children: null,
  src: '',
  defaultSrc: null,
  title: '',
};

export default MaterialAvatar;
