import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    paddingLeft: 8,
    display: 'grid',
    gridGap: 8,
    gridTemplateColumns: 'repeat(auto-fill, 24px)',
    height: 'calc(100% - 24px)',
  },
}));

export default useStyles;
