import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  inputHeadline: {
    ...theme.typography.dina.overline,
    width: '287px',
    marginLeft: '18px',
    paddingTop: '8px',
  },
  textAreaDiv: {
    width: '295px',
    marginLeft: '16px',
  },
}));

export default useStyles;
