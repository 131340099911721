import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import styleEnum from '../../../utils/styleEnum';

const useStyles = makeStyles(theme => ({
  inputDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    ...theme.typography.dina.listItemLabel,
    color: ({ style }) =>
      style === styleEnum.HIGHLIGHT
        ? theme.palette.dina.highEmphasis
        : theme.palette.dina.mediumEmphasis,
    height: 28,
    paddingLeft: '4px',
    backgroundColor: 'transparent',
    cursor: 'move',
    border: '1px solid transparent',
    '&:focus': {
      outline: 'blue',
    },
  },
  verticalDivider: {
    height: 31,
    width: '4px',
    backgroundColor: 'transparent',
  },
}));

const InputView = ({ id, value, width, style, updateInputField, ...rest }) => {
  const classes = useStyles({ style });

  return (
    <div className={classes.inputDiv}>
      <input id={id} readOnly className={classes.input} value={value} style={{ width }} />
      <div className={classes.verticalDivider} />
    </div>
  );
};

InputView.propTypes = {
  value: PropTypes.string,
  width: PropTypes.number.isRequired,
};

InputView.defaultProps = {
  value: '',
};

export default InputView;
