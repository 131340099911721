import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'absolute',
  },
  dialogPaper: {
    margin: '0px',
    backgroundColor: theme.palette.dina.surfaceCard,
    borderRadius: '8px',
    overflow: 'unset',
  },

  dialogScrollPaper: {
    display: 'flex',
  },
  paperScrollPaper: {
    maxHeight: 'calc(100% - 20px)',
    maxWidth: '90vh',
  },
  backdropRoot: {
    position: 'absolute',
  },
}));

export default useStyles;
