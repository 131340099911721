/* eslint-disable max-len */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import fallbackImage from 'assets/images/dumbo.jpg';
import { Typography, Fade } from '@material-ui/core';
import Assignees from 'components/avatarGroup/assignees';
import ICONS from 'assets/icons/icons';
import { ReactComponent as ArrowRightSmall } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { ReactComponent as ArchivedSmall } from 'assets/icons/systemicons/archived_small.svg';
import StoryMarketOn from 'assets/icons/systemicons/storymarket_small_on.svg';
import IconButton from 'components/buttons/iconButton';
import ActionButtons from './components/iconButtons';
import useStyles from './storyCard-styles';

const StoryCard = ({
  title,
  image,
  selected,
  isPitch,
  timingInfo,
  archived,
  bookmarked,
  publishingAt,
  assignedUsers,
  onMoreClick,
  isHovered,
  onArrowClick,
  onBookmarkClick,
  onScheduleClick,
}) => {
  const classes = useStyles();

  const memoizedAssignedUser = useMemo(() => <Assignees size={20} assignees={assignedUsers} />, [
    assignedUsers,
  ]);

  const handleMoreClick = event => {
    event.stopPropagation();
    onMoreClick();
  };

  return (
    <div className={classes.sidebar}>
      <ActionButtons
        {...{ bookmarked, publishingAt, isHovered, selected, onBookmarkClick, onScheduleClick }}
      />
      {!isPitch &&
        (archived ? (
          <div className={classes.imageContainer}>
            <img src={image} className={classes.image} alt="thumbnail" />
            <div className={classes.archivedOverlay}>
              <ArchivedSmall />
            </div>
          </div>
        ) : (
          <img src={image} className={classes.image} alt="thumbnail" />
        ))}
      {isPitch && (
        <div className={classes.pitchDiv}>
          <div className={classes.pitchIcon}>
            <img src={StoryMarketOn} alt="pitch" />
          </div>
          <img src={image} className={classes.pitchThumbnail} alt="pitchThumbnail" />
        </div>
      )}

      <div className={classes.titleDiv}>
        <div className={classes.title}>{title}</div>
      </div>
      <div className={classes.secondary}>
        <div
          className={classes.timingDiv}
          role="button"
          tabIndex="0"
          onClick={onArrowClick}
          onKeyDown={() => {}}
        >
          <Typography className={classes.timingText}>{timingInfo}</Typography>
          <ArrowRightSmall />
        </div>
        <div className={classes.avatarWrapper}>
          <div className={isHovered ? classes.hoveredDiv : classes.initialDiv}>
            {isHovered && memoizedAssignedUser}
          </div>
          {onMoreClick && (
            <Fade in={isHovered} timeout={{ enter: 300, exit: 100 }} mountOnEnter unmountOnExit>
              <IconButton
                className={classes.iconButton}
                title="More options"
                icon={ICONS.MORE_VERTICAL}
                width={8}
                height={16}
                onClick={handleMoreClick}
              />
            </Fade>
          )}
        </div>
      </div>
    </div>
  );
};

StoryCard.propTypes = {
  /** Story title */
  title: PropTypes.string.isRequired,
  /** the associated user (owner) */
  image: PropTypes.string,
};

StoryCard.defaultProps = {
  image: fallbackImage,
};

export default StoryCard;
