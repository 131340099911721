import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    borderRadius: '25%',
    cursor: 'pointer',
  },
}));

export default useStyles;
