// Dina State Management

import React from 'react';
import { SidebarProvider } from './sidebarState';
import { ContentStoreProvider } from './tabs';
import { FeedsProvider } from './feeds';
import { PlanningProvider } from './plans';
import { RundownProvider } from './rundown';
import { EditorProvider } from './editor';
import { UsersProvider } from './users';
import { MembersProvider } from './members';
import { DatePickerProvider } from './datePicker';
import { SidebarDatePickerProvider } from './sidebarDatePicker';
import { SidebarSubTabProvider } from './sidebarSubTab';

function ProviderComposer({ contexts, children }) {
  return contexts.reduceRight(
    (kids, parent) =>
      React.cloneElement(parent, {
        children: kids,
      }),
    children,
  );
}

function ContextProvider({ children }) {
  return (
    <ProviderComposer
      contexts={[
        <SidebarDatePickerProvider />,
        <SidebarProvider />,
        <ContentStoreProvider />,
        <FeedsProvider />,
        <PlanningProvider />,
        <RundownProvider />,
        <EditorProvider />,
        <UsersProvider />,
        <MembersProvider />,
        <DatePickerProvider />,
        <SidebarSubTabProvider />,
      ]}
    >
      {children}
    </ProviderComposer>
  );
}

// eslint-disable-next-line import/prefer-default-export
export { ContextProvider };
