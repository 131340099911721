/* eslint-disable max-len */
import React, { createContext, useState, useContext, useEffect } from 'react';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'graphql/subscriptions/notifyMemberUpdate';
import UserCtx from 'contexts/UserContext';
import { SidebarDatePickerContext } from 'globalState/sidebarDatePicker';
import createMessage from 'graphql/utils/cache/notification/createMesasge';
import updateCacheWithNotification from 'graphql/utils/cache/notification/updateCacheWithNotification';
import useApolloSubscription from 'hooks/useApolloSubscription';

export const NotificationContext = createContext();

const NotificationStore = ({ children }) => {
  const { mId } = useContext(UserCtx);
  const [selectedDates] = useContext(SidebarDatePickerContext);

  const [notification, setNotification] = useState([{ message: '' }]);
  const [message, setMessage] = useState([]);

  const onSubscriptionData = ({ client, subscriptionData }) => {
    console.log("subscriptionData notification context",subscriptionData);
    const  notifyUser  = subscriptionData.data.notifyMemberUpdateSubscription;
    const { mUpdatedById } = notifyUser;
    const updatedBy = client.cache.data.data[mUpdatedById];
    const messageFromSubscription = JSON.parse(notifyUser.message);
    messageFromSubscription.loggedInUserId = mId;
    messageFromSubscription.selectedDates=selectedDates;
    notifyUser.message=messageFromSubscription;
    // Update cache before sending notification message.
    updateCacheWithNotification(client, notifyUser);
    const messageOfNotification = createMessage(notifyUser);

    if (!messageOfNotification) return;
    setNotification({
      message: messageOfNotification.messages,
      messageType: messageOfNotification.messageType,
      messageTypeText: messageOfNotification.messageTypeString,
      updatedBy: updatedBy ? updatedBy.mTitle : 'some user',
      avatar: updatedBy ? updatedBy.mAvatarUrl : ' ',
      time: notifyUser.mUpdatedAt,
      itemId: messageOfNotification.itemId,
      itemType: messageOfNotification.itemType,
      itemTitle: messageOfNotification.itemTitle,
    });
  };

  const [subscribe, unsubscribe] = useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: { mIdSubscribed: mId },
    onSubscriptionData,
  });

  useEffect(() => {
    subscribe();
    return () => {
      unsubscribe();
    }
  }, []);


  useEffect(() => {
    if (message.length > 9) {
      message.shift();
    }
    if (notification.message !== undefined && notification.message.length > 0) {
      setMessage(message.concat(notification));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  // eslint-disable-next-line no-console
  return (
    <NotificationContext.Provider value={{ message }}>
      {children}
    </NotificationContext.Provider>
  );
};
export default NotificationStore;
