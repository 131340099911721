import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/popover/Popover';
import Favorites from './MenuContent/Favorites';
import SingleInstance from './MenuContent/SingleInstance';

const CreateStoryInstanceMenu = props => {
  const { onClose, position, anchorEl, data, ...other } = props;
  return (
    <Popover
      onClose={onClose}
      position={position}
      anchorEl={anchorEl}
      {...other}
    >
      {!(Object.keys(data).length === 0 && data.constructor === Object) && (
        <>
          {/* <Favorites
            favoriteItems={[]}
            onClose={platformInfo => onClose(platformInfo)}
          /> */}
          <SingleInstance
            instanceItems={data}
            onClose={platformInfo => onClose(platformInfo)}
          />
        </>
      )}
    </Popover>
  );
};

CreateStoryInstanceMenu.propTypes = {
  /** Content that will be shown on the Menu */
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Default DOM element (object or function) */
  anchorEl: PropTypes.objectOf(PropTypes.object),
  /** Postion of the Menu (top or right)  */
  position: PropTypes.string,
  /** onClose function: enables the menu to close */
  onClose: PropTypes.func,
};

CreateStoryInstanceMenu.defaultProps = {
  data: {},
  anchorEl: null,
  position: 'top',
  onClose: () => {},
};

export default CreateStoryInstanceMenu;
