import gql from 'graphql-tag';

export default gql`
  query GetUser($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mTitle
      mAvatarUrl
      mBookmarks {
        bookmarkedId
        bookmarkedType
      }
    }
  }
`;
