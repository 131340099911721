import React, { createContext, useReducer, useEffect, useContext } from 'react';
import reducer from './reducers/sidebarReducer';

export const SidebarContext = createContext();

const SAVED_STATE = JSON.parse(window.localStorage.getItem('sidebars'));
const DEFAULT_STATE = {
  isLeftHidden: false,
  isRightHidden: false,
  isRightExpanded: false,
  leftSelection: 'user',
  rightSelection: 'contacts',
  contactTab: 0,
  openGroups: {},
};

export const SidebarProvider = ({ children }) => {
  const initialState = SAVED_STATE || DEFAULT_STATE;

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    window.localStorage.setItem(
      'sidebars',
      JSON.stringify({
        ...state,
      }),
    );
  }, [state]);

  return <SidebarContext.Provider value={[state, dispatch]}>{children}</SidebarContext.Provider>;
};

export const useSidebarContext = () => useContext(SidebarContext);
