import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/tabs';
import { IconButton } from '@material-ui/core';
import Divider from 'components/divider';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as PortraitIcon } from 'assets/icons/systemicons/mobile_portait.svg';
import { ReactComponent as LandscapeIcon } from 'assets/icons/systemicons/mobile_landscape.svg';
import { ReactComponent as TabletIcon } from 'assets/icons/systemicons/landscape_off.svg';
import { ReactComponent as DesktopIcon } from 'assets/icons/systemicons/desktop.svg';
import useStyles from './preview-styles';

const Preview = ({ id, title, closeDialog, src }) => {
  const classes = useStyles({ hasPreview: Boolean(src) });

  /** default tab selected: desktop */
  const [tabIndex, setTabIndex] = useState(3);

  const tabs = [
    {
      icon: <PortraitIcon className={classes[tabIndex === 0 ? 'iconEmphasis' : null]} />,
      title: 'Mobile, Portrait',
    },
    {
      icon: <LandscapeIcon className={classes[tabIndex === 1 ? 'iconEmphasis' : null]} />,
      title: 'Mobile, Landscape',
    },
    {
      icon: <TabletIcon className={classes[tabIndex === 2 ? 'iconEmphasis' : null]} />,
      title: 'Tablet',
    },
    {
      icon: <DesktopIcon className={classes[tabIndex === 3 ? 'iconEmphasis' : null]} />,
      title: 'Desktop  ',
    },
  ];

  const onTabChange = (value, index) => {
    setTabIndex(index);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>{`Preview of ${title}`}</div>
        <IconButton size="small" onClick={closeDialog}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      </div>
      <div className={classes.tabs}>
        <Tabs variant="icon" {...{ tabs, tabIndex, onTabChange }} />
      </div>
      <div className={classes.iFrameContainer}>
        <div className={classes[`iFrame${tabIndex}`]}>
          <iframe
            id={id}
            src={src}
            title={title}
            className={classes.iFrame}
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          />
        </div>
      </div>
      <Divider />
      <div className={classes.footer}>
        <div role="presentation" onClick={closeDialog} className={classes.button}>
          Close
        </div>
      </div>
    </div>
  );
};

Preview.propTypes = {
  /** Title of the preview dialog */
  title: PropTypes.string,
  /** Callback to close preview dialog */
  closeDialog: PropTypes.func,
  /** Source url for preview component */
  src: PropTypes.string,
  /** Id of the instance */
  id: PropTypes.string,
};

Preview.defaultProps = {
  title: '',
  closeDialog: () => {},
  src: '',
  id: '',
};

export default Preview;
