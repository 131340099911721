import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/popover/Popover';
import { ReactComponent as Photo } from 'assets/icons/systemicons/photo_off.svg';
import { FormControlLabel } from '@material-ui/core';
import UploadMediaInput from 'screens/story/utils/coverPhoto/uploadMediaInput';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import fallBackImage from 'assets/images/default/defaultCoverPhoto.png';
import defaultThumb from 'assets/images/default/defaultThumbnail.png';
import Avatar, { avatarVariants } from 'components/avatar/Avatar';
import InputField from 'components/inputField/InputField';
import Switch from 'components/switch';
import useStyles from './styles';

const MasterHeaderView = props => {
  const {
    image,
    masterTitle,
    masterStart,
    masterDuration,
    onMasterTitleChange,
    onMasterStartChange,
    onMasterDurationChange,
    mid,
    mThumbnailKey,
    mCoverPhotoKey,
    onCoverPhotoSelected,
    onToggleDailyExclusive,
    masterDailyExclusive,
  } = props;

  const classes = useStyles(image || fallBackImage)();
  const [anchorEl, setAnchorEl] = useState(null);

  const uploadComplete = val => {
    if (val === true) {
      setAnchorEl(null);
    }
  };

  const listItems = [
    <ListItem
      text="Upload cover photo"
      excludeDivider
      iconChild={<Photo />}
      key="photo"
      firstChild={
        <UploadMediaInput
          {...props}
          complete={val => uploadComplete(val)}
          storyId={mid}
          coverPhotoKey={mCoverPhotoKey}
          thumbnailKey={mThumbnailKey}
          coverPhotoSelected={onCoverPhotoSelected}
          coverPhoto
        />
      }
    />,
  ];

  return (
    <div className={classes.headerContainer}>
      <div className={classes.content}>
        <div className={classes.avatarDiv}>
          <div
            className={classes.avatar}
            role="button"
            tabIndex="0"
            onClick={event => setAnchorEl(event.currentTarget)}
            onKeyDown={() => {}}
          >
            <Avatar variant={avatarVariants.category} src={image || defaultThumb} size={62} />
          </div>
          <div
            role="button"
            tabIndex="0"
            onClick={event => setAnchorEl(event.currentTarget)}
            className={classes.coverDiv}
            onKeyDown={() => {}}
          >
            Edit Cover Photo
          </div>
        </div>
        <Popover
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <div className={classes.option}>{listItems}</div>
        </Popover>

        <div className={classes.inputDiv}>
          <div className={classes.input}>
            <InputField
              label="Rundown Title"
              severity="editing"
              value={masterTitle}
              onChange={onMasterTitleChange}
            />
          </div>
          <div className={classes.input}>
            <InputField
              label="Start time"
              severity="editing"
              value={masterStart}
              onChange={onMasterStartChange}
            />
          </div>
          <div className={classes.input}>
            <InputField
              label="Duration"
              severity="editing"
              value={masterDuration}
              onChange={onMasterDurationChange}
            />
          </div>
          <div className={classes.input}>
            <FormControlLabel
            value="dailyExclusive"
            control={<Switch selected={masterDailyExclusive} onClick={onToggleDailyExclusive} />}
            label="Daily Exclusive"
            classes={{
              label: classes.dailyExclusiveSwitch,
            }}
          />
          </div>
        </div>
      </div>
    </div>
  );
};

MasterHeaderView.propTypes = {
  /** Master rundown title */
  masterTitle: PropTypes.string,
  /** Master rundown cover image */
  image: PropTypes.string,
  /** Master rundown start time */
  masterStart: PropTypes.string,
  /** Master rundown duration time */
  masterDuration: PropTypes.string,
  /** Title Change callback function */
  onMasterTitleChange: PropTypes.func,
  /** Start time change callback function */
  onMasterStartChange: PropTypes.func,
  /** Duration change callback function */
  onMasterDurationChange: PropTypes.func,
  /** Daily Exclusive toggle callback */
  onToggleDailyExclusive: PropTypes.func,
  /** Daily Exclusive value for Master rundown */
  masterDailyExclusive: PropTypes.bool,
};

MasterHeaderView.defaultProps = {
  masterTitle: '',
  image: fallBackImage,
  masterStart: '',
  masterDuration: '',
  onMasterTitleChange: () => {},
  onMasterStartChange: () => {},
  onMasterDurationChange: () => {},
  onToggleDailyExclusive: () => {},
  masterDailyExclusive: true,
};

export default MasterHeaderView;
