/* eslint-disable no-param-reassign */
import React, { useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import getTimeString from 'utils/getTimeString';
import Tooltip from 'components/tooltip';
// eslint-disable-next-line max-len
import ArrowDropDownUnboxedSrc from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet_unboxed.svg';
import SelectView from './components/select';
import useStyles from './styles';

const MemoizedTooltip = memo(Tooltip);

/** Returns concatenated meta values */
const concatenateMetaValues = metaData => {
  if (metaData.length === 0) return '';
  return metaData.reduce((mstr, mdata) => {
    if (!mdata.isVisible) return mstr;
    if (mstr) mstr += ', ';
    mstr += mdata.value;
    return mstr;
  }, '');
};

const getSubtitleString = (metaData, asset) => {
  let subTitle = concatenateMetaValues(metaData);
  if (asset) {
    if (subTitle) subTitle += ' | ';
    if (asset.mTitle) subTitle += asset.mTitle;
    else if (asset.title) subTitle += asset.title;
    if (asset.itemDuration) subTitle += ` | ${getTimeString(asset.itemDuration)}`;
  }
  return subTitle;
};

const splitTitleBySlash = title =>
  title
    .split('/')
    .slice(0, 3)
    .map(line => line.trim());

const TitleBox = ({ title, meta, asset, updateVariant, spec = [] }) => {
  const classes = useStyles();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const titles = useMemo(() => title && splitTitleBySlash(title), [title]);
  const subTitle = useMemo(() => getSubtitleString(meta, asset), [meta, asset]);

  const openSelectMenu = useCallback(() => setIsSelectOpen(true), []);
  const closeSelectMenu = useCallback(() => setIsSelectOpen(false), []);

  const handleSelection = useCallback(
    selectedValue => {
      const template = spec.find(temp => temp.variant === selectedValue);
      if (template) {
        const { variant, fields, name, description } = template;
        updateVariant(variant, fields, name, description);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateVariant],
  );

  return (
    <div className={classes.root} role="presentation" onClick={openSelectMenu}>
      <img className={classes.arrow} src={ArrowDropDownUnboxedSrc} alt="expand" />

      <div className={classes.titles}>
        {spec && spec.length > 0 && (
          <SelectView
            isOpen={isSelectOpen}
            items={spec}
            onChange={handleSelection}
            onClose={closeSelectMenu}
            onOpen={openSelectMenu}
            selectedValue={title}
          />
        )}

        {spec &&
          spec.length === 0 &&
          titles &&
          titles.length > 0 &&
          titles.map(line => (
            <MemoizedTooltip title={line} key={line}>
              <div className={classes.title}>{line}</div>
            </MemoizedTooltip>
          ))}

        <MemoizedTooltip title={subTitle}>
          <div className={classes.subtitle}>{subTitle}</div>
        </MemoizedTooltip>
      </div>
    </div>
  );
};

TitleBox.propTypes = {
  /** templateVariant to show as title */
  title: PropTypes.string,
  /** metaData to show as subtitle */
  meta: PropTypes.arrayOf(PropTypes.object),
  /** on Title box click callback function */
  updateVariant: PropTypes.func,
};

TitleBox.defaultProps = {
  title: 'VARIANT',
  meta: [],
  updateVariant: () => {},
};

export default memo(TitleBox);
