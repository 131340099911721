import gql from 'graphql-tag';

export default gql`
  subscription updateRundownHourViewSubscription($rundownPublishingDate: String) {
    updateRundownHourViewSubscription(rundownPublishingDate: $rundownPublishingDate) {
      mTitle
      mOrder
      mPreorder
      mId
      mRefId
      mState
      mPreparingState
      mPlannedDuration
      mPrePlannedDuration
      mPublishingAt
      version
      recurrence {
        duration
        startTime
        timeZone
        start
      }
      mMetaData {
        key
        value
      }
    }
  }
`;
