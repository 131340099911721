
const validateTime = input => {
  const hoursMinutesSecondsPattern = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/;
  const isValid = hoursMinutesSecondsPattern.test(input);
  return isValid;
};


/**
 * This function validates an input 
 * if it is in HH:mm:ss or HHmmss format & the time value is [00:00:00-23:59:59]
 * It returns validated HHmmss input in HH:mm:ss format
 * @param {string} input Input that will be validated.
 * @returns {string} Validated string in HH:mm:ss format
 */

const ValidateTimeInput = input => {
  const splittedInput = input.split(/:/);
  switch (splittedInput.length) {
    case 1: {
      const separated = input.match(/.{1,2}/g) || [];
      const constructedTimeInput = separated.join(':');
      return validateTime(constructedTimeInput) ? constructedTimeInput : null;
    }
    case 3: {
      const splittedInputWithLeadingZero = splittedInput.map(t => {
        return t.length === 1 ? `0${t}` : t;
      });
      const reconstructedInput = splittedInputWithLeadingZero.join(':');
      return validateTime(reconstructedInput) ? reconstructedInput : null;
    }
    default:
      return null;
  }
};

export default ValidateTimeInput;
