import { makeStyles } from '@material-ui/styles';

const useStyles = (width, height, size) =>
  makeStyles(theme => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      outlineColor: 'transparent',
    },
    button: {
      padding: '0',
      maxWidth: size || width,
      minWidth: size || width,
      maxHeight: size || height,
      minHeight: size || height,
      border: `0.5px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
      marginLeft: '4px',
    },
    buttonText: {
      ...theme.typography.dina.durationLabel,
      color: theme.palette.dina.mediumEmphasis,
    },
    avatarText: {
      ...theme.typography.dina.storyCardAvatarText,
    },
  }));

export default useStyles;
