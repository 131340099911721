import { useContext } from 'react';
import configCtx from 'contexts/configContext';

const useCheckUserRight = () => {
  const config = useContext(configCtx);
  const policy = config.userRights;

  const check = (resourceName, action) => {
    const resource = policy.find(r => r.resourceName === resourceName);
    if (!resource || !resource.permissions) return false;
    const { permissions } = resource;
    const permission = permissions.find(p => p.action === action);
    if (permission && permission.access && permission.access === 'allow') return true;
    return false;
  };

  return [check];
};

export default useCheckUserRight;
