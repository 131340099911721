import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    minHeight: '71px',
    justifyContent: 'flex-start',
    paddingLeft: '14px',
    paddingRight: '14px',
  },
  categoryListItem: {
    display: 'flex',
    maxHeight: '39px',
    justifyContent: 'flex-start',
    paddingLeft: '14px',
    paddingRight: '14px',
  },
  divider: {
    backgroundColor: theme.palette.dina.dividerLight,
    marginLeft: '72px',
  },
  activeButton: {
    backgroundColor: theme.palette.dina.onFocus,
  },

  categoryDivider: {
    backgroundColor: theme.palette.dina.dividerLight,
    marginLeft: '56px',
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
    '&:focus': {
      backgroundColor: theme.palette.dina.onFocus,
    },
  },
  nameDiv: {
    width: '215px',
    marginLeft: '10px',
  },
  nameLine: {
    ...theme.typography.dina.listItemLabel,
  },
  titleLine: {
    ...theme.typography.dina.chipLabel,
  },
  buttonRoot: {
    height: '24px',
    padding: '2px 1px',
    width: '90px',
  },
  buttonLabel: {
    fontSize: '13px',
    lineHeight: '15px',
    letterSpacing: '0.25px',
    fontWeight: 500,
    color: theme.palette.dina.mediumEmphasis,
    textTransform: 'none',
  },
  buttonOutlined: {
    border: `1px solid ${theme.palette.dina.buttonBorderMediumEmphasis}`,
  },
  iconButton: {},
}));

export default useStyles;
