import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    height: 'calc(100vh - 96px)',
    background: theme.palette.dina.surfaceAppBackgroundMain,
    marginRight: '48px',
  },
  centerContent: {
    background: theme.palette.dina.surfaceAppBackgroundMain,
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  thumb: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    zIndex: 1101,
  },
  center: {
    flexGrow: 1,
    height: '100%',
  },
  sideContent: {
    maxWidth: '424px',
    zIndex: 2,
    display: 'flex',
  },
  leftMargin: {
    marginLeft: '327px',
    transition: 'all .2s linear',
  },
  rightMarginClear: {
    marginRight: 0,
    transition: 'all .1s linear',
  },
  rightMargin: {
    marginRight: '327px',
    transition: 'all .2s linear',
  },
  rightMarginExp: {
    marginRight: window.innerWidth / 2,
    transition: 'all .3s linear',
  },
  leftMarginClear: {
    marginLeft: 0,
    transition: 'all .1s linear',
  },
}));

export default useStyles;
