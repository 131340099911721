import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '250px',
    backgroundImage: ({ image }) =>
      image
        ? `linear-gradient(180deg, rgba(50, 62, 77, 0) 0%, #425063 100%), url(${image})`
        : '',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    border: ({ hover }) =>
      hover === true ? `1px solid ${theme.palette.dina.statusWarning}` : 'none',
  },
}));

export default useStyles;
