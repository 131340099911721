import updateInstanceToStory from 
'graphql/utils/cache/notification/transaction/updateInstanceToStory';
import memberTypes from 'graphql/memberTypes';

const dispatchMessage = (client, item) => {
    console.log('updateCacheWithNotifications dispatchMessage', client, item);
    switch (item.mType) {
        case memberTypes.INSTANCE:
             updateInstanceToStory(client, item);
            break;
        case memberTypes.ARCHIVED_INSTANCE:
            updateInstanceToStory(client, {...item,crudAction:"REMOVE"});
            break;    
        default:
            break;
    }
};

const updateCacheWithTransaction = (
    client,
    item,
) => {
    dispatchMessage(client, item);
};

export default updateCacheWithTransaction;
