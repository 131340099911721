import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import returnState from 'screens/planning/components/status/utils/statusBoards/returnState';
import { publishingPoints as publishingPointIcons } from 'assets/icons/publishingPoints';
import Divider from 'components/divider';
import PublishSettings from 'components/publishSettings';
import FallbackImage from 'assets/images/default/defaultThumbnail.png';
import capitalize from 'utils/capitalize';
import variants from 'utils/instance/variants';
import publishingPoints from '../../utils/publishingPoints';
import DestinationIndicator from './components/destinationIndicator';
import LinearEllipsisMenu from './components/linearEllipsisMenu';
import GeneralEllipsisMenu from './components/generalEllipsisMenu';
import SchedulingButtons from './components/schedulingButtons';
import TitleBox from './components/titleBox';
import useStyles from './header-styles';

const HeaderView = ({
  backgroundImage,
  canCreateNewTemplate,
  canDeleteTemplate,
  canDeleteTemplateFolder,
  canSeeVersionHistory,
  containerRef,
  currentDestination,
  folders,
  instanceId,
  onBackgroundClick,
  onCreateDuplicate,
  onCreateFolder,
  onDeleteFolder,
  onDeleteInstance,
  onDeleteTemplate,
  onDownload,
  onForceUnlock,
  onMetadataSelect,
  onOpenStory,
  onPublishSettingsChange,
  onSaveTemplate,
  onSelectTemplate,
  onTitleUpdate,
  publishingPoint,
  publishingPointIcon,
  schedule,
  showMetadata,
  statusId,
  summarize,
  templates,
  title,
  variant,
  writeLock,
  lockedByUser,
  onDock,
  onClose,
  disableEdit,
  editorValue,
  canShowNewDesign,
  versions,
  currentVersionContent,
  refetchVersionList,
  refetchVersionContent,
  onRestoreVersion,
  checkVersionRestorability,
  isSavingContent,
  auditListLoading,
  versionContentLoading,
}) => {
  const state = returnState(statusId);
  const backgroundColor = state ? state.backgroundColor : null;
  const classes = useStyles({ backgroundColor });
  const [anchorEl, setAnchorEl] = useState(null);

  const openPublishingSettings = event => !disableEdit && setAnchorEl(event.currentTarget);

  const onOK = newPublishingSettings => {
    setAnchorEl(null);
    onPublishSettingsChange(newPublishingSettings);
  };

  const handleCancel = () => {
    setAnchorEl(null);
  };

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    onBackgroundClick();
  };

  const { publishingTime } = currentDestination;
  const datetime = schedule;
  const isDeleteEnalbled =
    variant === variants.LINEAR ? currentDestination.id !== null : schedule !== null;

  const memoizedLinearMenu = useMemo(() => {
    return (
      <LinearEllipsisMenu
        {...{
          folders,
          onSelectTemplate,
          onSaveTemplate,
          onDeleteTemplate,
          onCreateFolder,
          onDeleteFolder,
          onDownload,
          onMetadataSelect,
          onOpenStory,
          onCreateDuplicate,
          onForceUnlock,
          showMetadata,
          writeLock,
          lockedByUser,
          canCreateNewTemplate,
          canDeleteTemplate,
          canDeleteTemplateFolder,
          canSeeVersionHistory,
          onDeleteInstance,
          isDeleteEnalbled,
          disableEdit,
          title,
          editorValue,
          versions,
          currentVersionContent,
          refetchVersionList,
          refetchVersionContent,
          onRestoreVersion,
          checkVersionRestorability,
          isSavingContent,
          auditListLoading,
          versionContentLoading,
        }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canCreateNewTemplate,
    canDeleteTemplate,
    canDeleteTemplateFolder,
    disableEdit,
    folders,
    isDeleteEnalbled,
    showMetadata,
    writeLock,
    lockedByUser,
    versions,
    currentVersionContent,
    refetchVersionList,
    refetchVersionContent,
    onRestoreVersion,
    checkVersionRestorability,
    isSavingContent,
    auditListLoading,
    versionContentLoading,
  ]);

  return (
    <div className={classes.root}>
      <div role="presentation" className={classes.backgroundColor} />

      <div className={classes.container}>
        <div className={classes.titleBox}>
          <TitleBox
            instanceId={instanceId}
            initialTitle={title || '-'}
            onTitleUpdate={onTitleUpdate}
            {...{ onDock, onClose, disableEdit }}
          />
        </div>
        <div className={classes.scheduleDestination}>
          <div className={classes.avatar} role="presentation" onClick={handleClick}>
            <img
              src={backgroundImage || FallbackImage}
              alt="rundown-thumbnail"
              className={classes.thumbnail}
            />
            <img
              className={classes.publishingPoint}
              src={
                publishingPointIcons[publishingPointIcon]
                  ? publishingPointIcons[publishingPointIcon]
                  : publishingPointIcons.default
              }
              alt="publishing-point"
            />
          </div>
          <DestinationIndicator destination={currentDestination.title} onClick={handleClick} />

          <SchedulingButtons
            datetime={datetime}
            isCMS={publishingPoint === publishingPoints.CMS}
            canShowNewDesign={canShowNewDesign}
            openPublishingSettings={openPublishingSettings}
            id={instanceId}
            title={title}
          />

          {publishingPoints.LINEAR === publishingPoint ? (
            memoizedLinearMenu
          ) : (
            <GeneralEllipsisMenu
              {...{
                isDeleteEnalbled,
                onDeleteInstance,
                onForceUnlock,
                onMetadataSelect,
                showMetadata,
                summarize,
                writeLock,
                lockedByUser,
                disableEdit,
                canSeeVersionHistory,
                title,
                versions,
                currentVersionContent,
                refetchVersionList,
                refetchVersionContent,
                onRestoreVersion,
                checkVersionRestorability,
                isSavingContent,
                auditListLoading,
                versionContentLoading,
              }}
            />
          )}
        </div>
        <Divider className={classes.divider} />
      </div>
      <PublishSettings
        instanceType={publishingPoint}
        publishingTime={variant === variants.LINEAR ? publishingTime : schedule}
        onCancel={handleCancel}
        variant={capitalize(variant)}
        selectedDestination={currentDestination}
        {...{ anchorEl, onOK }}
      />
    </div>
  );
};

HeaderView.propTypes = {
  /** Reference to the container element */
  containerRef: PropTypes.shape({ current: PropTypes.shape({}) }),
  /** Variant of the instance card */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Current status id of the instance */
  statusId: PropTypes.string,
  /** Publishing platform of the instance */
  publishingPoint: PropTypes.oneOf(Object.keys(publishingPointIcons)),
  /** Currently selected destination of this instance */
  currentDestination: PropTypes.shape({
    title: PropTypes.string,
    publishingTime: PropTypes.string,
  }),
  /** Callback to be invoked on publish settings change */
  onPublishSettingsChange: PropTypes.func,
  /** Title of the instance */
  title: PropTypes.string,
  /** Callback to be invoked on title update */
  onTitleUpdate: PropTypes.func,
  /** Publishing time for general instance variant */
  schedule: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** List of users assigned to current instance */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** Callback to be invoked on assignee update */
  onAssigneeUpdate: PropTypes.func,
  /** Instance templates to show on ellipsis menu for linear variant */
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),
  /** Callback to be invoked on template selection,
   * with the selected template passed in
   */
  onSelectTemplate: PropTypes.func,
  /** Callback to be invoked when save as template option is clicked,
   * with the templateName passed in
   */
  onSaveTemplate: PropTypes.func,
  /** Callback to be invoked on template deletion,
   * with the template to be deleted passed in
   */
  onDeleteTemplate: PropTypes.func,
  /** Callback to be invoked on click for non-editable elements */
  onBackgroundClick: PropTypes.func,
  /** List of template folders */
  folders: PropTypes.arrayOf(PropTypes.any),
  /** Callback to be invoked while creating new folder */
  onCreateFolder: PropTypes.func,
  /** Callback to be invoked when opening a story */
  onOpenStory: PropTypes.func,
  /** Callback to be invoked while deleting a folder */
  onDeleteFolder: PropTypes.func,
  /** Callback to be invoked when downloading an instance */
  onDownload: PropTypes.func,
  /** Boolean that indicates that user can change content */
  writeLock: PropTypes.bool,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  /** boolean that hides delete template from menu */
  canDeleteTemplate: PropTypes.bool,
  /** boolean that hides delete template folder from menu */
  canDeleteTemplateFolder: PropTypes.bool,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
};

HeaderView.defaultProps = {
  containerRef: { current: null },
  variant: variants.LINEAR,
  statusId: 'todo',
  publishingPoint: 'linear',
  currentDestination: {},
  onPublishSettingsChange: newPublishingSettings => {},
  title: '',
  onTitleUpdate: newTitle => {},
  schedule: null,
  assignees: [],
  onAssigneeUpdate: updatedAssignees => {},
  templates: [],
  onSelectTemplate: template => {},
  onSaveTemplate: templateName => {},
  onDeleteTemplate: template => {},
  onBackgroundClick: () => {},
  folders: [],
  onCreateFolder: () => {},
  onOpenStory: () => {},
  onDeleteFolder: () => {},
  onDownload: () => {},
  writeLock: false,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canDeleteTemplateFolder: false,
  disableEdit: false,
};

export default HeaderView;
