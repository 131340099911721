import gql from 'graphql-tag';

export default gql`
  subscription updatePlanStatusView($planStatusViewDate: String!) {
    updatePlanStatusViewSubscription(planStatusViewDate: $planStatusViewDate) {
      mId
      mTitle
      mRefId
      mDescription
      mState
      mPublishingAt
      mSyncProviders {
        provider
        mMetaData {
          key
          value
        }
      }
      mMetaData {
        key
        value
      }
      mAssignedMembers {
        mId
        mType
      }
      mUsers {
        mId
        mTitle
        mAvatarUrl
      }
      mProperties {
        __typename
        ... on PlatformType {
          platform
          account {
            accountUrl
            accountLogo
            accountTitle
            accountId
            accountRefId
            orderType
          }
        }
      }
    }
  }
`;
