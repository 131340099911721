import { useQuery } from 'react-apollo';
import GET_INSTANCE from 'graphql/queries/getInstance';
import { getStoryInstancesQuery } from 'graphql/queryVariables';

const useInstances = (storyId, fetchPolicy) => {
  const { data, error, loading, refetch } = useQuery(GET_INSTANCE, {
    variables: getStoryInstancesQuery(storyId),
    fetchPolicy,
  });

  let instances = [];
  if (data && data.getMembers) {
    const { getMembers } = data;
    instances = [...getMembers];
  }

  return { instances, error, loading, refetch };
};

export default useInstances;
