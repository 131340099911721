/* eslint-disable max-len */
import { elementTypes } from 'components/editor/constants';
import { ReactComponent as CameraIcon } from 'assets/icons/systemicons/editor/Primary_Camera_Small.svg';
import { ReactComponent as PackageIcon } from 'assets/icons/systemicons/editor/Primary_Package_Small.svg';
import { ReactComponent as VoiceOverIcon } from 'assets/icons/systemicons/editor/Primary_VoiceOver_Small.svg';
import { ReactComponent as FullscreenGraphicIcon } from 'assets/icons/systemicons/editor/Primary_FullscreenGraphic_Small.svg';
import { ReactComponent as LiveIcon } from 'assets/icons/systemicons/editor/Primary_Live_Small.svg';
import { ReactComponent as DveIcon } from 'assets/icons/systemicons/editor/Primary_Dve_Small.svg';
import { ReactComponent as JingleIcon } from 'assets/icons/systemicons/editor/Primary_Jingle_Small.svg';
import { ReactComponent as BreakIcon } from 'assets/icons/systemicons/editor/Primary_Break_Small.svg';
import { ReactComponent as TelephoneIcon } from 'assets/icons/systemicons/editor/Primary_Telephone_Small.svg';
import { ReactComponent as AdlibIcon } from 'assets/icons/systemicons/editor/Primary_Adlib_Small.svg';
import { ReactComponent as AudioIcon } from 'assets/icons/systemicons/editor/secondary_audio.svg';
import { ReactComponent as AccessoryIcon } from 'assets/icons/systemicons/editor/secondary_code.svg';
import { ReactComponent as StarCGIcon } from 'assets/icons/systemicons/editor/secondary_starCG.svg';

const {
  CAMERA,
  PACKAGE,
  VOICE_OVER,
  FULLSCREEN_GRAPHICS,
  LIVE,
  DVE,
  JINGLE,
  BREAK,
  TELEPHONE,
  ADLIB,
  AUDIO,
  ACCESSORY,
  OVERLAY_GRAPHICS,
} = elementTypes;

const iconComponents = {
  [CAMERA]: CameraIcon,
  [PACKAGE]: PackageIcon,
  [VOICE_OVER]: VoiceOverIcon,
  [FULLSCREEN_GRAPHICS]: FullscreenGraphicIcon,
  [LIVE]: LiveIcon,
  [DVE]: DveIcon,
  [JINGLE]: JingleIcon,
  [BREAK]: BreakIcon,
  [TELEPHONE]: TelephoneIcon,
  [ADLIB]: AdlibIcon,
  [AUDIO]: AudioIcon,
  [ACCESSORY]: AccessoryIcon,
  [OVERLAY_GRAPHICS]: StarCGIcon,
};

export default iconComponents;
