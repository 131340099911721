import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    height: 88,
    width: '100%',
    borderRadius: '8px 8px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
  },
  backgroundColor: {
    height: '100%',
    width: '100%',
    background: ({ backgroundColor }) =>
      backgroundColor ? palette.dina[backgroundColor] : palette.dina.kanbanTodo,
    opacity: 0.15,
    transition: 'background 0.5s ease-in-out',
  },
  divider: {
    width: '100%',
    background: ({ backgroundColor }) => {
      if (backgroundColor)
        return backgroundColor === 'surfaceCard'
          ? palette.dina.dividerLight
          : palette.dina[backgroundColor];
      return palette.dina.kanbanTodo;
    },
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  titleBox: {
    width: 'calc(100% - 16px)',
    height: 40,
    marginLeft: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  scheduleDestination: {
    height: 47,
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 32,
    height: 32,
    position: 'relative',
    marginLeft: '8px',
    marginRight: '8px',
  },
  thumbnail: {
    width: 26,
    height: 26,
    borderRadius: '2px',
    objectFit: 'cover',
  },
  publishingPoint: {
    width: 22,
    height: 22,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  assigneesWrapper: { marginLeft: 9.5, marginRight: 4 },
  fullWidth: { width: '100%' },
}));

export default useStyles;
