import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  standard: {
    width: '100%',
    margin: '0px 24px 0px 12px',
  },
  compact: {
    width: 'calc(100% - 24px)',
    margin: '0px 12px 0px 12px',
  },
  compactDiv: {
    width: 'calc(100% - 4px)',
  },
}));

const Wrapper = ({ children, usage }) => {
  const classes = useStyles();
  return usage === 'compact' ? (
    <div className={classes.compactDiv}>{children}</div>
  ) : (
    <>{children}</>
  );
};

const ContainerBox = ({ Indicator, Label, Field, usage }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {Indicator}
      <Wrapper usage={usage}>
        {Label}
        <div className={classes[usage]}>{Field}</div>
      </Wrapper>
    </div>
  );
};

ContainerBox.propTypes = {
  /** Indicator component  */
  Indicator: PropTypes.element,
  /** Label Component  */
  Label: PropTypes.element,
  /** Field component  */
  Field: PropTypes.element,
};

ContainerBox.defaultProps = {
  Indicator: <div />,
  Label: <div />,
  Field: <div />,
};

export default ContainerBox;
