import gql from 'graphql-tag';

export default gql`
  subscription updateFeed($provider: String) {
    updateFeedSubscription(provider: $provider) {
      __typename
      provider
      mId
      version
      mPublishedAt
      section
      priority
      pubstatus
      ednotes
      newscodes
      mTitle
      byline
      mContent
      mDescription
      location
      embargoed
      href
      assets {
        duration
        headline
        byline
        type
        renditions {
          href
        }
      }
    }
  }
`;
