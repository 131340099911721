import React, { useState, useCallback, useContext, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import configCtx from 'contexts/configContext';
import { Draggable } from 'react-beautiful-dnd';
import { useSlate, ReactEditor, useReadOnly } from 'slate-react';
import { elementTypes, outTimingTypes } from 'components/editor/constants/types';
import SelectedElement from 'components/editor/components/selectedElement';
import PlaceholderDialog from 'components/editor/components/placeholderDialog';
// eslint-disable-next-line max-len
import removePlaceholder from 'components/editor/components/placeholderDialog/utils/removePlaceholder';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import iconComponents from './constants/iconComponents';
import Box from './components/box';
import InTimingSelect from './components/inTimingSelect';
import OutTimingSelect from './components/outTimingSelect';
import EllipsisMenu from './components/ellipsisMenu';
import Dialog from './components/dialog';
import SecondaryDropZone from './components/secondaryDropZone';
import GraphicsDropZone from './components/graphicsDropZone';
import ClipDropZone from './components/clipDropZone';
import CharacterGraphics from './components/characterGrapgics';
import TitleBox from './components/title';
import getInitialData from './utils/getInitialData';
import useStyles from './styles';

const { MANUAL_OUT } = outTimingTypes;
const { OVERLAY_GRAPHICS } = elementTypes;

const SecondaryAutomation = ({ attributes, children, element }) => {
  const editor = useSlate();
  const readOnly = useReadOnly();
  const { update } = useEditorContext();
  const { data, type } = element;
  const { itemId, mosobj, protocol } = data;
  const [openDetails, setOpenDetails] = useState(false);
  const [placeholderDialogOpen, setPlaceholderDialogOpen] = useState(false);
  const initialData = useMemo(() => getInitialData(data), [data]);
  const { assets } = initialData;
  const Icon = iconComponents[type];
  const { inTiming, outTiming, templateVariant } = initialData;
  const isGraphic = type === OVERLAY_GRAPHICS;
  const classes = useStyles({ readOnly, isGraphic });
  const { automationTemplateConfigs } = useContext(configCtx);

  const { templates } = automationTemplateConfigs[0]
    ? automationTemplateConfigs[0].templateSets[0]
    : {};

  const placeholder = useMemo(
    () => assets.find(({ mediaType }) => mediaType === 'video/placeholder') || false,
    [assets],
  );

  const hasPlaceholder = Boolean(placeholder);

  const handleOpenDetails = useCallback(() => setOpenDetails(true), []);

  const handleCloseDetails = useCallback(() => setOpenDetails(false), []);

  const openPlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(true), []);

  const closePlaceholderDialog = useCallback(() => setPlaceholderDialogOpen(false), []);

  const handleRemovePlaceholder = useCallback(
    () => removePlaceholder(editor, initialData, placeholder, update),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialData, placeholder],
  );

  const [index] = ReactEditor.findPath(editor, element);

  const renderContent = useCallback(
    provided => {
      const { draggableProps, innerRef, dragHandleProps } = provided;

      return (
        <div {...attributes}>
          <div ref={innerRef} {...draggableProps}>
            <SelectedElement {...{ element }}>
              <SecondaryDropZone {...{ element }}>
                <GraphicsDropZone {...{ element }}>
                  <ClipDropZone {...{ element }}>
                    {children}

                    <div contentEditable={false} className={classes.root} {...dragHandleProps}>
                      <Box
                        inTimingType={inTiming}
                        outTimingType={isGraphic ? outTiming : MANUAL_OUT}
                        {...{ isGraphic }}
                      >
                        <div className={classes.content}>
                          <div className={classes.selects}>
                            <InTimingSelect {...{ element }} />
                            {isGraphic && <OutTimingSelect {...{ element }} />}
                          </div>

                          <div className={classes.titleBox}>
                            {protocol === 'starcg' ? (
                              <CharacterGraphics {...{ data }} />
                            ) : (
                              <TitleBox
                                {...{
                                  initialData,
                                  type,
                                  templates,
                                }}
                              />
                            )}
                          </div>

                          <div className={classes.iconMenuWrapper}>
                            <Icon className={classes.icon} />
                            <EllipsisMenu
                              isGraphic={isGraphic}
                              className={classes.menu}
                              removePlaceholder={handleRemovePlaceholder}
                              {...{
                                mosobj,
                                hasPlaceholder,
                                handleOpenDetails,
                                openPlaceholderDialog,
                              }}
                            />
                          </div>
                        </div>
                      </Box>
                    </div>
                  </ClipDropZone>
                </GraphicsDropZone>
              </SecondaryDropZone>
            </SelectedElement>
          </div>
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      assets,
      attributes,
      children,
      element,
      handleOpenDetails,
      inTiming,
      initialData,
      isGraphic,
      mosobj,
      outTiming,
      templateVariant,
      templates,
    ],
  );

  return (
    <div>
      <Draggable draggableId={itemId} {...{ index }}>
        {renderContent}
      </Draggable>

      {!isGraphic && openDetails && (
        <Dialog
          open={openDetails}
          onClose={handleCloseDetails}
          {...{ initialData, type, element }}
        />
      )}

      {placeholderDialogOpen && (
        <PlaceholderDialog
          open={placeholderDialogOpen}
          onClose={closePlaceholderDialog}
          {...{ element }}
        />
      )}
    </div>
  );
};

SecondaryAutomation.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

SecondaryAutomation.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.ACCESSORY,
    children: [],
  },
};

export default memo(SecondaryAutomation);
