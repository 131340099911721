import { markTypes } from 'components/editor/constants';
import { ReactComponent as Bold } from 'assets/icons/systemicons/editor/bold_off.svg';
import { ReactComponent as Italic } from 'assets/icons/systemicons/editor/italic_off.svg';
import { ReactComponent as Underline } from 'assets/icons/systemicons/editor/underline_off.svg';
// eslint-disable-next-line max-len
import { ReactComponent as StrikeThrough } from 'assets/icons/systemicons/editor/strikethrough_off.svg';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const iconComponents = {
  [BOLD]: Bold,
  [ITALIC]: Italic,
  [UNDERLINE]: Underline,
  [STRIKE_THROUGH]: StrikeThrough,
};

export default iconComponents;
