import React, { useState } from 'react';
import Dialog from 'components/dialog';
import memberTypes from 'graphql/memberTypes';
import Searchbar from 'components/searchbar';
import RelevantHeader from '../../relevantHeader';
import Tabs from '../../tabs';
import MasterWrapper from '../../masterWrapper';
import useStyles from './list-styles';
import ListContainer from './list-container';
import menuItems from './menu-items';
import menuActions from './menu-actions';
import CreateContact from '../createContact';

const ListView = ({ containerRef, showDetail, contactTab, handleContactTabChange }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(<div />);
  const [filters, setFilters] = useState([]);

  const tabItems = [
    {
      label: 'INTERNAL',
      content: (
        <div className={classes.listWrapper}>
          <ListContainer filters={filters} onClick={showDetail} type={memberTypes.USER} />
        </div>
      ),
    },
    {
      label: 'EXTERNAL',
      content: (
        <div className={classes.listWrapper}>
          <ListContainer filters={filters} onClick={showDetail} type={memberTypes.CONTACT} />
        </div>
      ),
    },
  ];

  const onMenuItemClick = action => {
    switch (action) {
      case menuActions.CREATE_CONTACT:
        setDialogContent(
          <CreateContact
            onCancel={() => setDialogOpen(false)}
            onSubmit={contact => {
              // TODO: if null display error message.
              setDialogOpen(false);
            }}
          />,
        );
        setDialogOpen(true);
        break;
      default:
        setDialogContent(<div />);
        setDialogOpen(false);
        break;
    }
  };

  const onTabChange = (_, newValue) => {
    handleContactTabChange(newValue);
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        container={containerRef.current}
        BackdropProps={{ classes: { root: classes.backdropRoot } }}
        onClose={() => setDialogOpen(false)}
      >
        {dialogContent}
      </Dialog>

      <MasterWrapper
        header={<RelevantHeader headerTitle="Contacts" {...{ menuItems, onMenuItemClick }} />}
        body={<Tabs disableElevation {...{ tabItems, onTabChange, tabIndex: contactTab }} />}
      />
      <div className={classes.filterBar}>
        <Searchbar chips={filters} chipChangeHandler={chips => setFilters(chips)} />
      </div>
    </>
  );
};

export default ListView;
