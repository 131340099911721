import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Icon from 'components/icon/icon-view';
import AddMember from 'components/addMemberDialog';
import Dialog from 'components/dialog';
import useStyles from './input-styles';

const MemberInput = props => {
  const { icon, label, value, onChange, type, dialogTitle } = props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const handleConfirm = val => {
    const memberIds = val.map(({ mId, mTitle }) => ({ mId, mTitle }));
    onChange(memberIds);
  };

  const displayValues = value ? value.map(member => member.mTitle) : [];

  return (
    <div className={classes.inputWrapper}>
      <Icon icon={icon} height={24} width={24} />
      <Select
        multiple
        MenuProps={{ variant: 'selectedMenu', PopoverClasses: { root: classes.test } }}
        fullWidth
        displayEmpty
        open={openDialog}
        onOpen={() => setOpenDialog(true)}
        onClose={() => setOpenDialog(false)}
        value={displayValues}
        classes={{ icon: classes.selectIcon }}
        renderValue={selected => {
          return !selected || selected.length === 0 ? (
            <span className={classes.placeholder}>{label}</span>
          ) : (
            selected.join(' | ')
          );
        }}
        input={
          <Input
            classes={{
              root: classes.root,
              underline: classes.underline,
              input: classes.input,
            }}
          />
        }
      >
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <AddMember
            variant={type}
            dialogTitle={dialogTitle}
            onCancel={() => setOpenDialog(false)}
            onOk={val => {
              handleConfirm(val);
              setOpenDialog(false);
            }}
          />
        </Dialog>
      </Select>
    </div>
  );
};

MemberInput.propTypes = {
  /** icon */
  icon: PropTypes.string.isRequired,
  /** input label */
  label: PropTypes.string.isRequired,
  /** controlled form value */
  value: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
    }),
  ),
  /** onChange handler */
  onChange: PropTypes.func,
  /** the type */
  type: PropTypes.string,
  /** The title of the dialog */
  dialogTitle: PropTypes.string,
};

MemberInput.defaultProps = {
  value: [],
  onChange: () => {},
  type: 'People',
  dialogTitle: 'People',
};

export default MemberInput;
