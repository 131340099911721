import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import useInputEvents from 'hooks/useInputEvents';
import stopEventPropagation from 'utils/stopEventPropagation';
import useStyles from './styles';

const InputBase = ({ className, onUpdate, type, value }) => {
  const classes = useStyles();
  const [localValue, setLocalValue] = useState(value);

  const update = useCallback(
    newValue => {
      if (newValue === value) setLocalValue(newValue);
      else onUpdate(newValue);
    },
    [onUpdate, value],
  );

  const [inputRef, onKeyDown, onBlur] = useInputEvents(update, localValue, value);

  useEffect(() => setLocalValue(value), [value]);

  const onChange = useCallback(event => {
    setLocalValue(event.target.value);
  }, []);

  return (
    <div>
      {type === 'input' ? (
        <input
          className={`${classes.root} ${classes.input} ${className}`}
          ref={inputRef}
          value={localValue}
          onMouseDown={stopEventPropagation}
          {...{ onBlur, onChange, onKeyDown }}
        />
      ) : (
        <textarea
          className={`${classes.root} ${classes.textarea} ${className}`}
          ref={inputRef}
          value={localValue}
          onMouseDown={stopEventPropagation}
          {...{ onChange, onKeyDown }}
        />
      )}
    </div>
  );
};

InputBase.propTypes = {
  /** CSS class handle */
  className: PropTypes.string,
  /** Callback to be invoked on value update */
  onUpdate: PropTypes.func,
  /** Type for the text input */
  type: PropTypes.oneOf(['input', 'textarea']),
  /** Value for the input */
  value: PropTypes.string,
};

InputBase.defaultProps = {
  className: '',
  onUpdate: newValue => {},
  type: 'input',
  value: '',
};

export default memo(InputBase);
