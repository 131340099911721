import gql from 'graphql-tag';

export default gql`
  query GetAllMembersByPublishingDate(
    $storyInput: GetMembersByPublishingDateInput
    $pitchInput: GetMembersByPublishingDateInput
    $instanceInput: GetMembersByPublishingDateInput
  ) {
    story: getMembersByPublishingDate(input: $storyInput) {
      mId
      mRefId
      mTitle
      mUpdatedAt
      mPriority
      mPublishingAt
      mType
      mContent
      mDescription
      mCategories {
        label
        categoryId
      }
      mAssignedMembers {
        mId
        mType
      }
      mThumbnailUrl
    }
    pitch: getMembersByPublishingDate(input: $pitchInput) {
      mId
      mRefId
      mTitle
      mUpdatedAt
      mPriority
      mPublishingAt
      mType
      mContent
      mDescription
      mCategories {
        label
        categoryId
      }
      mAssignedMembers {
        mId
        mType
      }
      mThumbnailUrl
    }
    instance: getMembersByPublishingDate(input: $instanceInput) {
      mId
      mRefId
      mTitle
      mUpdatedAt
      mThumbnailUrl
      mPublishingAt
      mState
      mStoryId
      mContentKey
      mDescription
      mMetaData {
        key
        value
      }    
      mProperties {
        __typename
        ... on PlatformType {
          platform
          account {
            accountTitle
          }
        }
      }
      mUsers {
        mId
        mTitle
        mAvatarUrl
      }
      mAssignedMembers {
        mId
        mType
      }
    }
  }
`;
