import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import Switch from 'components/switch/Switch';
import EllipsisDropdown from 'components/ellipsisDropdown';
import Popover from 'components/popover';
import rundownStates from 'screens/rundown/utils/rundownStates';

const useStyles = makeStyles(theme => ({
  subMenu: { width: '256px' },
}));

const dropDownAction = {
  EDIT_MASTER: 'edit_master',
  DOWNLOAD: 'download',
  PRINT: 'print',
  PRINTPREORDER: 'printpreorder',
  STATE_READY: '04-ready',
  STATE_PUBLISHED: '05-published',
  STATE_ARCHIVED: '06-archived',
};

const EllipsisDropdownContainer = props => {
  const {
    mosState,
    preparingState: preparingMosState,
    onRundownStateChange,
    onPreparingStateChange,
    onEditMasterClick,
    onDownloadClick,
    isInitializeState,
    onChangeState,
    disableStateChange,
  } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [subAnchorEl, setSubAnchorEl] = useState(null);

  const [rundownState, setRundownState] = useState(mosState);

  const [preparingState, setPreparingState] = useState(preparingMosState);

  const { NOTREADY, ACTIVE, READY } = rundownStates;

  useEffect(() => {
    setRundownState(mosState);
  }, [mosState]);

  const handleRundownStateChange = action => {
    let tmpState = NOTREADY;

    if (action === 'readytoair' && rundownState === READY) {
      tmpState = ACTIVE;
    }

    if (action === 'readytoair' && (rundownState === NOTREADY || rundownState === ACTIVE)) {
      tmpState = READY;
    }

    if (action === 'active' && rundownState === READY) {
      tmpState = NOTREADY;
    }

    if (action === 'active' && rundownState === NOTREADY) {
      tmpState = ACTIVE;
    }

    setRundownState(tmpState);

    onRundownStateChange(tmpState);
  };

  const handlePreparingStateChange = () => {
    const state = preparingState === NOTREADY ? ACTIVE : NOTREADY;

    setPreparingState(state);

    onPreparingStateChange(state);
  };

  const listItemClicked = item => {
    if (item === dropDownAction.EDIT_MASTER) onEditMasterClick(item);
    if (item === dropDownAction.PRINT) onDownloadClick(item);
    if (item === dropDownAction.PRINTPREORDER) onDownloadClick(item);
    if (item === dropDownAction.DOWNLOAD) onDownloadClick(item);
    if (item === dropDownAction.STATE_READY) onChangeState(item);
    if (item === dropDownAction.STATE_PUBLISHED) onChangeState(item);
    if (item === dropDownAction.STATE_ARCHIVED) onChangeState(item);
    setSubAnchorEl(null);
    setAnchorEl(null);
  };

  const listItems = [
    <ListItem
      disabled={isInitializeState}
      key="Ready"
      text="READY TO AIR"
      iconChild={
        <Switch
          {...{ selected: mosState === READY }}
          onClick={() => handleRundownStateChange('readytoair')}
        />
      }
    />,
    <ListItem
      disabled={isInitializeState}
      key="Active"
      text="Active"
      iconChild={
        <Switch
          {...{ selected: mosState === ACTIVE || mosState === READY }}
          onClick={() => handleRundownStateChange('active')}
        />
      }
    />,
    <ListItem
      disabled={isInitializeState}
      key="ActivatePreparing"
      text="Activate Preparing"
      iconChild={
        <Switch
          {...{ selected: preparingMosState === ACTIVE }}
          onClick={handlePreparingStateChange}
        />
      }
    />,
    <ListItem
      disabled={isInitializeState}
      key="printready"
      text="Print ready Rundown"
      onClick={() => listItemClicked(dropDownAction.PRINT)}
    />,
    <ListItem
      disabled={isInitializeState}
      key="printpreparing"
      text="Print preparing Rundown"
      onClick={() => listItemClicked(dropDownAction.PRINTPREORDER)}
    />,
    <ListItem
      disabled={isInitializeState}
      key="Download"
      text="Download"
      onClick={() => listItemClicked(dropDownAction.DOWNLOAD)}
    />,
    <div key="State">
      <ListItem
        disabled={isInitializeState || disableStateChange}
        text="State"
        onClick={e => setSubAnchorEl(e.currentTarget)}
      />
      <Popover
        anchorEl={subAnchorEl}
        onClose={() => {
          setSubAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <div className={classes.subMenu}>
          <ListItem text="Ready" onClick={() => listItemClicked(dropDownAction.STATE_READY)} />
          <ListItem
            text="Published"
            onClick={() => listItemClicked(dropDownAction.STATE_PUBLISHED)}
          />
          <ListItem
            text="Archived"
            onClick={() => listItemClicked(dropDownAction.STATE_ARCHIVED)}
          />
        </div>
      </Popover>
    </div>,
  ];

  return <EllipsisDropdown listItems={listItems} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />;
};

EllipsisDropdownContainer.propTypes = {
  /** Edit master rundown clicked */
  onEditMasterClick: PropTypes.func,
  onRundownStateChange: PropTypes.func,
  onPreparingStateChange: PropTypes.func,
  /** Callback to change states of all instances in ready list */
  onChangeState: PropTypes.func,
  /** Boolean that indicates that state change is disabled */
  disableStateChange: PropTypes.bool,
};

EllipsisDropdownContainer.defaultProps = {
  onEditMasterClick: () => {},
  onRundownStateChange: () => {},
  onPreparingStateChange: () => {},
  onChangeState: () => {},
  disableStateChange: true,
};

export default EllipsisDropdownContainer;
