import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import StoryCard from '../monthStoryCard';
import useStyles from './cardContainer-styles';

const CardContainer = props => {
  const { stories, height, onMoreClick, openStory, onlyShowUsersItems } = props;
  const classes = useStyles();
  const [storiesToShow, setStoriesToShow] = useState(stories);

  useEffect(() => {
    setStoriesToShow(stories);
  }, [stories]);

  const handleHideItem = id => {
    const newItemList = storiesToShow.filter(item => id !== item.mId);
    setStoriesToShow(newItemList);
  };

  const heightOfMoreText = 14;
  const heightOfStoryCardWithMargin = 21;
  const noOfStoriesShown = Math.floor((height - heightOfMoreText) / heightOfStoryCardWithMargin);
  const noOfStoriesNotShown = storiesToShow.length - noOfStoriesShown;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {storiesToShow.slice(0, noOfStoriesShown).map(story => {
          const {
            mId,
            mTitle,
            mThumbnailUrl,
            priorityLevel,
            mType,
            mAssignedMembers,
            mUpdatedAt,
          } = story;
          return (
            <StoryCard
              key={mId}
              id={mId}
              assignedMembers={mAssignedMembers}
              image={mThumbnailUrl}
              title={mTitle}
              priorityLevel={priorityLevel}
              openStory={openStory}
              isPitch={mType === 'pitch'}
              onlyShowUsersItems={onlyShowUsersItems}
              onHideItem={handleHideItem}
              updatedAt={mUpdatedAt}
            />
          );
        })}
      </div>
      <Typography
        classes={{ root: classes.moreStoriesText }}
        className={classes.moreStories}
        onClick={onMoreClick}
      >
        {noOfStoriesNotShown > 0 && `+${noOfStoriesNotShown} more Stories...`}
      </Typography>
    </div>
  );
};

CardContainer.propTypes = {
  /** A list of stories of the day */
  stories: PropTypes.arrayOf(PropTypes.any),
  /** Callback to open a story */
  openStory: PropTypes.func,
  /** height of card container component */
  height: PropTypes.number,
  /** Callback to be invoked when more story is clicked */
  onMoreClick: PropTypes.func,
  /** boolean that shows only users stories & pitches the user is assigned to */
  onlyShowUsersItems: PropTypes.bool,
};

CardContainer.defaultProps = {
  stories: [],
  openStory: (title, id) => {},
  height: 40,
  onMoreClick: () => {},
  onlyShowUsersItems: false,
};

export default CardContainer;
