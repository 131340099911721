import React from 'react';
import fieldTypes from 'utils/rundown/fieldTypes';
import PropTypes from 'prop-types';
import {
  durationTypes,
  getDuration,
  getDurationKey,
  getSeconds,
  getTime,
} from 'screens/rundown/components/editor/utils';
import TextBox from '../textBox';
import FlexibleField, { DivWrapper } from './flexibleField-view';

const MetadataField = ({
  id,
  type,
  function: func,
  onUpdateValue,
  parameters,
  metadata,
  description,
  name,
  readonly,
  usage,
  disableEdit,
  ...rest
}) => {
  const findValue = fieldId => {
    const meta = metadata.find(data => data.key === fieldId);
    return meta ? meta.value : null;
  };

  const findKey = str => {
    return metadata.find(item => getDurationKey(item) === str);
  };

  const renderedValue = findValue(id) || '';
  const isReadOnly = readonly || type === fieldTypes.FUNCTION;

  const updateField = val => {
    if (val !== renderedValue) {
      const newFields = [];
      if (id.includes(durationTypes.CLIP_DURATION)) {
        const scriptDuration = getDuration(metadata, durationTypes.SPEAK_DURATION);
        const newTotalDuration = getSeconds(scriptDuration) + getSeconds(val);
        newFields.push({
          key: findKey(durationTypes.TOTAL_DURATION).key,
          value: getTime(newTotalDuration),
        });
      }
      newFields.push({ key: id, value: val });
      onUpdateValue(newFields);
    }
  };

  return isReadOnly ? (
    <DivWrapper>
      <TextBox value={renderedValue} label={name} {...{ usage, description }} />
    </DivWrapper>
  ) : (
    <FlexibleField
      {...{
        type,
        updateField,
        renderedValue,
        name,
        description,
        parameters,
        usage,
        disableEdit,
      }}
      {...rest}
    />
  );
};

MetadataField.propTypes = {
  /** Field Id */
  id: PropTypes.string,
  /** Type of metadata field: select, combo, menu, default input field */
  type: PropTypes.string,
  /** onUpdateValue callback function */
  onUpdateValue: PropTypes.func,
  /** description of the field */
  description: PropTypes.string,
  /** label of the Field to be used */
  name: PropTypes.string,
  /** options to be rendered on the select/menu/combobox */
  options: PropTypes.arrayOf(PropTypes.object),
  /** Whether the field is read only or not: true/false */
  readonly: PropTypes.bool,
  /**  Function name if field type is function */
  function: PropTypes.string,
  /** Boolean that stops an user from editing */
  disableEdit: PropTypes.bool,
};

MetadataField.defaultProps = {
  id: '',
  type: '',
  onUpdateValue: () => {},
  description: '',
  name: '',
  options: [],
  readonly: false,
  function: '',
  disableEdit: false,
};

export default MetadataField;
