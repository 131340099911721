import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    width: '20px',
    height: '20px',
    margin: '10px',
    '& path': {
      fill: theme.palette.dina.blackMediumEmphasis,
      fillOpacity: 1,
    },
  },
  thumbnail: {
    ...theme.typography.dina.listItemLabel,
    fontWeight: 500,
    lineHeight: '32px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
  },
}));

export default useStyles;
