import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.dina.surfaceCard,
    borderRadius: '8px',
    backdropFilter: 'blur(30px)',
    width: '256px',
    padding: '8px 0',
    opacity: ({ disableChildren }) => (disableChildren ? 0.5 : 1),
    maxHeight: '85vh',
    overflow: 'auto',
  },
  popover: {
    margin: '-8px 0',
  },
}));

export default useStyles;
