import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Divider from 'components/divider';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Editor from 'components/editor';
import Scrollbar from 'screens/main/components/scrollbar';
import useStyles from './content-styles';


const Content = props => {
  const classes = useStyles();
  const { headerTitle, content, variant, loading } = props;
  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography className={classes.headerTitle}>{headerTitle}</Typography>
        <Divider className={classes.headerDivider} />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.contentOutline}>
          {loading && (<LoadingIndicator />)}
          <Scrollbar>
            <div className={classes.contentInline}>
              {variant === 'version-history' ? (
                <Editor value={content} readOnly renderToolbar={() => {}}/>
              ) : content}
            </div>
          </Scrollbar>
        </div>
      </div>
    </div>
  );
};

Content.propTypes = {
  /** Title of the dialog */
  headerTitle: PropTypes.string,
  /** content */
  content: PropTypes.string,
};

Content.defaultProps = {
  headerTitle: 'Header for Contents',
  content: 'Placeholder content',
};

export default Content;
