import React, { memo } from 'react';
import { markTypes, elementTypes } from 'components/editor/constants';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import MarkButton from '../markButton';
import ElementButton from '../elementButton';
import ListButton from '../listButton';
import QuoteButton from '../quoteButton';
import LinkButton from '../linkButton';
import HorizontalRuleButton from '../horizontalRuleButton';
import ColorButton from '../colorButton';
import VideoButton from '../videoButton';
import ImageButton from '../imageButton';
import PlaceholderButton from '../placeholderButton';
import Wrapper from '../wrapper';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const {
  UNORDERED_LIST,
  HEADING_FOUR,
  HEADING_ONE,
  HEADING_THREE,
  HEADING_TWO,
  ORDERED_LIST,
} = elementTypes;

const GeneralToolbar = () => {
  const { isAllowed } = useEditorContext();

  return (
    <>
      <Wrapper>
        <MarkButton type={STRIKE_THROUGH} />
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <ElementButton type={HEADING_ONE} />
        <ElementButton type={HEADING_TWO} />
        <ElementButton type={HEADING_THREE} />
        <ElementButton type={HEADING_FOUR} />
        <ListButton type={UNORDERED_LIST} />
        <ListButton type={ORDERED_LIST} />
        <QuoteButton />
        <LinkButton />
        <HorizontalRuleButton />
        <ColorButton />
      </Wrapper>

      <Wrapper showRightMargin>
        {!isAllowed && <PlaceholderButton />}
        <VideoButton />
        <ImageButton />
        <ImageButton isGif />
      </Wrapper>
    </>
  );
};

export default memo(GeneralToolbar);
