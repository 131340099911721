/**
 *  Hook for updating instance state
 */

import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

const UPDATE_INSTANCE = gql`
  mutation updateInstance($input: UpdateInstanceInput) {
    updateInstance(input: $input) {
      mId
      mRefId
      mTitle
      mUpdatedAt
      mThumbnailUrl
      mState
      mStoryId
      mPublishingAt
      mProperties {
        __typename
        ... on PlatformType {
          platform
          account {
            accountTitle
          }
        }
      }
      mUsers {
        mId
        mTitle
        mAvatarUrl
      }
    }
  }
`;

const GET_INSTANCES_BY_PUBLISHING_DATE = gql`
  query GetInstancesByPublishingDate($input: GetMembersByPublishingDateInput) {
    getMembersByPublishingDate(input: $input) {
      mId
      mRefId
    }
  }
`;

const useUpdateInstanceState = () => {
  const [update] = useMutation(UPDATE_INSTANCE);

  const run = async (mId, mState, startDate, endDate, mPublishingAt) => {
    const input = {
      mId,
      mState,
    };
    if (mPublishingAt) input.mPublishingAt = mPublishingAt;

    await update({
      variables: {
        input,
      },
      update: (proxy, mutationResult) => {
        const { updateInstance } = mutationResult.data;

        try {
          const variables = {
            input: {
              mType: 'instance',
              startDate,
              endDate,
            },
          };

          const instancesList = proxy.readQuery({
            query: GET_INSTANCES_BY_PUBLISHING_DATE,
            variables,
          });

          const found = instancesList.getMembersByPublishingDate.find(
            instance => instance.mId === updateInstance.mId,
          );
          if (!found) {
            instancesList.getMembersByPublishingDate.push(updateInstance);
          }

          proxy.writeQuery({
            query: GET_INSTANCES_BY_PUBLISHING_DATE,
            variables,
            data: instancesList,
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      },
    });
  };

  return [run];
};

export default useUpdateInstanceState;
