import variants from 'utils/instance/variants';

/**
 * matches variant restriction for editor
 *
 * p.s: to allow any editor restriction on instance,
 * please add it on the matchedConstraints
 *
 * @param {String} variant instance variant i.e. 'twitter', 'linear', 'cms' etc.
 * @returns {Boolean} whether the variant in the matched constraint or not
 */

const matchedConstraints = [variants.TWITTER, variants.CMS, variants.YOUTUBE];

const matchRestriction = variant => {
  return matchedConstraints.includes(variant);
};

export default matchRestriction;
