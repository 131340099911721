import React, { useContext, useState } from 'react';
import { CurrentTabContext, useSidebarContext } from 'globalState';
import UserCtx from 'contexts/UserContext';
import memberTypes from 'graphql/memberTypes';
import Popover from 'components/popover/Popover';
import CreateStory from 'components/createNew';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useCreateStory from 'hooks/useCreateStory';
import useSetStorySync from 'hooks/useSetStorySync';
import Tab from './create-view';

const CreateTab = props => {
  const [, setCurrentTab] = useContext(CurrentTabContext);
  const [{ isLeftHidden, leftSelection }] = useSidebarContext();
  const { mId } = useContext(UserCtx);
  const [anchorEl, setAnchorEl] = useState(null);

  const [createStoryForUser] = useCreateStory();
  const [checkUserRight] = useCheckUserRight();
  const enableSynchronize = checkUserRight('feature', 'story-auto-synchronize');
  const [setSync] = useSetStorySync();

  const onCreateStory = async mTitle => {
    try {
      setAnchorEl(null);
      const createdStoryId = await createStoryForUser(mId, mTitle, memberTypes.USER_STORY, mId);
      if (enableSynchronize) setSync(createdStoryId, ['*']);
      setCurrentTab({
        page: 'create',
        title: mTitle,
        id: createdStoryId,
      });

      if (
        !isLeftHidden &&
        (leftSelection === memberTypes.STORY || leftSelection === memberTypes.USER)
      ) {
        const elm = document.getElementById('scrollbar');

        if (elm && elm.firstChild) {
          setTimeout(() => {
            elm.firstChild.scrollTop = 0;
          }, 1000);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return (
    <div>
      <Tab
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
        {...props}
      />
      <Popover onClose={() => setAnchorEl(null)} anchorEl={anchorEl} position="right">
        <CreateStory
          onCancel={() => setAnchorEl(null)}
          onCreate={title => {
            onCreateStory(title);
          }}
        />
      </Popover>
    </div>
  );
};

export default CreateTab;
