import React, { useContext, useEffect, useState } from 'react';
import { ToolbarContext } from 'contexts/Toolbar';
import ConfigCtx from 'contexts/configContext';
import { Typography } from '@material-ui/core';
import { FeedsContext } from 'globalState';
import FeedsToolbar from './components/toolbar';
import useStyles from './feeds-styles';
import FeedPanel from './components/panel';
import Inspector from './components/inspector';
import Search from './components/search/search-container';
import InspectorEmptyState from './components/inspector/emptyState';

const FeedsContainer = () => {
  const { feeds } = useContext(ConfigCtx);
  const [, setToolbarElements] = useContext(ToolbarContext);
  const { savedFilters, setSavedFilters } = useContext(FeedsContext);
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedFeedItem, setSelectedFeedItem] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filters, setFilters] = useState([]);
  const classes = useStyles(selectedFeedItem)();

  const saveFilter = label => {
    if (filters && filters.length > 0) {
      setSavedFilters([...savedFilters, { label, expression: filters }]);
    }
  };

  useEffect(() => {
    setToolbarElements(
      <FeedsToolbar
        sources={feeds}
        selectedSource={selectedSource}
        setSelectedSource={setSelectedSource}
        filters={savedFilters}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        onCreateFilter={label => saveFilter(label)}
        updateSavedFilters={setSavedFilters}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeds, selectedSource, selectedFilter, savedFilters, filters]);

  useEffect(() => {
    if (selectedSource === '' && feeds && feeds[0]) {
      setSelectedSource(feeds[0].mRefId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeds]);

  if (selectedSource.length < 1) {
    if (selectedFeedItem) setSelectedFeedItem(null);
    return <div className={classes.feeds} />;
  }

  const selectedFeedItemHandler = item => {
    if (!item) return;
    if (!selectedFeedItem || item.mId !== selectedFeedItem.mId) {
      setSelectedFeedItem(item);
    }
  };

  const allFilterExpressions = () => {
    const allFilters = []
      .concat(selectedFilter && selectedFilter.expression ? selectedFilter.expression : [], filters)
      .filter((item, i, arr) => item && arr.indexOf(item) === i);

    return allFilters;
  };

  return (
    <div className={classes.feedsPage}>
      <span className={classes.pageTitleContainer}>
        <Typography className={classes.pageTitle}>Feeds</Typography>
      </span>
      <div className={classes.feeds}>
        <FeedPanel
          provider={selectedSource}
          onInspect={item => selectedFeedItemHandler(item)}
          filters={allFilterExpressions()}
        />
        {selectedFeedItem ? (
          <Inspector feedItem={selectedFeedItem} filters={allFilterExpressions()} />
        ) : (
          <InspectorEmptyState />
        )}
      </div>
      <Search
        chips={filters}
        chipChangeHandler={chips => setFilters(chips)}
        customFilter={selectedFilter}
        toggleCustomFilter={() => setSelectedFilter(null)}
      />
    </div>
  );
};

export default FeedsContainer;
