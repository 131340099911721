import gql from 'graphql-tag';

export default gql`
  mutation updateStoryCategories($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mTitle
      mUpdatedAt
      mCategories {
        label
        score
        categoryId
      }
    }
  }
`;
