import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  connector: {
    backgroundColor: '#3997FF',
    position: 'relative',
    width: '1px',
    '&.selected': {
      backgroundColor: theme.palette.dina.iconInactive,
    },
    '&.lastElement': {
      visibility: 'hidden',
    },
    height: '95%',
  },

  connectorGap: {
    backgroundColor: '#3997FF',
    position: 'relative',
    width: '1px',
    '&.selected': {
      backgroundColor: theme.palette.dina.iconInactive,
    },
    height: '15px',
  },

  dot: {
    backgroundColor: '#3997FF',
    borderWidth: '0px',
    '&.selected': {
      backgroundColor: theme.palette.dina.iconInactive,
    },
    marginTop: '0px',
    marginBottom: '0px',
  },
  timeline: {
    position: 'relative',
    left: '-34px',
    minHeight: '48px',
    cursor: 'pointer',
    width: '274px',

    '&.selected': {
      cursor: 'grab',
      backgroundColor: '#3997FF',
    },
  },
  timelineContent: {
    padding: '0',
    position: 'relative',
    left: '0%',
    right: '0%',
    top: '2.56%',
    bottom: '76.92%',
  },
}));

export default useStyles;