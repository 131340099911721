import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import useTextStorage from 'hooks/useTextStorage';
import configCtx from 'contexts/configContext';
import { isOlderSlateValue, migrateValue } from 'components/editor';
import InstanceCard from 'components/instanceCard';
import respectHostReadSpeed from 'screens/rundown/utils/respectHostReadSpeed';
import { durationTypes, getDuration } from 'screens/rundown/components/editor/utils';
import getVariant from 'components/instanceCard/utils/getVariant';
import getHostReadSpeed from 'components/instanceCard/utils/getHostReadSpeed';
import countTwitterThread from 'utils/instance/countTwitterThread';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';

const Preview = ({
  title,
  image,
  mContentKey,
  onOpenStory,
  platform,
  id,
  assignees,
  state,
  publishingPointIcon,
  destination,
  publishingAt,
  metaData,
  description,
}) => {
  const [showMetadata, setShowMetadata] = useState(false);
  const { data, loading } = useTextStorage(mContentKey, false);
  const hostReadSpeed = getHostReadSpeed(metaData);
  const metadata = respectHostReadSpeed(metaData, hostReadSpeed);
  const { metadataForms } = useContext(configCtx);
  const form = metadataForms[0];

  const value = useMemo(() => (data && isOlderSlateValue(data) ? migrateValue(data) : data), [
    data,
  ]);

  return (
    <InstanceCard
      fixedHeight={488}
      title={title}
      backgroundImage={image}
      disableEdit
      assignees={assignees}
      loading={loading}
      editorValue={value}
      instance={{ mId: id }}
      hideToolbar
      onOpenStory={onOpenStory}
      publishingPoint={platform}
      variant={getVariant(platform)}
      statusId={state}
      publishingPointIcon={publishingPointIcon}
      currentDestination={{ title: destination }}
      schedule={publishingAt}
      description={description}
      mMetaData={metadata}
      clipDuration={getDuration(metadata, durationTypes.CLIP_DURATION)}
      scriptDuration={getDuration(metadata, durationTypes.SPEAK_DURATION)}
      totalDuration={getDuration(metadata, durationTypes.TOTAL_DURATION)}
      showMetadata={showMetadata}
      setShowMetadata={setShowMetadata}
      form={form}
      twitterThreadCount={countTwitterThread(metadata, value, getEmptyMetadataForForm(form))}
    />
  );
};

Preview.propTypes = {
  mContentKey: PropTypes.string,
};
Preview.defaultProps = {
  mContentKey: '',
};

export default Preview;
