import BATCH_GETMEMBERS from 'graphql/queries/batchGetRundownInstances';
import { useApolloClient } from 'react-apollo';
import chunkArray from 'utils/arrayUtils';

const useBatchGetMembers = () => {
  
  const client = useApolloClient();
  
  const loadItems = async(rundown)=> {
    const mOrder = rundown.mOrder || [];
    const mPreorder = rundown.mPreorder || [];
    
    const ids = [...mOrder, ...mPreorder];
    
    const idGroups  = chunkArray(ids.filter(id=>id && id[0]!=='-'), 100);

    const promises = idGroups.map(mIds=>{
      return client.query({
        query: BATCH_GETMEMBERS,
        variables: {
          input: { mIds },
        },
        fetchPolicy: 'network-only',
      })
    });
    
    await Promise.all(promises);
  }

  return [loadItems];
};

export default useBatchGetMembers;
