import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => {
  const iconButtonStyle = {
    position: 'absolute',
    width: '24px',
    height: '24px',
    backdropFilter: 'blur(15px)',
    zIndex: 1,
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    '&:hover': {
      backgroundColor: theme.palette.dina.blackHoverOverlay,
    },
  };
  return {
    root: {
      width: ({ width }) => width,
      height: ({ height }) => height,
      backgroundImage: ({ src }) => `url(${src || null})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'relative',
    },
    playButton: {
      ...iconButtonStyle,
      width: '32px',
      height: '32px',
      top: '50%',
      left: '50%',
      transform: 'translate3d(-50%, -50%, 0)',
      padding: 0,
    },
    playImage: {
      width: '32px',
      height: '32px',
    },
    closeButton: {
      ...iconButtonStyle,
      top: '4px',
      right: '4px',
    },
    closeImage: {
      width: '10px',
      height: '10px',
    },
    editButton: {
      ...iconButtonStyle,
      bottom: '4px',
      right: '4px',
    },
    editImage: {
      width: '24px',
      height: '24px',
    },
    tagButton: {
      ...iconButtonStyle,
      bottom: '4px',
      left: '4px',
    },
    tagImage: {
      width: '20px',
      height: '20px',
    },
    duration: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '44px',
      height: '16px',
      borderRadius: '0 4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: ({ isScratchPad }) =>
        theme.palette.dina[isScratchPad ? 'buttonBackgroundHighEmphasis' : 'blackInactive'],
      backdropFilter: 'blur(15px)',
      // opacity: 0.5,
      ...theme.typography.dina.captionRegular,
      letterSpacing: '.25px',
      lineHeight: '13px',
      color: theme.palette.dina.highEmphasis,
    },
    video: {
      borderRadius: '4px',
      outline: 'none',
      background: 'linear-gradient(180deg, rgba(1,0,5,0.7) 0%, rgba(1,0,5,0.7) 0%)',
    },
  };
});

export default useStyles;
