import React from 'react';
import PropTypes from 'prop-types';
import {
  addWeeks,
  getISOWeek,
  eachDay,
  format,
  isToday,
  isSameMonth,
  isWeekend,
  isSameDay,
  isThisMonth,
  getISODay,
  isWithinRange,
} from 'date-fns';
import { Typography } from '@material-ui/core';
import Divider from 'components/divider';
import getDates, { TimeVariant } from 'utils/getDates';
import chunkArray from 'utils/arrayUtils';
import DatePicker from '../datePicker';
import useStyles from './dateSelector-styles';

const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const getWeekNumbers = time => {
  const weekNumbers = [...Array(6).keys()].map(a => {
    return getISOWeek(addWeeks(time, a));
  });
  return weekNumbers;
};

const selectCalendarHeaderStyle = index => {
  const date = new Date();
  if (isThisMonth(date) && getISODay(date) === index + 1) return 'cellTextToday';
  if (index > 4) return 'cellTextHoliday';
  return 'cellText';
};

const DateSelector = ({
  date,
  setDate,
  onDateSelect,
  selectedDate,
  startDate,
  endDate,
  isSelectingRange,
  ...rest
}) => {
  const classes = useStyles();

  const { startDate: sDate, endDate: eDate } = getDates(date, TimeVariant.Month);

  const weekNumbers = getWeekNumbers(sDate);

  const isCurrentWeek = getISOWeek(new Date());

  const chunkedArray = chunkArray(eachDay(sDate, eDate), 7);

  const dateSelectedChecker = selectedRangeDate => {
    if (!isSelectingRange) return [isSameDay(selectedRangeDate, selectedDate), null];
    if (!startDate) return [false, null];
    if (!endDate) return isSameDay(selectedRangeDate, startDate) ? [true, 'left'] : [false, null];
    if (!isWithinRange(selectedRangeDate, startDate, endDate)) return [false, null];
    if (isSameDay(startDate, endDate)) return [true, null];
    if (isSameDay(selectedRangeDate, endDate)) return [true, 'right'];
    if (isSameDay(selectedRangeDate, startDate)) return [true, 'left'];
    return [true, 'middle'];
  };

  return (
    <div className={classes.root}>
      <div className={classes.calendarHeader}>
        <div className={classes.cell}>
          <Typography classes={{ root: classes.weekText }}>Week</Typography>
        </div>
        {daysOfWeek.map((dayOfWeek, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={classes.cell} key={index}>
            <Typography
              classes={{
                root: classes[selectCalendarHeaderStyle(index)],
              }}
            >
              {dayOfWeek}
            </Typography>
          </div>
        ))}
      </div>
      <Divider />

      <div className={classes.calendarBody}>
        {weekNumbers.map((week, index) => (
          <div className={classes.calendarRow} key={week}>
            <div className={classes.row}>
              <div className={classes.cell}>
                <Typography
                  classes={{
                    root: classes[isCurrentWeek === week ? 'cellTextToday' : 'weekText'],
                  }}
                >
                  {week}
                </Typography>
              </div>
              <Divider orientation="vertical" flexItem />

              {chunkedArray[index].map(d => {
                const [isSelected, rangePosition] = dateSelectedChecker(d);
                return (
                  <div
                    role="presentation"
                    className={classes.cell}
                    onClick={() => {
                      setDate(d.toISOString());
                      onDateSelect(d);
                    }}
                    key={d}
                  >
                    <DatePicker
                      text={format(d, 'D')}
                      isWeekend={isWeekend(d)}
                      isOtherMonth={!isSameMonth(d, date)}
                      isToday={isToday(d)}
                      isSelected={isSelected}
                      rangePosition={rangePosition}
                      {...rest}
                    />
                  </div>
                );
              })}
            </div>
            <Divider className={classes.dateDivider} />
          </div>
        ))}
      </div>
    </div>
  );
};

DateSelector.propTypes = {
  date: PropTypes.string.isRequired,
  setDate: PropTypes.func,
  selectedDate: PropTypes.string,
  onDateSelect: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  isSelectingRange: PropTypes.bool,
};

DateSelector.defaultProps = {
  setDate: () => {},
  selectedDate: new Date().toISOString(),
  onDateSelect: () => {},
  startDate: '',
  endDate: '',
  isSelectingRange: false,
};

export default DateSelector;
