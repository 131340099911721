import { useContext, useEffect, useState } from 'react';
import { useApolloClient } from 'react-apollo';
import memberTypes from 'graphql/memberTypes';
import { getMembersOfQuery, getMembersOfTypeQuery } from 'graphql/queryVariables';
import configCtx from 'contexts/configContext';
import { UsersContext } from 'globalState/users';
import { MembersContext } from 'globalState/members';
import GET_MEMBERSOF from '../queries/getMembersOf';
import GET_MEMBERS_FROM_MID from '../queries/getMembersFromId';
import GET_CONFIGS from '../queries/getConfigs';
import GET_METADATA_FORMS from '../queries/getMetadataForms';
import GET_USERS from '../queries/getUsers';
import GET_TEAMS from '../queries/getTeams';
import GET_DEPARTMENTS from '../queries/getDepartments';
import GET_POLICY from '../queries/getPolicy';
import GET_SETTINGS from '../queries/getSettings';
import {
  saveAutoTempOnContext,
  savePolicyOnContext,
  saveExtUrlOnContext,
  saveFeedOnContext,
  saveIptcMediaOnContext,
  saveMetaFormOnContext,
  saveSettingsOnContext,
} from './utils';

async function InitialQueries(mId, groups = [], setInitialLoading) {
  const [skip, setSkip] = useState(false);

  const config = useContext(configCtx);

  const { updateMembers } = useContext(MembersContext);
  const { setUsers } = useContext(UsersContext);

  useEffect(() => {
    setSkip(true);
  }, []);

  const client = useApolloClient();

  const fetchPolicy = !skip ? 'network-only' : 'cache-only';

  const policyPromise = client.query({
    query: GET_POLICY,
    variables: { input: { mId: 'groupPolicy' } },
    fetchPolicy,
    skip,
  });

  const extUrlPromise = client.query({
    query: GET_CONFIGS,
    variables: { input: { mId: 'externalurl' } },
    fetchPolicy,
    skip,
  });

  const automationPromise = client.query({
    query: GET_CONFIGS,
    variables: { input: { mId: 'automation' } },
    fetchPolicy,
    skip,
  });

  const feedPromise = client.query({
    query: GET_CONFIGS,
    variables: { input: { mId: 'feed' } },
    fetchPolicy,
    skip,
  });

  const metadataPromise = client.query({
    query: GET_METADATA_FORMS,
    variables: { input: { mId: 'form' } },
    fetchPolicy,
    skip,
  });

  const getUserPromise = client.query({
    query: GET_USERS,
    variables: getMembersOfTypeQuery(memberTypes.USER),
    fetchPolicy,
    skip,
  });

  const teamPromise = client.query({
    query: GET_TEAMS,
    variables: getMembersOfTypeQuery(memberTypes.TEAM),
    fetchPolicy,
    skip,
  });

  const deptPromise = client.query({
    query: GET_DEPARTMENTS,
    variables: getMembersOfTypeQuery(memberTypes.DEPARTMENT),
    fetchPolicy,
    skip,
  });

  const settingsPromise = client.query({
    query: GET_SETTINGS,
    variables: {
      generalSettingsInput: { mId: 'settings', mRefId: 'general' },
      userSettingsInput: { mId: 'settings', mRefId: mId },
    },
    fetchPolicy,
    skip,
  });

  const medtopPromise = client.query({
    query: GET_MEMBERS_FROM_MID,
    variables: { input: { mId: 'medtop' } },
    fetchPolicy,
    skip,
  });

  const userPromise = client.query({
    query: GET_MEMBERSOF,
    variables: getMembersOfQuery(mId, memberTypes.USER),
    fetchPolicy,
    skip,
  });

  const teamUserPromise = client.query({
    query: GET_MEMBERSOF,
    variables: getMembersOfQuery(mId, memberTypes.TEAM_USER),
    fetchPolicy,
    skip,
  });

  const deptUserPromise = client.query({
    query: GET_MEMBERSOF,
    variables: getMembersOfQuery(mId, memberTypes.DEPARTMENT_USER),
    fetchPolicy,
    skip,
  });

  const contactPromise = client.query({
    query: GET_USERS,
    variables: getMembersOfTypeQuery(memberTypes.CONTACT),
    fetchPolicy,
    skip,
  });

  const promises = [
    policyPromise,
    extUrlPromise,
    automationPromise,
    feedPromise,
    metadataPromise,
    getUserPromise,
    teamPromise,
    deptPromise,
    settingsPromise,
    medtopPromise,
    userPromise,
    teamUserPromise,
    deptUserPromise,
    contactPromise,
  ];

  const responses = await Promise.allSettled(promises);

  const [
    policyResult,
    extUrlResult,
    automationResult,
    feedResult,
    metadataResult,
    getUserResult,
    teamResult,
    deptResult,
    settingsResult,
    medtopResult,
  ] = responses.map(response => response.value);

  if (
    !policyResult.loading &&
    !extUrlResult.loading &&
    !automationResult.loading &&
    !feedResult.loading &&
    !metadataResult.loading &&
    !getUserResult.loading &&
    !teamResult.loading &&
    !deptResult.loading &&
    !settingsResult.loading &&
    !medtopResult.loading
  ) {
    // Save policies on context

    savePolicyOnContext(groups, policyResult, config);

    // save externalUrl config on context

    saveExtUrlOnContext(extUrlResult, config);

    // save automation config on context

    saveAutoTempOnContext(automationResult, config);

    // save feed config on context
    saveFeedOnContext(feedResult, config);

    // save metadta config on context
    saveMetaFormOnContext(metadataResult, config);

    // save settings on context

    saveSettingsOnContext(settingsResult, config);

    // save getUser results on context

    const { getMembersOftype } = getUserResult.data;

    updateMembers(getMembersOftype, memberTypes.USER);
    setUsers(getMembersOftype);

    // save team on context

    updateMembers(teamResult.data.getMembersOftype, memberTypes.TEAM);

    // save department on context

    updateMembers(deptResult.data.getMembersOftype, memberTypes.DEPARTMENT);

    // save iptcMediaTopic config on context
    saveIptcMediaOnContext(medtopResult, config);

    setInitialLoading(false);
  }
}

export default InitialQueries;
