import { makeStyles } from '@material-ui/styles';

const useStyles = image =>
  makeStyles(theme => ({
    headerContainer: {
      // eslint-disable-next-line max-len
      backgroundImage: `linear-gradient(180deg, rgba(50,62,77, 0.65) 0%, rgba(32,42,54,0.95) 100%), url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '128px',
      marginTop: '20px',
    },
    content: {
      padding: '17px',
      display: 'flex',
    },
    avatarDiv: {
      height: '97px',
      width: '79px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    avatar: {
      '&:focus': {
        outline: 'transparent',
      },
      display: 'flex',
      justifyContent: 'center',
    },
    coverDiv: {
      ...theme.typography.dina.durationLabel,
      color: theme.palette.dina.mediumEmphasis,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.dina.highEmphasis,
      },
      '&:focus': {
        outline: 'transparent',
      },
    },
    option: {
      width: '256px',
    },
    inputDiv: {
      width: '100%',
      marginLeft: '10px',
      display: 'flex',
      marginTop: '12px',
    },
    input: {
      marginLeft: '8px',
    },
    dailyExclusiveSwitch: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '48px',
      color: theme.palette.dina.highEmphasis,
    },
  }));

export default useStyles;
