import { makeStyles } from '@material-ui/styles';

const useStyles = (width, height) =>
  makeStyles(() => ({
    root: {
      position: 'relative',
      width,
      height,
      cursor: 'pointer',
    },
  }));

export default useStyles;
