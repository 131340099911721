import rundownItemTypes, { primaryTypes, secondaryTypes } from 'utils/rundownItemTypes';

const voidTypes = {
  HORIZONTAL_RULE: 'horizontal-rule',
  IMAGE: 'image',
  MENTION: 'mention',
  PLACEHOLDER: 'placeholder',
  VIDEO: 'video',
  DESCRIPTION: 'description',
  GIF: 'gif',
  INFO: 'info',
  TAGS: 'tags',
  TITLE: 'title',
  FACT: 'fact',
  PRIVACY: 'privacy',
  MEDIA: 'media',
  LOCATION: 'location',
  CHECK_IN: 'check-in',
  QUOTE_BOX: 'quote',
  primaryTypes,
  secondaryTypes,
  ...rundownItemTypes,
};

export default voidTypes;
