import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format, isThisMinute } from 'date-fns';
import InputField from 'components/inputField/InputField';
import Popper from 'components/shared/popper';
import Calendar from 'components/calendar';
import { InputAdornment, IconButton, ClickAwayListener } from '@material-ui/core';
import Popover from 'components/popover/Popover';
import getRelativeDate from 'utils/getRelativeDate';
import capitalize from 'utils/capitalize';
import DateTimePicker from 'components/dateTimePicker';
import useStyles from './picker-styles';

const WithPopover = ({ children, handleClose, anchorEl }) => (
  <Popover onClose={handleClose} anchorEl={anchorEl}>
    <ClickAwayListener onClickAway={handleClose}>
      <div>{children}</div>
    </ClickAwayListener>
  </Popover>
);

const Picker = props => {
  const {
    Icon,
    SecondaryIcon,
    displaySecondaryIcon,
    type,
    label,
    timeValue,
    disable,
    onChange,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayValue, setDisplayValue] = useState('n/a');
  const formatString = type === 'time' ? 'HH:mm' : 'YYYY-MM-DD';

  useEffect(() => {
    !disable && type === 'time' && setDisplayValue(format(timeValue, 'HH:mm'));
    !disable && isThisMinute(timeValue) && setDisplayValue('Now');
    type === 'date' && setDisplayValue(capitalize(getRelativeDate(timeValue, 'ddd. D MMM. YYYY')));
  }, [disable, timeValue, type]);

  const openPicker = event => {
    !disable && setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handles change of date
   * @param {Date} date - date object with zero time component (midnight)
   */
  const handleDateChange = date => {
    onChange(date);
    setAnchorEl(null);
  };

  /**
   * Handles change of the time component
   * @param {string} timeStr - new time value: "HH:mm"
   */
  const handleTimeChange = timeStr => {
    const [hours, minutes] = timeStr.split(':');
    const newTime = new Date(timeValue);
    newTime.setHours(hours, minutes, 0, 0);
    onChange(newTime);
    setAnchorEl(null);
  };

  return (
    <div>
      <InputField
        onClick={openPicker}
        label={label}
        type="text"
        readOnly
        value={displayValue}
        endAdornment={
          <InputAdornment position="end">
            <IconButton className={classes.iconButton} tabIndex="0">
              {displaySecondaryIcon ? <SecondaryIcon /> : <Icon />}
            </IconButton>
          </InputAdornment>
        }
      />
      {type === 'date' ? (
        <Popper {...{ anchorEl }} placement="left">
          <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.calendarContainer}>
              <Calendar
                selectedDate={timeValue || new Date()}
                changeSelectedDate={handleDateChange}
                hideUnscheduleButton
              />
            </div>
          </ClickAwayListener>
        </Popper>
      ) : (
        <WithPopover
          {...{
            handleClose,
            anchorEl,
          }}
        >
          <DateTimePicker
            type={type}
            defaultDate={format(timeValue, formatString)}
            onBlur={event => handleTimeChange(event.target.value)}
            autoFocus
          />
        </WithPopover>
      )}
    </div>
  );
};

Picker.prototype = {
  /** Icon to be shown as end adornment of the input */
  Icon: PropTypes.node,
  /** Icon to be shown instead of first icon */
  SecondaryIcon: PropTypes.node,
  /** boolean to show secondary icon instead of first */
  displaySecondaryIcon: PropTypes.bool,
  /** Type of Picker */
  type: PropTypes.oneOf(['date', 'time']),
  /** Label of the input field */
  label: PropTypes.string,
  /** time to be changed */
  timeValue: PropTypes.string,
  /** Disables opening the popover */
  disable: PropTypes.bool,
  /** Callback to be invoked when time is changed. Value given as Date()  */
  onChange: PropTypes.func,
};

Picker.defaultProps = {
  Icon: '',
  SecondaryIcon: '',
  displaySecondaryIcon: false,
  type: 'date',
  label: 'Date',
  timeValue: new Date().toISOString(),
  disable: false,
  onChange: () => {},
};

export default Picker;
