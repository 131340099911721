import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  automationIcon: {
    position: 'absolute',
    top: -1,
    left: -1,
  },
}));

export default useStyles;
