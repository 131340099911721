import React from 'react';
import SlidingButtonWrapper from 'components/buttons/wrapper/SlidingButtonWrapper';
import { IconButton as MaterialIconButton } from '@material-ui/core';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import useStyles from './listItem-styles';

const IconButton = ({ icon, variant, onClick }) => {
  const classes = useStyles();
  return (
    <MaterialIconButton
      classes={{ root: classes[variant] }}
      onClick={event => {
        event.stopPropagation();
        onClick(event);
      }}
      tabIndex="-1"
    >
      {icon}
    </MaterialIconButton>
  );
};

const ListActionButton = ({ onClose, onDelete, ...rest }) => {
  return (
    <SlidingButtonWrapper {...rest}>
      <IconButton icon={<Delete />} onClick={onDelete} variant="delete" />
      <IconButton icon={<Close />} onClick={onClose} variant="close" />
    </SlidingButtonWrapper>
  );
};

export default ListActionButton;
