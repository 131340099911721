import { makeStyles } from '@material-ui/styles';

const useStyles = (selected, rundown) => makeStyles(theme => ({
  button: {
    height: '32px',
    minWidth: '112px',
    background: selected
      ? theme.palette.dina.highEmphasis
      : 'none',
    ...theme.typography.dina.listItemLabel,
    color: () => {
      if(!selected)
        return theme.palette.dina.mediumEmphasis;
      return rundown ? theme.palette.dina.paletteAccentPurple : theme.palette.dina.onSelected;
    },
    border: `1px solid ${theme.palette.dina.mediumEmphasis}`,
    textTransform: 'capitalize',
    '&:hover': {
      background: selected
      ? theme.palette.dina.highEmphasis
      : 'none',
    },
  },
  root: {
    borderRadius: '20px',
  },
}));

export default useStyles;