import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    maxWidth: ({ buttonWidth }) => buttonWidth,
    maxHeight: ({ buttonHeight }) => buttonHeight,
    minWidth: ({ buttonWidth }) => buttonWidth,
    minHeight: ({ buttonHeight }) => buttonHeight,
    padding: '0',
  },
}));

export default useStyles;
