import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 60px)',
    margin: '0 14px',
  },
  root: {
    height: 'calc(100% - 64px)',
  },
}));

export default useStyles;
