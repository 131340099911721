/**
 *  Hook for creating a new story from Pitch and attach it to a user.
 *  Input: pitchId and userId
 *  Returns the story id of the created story.
 *
 */

import { useMutation } from 'react-apollo';
import GET_STORIES from 'graphql/queries/getStoriesPagination';
import CREATE_STORY_FROM_PITCH from 'graphql/mutations/createStoryFromPitch';
import { getStoriesQuery } from 'graphql/queryVariables';
import memberTypes from 'graphql/memberTypes';

const useCreateStoryFromPitch = () => {
  const [createStoryFromPitch] = useMutation(CREATE_STORY_FROM_PITCH);
  let createdStoryId;

  const createStory = async (mId, userId) => {
    await createStoryFromPitch({
      variables: {
        input: {
          mId,
          mCreatedById: userId,
        },
      },
      update: (proxy, mutationResult) => {
        const { createStoryFromPitch: createdStory } = mutationResult.data;

        try {
          const storyList = proxy.readQuery({
            query: GET_STORIES,
            variables: getStoriesQuery(userId, memberTypes.USER_STORY),
          });

          // Check if the story is in the storyList, if not add it to the list
          // this is to see if the story is already in the cache to avoid duplicates
          const exists = story => {
            return story !== null && story.mId === createdStory.mId;
          };

          if (!storyList.getMembersPagination.items.some(exists)) {
            storyList.getMembersPagination.items.unshift(createdStory);
          }

          proxy.writeQuery({
            query: GET_STORIES,
            variables: getStoriesQuery(userId, memberTypes.USER_STORY),
            data: storyList,
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
        createdStoryId = createdStory.mId;
      },
    });

    return createdStoryId;
  };

  return createStory;
};

export default useCreateStoryFromPitch;
