import React from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItem, SvgIcon, Divider } from '@material-ui/core';
import { ReactComponent as AddItemIcon } from 'assets/icons/systemicons/CTA_Resting.svg';
import useStyles from './add-item-styles';

const AddItem = props => {
  const { title, onClick } = props;
  const classes = useStyles();

  return (
    <div>
      <ListItem
        button
        classes={{ button: classes.button, root: classes.listItem }}
        onClick={onClick}
      >
        <SvgIcon classes={{ root: classes.icon }}>
          <AddItemIcon />
        </SvgIcon>
        <Typography classes={{ root: classes.titleLine }}>{title}</Typography>
      </ListItem>

      <Divider classes={{ root: classes.divider }} />
    </div>
  );
};

AddItem.propTypes = {
  /** Title to be shown beside the add icon */
  title: PropTypes.string,
  /** Callback to be invoked on on click */
  onClick: PropTypes.func,
};

AddItem.defaultProps = {
  title: '',
  onClick: () => {},
};

export default AddItem;
