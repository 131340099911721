import { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import {format} from 'date-fns';
import Storage from '@aws-amplify/storage';
import GET_AUDITS from 'graphql/queries/getVersionAudits';


const useVersionHistory = (instanceId, users) => {
  const [reFetchList, setReFetchList] = useState(false);
  const [contentKey, setContentKey] = useState(null);
  const [versions, setVersions] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);
  const [currentVersionContent, setCurrentVersionContent] = useState(null);

  const refetchAuditListCallback = () => {
    setReFetchList(previousState => !previousState);
  };
  const refetchVersionContentCallback = (cKey) => {
    setContentKey(cKey);
  };

  const getSpecificVersionContent = async cKey => {
    const s3Url = await Storage.get(cKey, { customPrefix: {public: ''}});
    const s3Resp = await fetch(s3Url);
    return s3Resp.json();
  }

  const {data: versionData, loading: auditListLoading, refetch: refetchVersions} = useQuery(GET_AUDITS, {
    variables: {
      input: {
        mId: instanceId,
        mActionId: 'version',
      },
    },
  });

  useEffect(()=>{
    refetchVersions();
  }, [reFetchList, refetchVersions]);

  useEffect(() => {
      // eslint-disable-next-line max-len
    const currentVersions = versionData && versionData.getAudits ? versionData.getAudits.map(version => {
      const updatedBy = users.filter(u => u.mId === version.mUpdatedBy);
      return {
        timestamp: format(version.mUpdatedAt, 'MMM. DD YYYY, HH:mm'),
        updatedBy: updatedBy.length > 0 && updatedBy[0].mTitle ? updatedBy[0].mTitle : '',
        contentKey: version.mPayload.mContentKey,
      };
    }) : [];
    setVersions(currentVersions);
    if(currentVersions.length > 0){
        setContentKey(currentVersions[0].contentKey);
    }
  }, [versionData, users, reFetchList]);

  useEffect(() => {
      (async () => {
        if(versions.length > 0){
         setContentLoading(true);
         const currentContent = await getSpecificVersionContent(contentKey);
         setCurrentVersionContent(currentContent);
         setContentLoading(false);
        }
      })();
  }, [versions, contentKey])

  return {
    versions, 
    currentVersionContent, 
    auditListLoading,
    versionContentLoading: contentLoading,
    refetchVersionList: refetchAuditListCallback, 
    refetchVersionContent: refetchVersionContentCallback}
};

export default useVersionHistory;