import React from 'react';
import GET_INSTANCE_FROM_CACHE from 'graphql/queries/getInstanceFromLocalResolver';
import { useDrag, DragPreviewImage } from 'react-dnd';
import { useSelectionContext } from 'contexts/SelectionContext';
import dndTypes from 'utils/dndTypes';
import { useQuery } from 'react-apollo';
import truncateString from './utils/truncateString';
import GridItem from './general-grid-item';
import createDragPreview from './createDragImage';

const DraggableGridItem = ({
  instance,
  index,
  key,
  selectionCount,
  scrollingId,
  style,
  disableEdit,
  ...rest
}) => {
  const { mId } = instance;
  const skip = mId[0] === '-';

  const payload = {
    index,
    mId,
    scrollingId,
  };

  const [selectedIds, setSelectedIds] = useSelectionContext();

  const [{ isDragging, dragged }, dragRef, preview] = useDrag({
    item: { type: dndTypes.GRID_INSTANCE, payload },
    canDrag: monitor => {
      if (disableEdit) return false;
      if (!selectionCount) return true;
      if (selectionCount === 1 && !selectedIds.includes(mId)) {
        setSelectedIds([]);
        return true;
      }
      if (!selectedIds.includes(mId)) {
        setSelectedIds([]);
        return false;
      }

      return true;
    },
    end: (dropResult, monitor) => {
      if (!monitor.didDrop()) setSelectedIds([]);
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      dragged: monitor.getItem(),
    }),
  });

  const { data } = useQuery(GET_INSTANCE_FROM_CACHE, {
    variables: {
      input: {
        mId,
        mRefId: mId,
      },
    },
    skip,
  });

  if (!data && !skip) return <div ref={dragRef} style={style} id={instance.mId} />;

  const cachedInstance = skip ? { mId } : data.instance;

  const insTitle = skip ? 'Empty placeholder' : truncateString(cachedInstance.mTitle, 18);

  const dragInfo = selectionCount > 1 ? `Moving ${selectionCount} items` : `${insTitle}`;

  return (
    <div ref={dragRef} style={style} id={instance.mId}>
      <DragPreviewImage connect={preview} src={createDragPreview(dragInfo)} />
      <GridItem
        instance={cachedInstance}
        {...{
          index,
          scrollingId,
          isDragging,
          dragged,
          selectionCount,
          disableEdit,
        }}
        {...rest}
      />
    </div>
  );
};

export default DraggableGridItem;
