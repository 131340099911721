import React, { memo } from 'react';
import PropTypes from 'prop-types';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import Tooltip from 'components/tooltip';
import useStyles from './styles';

const MemoizedTooltip = memo(Tooltip);

const ButtonBase = ({ IconComponent, type, isActive, onMouseDown }) => {
  const classes = useStyles({ isActive });

  return (
    <MemoizedTooltip title={tooltipEnum[type]}>
      <IconComponent className={classes.root} {...{ onMouseDown }} />
    </MemoizedTooltip>
  );
};

ButtonBase.propTypes = {
  /** Component to render as icon */
  IconComponent: PropTypes.elementType,
  /** Specifies if the button should show is active styles or not */
  isActive: PropTypes.bool,
  /** Callback to be invoked on mouse click */
  onMouseDown: PropTypes.func,
};

ButtonBase.defaultProps = {
  IconComponent: () => null,
  isActive: false,
  onMouseDown: () => {},
};

export default ButtonBase;
