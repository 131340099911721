import { makeStyles } from '@material-ui/styles';
import image from 'assets/images/PitchBackgroundOverlay.png';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow:
      // eslint-disable-next-line max-len
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '2px',
    backgroundImage: ({ isPitch }) => (isPitch ? `url(${image})` : null),
    backgroundColor: theme.palette.dina.surfaceCard,
    marginBottom: '2px',
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },
  card: {
    height: '48px',
    width: '100%',
    boxShadow: '4px 0px 8px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    display: 'flex',
  },
  cardRoot: {
    width: '240px',
    minWidth: '240px',
  },
  storyBox: {
    width: '327px',
    height: '830px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
}));

export default useStyles;
