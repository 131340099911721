import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { mediaTypes } from 'utils/rundownItemTypes';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import DropZone from 'components/editor/components/dropZone';
import addGraphics from './utils/addGraphics';

const { GRAPHICS } = mediaTypes;

const MediaDropZone = ({ children, element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();

  const onDrop = useCallback(
    ({ payload }) => addGraphics(editor, element, payload, update),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  return (
    <DropZone accept={[GRAPHICS]} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

MediaDropZone.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

MediaDropZone.defaultProps = {
  children: null,
  element: {
    children: [],
  },
};

export default memo(MediaDropZone);
