import gql from 'graphql-tag';

export default gql`
  query GetMember($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mUpdatedAt
      locked
      mContentUrl
    }
  }
`;
