import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'grab',
    outline: 'none',

    '&.published': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  },

  cross: {
    cursor: 'pointer',
    '& path': { fill: theme.palette.dina.statusError },
  },
}));

export default useStyles;
