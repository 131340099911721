import React, { useState } from 'react';
import Divider from 'components/divider';
import Dialog from 'components/dialog';
import Searchbar from 'components/searchbar';
import Scrollbar from 'screens/main/components/scrollbar';
import RelevantHeader from '../../relevantHeader';
import menuItems from './menuItems';
import actionTypes from './actionTypes';
import CreateTeam from '../createTeam';
import useStyles from './master-styles';
import TeamListContainer from './team-list-container';

const TeamsView = ({ showDetail, containerRef }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(<div />);
  const [filters, setFilters] = useState([]);

  const onClick = ({ id, name, image, imageKey, description }) =>
    showDetail({
      teamId: id,
      title: name,
      avatarUrl: image,
      imageKey,
      description,
    });

  const onMenuItemClick = action => {
    switch (action) {
      case actionTypes.CREATE_TEAM:
        setDialogContent(
          <CreateTeam
            onCancel={() => setDialogOpen(false)}
            onSubmit={team => {
              showDetail({
                teamId: team.mId,
                title: team.mTitle,
                imageKey: team.mAvatarKey,
                avatarUrl: team.mAvatarUrl,
                description: team.mDescription,
              });
              setDialogOpen(false);
            }}
          />,
        );
        setDialogOpen(true);
        break;
      default:
        setDialogContent(<div />);
        setDialogOpen(false);
        break;
    }
  };

  return (
    <>
      <RelevantHeader headerTitle="Teams" {...{ menuItems, onMenuItemClick }} />
      <Dialog
        open={dialogOpen}
        container={containerRef.current}
        BackdropProps={{ classes: { root: classes.backdropRoot } }}
        onClose={() => setDialogOpen(false)}
      >
        {dialogContent}
      </Dialog>
      <Divider />
      <div className={classes.teams}>
        <Scrollbar>
          <TeamListContainer filters={filters} {...{ onClick }} />
        </Scrollbar>
      </div>
      <div className={classes.filterBar}>
        <Searchbar chips={filters} chipChangeHandler={chips => setFilters(chips)} />
      </div>
    </>
  );
};

export default TeamsView;
