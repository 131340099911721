import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: theme.palette.dina.iconInactive,
    height: 40,
    lineHeight: '17px',

    '&.today:not(.static-selected):not(.animated-selected):not(.drag-selected)': {
      color: theme.palette.dina.onSelected,
    },

    '&.animated-selected': {
      color: 'white',
    },

    '&.static-selected': {
      color: 'white',
      position: 'relative',

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '105%',
        height: '100%',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        background: theme.palette.dina.onSelected,
        zIndex: -1,
      },

      '&.static-selected-first:before': {
        width: 'calc((100% - 40px) / 2 + 40px)',
        left: 'calc((100% - 40px) / 2)',
        transform: 'none',
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
      },

      '&.static-selected-last:before': {
        width: 'calc((100% - 40px) / 2 + 40px)',
        left: 0,
        transform: 'none',
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
      },

      '&.static-selected-last.static-selected-first:before': {
        width: 40,
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
  },
}));

export default useStyles;
