import React, { useState, useMemo, useCallback, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { pipe } from 'lodash/fp';
import colors from 'theme/colors';
import { createEditor } from 'slate';
import { Slate, withReact, Editable, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import preventDefaultEvent from 'utils/preventDefaultEvent';
import stopEventPropagation from 'utils/stopEventPropagation';
import variants from 'utils/instance/variants';
import Scrollbar from 'screens/main/components/scrollbar';
import { initialValues, actionTypes, elementComponents } from './constants';
import ReorderDropZone from './components/reorderDropZone';
import Paragraph from './components/paragraph';
import Leaf from './components/leaf';
import Toolbar from './components/toolbar';
import onParagraphKeyDown from './components/paragraph/utils/onParagraphKeyDown';
import onLeafKeyDown from './components/leaf/utils/onLeafKeydown';
import onQuoteKeyDown from './components/quote/utils/onQuoteKeyDown';
import onListKeyDown from './components/list/utils/onListKeyDown';
import { withLink, onLinkKeyDown } from './components/link/utils';
import onHorizontalRuleKeyDown from './components/horizontalRule/utils/onHorizontalRuleKeyDown';
import onPrimaryKeyDown from './components/primaryAutomation/utils/onPrimaryKeyDown';
import Suggestions from './components/mention/components/suggestions';
import EditorContext from './EditorContext';
import HoveringTooltip from './components/hoveringTooltip';
import {
  onAssetElementKeyDown,
  onElementKeyDown,
  onVoidKeyDown,
  withInline,
  withNormalization,
  withVoid,
} from './utils';
import useStyles from './styles';

const wrappedEditor = pipe(
  createEditor,
  withReact,
  withHistory,
  withNormalization,
  withVoid,
  withInline,
  withLink,
);

const Editor = ({
  background,
  defaultPlaceholderTitle,
  height,
  hostReadSpeed,
  placeholder,
  readOnly,
  renderToolbar,
  update,
  users,
  value,
  variant,
  shouldResetSelection,
  width,
  thumbnail,
  isAllowed,
  isFixedHeightEditor,
  onTabChange,
}) => {
  const classes = useStyles({ width, height, background, readOnly });
  const containerRef = useRef(null);
  const editor = useMemo(() => wrappedEditor(), []);
  const { document: initialDocument, ...rest } = value || initialValues(variant, isAllowed);

  const [document, setDocument] = useState(initialDocument);

  const resetSelection = () => {
    const { deselect, blur } = ReactEditor;
    deselect(editor);
    blur(editor);
  };

  useEffect(() => {
    resetSelection();
    if (value) {
      setDocument(value.document);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (shouldResetSelection) {
      resetSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldResetSelection]);

  const renderElement = useCallback(props => {
    const ElementComponent = elementComponents[props.element.type] || Paragraph;

    return <ElementComponent {...props} />;
  }, []);

  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  const handleUpdate = useCallback(
    ({ type, payload }) => update({ type, payload: { ...payload, ...rest } }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update],
  );

  const handleChange = useCallback(
    newValue => {
      setDocument(newValue);
      handleUpdate({
        type: actionTypes.CHANGE,
        payload: { document: newValue },
      });
    },
    [handleUpdate],
  );

  const onKeyDown = useCallback(
    event => {
      onLeafKeyDown(editor, event);
      onLinkKeyDown(editor, event);
      onElementKeyDown(editor, event, variant, isAllowed);
      onParagraphKeyDown(editor, event, variant, isAllowed);
      onQuoteKeyDown(editor, event);
      onListKeyDown(editor, event);
      onHorizontalRuleKeyDown(editor, event);
      onAssetElementKeyDown(editor, event, handleUpdate);
      onPrimaryKeyDown(editor, event, handleUpdate);
      onVoidKeyDown(editor, event, variant, isAllowed);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toolbar = useMemo(() => renderToolbar({ classes, variant, readOnly, isAllowed }), [
    readOnly,
  ]);

  const getEditorTextArea = () => {
    return (
      <div
        className={classes.editableWrapper}
        role="presentation"
        onMouseDown={preventDefaultEvent}
      >
        <ReorderDropZone>
          <Editable
            onMouseDown={stopEventPropagation}
            {...{
              onKeyDown,
              placeholder,
              readOnly,
              renderElement,
              renderLeaf,
            }}
          />
        </ReorderDropZone>
      </div>
    );
  };

  return (
    <div className={classes.root} ref={containerRef}>
      <Slate onChange={handleChange} value={document} {...{ editor }}>
        <EditorContext.Provider
          value={{
            update: handleUpdate,
            containerRef,
            defaultPlaceholderTitle,
            users,
            variant,
            thumbnail,
            isAllowed,
            onTabChange,
          }}
        >
          {toolbar}
          {isFixedHeightEditor ? <Scrollbar>{getEditorTextArea()}</Scrollbar> : getEditorTextArea()}
          <HoveringTooltip {...{ hostReadSpeed }} />
          <Suggestions />
        </EditorContext.Provider>
      </Slate>
    </div>
  );
};

Editor.propTypes = {
  /** Background color for the editor text area */
  background: PropTypes.string,
  /** Default title for placeholder item */
  defaultPlaceholderTitle: PropTypes.string,
  /** Height of the editor view */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Placeholder text to show on empty editable area */
  placeholder: PropTypes.string,
  /** Boolean that indicates a read only editor */
  readOnly: PropTypes.bool,
  /** Render custom toolbar */
  renderToolbar: PropTypes.func,
  /** If true, deselects editor  */
  shouldResetSelection: PropTypes.bool,
  /** Callback to invoked when text editor's value updates,
   * with the update type and relevant payload passed in
   */
  update: PropTypes.func,
  /** List of users to show as suggestions */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      mTitle: PropTypes.string,
    }),
  ),
  /** JSON value for the editor content */
  value: PropTypes.shape({
    version: PropTypes.string,
    document: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        children: PropTypes.array,
        data: PropTypes.shape({}),
      }),
    ),
  }),
  /** Variant of the editor */
  variant: PropTypes.oneOf(Object.values(variants)),
  /** Width of the editor view */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line max-len
  /** If fixed height editor we will add scrollbar in the editor otherwise there will be no scrollbar in the editor */
  isFixedHeightEditor: PropTypes.bool,
};

Editor.defaultProps = {
  background: colors.surfaceCard,
  defaultPlaceholderTitle: '',
  height: 670,
  placeholder: 'Type something here...',
  readOnly: true,
  renderToolbar: ({ classes, variant, readOnly, isAllowed }) => (
    <div className={classes.toolbarWrapper}>
      <Toolbar {...{ variant, readOnly, isAllowed }} />
    </div>
  ),
  shouldResetSelection: false,
  update: ({ type, payload }) => {},
  users: [],
  value: initialValues(variants.GENERAL),
  variant: variants.GENERAL,
  width: '100%',
  isFixedHeightEditor: true,
};

export default memo(Editor);
