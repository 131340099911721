import GET_INSTANCE_BY_PUBLISHING_DATE from 'graphql/queries/getInstancesByPublishingDate';

const getUpdatedList = (list, instance) => {
    const { crudAction } = instance;
    const updatedList = list;
    if (crudAction === "INSERT") {
        const memberExist = updatedList.filter(m => m.mId === instance.mId);
        if (memberExist.length === 0) {
            updatedList.push(instance);
        }
        return updatedList;
    }
    if (crudAction === "REMOVE") {
        return updatedList.filter(m => m.mId !== instance.mId);
    }
    return list;
}


const updateCache = (proxy, instance, startDate, endDate) => {
    const { mType } = instance;
    try {
        const list = proxy.readQuery({
            query: GET_INSTANCE_BY_PUBLISHING_DATE,
            variables: {
                input: {
                    mType,
                    startDate,
                    endDate,
                },
            },
        });
        const updatedList = getUpdatedList(list.getMembersByPublishingDate, instance);
        // Write updated member list in the cache.
        proxy.writeQuery({
            query: GET_INSTANCE_BY_PUBLISHING_DATE,
            variables: {
                input: {
                    mType,
                    startDate,
                    endDate,
                },
            },
            data: { getMembersByPublishingDate: updatedList },
        });


    } catch (e) {
        console.log(e);
    }
}

export default updateCache;