import React from 'react';
import getRelativeDate from 'utils/getRelativeDate';
import Scrollbar from 'screens/main/components/scrollbar';
import capitalize from 'utils/capitalize';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import SortBy from 'screens/story/utils/SortBy';
import Instance from '../instance';

const useStyles = makeStyles(theme => ({
  container: {
    height: ({ collapsed, height }) =>
      collapsed ? `${height - 64}px` : `calc(${height / 2}px - 64px)`,
  },
}));

const getScheduleText = ({ mProperties, mPublishingAt }) => {
  const { platform, account } = mProperties;
  if (platform === 'linear') {
    return account.accountTitle;
  }
  if (mPublishingAt) {
    return `${capitalize(getRelativeDate(mPublishingAt, 'MMM D'))}, ${format(
      mPublishingAt,
      'HH:mm:ss',
    )}`;
  }
  return 'Unscheduled';
};

const Instances = ({ collapsed, height, instances, platforms, onInstanceClick, storyId }) => {
  const classes = useStyles({ collapsed, height });
  return (
    <div className={classes.container}>
      <Scrollbar>
        {SortBy(instances, false).map(instance => (
          <Instance
            key={instance.mId}
            id={instance.mId}
            statusId={instance.mState}
            title={instance.mTitle}
            publishingPoint={instance.mProperties.platform}
            publishingPlatform={platforms.find(
              item => item.mProperties.platform === instance.mProperties.platform,
            )}
            schedule={getScheduleText(instance)}
            onClick={() => onInstanceClick(storyId, instance.mId)}
            publishingAt={instance.mPublishingAt}
            accountId={instance.mProperties.account.accountId}
            accountRefId={instance.mProperties.account.accountRefId}
          />
        ))}
      </Scrollbar>
    </div>
  );
};

export default Instances;
