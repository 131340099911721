import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { storyTimelinePublishingPoints } from 'assets/icons/publishingPoints';
import useStyles from './instances-styles';

const Instances = ({
  mPublishingAt,
  platform,
  xScaleRef,
  offset,
  margin
}) => {
  const classes = useStyles();
  const groupRef = useRef(null);

  const { mProperties } = platform;
  const { platformIcon } = mProperties;

  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);

  const init = () => {
    if (xScaleRef.current) {
      setTranslateX(xScaleRef.current(new Date(mPublishingAt)) + margin);
      setTranslateY(offset);
    }
  };

  useEffect(init);

  return (
    <g
      ref={groupRef}
      className={classes.root}
      transform={`translate(${translateX}, ${translateY})`}
      tabIndex={0}
    >
      <image
        width={24}
        height={24}
        x={0}
        y={0}
        href={
          storyTimelinePublishingPoints[platformIcon]
            ? storyTimelinePublishingPoints[platformIcon]
            : storyTimelinePublishingPoints.default
        }
      />
    </g>
  );
};

Instances.propTypes = {
  /** publishing time of the instance */
  mPublishingAt: PropTypes.string,
  /** publishing platform of instance */
  platform: PropTypes.shape({
    mProperties: PropTypes.shape({
      platformIcon: PropTypes.string,
    }),
  }),
  /** Specifies the current d3 time scale */
  xScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  /** offset for the height of current instance icon */
  offset: PropTypes.number,
};

Instances.defaultProps = {
  mPublishingAt: '',
  platform: { mProperties: { platformIcon: null } },
  offset: 0,
};
export default Instances;
