import { makeStyles } from '@material-ui/styles';

const oneSeventhPercent = `${(1 / 7) * 100}%`;
const oneSixthPercent = `${(1 / 6) * 100}%`;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 105px)',
  },
  header: {
    display: 'flex',
    width: '100%',
    height: '45px',
  },
  dayCellHeader: {
    flex: `1 1 ${oneSeventhPercent}`,
    padding: '2px 7px 0 7px',
    borderTop: `1px solid ${theme.palette.dina.dividerLight}`,
    borderLeft: `1px solid ${theme.palette.dina.dividerLight}`,
    '&:nth-last-child(-n+2)': {
      background: theme.palette.dina.blackHoverOverlay,
    },
  },
  dayCellHeaderTextBlock: {
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dayCellHeaderText: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.mediumEmphasis,
  },
  body: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexWrap: 'wrap',
  },
  dayCell: {
    height: `${oneSixthPercent}`,
    minHeight: '108px',
    width: `${oneSeventhPercent}`,
  },
}));

export default useStyles;
