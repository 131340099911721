import React from 'react';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import StoryCard from '../../hourStoryCard';

const CardContainer = ({ story, openStory, onPriorityChange, instances, platforms, xScaleRef, margin }) => {
  const handlePriorityChange = priorityValue => {
    onPriorityChange(priorityValue, story.mId, story.mPriority);
  };

  const [assignedUsers] = useGetAssignedMembers(story.mAssignedMembers || []);

  return (
    <StoryCard
        id={story.mId}
        priorityLevel={story.mPriority}
        image={story.mThumbnailUrl}
        categories={story.mCategories}
        title={story.mTitle}
        isPitch={story.mType === 'pitch'}
        openStory={openStory}
        onPriorityChange={handlePriorityChange}
        assignees={assignedUsers}
        instances={instances}
        platforms={platforms}
        xScaleRef={xScaleRef}
        margin={margin}
      />
  );
};

export default CardContainer;
