import React, { useRef, useState } from 'react';
import FallbackSrc from 'assets/images/defaultStoryCoverPhoto.jpg';
import { formatDuration } from 'utils/formatDuration';
import FileInput from 'screens/story/utils/coverPhoto/uploadMediaInput';
import Scrollbar from 'screens/main/components/scrollbar';
import Dialog from 'components/dialog';
import { useDrop } from 'react-dnd';
import { mediaTypes } from 'utils/rundownItemTypes';
import Group from '../group';
import AssetItem from './assetItem';
import MediaViewer from './mediaViewer';
import sizeInMB from './utils/sizeInMB';
import IconImage from './utils/Icons';

const isValidArray = array => array && Array.isArray(array);

const getMetadataValue = metadata => prop => {
  const mValue = metadata.find(meta => meta.key === prop);
  return mValue ? mValue.value : mValue;
};

const getExtensionFromUrl = url => {
  const extension = url
    .split(/[#?]/)[0]
    .split('.')
    .pop()
    .trim();
  return extension ? `.${extension}` : null;
};

const CommonAssetGroup = ({
  label,
  asset,
  storyId,
  defaultIcon,
  isVideo,
  onDoubleClick = () => {},
  ...rest
}) => {
  const inputRef = useRef(null);
  const MediaInput = <FileInput storyId={storyId} inputRef={inputRef} />;
  const handleOnAddClick = () => {
    if (inputRef && inputRef.current) inputRef.current.click();
  };
  return (
    <Group label={label} itemCount={asset.length} Input={MediaInput} onAddClick={handleOnAddClick}>
      <div>
        {isValidArray(asset) &&
          asset.map(asst => {
            const extractValueFor = getMetadataValue(asst.mMetaData);

            const info = isVideo
              ? formatDuration(extractValueFor('duration'))
              : sizeInMB(extractValueFor('size'));

            const thumbnail =
              label === 'Photos' || isVideo
                ? asst.mThumbnailUrl || asst.mContentUrl || FallbackSrc
                : IconImage[extractValueFor('extension')] || IconImage[defaultIcon];

            return (
              <AssetItem
                key={asst.mRefId}
                asset={asst}
                fileName={asst.mTitle}
                fileInfo={info}
                thumbnailImage={thumbnail}
                isVideo={isVideo}
                extractValueFor={extractValueFor}
                onDoubleClick={() => onDoubleClick(asst)}
                {...rest}
              />
            );
          })}
      </div>
    </Group>
  );
};

const AssetsView = ({ assetData, storyId, addItemToStoryAsset, storyContainerRef }) => {
  const { image = [], video = [], application = [], audio = [] } = assetData;
  const [openDialog, setOpenDialog] = useState(null);

  const [{ didDrop }, dropRef] = useDrop({
    accept: mediaTypes.CLIP,
    drop(item, monitor) {
      if (!didDrop) {
        const { payload } = item;
        payload && addItemToStoryAsset(payload);
      }
    },
    collect: monitor => ({ didDrop: monitor.didDrop() }),
  });

  const handleDoubleClick = asset => {
    setOpenDialog(asset);
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  const handleDownload = asset => {
    /* const element = document.createElement('a');
    element.href = asset.mContentUrl;
    element.download = `${asset.mTitle}`;
    element.target = '_blank';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element); */

    /**
     * sources:
     * https://stackoverflow.com/questions/40800701/image-does-not-download-with-its-own-extension
     * https://stackoverflow.com/questions/7526849/how-do-i-change-a-filename-on-download-with-javascript/14363246#:~:text=You%20can%20use%20XHR2%20to,and%20then%20click%20the%20link.
     */

    const element = document.createElement('a');

    const { mTitle, mRefId, mMetaData, mContentUrl } = asset;
    const extractValueFor = getMetadataValue(mMetaData);
    const extension = extractValueFor('extension') || getExtensionFromUrl(mContentUrl);
    const fileName = `${mTitle || mRefId}${extension}`;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', asset.mContentUrl, true);
    xhr.responseType = 'blob';

    xhr.onload = () => {
      const file = new Blob([xhr.response], { type: 'application/octet-stream' });
      element.href = window.URL.createObjectURL(file);
      element.download = fileName;
      element.target = '_blank';
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      document.body.removeChild(element);
    };

    xhr.send();
  };

  return (
    <Scrollbar>
      <div style={{ height: '100%' }} ref={dropRef}>
        <CommonAssetGroup
          label="Photos"
          asset={image}
          storyId={storyId}
          onDoubleClick={handleDoubleClick}
        />
        <CommonAssetGroup
          label="Videos"
          isVideo
          asset={video}
          storyId={storyId}
          onDoubleClick={handleDoubleClick}
        />
        <CommonAssetGroup
          label="Documents"
          asset={application}
          storyId={storyId}
          defaultIcon="defaultDoc"
          onDownload={handleDownload}
          onDoubleClick={handleDownload}
          isSmallThumbnail
        />
        <CommonAssetGroup
          label="Audio"
          asset={audio}
          storyId={storyId}
          defaultIcon="defaultAudio"
          isSmallThumbnail
          onDoubleClick={handleDoubleClick}
        />
        <Dialog
          disableScrollLock
          disableRestoreFocus
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
          open={Boolean(openDialog)}
          container={storyContainerRef && storyContainerRef.current}
          onClose={handleCloseDialog}
        >
          {openDialog && (
            <MediaViewer
              getMetadataValue={getMetadataValue}
              onClose={handleCloseDialog}
              asset={openDialog}
            />
          )}
        </Dialog>
      </div>
    </Scrollbar>
  );
};

export default AssetsView;
