const saveMetaFormsOnContext = (metadataResult, config) => {
  config.metadataForms = [
    ...metadataResult.data.getMemberFromId.map(({ mTitle, mRefId, mProperties }) => ({
      mTitle,
      mRefId,
      defaultView: (mProperties && mProperties.defaultView) || null,
      defaultViews: (mProperties && mProperties.defaultViews) || null,
      fields: (mProperties && mProperties.fields) || [],
      views: (mProperties && mProperties.views) || [],
    })),
  ];
};

export default saveMetaFormsOnContext;
