import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    minHeight: '72px',
    justifyContent: 'flex-start',
  },
  divider: {
    backgroundColor: theme.palette.dina.dividerLight,
    // width: '311px',
    width: 'calc(100% - 16px)',
    marginLeft: '16px',
  },
  button: {
    '&:hover': {
      background: theme.palette.dina.surfaceAppBackgroundMain,
    },
    '&:focus': {
      backgroundColor: theme.palette.dina.onFocus,
    },
  },
  titleLine: {
    ...theme.typography.dina.listItemLabel,
    marginLeft: '24px',
    width: '215px',
  },
  icon: {
    marginLeft: '10px',
    height: '24px',
    width: '24px',
  },
}));

export default useStyles;
