import { makeStyles } from '@material-ui/styles';

const marginTop = 4;

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    '& .colorBox': {
      fill: ({ markColor }) => markColor,
    },
  },
  paper: {
    background: 'transparent',
    marginTop,
  },
}));

export default useStyles;
