import gql from 'graphql-tag';

export default gql`
  query GetStoriesByUpdatedDate(
    $input: GetMembersByPublishingDateInput
    $limit: Int
    $nextToken: String
  ) {
    getMembersByUpdatedAtDate(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
        mTitle
        mDescription
        mAvatarUrl
        mAvatarKey
        mThumbnailKey
        mThumbnailUrl
        mContentKey
        mCreatedAt
        mUpdatedAt
        mState
        mPublishingAt
        mType
        mMetaData {
          key
          value
        }
        mAssignedMembers {
          mId
          mType
        }
        mCategories {
          label
          score
          categoryId
        }
      }
      nextToken
    }
  }
`;
