import gql from 'graphql-tag';

export default gql`
  query GetTeams($input: GetMemberOfTypeInput) {
    getMembersOftype(input: $input) {
      mId
      mRefId
      mTitle
      mAvatarUrl
      mAvatarKey
      mDescription
    }
  }
`;
