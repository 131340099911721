import React from 'react';
import PropTypes from 'prop-types';
import Available from './available/Available';
import Away from './away/Away';
import Idle from './idle/Idle';
import Checked from './checked/Checked';

const StatusIndicator = ({ variant, notification }) => {
  switch (variant) {
    case 'Available':
      return <Available />;
    case 'Away':
      return <Away />;
    case 'Idle':
      return <Idle />;
    case 'Checked':
      return <Checked />;
    default:
      return null;
  }
};

StatusIndicator.propTypes = {
  /** Defines type of the indicator.
   * Values: "Available" or "Away" or "Idle" or "Checked"
   */
  variant: PropTypes.oneOf(['', 'Available', 'Away', 'Idle', 'Checked']),
  notification: PropTypes.string,
};

StatusIndicator.defaultProps = {
  variant: '',
  notification: '',
};

export default StatusIndicator;
