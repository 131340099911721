import i1 from './ArtCultureEntertainment.svg';
import i2 from './Legal.svg';
import i3 from './Disasters.svg';
import i4 from './Economy.svg';
import i5 from './Education.svg';
import i6 from './Environment.svg';
import i7 from './Health.svg';
import i8 from './HumanInterest.svg';
import i9 from './Labour.svg';
import i10 from './LifestyleLeisure.svg';
import i11 from './Politics.svg';
import i12 from './Religion.svg';
import i13 from './Science.svg';
import i14 from './Society.svg';
import i15 from './Sports.svg';
import i16 from './ConflictWarPeace.svg';
import i17 from './Weather.svg';

const categoryIconData = [
  {
    mId: '01000000',
    mAvatarUrl: i1,
    color: 'categoryArtCultureEntertainment',
  },
  {
    mId: '02000000',
    mAvatarUrl: i2,
    color: 'categoryLegal',
  },
  {
    mId: '03000000',
    mAvatarUrl: i3,
    color: 'categoryDisasters',
  },
  {
    mId: '04000000',
    mAvatarUrl: i4,
    color: 'categoryEconomy',
  },
  {
    mId: '05000000',
    mAvatarUrl: i5,
    color: 'categoryEducation',
  },
  {
    mId: '06000000',
    mAvatarUrl: i6,
    color: 'categoryEnvironment',
  },
  {
    mId: '07000000',
    mAvatarUrl: i7,
    color: 'categoryHealth',
  },
  {
    mId: '08000000',
    mAvatarUrl: i8,
    color: 'categoryHumanInterest',
  },
  {
    mId: '09000000',
    mAvatarUrl: i9,
    color: 'categoryLabour',
  },
  {
    mId: '10000000',
    mAvatarUrl: i10,
    color: 'categoryLifestyleLeisure',
  },
  {
    mId: '11000000',
    mAvatarUrl: i11,
    color: 'categoryPolitics',
  },
  {
    mId: '12000000',
    mAvatarUrl: i12,
    color: 'categoryReligion',
  },
  {
    mId: '13000000',
    mAvatarUrl: i13,
    color: 'categoryScience',
  },
  {
    mId: '14000000',
    mAvatarUrl: i14,
    color: 'categorySociety',
  },
  {
    mId: '15000000',
    mAvatarUrl: i15,
    color: 'categorySports',
  },
  {
    mId: '16000000',
    mAvatarUrl: i16,
    color: 'categoryConflictWarPeace',
  },
  {
    mId: '17000000',
    mAvatarUrl: i17,
    color: 'categoryWeather',
  },
];

export default categoryIconData;
