import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: '12px',
    marginTop: '5px',
  },
  buttonContainer: {
    margin: '8px 0px',
  },
}));
export default useStyles;
