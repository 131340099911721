import React from 'react';
import PropTypes from 'prop-types';
import { distanceInWords } from 'date-fns';
import useHighLightWords from 'hooks/useHighlightWords';
import { Typography, Chip } from '@material-ui/core';

const Header = props => {
  const { classes, href, mPublishedAt, section, source, mTitle, searchWords } = props;
  const highlight = useHighLightWords();

  const createMarkup = () => {
    return {
      __html: `${mTitle && mTitle !== 'null' ? highlight(mTitle, searchWords) : ''}`,
    };
  };

  return (
    <>
      <div className={classes.cover}>
        {section ? (
          <Chip label={section} classes={{ root: classes.chip, label: classes.chipLabel }} />
        ) : null}
        <Typography className={classes.title} dangerouslySetInnerHTML={createMarkup()} />
      </div>
      <div className={classes.info}>
        <span className={classes.source}>
          <Typography className={classes.sourceTitle}>
            {source}{' '}
            {href && href.length > 0 ? (
              <a
                className={classes.sourceUrl}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
              >
                open original article
              </a>
            ) : (
              ''
            )}
          </Typography>
          <Typography className={classes.section}>{section || <>&nbsp;</>}</Typography>
        </span>
        <Typography className={classes.date}>
          {distanceInWords(mPublishedAt, new Date())} ago
        </Typography>
      </div>
    </>
  );
};

Header.propTypes = {
  href: PropTypes.string,
  mPublishedAt: PropTypes.string,
  section: PropTypes.string,
  source: PropTypes.string,
  mTitle: PropTypes.string,
  searchWords: PropTypes.arrayOf(PropTypes.string),
};

Header.defaultProps = {
  href: '',
  mPublishedAt: '',
  section: ' ',
  source: '',
  mTitle: '',
  searchWords: [],
};

export default Header;
