import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3';
import createZoomBehavior from './createZoomBehavior';
import makeInitialZoom from './makeInitialZoom';
import createRollingBehavior from './createRollingBehavior';

const ZoomController = forwardRef((props, ref) => {
  const {
    width,
    margin,
    height,
    xScaleRef,
    newXScaleRef,
    startDate,
    endDate,
    onZoomChange,
    draggingRef,
    storyPulishingDate,
  } = props;

  const rectRef = useRef(null);
  const kRef = useRef(1);
  const zoomingRef = useRef(false);
  const zoomInstanceRef = useRef(null);
  const rectElementRef = useRef(null);

  const zoomIn = () => {
    const zoomInstance = zoomInstanceRef.current;

    rectElementRef.current
      .transition()
      .duration(1000)
      .call(zoomInstance.scaleBy, 2);
  };

  const zoomOut = () => {
    const zoomInstance = zoomInstanceRef.current;

    rectElementRef.current
      .transition()
      .duration(1000)
      .call(zoomInstance.scaleBy, 0.5);
  };

  const translateLeft = () => {
    const zoomInstance = zoomInstanceRef.current;

    rectElementRef.current.call(zoomInstance.translateBy, -1 / kRef.current, 0);
  };

  const translateRight = () => {
    const zoomInstance = zoomInstanceRef.current;

    rectElementRef.current.call(zoomInstance.translateBy, 1 / kRef.current, 0);
  };

  useImperativeHandle(ref, () => ({
    zoomIn,
    zoomOut,
    translateLeft,
    translateRight,
  }));

  const init = () => {
    let timer;

    if (xScaleRef.current && width && height) {
      const rectElement = select(rectRef.current);

      const zoomInstance = createZoomBehavior({
        endDate,
        startDate,
        width,
        height,
        zoomingRef,
        kRef,
        onZoomChange,
      });

      rectElement.call(zoomInstance);

      makeInitialZoom(rectElement, zoomInstance, xScaleRef, width, storyPulishingDate);

      timer = createRollingBehavior({
        rectElement,
        zoomInstance,
        newXScaleRef,
        zoomingRef,
        kRef,
        xScaleRef,
        draggingRef,
      });

      zoomInstanceRef.current = zoomInstance;
      rectElementRef.current = rectElement;
    }

    return () => clearInterval(timer);
  };

  useEffect(init, [width]);

  return (
    <g>
      <rect
        ref={rectRef}
        x={0}
        y={0}
        width={width + margin}
        height={height}
        fill="transparent"
      />
    </g>
  );
});

ZoomController.propTypes = {
  /** Specifies the width of the timeline */
  width: PropTypes.number.isRequired,
  /** Specifies the margin for the timeline grid */
  margin: PropTypes.number.isRequired,
  /** Specifies the height of the timeline */
  height: PropTypes.number.isRequired,
  /** Specifies the initial d3 time scale */
  xScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  /** Specifies the current d3 time scale */
  newXScaleRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  /** Defines the start of the timeline */
  startDate: PropTypes.instanceOf(Date).isRequired,
  /** Defines the end of the timeline */
  endDate: PropTypes.instanceOf(Date).isRequired,
  /** Callback to be invoked on zoom change */
  onZoomChange: PropTypes.func.isRequired,
  /** Determines if the instances are being dragged or not */
  draggingRef: PropTypes.shape({
    current: PropTypes.bool,
  }).isRequired,
};

export default ZoomController;
