import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/popover/Popover';
import EllipsisButton from 'components/buttons/ellipsisButton';
import useStyles from './ellipsisDropdown-styles';

const EllipsisDropdown = props => {
  const { listItems: items, anchorEl, setAnchorEl } = props;
  const classes = useStyles();

  return (
    <div>
      <EllipsisButton
        onClick={e => {
          setAnchorEl(e.currentTarget);
        }}
      />
      <Popover
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <div className={classes.container}>{items}</div>
      </Popover>
    </div>
  );
};

EllipsisDropdown.propTypes = {
  /** Items that should be displayed */
  listItems: PropTypes.arrayOf(PropTypes.element),
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.objectOf(PropTypes.object),
};

EllipsisDropdown.defaultProps = {
  listItems: [<div key="1" />],
  setAnchorEl: () => {},
  anchorEl: null,
};

export default EllipsisDropdown;
