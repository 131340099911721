import { makeStyles } from '@material-ui/styles';

const borderRadius = 4;
const padding = 8;

const useStyles = makeStyles(({ palette }) => ({
  root: {
    borderRadius,
    background: ({ showHighlight }) =>
      showHighlight && palette.dina.blackHoverOverlay,
    padding,
  },
}));

export default useStyles;
