import React from 'react';
import PropTypes from 'prop-types';
import Container from '../container/Container';
import MaterialAvatar from '../material-avatar';
import useStyles from './styles';

const Category = ({ children, src, ...rest }) => {
  const classes = useStyles();

  return (
    <Container {...rest}>
      <MaterialAvatar classes={classes.root} {...{ src }}>
        {children}
      </MaterialAvatar>
    </Container>
  );
};

Category.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
};

Category.defaultProps = {
  children: null,
  src: '',
};

export default Category;
