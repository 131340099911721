import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor } from 'slate-react';
import { elementTypes } from 'components/editor/constants/types';
import { iconComponents } from '../../constants';
import useStyles from './styles';

const AutomationIcon = ({ type, dragHandleProps }) => {
  const classes = useStyles();
  const editor = useSlate();
  const IconComponent = useMemo(() => iconComponents[type], [type]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const forceEditorFocus = useCallback(() => ReactEditor.focus(editor), []);

  return (
    <div
      className={classes.automationIcon}
      onClick={forceEditorFocus}
      role="presentation"
      {...dragHandleProps}
    >
      <IconComponent />
    </div>
  );
};

AutomationIcon.propTypes = {
  /** Automation type */
  type: PropTypes.string,
};

AutomationIcon.defaultProps = {
  type: elementTypes.CAMERA,
};

export default memo(AutomationIcon);
