import React from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    background: 'transparent',
    border: 0,
    outline: 0,
    '&:hover': {
      cursor: 'pointer',
      '& svg': {
        fillOpacity: 1,
        '& path': {
          fillOpacity: 1,
        },
      },
    },
  },
}));

const ImageToggleButton = props => {
  const { imageChecked, imageUnChecked, checked, onClick } = props;
  const classes = useStyles();

  return (
    <button
      data-testid="regular-toggleButton"
      onClick={onClick}
      type="submit"
      className={classes.button}
    >
      {checked ? imageChecked : imageUnChecked}
    </button>
  );
};

ImageToggleButton.propTypes = {
  // path to the image or SVG icon
  imageUnChecked: PropTypes.string,
  // path to the image or SVG icon
  imageChecked: PropTypes.string,
  // bool value
  checked: PropTypes.bool,
  // callback to the onClick function
  onClick: PropTypes.func,
};

ImageToggleButton.defaultProps = {
  checked: false,
  imageChecked: '',
  imageUnChecked: '',
  onClick: () => {},
};

export default ImageToggleButton;
