import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    // overflowY: 'scroll',
  },
  backdropRoot: {
    position: 'absolute',
  },
}));

export default useStyles;
