/* eslint-disable no-useless-catch */
import React, { createContext, useState, useEffect } from 'react';
import useResetPlans from 'hooks/useResetPlans';
import Auth from '@aws-amplify/auth';
import { resetLockToken } from 'utils/lock/lockToken';

const initialState = { user: null, groups: [] };

const AuthContext = createContext(initialState);

const AuthProvider = props => {
  const { children } = props;
  const [userState, setUserState] = useState(initialState);
  const resetPlans = useResetPlans();

  const setLoggedInUser = loggedinUser => {
    if (loggedinUser) {
      const idToken = loggedinUser.getSignInUserSession().getIdToken();
      if (idToken && idToken.payload) {
        setUserState({ user: loggedinUser, groups: idToken.payload['cognito:groups'] || [] });
      } else {
        setUserState({ user: loggedinUser, groups: [] });
      }
    } else setUserState(initialState);
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(loggedInUser => {
        if (!userState.user) {
          setLoggedInUser(loggedInUser);
        }
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = async () => {
    try {
      // clearStore clears the store without refetching active queries.
      await Auth.signOut().then(children.props.client.clearStore());
      resetPlans();
    } catch (error) {
      // console.log(error);
    }

    resetLockToken();
    setLoggedInUser(null);
  };

  const login = async (email, password) => {
    // const code = '000000';
    // Verify if user exists.
    /* try {
      await Auth.confirmSignUp(email, code, {
        /* If set to False, the API will throw an AliasExistsException error if the phone 
        number/email used already exists as an alias with a different user 
        forceAliasCreation: false,
      });
    } catch (error) {
      if (error.code === 'UserNotFoundException') {
        setLoggedInUser(null);
        throw error;
      }
    } */

    try {
      const cognitoUser = await Auth.signIn(email, password);
      if (cognitoUser.challengeName !== 'NEW_PASSWORD_REQUIRED') {
        setLoggedInUser(cognitoUser);
      }

      resetLockToken();
      return cognitoUser;
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  const forgotPassword = async username => {
    try {
      const cognitoUser = await Auth.forgotPassword(username);
      return cognitoUser;
    } catch (error) {
      throw error;
    }
  };

  const forgotPasswordSubmit = async (username, verificationCode, newPassword) => {
    try {
      const cognitoUser = await Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
      return cognitoUser;
    } catch (error) {
      throw error;
    }
  };

  const completeSignup = async (userToConnfirm, firstName, lastName, password) => {
    try {
      const cognitoUser = await Auth.completeNewPassword(userToConnfirm, password, {
        given_name: firstName,
        family_name: lastName,
      });

      setLoggedInUser(cognitoUser);
    } catch (error) {
      // console.log(error);
      if (error.code === 'InvalidPasswordException') {
        throw error;
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user: userState.user,
        groups: userState.groups,
        setUser: setLoggedInUser,
        login,
        forgotPassword,
        forgotPasswordSubmit,
        logout,
        completeSignup,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;
export { AuthProvider, AuthConsumer };
