import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor } from 'slate-react';
import useEditorContext from 'components/editor/hooks/useEditorContext';
import Dialog from 'components/dialog';
import CreateNew from 'components/createNew';
import createPlaceholder from './utils/createPlaceholder';

const MemoizedDialog = memo(Dialog);
const MemoizedCreateNew = memo(CreateNew);

const PlaceholderDialog = ({ element, onClose, open }) => {
  const editor = useSlate();
  const { containerRef, update, defaultPlaceholderTitle } = useEditorContext();

  const handleCreatePlaceholder = useCallback(
    title => {
      onClose();
      createPlaceholder(editor, element, update, title);
      ReactEditor.focus(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  return (
    <MemoizedDialog
      container={containerRef.current}
      {...{ open, onClose }}
      disableRestoreFocus
      disableEnforceFocus
    >
      <MemoizedCreateNew
        variant="placeholder"
        defaultTitle={defaultPlaceholderTitle}
        onCreate={handleCreatePlaceholder}
        onCancel={onClose}
      />
    </MemoizedDialog>
  );
};

PlaceholderDialog.propTypes = {
  /** SlateJS element */
  element: PropTypes.shape({
    data: PropTypes.shape({
      assets: PropTypes.array,
    }),
  }),
  /** Callback to be invoked when the dialog closes */
  onClose: PropTypes.func,
  /** Specifies if the dialog is open or not */
  open: PropTypes.bool,
};

PlaceholderDialog.defaultProps = {
  element: {},
  onClose: () => {},
  open: false,
};

export default memo(PlaceholderDialog);
