import React from 'react';
import memberTypes from 'graphql/memberTypes';
import { useMutation } from 'react-apollo';
import CREATE_DEPARTMENT from 'graphql/mutations/createDepartment';
import GET_DEPARTMENTS from 'graphql/queries/getDepartments';
import { getMembersOfTypeQuery } from 'graphql/queryVariables';
import CreateDepartment from './create-department';

const CreateDepartmentContainer = props => {
  const { onSubmit, onCancel } = props;
  const departmentType = memberTypes.DEPARTMENT;

  const [createDepartment] = useMutation(CREATE_DEPARTMENT, {
    update: (proxy, mutationResult) => {
      const { createMember } = mutationResult.data;
      // Read departments from the cache
      try {
        const departmentList = proxy.readQuery({
          query: GET_DEPARTMENTS,
          variables: getMembersOfTypeQuery(departmentType),
        });

        const list = departmentList.getMembersOftype.filter(
          department =>
            department !== null && department.mId === createMember.mId
        );
        // Add new story to the storylist if it is not the cache
        if (list.length === 0) {
          departmentList.getMembersOftype.push(createMember);
        }
        // Write updated storylist in the cache.
        proxy.writeQuery({
          query: GET_DEPARTMENTS,
          variables: getMembersOfTypeQuery(departmentType),
          data: departmentList,
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const createNewDepartment = async (title, description) => {
    if (!title) {
      return;
    }

    const input = {
      mTitle: title,
      mType: departmentType,
    };

    if (description) {
      input.mDescription = description;
    }

    createDepartment({
      variables: {
        input,
      },
    })
      .then(result => {
        if (result.data.createMember) {
          onSubmit(result.data.createMember);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <>
      <CreateDepartment onCancel={onCancel} onSubmit={createNewDepartment} />
    </>
  );
};

export default CreateDepartmentContainer;
