import React, { useState } from 'react';
import { MenuItem as MaterialMenuItem } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/styles';
import StarBorder from '@material-ui/icons/StarBorderRounded';
import ArrowRight from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';

import Popover from 'components/popover/Popover';
// import PlatformIcons from '../PlatformIcons';

const useStyles = makeStyles(theme => ({
  root: {
    '&$selected': {
      backgroundColor: theme.palette.dina.onSelected,
    },
    '&:hover': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
  },
  rootUnassigned: {
    borderBottom: '1px solid rgba(172, 184, 198, 0.25)',
    '&$selected': {
      backgroundColor: theme.palette.dina.onSelected,
    },
    '&:hover': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
  },
  label: {
    color: theme.palette.dina.highEmphasis,
  },

  labelRight: {
    color: theme.palette.dina.highEmphasis,
    marginRight: -30,
  },

  icon: {
    color: theme.palette.dina.mediumEmphasis,
  },
  iconRight: {
    color: theme.palette.dina.mediumEmphasis,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  primary: {
    color: theme.palette.dina.mediumEmphasis,
  },
  labelText: {
    width: '130px',
    marginLeft: -15,
  },
  italicLabelText: {
    width: '130px',
    marginLeft: -15,
    fontStyle: 'italic',
  },
  expand: {},
  selected: {},
}));

const InstanceMenuItem = props => {
  const classes = useStyles();
  const { label, children, noOfSubInstance, items, onClose, unassignedVariant, ...others } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(false);
  const handleClick = event => {
    if (noOfSubInstance === undefined) {
      onClose(items);
    } else {
      setAnchorEl(event.currentTarget);
      setSelected(true);
    }
  };
  return (
    <>
      <MaterialMenuItem
        selected={selected}
        onClick={handleClick}
        classes={{
          root: unassignedVariant ? classes.rootUnassigned : classes.root,
          selected: classes.selected,
        }}
        {...others}
      >
        <ListItemIcon className={classes.icon}>{children}</ListItemIcon>
        <ListItemText
          classes={{
            root: unassignedVariant ? classes.italicLabelText : classes.labelText,
            primary: classes.label,
          }}
          primary={label}
        />

        <ListItemText
          classes={{ root: classes.labelRight, primary: classes.primary }}
          inset
          primary={noOfSubInstance}
        />
        <ListItemIcon className={classes.iconRight}>
          {noOfSubInstance ? <img src={ArrowRight} alt="" /> : null}
        </ListItemIcon>
      </MaterialMenuItem>
      {noOfSubInstance && (
        <Popover
          onClose={() => {
            setAnchorEl(null);
            setSelected(false);
          }}
          position="right"
          anchorEl={anchorEl}
        >
          <>
            {items.map(item => (
              <InstanceMenuItem
                key={item.accountTitle}
                label={item.accountTitle}
                unassignedVariant={item.isUnassigned}
                onClose={account => {
                  setAnchorEl(null);
                  onClose(account);
                }}
                items={item}
              >
                {children}
              </InstanceMenuItem>
            ))}
          </>
        </Popover>
      )}
    </>
  );
};

export default InstanceMenuItem;
