import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  inputWrapper: {
    minWidth: '300px',
    maxWidth: '415px',
    display: 'flex',
    alignItems: 'center',
    height: '39px',
    marginBottom: '8px',
  },
  textField: {
    width: '100%',
    height: '100%',
    marginLeft: '10px',
    padding: '4px 8px',
  },
  root: {
    height: '39px',
    backgroundColor: theme.palette.dina.inputBackground,
    borderRadius: '1px 1px 0 0',
    marginLeft: '10px',
    '&:hover': {
      borderBottom: `none`,
    },
    overflow: 'auto',
  },
  input: {
    ...theme.typography.dina.body2,
    padding: '4px 8px',
    '&::placeholder': {
      color: theme.palette.dina.disabled,
      opacity: 1,
    },
  },
  underline: {
    '&:before': {
      borderBottom: `1px solid ${theme.palette.dina.inputBorderResting}`,
    },
    '&:after': {
      borderBottom: `1px solid ${theme.palette.dina.onFocus}`,
    },
    '&:hover': {
      '&:before': {
        borderBottom: `1px solid ${theme.palette.dina.inputBorderResting}`,
      },
      '&:not(.Mui-disabled):before': {
        borderBottom: `1px solid ${theme.palette.dina.inputBorderResting}`,
      },
    },
  },
  selectIcon: {
    fill: theme.palette.dina.iconInactive,
  },
  checkbox: {
    color: theme.palette.dina.iconInactive,
    '&$checked': {
      transformOrigin: 0,

      color: theme.palette.dina.onFocus,
    },
  },
  checked: {
    transformOrigin: 0,

    color: theme.palette.dina.onFocus,
  },
  placeholder: {
    ...theme.typography.dina.body2,
    color: theme.palette.dina.disabled,
  },
  checkboxPopupTitle: {
    ...theme.typography.dina.overline,
    marginLeft: '12px',
    marginBottom: '6px',
  },
  menuItem: {
    ...theme.typography.dina.listItemLabel,
    height: '32px',
  },
  addMember: {
    // opacity: 0,
    height: '10px',
  },
  test: {
    opacity: 0,
  },
}));

export default useStyles;
