import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Icon from 'components/icon/icon-view';
import useStyles from './input-styles';

const CheckboxInput = props => {
  const { icon, label, value, onChange, boxes } = props;
  const classes = useStyles();

  return (
    <div className={classes.inputWrapper}>
      <Icon icon={icon} height={24} width={24} />
      <Select
        multiple
        MenuProps={{ variant: 'menu' }}
        fullWidth
        displayEmpty
        value={value}
        onChange={e => onChange(e.target.value)}
        classes={{ icon: classes.selectIcon }}
        renderValue={selected => {
          return !selected || selected.length === 0 ? (
            <span className={classes.placeholder}>{label}</span>
          ) : (
            selected.join(' | ')
          );
        }}
        input={
          <Input
            classes={{
              root: classes.root,
              underline: classes.underline,
              input: classes.input,
            }}
          />
        }
      >
        <Typography className={classes.checkboxPopupTitle}>Edition Types</Typography>
        {boxes.map(item => (
          <MenuItem classes={{ root: classes.menuItem }} key={item} value={item}>
            <Checkbox
              classes={{
                root: classes.checkbox,
                checked: classes.checked,
              }}
              checked={value.indexOf(item) > -1}
            />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

CheckboxInput.propTypes = {
  /** icon */
  icon: PropTypes.string.isRequired,
  /** input label */
  label: PropTypes.string.isRequired,
  /** controlled form value */
  value: PropTypes.arrayOf(PropTypes.string),
  /** onChange handler */
  onChange: PropTypes.func,
  /** array with check box values */
  boxes: PropTypes.arrayOf(PropTypes.string),
};

CheckboxInput.defaultProps = {
  value: [],
  onChange: () => {},
  boxes: [],
};

export default CheckboxInput;
