import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.dina.onSelected,
    width: 40,
    height: 40,
    borderRadius: 20,
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'left 0.3s ease-out, width 0.3s ease-out',
    visibility: 'hidden',
    willChange: 'left, width',
  },
}));

const Indicator = forwardRef((props, ref) => {
  const classes = useStyles();

  return <div className={classes.root} {...props} ref={ref} />;
});

export default Indicator;
