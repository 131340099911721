import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  inputFieldDiv: {
    width: 'auto',
  },
  exceedChar: {
    color: theme.palette.dina.statusWarning,
  },
  labelRoot: {
    paddingLeft: ({ type }) => (type === 'editor' ? null : '6px'),
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '10px',
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: theme.palette.dina.disabled,
    mixBlendMode: 'normal',
    '&$focused': {
      color: theme.palette.dina.disabled,
    },
    '&$disabled': {
      color: theme.palette.dina.disabled,
    },
    '&$error': {
      color: theme.palette.dina.disabled,
    },
    '&$shrink': {
      transform: 'translate(10px, 4px) scale(0.70)',
    },
  },

  filledInputRoot: {
    minHeight: '40px',
    '&:hover:not($disabled)': {
      backgroundColor: theme.palette.dina.outlineButtonOnHover,
    },
    '&$error': {
      border: `1px solid ${theme.palette.dina.statusError}`,
    },
  },

  filledInput: {
    ...theme.typography.dina.body2,
    fontWeight: 'normal',
    color: theme.palette.dina.highEmphasis,
    width: '100%',
    padding: '0px 5px 5px',
  },

  cssUnderlineRegular: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.dina.inputBorderResting}`,
    },

    '&:after': {
      borderBottom: `2px solid ${theme.palette.dina.onFocus}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
    '&:before': {
      borderBottom: `1px solid ${theme.palette.dina.disabled}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
  },

  cssUnderlineTouched: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.dina.inputBorderResting}`,
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.dina.onFocus}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
    '&:before': {
      borderBottom: `1px solid ${theme.palette.dina.statusWarning}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
  },

  cssUnderlineNotTouched: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `1px solid ${theme.palette.dina.inputBorderResting}`,
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.dina.onFocus}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
    '&:before': {
      borderBottom: `1px solid ${theme.palette.dina.disabled}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
    },
    '&$disabled:before': {
      borderBottomStyle: 'solid',
    },
  },

  iconButton: {
    color: theme.palette.dina.mediumEmphasis,
    position: 'absolute',
    top: 0,
    right: 5,
  },

  formHelperRoot: {
    ...theme.typography.dina.captionRegular,
    lineHeight: '13px',
    color: theme.palette.dina.mediumEmphasis,
    margin: '0',
    padding: ({ type }) => (type === 'SoMe' ? '2px 0px 0px 8px' : '4px 0px 4px 16px'),
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px',
    '&$error': {
      color: theme.palette.dina.mediumEmphasis,
      background: theme.palette.dina.statusError,
    },
    '&$disabled': {
      color: theme.palette.dina.disabled,
    },
  },
  multiline: {
    padding: 0,
  },
  inputMultiline: {
    padding: '10px 8px 0',
  },
  focused: {},
  disabled: {},
  error: {},
  shrink: {},
}));

export default useStyles;
