import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import { ReactComponent as CloseSrc } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as ShareOffSrc } from 'assets/icons/systemicons/share_off.svg';
import { ReactComponent as ShareOnSrc } from 'assets/icons/systemicons/share_on.svg';
import useStyles from './speed-dial-styles';

const SpeedDialView = ({ actions, isShared, onOpen, onClose }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClose = () => setOpen(false);

  const handleClick = () => {
    setOpen(prevState => !prevState);
    onOpen();
  };

  const handleItemClick = type => {
    handleClose();
    onClose(type);
  };

  const renderShareIcon = () => {
    if (open) return <CloseSrc />;
    return isShared ? <ShareOnSrc /> : <ShareOffSrc />;
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <SpeedDial
        ariaLabel="Share"
        icon={renderShareIcon()}
        onClick={handleClick}
        direction="down"
        TransitionComponent={({ children }) => <div>{children}</div>}
        classes={{ fab: open ? classes.clicked : classes.button }}
        {...{ open }}
      >
        {actions.map(({ name, icon, type }) => (
          <SpeedDialAction
            {...{ icon }}
            key={name}
            tooltipTitle={name}
            onClick={() => handleItemClick(type)}
            classes={{ fab: classes.list }}
            TooltipClasses={{ tooltip: classes.tooltip }}
          />
        ))}
      </SpeedDial>
    </ClickAwayListener>
  );
};

SpeedDialView.propTypes = {
  /** List of items to be rendered on menu open  */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.node,
      type: PropTypes.string,
    })
  ),
  /** Defines if the item is already shared or not  */
  isShared: PropTypes.bool,
  /** Callback to be invoked on menu open  */
  onOpen: PropTypes.func,
  /** Callback to be invoked on menu close  */
  onClose: PropTypes.func,
};

SpeedDialView.defaultProps = {
  actions: [],
  isShared: false,
  onOpen: () => {},
  onClose: () => {},
};

export default SpeedDialView;
