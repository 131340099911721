import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '96px',
    height: '64px',
    borderRadius: '4px',
    position: 'relative',
    backgroundColor: theme.palette.dina.thumbGraphicsBackground,
    '&:hover $dragButton': {
      opacity: 1,
    },
  },
  title: {
    position: 'absolute',
    left: '4px',
    right: '4px',
    bottom: '2px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  rundownIcon: {
    position: 'absolute',
    left: '4px',
    top: '4px',
  },
  titleText: {
    ...theme.typography.dina.storyCardAvatarText,
    color: theme.palette.dina.highEmphasis,
    fontSize: '10px',
    letterSpacing: '0.5px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  closeButton: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    top: '4px',
    right: '4px',
    backdropFilter: 'blur(15px)',
  },
  closeImage: {
    position: 'absolute',
  },
  dragButton: {
    position: 'absolute',
    opacity: 0,
    left: 'calc(50% - 32px/2)',
    top: 'calc(50% - 32px/2 - 4px)',
    width: '32px',
    height: '32px',
    backgroundColor: theme.palette.dina.blackMediumEmphasis,
    '&:hover': {
      backgroundColor: theme.palette.dina.blackMediumEmphasis,
    },
  },
  dragImage: {
    position: 'absolute',
    width: '22px',
    height: '22px',
  },
}));

export default useStyles;
