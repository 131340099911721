import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListItemText, ListItemSecondaryAction, MenuItem } from '@material-ui/core';
import Popover from 'components/popover';
import Delete from 'assets/icons/systemicons/delete_small.svg';
import Folder from 'assets/icons/systemicons/folder.svg';
import Arrow from 'assets/icons/systemicons/arrows/disclosurearrow_right_opaque.svg';
import useCommonStyles from '../linearEllipsisMenu/linear-ellipsis-menu-styles';
import ConfirmationComponent from '../linearEllipsisMenu/components/confirmationComponent';
import useStyles from './menu-item-styles';

const MenuItemFolder = ({
  anchorEl,
  label,
  data,
  onClick,
  image,
  secondaryItem,
  showSecondaryItem,
  disabled,
  showDeleteButton,
  onDeleteButtonClick,
  isMuted,
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles({ isMuted });
  const [openDialog, setOpenDialog] = useState(false);

  const handleDeleteButtonClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDialog(false);
  };

  const handleConfirm = event => {
    event.preventDefault();
    event.stopPropagation();
    onDeleteButtonClick();
    handleDialogClose();
  };

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    onClick();
  };

  return (
    <div className={classes.container}>
      <MenuItem
        onClick={handleClick}
        classes={{ root: classes.root }}
        title={label}
        disabled={disabled}
      >
        <img src={image || Folder} alt="" className={commonClasses.icon} />
        <ListItemText
          primary={label}
          title={disabled ? 'Not implemented yet' : label}
          classes={{ primary: commonClasses.primaryText }}
        />
        {showSecondaryItem && (
          <ListItemSecondaryAction>
            {secondaryItem ||
              (data && (
                <div className={classes.number}>
                  {data.length > 0 && data.length}
                  {showDeleteButton && (
                    <img
                      role="presentation"
                      src={Delete}
                      alt="delete"
                      className={classes.delete}
                      title="Delete Folder"
                      onClick={handleDeleteButtonClick}
                    />
                  )}
                  <img src={Arrow} alt="" />
                </div>
              ))}
          </ListItemSecondaryAction>
        )}
      </MenuItem>
      {openDialog && (
        <Popover anchorEl={anchorEl} onClose={handleDialogClose}>
          <div className={classes.popover}>
            <ConfirmationComponent
              label={label}
              onOk={handleConfirm}
              onCancel={handleDialogClose}
            />
          </div>
        </Popover>
      )}
    </div>
  );
};

MenuItemFolder.propTypes = {
  /** name of menuItem */
  label: PropTypes.string,
  /** contents of menuItem */
  data: PropTypes.arrayOf(PropTypes.any),
  /** callback to open menuItem folder */
  onClick: PropTypes.func,
  /** image of menuItem */
  image: PropTypes.string,
  /** Secondary Action item */
  secondaryItem: PropTypes.node,
  /** Boolean to show secondary action item */
  showSecondaryItem: PropTypes.bool,
  /** Boolean to disable the MenuItem */
  disabled: PropTypes.bool,
  /** Boolean to show the delete button */
  showDeleteButton: PropTypes.bool,
  /** Callback to be invoked when delete is clicked */
  onDeleteButtonClick: () => {},
  /** Node for the popover to attach to */
  anchorEl: PropTypes.instanceOf(Element),
  /** boolean to reduce the opacity to half */
  isMuted: PropTypes.bool,
};

MenuItemFolder.defaultProps = {
  label: '',
  data: [],
  onClick: () => {},
  image: null,
  secondaryItem: null,
  showSecondaryItem: true,
  disabled: false,
  showDeleteButton: false,
  onDeleteButtonClick: () => {},
  anchorEl: null,
  isMuted: false,
};

export default MenuItemFolder;
