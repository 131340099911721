import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, InputBase } from '@material-ui/core';
// eslint-disable-next-line max-len
import { ReactComponent as ArrowIcon } from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet.svg';
import priorityList, { findClassName } from './priorityList';
import useStyles from './storyImportance-styles';

const StoryImportance = ({ value, onChange }) => {
  const classes = useStyles();

  const handleChange = event => {
    event.stopPropagation();
    onChange(event.target.value);
  };

  const handleClose = event => {
    event.stopPropagation();
  };

  return (
    <Select
      input={
        <InputBase
          className={classes[findClassName(value)]}
          classes={{ root: classes.root, input: classes.input }}
        />
      }
      value={value}
      displayEmpty
      onChange={handleChange}
      IconComponent={ArrowIcon}
      onClose={handleClose}
    >
      {priorityList.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
          className={classes[option.className]}
          dense
        >
          {option.text}
        </MenuItem>
      ))}
    </Select>
  );
};

StoryImportance.propTypes = {
  /** priority value  */
  value: PropTypes.string,
  /** callback to be invoked on priority Change */
  onChange: PropTypes.func,
};

StoryImportance.defaultProps = {
  value: '',
  onChange: () => {},
};

export default StoryImportance;
