import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CurrentTabContext, StoryTabsContext } from 'globalState';
import Tab from './content-view';

const StoryTab = props => {
  const { id, image, title, type, selecteddate, mid, mType } = props;

  const [, setCurrentTab] = useContext(CurrentTabContext);
  const [, , closeStoryTab] = useContext(StoryTabsContext);

  const openContent = () => {
    if (type === 'rundown') {
      setCurrentTab({
        page: 'rundown',
        title,
        id,
        selecteddate,
        mid,
        mType,
      });
    } else if (type === 'pitch') {
      setCurrentTab({
        page: type,
        title,
        id,
      });
    } else {
      setCurrentTab({
        page: 'story',
        title,
        id,
        image,
      });
    }
  };

  const closeTab = () => {
    closeStoryTab(id);
  };

  return (
    <Tab
      key={id}
      openStory={openContent}
      closeTab={closeTab}
      type={type}
      mType={mType}
      {...props}
    />
  );
};

StoryTab.propTypes = {
  /** Story id */
  id: PropTypes.string.isRequired,
  /** Story title */
  title: PropTypes.string,
  /** Story imgUrl */
  image: PropTypes.string,
  /** type (rundown, story etc) */
  type: PropTypes.string,
  /** mType (the memberType) */
  mType: PropTypes.string,
};

StoryTab.defaultProps = {
  title: '',
  image: '',
  type: 'story',
  mType: null,
};

export default StoryTab;
