import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography.dina.overline,
    lineHeight: '12px',
    letterSpacing: '1px',
    color: theme.palette.dina.highEmphasis,
    paddingTop: '6px',
  },
  description: {
    ...theme.typography.dina.caption,
    lineHeight: '14px',
    fontWeight: 'normal',
    letterSpacing: '0.25px',
    width: '100%',
  },
  multiline: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 10,
    '-webkit-box-orient': 'vertical',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  textBoxContainer: {
    width: '100%',
    minHeight: '40px',
  },
}));

const TextBox = ({ label, description, multiline }) => {
  const classes = useStyles();
  return (
    <div className={classes.textBoxContainer}>
      <div className={classes.label}>{label}</div>
      <div
        className={`${classes.description} ${
          multiline ? classes.multiline : classes.ellipsis
        }`}
      >
        {description}
      </div>
    </div>
  );
};

export default TextBox;
