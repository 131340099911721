/* eslint-disable no-useless-catch */
import gql from 'graphql-tag';
import { getStoryInstancesQuery } from 'graphql/queryVariables';

const GET_INSTANCES = gql`
  query getMembers($input: GetMemberInput) {
    getMembers(input: $input) {
      mId
      mRefId
    }
  }
`;

const updateStoryInstanceCache = async (proxy, storyId, newInstance) => {
  try {
    const storyInstanceList = proxy.readQuery({
      query: GET_INSTANCES,
      variables: getStoryInstancesQuery(storyId),
    });
    // storyList.getMembers.map(story =>  proxy.data.delete(story.mId))
    // Check if story is already in the cache
    const list = storyInstanceList.getMembers.filter(
      storyInstance => storyInstance !== null && storyInstance.mId === newInstance.mId,
    );
    // Add new story to the storylist if it is not the cache
    if (list.length === 0) {
      storyInstanceList.getMembers.push(newInstance);
    }
    // Write updated storylist in the cache.
    proxy.writeQuery({
      query: GET_INSTANCES,
      variables: getStoryInstancesQuery(storyId),
      data: storyInstanceList,
    });
  } catch (error) {
    // console.log(error);
  }
};

export default updateStoryInstanceCache;
